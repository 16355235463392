var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"down-top-padding",attrs:{"fluid":""}},[_c('BaseBreadcrumb',{attrs:{"title":_vm.page.title,"icon":_vm.page.icon,"breadcrumbs":_vm.breadcrumbs}}),_c('v-card',[_c('v-card-text',{staticClass:"pa-5"},[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"mb-8",attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Поиск...","filled":"","background-color":"transparent","hide-details":"","name":"title"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[(_vm.search)?_c('v-btn',{staticClass:"reset-button",attrs:{"slot":"append","icon":"","color":"red"},on:{"click":function($event){_vm.search = null}},slot:"append"},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1)],1),_c('v-col',[_c('v-switch',{staticStyle:{"margin-top":"16px"},attrs:{"label":"Показать удаленные","name":"only_trashed","value":"1"},model:{value:(_vm.only_trashed),callback:function ($$v) {_vm.only_trashed=$$v},expression:"only_trashed"}})],1)],1),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{staticClass:"mb-2 text-capitalize",attrs:{"color":"primary","to":"/faqs/create","dark":""}},[_vm._v(" Добавить вопрос ")])]}}])})],1)]},proxy:true},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"pill":""}},[_vm._v(_vm._s(item.id))])]}},{key:"item.position",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"pill":""}},[_vm._v(_vm._s(item.position === null ? '-' : item.position + 1))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"href":_vm.editItem(item),"icon":""}},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-pencil ")])],1),_c('v-icon',{attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }