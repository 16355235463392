<template>
  <v-container>
    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <v-row>
        <v-col>
          <ValidationProvider name="Название" v-slot="{ errors }" rules="required|min:3">
            <v-text-field label="Название" v-model="form.title" :error-messages="errors"/>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <ValidationProvider name="Название поля" v-slot="{ errors }" rules="required">
            <v-text-field :error-messages="errors" label="Название поля в ресурсе" v-model="form.field_name"/>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <ValidationProvider name="Тип" v-slot="{ errors }" rules="required">
            <v-select label="Тип" :error-messages="errors" :items="types" item-value="key" item-text="value" v-model="form.type"/>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <ValidationProvider name="Группа" v-slot="{ errors }" rules="required">
            <v-select label="Группа" :error-messages="errors" :items="groups" item-value="key" item-text="value" v-model="form.group_id"/>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="v-text-field__details">
            <div class="v-messages theme--light">
              <div class="v-messages__wrapper">
                <div class="v-messages__message">
                  <div v-html="hint"/>
                </div>
              </div>
            </div>
          </div>
          <VJsonEditor
            :plus="true"
            v-model="form.extra_options"
            :options="{ mode: 'code' }"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn color="primary" @click="submit" block>
            Сохранить
          </v-btn>
        </v-col>
      </v-row>
    </ValidationObserver>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import VJsonEditor from 'v-jsoneditor/src/index';

export default {
  name: "FieldForm",
  components: {
    VJsonEditor,
  },
  props: {
    field: {
      type: Object,
      default: () => ({}),
    }
  },
  data: () => ({
    form: {
      title: null,
      type: null,
      group_id: null,
      extra_options: null,
      field_name: null,
      hints: {},
    }
  }),
  created() {
    this.fetchTypes();
    this.fetchGroups();
    this.fetchHints();
  },
  methods: {
    ...mapActions({
      fetchTypes: "Fields/fetchTypes",
      fetchGroups: "Fields/fetchGroups",
      fetchHints: "Fields/fetchHints",
    }),
    submit() {
      this.$refs.observer.validate().then((isValid) => {
        if (isValid){
          this.$emit('onSubmit', this.form);
        }
      })
      // this.$emit('onSubmit', this.form);
    },
  },
  computed: {
    ...mapGetters({
      types: "Fields/getTypes",
      groups: "Fields/getGroups",
      hints: "Fields/getHints",
    }),
    hint() {
      return this.hints[this.form.type];
    }
  },
  watch: {
    field: {
      handler(val) {
        if (val && Object.keys(val).length > 0) {
          this.form = JSON.parse(JSON.stringify(val));
        }
      }
    }
  }
}
</script>

<style scoped>
.jsoneditor-container {
  height: 800px;
}
</style>
