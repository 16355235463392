import { Model } from "@tailflow/laravel-orion/lib/model";

export class Widget extends Model<{
    title: string
}> {

    $resource(): string {
        return "module/widgets";
    }
}
