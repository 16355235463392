<template>
  <v-container fluid class="down-top-padding">


    <!--
    <h3>Статистика</h3>

    <vue-fusion-chart
      :dataSource="column2dSource"
    />
    <vue-fusion-chart
      :dataSource="doughnut2dSource"
      :config="doughnut2dConfig"
    />
    <vue-fusion-chart
      :dataSource="chordSource"
      :config="chordConfig"
    />
    -->
    <template v-if="Object.keys(fetchedWidget).length > 0 && hasBetaRole">
      <v-card>
        <v-card-title>
          <h2>{{ fetchedWidget.title }}</h2>
        </v-card-title>
        <v-card-text>
          <div v-for="chart in fetchedWidget.datasets" :key="chart.id">
            <apexchart type="bar" :options="fetchedWidget.charts_options" :series="chart.dataset" height="500"></apexchart>
          </div>
        </v-card-text>
      </v-card>
    </template>
    <template v-if="userNotes.length">
      <v-card class="mt-5">
        <v-card-title>
          <h2>Заметки</h2>          
        </v-card-title>  
         <v-card-text>
          <div v-for="note in userNotes" :key="note.id">
            <div v-if="note.description" v-html="note.description"></div>
          </div>
        </v-card-text>      
      </v-card>
    </template>
    <template v-if="hasBetaRole">
      <v-card class="mt-5">
        <v-card-title>
          <h2>
            Последние изменения
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  to="/logs"
                  v-bind="attrs"
                  v-on="on"
                  fab small
                  color="primary elevation-1"
                >
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </template>
              <span>Перейти на детальную</span>
            </v-tooltip>

          </h2>
        </v-card-title>
        <v-card-text>
          <preview/>
        </v-card-text>
      </v-card>
    </template>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Preview from "./logging/Preview"

export default {
  name: "Index",
  data() {
    return {
      collections: [],
    }
  },
  components: { Preview },
  created() {
    this.fetchWidget(1);
    this.fetchUserNotes();
  },
  methods: {
    ...mapActions({
      fetchWidget: 'Widgets/fetchDetail',
      fetchUserNotes: 'Pages/fetchUserNotes',
    })
  },
  computed: {
    ...mapGetters({
      fetchedWidget: 'Widgets/getFetchedDetail',
      hasBetaRole: 'auth/hasBetaRole',
      userNotes: 'Pages/getFetchedUserNotes',
    })
  }
}
</script>
