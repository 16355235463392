<template>
  <v-container>
    <v-row>
      <v-col>
        <v-row>
          <h1>Создание настройки</h1>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <OptionForm
          :option="option"
          :errors="errors"
          @onSubmit="store"
        />
      </v-col>
    </v-row>
    <v-snackbar
      v-model="errors.hasErrors"
    >
      {{this.errors.message}}
    </v-snackbar>
  </v-container>
</template>

<script>
import OptionForm from "../../../components/portalOptions/OptionForm";
import {Option} from "../../../models/option";

export default {
  components: {
    OptionForm,
  },
  name: "Create",
  data(){
    return {
      option: new Option(),
      errors: {
        message: "",
        hasErrors: false,
      },
      snackbar: false,
    }
  },
  methods: {
    async store(form) {
      this.errors = {};
      await Option.$query().store(form)
        .then(() => this.$router.push({name: 'portal-options-index'}))
        .catch(() => {
          this.errors.message = "Такой ключ уже существует";
          this.errors.hasErrors = true;
        })
    }
  }
}
</script>

<style scoped>

</style>
