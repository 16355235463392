<template>
  <form>
    <v-text-field
      v-model="form.title"
      label="Заголовок"
    />
    <v-card>
      <v-card-title>Структура настройки</v-card-title>
      <div class="ml-2">
        <h4>Вид отображения</h4>
        <v-radio-group row v-model="mode" class="mt-0 pt-0">
          <v-radio label="Деревом" value="tree"/>
          <v-radio label="Кодом" value="code"/>
          <v-radio label="Формой" value="form"/>
          <v-radio label="Текстом" value="text"/>
        </v-radio-group>
      </div>
      <v-card-text class="pl-0 pr-0 pb-0">
        <VJsonEditor
          v-model="form.settings"
          :plus="true"
          class="mb-2"
          :options="options"
          ref="jsonEditor"
        />
      </v-card-text>
    </v-card>
    <v-btn
      @click="submit"
      block color="primary"
      class="mt-3"
      :loading="isButtonDisabled"
      :disabled="isButtonDisabled"
    >
      Сохранить
    </v-btn>
  </form>
</template>

<script>
import VJsonEditor from 'v-jsoneditor/src/index';
import {mapGetters} from "vuex";

export default {
  name: "BlockSettingForm",
  components: {VJsonEditor},
  props: {
    blockSetting: {
      type: Object,
      default: () => ({}),
    }
  },
  data: () => ({
    form: {
      title: null,
      settings: null,
    },
    options: {
      mode: "tree"
    },
    mode: "tree",
  }),
  computed: {
    ...mapGetters({
      isButtonDisabled: 'BlockSettings/isSaveButtonDisabled',
    })
  },
  methods: {
    submit() {
      this.$emit('submit', this.form);
    }
  },
  watch: {
    mode: {
      handler (val) {
        this.$refs.jsonEditor.$options.propsData.options.mode = val;
      }
    },
    blockSetting: {
      handler() {
        if (this.blockSetting && Object.keys(this.blockSetting).length > 0) {
          this.form = { ...this.form, ...JSON.parse(JSON.stringify(this.blockSetting)) };
        }
      }
    }
  }
}
</script>

<style scoped>
.jsoneditor-container{
  height: 800px;
}
</style>
