<template>
  <div>
    <v-row>
      <v-col>
        <h1>Создание урока</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <lesson-form
          @onSubmit="submit"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LessonForm from '../../../components/lessons/LessonForm';
import {mapActions} from "vuex";
import {Media} from "../../../models/media";

export default {
  name: 'Create',
  components: { LessonForm },
  methods: {
    ...mapActions({
      storeItem: 'Lessons/storeItem',
    }),
    async submit({ form, mediaIds, isNeedRedirect, media }) {
        if (mediaIds) {
          form.mediaIds = mediaIds;
        }

        await this.storeItem(form).then(async (newID) => {
          if (newID) {
            await Media.$query().store({...media, entity_id: newID});
            if (isNeedRedirect) {
              this.$router.push({ name: 'lessons-index', params: { course_id: this.$route.params.course_id } });
            } else {
              this.$router.push({ name: 'lessons-edit', params: { course_id: this.$route.params.course_id, id: newID } });
            }
          }
        });
    },
  },
};
</script>
