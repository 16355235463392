import Index from '../../views/main/reviews/types/Index'
import Create from '../../views/main/reviews/types/Create'
import Edit from '../../views/main/reviews/types/Edit';

export default [
  {
    component: Index,
    name: 'review-types-index',
    path: '/reviews/types',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'review_types',
    }
  },
  {
    component: Create,
    name: 'review-types-create',
    path: '/reviews/types/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'review_types',
    }
  },
  {
    component: Edit,
    name: 'review-types-edit',
    path: '/reviews/types/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'review_types',
    }
  },
]
