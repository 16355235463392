<template>
  <div>
    <h1>Редактирование {{ fetchedGroup.title }}</h1>
    <div>
      <FieldGroupForm :group="fetchedGroup" @onSubmit="onSubmit"/>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import FieldGroupForm from "../../../components/fieldGroups/FieldGroupForm";

export default {
  name: "Edit",
  components: {FieldGroupForm},
  computed: {
    ...mapGetters({
      fetchedGroup: "FieldGroups/getFetchedDetail",
    })
  },
  created() {
    this.fetchDetail(this.$route.params.id);
  },
  methods: {
    ...mapActions({
      fetchDetail: "FieldGroups/fetchDetail",
      updateItem: "FieldGroups/updateItem"
    }),
    onSubmit(form) {
      this.updateItem(form);
    }
  }
}
</script>

<style scoped>

</style>
