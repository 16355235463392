<template>
  <v-dialog v-model="show" max-width="800">
    <v-card>
      <v-card>
        <v-card-title class="justify-center">
          <div class="text-center">
            Сортировка тизеров
          </div>
        </v-card-title>
        <v-card-text>
          <div>
            <v-list>
              <v-list-item-group color="indigo">
                <draggable
                  :list="items"
                >
                  <v-card
                    class="d-flex"
                    v-for="(element, index) in items"
                    :key="element.id">

                    <v-card-text class="mr-auto text-h5 black--text">
                      {{element.title}}
                    </v-card-text>
                    <v-card-text class="text-right black--text">
                      <v-row>
                        Старая позиция: {{element.position === null ? '-' : element.position + 1}}
                      </v-row>
                      <v-row>
                        Новая позиция:{{index + 1}}
                      </v-row>
                    </v-card-text>
                  </v-card>
                </draggable>
              </v-list-item-group>
            </v-list>
          </div>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            @click="show=false"
            text
            color="green darken-1"
          >Закрыть
          </v-btn>
          <v-btn
            @click="submitSort"
            text
            color="green darken-1"
          >Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import draggable from "vuedraggable";
import {Teaser} from "../../models/teaser";
import axiosInstance from "../../axiosInstance";


export default {
  name: "SortableTeaser",
  components: {
    draggable,
  },
  props: {
    value: Boolean,
  },
  data: () => ({
    items: [],
  }),
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value);
      }
    },
  },
  methods: {
    async submitSort() {
      let formattedData = {};

      this.items.forEach((elem, index) => {
        formattedData[elem.id] = { position: index }
      });

      await axiosInstance.patch('/module/teasers/batch', { resources: formattedData }).then(() => {
        this.show = false;
      });
    },
    async get() {
      await Teaser.$query().get(100).then((response) => {
        this.items = response.map(item => item.$attributes);
        this.items.sort(function (a, b) {
          return (a.position === null) - (b.position === null) || +(a.position > b.position) || -(a.position < b.position);
        });
      });
    },
  },
  created() {
    this.get();
  },
}
</script>

<style scoped>

</style>
