<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <form>
      <validation-provider v-slot="{ errors }" name="Название" rules="required">
        <v-text-field
          v-model="form.title"
          :error-messages="errors"
          label="Название"
        />
      </validation-provider>
      <validation-provider v-slot="{ errors }" name="Slug" rules="required">
        <v-text-field
          v-model="form.slug"
          :disabled="form.id ? true : false"
          :error-messages="errors"
          label="slug"
        />
      </validation-provider>
      <validation-provider v-slot="{ errors }" name="Статус" rules="required">
        <v-select label="Статус" :items="getStatuses" item-value="value" item-text="label" v-model="form.status" :error-messages="errors"/>
      </validation-provider>
      <validation-provider v-slot="{ errors }" name="Иконка(mdi)" rules="required">
        <v-text-field v-model="form.icon" label="Иконка(mdi)" :error-messages="errors"/>
      </validation-provider>
      <v-btn :loading="isLoading" :disabled="isLoading" color="primary" @click="submit(true)">Сохранить</v-btn>
      <v-btn :loading="isLoading" :disabled="isLoading" color="primary" class="ml-2" @click="submit(false)">Сохранить и продолжить</v-btn>
    </form>
  </validation-observer>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import slugify from "slugify";

export default {
  name: "CatalogForm",
  data: () => ({
    form: {
      title: '',
      slug: '',
      status: null,
      icon: '',
    }
  }),
  props: {
    catalog: {
      type: Object,
      default: () => ({})
    }
  },
  created() {
    this.fetchStatuses()
  },
  computed: {
    ...mapGetters({
      getStatuses: "Constants/getStatuses",
      isLoading: "Catalog/getIsLoading",
    })
  },
  methods: {
    ...mapActions({
      fetchStatuses: "Constants/fetchStatuses",
    }),
    submit(isNeedRedirect) {
      this.$refs.observer.validate().then((response) => {
        if (response) {
          this.$emit('onSubmit', {
            form: this.form,
            isNeedRedirect: isNeedRedirect,
          })
        }
      });
    }
  },
  watch: {
    'form.title': {
      handler(value) {
        if (value && !this.form.id) {
          this.form.slug = slugify(value.toLowerCase()).match(/[a-z]|[0-9]|[-]/gm).join('');
        }
      }
    },
    catalog: {
      handler(value) {
        if (Object.keys(value).length > 0) {
          this.form = JSON.parse(JSON.stringify(value));
        }
      }
    }
  },
}
</script>

<style scoped>

</style>
