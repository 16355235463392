<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <h1>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    small
                    :href="backUrl"
                    v-bind="attrs"
                    v-on="on"
                    style="text-decoration: none"
                    color="primary"
                  >
                    <v-icon color="white">
                      mdi-arrow-left
                    </v-icon>
                  </v-btn>
                </template>
                <span>Вернутся назад</span>
              </v-tooltip>
              Сортировка персон
            </h1>
          </v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item-group>
                <draggable v-model="sortableItems">
                  <v-card
                    class="d-flex"
                    v-for="(element, index) in sortableItems"
                    :key="element.id"
                  >
                    <v-card-text class="mr-auto black--text">
                      {{element.full_name}}
                    </v-card-text>
                    <v-card-text class="text-right black--text">
                      <v-row>
                        Старая позиция: {{ element.position === null ? '-' : element.position + 1 }}
                      </v-row>
                      <v-row>
                        Новая позиция: {{ index + 1 }}
                      </v-row>
                    </v-card-text>
                  </v-card>
                </draggable>
              </v-list-item-group>
            </v-list>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" class="ml-2" @click="submitSort">
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import draggable from 'vuedraggable';

export default {
  name: "Sort",
  components: {
    draggable,
  },
  data: () => ({
    page: {
      title: "Сортировка персон"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Персоны",
        disabled: true,
        to: '/persons'
      },
      {
        text: "Сортировка",
        disabled: true,
        to: '/persons/sort'
      },
    ]
  }),
  computed: {
    ...mapGetters({
      fetchedItems: 'Persons/getListingWithoutFilters',
    }),
    sortableItems: {
      get() {
        return this.fetchedItems;
      },
      set(val) {
        this.setSortableList(val);
      }
    },
    backUrl() {
      return this.$router.resolve({name: 'persons-index'}).href;
    }
  },
  created() {
    this.fetchItems();
  },
  methods: {
    ...mapMutations({
      setSortableList: 'Persons/setListingWithoutFilters',
    }),
    ...mapActions({
      fetchItems: 'Persons/fetchListingWithoutFilters',
      submitSort: 'Persons/submitSort',
    }),
  }
}
</script>

<style scoped>

</style>
