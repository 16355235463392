<template>
 <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <div class="text-center">
        <v-card>
            <v-card-text class="pa-5">
                <v-col cols="12" lg="12">
                    <v-data-table
                        :headers="headers"
                        :items="filteredBlocks"
                        :items-per-page="15"
                        :footer-props="{ 'items-per-page-options': [5, 10, 15, 20, -1] }"
                        >
                 
                        <template v-slot:item.position_0="{ item }">
                          <v-chip :color="getColor(item.position_0 ,'position_0')" dark>
                            {{ item.position_0 }}
                          </v-chip>
                        </template>

                        <template v-slot:item.position_1="{ item }">
                          <v-chip :color="getColor(item.position_1 ,'position_1')" dark>
                            {{ item.position_1 }}
                          </v-chip>
                        </template>

                        <template v-slot:item.position_2="{ item }">
                          <v-chip :color="getColor(item.position_2 ,'position_2')" dark>
                            {{ item.position_2 }}
                          </v-chip>
                        </template>

                        <template v-slot:item.position_3="{ item }">
                          <v-chip :color="getColor(item.position_3,'position_3')" dark>
                            {{ item.position_3 }}
                          </v-chip>
                        </template>

                        <template v-slot:item.position_4="{ item }">
                          <v-chip :color="getColor(item.position_4,'position_4')" dark>
                            {{ item.position_4 }}
                          </v-chip>
                        </template>

                        <template v-slot:item.position_5="{ item }">
                          <v-chip :color="getColor(item.position_5,'position_5')" dark>
                            {{ item.position_5 }}
                          </v-chip>
                        </template>

                        <template v-slot:item.position_6="{ item }">
                          <v-chip :color="getColor(item.position_6,'position_6')" dark>
                            {{ item.position_6 }}
                          </v-chip>
                        </template>

                        <template v-slot:item.position_7="{ item }">
                          <v-chip :color="getColor(item.position_7,'position_7')" dark>
                            {{ item.position_7 }}
                          </v-chip>
                        </template>

                        <template v-slot:item.position_8="{ item }">
                          <v-chip :color="getColor(item.position_8,'position_8')" dark>
                            {{ item.position_8 }}
                          </v-chip>
                        </template>

                        <template v-slot:item.position_9="{ item }">
                          <v-chip :color="getColor(item.position_9,'position_9')" dark>
                            {{ item.position_9 }}
                          </v-chip>
                        </template>

                        <template v-slot:top>
                          <v-toolbar flat class="mb-8">
                            <v-select class="col-sm-12"
                              v-if="users"
                              v-model="users.full_name"
                              :items="users"
                              item-value="id"
                              item-text="full_name"
                              label="Данные по пользователям"
                              name="users"  
                              @input="getInfoById"                            
                            ></v-select>
                            <v-select class="col-sm-12"
                              v-if="positions"
                              v-model="positions.text"
                              :items="positions"
                              item-value="value"
                              item-text="text"
                              label="Получить топ 3 для позиции" 
                              @input="getTop"                            
                            ></v-select>
                            <v-spacer></v-spacer>
                            <v-btn
                                    color="primary"
                                    dark
                                    to="/blocks/"
                                    class="mb-2 text-capitalize mr-2"
                                >
                                    Назад
                            </v-btn>                  
                          </v-toolbar>                          
                          <v-toolbar flat class="mb-12">                            
                              <v-row no-gutters>
                                <v-col md="12" align="left">
                                  <v-icon :color="getLegendColor('colorDark')">mdi-checkbox-blank-circle</v-icon>
                                  <span class="ml-4">Самые популярные блоки</span>
                                </v-col>
                                <v-col md="12" class="mt-1" align="left">
                                  <v-icon :color="getLegendColor('colorMediumDark')">mdi-checkbox-blank-circle</v-icon>
                                  <span class="ml-4">Популярные блоки</span>
                                </v-col>  
                                <v-col md="12" class="mt-1" align="left">
                                  <v-icon :color="getLegendColor('colorMediumLight')">mdi-checkbox-blank-circle</v-icon>
                                  <span class="ml-4">Часто используемые блоки</span>
                                </v-col>
                                <v-col md="12" class="mt-1" align="left">
                                  <v-icon :color="getLegendColor('colorLight')">mdi-checkbox-blank-circle</v-icon>
                                  <span class="ml-4">Редко используемые блоки</span>
                                </v-col>   
                                <v-col md="12" class="mt-1" align="left">
                                  <v-icon color="#c5c2c1">mdi-checkbox-blank-circle</v-icon>
                                  <span class="ml-4">Неиспользуемые блоки</span>
                                </v-col>                               
                              </v-row> 
                                                                   
                          </v-toolbar>
                      </template>
                    </v-data-table>
                </v-col>
            </v-card-text>
        </v-card>
    </div>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import axiosInstance from "../../../axiosInstance";
import colors from "../../../../public/colors.js";

export default { 
  props: ["value"],
  computed: {
    popup: {
      get (){
        return this.value;
      },
      set (value){
        this.$emit('input',value);
      },
    },
    ...mapGetters({
      fetchedPopularBlocks: 'Blocks/getfetchedPopularBlocks',
    })
  },
  data: () => ({
    positions: [
      { text: 'Позиция 1', value: 'position_0' },
      { text: 'Позиция 2', value: 'position_1' },
      { text: 'Позиция 3', value: 'position_2' },
      { text: 'Позиция 4', value: 'position_3' },
      { text: 'Позиция 5', value: 'position_4' },
      { text: 'Позиция 6', value: 'position_5' },
      { text: 'Позиция 7', value: 'position_6' },
      { text: 'Позиция 8', value: 'position_7' },
      { text: 'Позиция 9', value: 'position_8' },
      { text: 'Позиция 10', value: 'position_9' },
    ],
    headers: [
    { text: 'Id', value: 'component' },
    { text: 'Позиция 1', value: 'position_0' },
    { text: 'Позиция 2', value: 'position_1' },
    { text: 'Позиция 3', value: 'position_2' },
    { text: 'Позиция 4', value: 'position_3' },
    { text: 'Позиция 5', value: 'position_4' },
    { text: 'Позиция 6', value: 'position_5' },
    { text: 'Позиция 7', value: 'position_6' },
    { text: 'Позиция 8', value: 'position_7' },
    { text: 'Позиция 9', value: 'position_8' },
    { text: 'Позиция 10', value: 'position_9' },
    ],
    colorValues: [],
    users: [],
    filteredBlocks: [],    
    page: {
      title: "Популярные блоки"
    },
    breadcrumbs: [
        {
            text: "Админ панель",
            disabled: false,
            to: '/'
        },
        {
            text: "Блоки",
            disabled: false,
            to: '/blocks'
        },
        {
            text: "Популярные блоки",
            disabled: true,
            to: '/blocks/popular-blocks'
        },
    ],    
  }),
  async created() {
    this.fetchPopularBlocks();
    this.getUsers();
  },
  methods: {
    ...mapActions({
      fetchPopularBlocks: 'Blocks/fetchPopularBlocks',
    }),
    getTopPositions(count, key){
      
      if(this.fetchedPopularBlocks.length)
      {
        let arr = this.fetchedPopularBlocks.map(element => element[key]);     
        arr.sort((a, b) => a < b ? 1 : a > b ? -1 : 0);           
        let filterValue = arr.slice(0, count)[count-1];        
        let result = this.fetchedPopularBlocks.filter(element => {return element[key] >= filterValue && element[key] > 0});
      
        return result;
      }
      
      return [];
    },
    getColorsPosition(key){
      if(this.fetchedPopularBlocks)
      {
        let arr = this.fetchedPopularBlocks.map(element => element[key]);  
        arr.sort((a, b) => a < b ? 1 : a > b ? -1 : 0);           
        let filterValue = arr.slice(0, 3)[2];
        let result = arr.filter(element => {return element < filterValue})

        result = this.chunkArray(result,Math.ceil(result.length/3)).map(element => element[0]);        
        result.unshift(filterValue)
        
        return result;
      }
      
    },
    getColorsPositions(){
      this.headers.forEach(element => this.colorValues[element.value] = this.getColorsPosition(element.value));
    },
    getColorsRate(value){
      if(this.colorValues){
        return this.colorValues[value];
      }
      return 0;
    },
    async getUsers() {       

      let creators = await axiosInstance.get(`/pages/get-page-creators/`);

      let filter = 
        {
          "filters": [
            {
              "field" : "id",
              "operator" : "in",
              "value" : creators.data.map(element => element.creator_id),
            },            
          ],
        };
      if(creators.data)
      {
        (await axiosInstance.post(`/users/search?limit=100`,  filter,
          ).then((res) => {
            this.users = res.data.data;
          }));        
      }
    },    
    chunkArray(arr, len) {
      var chunks = [], i = 0, n = arr.length;
      while (i < n) {
        chunks.push(arr.slice(i, i += len));
      }
      return chunks;
    },
    getColor (value, key) {
      if(value == 0)      
        return '#c5c2c1';

      let colorValues = this.getColorsRate(key);
      if(colorValues){
        if (value >= colorValues[0]) return colors.colorDark
        else if (value >= colorValues[1]) return colors.colorMediumDark
        else if (value >= colorValues[2]) return colors.colorMediumLight
        else return colors.colorLight
      }
    },
    getInfoById(value){
      this.fetchPopularBlocks(value);
    },
    getTop(value){
      this.filteredBlocks  = this.getTopPositions(3, value);     
    },
    getLegendColor(color){
      switch(color) {
        case 'colorDark': 
            return colors.colorDark;
        case 'colorMediumDark': 
            return colors.colorMediumDark;
        case 'colorMediumLight': 
            return colors.colorMediumLight;
        case 'colorLight': 
            return colors.colorLight;
      }
      return "red";
    }
  },
  watch: {
    'fetchedPopularBlocks': {
      handler() {
        this.filteredBlocks = this.fetchedPopularBlocks;        
        this.getColorsPositions();
      }
    },
  }
};
</script>

<style scoped>

</style>
