var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"down-top-padding",attrs:{"fluid":""}},[_c('BaseBreadcrumb',{attrs:{"title":_vm.page.title,"icon":_vm.page.icon,"breadcrumbs":_vm.breadcrumbs}}),_c('v-card',[_c('v-card-text',{staticClass:"pa-5"},[_c('vxe-toolbar',{scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('vxe-button',{attrs:{"status":"primary"},on:{"click":_vm.addItem}},[_c('div',[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v(" mdi-plus ")]),_vm._v(" Добавить ")],1)]),_c('vxe-button',{attrs:{"status":"success"},on:{"click":_vm.saveItems}},[_c('div',[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v(" mdi-content-save-check ")]),_vm._v(" Сохранить ")],1)]),_c('vxe-button',{attrs:{"status":"danger"},on:{"click":_vm.removeItems}},[_c('div',[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v(" mdi-delete ")]),_vm._v(" Удалить ")],1)])]},proxy:true}])}),_c('vxe-grid',{ref:"table",attrs:{"border":"","resizable":"","keep-source":"","empty-text":"Данные отсутствуют","id":"table","height":"500","row-key":"","loading":_vm.loading,"columns":_vm.tableColumns,"edit-config":_vm.editConfig,"column-config":{ isCurrent:true, isHover: true, selected: true },"seq-config":{ seqMethod: _vm.seqMethod }},scopedSlots:_vm._u([{key:"default_drag_and_drop",fn:function(){return [_c('span',{staticClass:"drag-btn"},[_c('i',{staticClass:"vxe-icon--menu"})])]},proxy:true},{key:"default_header",fn:function(){return [_c('vxe-tooltip',{attrs:{"content":"Вы можете использовать Drag and drop","ENTERABLE":""},model:{value:(_vm.showTooltip),callback:function ($$v) {_vm.showTooltip=$$v},expression:"showTooltip"}},[_c('i',{staticClass:"vxe-icon--question",on:{"click":function($event){_vm.showTooltip = !_vm.showTooltip}}})])]},proxy:true},{key:"title_edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{model:{value:(row.title),callback:function ($$v) {_vm.$set(row, "title", $$v)},expression:"row.title"}})]}},{key:"position_edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{model:{value:(row.position),callback:function ($$v) {_vm.$set(row, "position", $$v)},expression:"row.position"}})]}},{key:"field_name_edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{model:{value:(row.field_name),callback:function ($$v) {_vm.$set(row, "field_name", $$v)},expression:"row.field_name"}})]}},{key:"type_edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-select',{attrs:{"placement":"bottom"},model:{value:(row.type),callback:function ($$v) {_vm.$set(row, "type", $$v)},expression:"row.type"}},_vm._l((_vm.fieldTypes),function(item){return _c('vxe-option',{key:item.value,attrs:{"value":item.value,"label":item.label}})}),1)]}},{key:"edit_extra_options",fn:function(ref){
var row = ref.row;
return [_c('vxe-textarea',{attrs:{"value":JSON.stringify(row.extra_options)},on:{"input":function (event) { return row.extra_options = JSON.parse(event.value); }}})]}},{key:"default_type",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"vxe-cell"},[_c('span',{staticClass:"vxe-cell--label"},[_vm._v(_vm._s(_vm.getFieldType(row.type)))])])]}},{key:"default_extra_options",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('span',[_vm._v("Войдите в режим редактирования для просмотра настроек")])])]}}])})],1),_c('vxe-modal',{attrs:{"width":"800","min-width":"600","min-height":"300","title":_vm.selectedRow.title},scopedSlots:_vm._u([(_vm.selectedRow.type)?{key:"default",fn:function(){return [_c(_vm.getComponent(_vm.selectedRow.type),{tag:"component",attrs:{"form-data":_vm.selectedRow.extra_options},on:{"onSubmit":_vm.onSubmitHandler}})]},proxy:true}:null],null,true),model:{value:(_vm.showEdit),callback:function ($$v) {_vm.showEdit=$$v},expression:"showEdit"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }