<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <role-form
              :errors="errors"
              @onSubmit="submit"
            />
          </v-col>
        </v-row>
        <v-snackbar
          v-model="snackbarSuccess"
          :timeout="timeout"
          color="success"
        >
          Сохранено

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarSuccess = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar
          v-model="snackbarError"
          :timeout="timeout"
          color="error"
        >
          {{ this.errors }}

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarError = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import RoleForm from '../../../components/roles/RoleForm';
import {Role} from "../../../models/role";

export default {
  name: "Create",
  components: {RoleForm},
  data: () => ({
    errors: {},
    snackbarSuccess: false,
    snackbarError: false,
    timeout: 2000,
    page: {
      title: "Создание роли"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Роли",
        disabled: true,
        to: '/roles'
      },
      {
        text: "Создание",
        disabled: true,
        to: '/roles/create'
      },
    ]
  }),
  methods: {
    submit(permissions, roleName) {
      Role.$query().store(roleName.$attributes).then(
        role => {
          this.snackbarSuccess = true;
          role.permissions().sync(permissions.map(permission => permission.$attributes.id));
          this.$router.push({name: 'roles-index'});
        }
      ).catch(() => {
          this.errors.name = "This role is already exist";
          this.snackbarError = true;
        }
      );
    }
  }
}
</script>
