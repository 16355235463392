<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col class="col-10">
            <vue-json-compare
              :old-data="history.before"
              :new-data="history.after"
            ></vue-json-compare>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {History} from "../../../models/history";
import vueJsonCompare from 'vue-json-compare';

export default {
  name: "Show",
  components: {vueJsonCompare},
  data: () => {
    return {
      history: {},
      page: {
        title: "История"
      },
      breadcrumbs: [
        {
          text: "Админ панель",
          disabled: false,
          to: '/'
        },
        {
          text: "История",
          disabled: true,
          to: '/histories'
        },
        {
          text: `История`,
          disabled: true,
          to: '/histories/show'
        },
      ]
    }
  },
  created() {
    this.find();
  },
  methods: {
    async find() {
      const history = await History.$query().find(this.$route.params.id);
      this.history = history.$attributes;
    },
  },
  watch: {
    history: {
      handler() {
        let title = `${this.history.entity.title || this.history.id}`;

        this.page.title += ` "${title}"`;
        this.breadcrumbs[2].text = title;
      }
    },
  }
}
</script>

