<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row>
      <v-col>
        <h1>Медиа библиотека</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="primary" to="/medias/create">
          Загрузить файл
        </v-btn>
      </v-col>
    </v-row>
    <v-tabs v-model="tab">
      <v-tab>
        Графические файлы
      </v-tab>
      <v-tab>
        Медиа файлы
      </v-tab>
      <v-tab>
        Видео файлы
      </v-tab>
      <v-tab>
        SVG-файлы
      </v-tab>
      <v-tab>
        Архивы
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-row>
          <v-col cols="3">
            <v-text-field
              v-model="query.images.search"
              label="Поиск..."
              name="title"
            >
              <v-icon slot="prepend">
                mdi-magnify
              </v-icon>
              <v-btn
                slot="append"
                icon
                @click="query.images.search = ''"
                color="red"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-row align="center">
              <v-col class="pr-0">
                <v-select
                  v-if="groups"
                  v-model="query.images.group"
                  :items="groups"
                  item-value="value"
                  item-text="label"
                  label="Выберите группу"
                  name="type"
                  prepend-icon="mdi-account"
                ></v-select>
              </v-col>
              <v-col cols="auto" class="pl-0">
                <v-btn
                  icon
                  @click="query.group = -1"
                  color="red"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="3">
            <v-row align="center">
              <v-col class="pr-0">
                <v-select
                  v-model="query.images.taxonomy"
                  :items="taxonomy"
                  label="Таксономия"
                  item-value="key"
                  item-text="value"
                  name="type"
                  prepend-icon="mdi-account"
                ></v-select>
              </v-col>
              <v-col cols="auto" class="pl-0">
                <v-btn
                  icon
                  @click="query.images.taxonomy = null"
                  color="red"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="3">
            <v-select :items="mediaCreators"
                      label="Выберите автора"
                      item-value="id"
                      item-text="full_name"
                      v-model="query.images.creator"
            />
          </v-col>
          <v-col cols="3">
            <v-menu
              v-model="datePicker1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="query.images.created_at"
                  label="Дата создания"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  append-icon="mdi-close"
                  @click:append="query.images.created_at = null"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="query.images.created_at"
                locale="ru-RU"
                range
              >
                <v-spacer/>
                <v-btn text color="primary" @click="datePicker1 = false">ОК</v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="items"
              :options.sync="options.images"
              :footer-props="{'items-per-page-options':[15, 30, 50, 100, -1]}"
              :server-items-length="total"
            >
              <template v-slot:item.preview="{ item }">
                <div style="background-color: #EEEEEE">
                  <img class="p-3" :src="item.url" alt="image">
                </div>
              </template>
              <template v-slot:item.has_taxonomy="{ item }">
                <v-icon v-if="item.has_taxonomy" color="green">
                  mdi-check-bold
                </v-icon>
                <v-icon v-else color="red">
                  mdi-close
                </v-icon>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-row>
                  <v-col>
                    <v-btn
                      :href="editItem(item)"
                      icon
                    >
                      <v-icon color="primary">
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      @click="deleteItem(item)"
                      icon
                    >
                      <v-icon
                        color="red"
                      >
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      @click="copyURL(item)"
                      icon>
                      <v-icon color="primary">
                        mdi-link
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>


              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row>
          <v-col cols="3">
            <v-text-field
              v-model="query.files.search"
              label="Поиск..."
              name="title"
            >
              <v-icon slot="prepend">
                mdi-magnify
              </v-icon>
              <v-btn
                slot="append"
                icon
                @click="query.files.search = ''"
                color="red"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-row align="center">
              <v-col class="pr-0">
                <v-select
                  v-model="query.files.taxonomy"
                  :items="taxonomy"
                  item-value="key"
                  item-text="value"
                  label="Таксономия"
                  name="type"
                  prepend-icon="mdi-account"
                ></v-select>
              </v-col>
              <v-col cols="auto" class="pl-0">
                <v-btn
                  icon
                  @click="query.files.taxonomy = null"
                  color="red"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="3">
            <v-select :items="mediaCreators"
                      label="Выберите автора"
                      item-value="id"
                      item-text="full_name"
                      v-model="query.files.creator"
            />
          </v-col>
          <v-col cols="3">
            <v-menu
              v-model="datePicker2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="query.files.created_at"
                  label="Дата создания"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  append-icon="mdi-close"
                  @click:append="query.files.created_at = null"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="query.files.created_at"
                range
                locale="ru-RU"
              >
                <v-spacer/>
                <v-btn text color="primary" @click="datePicker2 = false">ОК</v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="items"
              :options.sync="options.files"
              :footer-props="{'items-per-page-options':[15, 30, 50, 100, -1]}"
              :server-items-length="total"
            >
              <template v-slot:item.preview="{ item }">
                <img :src="item.file_image_preview" alt="image">
              </template>
              <template v-slot:item.has_taxonomy="{ item }">
                <v-icon v-if="item.has_taxonomy" color="green">
                  mdi-check-bold
                </v-icon>
                <v-icon v-else color="red">
                  mdi-close
                </v-icon>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-row>
                  <v-col>
                    <v-btn
                      :href="editItem(item)"
                      icon
                    >
                      <v-icon color="primary">
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      @click="deleteItem(item)"
                      icon
                    >
                      <v-icon
                        color="red"
                      >
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      @click="copyURL(item)"
                      icon>
                      <v-icon color="primary">
                        mdi-link
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row>
          <v-col cols="3">
            <v-text-field
              v-model="query.videos.search"
              label="Поиск..."
              name="title"
            >
              <v-icon slot="prepend">
                mdi-magnify
              </v-icon>
              <v-btn
                slot="append"
                icon
                @click="query.videos.search = ''"
                color="red"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-select :items="mediaCreators"
                      label="Выберите автора"
                      item-value="id"
                      item-text="full_name"
                      v-model="query.videos.creator"
            />
          </v-col>
          <v-col cols="3">
            <v-menu
              v-model="datePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="query.videos.created_at"
                  label="Дата создания"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  append-icon="mdi-close"
                  @click:append="query.videos.created_at = null"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="query.videos.created_at"
                range
                locale="ru-RU"
              >
                <v-btn text color="primary" @click="datePicker = false">ОК</v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="3">
            <v-row align="center">
              <v-col class="pr-0">
                <v-select
                  item-value="key"
                  item-text="value"
                  v-model="query.videos.taxonomy"
                  :items="taxonomy"
                  label="Таксономия"
                  name="type"
                  prepend-icon="mdi-account"
                ></v-select>
              </v-col>
              <v-col cols="auto" class="pl-0">
                <v-btn
                  icon
                  @click="query.videos.taxonomy = null"
                  color="red"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="items"
              :options.sync="options.videos"
              :footer-props="{'items-per-page-options':[15, 30, 50, 100, -1]}"
              :server-items-length="total"
            >
              <template v-slot:item.preview="{ item }">
                <img :src="item.file_image_preview" alt="image">
              </template>
              <template v-slot:item.has_taxonomy="{ item }">
                <v-icon v-if="item.has_taxonomy" color="green">
                  mdi-check-bold
                </v-icon>
                <v-icon v-else color="red">
                  mdi-close
                </v-icon>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-row>
                  <v-col>
                    <v-btn
                      :href="editItem(item)"
                      icon
                    >
                      <v-icon color="primary">
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      @click="deleteItem(item)"
                      icon
                    >
                      <v-icon
                        color="red"
                      >
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      @click="copyURL(item)"
                      icon>
                      <v-icon color="primary">
                        mdi-link
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row>
          <v-col cols="3">
            <v-text-field
              v-model="query.svg.search"
              label="Поиск..."
              name="title"
            >
              <v-icon slot="prepend">
                mdi-magnify
              </v-icon>
              <v-btn
                slot="append"
                icon
                @click="query.svg.search = ''"
                color="red"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-select :items="mediaCreators"
                      label="Выберите автора"
                      item-value="id"
                      item-text="full_name"
                      v-model="query.svg.creator"
            />
          </v-col>
          <v-col cols="3">
            <v-row align="center">
              <v-col class="pr-0">
                <v-select
                  v-if="groups"
                  v-model="query.svg.group"
                  :items="groups"
                  item-value="value"
                  item-text="label"
                  label="Выберите группу"
                  name="type"
                  prepend-icon="mdi-account"
                ></v-select>
              </v-col>
              <v-col cols="auto" class="pl-0">
                <v-btn
                  icon
                  @click="query.group = -1"
                  color="red"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="3">
            <v-menu
              v-model="datePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="query.videos.created_at"
                  label="Дата создания"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  append-icon="mdi-close"
                  @click:append="query.videos.created_at = null"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="query.videos.created_at"
                range
                locale="ru-RU"
              >
                <v-btn text color="primary" @click="datePicker = false">ОК</v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="3">
            <v-row align="center">
              <v-col class="pr-0">
                <v-select
                  item-value="key"
                  item-text="value"
                  v-model="query.svg.taxonomy"
                  :items="taxonomy"
                  label="Таксономия"
                  name="type"
                  prepend-icon="mdi-account"
                ></v-select>
              </v-col>
              <v-col cols="auto" class="pl-0">
                <v-btn
                  icon
                  @click="query.videos.taxonomy = null"
                  color="red"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="items"
              :options.sync="options.svg"
              :footer-props="{'items-per-page-options':[15, 30, 50, 100, -1]}"
              :server-items-length="total"
            >
              <template v-slot:item.preview="{ item }">
                <img :src="item.url" alt="image">
              </template>
              <template v-slot:item.has_taxonomy="{ item }">
                <v-icon v-if="item.has_taxonomy" color="green">
                  mdi-check-bold
                </v-icon>
                <v-icon v-else color="red">
                  mdi-close
                </v-icon>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-row>
                  <v-col>
                    <v-btn
                      :href="editItem(item)"
                      icon
                    >
                      <v-icon color="primary">
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      @click="deleteItem(item)"
                      icon
                    >
                      <v-icon
                        color="red"
                      >
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      @click="copyURL(item)"
                      icon>
                      <v-icon color="primary">
                        mdi-link
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row>
          <v-col cols="3">
            <v-text-field
              v-model="query.zip.search"
              label="Поиск..."
              name="title"
            >
              <v-icon slot="prepend">
                mdi-magnify
              </v-icon>
              <v-btn
                slot="append"
                icon
                @click="query.zip.search = ''"
                color="red"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-select :items="mediaCreators"
                      label="Выберите автора"
                      item-value="id"
                      item-text="full_name"
                      v-model="query.zip.creator"
            />
          </v-col>
          <v-col cols="3">
            <v-row align="center">
              <v-col class="pr-0">
                <v-select
                  v-if="groups"
                  v-model="query.zip.group"
                  :items="groups"
                  item-value="value"
                  item-text="label"
                  label="Выберите группу"
                  name="type"
                  prepend-icon="mdi-account"
                ></v-select>
              </v-col>
              <v-col cols="auto" class="pl-0">
                <v-btn
                  icon
                  @click="query.group = -1"
                  color="red"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="3">
            <v-menu
              v-model="datePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="query.zip.created_at"
                  label="Дата создания"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  append-icon="mdi-close"
                  @click:append="query.zip.created_at = null"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="query.zip.created_at"
                range
                locale="ru-RU"
              >
                <v-btn text color="primary" @click="datePicker = false">ОК</v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="3">
            <v-row align="center">
              <v-col class="pr-0">
                <v-select
                  item-value="key"
                  item-text="value"
                  v-model="query.zip.taxonomy"
                  :items="taxonomy"
                  label="Таксономия"
                  name="type"
                  prepend-icon="mdi-account"
                ></v-select>
              </v-col>
              <v-col cols="auto" class="pl-0">
                <v-btn
                  icon
                  @click="query.zip.taxonomy = null"
                  color="red"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="items"
              :options.sync="options.zip"
              :footer-props="{'items-per-page-options':[15, 30, 50, 100, -1]}"
              :server-items-length="total"
            >
              <template v-slot:item.preview="{ item }">
                <img :src="item.file_image_preview" alt="image">
              </template>
              <template v-slot:item.has_taxonomy="{ item }">
                <v-icon v-if="item.has_taxonomy" color="green">
                  mdi-check-bold
                </v-icon>
                <v-icon v-else color="red">
                  mdi-close
                </v-icon>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-row>
                  <v-col>
                    <v-btn
                      :href="editItem(item)"
                      icon
                    >
                      <v-icon color="primary">
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      @click="deleteItem(item)"
                      icon
                    >
                      <v-icon
                        color="red"
                      >
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      @click="copyURL(item)"
                      icon>
                      <v-icon color="primary">
                        mdi-link
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>

  </v-container>
</template>

<script>
import axiosInstance from '../../../axiosInstance';
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: 'Index',
  data: () => ({
    options: {
      images: {
        sortBy: ['id'],
        sortDesc: [true],
        itemsPerPage:30
      },
      files: {
        sortBy: ['id'],
        sortDesc: [true],
        itemsPerPage:30
      },
      videos: {
        sortBy: ['id'],
        sortDesc: [true],
        itemsPerPage: 30,
      },
      svg: {
        sortBy: ['id'],
        sortDesc: [true],
        itemsPerPage: 30,
      },
      zip: {
        sortBy: ['id'],
        sortDesc: [true],
        itemsPerPage: 30,
      }
    },
    datePicker: false,
    datePicker1: false,
    datePicker2: false,
    headers: [
      {text: 'ID', value: 'id'},
      {text: 'Изображение', value: 'preview', sortable: false},
      {text: 'Название', value: 'title'},
      {text: 'Описание', value: 'description'},
      {text: 'Ключевые слова', value: 'key_words'},
      {text: 'Таксономия', value: 'has_taxonomy'},
      {text: 'Дата создания', value: 'created_at'},
      {text: 'Кем создано', value: 'creator_name', sortable: false},
      {text: '', value: 'actions', sortable: false},
    ],
    taxonomy: [
      { key: null, value: 'Все' },
      { key: true, value: 'Имеют таксономию' },
      { key: false, value: 'Не имеют таксономию' },
    ],
    tab: 0,
    query: {
      images: {
        group: -1,
        file_type: 0,
        created_by: null,
        creator: null,
        created_at: null,
        taxonomy: null,
        search: ''
      },
      files: {
        file_type: 1,
        created_by: null,
        taxonomy: null,
        creator: null,
        created_at: null,
        search: ''
      },
      videos: {
        file_type: 2,
        created_by: null,
        taxonomy: null,
        created_at: null,
        creator: null,
        search: '',
      },
      svg: {
        group: -1,
        file_type: 3,
        created_by: null,
        taxonomy: null,
        created_at: null,
        creator: null,
        search: '',
      },
      zip: {
        group: -1,
        file_type: 4,
        created_by: null,
        taxonomy: null,
        created_at: null,
        creator: null,
        search: '',
      }
    },
    file_types: [
      {text: 'Графические файлы', value: 0},
      {text: 'Текстовые файлы', value: 1},
    ],
    fileImage: 'https://img.icons8.com/bubbles/100/000000/document.png',
    page: {
      title: "Медиа библиотека"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Медиа библиотека",
        disabled: true,
        to: '/medias'
      },
    ],
  }),
  computed: {
    ...mapGetters({
      items: "Medias/getFetchedListing",
      total: "Medias/getListingTotalItems",
      loading: "Medias/getListingTotalItems",
      mediaCreators: "Medias/getMediaCreators",
      groups: "Medias/getMediaGroups",
    }),
    listingPayload() {
      return {
        options: this.options,
        filters: this.query,
      }
    }
  },
  created() {
    this.fetchMediaGroups().then(() => {
      if (this.$route.query.file_type) {
        this.tab = Number(this.$route.query.file_type);

        let payload = {}

        if (this.tab === 1) {
          payload = { options: this.options.files, filters: this.query.files }
        } else if (this.tab === 2) {
          payload = { options: this.options.videos, filters: this.query.videos };
        }

        this.fetchListing(payload);
      } else {
        this.fetchListing({options: this.options.images, filters: this.query.images});
      }

      this.fetchMediaCreators();
    })
  },
  methods: {
    ...mapActions({
      fetchListing: "Medias/fetchListing",
      fetchMediaCreators: "Medias/fetchMediaCreators",
      fetchMediaGroups: "Medias/fetchMediaGroups",
      destroyItem: "Medias/destroyItem",
    }),
    ...mapMutations({
      setItems: "Medias/setFetchedListing",
    }),
    editItem(item) {
      return this.$router.resolve({name: 'medias-edit', params: {id: item.id}}).href
    },
    async deleteItem(item) {
      this.$confirm('Вы уверены?', 'Удаление').then(async () => {
        await axiosInstance.get(`/media/check-using/${item.id}`).then(async () => {
          this.destroyItem(item).then(() => {
            this.fetchListing(this.listingPayload);
          });
        }).catch(() => {
          this.$alert('Картинка уже используется', 'Удаление запрещено', 'error');
        })
      });
    },
    copyURL(item) {
      navigator.clipboard.writeText(item.original_url)
    }
  },
  watch: {
    'query.images': {
      handler() {
        this.fetchListing({ options: this.options.images, filters: this.query.images });
      },
      deep: true,
    },
    'query.videos': {
      handler() {
        this.fetchListing({ options: this.options.videos, filters: this.query.videos });
      },
      deep: true,
    },
    'query.files': {
      handler() {
        this.fetchListing({ options: this.options.files, filters: this.query.files });
      },
      deep: true,
    },
    'query.zip': {
      handler() {
        this.fetchListing({ options: this.options.zip, filters: this.query.zip });
      },
      deep: true,
    },
    'options.files': {
      handler() {
        this.fetchListing({ options: this.options.files, filters: this.query.files });
      },
      deep: true,
    },
    'options.videos': {
      handler() {
        this.fetchListing({ options: this.options.videos, filters: this.query.videos })
      },
      deep: true,
    },
    'options.images': {
      handler() {
        this.fetchListing({ options: this.options.images, filters: this.query.images });
      },
      deep: true,
    },
    'options.zip': {
      handler() {
        this.fetchListing({ options: this.options.zip, filters: this.query.zip });
      },
      deep: true,
    },
    tab: {
      handler(val) {
        this.setItems([]);
        if (val === 0) {
          this.fetchListing({ options: this.options.images, filters: this.query.images })
        } else if (val === 1) {
          this.fetchListing({ options: this.options.files, filters: this.query.files })
        } else if (val === 2) {
          this.fetchListing({ options: this.options.videos, filters: this.query.videos })
        } else if (val === 3) {
          this.fetchListing({ options: this.options.svg, filters: this.query.svg });
        } else if (val === 4) {
          this.fetchListing({ options: this.options.zip, filters: this.query.zip });
        }
      }
    },

  }
};
</script>
