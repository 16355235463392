import axiosInstance from "@/axiosInstance";

export default {
  namespaced: true,

  state: {
    isLoading: false,
    statsForTable: [],
    listingTotal: 0,
  },
  getters: {
    getIsLoading: state => state.isLoading,
    getStatsForTable: state => state.statsForTable,
    getListingTotal: state => state.listingTotal,
  },
  mutations: {
    setIsLoading(state, listing) {
      state.isLoading = listing;
    },
    setFetchedStatsForTable(state, listing) {
      state.statsForTable = listing;
    },
    setListingTotalItems(state, listing) {
      state.listingTotal = listing;
    }
  },
  actions: {
    async fetchStatsForTable({commit}, {options, search, only_trashed, short_urls_id}) {
      commit('setIsLoading', true);

      const {sortBy, sortDesc, page, itemsPerPage} = options;
      const onlyTrashed = only_trashed ? `&only_trashed=1` : '';

      let data = {};

      if (short_urls_id) {
        data.filters = [
          {
            field: 'short_urls_id',
            operator: "=",
            value: short_urls_id
          }
        ];
      }

      search ? data.search = {value: search} : null;
      sortBy && sortBy[0] ? data.sort = [{field: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc'}] : null;

      axiosInstance.post(`shortUrlsStats/search?limit=${itemsPerPage}&page=${page}${onlyTrashed}`, data)
        .then((response) => {
          commit('setFetchedStatsForTable', response.data.data);
          commit('setListingTotalItems', response.data.meta.total);
          commit('setIsLoading', false);
        });
    }
  }
}
