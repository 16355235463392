import Index from '../../views/main/profile/Index.vue'
import Edit from '../../views/main/profile/Edit'

export default [
  {
    component: Index,
    name: 'profile-index',
    path: '/profile',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
  {
    component: Edit,
    name: 'profile-edit',
    path: '/profile/edit',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  }
]
