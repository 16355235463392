<template>
  <v-form>
    <v-row>
      <v-col>
        <v-card>
          <v-container>
            <v-text-field
              v-model="form.meta_title"
              :error-messages="errors.meta_title"
              label="Meta title"
              name="meta_title"
              required
            ></v-text-field>
            <v-text-field
              v-model="form.meta_description"
              :error-messages="errors.meta_description"
              label="Meta description"
              name="meta_description"
              required
            ></v-text-field>
            <v-text-field
              v-model="form.meta_keywords"
              :error-messages="errors.meta_keywords"
              label="Meta keywords"
              name="meta_keywords"
              required
            ></v-text-field>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!hideButton">
      <v-col>
        <div class="seo-preview">
          <h3>Предпросмотр</h3>
          <h4 class="search-system-title"><span>Google</span></h4>
          <google-seo-widget :entity="entity" :form="form" />
          <h4 class="search-system-title"><span>Yandex</span></h4>
          <ya-seo-widget :entity="entity" :form="form" />
        </div>
        <v-flex>
          <v-btn
            class="mr-4"
            color="primary"
            @click="submit"
            :disabled="isSaveDisabled || isDisabled"
            :loading="isSaveDisabled"
          >
            Сохранить
          </v-btn>
        </v-flex>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import YaSeoWidget from './YaSeoWidget.vue';
import GoogleSeoWidget from './GoogleSeoWidget.vue';
export default {
  name: 'SEOForm',
  components: {
    YaSeoWidget,
    GoogleSeoWidget
  },
  props: {
    entity: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    hideButton: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    form: {
      id: null,
      meta_title: null,
      meta_description: null,
      meta_keywords: null,
    },
    formErrors: {},
    isSaveDisabled: false,
  }),
  created() {
    if (this.$route.params.data) {
      this.form = JSON.parse(this.$route.params.data);
    }
  },
  methods: {
    async submit() {
      this.isSaveDisabled = true;
      this.$emit('onSubmit', {form: this.form});
    },
  },
  watch: {
    entity: {
      handler(entity) {
        if (entity && Object.keys(entity).length > 0) {
          this.form = entity;
          this.isSaveDisabled = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style>
  .seo-preview {
    margin-left: 15px;
    margin-right: 15px;
  }

  h4.search-system-title {
    font-size: 14px;
    letter-spacing: 0.015em;
    color: #A3B3CA;
    font-family: "adelle-sans", sans-serif;
    position: relative;
  }

  h4.search-system-title span {
    position: relative;
    background: #fff;
    padding-right: 12px;
  }

  h4.search-system-title:before {
    content: '';
    position: absolute;
    top: 10px;
    right: 0;
    width: 100%;
    height: 1px;
    background: #E0E7F1;
  }
</style>
