<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Редактирование промокода "{{ promocode.title }}"</h1>
      </v-col>
    </v-row>
    <v-tabs
      v-model="tab"
      background-color="transparent"
      grow
      class="mb-5"
    >
    <v-tab>
      Основная информация
    </v-tab>
    <v-tab>
      Таксономия
    </v-tab>
  </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-row>
          <v-col>
            <promocode-form
              :promocode="promocode"
              :errors="errors"
              @onSubmit="submit"
            />
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <collection-relation-form
          module-name="Promocodes"
          :errors="errors"
          :selected-collection-items="promocode.selected_collection_items"
          :main-items="promocode.main_taxonomy_ids"
          :selected-courses="promocode.courses_id"
          :selected-persons="promocode.persons_id"
          @onSubmit="submitCollection"
          @onSubmitMain="submitMainCollection"
          @onSubmitCourses="submitCourses"
          @onSubmitPersons="submitPersons"
        />
      </v-tab-item>
      </v-tabs-items>
    <v-snackbar
      v-model="snackbarSuccess"
      :timeout="timeout"
      color="success"
    >
      Сохранено

      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          @click="snackbarSuccess = false"
          color="red"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackbarError"
      :timeout="timeout"
      color="error"
    >
      {{ this.errors }}

      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          @click="snackbarError = false"
          color="red"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import PromocodeForm from "../../../components/promocodes/PromocodeForm";
import {Promocode} from "../../../models/promocode";
import CollectionRelationForm from "../../../components/collections/CollectionRelationForm";
import {mapActions, mapGetters} from "vuex";


export default {
  name: 'Create',
  components: { PromocodeForm, CollectionRelationForm},
  data: () => ({
    errors: {},
    snackbarSuccess: false,
    snackbarError: false,
    timeout: 2000,
    tab: 0,
  }),
  mounted() {
    this.find();
  },
  computed: {
    ...mapGetters({
      promocode: 'Promocodes/getFetchedDetail',
    }),
  },
  methods: {
    ...mapActions({
      submitCollections: 'Promocodes/submitCollections',
      submitMainCollections: 'Promocodes/submitMainCollections',
      fetchDetail: 'Promocodes/fetchDetail',
      submitCourses: 'Promocodes/submitCourses',
      submitPersons: 'Promocodes/submitPersons',
    }),
    async submit({ form }) {
      try {
        this.errors = {};
        await Promocode.$query().update(form.id, form);
        await this.$router.push({ name: 'promocodes-index'});
        this.snackbarSuccess = true;
      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors
          this.snackbarError = true;
        }
        throw e;
      }
    },
    async find() {
      this.fetchDetail(this.$route.params.id);
    },
    async submitCollection({ items }) {
      await this.submitCollections({ items: items}).then(() => {
             });
    },
    async submitMainCollection(items) {
      await this.submitMainCollections({items: items});
    }
  },
};
</script>
