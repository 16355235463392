<template>
  <v-form>
    <v-text-field
      v-model="form.title"
      label="Название иконки"
      name="title"
      required
    />
    <v-text-field
      v-model="form.slug"
      label="Slug"
      name="slug"
      :disabled="!(Object.keys(icon).length === 0)"
      required
    >
    </v-text-field>
    <media-widget
      v-if="icon.id && icon"
      class="mb-3"
      label="Изображения"
      :medias="form.icon"
      :is-multiple="false"
      @onChange="setImage"
    />
    <v-btn
      color="primary"
      @click="submit"
    >
     Сохранить
    </v-btn>
    <media-uploader
      v-if="icon.id && icon"
      :loaded-medias="icon.loaded_medias"
      @completeUploads="onCompleteUploads"
    />
    <v-btn @click="back" class="ml-3" color="warning">
      Назад
    </v-btn>
  </v-form>
</template>

<script>
import MediaUploader from '../MediaUploader';
import MediaWidget from '../MediaWidget'
import slugify from "slugify";

export default {
  name: "FunctionalIconsForm",
  components: {MediaUploader, MediaWidget},
  component: {
    MediaUploader,
    MediaWidget,
  },
  props: {
    icon: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    form: {
      title: null,
      slug: null,
      icon: null,
    },
    mediaIds: [],
  }),
  methods: {
    async onCompleteUploads(ids) {
      this.mediaIds = ids;
      await this.submit();
      this.mediaIds = [];
    },
    submit() {
      this.$emit('onSubmit', {
        form: this.form,
        mediaIds: this.mediaIds,
      })
    },
    setImage(image){
      this.form.icon = image;
    },
    back(){
     this.$router.push({ name: 'functional-icons-index' });
    }
  },
  watch: {
    icon: {
      handler() {
        if (Object.keys(this.icon).length > 0) {
          this.form = JSON.parse(JSON.stringify(this.icon));
        }
      },
      immediate: true,
    },
    'form.title': {
      handler(value) {
        if (value && Object.keys(this.icon).length === 0) {
          this.form.slug = slugify(value.toLowerCase()).match(/[a-z]|[0-9]|[-]/gm).join('');
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
