var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"6"}},[_c('h4',[_c('b',[_vm._v("Области применения:")])]),_c('v-virtual-scroll',{attrs:{"items":_vm.library.area,"height":"300","item-height":"64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item)}})],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('h4',[_c('b',[_vm._v("Теги элемента:")])]),_c('v-virtual-scroll',{attrs:{"items":_vm.library.tags,"height":"300","item-height":"64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item)}})],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('h4',[_c('b',[_vm._v("Целевая аудитория:")])]),_c('v-virtual-scroll',{attrs:{"items":_vm.library.target,"height":"300","item-height":"64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item)}})],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('h4',[_c('b',[_vm._v("Тематика:")])]),_c('v-virtual-scroll',{attrs:{"items":_vm.library.thematic,"height":"300","item-height":"64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item)}})],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('h4',[_c('b',[_vm._v("Возраст:")])]),_c('v-virtual-scroll',{attrs:{"items":_vm.library.age,"height":"300","item-height":"64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item)}})],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('h4',[_c('b',[_vm._v("Категории:")])]),_c('v-virtual-scroll',{attrs:{"items":_vm.library.category,"height":"300","item-height":"64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item)}})],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('h4',[_c('b',[_vm._v("Содержательный тип:")])]),_c('v-virtual-scroll',{attrs:{"items":_vm.library.notes,"height":"300","item-height":"64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item)}})],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('h4',[_c('b',[_vm._v("Тип:")])]),_c('v-virtual-scroll',{attrs:{"items":_vm.library.old_type,"height":"300","item-height":"64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item)}})],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('h4',[_c('b',[_vm._v("Ключевые слова:")])]),_c('v-virtual-scroll',{attrs:{"items":_vm.library.keywords,"height":"300","item-height":"64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item)}})],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }