import Index from '../../views/main/courses/Index'
import Create from '../../views/main/courses/Create'
import Edit from '../../views/main/courses/Edit';
import Temp from '../../views/main/courses/Temp';
import Conspectus from "@/views/main/courses/Conspectus";

export default [
  {
    component: Index,
    name: 'courses-index',
    path: '/courses',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'courses',
    }
  },
  {
    component: Create,
    name: 'courses-create',
    path: '/courses/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'courses',
    }
  },
  {
    component: Edit,
    name: 'courses-edit',
    path: '/courses/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'courses',
    }
  },
  {
    component: Temp,
    name: 'courses-temp',
    path: '/courses/temp',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'courses',
    }
  },
  {
    component: Conspectus,
    name: 'conspectus',
    path: '/courses/conspectus',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'courses',
    }
  }
]
