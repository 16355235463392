<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <BlockSettingForm
              :block-setting="fetchedDetail"
              @submit="submit"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import BlockSettingForm from "../../../../components/blocks/BlockSettingForm";
export default {
  name: "Edit",
  components: {
    BlockSettingForm,
  },
  data: () => ({
    page: {
      title: "Редактирование настроек блока"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Настройки блока",
        disabled: true,
        to: '/block-settings'
      },
      {
        text: "Редактирование",
        disabled: true,
        to: '/block-settings/edit'
      },
    ]
  }),
  created() {
    this.fetchDetail(this.$route.params.id);
  },
  computed: {
    ...mapGetters({
      fetchedDetail: 'BlockSettings/getFetchedDetail',
    })
  },
  methods: {
    ...mapActions({
      fetchDetail: 'BlockSettings/fetchDetail',
      update: 'BlockSettings/updateItem',
    }),
    submit(form) {
      this.update(form).then(() => {
        this.$router.push({ name: 'block-settings-index' })
      });
    }
  },
  watch: {
    'fetchedDetail.title': {
      handler(newTitle) {
        this.page.title +=  ` "${newTitle}"`;
      }
    },
  }
}
</script>

<style scoped>

</style>
