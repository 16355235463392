<template>
  <v-container fluid class="down-top-padding">
    <!--  <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>  -->
      <v-card>
        <vxe-toolbar>
          <template #buttons>
            <vxe-button content="Добавить" class="ml-3" status="primary" @click="insertEvent"/>
            <vxe-button content="Удалить" status="danger" @click="removeEvent"/>
            <vxe-button content="Сохранить" status="success" @click="saveEvent"/>
          </template>
        </vxe-toolbar>
        <!--
        <v-expansion-panels class="mb-2 mt-2">
          <v-expansion-panel>
            <v-expansion-panel-header>Скрытие столбцов</v-expansion-panel-header>
            <v-expansion-panel-content>
              <template v-for="(column, index) in hidenColumns">
                <vxe-checkbox v-model="column.visible" :key="index" @change="$refs.xTable.refreshColumn()" class="col-2">
                  {{ column.title }}
                </vxe-checkbox>
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        -->
        <div class="dfdf" style="height:calc(100vh - 260px)">
      <vxe-table
        border
        resizable
        highlight-hover-row

        :column-config="columnConfig"
        :data.sync="fetchedData"
        :edit-config="editConfig"
        :edit-rules="validRules"
        :loading="isLoading"
        :row-config="rowConfig"
        :seq-config="{ seqMethod }"
        :menu-config="menuConfig"
        keep-source
        ref="xTable"
        empty-text="Нет данных."
        max-height="100%"
        @menu-click="contextMenuClickEvent"
      >
        <vxe-column type="checkbox" width="60" fixed="left"/>
        <vxe-column type="seq" width="60" fixed="left" title="ID"></vxe-column>
        <vxe-column field="title" title="Название" :edit-render="editRender" width="300" fixed="left">
          <template #edit="scope">
            <vxe-input v-model="scope.row.title" type="text" @input="$refs.xTable.updateStatus(scope)"/>
          </template>
        </vxe-column>
        <vxe-column field="slug" title="Slug" :edit-render="editRender" width="300">
          <template #edit="scope">
            <vxe-input v-model="scope.row.slug" type="text" @input="$refs.xTable.updateStatus(scope)"/>
          </template>
        </vxe-column>
       <!-- <vxe-column field="sku" title="Sku" :edit-render="editRender" width="300">
          <template #edit="scope">
            <vxe-input v-model="scope.row.sku" type="text" @input="$refs.xTable.updateStatus(scope)"/>
          </template>
        </vxe-column>-->
        <vxe-column field="price" title="Цена" :edit-render="editRender" width="300">
          <template #edit="scope">
            <vxe-input v-model="scope.row.price" type="text" @input="$refs.xTable.updateStatus(scope)"/>
          </template>
        </vxe-column>
        <template v-for="dynamicField in dynamicFields">
          <component
            :key="dynamicField.id"
            :is="getComponentName(dynamicField.component)"
            :dynamicField="dynamicField"
            :tableRef="$refs.xTable"
          />
        </template>
      </vxe-table>


      <vxe-pager
        border
        size="medium"
        :loading="isLoading"
        :current-page="paginator.currentPage"
        :page-size="paginator.limit"
        :total="totalItems"
        :layouts="paginator.vxePagerLayouts"
        @page-change="changePage"
      />
        </div>
    </v-card>
    <wysywig-popup
      v-if="isShowWysywig"
      v-model="wysywigPopup"
      :html="fetchedData[latestCellContextMenuClick.row][latestCellContextMenuClick.column]"
      @html-input="htmlInput"
    />
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {fields} from '@/fields/fields';
import WysywigPopup from "@/fields/table/WysywigPopup";
import VXETable from "vxe-table";

export default {
  name: "Table",
  mixins: [fields],
  components: { WysywigPopup },
  data() {
    return {
      page: {
        title: "Элементы каталога"
      },
      breadcrumbs: [
        {
          text: "Админ панель",
          disabled: false,
          to: '/'
        },
        {
          text: "Каталог",
          disabled: true,
          to: '/catalogs'
        }
      ],
      paginator: {
        currentPage: 1,
        limit: 16,
        vxePagerLayouts: [
          'PrevPage',
          'NextPage',
          'Sizes',
          'Total',
        ]
      },
      columnConfig: {
        resizable: true,
      },
      editRender: {
        autofocus: '.myinput'
      },
      editConfig: {
        trigger: 'click',
        mode: 'cell',
        showStatus: true
      },
      rowConfig: {
        isHover: true,
      },
      hidenColumns: [],
      wysywigPopup: false,
      validRules: {
        title: [
          {required: true, message: 'Обязательно для заполнения'}
        ],
        slug: [
          {required: true, message: 'Обязательно для заполнения'},
        ],
        sku: [
          {required: true, message: 'Обязательно для заполнения'},
        ],
        price: [
          {required: true, message: 'Обязательно для заполнения'},
        ]
      },
      menuConfig: {
        body: {
          options: [
            [
              {code: 'wysiwyg', name: 'Wysiwyg', visible: true, disabled: false},
            ]
          ]
        },
        visibleMethod: this.visibleMethod,
      },
      latestCellContextMenuClick: {
        row: null,
        column: null,
      }
    }
  },
  async created() {
    await this.fetchData(this.payload);

    this.refreshColumns();
  },
  computed: {
    ...mapGetters({
      getFetchedData: "CatalogItemTable/getFetchedData",
      isLoading: "CatalogItemTable/getIsLoading",
      totalItems: "CatalogItemTable/getListingTotalItems",
      dynamicFields: "CatalogItemTable/getDynamicFields"
    }),
    fetchedData: {
      get() {
        return this.getFetchedData;
      },
      set(val) {
        this.setFetchedData(val);
      }
    },
    payload() {
      return {
        catalogID: this.$route.params.catalog_id,
        page: this.paginator.currentPage,
        limit: this.paginator.limit,
      }
    },
    //Определяем показываем ли компонент попапа или нет
    isShowWysywig() {
      return (this.latestCellContextMenuClick.row !== null && this.latestCellContextMenuClick.column !== null);
    }
  },
  methods: {
    ...mapActions({
      fetchData: "CatalogItemTable/fetchData",
      saveData: "CatalogItemTable/save",
    }),
    ...mapMutations({
      setFetchedData: "CatalogItemTable/setFetchedData",
    }),
    seqMethod({row}) {
      return row.id
    },
    async changePage({currentPage, pageSize}) {
      this.paginator.currentPage = currentPage;
      this.paginator.limit = pageSize;

      await this.fetchData(this.payload);

      this.refreshColumns();
    },
    refreshColumns() {
      this.$nextTick(() => {
        this.hidenColumns = this.$refs.xTable.getColumns();
      })
    },
    getComponentName(component) {
      return component + '-table';
    },
    async removeEvent() {
      if (this.$refs.xTable.getCheckboxRecords().length > 0) {
        const type = await VXETable.modal.confirm('Удалить выбраные записи?')
        if (type === 'confirm') {
          this.$refs.xTable.removeCheckboxRow();
        }
      }
    },
    insertEvent() {
      this.$refs.xTable.insert();
    },
    async saveEvent() {
      const errMap = await this.$refs.xTable.validate(true).catch(errMap => errMap)
      if (errMap) {
        VXETable.modal.message({status: 'error', content: 'Заполните все обязательные поля'})
      } else {
        await this.saveData(this.$refs.xTable.getRecordset()).then(async () => {
          await this.fetchData(this.payload);
        });
      }
    },
    visibleMethod({ options, column }) {
      const field = this.dynamicFields.find((item) => item.field_name === column.field);

      if (field?.component !== 'textarea-field') {
        options[0][0].disabled = true;
      } else {
        options[0][0].disabled = false;
      }

      return true;
    },
    contextMenuClickEvent({ menu, rowIndex, column }) {
      if (menu.code === "wysiwyg") {
        this.latestCellContextMenuClick.row = rowIndex;
        this.latestCellContextMenuClick.column = column.field;
        this.wysywigPopup = true;
      }
    },
    htmlInput(val) {
      this.fetchedData[this.latestCellContextMenuClick.row][this.latestCellContextMenuClick.column] = val;
    }
  }
}
</script>

<style >

.vxe-table--render-default .vxe-body--column.col--ellipsis, .vxe-table--render-default.vxe-editable .vxe-body--column, .vxe-table--render-default .vxe-footer--column.col--ellipsis, .vxe-table--render-default .vxe-header--column.col--ellipsis
{
  height:24px !important;

}

.vxe-cell{
  white-space: nowrap !important; text-overflow:ellipsis; overflow: hidden;
}

</style>
