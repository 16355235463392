<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card class="mb-5">
    <v-card-text class="pa-5 ">
    <v-row>
      <v-col>



          <v-btn
            color="primary"
            to="/pages/create"
            dark
            class="mb-2 text-capitalize"
          >
            <v-icon class="mr-2">mdi-book-open-page-variant-outline</v-icon>
            Создать страницу
          </v-btn>


        <vxe-table
          show-overflow
          row-key
          ref="xTree3"
          border="inner"
          :row-config="{isHover: true}"
          :show-header="true"
          :data="nodes"
          :checkbox-config="{labelField: 'name'}"
          :tree-config="{transform: true, expandAll: true, iconOpen: 'mdi mdi-minus-box', iconClose: 'mdi mdi-plus-box'}">
          <vxe-column type="seq" title="Структура" tree-node>
            <template #default="{ row }">
              <span>
                <template v-if="row.children && row.children.length">
                  <i class="tree-node-icon" :class="$refs.xTree3.isTreeExpandByRow(row) ? 'mdi mdi-folder-open' : 'mdi mdi-folder'"></i>
                </template>
                <template v-else>
                  <i class="tree-node-icon mdi mdi-file-outline"></i>
                </template>
                <span>{{ row.title }} (#{{row.id}})</span>
              </span>
            </template>
          </vxe-column>
          <vxe-column  title="Блочный редактор">
            <template #default="{ row }">
            <a v-if="row.preview_url" :href="getEditUrl(row)" target="_blank" title="Открыть страницу в режиме редактирования блоков">
              <svg-icons
                icon-name="aspect-ratio"
              />
            </a>
            </template>
          </vxe-column>
          <vxe-column title="Управление" width="190">
            <template  #default="{ row }">
              <div v-if="row.preview_url">


                <a :href="row.preview_url" target="_blank">
                  <v-icon small class="mr-2 info--text">mdi-eye</v-icon>
                </a>
                <a

                  :href="editItem2(row)"
                >
                  <v-icon small class="mr-2 info--text">
                    mdi-pencil
                  </v-icon>
                </a>




              </div>
            </template>
          </vxe-column>
        </vxe-table>
      </v-col>
    </v-row>
    </v-card-text>
    </v-card>



    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>

        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="total"
          :footer-props="{'items-per-page-options':[5, 10, 15, 20, -1]}"
          class="contact-listing-app"
        >
          <template v-slot:top>
            <v-toolbar flat class="mb-8">
              <v-row>
                <v-col cols="12" lg="6">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Поиск..."
                    filled
                    background-color="transparent"
                    hide-details
                    name="title"
                  >
                    <v-btn v-if="search" class="reset-button"
                      slot="append"
                      icon
                      @click="search = null"
                      color="red"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-switch v-model="only_trashed" label="Показать удаленные" name="only_trashed" value="1" style="margin-top: 16px;"></v-switch>
                </v-col>
              </v-row>


            </v-toolbar>
          </template>

          <template v-slot:item.id="{ item }">
            <v-chip pill>{{ item.id }}</v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <a :href="item.preview_url" target="_blank">
              <v-icon small class="mr-2 info--text">mdi-eye</v-icon>
            </a>
            <a
              v-if="(item.is_permission_view && can('page.permission.view')) || !item.is_permission_view"
              :href="editItem(item)"
            >
              <v-icon small class="mr-2 info--text">
                mdi-pencil
              </v-icon>
            </a>
            <v-icon
              small class="mr-2 info--text"
              @click="copyItem(item)"
            >
              mdi-content-copy
            </v-icon>
            <v-icon
              v-if="item.deleted_at"
              small
              color="green"
              @click="restoreItem(item)"
            >
              mdi-backup-restore
            </v-icon>
            <v-icon
              v-else-if="(item.is_permission_view && can('page.permission.view')) || !item.is_permission_view"
              small
              color="red"
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:item.translations="{ item }">
            <v-btn icon color="success" @click="openTranslationDialog(item)">
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
            <v-speed-dial v-for="value in item.existing_translations" :key="value.id">
                <template v-slot:activator v-if="item.language_id !== value.language_id">
                  <country-flag
                    :country="value.flag_icon"
                    :key="value.id"
                    class="mt-1"
                  />
                </template>
                <v-btn fab small color="primary" :to="`pages/edit/${value.id}`">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn fab small color="primary" :href="value.preview_url">
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
            </v-speed-dial>
          </template>
          <template v-slot:item.edit_mode="{ item }">
            <a :href="getEditUrl(item)" target="_blank" title="Открыть страницу в режиме редактирования блоков">
              <svg-icons
                icon-name="aspect-ratio"
              />
            </a>
          </template>
          <template v-slot:item.title_mode="{ item }">
            <a :href="getEditUrl(item)" target="_blank" title="Открыть страницу в режиме редактирования блоков">
              {{item.title}}
            </a>
            <br><small>{{item.slug}}</small>
          </template>
        </v-data-table>
        </v-col>
      </v-row>
      </v-card-text>
    </v-card>
    <TranslationCreatorComponent
      v-model="translationModal"
      :original_page="selectedPageTranslate"
      :existing-languages="selectedPageLanguages"
      @onSubmit="makeTranslationPage"
    />
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import TranslationCreatorComponent from "../../../components/TranslationCreatorComponent";
import SvgIcons from "@/components/SvgIcons";

export default {
  name: 'Index',
  components: {SvgIcons, TranslationCreatorComponent},
  data: () => ({
    search: '',
    only_trashed: null,
    /*
    nodes: [
      { id: 10000, parentId: null, name: 'test abc1', type: 'mp3', size: 1024, date: '2020-08-01' },
      { id: 10050, parentId: null, name: 'Test2', type: 'mp4', size: null, date: '2021-04-01' },
      { id: 24300, parentId: 10050, name: 'Test3', type: 'avi', size: 1024, date: '2020-03-01' },
      { id: 20045, parentId: 24300, name: 'test abc4', type: 'html', size: 600, date: '2021-04-01' },
      { id: 10053, parentId: 24300, name: 'test abc96', type: 'avi', size: null, date: '2021-04-01' },
      { id: 24330, parentId: 10053, name: 'test abc5', type: 'txt', size: 25, date: '2021-10-01' },
      { id: 21011, parentId: 10053, name: 'Test6', type: 'pdf', size: 512, date: '2020-01-01' },
      { id: 22200, parentId: 10053, name: 'Test7', type: 'js', size: 1024, date: '2021-06-01' },
      { id: 23666, parentId: null, name: 'Test8', type: 'xlsx', size: 2048, date: '2020-11-01' },
      { id: 23677, parentId: 23666, name: 'Test7', type: 'js', size: 1024, date: '2021-06-01' },
      { id: 23671, parentId: 23677, name: 'Test7', type: 'js', size: 1024, date: '2021-06-01' },
      { id: 23672, parentId: 23677, name: 'Test7', type: 'js', size: 1024, date: '2021-06-01' },
      { id: 23688, parentId: 23666, name: 'Test7', type: 'js', size: 1024, date: '2021-06-01' },
      { id: 23681, parentId: 23688, name: 'Test7', type: 'js', size: 1024, date: '2021-06-01' },
      { id: 23682, parentId: 23688, name: 'Test7', type: 'js', size: 1024, date: '2021-06-01' },
      { id: 24555, parentId: null, name: 'test abc9', type: 'avi', size: 224, date: '2020-10-01' },
      { id: 24566, parentId: 24555, name: 'Test7', type: 'js', size: 1024, date: '2021-06-01' },
      { id: 24577, parentId: 24555, name: 'Test7', type: 'js', size: 1024, date: '2021-06-01' }
    ],
    */

    options: {
      sortBy: ['id'],
      sortDesc: [true],
      page: 1,
      itemsPerPage: 20,
    },
    headers: [
      {text: 'Id', value: 'id'},
      {text: 'Название', value: 'title_mode', width: '30%'},
      {text: 'Вариант страницы', value: 'variant'},

      {text: 'Создатель', value: 'creator'},
      {text: 'Дата создания', value: 'created_at'},
     // {text: 'Дата обновления', value: 'updated_at'},
     //{text: 'Переводы', value: 'translations' },
      {text: '', value: 'edit_mode' },
      {text: '', value: 'actions'},
    ],
    page: {
      title: "Страницы"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Страницы",
        disabled: true,
        to: '/pages'
      },
    ],
    translationModal: false,
    selectedPageLanguages: {},
    selectedPageTranslate: null,
  }),
  created() {
    this.get();
  },
  computed: {
    ...mapGetters({
      items: 'Pages/getFetchedListing',
      nodes: 'Pages/getFetchedTreeListing',
      total: 'Pages/getListingTotalItems',
    }),
  },
  methods: {
    ...mapActions({
      fetchedListing: 'Pages/fetchListing',
      fetchedTreeListing: 'Pages/fetchTreeListing',
      destroyItem: 'Pages/destroy',
      restore: 'Pages/restoreItem',
      copy: 'Pages/makeCopy',
      storePage: 'Pages/store',
    }),
    editItem(item) {
      return this.$router.resolve({name: 'pages-edit', params: {id: item.id}}).href
    },
    editItem2(item) {
      return this.$router.resolve({name: 'pages-edit', params: {id: item.originalId}}).href
    },
    async deleteItem(item) {
      this.$confirm('Вы уверены?', 'Удаление').then(() => {
        this.destroyItem(item.id).then(() => {
          this.get();
        })
      });
    },
    restoreItem(item) {
      this.restore(item.id).then(() => {
        this.get();
      })
    },
    async copyItem(item) {
      this.copy(item.id).then(async () => {
        await this.get();
      })
    },
    async get() {
      const payload = {
        options: this.options,
        search: this.search,
        only_trashed: this.only_trashed,
      };

      this.fetchedListing(payload);
      this.fetchedTreeListing();
    },
    openTranslationDialog(page) {
      this.selectedPageLanguages = page.existing_translations.map(({language_id}) => (language_id));
      this.selectedPageTranslate = page.id;
      this.translationModal = true;
    },
    makeTranslationPage(form) {
      this.storePage({form: form}).then(() => {
        this.translationModal = false;
        this.get();
      });
    },
    getEditUrl(item) {
      return `${item.preview_url}?edit_mode=true`;
    }
  },
  watch: {
    only_trashed: {
      handler() {
        this.get();
      }
    },
    search: {
      handler(newSearch, oldSearch) {
        newSearch != oldSearch ? this.options.page = 1 : '';
        this.get();
      }
    },
    options: {
      handler() {
        this.get()
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
  .reset-button {
    width: unset!important;
    height: unset!important;
    margin-top: 5px;
  }


</style>
