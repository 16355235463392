<template>

  <div class="text-center" style="color:#4c6cb3; margin-top:7rem">
    <div style="font-size:10rem;line-height: 8rem">404</div>
    <div class="text-uppercase h1">Not found</div>
  </div>

</template>

<script>

  export default {
    name: 'not-found'
  }

</script>
