import axiosInstance from "@/axiosInstance";

export default {
  namespaced: true,
  state: {
    fetchedData: [],
    listingTotalItems: 0,
    isLoading: false,
    catalogDynamicFields: [],
    catalogID: null,
  },
  getters: {
    getFetchedData: (state) => state.fetchedData,
    getListingTotalItems: (state) => state.listingTotalItems,
    getIsLoading: (state) => state.isLoading,
    getDynamicFields: (state) => state.catalogDynamicFields,
    getCatalogID: (state) => state.catalogID,
  },
  mutations: {
    setFetchedData(state, val) {
      return state.fetchedData = val;
    },
    setListingTotalItems(state, val) {
      return state.listingTotalItems = val;
    },
    setIsLoading(state, val) {
      return state.isLoading = val;
    },
    setCatalogDynamicFields(state, val) {
      state.catalogDynamicFields = val;
    },
    setCatalogID(state, val) {
      state.catalogID = val;
    }
  },
  actions: {
    fetchData({ commit, dispatch }, { catalogID, page, limit }) {
      axiosInstance.get(`module/admin/catalog/${catalogID}/table/listing?page=${page}&limit=${limit}`).then((resp) => {
        commit("setFetchedData", resp.data.data);
        commit("setListingTotalItems", resp.data.meta.total);
        commit("setCatalogDynamicFields", resp.data.dynamic_fields);
        commit("setCatalogID", catalogID);
      }).catch(() => {
        dispatch("snackbar/showUnknownError", null, { root: true })
      }).finally(() => {
        commit("setIsLoading", false);
      })
    },
    async save({ dispatch, state }, { insertRecords, removeRecords, updateRecords }) {
      return axiosInstance.post(`module/admin/catalog/${state.catalogID}/table/save`, {
        insertRecords,
        removeRecords,
        updateRecords
      }).then(() => {
        dispatch("snackbar/showSuccessfullySaved", null, { root: true })
      }).catch(() => {
        dispatch("snackbar/showSaveError", null, { root: true })
      })
    }
  }
}
