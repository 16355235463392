import { Model } from "@tailflow/laravel-orion/lib/model";

export class History extends Model<{
    user_id: number,
    entity_id: number,
    entity_type: string,
    before: object,
    after: object,
}> {

    $resource(): string {
        return "histories";
    }
}
