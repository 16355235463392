var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vxe-toolbar',{scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('vxe-button',{on:{"click":_vm.addItem}},[_vm._v("Добавить")]),_c('vxe-button',{on:{"click":_vm.removeItems}},[_vm._v("Удалить")]),_c('vxe-button',{on:{"click":_vm.saveItems}},[_vm._v("Сохранить")])]},proxy:true}])}),_c('vxe-grid',{ref:"table",attrs:{"border":"","resizable":"","keep-source":"","empty-text":"Данные отсутствуют","id":"toolbar_demo_1","height":"500","columns":_vm.tableColumn,"data":_vm.tableData,"loading":_vm.isLoading,"edit-config":{ enabled: true, mode: 'cell', showStatus: true, trigger: 'click'  },"column-config":{ isCurrent:true, isHover: true, selected: true }},on:{"edit-actived":_vm.activateEdit},scopedSlots:_vm._u([{key:"original_edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_c('v-btn',{attrs:{"small":"","icon":"","color":"primary"},on:{"click":function($event){_vm.tipTapModal = true}}},[_c('v-icon',[_vm._v("mdi-pen")])],1)]},proxy:true}],null,true),model:{value:(row.original),callback:function ($$v) {_vm.$set(row, "original", $$v)},expression:"row.original"}})]}},{key:"russian_edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_c('v-btn',{attrs:{"small":"","icon":"","color":"primary"},on:{"click":function($event){_vm.tipTapModal = true}}},[_c('v-icon',[_vm._v("mdi-pen")])],1)]},proxy:true}],null,true),model:{value:(row.russian),callback:function ($$v) {_vm.$set(row, "russian", $$v)},expression:"row.russian"}})]}},{key:"english_edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_c('v-btn',{attrs:{"small":"","icon":"","color":"primary"},on:{"click":function($event){_vm.tipTapModal = true}}},[_c('v-icon',[_vm._v("mdi-pen")])],1)]},proxy:true}],null,true),model:{value:(row.english),callback:function ($$v) {_vm.$set(row, "english", $$v)},expression:"row.english"}})]}},{key:"eng_description_edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_c('v-btn',{attrs:{"small":"","icon":"","color":"primary"},on:{"click":function($event){_vm.tipTapModal = true}}},[_c('v-icon',[_vm._v("mdi-pen")])],1)]},proxy:true}],null,true),model:{value:(row.eng_description),callback:function ($$v) {_vm.$set(row, "eng_description", $$v)},expression:"row.eng_description"}})]}},{key:"ru_description_edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_c('v-btn',{attrs:{"small":"","icon":"","color":"primary"},on:{"click":function($event){_vm.tipTapModal = true}}},[_c('v-icon',[_vm._v("mdi-pen")])],1)]},proxy:true}],null,true),model:{value:(row.ru_description),callback:function ($$v) {_vm.$set(row, "ru_description", $$v)},expression:"row.ru_description"}})]}}])}),_c('vxe-modal',{attrs:{"title":"Редактирование ячейки в tipTap","width":"600","resize":"","remember":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tip-tap',{model:{value:(_vm.currentCol.value),callback:function ($$v) {_vm.$set(_vm.currentCol, "value", $$v)},expression:"currentCol.value"}}),_c('vxe-form',{attrs:{"title-align":"right","title-width":"60"}},[_c('vxe-form-item',{attrs:{"align":"center","span":"24"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('vxe-button',{attrs:{"type":"submit","status":"primary"},on:{"click":_vm.submitTipTap}},[_vm._v("Сохранить")]),_c('vxe-button',{on:{"click":_vm.closeEdit}},[_vm._v("Закрыть")])]},proxy:true}])})],1)]},proxy:true}]),model:{value:(_vm.tipTapModal),callback:function ($$v) {_vm.tipTapModal=$$v},expression:"tipTapModal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }