<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <course-form
              @onSubmit="submit"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import CourseForm from '../../../components/courses/CourseForm';
import {mapActions} from "vuex";

export default {
  name: 'Create',
  components: { CourseForm },
  data: () => ({
    page: {
      title: "Создание курса"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Курсы",
        disabled: false,
        to: '/courses/'
      },
      {
        text: "Создание",
        disabled: true,
        to: '/courses/create'
      },
    ],
  }),
  methods: {
    ...mapActions({
      storeItem: 'Courses/storeItem'
    }),
    async submit({ form, mediaIds, isNeedRedirect }) {
        if (mediaIds) {
          form.mediaIds = mediaIds;
        }

        const payload = {
          form: form,
          isNeedRedirect: isNeedRedirect,
          router: this.$router,
        }

        this.storeItem(payload).then(this.$router.push('/courses'));
    },
  },
};
</script>
