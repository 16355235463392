import Index from '../../views/main/webmasterPartners/Index'
import Create from '../../views/main/webmasterPartners/Create'

export default [
  {
    component: Index,
    name: 'webmasterPartners-index',
    path: '/webmasterPartners',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
  {
    component: Create,
    name: 'webmasterPartners-create',
    path: '/webmasterPartners/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
]
