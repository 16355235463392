import Index from '../../views/main/languages/Index'

export default [
  {
    component: Index,
    name: 'languages-index',
    path: '/lang',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
]
