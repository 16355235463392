<template>
  <div class="telegram-preview">
    <div class="card-seo-telegram">
      <a class="main-url" :href="getFullUrl">
        {{ getFullUrl }}
      </a>
      <div class="content-block">
        <div v-if="$data.facebookImg" class="image-block" :style="$data.facebookImg"></div>
        <div class="content">
          <a class="site-url">
            {{ getHostname }}
          </a>
          <span class="telegram-title">{{ title }}</span>
          <span class="telegram-description">{{ description }}</span>
        </div>
      </div>
      <div class="telegram-footer">{{ time }}</div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import smmMixin from "@/mixins/smmMixin";
import seoAndSmmWidgetMixin from "@/mixins/seoAndSmmWidgetMixin";

export default {
  mixins: [
    smmMixin,
    seoAndSmmWidgetMixin
  ],
  props: {
    form: {
      type: Object,
    },
    entity: {
      type: Object,
    },
  },
  data() {
    return {
      title: '',
      facebookImg: '',
      description: '',
      descriptionLength: 215,
    }
  },
  computed: {
    time() {
      let date = new Date();

      return `${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`;
    }
  },
  methods: {
    ...mapActions({
      dynamicResize: "Medias/dynamicResize"
    }),
    cutString(string, count) {
      if (string.length > count) {
        return string.substring(0, count) + '...';
      }

      return string;
    },
  },
  watch: {
    'form.smm_image': {
      async handler(val) {
        if (val != null) {
          let value = val.url || this.entity.smm_image.url;

          if (typeof value != 'string') return;

          const url = (await this.dynamicResize({ media: val.id, width: 1200, height: 630 })).data;

          this.$data.facebookImg = `background-image: url(${url})`;
        } else {
          this.$data.facebookImg = '';
        }
      },
      immediate: true,
    },
    'form.smm_description': {
      handler(val) {
        let value = val || this.entity.lead || this.entity.description;

        if (typeof value != 'string') return;

        this.description = this.cutString(value, this.descriptionLength) || this.entity.lead;
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
  .telegram-preview {
    margin-bottom: 15px;

    .card-seo-telegram {
      width: 430px;
      background: #eeffde;
      border-radius: 5px;
      padding: 10px 10px 0px 10px;
      font-size: 14px;

      a.main-url {
        color: #00488f;
        line-height: 20px;
        display: block;
        margin-bottom: 10px;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        max-height: 95px;
        overflow: hidden;
      }

      .content-block {
        border-left: 2px solid #88cb82;
        padding-left: 10px;

        .image-block {
          width: 100%;
          height: 191px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          margin-bottom: 5px;
        }

        .site-url {
          display: block;
          color: #4fae4e;
          font-weight: 500;
        }

        .telegram-title {
          display: block;
          font-weight: 500;
          margin-top: 2px;
          margin-bottom: 2px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .telegram-description {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }

      .telegram-footer {
        font-size: 14px;
        text-align: right;
        color: #4fae4e;
        padding-bottom: 3px;
        padding-top: 3px;
      }
    }
  }
</style>
