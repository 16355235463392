var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"down-top-padding",attrs:{"fluid":""}},[_c('BaseBreadcrumb',{attrs:{"title":_vm.page.title,"icon":_vm.page.icon,"breadcrumbs":_vm.breadcrumbs}}),_c('v-card',{staticClass:"mb-5"},[_c('v-card-text',{staticClass:"pa-5 "},[_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"mb-2 text-capitalize",attrs:{"color":"primary","to":"/pages/create","dark":""}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-book-open-page-variant-outline")]),_vm._v(" Создать страницу ")],1),_c('vxe-table',{ref:"xTree3",attrs:{"show-overflow":"","row-key":"","border":"inner","row-config":{isHover: true},"show-header":true,"data":_vm.nodes,"checkbox-config":{labelField: 'name'},"tree-config":{transform: true, expandAll: true, iconOpen: 'mdi mdi-minus-box', iconClose: 'mdi mdi-plus-box'}}},[_c('vxe-column',{attrs:{"type":"seq","title":"Структура","tree-node":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[(row.children && row.children.length)?[_c('i',{staticClass:"tree-node-icon",class:_vm.$refs.xTree3.isTreeExpandByRow(row) ? 'mdi mdi-folder-open' : 'mdi mdi-folder'})]:[_c('i',{staticClass:"tree-node-icon mdi mdi-file-outline"})],_c('span',[_vm._v(_vm._s(row.title)+" (#"+_vm._s(row.id)+")")])],2)]}}])}),_c('vxe-column',{attrs:{"title":"Блочный редактор"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.preview_url)?_c('a',{attrs:{"href":_vm.getEditUrl(row),"target":"_blank","title":"Открыть страницу в режиме редактирования блоков"}},[_c('svg-icons',{attrs:{"icon-name":"aspect-ratio"}})],1):_vm._e()]}}])}),_c('vxe-column',{attrs:{"title":"Управление","width":"190"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.preview_url)?_c('div',[_c('a',{attrs:{"href":row.preview_url,"target":"_blank"}},[_c('v-icon',{staticClass:"mr-2 info--text",attrs:{"small":""}},[_vm._v("mdi-eye")])],1),_c('a',{attrs:{"href":_vm.editItem2(row)}},[_c('v-icon',{staticClass:"mr-2 info--text",attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]):_vm._e()]}}])})],1)],1)],1)],1)],1),_c('v-card',[_c('v-card-text',{staticClass:"pa-5"},[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"contact-listing-app",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"footer-props":{'items-per-page-options':[5, 10, 15, 20, -1]}},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"mb-8",attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Поиск...","filled":"","background-color":"transparent","hide-details":"","name":"title"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[(_vm.search)?_c('v-btn',{staticClass:"reset-button",attrs:{"slot":"append","icon":"","color":"red"},on:{"click":function($event){_vm.search = null}},slot:"append"},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1)],1),_c('v-col',[_c('v-switch',{staticStyle:{"margin-top":"16px"},attrs:{"label":"Показать удаленные","name":"only_trashed","value":"1"},model:{value:(_vm.only_trashed),callback:function ($$v) {_vm.only_trashed=$$v},expression:"only_trashed"}})],1)],1)],1)]},proxy:true},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"pill":""}},[_vm._v(_vm._s(item.id))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.preview_url,"target":"_blank"}},[_c('v-icon',{staticClass:"mr-2 info--text",attrs:{"small":""}},[_vm._v("mdi-eye")])],1),((item.is_permission_view && _vm.can('page.permission.view')) || !item.is_permission_view)?_c('a',{attrs:{"href":_vm.editItem(item)}},[_c('v-icon',{staticClass:"mr-2 info--text",attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1):_vm._e(),_c('v-icon',{staticClass:"mr-2 info--text",attrs:{"small":""},on:{"click":function($event){return _vm.copyItem(item)}}},[_vm._v(" mdi-content-copy ")]),(item.deleted_at)?_c('v-icon',{attrs:{"small":"","color":"green"},on:{"click":function($event){return _vm.restoreItem(item)}}},[_vm._v(" mdi-backup-restore ")]):((item.is_permission_view && _vm.can('page.permission.view')) || !item.is_permission_view)?_c('v-icon',{attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}},{key:"item.translations",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"success"},on:{"click":function($event){return _vm.openTranslationDialog(item)}}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1),_vm._l((item.existing_translations),function(value){return _c('v-speed-dial',{key:value.id,scopedSlots:_vm._u([(item.language_id !== value.language_id)?{key:"activator",fn:function(){return [_c('country-flag',{key:value.id,staticClass:"mt-1",attrs:{"country":value.flag_icon}})]},proxy:true}:null],null,true)},[_c('v-btn',{attrs:{"fab":"","small":"","color":"primary","to":("pages/edit/" + (value.id))}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"fab":"","small":"","color":"primary","href":value.preview_url}},[_c('v-icon',[_vm._v("mdi-eye")])],1)],1)})]}},{key:"item.edit_mode",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":_vm.getEditUrl(item),"target":"_blank","title":"Открыть страницу в режиме редактирования блоков"}},[_c('svg-icons',{attrs:{"icon-name":"aspect-ratio"}})],1)]}},{key:"item.title_mode",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":_vm.getEditUrl(item),"target":"_blank","title":"Открыть страницу в режиме редактирования блоков"}},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('br'),_c('small',[_vm._v(_vm._s(item.slug))])]}}])})],1)],1)],1)],1),_c('TranslationCreatorComponent',{attrs:{"original_page":_vm.selectedPageTranslate,"existing-languages":_vm.selectedPageLanguages},on:{"onSubmit":_vm.makeTranslationPage},model:{value:(_vm.translationModal),callback:function ($$v) {_vm.translationModal=$$v},expression:"translationModal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }