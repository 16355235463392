import axiosInstance from '@/axiosInstance';

const state = () => ({
  collectionItems: [{id: 1, title: 'test', test: 'test'}, {id: 1, title: 'test', test: 'test'}]
});

const actions = {
  async fetchCollectionItems(ctx, payload) {
    const {id} = payload;

    const { data: collectionItems } = await axiosInstance.get(`follred/collection-items/${id}`);

    ctx.commit('setCollectionItems', collectionItems);
  },
  async saveCollection(ctx, payload) {
    const { items, taxonomyId, toDelete } = payload;

    if (items.length) {
      await axiosInstance.post(`follred/collection-items/update/${taxonomyId}`, {
        items,
      });
    }

    if (toDelete.length) {
      await axiosInstance.post(`follred/collection-items`, {
        items: toDelete,
      });
    }
  },
}

const mutations = {
  setCollectionItems(state, value) {
    state.collectionItems = value;
  }
}

const getters = {
  getCollectionItems: (state) => state.collectionItems,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
