<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="items"
              :options="options"
              :server-items-length="total"
              >
              <template v-slot:top>
                  <v-flex class="mb-4">
                    <v-row>
                      <v-col cols="10">
                        <v-row>
                          <v-col lg="3">
                            <v-text-field
                              v-model="search"
                              append-icon="mdi-magnify"
                              label="Поиск..."
                              filled
                              background-color="transparent"
                              hide-details
                              name="title"
                            >
                              <v-btn v-if="search" class="reset-button"
                                    slot="append"
                                    icon
                                    @click="search = null"
                                    color="red"
                              >
                                <v-icon>mdi-close</v-icon>
                              </v-btn>
                            </v-text-field>
                          </v-col>
                          <v-col lg="3" v-for="collection in collections" v-bind:key="collection.id">
                            <v-select
                                  :key="collection.$attributes.id"
                                  :items="collection.$attributes.items"
                                  :label="collection.$attributes.title"
                                  multiple
                                  @change="selectedCollectionItem($event, collection.$attributes.id)"
                                  item-value="id"
                                  item-text="title"/>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-spacer/>
                      <v-col cols="2">
                        <v-dialog max-width="700">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              color="primary"
                              to="/partners/create"
                              dark
                              class="mb-2 text-capitalize"
                            >
                              <v-icon class="mr-2">mdi-account-circle</v-icon>
                              Добавить партнера
                            </v-btn>
                          </template>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </v-flex>
              </template>
              <template v-slot:item.id="{item}">
                <v-chip pill>{{ item.id }}</v-chip>
              </template>
              <template v-slot:item.actions="{item}">
                <v-btn
                  :href="editItem(item)"
                  icon
                >
                  <v-icon small color="primary">
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-icon
                  color="red"
                  small
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {Partner} from '../../../models/partner';
import axiosInstance from "../../../axiosInstance";
import {Collection} from "@/models/collection";

export default {
  name: 'Index',
  data: () => ({
    loading: true,
    total: 0,
    options: {
      sortBy: ['id'],
      sortDesc: [true],
    },
    search: '',
    headers: [
      {text: 'Id', value: 'id'},
      {text: 'Название', value: 'title'},
      {text: 'Slug', value: 'slug'},
      {text: 'Дата публикации', value: 'published_at'},
      {text: 'Дата создания', value: 'created_at'},
      {text: 'Дата обновления', value: 'updated_at'},
      {text: '', value: 'actions'},
    ],
    items: [],
    page: {
      title: "Партнеры"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Партнеры",
        disabled: true,
        to: '/partners'
      },
    ],
    collections: [],
    selectedFilters: {},
  }),
  created() {
    this.get();
    this.getCollections();
  },
  methods: {
    editItem(item) {
      return this.$router.resolve({name: 'partners-edit', params: {id: item.id}}).href
    },
    async deleteItem(item) {
      await Partner.$query().destroy(item.id);
      await this.get();
    },
    async get() {
      this.loading = true;
      const {sortBy, sortDesc, page, itemsPerPage} = this.options;

      const data = {};
      this.search ? data.search = {value: this.search} : null;
      sortBy && sortBy[0] ? data.sort = [{field: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc'}] : null;
      data.collectionsFilter = this.selectedFilters;

      await axiosInstance.post(`/partners/search?limit=${itemsPerPage}&page=${page}`, data)
        .then((response) => {
          this.items = response.data.data;
          this.total = response.data.meta.total;
        });

      this.loading = false;
    },
    async getCollections()
    {
      this.collections = await Collection.$query()
        .sortBy('is_main', 'desc')
        .scope('whereModule', ['Partners'])
        .search();
    },
    selectedCollectionItem(event, collectionID) {
      if (event.length === 0) {
        delete this.selectedFilters[collectionID];
      } else {
        this.selectedFilters[collectionID] = event;
      }

      this.get();
    }
  },
  watch: {
    search: {
      handler() {
        this.get();
      }
    },
    options: {
      handler() {
        this.get()
      },
      deep: true,
    },
  },
};
</script>
