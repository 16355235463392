import { Model } from "@tailflow/laravel-orion/lib/model";

export class Media extends Model<{
    name: string,
    file_name: string,
    disk: string,
    mime_type: string,
    size: string,
    entity: string,
    title: string,
    description: string,
    group_id: string,
    keywords: string,
    copyright: string,
    created_at: string,
    updated_at: string
}> {

    $resource(): string {
        return "module/medias";
    }

}
