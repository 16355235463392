import {Model} from "@tailflow/laravel-orion/lib/model";
import {HasOne} from "@tailflow/laravel-orion/lib/drivers/default/relations/hasOne";
import {BelongsTo} from "@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo";
import {HasMany} from "@tailflow/laravel-orion/lib/drivers/default/relations/hasMany";
import {Block} from "./block";

export class BlockEntity extends Model<{
    entity: string,
    entity_id: number,
    structure: object,
    created_at: string,
    updated_at: string,
}> {

    $resource(): string {
        return "module/block-entities";
    }

}
