import axiosInstance from "../../../axiosInstance";

export default {
  namespaced: true,
  state: {
    fetchedList: [],
  },
  getters: {
    getFetchedList: (state) => state.fetchedList,
  },
  mutations: {
    setFetchedList(state, val) {
      state.fetchedList = val;
    },
  },
  actions: {
    async fetchList({ commit, dispatch }) {
      await axiosInstance.get('cities/select-list').then((response) => {
        commit('setFetchedList', response.data);
      }).catch(() => {
        dispatch('snackbar/showUnknownError', null, { root: true });
      });
    },
  }
}
