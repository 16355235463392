import Index from '../../views/main/users/Index'
import Edit from '../../views/main/users/Edit'
import Create from '../../views/main/users/Create'

export default [
  {
    component: Index,
    name: 'users',
    path: '/users',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'users',
    }
  },
  {
    component: Edit,
    name: 'users-edit',
    path: '/users/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'users',
    }
  },
  {
    component: Create,
    name: 'users-create',
    path: '/users/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'users',
    }
  },
]
