<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <event-form
              :errors="errors"
              @onSubmit="submit"
            />
          </v-col>
        </v-row>
        <v-snackbar
          v-model="snackbarSuccess"
          :timeout="timeout"
          color="success"
        >
          Сохранено

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarSuccess = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar
          v-model="snackbarError"
          :timeout="timeout"
          color="error"
        >
          {{ this.errors }}

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarError = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import EventForm from '../../../components/events/EventForm';
import {Event} from "../../../models/event";
import {Media} from "../../../models/media";
import { mapMutations } from 'vuex';

export default {
  name: 'Create',
  components: { EventForm },
  data: () => ({
    event_id: null,
    errors: {},
    snackbarSuccess: false,
    snackbarError: false,
    timeout: 2000,
    page: {
      title: "Создание события"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Календарь",
        disabled: true,
        to: '/events'
      },
      {
        text: "Создание",
        disabled: true,
        to: '/events/create'
      },
    ],
  }),
  mounted() {
    if (this.$route.params.event_id) {
      this.event_id = this.$route.params.event_id
    }
  },
  methods: {
    ...mapMutations({
      setIsLoading: "Events/setIsLoading",
    }),
    async submit({ form, media, mediaIds, isNeedRedirect }) {
      try {
        this.errors = {};

        const storeData = { ...form, event_id: this.event_id };

        if (mediaIds) {
          storeData.mediaIds = mediaIds;
        }

        const event = await Event.$query().store(storeData);
        await event.partners().sync(form.selected_partners || []);
        await event.organizers().sync(form.selected_organizers || []);
        await Media.$query().store({...media, entity_id: event.$attributes.id});

        if (isNeedRedirect) {
          await this.$router.push({name: 'events-index'});
        } else {
          await this.$router.push({name: 'events-edit', params: {id: event.$attributes.id}});
        }
        this.snackbarSuccess = true;
      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors
          this.snackbarError = true;
        }
        throw e;
      } finally {
        this.setIsLoading(false);
      }
    },
  },
};
</script>
