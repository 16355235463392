import Index from '../../views/main/regional-practices/Index'
import Edit from '../../views/main/regional-practices/Edit'
import Create from '../../views/main/regional-practices/Create'

export default [
  {
    component: Index,
    name: 'regional-practices-index',
    path: '/regional-practices',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
  {
    component: Edit,
    name: 'regional-practices-edit',
    path: '/regional-practices/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
  {
    component: Create,
    name: 'regional-practices-create',
    path: '/regional-practices/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  }
]
