<template>
  <v-form ref="form" v-model="rules.valid" lazy-validation>
    <v-row>
      <v-col cols="3">
        <template v-if="form.url || form.file_image">
          <img
            :src="(form.file_type === 0 || form.file_type === 3) ? form.url : form.file_image_preview"
            alt="image"
            style="max-width:200px; max-height: 200px"
          >
        </template>
        <v-divider></v-divider>

      </v-col>
      <v-col cols="9">

        <v-text-field
          v-model="form.title"
          :error-messages="errors.title"
          :rules="rules.nameRules"
          label="Название"
          name="title"
          required
        ></v-text-field>
        <v-textarea
          v-model="form.description"
          :rules="rules.descriptionRules"
          :error-messages="errors.description"
          label="Описание"
          name="description"
          required
        ></v-textarea>
        <v-text-field
          v-model="form.copyright"
          :error-messages="errors.copyright"
          label="copyright"
          name="copyright"
          required
        ></v-text-field>
        <v-text-field
          v-model="form.key_words"
          :error-messages="errors.key_words"
          :rules="rules.keyWordsRules"
          label="Ключевые слова(через запятую)"
          name="key_words"
          required
        ></v-text-field>
        <v-select
          v-if="form.file_type === 0 || form.file_type === 3"
          v-model="form.group_id"
          item-text="label"
          item-value="value"
          :rules="rules.groupRules"
          :error-messages="errors.group_id"
          :items="groups"
          label="Группа"
          name="group_id"
          required
        ></v-select>
        <v-flex>
          <v-btn
            color="primary"
            :disabled="isSaveDisabled"
            :loading="isSaveDisabled"
            @click="submit(true)"
          >
            Сохранить
          </v-btn>
          <v-btn
            color="primary"
            :disabled="isSaveDisabled"
            :loading="isSaveDisabled"
            class="ml-2"
            @click="submit(false)"
          >
            Сохранить и продолжить
          </v-btn>
        </v-flex>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "MediaForm",
  props: {
    media: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    form: {
      id: null,
      title: null,
      description: null,
      group_id: null,
      copyright: null,
      key_words: null
    },
    rules: {
      nameRules: [
        v => !!v || 'Название обязательно для заполнения',
        v => (v && v.length > 3) || 'Название должно иметь не менее 3 символов'
      ],
      keyWordsRules: [
        v => !!v || 'Поле "Ключевые слова" обязательное для заполнения',
        v => (v && v.length > 3) || 'Поле "Ключевые слова" должны содержать от 3 символов',
      ],
      descriptionRules: [
        v => !!v || 'Поле "Описание" обязательное для заполнения',
        v => (v && v.length > 3) || 'Поле "Описание" должно содержать от 3 символов',
      ],
      groupRules: [
        v => (v !== 0) || 'Поле "группа" обязательное для заполнения',
      ],
      valid: true,
    },
    isSaveDisabled: false,
  }),
  computed: {
    ...mapGetters({
      creator: 'auth/getUser',
      groups: "Medias/getMediaGroups",
    })
  },
  created() {
    if (this.$route.params.data) {
      this.form = JSON.parse(this.$route.params.data);
    }
  },
  methods: {
    submit(needRedirect) {
      if (this.$refs.form.validate()) {
        this.isSaveDisabled = true;
        if (!this.form.created_by) {
          this.form.created_by = this.creator.id;
        }
        this.$emit('onSubmit', { form: this.form, needRedirect: needRedirect });
      }
    },
  },
  watch: {
    media: {
      handler(media) {
        if (media && Object.keys(media).length > 0) {
          this.form = media;
          this.isSaveDisabled = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
}
</script>
