import Create from '../../views/clear/medias/Create';
import Edit from '../../views/clear/medias/Edit';

const meta = {
  layout: 'clear-layout',
  requiresAuth: true,
};

export default [
  {
    component: Create,
    name: 'medias-create',
    path: '/medias/create',
    meta,
  },
  {
    component: Edit,
    name: 'medias-edit',
    path: '/medias/edit/:id',
    meta,
  },
];
