<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <page-form :errors="errors" @onSubmit="submit"/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import PageForm from '../../../components/pages/PageForm';
import {mapActions} from "vuex";
import {Media} from "../../../models/media";

export default {
  name: 'Create',
  components: {PageForm},
  data: () => ({
    parent_id: null,
    errors: {},
    page: {
      title: "Создание страницы"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Страницы",
        disabled: false,
        to: '/pages/'
      },
      {
        text: "Создание",
        disabled: true,
        to: '/pages/create'
      },
    ],
  }),
  methods: {
    ...mapActions({
      store: 'Pages/store',
    }),
    async submit(form) {
      const parentId = this.$route.query.parent_id;
      if (parentId) {
        form.form.parent_id = parentId;
      }
      const page = await this.store(form);
      const media = form.media;
      await Media.$query().store({...media, entity_id: page.id});
      await this.$router.push({name: 'pages-edit', params: {id: page.id}});
    },
  },
};
</script>

<style scoped>

</style>
