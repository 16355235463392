<template>
  <v-container fluid class="down-top-padding" v-if="isLoaded">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <div class="row">
           <v-menu
                  v-model="datePicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFilter"
                      label="Диапазон дат"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      append-icon="mdi-close"
                      @click:append="dateFilter = null"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateFilter"
                    locale="ru-RU"
                    range
                  >
                    <v-spacer/>
                    <v-btn text color="primary" @click="datePickerAction">ОК</v-btn>
                  </v-date-picker>
                </v-menu>
        </div>
        <div class="row">
          <v-col>
            <div class="v-card v-sheet theme--light">
              <div class="v-card__text pa-5">
                <div class="d-flex align-center">
                  <v-btn elevation="0" fab rounded readonly="true" color="primary">
                    <v-icon>
                      mdi-account
                    </v-icon>
                  </v-btn>
                  <div class="ml-2 mr-1">
                    <h2 class="headline font-weight-light card-title">
                      {{ statistic.users_info.total_students }}
                    </h2>
                    <h5 class="subtitle-2 font-weight-regular">Всего студентов</h5>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="v-card v-sheet theme--light">
              <div class="v-card__text pa-5">
                <div class="d-flex align-center">
                  <v-btn elevation="0" fab rounded readonly="true" color="primary">
                    <v-icon>
                      mdi-currency-usd
                    </v-icon>
                  </v-btn>
                  <div class="ml-2 mr-1">
                    <h2 class="headline font-weight-light card-title">
                      {{ statistic.users_info.total_free_registrations }}
                    </h2>
                    <h5 class="subtitle-2 font-weight-regular">Зарегистрировано на бесплатные</h5>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="v-card v-sheet theme--light">
              <div class="v-card__text pa-5">
                <div class="d-flex align-center">
                  <v-btn elevation="0" fab rounded readonly="true" color="primary">
                    <v-icon>
                      mdi-credit-card-outline
                    </v-icon>
                  </v-btn>
                  <div class="ml-2 mr-1">
                    <h2 class="headline font-weight-light card-title">{{
                        statistic.users_info.total_payment_registrations
                      }}</h2>
                    <h5 class="subtitle-2 font-weight-regular">Зарегистрировано на платные</h5>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="v-card v-sheet theme--light">
              <div class="v-card__text pa-5">
                <div class="d-flex align-center">
                  <v-btn elevation="0" fab rounded readonly="true" color="primary">
                    <v-icon>
                      mdi-percent
                    </v-icon>
                  </v-btn>
                  <div class="ml-2 mr-1">
                    <h2 class="headline font-weight-light card-title">{{statistic.finished_stat_total}}%</h2>
                    <h5 class="subtitle-2 font-weight-regular">Доходимость</h5>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </div>
        <v-row>
          <v-col>
            <div class="v-card v-sheet theme--light">
              <div class="v-card__text pa-5">
                <div class="d-flex align-center">
                  <v-btn elevation="0" fab rounded readonly="true" color="primary">
                    <v-icon>
                      mdi-credit-card-settings-outline
                    </v-icon>
                  </v-btn>
                  <div class="ml-2 mr-1">
                    <h2 class="headline font-weight-light card-title">
                      {{ statistic.users_info.income }}
                    </h2>
                    <h5 class="subtitle-2 font-weight-regular">Доход</h5>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="v-card v-sheet theme--light">
              <div class="v-card__text pa-5">
                <div class="d-flex align-center">
                  <v-btn elevation="0" fab rounded readonly="true" color="primary">
                    <v-icon>
                      mdi-checkbook
                    </v-icon>
                  </v-btn>
                  <div class="ml-2 mr-1">
                    <h2 class="headline font-weight-light card-title">
                      {{ statistic.users_info.avg_income }}
                    </h2>
                    <h5 class="subtitle-2 font-weight-regular">Средний чек</h5>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <div class="row">
          <v-col>
            <apexchart height="325" type="line" :options="chartOptions" :series="series"/>
          </v-col>
        </div>
        <div class="row">
          <div class="col">
            <div class="row">
              <v-col>
                <vue-funnel-graph
                  style="background: #393862"
                  :labels="funnelGraphPayment.labels"
                  :values="paymentFunnelData"
                  :direction="funnelGraphPayment.direction"
                  :width="funnelGraphPayment.width"
                  :height="funnelGraphPayment.height"
                  :animated="true"
                  :colors="funnelColors"
                  :display-percentage="true"
                />
              </v-col>
              <v-col>
                <div class="row">
                  <v-col>
                    <div class="v-card v-sheet theme--light">
                      <div class="v-card__text pa-5">
                        <div class="d-flex align-center">
                          <v-btn elevation="0" fab rounded readonly="true" color="primary">
                            <v-icon>
                              mdi-credit-card-settings-outline
                            </v-icon>
                          </v-btn>
                          <div class="ml-2 mr-1">
                            <h2 class="headline font-weight-light card-title">
                              {{ statistic.users_info.income }}
                            </h2>
                            <h5 class="subtitle-2 font-weight-regular">Доход</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </div>
                <div class="row">
                  <v-col>
                    <div class="v-card v-sheet theme--light">
                      <div class="v-card__text pa-5">
                        <div class="d-flex align-center">
                          <v-btn elevation="0" fab rounded readonly="true" color="primary">
                            <v-icon>
                              mdi-checkbook
                            </v-icon>
                          </v-btn>
                          <div class="ml-2 mr-1">
                            <h2 class="headline font-weight-light card-title">
                              {{ statistic.users_info.avg_income }}
                            </h2>
                            <h5 class="subtitle-2 font-weight-regular">Средний чек</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </div>
              </v-col>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <v-col>
                <vue-funnel-graph
                  style="background: #393862"
                  :values="freeFunnelData"
                  :labels="funnelGraphFree.labels"
                  :direction="funnelGraphFree.direction"
                  :width="funnelGraphFree.width"
                  :height="funnelGraphFree.height"
                  :animated="true"
                  :display-percentage="true"
                />
              </v-col>
              <v-col>
                <div class="row">
                  <v-col>
                    <div class="v-card v-sheet theme--light">
                      <div class="v-card__text pa-5">
                        <div class="d-flex align-center">
                          <v-btn elevation="0" fab rounded readonly="true" color="primary">
                            <v-icon>
                              mdi-credit-card-settings-outline
                            </v-icon>
                          </v-btn>
                          <div class="ml-2 mr-1">
                            <h2 class="headline font-weight-light card-title">
                              {{ statistic.users_info.income }}
                            </h2>
                            <h5 class="subtitle-2 font-weight-regular">Доход</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </div>
                <div class="row">
                  <v-col>
                    <div class="v-card v-sheet theme--light">
                      <div class="v-card__text pa-5">
                        <div class="d-flex align-center">
                          <v-btn elevation="0" fab rounded readonly="true" color="primary">
                            <v-icon>
                              mdi-checkbook
                            </v-icon>
                          </v-btn>
                          <div class="ml-2 mr-1">
                            <h2 class="headline font-weight-light card-title">
                              {{ statistic.users_info.avg_income }}
                            </h2>
                            <h5 class="subtitle-2 font-weight-regular">Средний чек</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </div>
              </v-col>
            </div>
          </div>
        </div>
        
        <v-row class="mt-8">
          <h1>Курсы</h1>
        </v-row>
        <v-row class="align-center mt-4"> 
          <v-col cols="4" lg="4">
            <v-text-field
              v-model="searchCourses"
              prepend-inner-icon="mdi-magnify"
              label="Поиск..."
              filled
              background-color="transparent"
              hide-details
              name="title"
            >

            <v-btn v-if="searchCourses" class="reset-button"
                    slot="append"
                    icon
                    @click="searchCourses = null"
                    color="red"
              >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
        <div class="row mt-8" v-if="statistic.courses_statistics.length > 0">
          <v-col>
            <v-row no-gutters v-for="(course, index) in statistic.courses_statistics" :key="index" class="mb-4">
              <v-col md="5">
                <v-card class="mx-auto" outlined>
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="text-overline mb-4">
                        {{ course.general_information.title }}
                      </div>
                      <v-col>
                        <v-row>
                          Базовая цена курса: {{ course.general_information.amount }}
                        </v-row>
                        <v-row v-if=" course.recommended_price > 0">
                          Рекомендуемая цена курса: {{ course.recommended_price }}
                        </v-row>
                        <v-row>
                          Доход: {{ course.income }}
                        </v-row>
                        <v-row>
                          Число продаж: {{ course.quantity }}
                        </v-row>
                        <v-row v-if="course.quantity">
                          Ср. цена продажи: {{ course.avg_price }}
                        </v-row>
                        <v-row>
                          Среднее число дней прохождения: {{ course.avg_time }}
                        </v-row>
                        <v-row>
                          Видео на курсе: {{ course.course_total_videos }}
                        </v-row>
                        <v-row v-if="course.finished_stat>=0" >
                          Доходимость: {{ course.finished_stat }}%
                        </v-row>
                      </v-col>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col md="5" class="ml-5 mr-5">
                <vue-funnel-graph
                  style="background: #393862"
                  :labels="funnelGraphCourse.labels"
                  :values="course.chartValues"
                  :direction="funnelGraphCourse.direction"
                  :width="funnelGraphCourse.with"
                  :height="funnelGraphCourse.height"
                  :animated="true"
                  :colors="funnelColors"
                  :display-percentage="true"
                />
              </v-col>              
            </v-row>
            <v-pagination
              v-model="options.page"
              :length="options.last_page"
            ></v-pagination>
          </v-col>
        </div>
        </v-row>

      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axiosInstance from "../../../axiosInstance";
import {VueFunnelGraph} from 'vue-funnel-graph-js';
import moment from 'moment';

export default {
  name: "Index",
  components: {VueFunnelGraph},
  data: () => ({
    statistic: {},
    type: 'Column2D',
    width: '500',
    height: '300',
    dataFormat: 'json',
    options: {
      page: 1,
      last_page: 1,
      date_from: null,
      date_to: null,
      search: null,
    },
    dataSource: {
      chart: {
        caption: 'Vue FusionCharts Sample',
        theme: 'fint'
      },
      data: [{value: 1.9}, {value: 2.3}, {value: 2.1}]
    },
    displayValue: 'nothing',
    funnelColors: ['#f87171', '#833de6'],
    funnelGraphPayment: {
      labels: ['Регистраций на платные', 'Поступило оплат', 'Повторная покупка'],
      values: [300, 500, 700],
      height: 500,
      width: 300,
      direction: 'vertical'
    },
    funnelGraphFree: {
      labels: ['Регистраций на бесплатные', 'Регистраций на платные', 'Поступило оплат', 'Активировали промокод'],
      values: [300, 500, 700],
      height: 500,
      width: 300,
      direction: 'vertical'
    },
    funnelGraphCourse: {
      labels: ['Посещение главной страницы курса', 'Окончило курс'],
      height: 350,
      with: 250,
      direction: 'vertical',
    },
    page: {
      title: "Статистика"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Курсы",
        disabled: false,
        to: '/courses/'
      },
      {
        text: "Статистика",
        disabled: true,
        to: '/statistics'
      },
    ],
    datePicker: false,
    dateFilter: null,
    searchCourses: null,    
  }),
  computed: {
    chartOptions() {
      return {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: 'Регистраций',
          align: 'center'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: this.statistic.users_info.graphic_registrations.keys,
        }
      }
    },
    series() {
      return [
        {
          data: this.statistic.users_info.graphic_registrations.data,
        }
      ]
    },
    isLoaded() {
      return Object.keys(this.statistic).length > 0;
    },
    paymentFunnelData() {
      return [
        this.statistic.payment_funnel_charts.total_orders,
        this.statistic.payment_funnel_charts.total_payment_orders,
        this.statistic.payment_funnel_charts.repeat_purchased,
      ]
    },
    freeFunnelData() {
      return [
        this.statistic.free_funnel_charts.total_free_registrations,
        this.statistic.free_funnel_charts.total_payment_registrations,
        this.statistic.free_funnel_charts.total_payment_orders,
        this.statistic.free_funnel_charts.used_promocodes,
      ]
    }
  },
  async created() {
    this.getData();    
  },
  methods: {
    async getData(){
      let requestData = {'page': this.options.page, 'limit': 5}
      if(this.options.date_from)
        requestData.date_from = this.options.date_from;

      if(this.options.date_to)
        requestData.date_to = this.options.date_to;

      if(this.options.search)
        requestData.search = this.options.search;

      await axiosInstance.post('statistic', requestData).then((response) => {
        this.statistic = response.data;
        this.options.last_page = response.data.last_page;
      });
    },
    dateFilterMethod(){
      if(this.dateFilter){
        let dateFrom = this.filterformatDate(this.dateFilter[0]);       
        let dateTo = this.dateFilter[1] ? this.filterformatDate(this.dateFilter[1]) : dateFrom;
        this.options.date_from = dateFrom;
        this.options.date_to = dateTo;        
        this.getData();
      }
    },
    datePickerAction(){
      if(this.dateFilter)
        if(this.dateFilter.length > 1){
          if(this.dateFilter[1] < this.dateFilter[0]){
            let date = this.dateFilter[0];
            this.dateFilter[0] = this.dateFilter[1];
            this.dateFilter[1] = date;
          }
        }      
      this.dateFilterMethod();
      this.datePicker = false;
    },
    filterformatDate(dateString){ 
      if(dateString){        
        return moment(dateString).format('YYYY-MM-DD'); 
      }     
    },
  },
  watch: {
    'options.page'() {
      this.getData();
    },
    dateFilter: {
      handler(value) {
        if(value == null){
          this.options.date_from = null;
          this.options.date_to = null;
          this.getData();
        }
          
      }
    },
    searchCourses: {
      handler(value) {
          this.options.search = value;
          if(value == null || value.length == 0 || value.length >= 3){
            this.options.page = 1;            
            this.getData();
          }           
      }
    },    
  }
}
</script>

<style scoped>

</style>
