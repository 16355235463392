<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <h1>Редактирование каталога {{ fetchedDetail.id }}</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <regional-practices-form :regional-practices="fetchedDetail" @onSubmit="submit"/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import RegionalPracticesForm from "../../../components/regionalPractices/RegionalPracticesForm";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Edit",
  components: {RegionalPracticesForm},
  data: () => ({
    page: {
      title: "Редактирование каталога"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Региональные практики",
        disabled: true,
        to: '/regional-practices/'
      },
      {
        text: "Редактирование",
        disabled: true,
        to: '/regional-practices/edit'
      },
    ],
  }),
  async created(){
    await this.fetchDetail(this.$route.params.id);
  },
  methods: {
    ...mapActions({
      fetchDetail: "RegionalPractices/fetchDetail",
      updateItems: "RegionalPractices/updateItem",
    }),
    submit({ form, isNeedRedirect }) {
      this.updateItems({ form: form, isNeedRedirect: isNeedRedirect, router: this.$router });
    }
  },
  computed: {
    ...mapGetters({
      fetchedDetail: "RegionalPractices/getFetchedDetail",
    })
  }
}
</script>

<style scoped>

</style>
