import axiosInstance from "../../../axiosInstance";
import {Cron} from "../../../models/cron";

export default {
  namespaced: true,
  state: {
    fetchedListing: [],
    fetchedDetail: {},
    listingTotalItems: 0,
    isLoading: false,
  },
  getters: {
    getListings: (state) => state.fetchedListing,
    getDetail: (state) => state.fetchedDetail,
    totalItems: (state) => state.listingTotalItems,
    isLoading: (state) => state.isLoading,
  },
  mutations: {
    setFetchedListing(state, val) {
      state.fetchedListing = val;
    },
    setListingTotalItems(state, val) {
      state.listingTotalItems = val;
    },
    setIsLoading(state, val) {
      state.isLoading = val;
    },
    setFetchedDetail(state, val) {
      state.fetchedDetail = val;
    }
  },
  actions: {
    async fetchListings({ commit, dispatch }, { itemsPerPage, page }) {
      commit('setIsLoading', true);

      await axiosInstance.get(`cron?limit=${itemsPerPage}&page=${page}`).then((response) => {
        commit('setFetchedListing', response.data.data);
        commit('setListingTotalItems', response.data.meta.total);
      }).catch(() => {
        dispatch('snackbar/showUnknownError', null, { root: true })
      });

      commit('setIsLoading', false);
    },
    async destroyItem({ commit, dispatch }, { id } ) {
      commit('setIsLoading', true);

      Cron.$query().destroy(id).then(() => {
        dispatch('snackbar/showSuccessfullyDelete', null, { root: true })
      }).catch(() => {
        dispatch('snackbar/showDeleteError', null, { root: true })
      })

      commit('setIsLoading');
    },
    async storeItem({ commit, dispatch }, form) {
      commit('setIsLoading', true);

      Cron.$query().store(form).then(() => {
        dispatch('snackbar/showSuccessfullyDelete', null, { root: true })
      }).catch(() => {
        dispatch('snackbar/showDeleteError', null, { root: true })
      })

      commit('setIsLoading');
    },
    async findItem({ commit, dispatch }, id) {
      commit('setIsLoading', true);

      Cron.$query().find(id).then((response) => {
        commit('setFetchedDetail', response.$attributes);
      }).then(() => {
        dispatch('snackbar/showUnknownError', null, { root: true })
      })

      commit('setIsLoading', false);
    },
    async updateItem({ commit, dispatch }, form) {
      commit('setIsLoading', true);

      Cron.$query().update(form.id, form).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, { root: true })
      }).catch(() => {
        dispatch('snackbar/showUnknownError', null, { root: true })
      })

      commit('setIsLoading', false);
    }
  }
}
