import Vue from 'vue';
import Vuetify from "vuetify/lib";
import "@/scss/vuetify/overrides.scss";

Vue.use(Vuetify);

const theme = {
  primary: "#922bc5", // change header color from here || "#1e88e6", "#21c1d6", "#fc4b6c", "#563dea", "#9C27b0", "#ff9800"
  info: "#922bc5",
  success: "#21c1d6",
  accent: "#fc4b6c",
  default: "#563dea",
};

export default new Vuetify({
  // customProperties: true,
  // icons: {
  //   iconfont: 'mdiSvg'
  // },
  theme: {
    themes: {
      dark: theme,
      light: theme,
      // light: {
      //   primary: '#922bc5',
      //   secondary: '#b0bec5',
      //   accent: '#420063',
      //   error: '#b71c1c',
      // },
    },
    dark: false, // If you want to set dark theme then dark:true else set to false
  },
  rtl: false, // If you want to set rtl theme then rtl:true else set to false
});
