<template>
  <div>
    <v-row v-if="selectedItems.length > 0">
      <v-col>
        <h3>Сортировка Уроков</h3>
        <v-card style="max-height: 400px; overflow-y: scroll">
          <v-list>
            <v-list-item-group>
              <draggable v-model="selectedItems">
                <v-list-item
                  v-for="(item, i) in selectedItems"
                  :key="i"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-arrow-all</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      Порядковый номер:{{ i + 1}}
                      <br>
                      Урок:
                      <b>{{ item.title }}</b>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-row>
                     <!--<v-btn
                        icon
                        v-if="item.preview_url"
                        :href="item.preview_url"
                        target="_blank"
                      >
                        <v-icon color="primary">
                          mdi-eye
                        </v-icon>
                      </v-btn>-->
                      <v-btn
                        @click="attachItem(item)"
                        icon
                        color="primary"
                      >
                        <v-icon>
                          mdi-eye-plus
                        </v-icon>
                      </v-btn>
                      <v-btn
                        :href="editItem(item)"
                        icon
                      >
                        <v-icon color="primary">
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                      <v-btn
                        @click="deleteItem(item)"
                        icon
                      >
                        <v-icon color="red">
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>
              </draggable>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>Привязка существующих уроков</h3>
        <v-card>
          <v-text-field v-model="searchString" label="Поиск"/>
          <v-data-table
            :headers="headers"
            :items="searchableLessons"
            no-data-text="Ничего не найдено..."
            >
            <template v-slot:item.actions="{ item }">
              <v-btn icon small color="primary" target="_blank" :href="editSearchableItem(item)">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
              <v-btn icon small color="green" @click="copyItem(item)">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-flex>
          <v-btn
            class="mr-4"
            color="primary"
            @click="submit"
          >
            Сохранить
          </v-btn>
          <v-btn
            class="mr-4"
            color="primary"
            @click="updateAlert"
            :to="`/course/${course.id}/lessons/create`"
          >
            Новый урок
          </v-btn>
        </v-flex>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import {Lesson} from "../../models/lesson";
import {mapActions} from "vuex";
import axiosInstance from "../../axiosInstance";

export default {
  name: "CourseLessonsForm",
  components: {
    draggable,
  },
  props: {
    course: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedItems: [],
      searchString: null,
      searchableLessons: [],
      headers: [
        {text: 'Id', value: 'id'},
        {text: 'Название', value: 'title'},
        {text: 'Название курса', value: 'course_title' },
        {text: 'Описание урока', value: 'description'},
        {text: '', value: 'actions'},
      ],
    }
  },
  methods: {
    ...mapActions({
      elasticSearch: "Lessons/elasticSearch",
      onSuccess: 'snackbar/showSuccessfullyDelete',
      onSuccessCopy: "snackbar/showSuccessCopy",
      onErrorCopy: "snackbar/showErrorCopy",
      onError: 'snackbar/showDeleteError',
      makeCopy: "Lessons/copyItem",
      fetchDetail: "Courses/fetchDetail",
    }),
    submit() {
      const ids = {};

      this.selectedItems.map((item, index) => {
        ids[item.id.toString()] = {'position': `${index + 1}`}
      })

      this.$emit('onSubmit', ids);
    },
    attachItem(item) {
      axiosInstance.get(`/auth/user/course/${this.course.id}/attach`)
        .then((response) => {
          window.open(response.data + `/${item.slug}?preview_mode=true`);
        });
    },
    editItem(item) {
      return this.$router.resolve({name: 'lessons-edit', params: {id: item.id, course_id: this.course.id}}).href
    },
    editSearchableItem(item) {
      return this.$router.resolve({name: 'lessons-edit', params: {id: item.id, course_id: item.course_id}}).href
    },
    deleteItem(item) {
      Lesson.$query().destroy(item.id).then(() => {
        this.onSuccess();
        this.$emit('reloadEntity');
        this.updateAlert();
      }).catch(() => {
        this.onError();
      });
    },
    copyItem(item) {
      this.makeCopy({
        course_id: this.course.id,
        id: item.id,
      }).then(() => {
        this.onSuccessCopy();
        this.fetchDetail(this.course.id)
        this.updateAlert();
      }).catch(() => {
        this.onErrorCopy();
      })
    },
    updateAlert(){
      alert('После привязки и удаления уроков курса рекомендуется пересчитать стоимость');
    }
  },
  watch: {
    course: {
      handler(value) {
        if (value.lessons && value.lessons.length > 0) {
          this.selectedItems = value.lessons;
        }
      },
      deep: true,
      immediate: true,
    },
    searchString: {
      handler(value) {
        if (value.length >= 3) {
          this.elasticSearch(this.searchString).then((resp) => {
            this.searchableLessons = resp.data;
          })
        }
      }
    }
  }
}
</script>
