<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Изменения</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select label="Операция" :items="operations" v-model="query.operation"/>
      </v-col>
      <v-col>
        <v-select :items="entitiesList" label="Тип сущности" v-model="query.entity_type"/>
      </v-col>
      <v-col v-if="false">
        <v-text-field label="Поиск по названию сущности" v-model="query.entity_search"/>
      </v-col>
      <v-col>
        <v-select :items="usersList" item-value="id" item-text="name" label="Пользователь" v-model="query.causer_id"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :items="items"
          :headers="headers"
          :server-items-length="totalItems"
          :page.sync="query.page"
          :items-per-page.sync="query.per_page"
          disable-sort
        >
          <template v-slot:item.operation="{ item }">
            <v-chip :color="getColor(item)" text-color="white">
              {{ item.operation }}
            </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom v-if="item.operation === 'Обновление'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="showModal = true; selectedItem = item"
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-icon color="primary">mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>Просмотр изменений</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      width="900"
      v-model="showModal"
    >
      <v-card>
        <v-toolbar color="primary" dark>
          Измененные поля
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-expansion-panels>
              <v-expansion-panel v-for="(attribute, key) in selectedItem.changes" :key="key">
                <v-expansion-panel-header>
                  {{ key }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col style="background-color: rgb(255, 0, 0, 0.1)">
                      {{ attribute.old }}
                    </v-col>
                    <v-col style="background-color: rgb(0, 255, 0, 0.1)">
                      {{ attribute.new }}
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>

        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Index",
  created() {
    this.fetchItems(this.query);
    this.fetchUsers();
    this.fetchEntities();
  },
  data: () => ({
    headers: [
      { text: "ID", value: "id" },
      { text: "Пользователь", value: "causer" },
      { text: "Операция", value: "operation" },
      { text: "Тип сущность", value: "subject_type" },
      { text: "Название сущности", value: "subject" },
      { text: "ID сущности", value: "subject_id" },
      { text: "Дата и время изменения", value: "created_at" },
      { text: "", value: "actions" },
    ],
    operations: [
      { value: null, text: "Все" },
      { value: "updated", text: "Обновление" },
      { value: "deleted", text: "Удаление" },
      { value: "created", text: "Создание" },
    ],
    query: {
      entity_type: null,
      entity_search: null,
      operation: null,
      causer_id: null,
      page: 1,
      per_page: 15,
    },
    showModal: false,
    selectedItem: {},
  }),
  computed: {
    ...mapGetters({
      items: "ActivityLogs/getFetchedListing",
      usersList: "ActivityLogs/getUsersList",
      entitiesList: "ActivityLogs/getEntitiesList",
      totalItems: "ActivityLogs/getListingTotalItems"
    })
  },
  methods: {
    ...mapActions({
      fetchItems: "ActivityLogs/fetchFilteredListing",
      fetchUsers: "ActivityLogs/fetchUserList",
      fetchEntities: "ActivityLogs/fetchEntitiesList",
    }),
    getColor({ operation }) {
      switch (operation) {
        case "Обновление":
          return "orange";
        case "Создание":
          return "green";
        case "Удаление":
          return "red";
        default:
          return "gray";
      }
    }
  },
  watch: {
    query: {
      handler() {
        this.fetchItems(this.query);
      },
      deep: true,
    }
  }
}
</script>

<style scoped>

</style>
