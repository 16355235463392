import { render, staticRenderFns } from "./BlockSettingForm.vue?vue&type=template&id=8e9e318e&scoped=true&"
import script from "./BlockSettingForm.vue?vue&type=script&lang=js&"
export * from "./BlockSettingForm.vue?vue&type=script&lang=js&"
import style0 from "./BlockSettingForm.vue?vue&type=style&index=0&id=8e9e318e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e9e318e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VRadio,VRadioGroup,VTextField})
