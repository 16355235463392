import {SystemPhrase} from "../../../models/systemPhrase";
import axiosInstance from "../../../axiosInstance";

export default {
  namespaced: true,
  state: {
    fetchedListing: [],
    isLoading: false,
  },
  getters: {
    getFetchedListing: (state) => state.fetchedListing,
    isLoading: (state) => state.isLoading,
  },
  mutations: {
    setFetchedListing(state, val) {
      state.fetchedListing = val;
    },
    setIsLoading(state, val) {
      state.isLoading = val;
    }
  },
  actions: {
    fetchListing({commit, dispatch}) {
      commit('setIsLoading', true);

      return SystemPhrase.$query().get(1000000).then((resp) => {
        commit('setFetchedListing', resp.map((item) => item.$attributes));
      }).catch(() => {
        dispatch("snackbar/showUnknownError", null, {root: true});
      }).finally(() => {
        commit('setIsLoading', false);
      })
    },
    async saveData({commit, dispatch}, {insertRecords, removeRecords, updateRecords}) {
      commit('setIsLoading', false);

      let promises = [];

      if (insertRecords.length) {
        promises.push(axiosInstance.post('system-phrase/batch', {resources: insertRecords.reverse()}));
      }

      if (removeRecords.length) {
        promises.push(axiosInstance.delete('system-phrase/batch', {
          data: {
            resources: removeRecords.map(({id}) => (id)),
          }
        }));
      }

      if (updateRecords.length) {
        let prepareObject = {};

        updateRecords.forEach((item) => {
          prepareObject[item.id] = item;
        })
        promises.push(axiosInstance.patch('system-phrase/batch', {resources: prepareObject}));
      }

      return Promise.all(promises).then(() => {
        dispatch("snackbar/showSuccessfullySaved", null, { root: true })
      }).catch(() => {
        dispatch("snackbar/showSaveError", null, { root: true })
      }).finally(() => {
        commit('setIsLoading', false);
      });
    }
  }
}
