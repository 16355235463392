import Index from '../../views/main/answers/Index';
import Edit from '../../views/main/answers/Edit';

export default [
  {
    component: Index,
    name: 'answers-index',
    path: '/answers',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
  {
    component: Edit,
    name: 'answers-edit',
    path: '/answers/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  }
]
