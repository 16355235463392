import { v4 as uuidv4 } from 'uuid';

export default {
  namespaced: true,
  state: {
    blocks: [],
    form: {},
  },
  getters: {
    getBlocks: state => state.blocks,
    getForm: state => state.form,
  },
  mutations: {
    updatePageForm(state, form) {
      state.form = form;
    },
    setBlocks(state, blocks) {
      state.blocks = blocks;
    },
  },
  actions: {
    setBlocks({ commit }, blocks) {
      const newBlocks = JSON.parse(JSON.stringify(blocks));
      newBlocks.forEach((newBlock) => {
        if (!newBlock.id) {
          newBlock.id = uuidv4();
        }
      })
      commit('setBlocks', newBlocks)
    },
    updateForm({ commit }, form) {
      commit('updateForm', JSON.parse(JSON.stringify(form)))
    },
  },
};
