<template>
  <v-flex class="uploader">
    {{ label }}
    <v-flex
      class="uploader__images"
    >
      <div
        v-for="media in currentMedias"
        :key="`${media.url}/${media.id}`"
        class="uploader__image"
      >
        <template v-if="media.file_type !== 1">
          <img
            :src="media.crop_url ? media.crop_url : media.url"
            crossorigin="*"
            alt=""
          />
          <div class="uploader__image-actions">
            <v-icon @click="onDelete(media)">
              mdi-delete
            </v-icon>
            <!--<v-icon @click="onEdit(media)">
              mdi-content-cut
            </v-icon>-->
          </div>
        </template>
        <template v-else>
          <div class="file-container">
            <img :src="media.file_image || media.file_image_preview"/>
            <v-icon class="position-absolute" color="red" @click="onDelete(media)">
              mdi-delete
            </v-icon>
            <a :href="media.url" class="position-absolute" style="left: 0px;text-decoration: none">
              <v-icon color="green">mdi-download</v-icon>
            </a>
          </div>
          {{ media.file_name }}
        </template>
      </div>
      <div
        v-if="currentMedias.length <= 0"
        class="uploader__image uploader__image--drop"
        @dragover.prevent
        @drop="onDrop"
      />
    </v-flex>
    <v-dialog
      v-model="isShowModal"
      width="800"
      height="100%"
      class="overflow-auto"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Edit image
        </v-card-title>

        <v-card-text>
          <cropper
            ref="cropper"
            :src="editedURL"
            @change="onChangeCropper"
            @ready="onReadyCropper"
          />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="onUpdate"
          >
            I accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-flex>
</template>

<script>
import {Cropper} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import {eventBus} from '../main';

export default {
  name: 'MediaWidget',
  props: {
    label: {
      type: String,
      required: true,
    },
    medias: {
      type: [Array, Object],
      default: () => ([]),
    },
    isMultiple: {
      type: Boolean,
      default: true,
    },
    btnTitle: {
      type: String,
      default: 'Upload'
    },
  },
  components: {
    Cropper,
  },
  mounted() {
    if (eventBus) {
      eventBus.$on('deleteMedia', media => {
        const index = this.currentMedias.findIndex(({id}) => id === media.id)
        this.currentMedias.splice(index, 1);
        this.changeEvent();
      })
    }
  },
  data: () => ({
    currentMedias: [],
    editedURL: null,
    editedId: null,
    isShowModal: false,
  }),
  methods: {
    onChangeCropper({coordinates}) {
      const index = this.currentMedias.findIndex(({id}) => id === this.editedId);
      this.currentMedias[index].coordinates = coordinates;
    },
    onReadyCropper() {
      const index = this.currentMedias.findIndex(({id}) => id === this.editedId);
      if (this.currentMedias[index].coordinates && Object.keys(this.currentMedias[index].coordinates).length > 0) {
        this.$refs.cropper.setCoordinates(this.currentMedias[index].coordinates);
      }
    },
    async onEdit(media) {
      this.editedURL = media.url;
      this.editedId = media.id;
      this.isShowModal = true;
    },
    async onUpload(file) {
      const isExists = this.currentMedias.some(({id}) => id === file.id);
      if (!isExists) {
        if (!this.isMultiple && this.currentMedias.length === 1) {
          await this.onDelete(this.currentMedias[0]);
        }
        this.currentMedias.push(file);
        this.changeEvent();
      }
    },
    async onDelete(media) {
      const index = this.currentMedias.findIndex(currentMedia => currentMedia.id === media.id);
      this.currentMedias.splice(index, 1);
      this.changeEvent();
    },
    async onUpdate() {
      const index = this.currentMedias.findIndex(({id}) => id === this.editedId);
      const {coordinates, canvas} = this.$refs.cropper.getResult();
      this.editedId = null;
      this.editedURL = null;
      this.isShowModal = false;
      this.currentMedias[index].crop_url = canvas.toDataURL();
      this.currentMedias[index].coordinates = coordinates;
      this.changeEvent();
    },
    changeEvent() {
      if (this.isMultiple) {
        this.$emit('onChange', this.currentMedias);
      } else {
        this.$emit('onChange', this.currentMedias.length === 1 ? this.currentMedias[0] : null);
      }
    },
    onDrop($event) {
      const media = JSON.parse($event.dataTransfer.getData('text'));
      this.onUpload(media);
    },
  },
  watch: {
    medias: {
      handler(medias) {
        if (Array.isArray(medias)) {
          this.currentMedias = medias;
        } else if (medias && Object.keys(medias).length > 0) {
          this.currentMedias = [medias];
        } else {
          this.currentMedias = [];
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.my-clipper {
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-bottom: 15px;
}
</style>

<style lang="scss" scoped>
.uploader {

  #file-upload {
    display: none;
  }

  &__images {
    display: flex;
    flex-flow: wrap;
    width: 100%;
  }

  &__image {
    position: relative;
    width: 200px;

    padding: 5px;
    box-sizing: border-box;

    img {
      width: 100%;
      height: auto;
    }

    &--drop {
      height: 100%;
      width: 200px;
      height: 100px;
      border: 1px dotted black;
    }
  }

  &__image-actions {
    position: absolute;
    width: calc(100% - 10px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    bottom: 15px;

    button, i {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      text-shadow: 0 0 5px black;
      transition: color ease-in-out .3s;

      &:first-child {
        color: #ff5252;
      }

      &:last-child {
        color: #798FF6;
      }

      &:hover {
        color: white;
        transition: color ease-in-out .3s;
        cursor: pointer;
      }
    }
  }
}
</style>
