import Index from '../../views/main/shortUrls/Index'
import Create from '../../views/main/shortUrls/Create'
import Stats from '../../views/main/shortUrls/Stats'

export default [
  {
    component: Index,
    name: 'shortUrls-index',
    path: '/shortUrls',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      hasAccess: 'shortUrls.view',
      //module: 'ShortUrl',
    }
  },
  {
    component: Create,
    name: 'shortUrls-create',
    path: '/shortUrls/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      hasAccess: 'shortUrls.create',
      //module: 'ShortUrl',
    }
  },
  {
    component: Stats,
    name: 'shortUrls-stats',
    path: '/shortUrls/stats/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      //module: 'ShortUrl',
    }
  },
]
