<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="fetchedListing"
              :options.sync="listingPayload.options"
              :server-items-length="totalItems"
              :loading="isListingLoading"
              :footer-props="{'items-per-page-options':[5, 10, 15, 20, -1]}"
            >
              <template v-slot:top>
                <v-toolbar flat class="mb-8">
                  <v-row>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        v-model="listingPayload.search"
                        append-icon="mdi-magnify"
                        label="Поиск..."
                        filled
                        background-color="transparent"
                        hide-details
                        name="title"
                      >
                        <v-btn
                          v-if="listingPayload.search"
                          class="reset-button"
                          slot="append"
                          icon
                          @click="listingPayload.search = null"
                          color="red"
                          >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-switch
                        v-model="listingPayload.only_trashed"
                        label="Показать удаленные"
                        name="only_trashed"
                        value="1"
                        style="margin-top: 16px;"/>
                    </v-col>
                  </v-row>
                  <v-spacer/>
                  <v-btn
                    color="primary"
                    to="customers/create"
                    dark
                    class="mb-2 text-capitalize"
                  >
                    <v-icon class="mr-2">mdi-face-agent</v-icon>
                    Создать клиента
                  </v-btn>
                </v-toolbar>
              </template>
              <template v-slot:item.id="{ item }">
                <v-chip pill>{{ item.id }}</v-chip>
              </template>
              <template v-slot:item.status="{ item }">
                <v-icon :color="item.status ? 'green' : 'red'">mdi-circle</v-icon>
                <v-tooltip bottom v-if="item.status_description && item.status == 0" class="ml-2">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on">
                      mdi-comment-question-outline
                    </v-icon>
                  </template>
                  <span>{{ item.status_description }}</span>
                </v-tooltip>
              </template>
              <template v-slot:item.install="{item}">
                <template v-if="item.install !== 1">
                  <v-icon :color="item.install === 2 ? 'green' : 'red'">
                    mdi-circle
                  </v-icon>
                  <v-tooltip bottom v-if="item.install_error && item.install === 0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" dark v-bind="attrs" v-on="on">
                        mdi-comment-question-outline
                      </v-icon>
                    </template>
                    <span>{{ item.install_error }}</span>
                  </v-tooltip>
                </template>
                <template v-else>
                  <v-progress-circular :size="25" color="primary" indeterminate/>
                </template>
              </template>
              <template v-slot:item.deploy="{item}">
                <template v-if="item.deploy !== 1">
                  <v-icon :color="item.deploy === 2 ? 'green' : 'red'">
                    mdi-circle
                  </v-icon>
                  <v-tooltip bottom v-if="item.deploy_error && item.deploy === 0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" dark v-bind="attrs" v-on="on">
                        mdi-comment-question-outline
                      </v-icon>
                    </template>
                    <span>{{ item.deploy_error }}</span>
                  </v-tooltip>
                </template>
                <template v-else>
                  <v-progress-circular :size="25" color="primary" indeterminate/>
                </template>
              </template>
              <template v-slot:item.theme_update="{item}">
                <template v-if="item.theme_update !== 1">
                  <v-icon :color="item.theme_update === 2 ? 'green' : 'red'">
                    mdi-circle
                  </v-icon>
                  <v-tooltip bottom v-if="item.theme_update_error && item.theme_update === 0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" dark v-bind="attrs" v-on="on">
                        mdi-comment-question-outline
                      </v-icon>
                    </template>
                    <span>{{ item.theme_update_error }}</span>
                  </v-tooltip>
                </template>
                <template v-else>
                  <v-progress-circular :size="25" color="primary" indeterminate/>
                </template>
              </template>
              <template v-slot:item.config_update="{item}">
                <template v-if="item.config_update !== 1">
                  <v-icon :color="item.config_update === 2 ? 'green' : 'red'">
                    mdi-circle
                  </v-icon>
                  <v-tooltip bottom v-if="item.config_update_error && item.config_update === 0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" dark v-bind="attrs" v-on="on">
                        mdi-comment-question-outline
                      </v-icon>
                    </template>
                    <span>{{ item.config_update_error }}</span>
                  </v-tooltip>
                </template>
                <template v-else>
                  <v-progress-circular :size="25" color="primary" indeterminate/>
                </template>
              </template>
              <template v-slot:item.admin_url="{ item }">
                <a
                  v-if="item.admin_url"
                  :href="item.admin_url"
                  target="_blank">
                  {{ item.admin_url }}
                </a>
              </template>
              <template v-slot:item.front_url="{ item }">
                <a
                  v-if="item.front_url"
                  :href="item.front_url"
                  target="_blank">
                  {{ item.admin_url }}
                </a>
              </template>
              <template v-slot:item.logo="{ item }">
                <img
                  v-if="item.logo"
                  :src="item.logo"
                />
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  :href="editLink(item)"
                  icon
                >
                  <v-icon color="primary">
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-icon
                  v-if="!item.deleted_at"
                  color="red"
                  @click="destroy(item)"
                >
                  mdi-delete
                </v-icon>
                <v-icon
                  v-else
                  color="green"
                  @click="restore(item)"
                >
                  mdi-backup-restore
                </v-icon>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on" @click="changeModelField(item.id, 'deploy', 1)">
                      mdi-cloud-upload
                    </v-icon>
                  </template>
                  <span>Запустить деплой</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on" @click="changeModelField(item.id, 'config_update', 1)">
                      mdi-cog-play
                    </v-icon>
                  </template>
                  <span>Обновить конфиги</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on" @click="changeModelField(item.id, 'theme_update', 1)">
                      mdi-theme-light-dark
                    </v-icon>
                  </template>
                  <span>Обновить темы</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Index",
  data: () => ({
    listingPayload: {
      options: {
        sortBy: ['id'],
        sortDesc: true,
        page: 1,
        itemsPerPage: 20,
      },
      search: '',
      only_trashed: null,
    },
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'Лого', value: 'logo' },
      { text: 'Статус', value: 'status'},
      { text: 'Установка', value: 'install' },
      { text: 'Деплой', value: 'deploy' },
      { text: 'Темы', value: 'theme_update' },
      { text: 'Конфигурации', value: 'config_update' },
      { text: 'Название', value: 'title' },
      { text: 'Ссылка на админ панель', value: 'admin_url' },
      { text: 'Ссылка клиента', value: 'front_url' },
      { text: '', value: 'actions' }
    ],
    page: {
      title: "Клиенты"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Клиенты",
        disabled: true,
        to: '/customers'
      },
    ],
  }),
  created() {
    this.fetchListing(this.listingPayload);
  },
  computed: {
    ...mapGetters({
      fetchedListing: 'Customers/getFetchedListing',
      totalItems: 'Customers/getListingTotalItems',
      isListingLoading: 'Customers/getIsListingLoading',
    }),
  },
  methods: {
    ...mapActions({
      fetchListing: 'Customers/fetchListing',
      restoreItem: 'Customers/restoreItem',
      destroyItem: 'Customers/destroyItem',
      changeField: 'Customers/changeField',
    }),
    editLink(item) {
      return this.$router.resolve({ name: 'customers-edit', params: { id: item.id } }).href;
    },
    restore(item) {
      this.restoreItem(item.id).then(() => {
        this.fetchListing(this.listingPayload);
      })
    },
    destroy(item) {
      this.destroyItem(item.id).then(() => {
        this.fetchListing(this.listingPayload);
      })
    },
    changeModelField(id, field, value) {
      this.changeField({
        id: id,
        payload: {
          field: field,
          value: value,
        }
      }).then(() => {
        this.fetchListing(this.listingPayload);
      })
    }
  },
  watch: {
    listingPayload: {
      handler(val) {
        this.fetchListing(val);
      },
      deep: true,
    }
  }
}
</script>

<style scoped>

</style>
