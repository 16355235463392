<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="items"
              :options.sync="options"
              :server-items-length="total"
              class="contact-listing-app"
              >
              <template v-slot:top>
                <v-toolbar flat class="mb-8">
                  <v-row>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Поиск..."
                        filled
                        background-color="transparent"
                        hide-details
                        name="title"
                        >
                        <v-btn v-if="search" class="reset-button"
                               slot="append"
                               icon
                               @click="search = null"
                               color="red"
                               >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-switch v-model="only_trashed" label="Показать удаленные" name="only_trashed" value="1" style="margin-top: 16px;"/>
                    </v-col>
                  </v-row>
                  <v-spacer/>
                  <v-btn
                    color="primary"
                    to="/collections/create"
                    dark
                    class="mb-2 text-capitalize"
                    >
                    <v-icon class="mr-2">mdi-dns</v-icon>
                    Добавить коллекцию
                  </v-btn>
                </v-toolbar>
              </template>
              <template v-slot:item.id="{ item }">
                <v-chip pill>{{ item.id }}</v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  :href="editItem(item)"
                  small
                  icon
                >
                  <v-icon color="primary">
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-icon
                  v-if="item.deleted_at"
                  color="green"
                  small
                  @click="restoreItem(item)"
                >
                  mdi-backup-restore
                </v-icon>
                <v-icon
                  v-else
                  small
                  color="red"
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:item.modules="{ item }">
                {{ item.modules ? item.modules.join(',') : '-' }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {Collection} from '../../../models/collection';
import axiosInstance from "../../../axiosInstance";

export default {
  name: 'Index',
  data: () => ({
    search: '',
    only_trashed: null,
    options: {
      sortBy: ['id'],
      sortDesc: [true],
      page: 1,
      itemsPerPage: 20,
    },
    total: 0,
    headers: [
      {text: 'Id', value: 'id'},
      {text: 'Заголовок', value: 'title'},
      {text: 'Slug', value: 'slug'},
      {text: 'Модули', value: 'modules' },
      {text: 'Дата создания', value: 'created_at'},
      {text: 'Дата обновления', value: 'updated_at'},
      {text: '', value: 'actions'},
    ],
    items: [],
    page: {
      title: "Фасетные таксономии"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Фасетные таксономии",
        disabled: true,
        to: '/collections'
      },
    ]
  }),
  created() {
    this.get();
  },
  methods: {
    editItem(item) {
      return this.$router.resolve({name: 'collections-edit', params: {id: item.id}}).href
    },
    async deleteItem(item) {
      this.$confirm('Вы уверены?', 'Удаление').then(() => {
        Collection.$query().destroy(item.id, 0);
        this.get();
      });
    },
    async restoreItem(item) {
      await Collection.$query().restore(item.id);
      await this.get();
    },
    async get() {
      // eslint-disable-next-line no-unused-vars
      const {sortBy, sortDesc, page, itemsPerPage} = this.options;
      const onlyTrashed = this.only_trashed ? `&only_trashed=1` : '';
      let data = {};
      this.search ? data.search = {value: this.search} : null;
      sortBy && sortBy[0] ? data.sort = [{field: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc'}] : null;

      await axiosInstance.post(`/collections/search?limit=${itemsPerPage}&page=${page}${onlyTrashed}`, data).then((response) => {
        this.items = response.data.data;
        this.total = response.data.meta.total;
      })
    },
  },
  watch: {
    only_trashed: {
      handler() {
        this.get();
      }
    },
    search: {
      handler(newSearch, oldSearch) {
        newSearch != oldSearch ? this.options.page = 1 : '';
        this.get();
      }
    },
    options: {
      handler() {
        this.get()
      },
      deep: true,
    },
  },
};
</script>
