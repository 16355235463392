export default [
  {
    title: "Главная",
    icon: "mdi-home",
    to: "/",
  },
  {
    title: 'Статистика',
    to: '',
    icon: 'mdi-chart-areaspline',
    module: 'statistic',
    needAccess: 'page.view',
    children: [
      {
        title: 'Общие данные',
        icon: 'mdi-chart-pie',
        to: '/statisticsV2/general'
      },
      {
        title: 'Соц сети',
        icon: 'mdi-twitter',
        to: '/statisticsV2/social'
      },
      {
        title: 'Паспорт региона',
        icon: 'mdi-earth',
        to: '/statisticsV2/regional'
      },
      {
        title: 'Медиа',
        icon: 'mdi-file-document',
        to: '/statisticsV2/media'
      },
      {
        title: 'Популярное',
        icon: 'mdi-account-group',
        to: '/statisticsV2/popular'
      },
      {
        title: 'Мероприятия',
        icon: 'mdi-calendar-check',
        to: '/statisticsV2/events'
      },
    ]
  },
  {
    title: 'Страницы',
    to: '/pages',
    icon: 'mdi-book-open-page-variant-outline',
    needAccess: 'page.view',
    module: 'pages'
  },
  {
    title: 'Лендинги',
    to: '/landings',
    icon: 'mdi-file-document-multiple-outline',
    needAccess: 'landing.view',
    module: 'landings'
  },
  {
    title: 'Медиа',
    to: '/articles',
    icon: 'mdi-format-page-break',
    needAccess: 'article.view',
    module: 'articles'
  },
  {
    group: "/courses",
    title: 'Курсы',
    icon: 'mdi-book',
    module: 'courses',
    children: [
      {
        title: 'Курсы',
        to: '/courses',
        icon: 'mdi-book',
        module: 'courses'
      },
      {
        title: 'Статистика',
        to: '/statistics',
        icon: 'mdi-chart-bell-curve',
        module: 'courses'
      }
    ]
  },
  {
    title: 'Регионы',
    to: '/regions',
    icon: 'mdi-earth',
    module: 'regions',  needAccess: 'region.view'
  },
  {
    title: 'Региональные практики',
    to: '/regional-practices',
    icon: 'mdi-web',
    module: 'library',  needAccess: 'region.view'},
  {
    title: 'Библиотека',
    to: '/library',
    icon: 'mdi-book',
    module: 'library',  needAccess: 'region.view'
  },
  {
    title: 'Календарь',
    to: '/events',
    icon: 'mdi-calendar-check',
    module: 'events'
  },
  {
    group: '/ecommerce',
    title: 'Ecommerce',
    icon: 'mdi-shopping-outline',
    module: 'ecommerce',
    children: [
      {
        title: 'Заказы',
        to: '/orders',
        icon: 'mdi-file-star',
        module: 'orders'
      },
      {
        title: 'Промокоды',
        to: '/promocodes',
        icon: 'mdi-star',
        module: 'promocodes'
      },
      {
        title: 'Абонементы',
        to: '/season-pass',
        icon: 'mdi-chart-bell-curve',
        module: 'courses'
      },
      {
        title: 'Партнеры',
        to: '/webmasterPartners',
        icon: 'mdi-human-male-female',
        module: 'webmasterPartners'
      },
      {
        title: 'Партнеры. Статистика',
        to: '/partners-statistic',
        icon: 'mdi-human-male-female',
      }
    ]
  },
  {
    title: 'Тизеры',
    to: '',
    icon: 'mdi-video-vintage',
    module: 'teasers',
    children: [
      {
        title: 'Тизеры',
        to: '/teasers',
        icon: 'mdi-file-star',
        module: 'teasers'
      },
      {
        title: 'ХАБ СНГ',
        to: '/teasers-cis',
        icon: 'mdi-star',
        module: 'teasers_cis',
        needAccess: 'teaserCis.view',
      },
    ]
  },
  {
    title: 'Обратная связь',
    to: '/leads',
    icon: 'mdi-account-multiple-plus',
    module: 'leads'
  },
  {
    title: 'Подписчики',
    to: '/subscribers',
    icon: 'mdi-heart',
    module: 'subscribers',  needAccess: 'region.view'},
  {
    title: 'Короткие ссылки',
    to: '/shortUrls',
    icon: 'mdi-link-variant-plus',
    needAccess: 'shortUrls.view'
  },
  {
    group: '/tables',
    title: 'Таблицы',
    icon: 'mdi-shopping-outline',
    module: 'ecommerce',
    children: [
      {
        title: 'Таблица курсов',
        to: '/courses/temp/',
        icon: 'mdi-file-star',
        module: 'ecommerce'
      },
      {
        title: 'Таблица квизов',
        to: '/blocks/quiz-functional/',
        icon: 'mdi-star',
        module: 'ecommerce'
      },
      {
        title: 'Таблица конспекты',
        to: '/courses/conspectus',
        icon: 'mdi-chart-bell-curve',
        module: 'courses'
      },
    ]
  },
];
