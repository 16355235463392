<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="data"
              :options.sync="options"
              :server-items-length="totalItems"
              :footer-props="{ 'items-per-page-options': [5, 10, 15, 20, -1] }"
            >
              <template v-slot:item.id="{item}">
                <v-chip pill>{{ item.id }}</v-chip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

import axiosInstance from "@/axiosInstance";

export default {
  name: 'Index',
  data: () => ({
    options: {
      page: 1,
      itemsPerPage: 20,
    },
    data: [],
    only_trashed: null,
    headers: [
      { text: 'Id', value: 'id' },
      { text: 'Тип', value: 'type' },
      { text: 'Пользователь', value: 'user' },
      { text: 'Тип', value: 'type' },
      { text: 'Статус', value: 'status' },
      { text: 'Дата обновления', value: 'status_changed_at' },
      { text: 'Кто обновил статус', value: 'status_changed_by' },
      { text: 'Дата создания', value: 'created_at' },
      { text: '', value: 'actions' },
    ],
    page: {
      title: "Брифы"
    },
    totalItems: 0,
    popularPopup:false,
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Брифы",
        disabled: true,
        to: '/blocks'
      },
    ]
  }),
  created() {
    this.fetchListing();
  },
  methods: {
    fetchListing() {
      axiosInstance.get('briefs', { params: { page: this.options.page, perPage: this.options.itemsPerPage} }).then((response) => {
        this.data = response.data.data;
        this.totalItems = response.data.meta.total;
      })
    }
  },
  watch: {
    options: {
      async handler() {
        await this.fetchListing()
      },
      deep: true,
    },
  },
};
</script>

<style scoped>

</style>
