import { Model } from "@tailflow/laravel-orion/lib/model";

export class Field extends Model<{
    title: string,
    type: number,
    extra_options: object,
    group_id: number,
}> {
    $resource(): string {
        return "module/fields/admin/fields";
    }
}
