<template>
  <div class="excel">
    <v-tabs v-model="tabs">
      <v-tab>
        Продукты
      </v-tab>
      <v-tab>
        Процедуры
      </v-tab>
      <v-tab>
        Партнеры
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tabs">
      <v-tab-item>
        <v-tabs v-model="taxonomyTabs.currentTab">
          <v-tab
            v-for="tab in taxonomyTabs.tabs"
            :key="tab.id"
            @click="selectTab(tab.id)"
          >
            {{ tab.label }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="taxonomyTabs.currentTab">
          <v-tab-item>
            <div class="tab-inner-wrap">
              <div class="remove-panel">
                <button
                  v-for="(item, i) in getCollectionItems"
                  :key="item.id"
                  @click="deleteItem(item.id, i)"
                />
              </div>
              <vue-excel-editor v-model="getCollectionItems" ref="grid" width="900" @update="onUpdate">
                <vue-excel-column field="id" :readonly="true" key-field/>
                <vue-excel-column field="title" label="Название"/>
                <vue-excel-column field="test" label="test"/>
              </vue-excel-editor>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-tab-item>
      <v-tab-item>
        <v-tabs>
          <v-tab>
            Основная
          </v-tab>
          <v-tab>
            Таксономия - Показания
          </v-tab>
          <v-tab>
            Таксономия - Кому подходит
          </v-tab>
          <v-tab>
            Таксономия - Результат
          </v-tab>
          <v-tab>
            Таксономия - Время проведения процедуры
          </v-tab>
          <v-tab>
            Таксономия - Курс процедур
          </v-tab>
          <v-tab>
            Таксономия - Кратность применения
          </v-tab>
        </v-tabs>
      </v-tab-item>
      <v-tab-item>
        <v-row>
          <v-container>
            <vue-excel-editor v-model="partnersModel" ref="grid" width="900" @update="onUpdate">
              <vue-excel-column field="id" :readonly="true" key-field/>
              <vue-excel-column field="title" label="Название"/>
              <vue-excel-column field="phone" label="Телефон"/>
              <vue-excel-column field="geographic_address" label="Адрес"/>
              <vue-excel-column field="city" label="Город"/>
              <vue-excel-column field="metro" label="Метро"/>
              <vue-excel-column field="site_url" label="Сайт"/>
            </vue-excel-editor>
          </v-container>
        </v-row>
      </v-tab-item>
    </v-tabs-items>

    <div class="left-sidebar">
      <v-btn @click="addNewItem" color="green">Добавить</v-btn>
      <v-btn @click="undo" color="orange">Отменить</v-btn>
      <v-btn @click="save" color="primary">Сохранить</v-btn>
      <v-btn to="/" color="primary">В админку</v-btn>
    </div>

    <v-dialog persistent :value="isLoading" width="300">
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Загрузка
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  created() {
    this.fetchPartners();
  },
  data: () => ({
    toDelete: [],
    toUpdate: [],
    tabs: 0,
    currentTaxonomyId: null,
    taxonomyTabs: {
      currentTab: 0,
      tabs: [
        {
          id: 1,
          label: 'Таксономия - Тип продукта'
        }
      ]
    }
  }),
  computed: {
    ...mapGetters({
      partners: "Partners/getExcelListing",
      isLoading: "Partners/isExcelLoading",
      getCollectionItems: 'excel/getCollectionItems'
    }),
    collectionItems: {
      get() {
        return this.getCollectionItems;
      },
      set(val) {
        this.setCollectionItems(val);
      }
    },
    partnersModel: {
      get() {
        return this.partners;
      },
      set(val) {
        this.setPartners(val);
      }
    },
  },
  methods: {
    ...mapActions({
      fetchPartners: "Partners/fetchExcelListing",
      storeItems: "Partners/saveExcel",
      fetchCollectionItems: 'excel/fetchCollectionItems',
      saveCollection: 'excel/saveCollection',
    }),
    ...mapMutations({
      setPartners: "Partners/setExcelListing",
      setCollectionItems: 'excel/setCollectionItems'
    }),
    addNewItem() {
      this.$refs.grid.newRecord();
    },
    deleteItem(id, index) {
      this.toDelete.push(id);
      this.$refs.grid.deleteRecord(index);
    },
    selectTab(taxonomyId) {
      this.currentTaxonomyId = taxonomyId
      this.fetchCollectionItems({
        id: taxonomyId,
      });
    },
    onUpdate(items) {
      items.forEach((item) => {
        const itemToAdd = this.collectionItems.find((collection) => collection.$id === item.$id);
        const index = this.toUpdate.findIndex(({$id}) => $id === item.$id);

        if (index + 1) {
          this.toUpdate[index] = itemToAdd
        } else {
          this.toUpdate.push(itemToAdd);
        }
      });
    },
    save() {
      // this.storeItems({
      //   toDelete: this.toDelete,
      //   toSave: this.partnersModel,
      //   toUpdate: this.toUpdate,
      // });

      this.saveCollection({
        items: this.toUpdate,
        taxonomyId: this.currentTaxonomyId,
        toDelete: this.toDelete
      })
    },
    undo() {
      this.$refs.grid.undoTransaction();
    }
  },
  mounted() {
    console.log(this.$refs.grid);
    console.log(this.$refs.grid.value);
  }
}
</script>

<style lang="scss">
  .excel {
    padding-left: 160px;
    .tab-inner-wrap {
      display: flex;
      .remove-panel {
        display: flex;
        flex-direction: column;
        padding-top: 29px;

        button {
          width: 24px;
          height: 24px;
          background: red;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;

          &::after {
            transform: rotate(45deg);
          }

          &::before {
            transform: rotate(-45deg);
          }

          &::after,
          &::before {
            content: "";
            background: #fff;
            display: block;
            position: absolute;
            width: 2px;
            height: 14px;
          }
        }
      }
    }


    .vue-excel-editor {
      display: block !important;
      max-width: 100% !important;

      .component-content {
        max-width: 100% !important;
      }

      #systable {
        width: 100% !important;
      }
    }

    .left-sidebar {
      display: flex;
      padding: 50px 20px;
      flex-direction: column;
      position: fixed;
      border-right: 1px solid #d3d3d3;
      max-width: 160px;
      bottom: 0;
      top: 0;
      left: 0;

      .v-btn {
        margin-bottom: 10px;
      }
    }
  }
</style>
