import axiosInstance from "@/axiosInstance";
import {SeasonPass} from "@/models/seasonPass";

export default {
  namespaced: true,
  state: {
    fetchedListing: [],
    listingTotalItems: 0,
    isListingLoad: false,
    isSaveButtonDisabled: false,
  },
  getters: {
    getFetchedListing: state => state.fetchedListing,
    getListingTotalItems: state => state.listingTotalItems,
    getIsListingLoad: state => state.isListingLoad,
    isSaveButtonDisabled: state => state.isSaveButtonDisabled,
  },
  mutations: {
    setFetchedListing(state, listing) {
      state.fetchedListing = listing;
    },
    setListingTotalItems(state, totalItems) {
      state.listingTotalItems = totalItems;
    },
    setIsListingLoad(state, value) {
      state.isListingLoad = value;
    },
    setIsSaveButtonDisabled(state, value) {
      state.isSaveButtonDisabled = value;
    },
  },
  actions: {
    fetchListing({commit}, {options, search, only_trashed}) {

      commit('setIsListingLoad', true);

      const {sortBy, sortDesc, page, itemsPerPage} = options;
      const onlyTrashed = only_trashed ? `&only_trashed=1` : '';
      let data = {};

      search ? data.search = {value: search} : null;
      sortBy && sortBy[0] ? data.sort = [{field: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc'}] : null;

      axiosInstance.post(`season-pass/search?limit=${itemsPerPage}&page=${page}${onlyTrashed}`, data)
        .then((response) => {
          commit('setFetchedListing', response.data.data);
          commit('setListingTotalItems', response.data.meta.total);
          commit('setIsListingLoad', false);
        });
    },
    storeItem({ commit, dispatch }, {form, isNeedRedirect, router}) {
      commit('setIsSaveButtonDisabled', true);

      SeasonPass.$query().store(form).then((response) => {
        dispatch('snackbar/showSuccessfullySaved', null, { root: true });

        if (isNeedRedirect) {
          router.push({ name: 'seasonPass-index' });
        } else {
          router.push({ name: 'seasonPass-edit', params: { id: response.$attributes.id } });
        }
      }).catch((e) => {
        if (e.response.data.message) {
          dispatch("snackbar/showSnackbar", { color: "red", text: `Ошибка: ${e.response.data.message}` }, { root: true });
        } else {
          dispatch('snackbar/showSaveError', null, { root: true });
        }
      }).finally(() => {
        commit('setIsSaveButtonDisabled', false);
      });
    },
    destroyItem({ dispatch }, id) {
      SeasonPass.$query().destroy(id).then(() => {
        dispatch('snackbar/showSuccessfullyDelete', null, { root: true });
      }).catch(() => {
        dispatch('snackbar/showDeleteError', null, { root: true });
      });
    },
  },
}
