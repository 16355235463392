<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>

    <v-card>
      <v-card-text class="pa-5">
        <vxe-toolbar>
          <template #buttons>
            <vxe-button @click="addItem" status="primary">
              <div>
                <v-icon small color="white">
                  mdi-plus
                </v-icon>
                Добавить
              </div>
            </vxe-button>
            <vxe-button @click="saveItems" status="success">
              <div>
                <v-icon small color="white">
                  mdi-content-save-check
                </v-icon>
                Сохранить
              </div>
            </vxe-button>
            <vxe-button @click="removeItems" status="danger">
              <div>
                <v-icon small color="white">
                  mdi-delete
                </v-icon>
                Удалить
              </div>
            </vxe-button>
          </template>
        </vxe-toolbar>

        <vxe-grid
          border
          resizable
          keep-source
          empty-text="Данные отсутствуют"
          ref="table"
          id="table"
          height="500"
          row-key
          :loading="loading"
          :columns="tableColumns"
          :edit-config="editConfig"
          :column-config="{ isCurrent:true, isHover: true, selected: true }"
          :seq-config="{ seqMethod }"
        >
          <template #default_drag_and_drop>
            <span class="drag-btn">
              <i class="vxe-icon--menu"></i>
            </span>
          </template>
          <template #default_header>
            <vxe-tooltip v-model="showTooltip" content="Вы можете использовать Drag and drop" ENTERABLE>
              <i class="vxe-icon--question" @click="showTooltip = !showTooltip"></i>
            </vxe-tooltip>
          </template>
          <template #title_edit="{ row }">
            <vxe-input v-model="row.title"/>
          </template>
          <template #position_edit="{ row }">
            <vxe-input v-model="row.position"/>
          </template>
          <template #field_name_edit="{ row }">
            <vxe-input v-model="row.field_name"/>
          </template>
          <template #type_edit="{ row }">
            <vxe-select v-model="row.type" placement="bottom">
              <vxe-option v-for="item in fieldTypes" :key="item.value" :value="item.value" :label="item.label"/>
            </vxe-select>
          </template>
          <template #edit_extra_options="{ row }">
            <vxe-textarea :value="JSON.stringify(row.extra_options)" @input="(event) => row.extra_options = JSON.parse(event.value)"/>
          </template>
          <template #default_type="{ row }">
            <div class="vxe-cell">
              <span class="vxe-cell--label">{{ getFieldType(row.type) }}</span>
            </div>
          </template>
          <template #default_extra_options="{ row }">
<!--            <vxe-button type="text" icon="fa fa-edit" @click="editRow(row)"/>-->
            <div>
              <span>Войдите в режим редактирования для просмотра настроек</span>
            </div>
          </template>
        </vxe-grid>
      </v-card-text>
      <vxe-modal v-model="showEdit" width="800" min-width="600" min-height="300" :title="selectedRow.title">
        <template #default v-if="selectedRow.type">
          <component :is="getComponent(selectedRow.type)" :form-data="selectedRow.extra_options" @onSubmit="onSubmitHandler"/>
        </template>
      </vxe-modal>
    </v-card>
  </v-container>
</template>

<script>
import Swal from "sweetalert2";
import Sortable from 'sortablejs';
import {mapGetters, mapActions} from "vuex";
import SelectConfigForm from "../../../fields/configForms/SelectConfigForm";
import RadioConfigForm from "../../../fields/configForms/RadioConfigForm";
import InputConfigForm from "../../../fields/configForms/InputConfigForm";
import TextAreaConfigForm from "../../../fields/configForms/TextAreaConfigForm";

export default {
  name: "DynamicFields",
  components: {SelectConfigForm, RadioConfigForm, InputConfigForm, TextAreaConfigForm},
  data: () => ({
    page: {
      title: 'Динамические поля',
    },
    SelectConfigForm,
    RadioConfigForm,
    InputConfigForm,
    TextAreaConfigForm,
    tableColumns: [
      {type: 'checkbox', width: 50},
      {type: 'seq', visible: false},
      {width: 50, title: "#", slots: {default: "default_drag_and_drop", header: "default_header"}, align: "center"},
      {field: 'id', visible: false},
      {field: 'title', title: 'Название поля', slots: {edit: "title_edit"}, editRender: {}},
      {field: 'field_name', title: 'Slug поля', slots: {edit: "field_name_edit"}, editRender: {}},
      {field: 'type', title: 'Тип поля', slots: {edit: "type_edit", default: "default_type"}, editRender: {}},
      {field: 'position', title: 'Позиция в сортировке', slots: {edit: "position_edit"}, editRender: {}},
      {field: 'extra_options', title: 'Дополнительные настройки', slots: { default: 'default_extra_options', edit: "edit_extra_options" }, editRender: {} }
    ],
    emptyObject: {
      title: null,
      field_name: null,
      type: null,
      position: null,
    },
    showTooltip: false,
    editConfig: {
      enabled: true,
      mode: 'cell',
      showStatus: true,
      trigger: 'click'
    },
    fieldTypes: [
      {
        label: "Поле для ввода (input)",
        value: 0,
      },
      {
        label: "Большое поле для ввода (text-area)",
        value: 1,
      },
      {
        label: "Выпадающий список (select)",
        value: 2,
      },
      {
        label: "Переключатели (radio buttons)",
        value: 3,
      }
    ],
    validRules: {
      name: [
        {required: true, message: 'Error'},
      ]
    },
    showEdit: false,
    selectedRow: {},
  }),
  computed: {
    ...mapGetters({
      loading: "Catalog/getIsLoading",
      dynamicFields: "Catalog/getDynamicFields",
    }),
    breadcrumbs() {
      return [
        {
          text: "Админ панель",
          disabled: false,
          href: '/'
        },
        {
          text: "Каталог",
          disabled: false,
          href: `/catalogs/${this.$route.params.catalog_id}`
        },
        {
          text: 'Настройка динамических полей',
          disabled: true,
          href: '/'
        }
      ];
    }
  },
  async created() {
    await this.loadData();
  },
  beforeDestroy() {
    if (this.sortable1) {
      this.sortable1.destroy()
    }
  },
  methods: {
    ...mapActions({
      fetchDynamicFields: "Catalog/fetchDynamicFields",
      storeDynamicFields: "Catalog/storeDynamicFields",
    }),
    async addItem() {
      const {row: newRow} = await this.$refs.table.insertAt(JSON.parse(JSON.stringify(this.emptyObject)), -1);
      await this.$refs.table.setActiveCell(newRow, 'original');
    },
    removeItems() {
      if (this.$refs.table.getCheckboxRecords().length > 0) {
        Swal.fire({
          title: 'Вы уверены?',
          text: "Вы не сможете отменить это!",
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Да, удалить это!',
          cancelButtonText: 'Отменить'
        }).then((result) => {
          if (result.value) {
            this.$refs.table.removeCheckboxRow();
          }
        });
      }
    },
    async saveItems() {
      const recordSet = this.$refs.table.getRecordset();

      await this.storeDynamicFields({
        id: this.$route.params.catalog_id,
        toUpdate: recordSet.updateRecords,
        toDelete: recordSet.removeRecords,
        toStore: recordSet.insertRecords,
      }).then(async () => {
        await this.loadData();
      })
    },
    rowDrop() {
      this.$nextTick(() => {
        const xTable = this.$refs.table
        this.sortable1 = Sortable.create(xTable.$el.querySelector('.body--wrapper>.vxe-table--body tbody'), {
          handle: '.drag-btn',
          onEnd: ({
                    newIndex,
                    oldIndex
                  }) => {
            let tableData = this.$refs.table.getTableData().fullData;
            const currRow = tableData.splice(oldIndex, 1)[0]
            tableData.splice(newIndex, 0, currRow)
            this.$refs.table.loadData(tableData);
          }
        })
      })
    },
    seqMethod(obj) {
      let tableData = this.$refs.table.getTableData().fullData;

      if (tableData[obj.$rowIndex].position !== obj.seq) {
        tableData[obj.$rowIndex].position = obj.seq;
        this.$refs.table.loadData(tableData);
      }

      return obj.seq;
    },
    async loadData() {
      await this.fetchDynamicFields(this.$route.params.catalog_id).then(async () => {
        await this.$refs.table.loadData(this.dynamicFields).then(() => {
          this.rowDrop();
        });
      });
    },
    getFieldType(value) {
      if (value !== null) {
        return this.fieldTypes.find((item) => item.value === value)?.label || '-';
      }

      return '-';
    },
    editRow(row) {
      this.formData = row.extra_options;
      this.selectedRow = row;
      this.showEdit = true;
    },
    getComponent(type) {
      switch (type) {
        case 0:
          return InputConfigForm;
        case 1:
          return TextAreaConfigForm;
        case 2:
          return SelectConfigForm;
        case 3:
          return RadioConfigForm;
        default:
          return null;
      }
    },
    onSubmitHandler(value) {
      this.selectedRow.extra_options = value;
      this.$refs.table.reloadRow(this.selectedRow, this.selectedRow, 'extra_options');
      console.log(this.$refs.table.getRecordset());
      this.showEdit = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.do-color {
  &:hover {
    color: #1296DB;
  }
}
</style>
