<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <div>
          <CronForm @onSubmit="store"/>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import CronForm from "../../../components/cron/CronForm";
import { mapActions } from "vuex";

export default {
  name: "Create",
  components: {CronForm},
  data: () => ({
    page: {
      title: "Создание CRON"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Каталог",
        disabled: false,
        to: '/cron'
      },
      {
        text: "Создание CRON",
        disabled: true,
        to: '/cron/create'
      },
    ],
  }),
  methods: {
    ...mapActions({
      storeItem: "Cron/storeItem",
    }),
    store(form) {
      this.storeItem(form).then(() => {
        this.$router.push({ name: 'cron-index' });
      });
    }
  }
}
</script>

<style scoped>

</style>
