import Index from '../../views/main/leads/Index'
import Edit from "../../views/main/leads/Edit";

export default [
  {
    component: Index,
    name: 'leads-index',
    path: '/leads',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'leads'
    }
  },
  {
    component: Edit,
    name: 'leads-edit',
    path: '/leads/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'leads'
    }
  },
]
