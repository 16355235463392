<template>
  <div>
    <p>
      <b>Пользователь:</b>
      {{ form.user ? form.user.full_name : '' }}
    </p>
    <p>
      <b>Имя:</b>
      {{ form.name }}
    </p>
    <p>
      <b>Телефон:</b>
      {{ form.phone }}
    </p>
    <p>
      <b>Email:</b>
      {{ form.email }}
    </p>

    <p v-if="userLoaded">
      <a :href="editUser(userID)">Перейти к пользователю</a>
    </p>
    <p>
      <b>Страница, с которой пришли данные:</b>
      <a :href="form.sign_url">{{ form.sign_url }}</a>
    </p>
    <p>
      <b>Создан:</b>
      {{ form.created_at }}
    </p>
    <p>
      <b>Обновлен:</b>
      {{ form.updated_at }}
    </p>
    <p v-if="form.comment">
      <template v-for="(text, key) in form.comment">
        <b :key="key">{{ key[0].toUpperCase() + key.slice(1) }}:</b>
        {{ text }}
      </template>
    </p>
    <p v-if="form.changer">
      <b>Изменил статус:</b>
      <a :href="editUser(form.changer)">{{ form.changer.full_name }}</a>
    </p>
    <v-form>
      <v-select
        v-model="form.status"
        :items="statuses"
        label="Статус"
      ></v-select>
      <v-textarea
        v-model="form.response"
        :error-messages="errors.response"
        label="Комментарий"
        name="response"
        required
      ></v-textarea>
      <h3 class="mt-2 mb-4">Ответ пользователю</h3>
      <wysiwyg-editor
              class="mb-6 ws-border"
              v-model="form.answer"
              :error-messages="errors.answer"
              label="Ответ"
              title="Ответ"
              name="answer"
      />
      <h3>Прикрепить файлы</h3>
      <v-list class="col-md-4 mb-2">
          <v-list-item v-for="item in form.media" :key="item.filepath">
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item-content>
            <v-btn
              @click="downloadFile(item)"
              icon
            >
              <v-icon color="green">
                mdi-download
              </v-icon>
            </v-btn>
            <v-btn
              @click="deleteFile(item)"
              icon
            >
              <v-icon color="red">
                mdi-delete
              </v-icon>
            </v-btn>
          </v-list-item>
      </v-list>
      <div class="mb-2">
        <input
          v-if="form.id"
          ref="file"
          type="file"
          class="input-file"
          accept=".csv, .xls, .xlsx, .doc, .docx, .docx, .gif, .jpeg, .jpg, .png, .svg, .tiff, .webp, .mp4, .aac, .mpeg, .ogg, .webm, .xml, .pdf, .wmv, .flv,"
          v-on:change="checkFile"
          :disabled="uploadFileDisabled"
          multiple
        >
      </div>
      <v-row class="col-md-4 mb-2">
        <v-btn
          v-if="leadFiles.length"
          color="green"
          class="input-style"
          @click="uploadFiles"
          :disabled="uploadFileDisabled"
          :loading="filesUploading()"
          >
            Загрузить файлы
        </v-btn>
      </v-row>
      <v-flex>
        <v-btn
          color="primary"
          :disabled="isSaveDisabled"
          :loading="isSaveDisabled"
          @click="submit"
        >
          Сохранить
        </v-btn>
        <v-btn
          v-if="answerNotNull"
          color="primary"
          @click="sendMessage"
          class="ml-2"
        >
          Отправить ответ
        </v-btn>
      </v-flex>
    </v-form>
  </div>
</template>

<script>

import WysiwygEditor from "@/components/ui/WysiwygEditor.vue";
import axiosInstance from '../../axiosInstance';
import {Lead} from "../../models/lead";

export default {
  components: {
    WysiwygEditor,
  },
  name: "LeadForm",
  props: {
    lead: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    userID: null,
    userLoaded: false,
    answerNotNull: false,
    form: {
      id: null,
      name: null,
      phone: null,
      email: null,
      comment: null,
      sign_url: null,
      status: null,
      response: null,
      answer: null,
      media: [],
    },
    statuses: [
      {text: 'Новый', value: 0},
      {text: 'Прочитано', value: 1},
      {text: 'Отвечено', value: 2},
    ],
    isSaveDisabled: false,
    leadFiles: [],
    uploadFileDisabled: false,
    filesLoadArray: [],
    apiUrl: process.env.VUE_APP_API_URL,
  }),
  created() {
    if (this.$route.params) {
      this.form = this.$route.params;
    }
  },
  methods: {
    submit() {
      this.isSaveDisabled = true;

      this.$emit('onSubmit', {
        form: this.form,
      });
    },
    editUser(item) {
      return this.$router.resolve({name: 'users-edit', params: {id: item.id}}).href
    },
    async getUser() {
        let search =
        {
          "search": {
            "value": this.form.email,
          }
        };
        (await axiosInstance.post(`/users/search?limit=10`,  search,
          ).then((res) => {
            if(res.data.data[0])
              this.userID = res.data.data[0];
          }));
      if(this.userID.id)
        this.userLoaded = true;
    },
    async sendMessage() {
      this.form.status = 2;
      await Lead.$query().update(this.form.id, this.form).then(async () => {
        await axiosInstance.get(`/leads/${this.$route.params.id}/send-message`).then((response) => {
          if(response.status == 200){
              alert("Ответ отправлен");
          }else{
              alert("Ошибка при отправлении");
          }
        });
      });
    },
    setMedia(image) {
      this.form.media = image;
    },
    async checkFile(e) {
      const supported_mimes = [
        'text/csv',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.oasis.opendocument.text',
        'application/pdf',
        'text/xml',
        'video/mpeg',
        'video/mp4',
        'video/ogg',
        'video/quicktime',
        'video/webm',
        'video/x-ms-wmv',
        'video/x-flv',
        'video/x-msvideo',
        'video/3gpp',
        'video/3gpp2',
        'image/gif',
        'image/jpeg',
        'image/pjpeg',
        'image/png',
        'image/svg+xml',
        'image/tiff',
        'image/vnd.microsoft.icon',
        'image/vnd.wap.wbmp',
        'image/webp',
      ];
      let files = [];
      e.target.files.forEach(element => {
         if (element.size < 10500000 && supported_mimes.includes(element.type)) {
          files.push(element);
        } else {
          alert('Файл имеет неверный тип или размер более 10МБ');
        }
      });

      this.leadFiles = files;
    },
    uploadFile(file) {
        if(!this.form.media)
          this.form.media = [];
        this.uploadFileDisabled = true;
        let data = new FormData();
        data.append('file', file);
        axiosInstance.post(
          `/leads/${this.form.id}/upload-file`,
          data,
        ).then((res) => {
          this.form.media.push(res.data);
          this.filesLoadArray.pop();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    uploadFiles(){
      if (this.leadFiles) {
        let self = this;
        this.leadFiles.forEach(element => {
          self.filesLoadArray.push(element);
          self.uploadFile(element);
        });
      }
    },
    deleteFile(item){
      let data = {'filepath' : item.filepath};
      axiosInstance.post(
          `/leads/delete-file`,
          data,
        ).then((res) => {
          if(res.data);
            this.form.media = this.form.media.filter(file => file.filepath != item.filepath);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async downloadFile(item){
      await axiosInstance.get(`/leads/download-file?filepath=${item.filepath}&name=${item.name}`, {responseType: 'arraybuffer'}).then((res) => {
          if(res.data){
            let a = document.createElement("a");
            let file = new Blob([res.data], {type: res.headers["content-type"]});
            a.href = URL.createObjectURL(file);
            a.download = item.name;
            a.click();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    filesUploading(){
      return this.filesLoadArray.length == 0 ? false : true;
    }
  },
  watch: {
    lead: {
      handler(lead) {
        if (lead && Object.keys(lead).length > 0) {
          this.form = lead;
          this.isSaveDisabled = false;
        }
      },
      immediate: true,
      deep: true,
    },
    "form.email": {
      handler(value) {
        if(value){
          this.getUser();
        }
      },
    },
    "form.answer": {
      handler(value) {
        if(value){
          this.answerNotNull = true;
        }else{
          this.answerNotNull = false;
        }
      },
    },
    "form.status": {
      handler(value) {
        if(value == 2){
          this.answerNotNull = false;
        }else if(this.form.answer){
          this.answerNotNull = true;
        }
      },
    },
    filesLoadArray: {
      handler(value) {
        if(value.length == 0){
          this.uploadFileDisabled = false;
          this.leadFiles = [];
        }
      },
    },
  },
}
</script>
<style scoped>
  .ws-border
  {
    border: 2px solid whitesmoke;
    border-radius: 6px;
  }
</style>
