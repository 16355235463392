<template>
  <v-container>
    <v-tabs v-model="tab">
      <v-tab>
        Основная информация
      </v-tab>
      <v-tab v-if="form.id">
        Опции
      </v-tab>
      <v-tab v-if="form.id">
        Письма
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-container>
          <v-row v-if="site.pattern_header_url && site.pattern_footer_url && site.pattern_error_url && site.pattern_not_found_url && site.id">
            <v-col>
              <div class="text text-center">
                <h3>
                  Шаблон шапки странц
                  <a target="_blank" :href="site.pattern_header_url">
                    <v-icon color="primary">
                      mdi-eye
                    </v-icon>
                  </a>
                  <a target="_blank" :href="`${site.pattern_header_url}?edit_mode=true`">
                    <v-icon color="primary">
                      mdi-pen
                    </v-icon>
                  </a>
                </h3>
              </div>
            </v-col>
            <v-col>
              <div class="text text-center">
                <h3>
                  Шаблон подвала странц
                  <a target="_blank" :href="site.pattern_footer_url">
                    <v-icon color="primary">
                      mdi-eye
                    </v-icon>
                  </a>
                  <a target="_blank" :href="`${site.pattern_footer_url}?edit_mode=true`">
                    <v-icon color="primary">
                      mdi-pen
                    </v-icon>
                  </a>
                </h3>
              </div>
            </v-col>
            <v-col>
              <div class="text text-center">
                <h3>
                  Шаблон страницы 500
                  <a target="_blank" :href="site.pattern_error_url">
                    <v-icon color="primary">
                      mdi-eye
                    </v-icon>
                  </a>
                  <a target="_blank" :href="`${site.pattern_error_url}?edit_mode=true`">
                    <v-icon color="primary">
                      mdi-pen
                    </v-icon>
                  </a>
                </h3>
              </div>
            </v-col>
            <v-col>
              <div class="text text-center">
                <h3>
                  Шаблон страницы 404
                  <a target="_blank" :href="site.pattern_not_found_url">
                    <v-icon color="primary">
                      mdi-eye
                    </v-icon>
                  </a>
                  <a target="_blank" :href="`${site.pattern_not_found_url}?edit_mode=true`">
                    <v-icon color="primary">
                      mdi-pen
                    </v-icon>
                  </a>
                </h3>
              </div>
            </v-col>
          </v-row>
          <v-row v-else-if="site.id">
            <v-col>
              <v-btn color="primary" block @click="generatePatterns">Сгенерировать шаблоны страниц</v-btn>
            </v-col>
          </v-row>
          <v-form>
          <v-row>
            <v-col>
              <v-text-field
                prepend-icon="mdi-web"
                label="Название сайта"
                v-model="form.title"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                prepend-icon="mdi-domain"
                label="Домен сайта"
                v-model="form.domain"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                label="robots"
                v-model="form.robots"
                />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                label="Скрипты в Body"
                v-model="form.body_script"
                />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                label="Скрипты в Head"
                v-model="form.header_script"
                />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card>
                <v-card-title>Шрифты</v-card-title>
                <v-card-subtitle>Зеленым обозначены шрифты с поддержкой кирилицы</v-card-subtitle>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-card-title v-if="selected_font.id">
                        Выбранный шрифт: {{ selected_font.family }}
                      </v-card-title>
                    </v-col>
                    <v-col>
                      <v-btn
                        fab
                        small
                        color="red"
                        v-if="selected_font.id"
                        @click="form.font_id = null; selected_font = {}">
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-data-table
                        v-if="fontsElements.length > 0"
                        :items="fontsElements"
                        :headers="table.headers"
                        :options.sync="table.options"
                        :server-items-length="fontTotalItems"
                        disable-sort
                      >
                        <template v-slot:top>
                          <v-text-field
                            v-model="table.search"
                            label="Поиск..."
                          />
                        </template>
                        <template v-slot:item={item}>
                          <tr :class="{'green lighten-5': item.subsets.indexOf('cyrillic') !== -1}">
                            <td>{{ item.id }}</td>
                            <td>{{ item.family }}</td>
                            <td>
                              <v-btn
                                fab
                                small
                                color="primary"
                                v-if="!form.font_id || item.id !== form.font_id"
                                @click="form.font_id = item.id; selected_font = item">
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                              <v-btn
                                fab
                                small
                                color="red"
                                v-else
                                @click="form.font_id = null; selected_font = {}">
                                <v-icon>mdi-minus</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="ml-1">
            <media-widget
              v-if="site && site.id"
              label="Иконка сайта(ico)"
              :medias="site.icon"
              :is-multiple="false"
              @onChange="setImage"
            />
            <media-widget
              v-if="site && site.id"
              label="Иконка сайта(png)"
              :medias="site.icon_png"
              :is-multiple="false"
              @onChange="setImagePng"
             />
            <media-widget
              v-if="site && site.id"
              label="Лого"
              :medias="site.site_logo"
              :is-multiple="false"
              @onChange="setSiteLogo"
              />
              <media-widget
                v-if="site && site.id"
                label="SMM изображение"
                :medias="site.smm_image"
                :is-multiple="false"
                @onChange="setSmmImage"
              />
          </v-row>
          <v-row>
            <v-col>
              <v-checkbox
                label="Индексировать сайт"
                v-model="form.is_indexed"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-checkbox
                label="По умолчанию"
                :disabled="isDefaultDisabled"
                v-model="form.is_default"
              />
            </v-col>
          </v-row>
          <v-btn
            color="primary"
            :loading="isLoading"
            :disabled="isLoading"
            @click="submit"
          >
            Сохранить
          </v-btn>
          <media-uploader
            v-if="site && site.id"
          />
        </v-form>
        </v-container>
      </v-tab-item>
      <v-tab-item v-if="form.id">
        <OptionForm/>
      </v-tab-item>
      <v-tab-item v-if="form.id">
        <index/>
      </v-tab-item>
    </v-tabs-items>

  </v-container>
</template>


<script>
import {mapActions, mapGetters} from "vuex";
import OptionForm from "../portalOptions/OptionForm";
import Index from "../mails/Index.vue";
import MediaUploader from '../MediaUploader';
import MediaWidget from '../MediaWidget';

export default {
  name: "SiteComponent",
  components: {
    MediaWidget,
    OptionForm,
    Index,
    MediaUploader
  },
  data: () => ({
    form: {
      title: '',
      domain: '',
      font_id: null,
      icon: null,
      is_default: false,
      is_indexed: true,
      robots: null,
      header_script: null,
      body_script: null,
      site_logo: null,
    },
    selected_font: {},
    table: {
      headers: [
        {text: 'ID', value: 'id'},
        {text: 'Family', value: 'family'},
        {text: '', value: 'action'},
      ],
      search: '',
      options: {
        sortBy: ['id'],
        sortDesc: true,
        page: 1,
        itemsPerPage: 15,
      }
    },
    tab: null,
    errors: {},
  }),
  props: {
    site: {
      required: false,
      type: Object,
      default: () => ({}),
    }
  },
  computed: {
    ...mapGetters({
      isLoading: "Sites/isSaveButtonDisabled",
      fetchedFonts: "Sites/getFonts",
      fontTotalItems: "Sites/getFontTotalItems",
      options: "Sites/getOptions",
    }),
    fontsElements: function () {
      return this.fetchedFonts;
    },
    isDefaultDisabled: function () {
      return this.site.is_default == true;
    }
  },
  created() {
    this.callFetchFonts();
  },
  methods: {
    ...mapActions({
      "fetchFonts": 'Sites/fetchFonts',
      "makePatterns": "Patterns/generatePatterns",
      "fetchSite": "Sites/fetchDetail",
    }),
    callFetchFonts() {
      const payload = {
        options: this.table.options,
        search: this.table.search,
      }

      this.fetchFonts(payload);
    },
    generatePatterns() {
      this.makePatterns({
        entity_type: this.site.entity_type,
        entity_id: this.site.id,
      }).then(() => {
        this.fetchSite(this.site.id);
      })
    },
    submit() {
      this.$emit('onSubmit', this.form);
    },
    setImage(image) {
      this.form.icon = image;
    },
    setImagePng(image) {
      this.form.icon_png = image;
    },
    setSiteLogo(image) {
      this.form.site_logo = image;
    },
    setSmmImage(image) {
      this.form.smm_image = image;
    }
  },
  watch: {
    site: {
      handler() {
        if (this.site && Object.keys(this.site).length > 0) {
          this.form = {...this.form, ...JSON.parse(JSON.stringify(this.site))};
          if (this.form.font) {
            this.selected_font = this.form.font;
          }
        }
      }
    },
    'table.options': {
      handler() {
        this.callFetchFonts();
      }
    },
    'table.search': {
      handler() {
        this.callFetchFonts();
      }
    }
  }
}
</script>

<style scoped>

</style>
