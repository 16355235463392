<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <region-form @onSubmit="submit"/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import RegionForm from '../../../components/regions/RegionForm';
import {mapActions} from "vuex";

export default {
  name: 'Create',
  components: {RegionForm},
  data: () => ({
    page: {
      title: "Создание региона"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Регионы",
        disabled: false,
        to: '/regions/'
      },
      {
        text: "Создание",
        disabled: true,
        to: '/regions/create'
      },
    ],
  }),
  methods: {
    ...mapActions({
      store: 'Regions/store',
    }),
    async submit({form, isNeedRedirect}) {
      const parentId = this.$route.query.parent_id;
      if (parentId) {
        form.form.parent_id = parentId;
      }
      const region = await this.store(form);

      if (isNeedRedirect) {
        await this.$router.push({name: 'regions-index'});
      } else {
        await this.$router.push({name: 'regions-edit', params: {id: region.id}});
      }
    },
  },
};
</script>
