<template>
  <vxe-form :items="formItems" :data="emptyFormData" @submit="submitEvent">
    <template #items_render="{ data }">
      <v-card>
        <v-card-title>
          Элементы
          <v-btn icon color="green" @click="addItem">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div v-for="(item, index) in data.items" :key="item.key">
            <v-row>
              <v-col>
                <vxe-input v-model="item.key" placeholder="Значение"/>
              </v-col>
              <v-col>
                <vxe-input v-model="item.value" placeholder="Текст"/>
              </v-col>
              <v-col>
                <v-btn icon color="red" class="pb-2" @click="deleteItem(index)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </template>
  </vxe-form>
</template>

<script>
export default {
  name: "RadioConfigForm",
  props: {
    formData: {
      required: true,
      type: Object,
    }
  },
  created() {
    if (this.formData) {
      this.emptyFormData = JSON.parse(JSON.stringify(this.formData));
    }
  },
  data: () => ({
    formItems: [
      {
        title: 'Редактирование',
        span: 24,
        children: [
          {
            title: 'Подсказка',
            field: 'label',
            span: 24,
            itemRender: { name: '$input', props: { placeholder: 'Строка отображаемая в поле' } }
          },
          {
            title: 'Значение по умолчанию',
            field: 'default',
            span: 24,
            itemRender: { name: '$input', props: { placeholder: 'Значение по умолчанию' } }
          },
          {
            field: 'items',
            span: 24,
            itemRender: {  },
            slots: { default: 'items_render' }
          },
          {
            span: 24,
            itemRender: { name: '$button', props: { type: 'submit', content: 'Сохранить' } }
          }
        ]
      }
    ],
    emptyFormData: {
      label: "",
      default: null,
      items: [],
    }
  }),
  methods: {
    addItem() {
      this.emptyFormData.items.push({
        key: null,
        value: null,
      })
    },
    deleteItem(index) {
      this.emptyFormData.items.splice(index, 1)
    },
    submitEvent() {
      this.$emit('onSubmit', this.emptyFormData);
    }
  }
}
</script>

<style scoped>

</style>
