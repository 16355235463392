<template>
  <v-container>
    <v-form>
      <v-text-field
        v-model="form.title"
        :error-messages="errors.title"
        label="Название"
        name="title"
        required
      ></v-text-field>
      <v-text-field
        v-model="form.slug"
        :error-messages="errors.slug"
        label="Slug"
        name="slug"
        required
        :disabled="!(Object.keys(partner).length === 0)"
      ></v-text-field>
      <v-datetime-picker
        v-model="form.published_at"
        scrollable
        label="Дата публикации. По умолчанию текущая дата и время"
        clear-text="Очистить"
      ></v-datetime-picker>
      <v-textarea
        name="lead"
        label="Описание"
        v-model="form.lead"
        :error-messages="errors.lead"
        required
      ></v-textarea>
      <v-text-field
        v-model="form.site_url"
        prepend-icon="mdi-web"
        :error-messages="errors.site_url"
        label="Ссылка на сайт"
        name="site_url"
      ></v-text-field>
      <v-text-field
        v-model="form.additional_site_url"
        prepend-icon="mdi-web"
        label="Ссылка на дополнительный сайт"
        name="site_url"
      ></v-text-field>
      <v-text-field
        v-model="form.vk_url"
        :error-messages="errors.vk_url"
        label="Ссылка на VK"
        name="vk_url"
      ></v-text-field>
      <v-text-field
        v-model="form.ok_url"
        :error-messages="errors.ok_url"
        label="Ссылка на Odnoklassniki"
        name="ok_url"
      ></v-text-field>
      <v-text-field
        v-model="form.fb_url"
        prepend-icon="mdi-facebook"
        :error-messages="errors.fb_url"
        label="Ссылка на FB"
        name="fb_url"
      ></v-text-field>
      <v-text-field
        v-model="form.inst_url"
        prepend-icon="mdi-instagram"
        :error-messages="errors.inst_url"
        label="Ссылка на Instagram"
        name="inst_url"
      ></v-text-field>
      <v-text-field
        v-model="form.tg_url"
        :error-messages="errors.tg_url"
        label="Ссылка на Telegram"
        name="tg_url"
      ></v-text-field>
      <v-text-field
        v-model="form.tt_url"
        :error-messages="errors.tt_url"
        label="Ссылка на TikTok"
        name="tt_url"
      ></v-text-field>
      <v-text-field
        v-model="form.youtube_url"
        prepend-icon="mdi-youtube"
        :error-messages="errors.youtube_url"
        label="Ссылка на YouTube"
        name="youtube_url"
      ></v-text-field>
      <v-text-field
        v-model="form.twitter_url"
        prepend-icon="mdi-twitter"
        :error-messages="errors.twitter_url"
        label="Ссылка на Twitter"
        name="twitter_url"
      ></v-text-field>
      <v-text-field
        v-model="form.zen_url"
        :error-messages="errors.zen_url"
        label="Ссылка на Дзен"
        name="zen_url"
      ></v-text-field>
      <v-text-field
        v-model="form.email"
        prepend-icon="mdi-at"
        :error-messages="errors.email"
        label="Почта"
        name="email"
      ></v-text-field>
      <v-text-field
        v-model="form.phone"
        :error-messages="errors.phone"
        prepend-icon="mdi-phone-classic"
        hint="К примеру, +7(926)000-00-00"
        label="Телефон"
        name="phone"
      ></v-text-field>
      <v-text-field
        v-model="form.second_phone"
        prepend-icon="mdi-phone-classic"
        hint="К примеру, +7(926)000-00-00"
        label="Второй номер телефона"
        name="second_phone"
      ></v-text-field>
      <v-text-field
        v-model="form.whatsapp"
        prepend-icon="mdi-whatsapp"
        hint="К примеру, +7(926)000-00-00"
        label="Whatsapp"
        name="whatsapp"
      />
      <v-text-field
        v-model="form.viber"
        prepend-icon="mdi-phone-incoming-outline"
        hint="К примеру, +7(926)000-00-00"
        label="Viber"
        name="viber"
      />
      <v-text-field
        v-model="form.geographic_address"
        prepend-icon="mdi-map-marker"
        :error-messages="errors.geographic_address"
        label="Географический адрес"
        name="geographic_address"
      ></v-text-field>
      <v-text-field
        v-model="form.latitude"
        prepend-icon="mdi-map-marker"
        :error-messages="errors.geographic_address"
        label="Точка локации (широта)"
        name="latitude"
      ></v-text-field>
      <v-text-field
        v-model="form.longitude"
        prepend-icon="mdi-map-marker"
        :error-messages="errors.geographic_address"
        label="Точка локации (долгота)"
        name="longitude"
      ></v-text-field>
      <v-text-field
        v-model="form.metro"
        prepend-icon="mdi-train"
        :error-messages="errors.geographic_address"
        label="Метро"
        name="geographic_address"
      ></v-text-field>
      <v-text-field
        v-model="form.work_time"
        prepend-icon="mdi-clock-time-four-outline"
        :error-messages="errors.geographic_address"
        label="Режим работы"
        name="work_time"
      ></v-text-field>
      <v-text-field
        v-model="form.contact_person"
        prepend-icon="mdi-account"
        label="Контактное лицо"
        name="contact_person"
      ></v-text-field>
      <v-text-field
        v-model="form.contact_person_post"
        prepend-icon="mdi-account"
        label="Должность контактного лица"
        name="contact_person_post"
      />
      <v-autocomplete
        v-model="form.selected_persons"
        prepend-icon="mdi-account"
        v-if="persons"
        :items="persons"
        item-text="title"
        item-value="id"
        label="Выберите персоны"
        multiple
      >
        <template v-slot:selection="data">
          <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            close
            @click="data.select"
            @click:close="removePersons(data.item)"
          >
            <v-avatar left>
              <v-img :src="data.item.image" />
            </v-avatar>
            {{ data.item.title }}
          </v-chip>
        </template>
        <template v-slot:item="data">
          <v-list-item-avatar>
            <img :src="data.item.image">
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ data.item.title }}</v-list-item-title>
          </v-list-item-content>
        </template>
      </v-autocomplete>
      <v-autocomplete v-model="form.city" :items="fetchedCitiesList" item-text="value" item-value="key"
                      label="Выберите регион">

      </v-autocomplete>
      <v-textarea
        v-model="form.comment"
        :error-messages="errors.comment"
        label="Комментарий"
        name="comment"
      ></v-textarea>
      <label>Основной график</label>
      <v-row>
        <v-col
          v-for="(label, value) in workdays"
          :key="label">
          <v-checkbox
            v-model="form.work_days_main"
            :label="label"
            :value="value"
            multiple
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-menu
            v-model="work_from_main_picker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.work_from_main"
                label="Начало"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                append-icon="mdi-close"
                @click:append="form.work_from_main = null"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-model="form.work_from_main"
              format="24hr"
            >
              <v-spacer />
              <v-btn text color="primary" @click="work_from_main_picker = false">ОК</v-btn>
            </v-time-picker>
          </v-menu>
        </v-col>
        <v-col>
          <v-menu
            v-model="work_till_main_picker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.work_till_main"
                label="До"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                append-icon="mdi-close"
                @click:append="form.work_till_main = null"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-model="form.work_till_main"
              format="24hr"
            >
              <v-spacer />
              <v-btn text color="primary" @click="work_from_main_picker = false">ОК</v-btn>
            </v-time-picker>
          </v-menu>
        </v-col>
        <v-col>
          <v-menu
            v-model="break_from_main_picker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.break_from_main"
                label="Перерыв"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                append-icon="mdi-close"
                @click:append="form.break_from_main = null"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-model="form.break_from_main"
              format="24hr"
            >
              <v-spacer />
              <v-btn text color="primary" @click="break_from_main_picker = false">ОК</v-btn>
            </v-time-picker>
          </v-menu>
        </v-col>
        <v-col>
          <v-menu
            v-model="break_till_main_picker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.break_till_main"
                label="До"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                append-icon="mdi-close"
                @click:append="form.break_till_main = null"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-model="form.break_till_main"
              format="24hr"
            >
              <v-spacer />
              <v-btn text color="primary" @click="break_till_main_picker = false">ОК</v-btn>
            </v-time-picker>
          </v-menu>
        </v-col>
      </v-row>
      <label>Дополнительный график</label>
      <v-row>
        <v-col
          v-for="(label, value) in workdays"
          :key="label">
          <v-checkbox
            v-model="form.work_days_additional"
            :label="label"
            :value="value"
            multiple
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-menu
            v-model="work_from_additional_picker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.work_from_additional"
                label="Начало"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                append-icon="mdi-close"
                @click:append="form.work_from_additional = null"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-model="form.work_from_additional"
              format="24hr"
            >
              <v-spacer />
              <v-btn text color="primary" @click="work_from_additional_picker = false">ОК</v-btn>
            </v-time-picker>
          </v-menu>
        </v-col>
        <v-col>
          <v-menu
            v-model="work_till_additional_picker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.work_till_additional"
                label="До"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                append-icon="mdi-close"
                @click:append="form.work_till_additional = null"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-model="form.work_till_additional"
              format="24hr"
            >
              <v-spacer />
              <v-btn text color="primary" @click="work_from_additional_picker = false">ОК</v-btn>
            </v-time-picker>
          </v-menu>
        </v-col>
        <v-col>
          <v-menu
            v-model="break_from_additional_picker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.break_from_additional"
                label="Перерыв"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                append-icon="mdi-close"
                @click:append="form.break_from_additional = null"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-model="form.break_from_additional"
              format="24hr"
            >
              <v-spacer />
              <v-btn text color="primary" @click="break_from_additional_picker = false">ОК</v-btn>
            </v-time-picker>
          </v-menu>
        </v-col>
        <v-col>
          <v-menu
            v-model="break_till_additional_picker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.break_till_additional"
                label="До"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                append-icon="mdi-close"
                @click:append="form.break_till_additional = null"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-model="form.break_till_additional"
              format="24hr"
            >
              <v-spacer />
              <v-btn text color="primary" @click="break_till_additional_picker = false">ОК</v-btn>
            </v-time-picker>
          </v-menu>
        </v-col>
      </v-row>
      <media-widget
        class="mb-1"
        label="Логотип"
        :medias="form.image"
        :is-multiple="false"
        @onChange="setImage"
      />
      <media-widget
        class="mb-1"
        label="Keyvisual"
        :medias="form.additional_image"
        :is-multiple="false"
        @onChange="setAdditionalImage"
      />
      <media-uploader
        :loaded-medias="partner.loaded_medias"
        @completeUploads="onCompleteUploads"
      />

      <v-row>
        <v-col>
          <v-btn
            block
            color="primary"
            :disabled="isSaveDisabled"
            :loading="isSaveDisabled"
            @click="submit(true)"
          >
            Сохранить
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
            block
            color="primary"
            :disabled="isSaveDisabled"
            :loading="isSaveDisabled"
            @click="submit(false)"
          >
            Сохранить и продолжить
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import MediaUploader from '../MediaUploader';
import MediaWidget from '../MediaWidget';
import moment from "moment";
import slugify from 'slugify';
import axiosInstance from "../../axiosInstance";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "PartnerForm",
  components: {
    MediaUploader,
    MediaWidget,
  },
  props: {
    partner: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    form: {
      id: null,
      title: null,
      slug: null,
      published_at: null,
      lead: null,
      image: null,
      site_url: null,
      vk_url: null,
      fb_url: null,
      ok_url: null,
      tg_url: null,
      tt_url: null,
      inst_url: null,
      youtube_url: null,
      twitter_url: null,
      zen_url: null,
      email: null,
      phone: null,
      geographic_address: null,
      selected_persons: null,
      comment: null,
      second_phone: null,
      whatsapp: null,
      viber: null,
      additional_image: null,
      city: null,
      longitude: null,
      latitude: null,
      metro: null,
      work_time: null,
      additional_site_url: null,
      contact_person: null,
      contact_person_post: null,
    },
    mediaIds: [],
    persons: [],
    isSaveDisabled: false,
    workdays: {
      mon: 'пн',
      tue: 'вт',
      wed: 'ср',
      thu: 'чт',
      fri: 'пт',
      sat: 'сб',
      sun: 'вс'
    },
    work_from_main_picker: false,
    work_till_main_picker: false,
    work_from_additional_picker: false,
    work_till_additional_picker: false,
    break_from_main_picker: false,
    break_till_main_picker: false,
    break_from_additional_picker: false,
    break_till_additional_picker: false,
  }),
  created() {
    if (this.$route.params.data) {
      this.form = JSON.parse(this.$route.params.data);
    }
    this.getPersons();
    this.fetchCitiesList();
  },
  computed: {
    ...mapGetters({
      "fetchedCitiesList": "Cities/getFetchedList",
    })
  },
  methods: {
    ...mapActions({
      "fetchCitiesList": "Cities/fetchList",
    }),
    submit(isNeedRedirect) {
      this.isSaveDisabled = true;

      this.form.published_at = this.form.published_at
        ? moment(this.form.published_at).format('YYYY-MM-DD HH:mm')
        : null;

      this.$emit('onSubmit', { form: this.form, mediaIds: this.mediaIds, isNeedRedirect: isNeedRedirect });
    },
    async onCompleteUploads(ids) {
      this.mediaIds = ids;
      await this.submit(true);
      this.mediaIds = [];
    },
    setImage(image) {
      this.form.image = image;
    },
    setAdditionalImage(image) {
      this.form.additional_image = image
    },
    async getPersons() {
      await axiosInstance.get('persons/select-list').then((response) => {
        this.persons = response.data.data;
      });
    },
    removePersons(item) {
      const index = this.form.selected_persons.indexOf(item.id);
      if (index >= 0) this.form.selected_persons.splice(index, 1);
    },
  },
  watch: {
    partner: {
      handler(partner) {
        if (partner && Object.keys(partner).length > 0) {
          this.form = partner;
          this.isSaveDisabled = false;
        }
      },
      immediate: true,
      deep: true,
    },
    'form.title': {
      handler(value) {
        if (value && Object.keys(this.partner).length === 0) {
          this.form.slug = slugify(value.toLowerCase()).match(/[a-z]|[0-9]|[-]/gm).join('');
        }
      }
    },
  },
}
</script>
