<template>
  <div class="ok-preview">
    <div class="card-seo-ok">
      <div v-if="$data.facebookImg" class="image-block" :style="$data.facebookImg"></div>
      <div class="content-block">
        <span class="ok-title">{{ title }}</span>
        <span class="ok-description">{{ description }}</span>
        <a class="site-url" :href="getHostname">{{ getHostname }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import smmMixin from "@/mixins/smmMixin";
import seoAndSmmWidgetMixin from "@/mixins/seoAndSmmWidgetMixin";

export default {
  mixins: [
    smmMixin,
    seoAndSmmWidgetMixin
  ],
  props: {
    form: {
      type: Object,
    },
    entity: {
      type: Object,
    },
    resizedImage: {
      type: String,
    }
  },
  data() {
    return {
      title: '',
      facebookImg: '',
      description: '',
      descriptionLength: 215,
    }
  },
  methods: {
    ...mapActions({
      dynamicResize: "Medias/dynamicResize"
    }),
    cutString(string, count) {
      if (string.length > count) {
        return string.substring(0, count) + '...';
      }

      return string;
    }
  },
  watch: {
    'form.smm_image': {
      async handler(val) {
        if (val != null) {
          let value = val.url || this.entity.smm_image.url;

          if (typeof value != 'string') return;

          const url = (await this.dynamicResize({ media: val.id, width: 1200, height: 630 })).data;

          this.$data.facebookImg = `background-image: url(${url})`;
        } else {
          this.$data.facebookImg = '';
        }
      },
      immediate: true,
    },
    'form.smm_description': {
      handler(val) {
        let value = val || this.entity.lead || this.entity.description;

        if (typeof value != 'string') return;

        this.description = this.cutString(value, this.descriptionLength) || this.entity.lead;
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
  .ok-preview {
    margin-bottom: 15px;

    .card-seo-ok {
      width: 548px;
      border-radius: 8px;
      border: 1px solid rgba(0,0,0,.08);

      .image-block {
        border-bottom: none;
        border-radius: 8px 8px 0 0;
        width: 100%;
        height: 275px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }

      .content-block {
        padding: 16px 16px 17px;
        padding-right: 32px;

        .ok-title {
          margin: -6px -12px -7px -8px;
          padding: 6px 8px 7px;
          border-radius: 3px;
          color: #333;
          font-weight: 700;
          font-size: 16px;
          margin-bottom: 4px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-height: 1.4;
          max-height: 50px;
          overflow: hidden;
        }

        .ok-title:hover {
          background: #f2f2f2;
          cursor: pointer;
        }

        .ok-description {
          display: block;
          margin: -4px -8px;
          padding: 4px 8px;
          border-radius: 3px;
          color: #666;
          font-size: 14px;
          line-height: 22px;
          margin-bottom: 0px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-height: 1.4;
          max-height: 50px;
          overflow: hidden;
        }

        .ok-description:hover {
          background: #f2f2f2;
          cursor: pointer;
        }

        a.site-url {
          display: block;
          margin-top: 4px;
          color: #999;
          margin-bottom: 8px;
        }

        a.site-url:hover {
          text-decoration: none;
        }
      }
    }
  }
</style>
