<script>
  import {mapActions} from "vuex";

  export default {
    mounted() {
    },
    methods: {
      ...mapActions({
        onSuccess: 'snackbar/showSuccessfullySaved',
        onError: 'snackbar/showSaveError',
      }),
      submit() {
        if (this.disabled) {
          return
        }

        if (this.fire('submit') === false) {
          return
        }

        if (this.$_shouldValidateOn('submit')) {
          this.validate()
        }

        this.proceed(() => {
          try {
            this.send();
            this.onSuccess();
          } catch {
            this.onError();
          }
        })
      }
    }
  }
</script>
