var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"down-top-padding",attrs:{"fluid":""}},[_c('BaseBreadcrumb',{attrs:{"title":_vm.page.title,"icon":_vm.page.icon,"breadcrumbs":_vm.breadcrumbs}}),_c('v-row',[_c('v-col',[_c('h1',[_vm._v(_vm._s(_vm.page.title))])])],1),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"primary","to":"/season-pass/create"}},[_vm._v(" Создать абонемент ")])],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"loading":_vm.loading,"server-items-length":_vm.listingTotal,"search":_vm.search},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.recurrent_payments",fn:function(ref){
var item = ref.item;
return [(item.recurrent_payments)?[_c('v-chip',{attrs:{"color":"green","content":"да","label":""}},[_vm._v("да")])]:[_c('v-chip',{attrs:{"color":"blue","content":"нет","label":""}},[_vm._v("нет")])]]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [(item.active)?[_c('v-chip',{attrs:{"color":"green","content":"да","label":""}},[_vm._v("да")])]:[_c('v-chip',{attrs:{"color":"red","content":"нет","label":""}},[_vm._v("нет")])]]}},{key:"item.test_mode",fn:function(ref){
var item = ref.item;
return [(item.test_mode)?[_c('v-chip',{attrs:{"color":"blue","content":"да","label":""}},[_vm._v("да")])]:[_c('v-chip',{attrs:{"color":"green","content":"нет","label":""}},[_vm._v("нет")])]]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getTypeColor(item.type),"content":_vm.getHumanType(item.type),"label":""}},[_vm._v(_vm._s(_vm.getHumanType(item.type)))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"href":_vm.editItem(item),"icon":""}},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-delete ")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }