<template>
  <v-form>
    <v-text-field
      v-model="form.title"
      :error-messages="errors.title"
      label="Название"
      name="title"
      required
    ></v-text-field>
    <v-btn
      class="mr-4"
      color="primary"
      :disabled="isSaveDisabled"
      :loading="isSaveDisabled"
      @click="submit"
    >
      Сохранить
    </v-btn>
  </v-form>
</template>

<script>
export default {
  name: 'ReviewForm',
  props: {
    reviewType: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    form: {
      id: null,
      title: null,
    },
    isSaveDisabled: false,
  }),
  methods: {
    async submit() {
      this.isSaveDisabled = true;
      this.$emit('onSubmit', {
        form: this.form,
      });
    },
  },
  watch: {
    reviewType: {
      handler(reviewType) {
        if (reviewType && Object.keys(reviewType).length > 0) {
          this.form = reviewType;
          this.isSaveDisabled = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
