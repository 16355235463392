<template>
  <div class="short-link-widget">
    <template v-if="!currentShortUrl">
      <h3>Полная ссылка</h3>
      <short-urls-form
        class="generate-short-url-block"
        :url="getFullUrl"
        buttonName="Сгенерировать короткую ссылку"
        @onSubmit="submit"
      />
    </template>
    <template v-else>
      <h3>Короткая ссылка</h3>
      <div class="row">
        <span id="copyLink" class="col">{{ getShortUrl }}</span>
        <v-btn
          color="primary"
          class="col-2 mr-2"
          @click="doCopy(getShortUrl)"
        >Копировать</v-btn>
      </div>
    </template>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ShortUrlsForm from '../components/shortUrls/ShortUrlsForm';
import seoAndSmmWidgetMixin from "@/mixins/seoAndSmmWidgetMixin";

export default {
  name: 'ShortLinkWidget',
  components: {
    ShortUrlsForm
  },
  mixins: [
    seoAndSmmWidgetMixin
  ],
  props: {
    entity: {
      type: Object,
    },
  },
  created() {
    this.getByUrl(this.getPreviewUrl);

    this.$emit('updateParent', {
      shareUrl: this.getFullUrl,
    });
  },
  computed: {
    ...mapGetters({
      currentShortUrl: 'ShortUrls/getShortUrl',
    }),
    titlePostfix() {
      return process.env.VUE_APP_SEO_SITE_TITLE;
    },
    getShortUrl() {
      return this.currentShortUrl;
    },
  },
  methods: {
    ...mapActions({
      storeItem: 'ShortUrls/storeItem',
      getByUrl: 'ShortUrls/getByUrl',
    }),
    submit(payload) {
      this.storeItem(payload);
    },
    cutString(string, count) {
      if (string.length > count)
        return string.substring(0, count) + '...';

      return string;
    },
    doCopy: function (message) {
      this.$copyText(message).then(() => {
        this.$store.dispatch('snackbar/showSuccessCopyShortUrl', null, { root: true });
      }, () => {
        this.$store.dispatch('snackbar/showErrorCopy', null, { root: true });
      })
    }
  },
  watch: {
    currentShortUrl(newValue) {
      this.$emit('updateParent', {
        shareUrl: newValue,
      });
    }
  }
}
</script>


<style>
.short-link-widget {
  margin-bottom: 15px;
  margin-right: 5px;
}
</style>
