<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <SiteComponent
              @onSubmit="submit"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import SiteComponent from "../../../components/sites/SiteComponent";
import {mapActions} from "vuex";
export default {
  name: "Create",
  components: {SiteComponent},
  data: () => ({
    page: {
      title: "Создание сайта"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Сайты",
        disabled: true,
        to: '/sites'
      },
      {
        text: "Создание",
        disabled: true,
        to: '/sites/create'
      },
    ]
  }),
  methods: {
    ...mapActions({
      store: 'Sites/storeItem',
    }),
    async submit(form) {
      this.store(form).then(() => {
        this.$router.push({name: 'site-index'});
      })
    }
  }
}
</script>

<style scoped>

</style>
