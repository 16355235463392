<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <regional-practices-form @onSubmit="store"/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import RegionalPracticesForm from "../../../components/regionalPractices/RegionalPracticesForm";
import {mapActions} from "vuex";
export default {
  name: "Create",
  components: {RegionalPracticesForm},
  data: () => ({
    page: {
      title: "Создание каталога"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Региональные практики",
        disabled: true,
        to: '/regional-practices/'
      },
      {
        text: "Создание",
        disabled: true,
        to: '/regional-practices/create'
      },
    ],
  }),
  methods: {
    ...mapActions({
      storeItem: "RegionalPractices/storeItem"
    }),
    store({ form, isNeedRedirect }) {
      this.storeItem({ form: form, isNeedRedirect: isNeedRedirect, router: this.$router });
    }
  }
}
</script>

<style scoped>

</style>
