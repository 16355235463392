var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"down-top-padding",attrs:{"fluid":""}},[_c('BaseBreadcrumb',{attrs:{"title":_vm.page.title,"icon":_vm.page.icon,"breadcrumbs":_vm.breadcrumbs}}),_c('v-card',[_c('v-card-text',{staticClass:"pa-5"},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Поиск...","filled":"","background-color":"transparent","hide-details":"","name":"title"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[(_vm.search)?_c('v-btn',{staticClass:"reset-button",attrs:{"slot":"append","icon":"","color":"red"},on:{"click":function($event){_vm.search = null}},slot:"append"},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1)],1),_c('v-col',[_c('v-switch',{attrs:{"label":"Показать удаленные","name":"only_trashed","value":"1"},model:{value:(_vm.only_trashed),callback:function ($$v) {_vm.only_trashed=$$v},expression:"only_trashed"}})],1),_c('v-col',{staticClass:"justify-end align-center d-flex",attrs:{"cols":"12","lg":"4"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return undefined}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.total,"search":_vm.search,"single-select":false,"show-select":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"pill":""}},[_vm._v(_vm._s(item.id))])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.type),"content":item.human_type,"label":""}},[_vm._v(_vm._s(item.human_type))])]}},{key:"item.status_title",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.status),"content":item.status_title,"label":""}},[_vm._v(_vm._s(item.status_title))])]}},{key:"item.changer",fn:function(ref){
var item = ref.item;
return [(item.changer && item.changer.full_name)?_c('span',[_vm._v(_vm._s(item.changer.full_name))]):_c('span',[_vm._v(" - ")])]}},{key:"item.message",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.message)+" ")]}},{key:"item.sign_url",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.sign_url)+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }