import { Model } from "@tailflow/laravel-orion/lib/model";

export class ShortUrls extends Model<{
    url: string,
    shortUrl: string,
    created_at: string,
    updated_at: string,
}> {

    $resource(): string {
        return "shortUrls";
    }
}
