<template>
  <v-container fluid class="down-top-padding" v-if="isLoaded">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <div class="row">
          <v-col>
              <v-select label="Партнеры" chips item-text="name" item-value="id" v-model="filteredPartners" :items="items" multiple>
                <template v-slot:selection="[]">
                </template>
                <template v-slot:prepend-item>
                  <v-text-field class="ml-2 mr-2" v-model="search" @input="searchUser"></v-text-field>
                  <v-divider class="mt-2"/>
                </template>
                <template v-slot:item="data" >
                  <v-list-item-content >
                    <v-list-item-title>
                      {{ data.item.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ data.item.code }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-btn class="reset-button"
                          slot="append"
                          @click="onlyUser()"
                          color="gray"
                    >
                    Только
                  </v-btn>
                </template>
              </v-select>
          </v-col>
          <v-col>
              <v-select label="Источник" chips item-text="name" item-value="id" v-model="filteredReferer" :items="arrayItemReferer" multiple>
                <template v-slot:selection="[]">
                </template>
                <template v-slot:item="data" >
                  <v-list-item-content >
                    <v-list-item-title>
                      {{ data.item }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-btn class="reset-button"
                          slot="append"
                          @click="onlyRef()"
                          color="gray"
                    >
                    Только
                  </v-btn>
                </template>
              </v-select>
          </v-col>
          <v-col>
            <v-menu
              v-model="datePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFilter"
                  label="Диапазон дат"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  append-icon="mdi-close"
                  @click:append="dateFilter = null"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateFilter"
                locale="ru-RU"
                range
              >
                <v-spacer/>
                <v-btn text color="primary" @click="datePickerAction">ОК</v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </div>
        <div class="row">
          <v-col>
            <div class="v-card v-sheet theme--light">
              <div class="v-card__text pa-5">
                <div class="d-flex align-center">
                  <v-btn elevation="0" fab rounded readonly="true" color="primary">
                    <v-icon>
                      mdi-eye
                    </v-icon>
                  </v-btn>
                  <div class="ml-2 mr-1">
                    <h2 class="headline font-weight-light card-title">
                      {{ statistic.views }}
                    </h2>
                    <h5 class="subtitle-2 font-weight-regular">Просмотры</h5>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col >
            <div class="v-card v-sheet theme--light">
              <div class="v-card__text pa-5">
                <div class="d-flex align-center">
                  <v-btn elevation="0" fab rounded readonly="true" color="primary">
                    <v-icon>
                      mdi-laptop
                    </v-icon>
                  </v-btn>
                  <div class="ml-2 mr-1">
                    <h2 class="headline font-weight-light card-title">{{
                        statistic.visits
                      }}</h2>
                    <h5 class="subtitle-2 font-weight-regular">Сеансы</h5>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="v-card v-sheet theme--light">
              <div class="v-card__text pa-5">
                <div class="d-flex align-center">
                  <v-btn elevation="0" fab rounded readonly="true" color="primary">
                    <v-icon>
                      mdi-account
                    </v-icon>
                  </v-btn>
                  <div class="ml-2 mr-1">
                    <h2 class="headline font-weight-light card-title">
                      {{ statistic.total_users }}
                    </h2>
                    <h5 class="subtitle-2 font-weight-regular">Всего пользователей</h5>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="v-card v-sheet theme--light">
              <div class="v-card__text pa-5">
                <div class="d-flex align-center">
                  <v-btn elevation="0" fab rounded readonly="true" color="primary">
                    <v-icon>
                      mdi-credit-card-settings-outline
                    </v-icon>
                  </v-btn>
                  <div class="ml-2 mr-1">
                    <h2 class="headline font-weight-light card-title">{{statistic.orders_info.count}}</h2>
                    <h5 class="subtitle-2 font-weight-regular">Транзакции</h5>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="v-card v-sheet theme--light">
              <div class="v-card__text pa-5">
                <div class="d-flex align-center">
                  <v-btn elevation="0" fab rounded readonly="true" color="primary">
                    <v-icon>
                      mdi-currency-usd
                    </v-icon>
                  </v-btn>
                  <div class="ml-2 mr-1">
                    <h2 class="headline font-weight-light card-title">
                      {{ statistic.orders_info.amount_total }}
                    </h2>
                    <h5 class="subtitle-2 font-weight-regular">Общий доход</h5>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </div>
        <div class="row">
          <v-col>
            <apexchart height="325" type="line" :options="chartUsersOptions('Пользователей')" :series="usersGraph"/>
          </v-col>
          <v-col>
            <apexchart height="325" type="line" :options="chartOptions('Транзакции')" :series="transactionsGraph"/>
          </v-col>
          <v-col>
            <apexchart height="325" type="line" :options="chartOptions('Доход')" :series="amountGraph"/>
          </v-col>
        </div>
        <v-row>
          <v-col>
            <v-data-table
                :headers="headers"
                :items="statistic.traffics_source"
                :items-per-page="15"
                :footer-props="{ 'items-per-page-options': [5, 10, 15, 20, -1] }"
                >
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axiosInstance from "../../../axiosInstance";
import moment from 'moment';
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Index",
  data: () => ({
    statistic: {},
    type: 'Column2D',
    width: '500',
    height: '300',
    dataFormat: 'json',
    options: {
      page: 1,
      last_page: 1,
      date_from: null,
      date_to: null,
      search: null,
    },
    search: '',
    only_trashed: null,
    partner_options: {
      sortBy: ['id'],
      sortDesc: [true],
      page: 1,
      itemsPerPage: 10,
    },
    headers: [
      {text: 'Код партнера', value: 'partner_id'},
      {text: 'Канал', value: 'referer_name'},
      {text: 'Источник', value: 'source'},
      {text: 'Всего пользователей', value: 'users' },
      {text: 'Сеансы', value: 'visits'},
      {text: 'Просмотры', value: 'pageview'},
      {text: 'Доля вовлеч.', value: 'engagement'},
      {text: 'Транзакции', value: 'orders'},
      {text: 'Общий доход', value: 'total_revenue'},
    ],
    displayValue: 'nothing',
    page: {
      title: "Статистика"
    },
    filteredPartners: [],
    filteredReferer: [],
    arrayItemReferer: [],
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Курсы",
        disabled: false,
        to: '/courses/'
      },
      {
        text: "Статистика",
        disabled: true,
        to: '/statistics'
      },
    ],
    datePicker: false,
    dateFilter: null,
  }),
  computed: {
     ...mapGetters({
        items: 'WebmasterPartners/getFetchedListing',
    }),
    listingPayload() {
      return {
        options: this.partner_options,
        search: this.search,
        only_trashed: this.only_trashed,
        sortBy: 'id',
      }
    },
    amountGraph() {
      return [
        {
          name: 'Доход',
          data: this.statistic.orders_info.amount_graph.values,
        }
      ]
    },
    usersGraph() {
      let users = this.statistic.visits_graph.map(item => item.users);
      return [
        {
          name: 'Визиты',
          data: users,
        }
      ]
    },
    transactionsGraph() {
      return [
        {
          name: 'Транзакции',
          data: this.statistic.orders_info.transactions_graph.values,
        }
      ]
    },
    isLoaded() {
      return Object.keys(this.statistic).length > 0;
    },
  },
  async created() {
    this.getData();
    this.fetchedListing(this.listingPayload);
  },
  methods: {
    ...mapActions({
      fetchedListing: 'WebmasterPartners/fetchListing',
    }),
    async searchUser(){
      this.fetchedListing(this.listingPayload);
    },
    onlyUser() {
      this.filteredPartners = [];
    },
    onlyRef() {
      this.filteredReferer = [];
    },
    chartOptions(title) {
      return {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {enabled: false},
        stroke: {curve: 'straight'},
        title: {text: title,align: 'center'},
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          },
        },
        xaxis: {categories: this.statistic.orders_info.transactions_graph.dates,}
      }
    },
    chartUsersOptions(title) {
      let dates = this.statistic.visits_graph.map(item => moment(item.date).format('DD.MM.YY'));
      return {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {enabled: false},
        stroke: {curve: 'straight'},
        title: {text: title,align: 'center'},
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          },
        },
        xaxis: {categories: dates,}
      }
    },
    async getData(){
      let requestData = {'page': this.options.page, 'limit': 5}
      if(this.options.date_from)
        requestData.date_from = this.options.date_from;

      if(this.options.date_to)
        requestData.date_to = this.options.date_to;

      if(this.options.search)
        requestData.search = this.options.search;

      if(this.filteredPartners.length)
      {
        requestData.partner = this.filteredPartners;
        let partners = this.items.filter(item => requestData.partner.includes(item.id));
        requestData.partner_codes = partners.map(item => item.code);
      }

      if(this.filteredReferer.length)
      {
        requestData.referers = this.filteredReferer;
      }
      await axiosInstance.post('partners-statistic', requestData).then((response) => {
        this.statistic = response.data;
        this.itemsReferer();
      });
    },
    dateFilterMethod(){
      if(this.dateFilter){
        let dateFrom = this.filterformatDate(this.dateFilter[0]);
        let dateTo = this.dateFilter[1] ? this.filterformatDate(this.dateFilter[1]) : dateFrom;
        this.options.date_from = dateFrom;
        this.options.date_to = dateTo;
        this.getData();
      }
    },
    datePickerAction(){
      if(this.dateFilter)
        if(this.dateFilter.length > 1){
          if(this.dateFilter[1] < this.dateFilter[0]){
            let date = this.dateFilter[0];
            this.dateFilter[0] = this.dateFilter[1];
            this.dateFilter[1] = date;
          }
        }
      this.dateFilterMethod();
      this.datePicker = false;
    },
    filterformatDate(dateString){
      if(dateString){
        return moment(dateString).format('YYYY-MM-DD');
      }
    },
    itemsReferer() {
      if(!this.arrayItemReferer.length)
      {
        let refs = this.statistic.traffics_source.map(item => item.referer_name);
        let uniqueArray = refs.filter(function(item, pos) {
            return (refs.indexOf(item) == pos);
        });
        uniqueArray = uniqueArray.filter(function(item) {
            return item != null;
        });
        this.arrayItemReferer = uniqueArray;
        this.arrayItemReferer.push('widget');
      }
      return this.arrayItemReferer;
    },
  },
  watch: {
    filteredPartners: {
      handler() {
        this.getData();
      },
    },
    filteredReferer: {
      handler() {
        this.getData();
      },
    },
    dateFilter: {
      handler(value) {
        if(value == null){
          this.options.date_from = null;
          this.options.date_to = null;
          this.getData();
        }

      }
    },
  }
}
</script>

<style scoped>

</style>
