<template>
  <vxe-form :items="formItems"/>
</template>

<script>
export default {
  name: "InputConfigForm",
  data: () => ({
    formItems: [
      {
        title: 'Редактирование',
        span: 24,
        children: [
          {
            title: 'Подсказка',
            field: 'hint',
            span: 24,
            itemRender: { name: '$input', props: { placeholder: 'Подсказка под полем' } }
          },
          {
            title: 'Подстрока',
            field: 'label',
            span: 24,
            itemRender: { name: '$input', props: { placeholder: 'Строка отображаемая в поле' } }
          },
          {
            title: 'Заполнитель',
            field: 'placeholder',
            span: 24,
            itemRender: { name: '$textarea', props: { placeholder: 'Подсказка отображаемая в поле' } }
          },
          {
            title: 'Счетчик',
            field: 'counter',
            span: 24,
            itemRender: { name: '$input', props: { placeholder: 'Счетчик' } }
          },
          {
            title: 'Значение по умолчанию',
            field: 'default',
            span: 24,
            itemRender: { name: '$input', props: { placeholder: 'Значение по умолчанию' } }
          }
        ]
      }
    ]
  })
}
</script>

<style scoped>

</style>
