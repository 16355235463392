<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row>
      <v-col>
        <h1>Редактирование медиа "{{ media.title || media.name }}"</h1>
      </v-col>
    </v-row>
    <v-tabs v-model="tab">
      <v-tab>
        Основная информация
      </v-tab>
      <v-tab>
        Таксономия
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-row>
          <v-col cols="12">
            <vue2-dropzone
              id="media-upload-dropzone"
              ref="dropzone"
              :options="dropzoneOptions"
              @vdropzone-error="onUploadError"
              @vdropzone-success="onUploadSuccess"
            />
            <div v-if="isError">
        <span class="text-danger">
          При загрузке произошла ошибка, попробуйте загрузить другое изображение
        </span>
            </div>
            <div v-if="isLoading">
              <v-progress-circular
                :width="3"
                color="grey"
                indeterminate
              ></v-progress-circular>
            </div>
          </v-col>
          <v-col cols="12">
            <media-form
              :media="media"
              :errors="errors"
              @onSubmit="submit"
              style="margin: 0 auto"
            ></media-form>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <CollectionRelationForm
          module-name="Media"
          :errors="errors"
          :selected-collection-items="media.selected_collection_items"
          :main-items="media.main_taxonomy_ids"
          @onSubmit="submitCollectionItems"
          @onSubmitMain="submitMainCollectionItems"
        />
      </v-tab-item>
    </v-tabs-items>

    <v-snackbar
      v-model="snackbarSuccess"
      :timeout="timeout"
      color="success"
    >
      Сохранено

      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          @click="snackbarSuccess = false"
          color="red"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackbarError"
      :timeout="timeout"
      color="error"
    >
      {{ this.errors }}

      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          @click="snackbarError = false"
          color="red"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axiosInstance from "../../../axiosInstance";
import MediaForm from "../../../components/media/MediaForm";
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import CollectionRelationForm from "../../../components/collections/CollectionRelationForm";
import {mapActions} from "vuex";

export default {
  name: 'Edit',
  components: {
    CollectionRelationForm,
    MediaForm,
    vue2Dropzone,
  },
  data: () => ({
    media: {},
    tab: 0,
    errors: {},
    snackbarSuccess: false,
    snackbarError: false,
    timeout: 2000,
    isShow: false,
    isLoading: false,
    isError: false,
    medias: [],
    dropzoneOptions: {
      url: `${process.env.VUE_APP_API_URL}/api/media`,
      createImageThumbnails: false,
      uploadMultiple: false,
      paramName: 'files',
      maxFilesize: 100,
      acceptedFiles: '.jpg,.jpeg,.png,.gif,.doc,.docx,.txt,,.pdf,.csv,.xlsx,.xlsm,.xlsb,.xltx,.xltm,.xls,.xlt,.xls,.ppt,.pptx,' +
        '.mpg,.avi,.mov,.afs,.wmv,.mp4,.3GP,.flv,.ico,.zip,.rar,.gzip',
      dictDefaultMessage: 'Перетащите файл для загрузки. Максимальный размер 100mb',
    },
    loadedFiles: 0,
    confirmDialog: false,
    page: {
      title: "Медиа библиотека"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Медиа библиотека",
        disabled: true,
        to: '/medias'
      },
      {
        text: "Редактирование",
        disabled: true,
        to: '/medias/edit'
      },
    ],
  }),
  created() {
    this.fetchMediaGroups().then(() => {
      this.find();
      this.dropzoneOptions.url = `${process.env.VUE_APP_API_URL}/api/media/${this.$route.params.id}`;
      this.dropzoneOptions.headers = {
        Authorization: `Bearer ${this.$store.getters['auth/getToken']}`
      }
    })
  },
  methods: {
    ...mapActions({
      makeMainCollectionItem: "Medias/makeMainCollectionItem",
      syncCollectionItems: "Medias/syncCollectionItems",
      showSnackbar: "snackbar/showSnackbar",
      fetchMediaGroups: "Medias/fetchMediaGroups",
    }),
    async submit({ form, needRedirect }) {
      try {
        this.errors = {};

        await axiosInstance.post(`/media/${form.id}`, form).then(() => {
          if (needRedirect) {
            this.$router.push({ name: 'medias-index', query: { file_type: this.media.file_type } });
          }
        });

        this.snackbarSuccess = true;
        await this.find();
      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors || e.response.data.message;
          this.snackbarError = true;
        } else {
          this.errors = e;
          this.snackbarError = true;
        }
        throw e;
      }
    },
    async find() {
      const {data} = await axiosInstance.get(`/media/${this.$route.params.id}`)
      this.media = data.data;
    },
    onUploadSuccess(file, response) {
      if (response.id) {
        this.find();
      } else {
        response.forEach((r) => {
          this.isError = false;
          const index = this.medias.findIndex(({id}) => id === r.id);
          if (index >= 0) {
            this.medias[index] = r;
          } else {
            this.isLoading = false;
            this.medias.push(r);
          }
        })
      }
    },
    onUploadError(file) {
      this.isError = !file.accepted;
      this.showSnackbar({ color: 'red', text: "Файл слишком велик"});
    },
    submitCollectionItems({ items, isNeedRedirect }) {
      this.syncCollectionItems({ media: this.media, items: items }).then(() => {
        if (isNeedRedirect) {
          this.$router.push({ name: 'medias-index' });
        }
        this.find();
      });
    },
    submitMainCollectionItems(items) {
      this.makeMainCollectionItem({ media: this.media, items: items }).then(() => {
        this.find();
      });
    }
  },
};
</script>
