import {Model} from "@tailflow/laravel-orion/lib/model";

export class Region extends Model<{
    title: string,
    index: number,
    created_at: string,
    updated_at: string,
}> {

    $resource(): string {
        return "module/admin/regions";
    }
}
