<template>
  <form>
    <v-text-field
      label="Название"
      v-model="permission.$attributes.name"
    />
    <v-text-field
      label="Guard"
      v-model="permission.$attributes.guard_name"
    />
    <v-textarea
      label="Описание"
      v-model="permission.$attributes.description"
    />
    <v-btn
      color="primary"
      :disabled="isSaveDisabled"
      :loading="isSaveDisabled"
      @click="submit"
    >
      Сохранить
    </v-btn>
  </form>
</template>

<script>
import {Permission} from "../../models/permission";

export default {
  name: "PermissionForm",
  props: {
    permission: {
      type: Permission,
      default: () => (new Permission()),
    }
  },
  data() {
    return {
      isSaveDisabled: false,
    }
  },
  methods: {
    submit() {
      this.isSaveDisabled = true;
      this.$emit('onSubmit', this.permission);
    }
  },
  watch: {
    permission: {
      handler() {
        this.isSaveDisabled = false;
      },
      deep: true,
    }
  }
}
</script>
