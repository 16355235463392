import Index from '../../views/main/reviews/Index'
import Create from '../../views/main/reviews/Create'
import Edit from '../../views/main/reviews/Edit';

export default [
  {
    component: Index,
    name: 'reviews-index',
    path: '/reviews',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'reviews',
    }
  },
  {
    component: Create,
    name: 'reviews-create',
    path: '/reviews/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'reviews',
    }
  },
  {
    component: Edit,
    name: 'reviews-edit',
    path: '/reviews/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'reviews',
    }
  },
]
