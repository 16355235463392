<template>
  <div class="text-center">
    <v-dialog
      v-model="popup"
      width="90%"
      persistent
      >
      <v-toolbar color="primary" dark>
          <v-spacer/>
          <v-btn icon small @click="popup = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
      </v-toolbar>
      <v-card>
        <div class="widget-content">
            <div class="mb-4">
              <v-btn
                class="mt-4"
                color="primary"
                @click="coursesDialog = true"
              >
                Добавить курс в виджет
              </v-btn>
              <v-dialog
                  v-model="coursesDialog"
                  max-width="600px"
                >
                  <v-card>
                    <v-container>
                      <v-row>
                        <v-col>
                          <h3>Добавление курса</h3>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-autocomplete
                            v-if="filteredItems"
                            v-model="selectedCourses"
                            :items="filteredItems"
                            item-text="title"
                            item-value="id"
                            label="Выберите курс"
                            name="course"
                            required
                            @update:search-input="searchCourses"
                          ></v-autocomplete>
                        </v-col>
                        <v-col>
                          <v-btn
                            color="primary"
                            @click="addElement"
                          >
                            Ок
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-dialog>
            </div>
            <div v-if="selected.length" class="mb-4">
              <h3>Выбранные курсы виджета</h3>
                <v-chip-group
                  multiple
                  active-class="primary--text"
                  column

                >
                <v-chip
                    v-for="course in selected"
                    :key="course.id"
                    @click="removeElement(course)"
                  >
                    {{ course.title }}
                  </v-chip>
                </v-chip-group>
            </div>
              <div class="widget-html-content mt-6">
                <h3>Пример виджета</h3>
                <v-flex v-if="selected.length">
                  <v-row>
                      <html-block-view :partner="partner" :items="selected"></html-block-view>
                  </v-row>
                </v-flex>
              </div>

              <div class="widget-html-content mt-4">
                <h3>Код виджета</h3>
                <v-flex v-if="selected.length">
                  <v-row>
                      <html-block :partner="partner" :items="selected"></html-block>
                  </v-row>
                </v-flex>
              </div>
        </div>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import HtmlBlock from "./HtmlBlock.vue";
import HtmlBlockView from "./HtmlBlockView.vue";
import {Course} from "../../models/course";

export default {
  components: {
    HtmlBlock,
    HtmlBlockView,
  },
  props: {
    value: {
      type: Boolean ,
      default: false,
    },
    partner: {
      type: String,
      default: ""
    },
  },
  data: () => ({
    options: {
      sortBy: ['pickup_at'],
      sortDesc: [true],
      page: 1,
      itemsPerPage: 20,
    },
    selectedCourses: [],
    selectedItems: [],
    selected: [],
    filteredItems: [],
    coursesDialog: false,
    items: [],
  }),
  async created() {
    this.getCourses();
  },
  computed: {
    getExportUrl(){
      return process.env.VUE_APP_API_URL + '/api/module/courses/export';
    },
    popup: {
      get (){
        return this.value;
      },
      set (value){
        this.$emit('input',value);
      },
    },
  },
  methods: {
    async getCourses(search = '') {

      if(search){
        const items = await Course.$query().lookFor(search).search(15);
        this.items  = items.map(item => item.$attributes);
      }else{
        const items = await Course.$query().get(15);
        this.items  = items.map(item => item.$attributes);
      }
    },
    filterItems() {
      const self = this;
      this.filteredItems = this.items.filter((element) => {
        return !self.selected.map(element => element.id).includes(element.id);
      });

    },

    addElement() {
      if(!this.selected.map(element => element.id).includes(this.selectedCourses)){
        const element = this.items.find((item) => item.id == this.selectedCourses);
        this.selected.push(element);
        this.selectedCourses = null;
        this.filterItems();
      }
      this.coursesDialog = false;
    },
    removeElement(element) {
      let index = null;
      this.selected.forEach((item, i) => {
          if(item.id == element.id)
            index = i;
      });
      if(index != null)
        this.selected.splice(index,1);
      this.filterItems();
    },
    searchCourses(value) {
      if(value){
        if(value.length > 3)
          this.getCourses(value);
      }
      this.getCourses(value);
    },
  },
  watch: {
    items:{
      handler(value) {
        this.filteredItems = value;
      },
    },
  },
};
</script>

<style scoped>
.widget-content {
  padding: 0 50px 50px;
}
</style>
