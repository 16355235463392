<template>
  <v-container fluid class="down-top-padding">
    <h1>Нет доступа для просмотра контента</h1>
  </v-container>
</template>

<script>

export default {
  name: 'Index',
  data: () => ({}),
  created() {},
  computed: {},
  methods: {},
};
</script>
