<template>
  <v-form ref="form" lazy-validation v-model="valid">
    <v-text-field
      v-model="form.last_name"
      label="Фамилия"
    />
    <v-text-field
      v-model="form.first_name"
      label="Имя"
    />
    <v-text-field
      v-model="form.name"
      label="Отчество"
    />
    <v-text-field
      v-model="form.email"
      :rules="rules.emailRules"
      label="Email"
      type="email"
    />
    <v-text-field
      v-model="form.phone"
      label="Номер телефона"
      type="phone"
      />
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="form.date_of_birth"
          label="Дата рождения"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="form.date_of_birth"
        @change="save"
      ></v-date-picker>
    </v-menu>
    <v-text-field
      v-model="password"
      prepend-icon="mdi-key-arrow-right"
      @click:prepend="generatePassword"
      label="Пароль"
      @input="inputPassword"
    />
    <v-autocomplete label="Регион" :items="regions" item-text="title" item-value="id" chips v-model="form.region_id">
    </v-autocomplete>
    <v-autocomplete label="ФОИВ/ФМЦ" :items="partners" item-text="title" item-value="id" chips v-model="form.partner_id"/>
    <v-autocomplete label="Олимпиада" :items="partners" item-text="title" item-value="id" chips v-model="form.olympic_partner_id"/>
    <v-autocomplete label="Роли" multiple :items="rolesList" item-text="name" item-value="id" chips v-model="form.roles">
      <template v-slot:selection="data">
        <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          close
          @click="data.select"
          @click:close="removeRole(data.item)"
        >
          {{ data.item.name }}
        </v-chip>
      </template>
      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ data.item.description }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-select label="Поиск..." chips item-text="name" item-value="id" v-model="form.model_permissions" :items="copyPermissionList" multiple>
      <template v-slot:selection="data">
        <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          close
          @click="data.select"
          @click:close="removePermission(data.item)"
        >
          {{ data.item.name }}
        </v-chip>
      </template>
      <template v-slot:prepend-item>
        <v-text-field class="ml-2 mr-2" v-model="searchPermission" @input="search"></v-text-field>
        <v-list-item ripple @click="toggle">
          <v-list-item-action>
            <v-icon :color="form.model_permissions.length > 0 ? 'indigo darken-4': ''">
              {{ icon }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Выбрать все
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"/>
      </template>
      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ data.item.description }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-select>
        <v-select label="Персона" chips item-text="title" item-value="id" v-model="form.person_id" :items="copyPersonsList">
      <template v-slot:selection="data">
        <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          close
          @click="data.select"
          @click:close="revokePerson"
        >
          {{ data.item.title }}
        </v-chip>
      </template>
      <template v-slot:prepend-item>
        <v-text-field class="ml-2 mr-2" v-model="searchPerson" @input="getSearchPerson"></v-text-field>
      </template>
      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.title }}
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </v-select>
    <v-text-field
      v-model="form.onboarding_data"
      label="Данные онбординга"
      disabled
    >
    </v-text-field>
    <v-checkbox
      v-model="form.is_verified"
      label="Подтвержденный email"
      />
    <v-btn
      color="primary"
      :disabled="isSaveDisabled"
      :loading="isSaveDisabled"
      @click="submit"
    >
      Сохранить
    </v-btn>
  </v-form>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import axiosInstance from "@/axiosInstance";

export default {
  name: "UserForm",
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      valid: true,
      rules: {
        emailRules: [
          v => !!v || 'Email is required',
          v => /.+@.+/.test(v) || 'Email is invalid'
        ],
        nameRules: [
          v => !!v || 'Name is required',
          v => (v && v.length > 3) || 'The minimum number of characters is 4'
        ],
      },
      menu1: false,
      form: {
        name: '',
        email: '',
        password: '',
        roles: [],
        model_permissions: [],
        person_id: '',
        phone: '',
        is_verified: false,
        date_of_birth: null,
        region_id: null,
        partner_id: null,
        olympic_partner_id: null,
      },
      searchPermission: "",
      searchPerson: '',
      copyPermissionList: [],
      copyPersonsList: {},
      regions: [],
      partners: [],
      password: '',
    }
  },
  watch: {
    user: {
      handler() {
        if (Object.keys(this.user).length > 0) {
          this.form = JSON.parse(JSON.stringify(this.user));
        }
      },
      deep: true,
      immediate: true,
    },
    permissionsList: {
      handler(val) {
        this.copyPermissionList = val;
      }
    },
    personsList: {
      handler(val) {
        this.copyPersonsList = val;
      }
    }
  },
  created() {
    this.fetchRoles();
    this.fetchPermissions();
    this.fetchPersons();
    this.getRegions();
    this.getPartners();
  },
  computed: {
    ...mapGetters({
      fetchedDetail: 'Users/getFetchedDetail',
      isSaveDisabled: 'Users/isSaveButtonDisabled',
      rolesList: 'Roles/getFetchedList',
      permissionsList: 'Permissions/getFetchedList',
      personsList: 'Persons/getFetchedList',
    }),
    icon () {
      if (this.form.model_permissions.length === this.permissionsList.length) return 'mdi-close-box'
      if (this.form.model_permissions.length > 0 && (this.form.model_permissions.length !== this.permissionsList.length)) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    }
  },
  methods: {
    ...mapActions({
      fetchRoles: 'Roles/fetchList',
      fetchPermissions: 'Permissions/fetchList',
      fetchPersons: 'Persons/fetchList',
    }),
    removeRole(item) {
      const index = this.form.roles.indexOf(item.id);
      if (index >= 0) this.form.roles.splice(index, 1);
    },
    removePermission(item) {
      const index = this.form.model_permissions.indexOf(item.id);
      if (index >= 0) this.form.model_permissions.splice(index, 1);
    },
    generatePassword() {
      this.form.password = Math.random().toString(36).slice(-11);
      this.password = this.form.password;
    },
    inputPassword(input) {
      this.form.password = input;
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.$emit('onSubmit', this.form)
      }
    },
    toggle() {
      this.$nextTick(() => {
        if (this.permissionsList.length === this.form.model_permissions.length) {
          this.form.model_permissions = [];
        } else {
          this.form.model_permissions = this.permissionsList.slice();
        }
      })
    },
    search() {
      if (!this.searchPermission) {
        this.copyPermissionList = this.permissionsList;
      } else {
        this.copyPermissionList = this.permissionsList.filter((permission) => {
          let description = false;
          if (permission.description) {
            description = permission.description.toLowerCase().indexOf(this.searchPermission.toLowerCase()) > -1;
          }
          return permission.name.toLowerCase().indexOf(this.searchPermission.toLowerCase()) > -1 || description;

        })
      }
    },
    getSearchPerson() {
      if(!this.searchPerson) {
        this.copyPersonsList = this.personsList;
      } else {
        this.copyPersonsList = this.personsList.filter((person) => {
          let title = false;
          if (person.title) {
            title = person.title.toLowerCase().indexOf(this.searchPerson.toLowerCase()) > -1;
          }
          return person.title.toLowerCase().indexOf(this.searchPerson.toLowerCase()) > -1 || title;

        })
      }
    },
    revokePerson() {
      this.form.person_id = null;
    },
    async getPartners() {
      await axiosInstance.get('partners/select-list').then((response) => {
        this.partners = response.data.data;
      });
    },
    async getRegions() {
      await axiosInstance.get('module/admin/regions/select-list').then((response) => {
        this.regions = response.data.data;
      });
    },
    save (date) {
      this.$refs.menu.save(date)
    },
  },
}
</script>

<style scoped>

</style>
