<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row justify="space-between">
          <v-col cols="12" lg="4">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Поиск..."
              filled
              background-color="transparent"
              hide-details
              name="title"
            >
              <v-btn v-if="search" class="reset-button"
                     slot="append"
                     icon
                     @click="search = null"
                     color="red"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-text-field>
          </v-col>
          <v-col>
            <v-switch
              v-model="only_trashed"
              label="Показать удаленные"
              name="only_trashed"
              value="1"
            ></v-switch>
          </v-col>
          <v-col cols="12" lg="4" class="justify-end align-center d-flex">
            <v-dialog v-model="dialog" persistent max-width="700px">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" class="mb-4" to="items/create">
                  Добавить элемент каталога
                </v-btn>
              </template>
            </v-dialog>
            <v-btn color="primary" class="mb-4 ml-2" to="dynamic-fields">
              Настройка динамических полей
            </v-btn>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" class="mb-4 ml-2" icon v-bind="attrs" v-on="on" to="items/table">
                  <v-icon>mdi-table</v-icon>
                </v-btn>
              </template>
              <span>Редактирование в режиме таблицы</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row v-if="fetchedCatalog.pattern_listing_url && fetchedCatalog.pattern_detail_url">
          <v-col>
            <div class="text text-center">
              <h3>
                Шаблон списочной странцы
                <a target="_blank" :href="fetchedCatalog.pattern_listing_url">
                  <v-icon color="primary">
                    mdi-eye
                  </v-icon>
                </a>
                <a target="_blank" :href="`${fetchedCatalog.pattern_listing_url}?edit_mode=true`">
                  <v-icon color="primary">
                    mdi-pen
                  </v-icon>
                </a>
                <v-btn icon color="primary" @click="regenerate">
                  <v-icon>
                    mdi-backup-restore
                  </v-icon>
                </v-btn>
              </h3>
            </div>
          </v-col>
          <v-col>
            <div class="text text-center">
              <h3>
                Шаблон детальной странцы
                <a target="_blank" :href="fetchedCatalog.pattern_detail_url">
                  <v-icon color="primary">
                    mdi-eye
                  </v-icon>
                </a>
                <a target="_blank" :href="`${fetchedCatalog.pattern_detail_url}?edit_mode=true`">
                  <v-icon color="primary">
                    mdi-pen
                  </v-icon>
                </a>
              </h3>
            </div>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col>
            <v-btn color="primary" block @click="generatePatterns">Сгенерировать шаблоны страниц</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :options.sync="options"
              :loading="isLoading"
              loading-text="Загрузка..."
              :items="fetchedData"
              :server-items-length="totalItems"
              :footer-props="{'items-per-page-options':[5, 10, 15, 20, -1]}"
            >
              <template v-slot:item.id="{ item }">
                <v-chip pill>{{ item.id }}</v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn icon :to="`/catalogs/${catalogId}/items/${item.id}/edit`">
                  <v-icon color="primary" small>
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <a target="_blank" :href="item.preview_url">
                  <v-icon color="primary" small>
                    mdi-eye
                  </v-icon>
                </a>
                <v-icon
                  v-if="!item.deleted_at"
                  color="red"
                  @click="deleteItem(item, 0)"
                  small
                >
                  mdi-delete
                </v-icon>
                <v-icon
                  v-else
                  color="green"
                  @click="restoreItem(item)"
                  small
                >
                  mdi-backup-restore
                </v-icon>
                <v-icon
                  color="primary"
                  @click="regenerateItem(item.id)"
                  small
                >
                  mdi-backup-restore
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
<!--        <template v-if="!tableFetchError">-->
<!--          <v-row>-->
<!--          <v-col>-->
<!--            <div class="left-sidebar">-->
<!--              <v-btn @click="addNewItem" color="green">Добавить</v-btn>-->
<!--              <v-btn @click="undo" color="orange">Отменить</v-btn>-->
<!--              <v-btn @click="updateTable" color="primary">Сохранить</v-btn>-->
<!--              <v-btn @click="deleteItems" color="error">Удалить</v-btn>-->
<!--              <v-btn @click="transferItems" color="primary">Импортировать записи</v-btn>-->

<!--                <template v-if="showEditCellButton">-->
<!--                  <v-btn-->
<!--                    class="btn btn-primary"-->
<!--                    @click="editCellModalHandler"-->
<!--                    :disabled="!isCellEditorValueValid"-->
<!--                  >-->
<!--                    Редактировать<br>-->
<!--                    ячейку-->
<!--                  </v-btn>-->
<!--                </template>-->
<!--              </div>-->
<!--              <div style="overflow-x: auto;">-->
<!--                &lt;!&ndash; <v-text-field label="Поиск" v-model="searchText" @input="search('searchableProcedures', $event, 'procedures', 'title')"/> &ndash;&gt;-->
<!--                <vue-excel-editor v-model="table.rows" ref="grid" width="100%" @cell-click="cellClickHandler($event)" @update="updatedTableData" @delete="deleteItemHandler">-->
<!--                  <vue-excel-column :invisible="true" field="id" :readonly="true" key-field/>-->
<!--                  <vue-excel-column v-for="column in table.columns" :field="column.attribute" :label="column.title" type="string" :key="column.id"/>-->
<!--                  &lt;!&ndash; <vue-excel-column field="destination" label="Назначение" type="string"/>-->
<!--                  <vue-excel-column field="description" label="Описание" type="string"/>-->
<!--                  <vue-excel-column field="announcement_description" label="Описание в анонсе" type="string"/>-->
<!--                  <vue-excel-column field="image" label="Фото" type="string"/>-->
<!--                  <vue-excel-column field="for_who" label="Кому подходит" type="string"/>-->
<!--                  <vue-excel-column field="programs" label="Программа" type="string"/>-->
<!--                  <vue-excel-column field="indications" label="Показания" type="string"/>-->
<!--                  <vue-excel-column field="results" label="Результат" type="string"/>-->
<!--                  <vue-excel-column field="video" label="Видео" type="string"/>-->
<!--                  <vue-excel-column field="science_1" label="Наука лежащая в основе (1)" type="string"/>-->
<!--                  <vue-excel-column field="science_image_1" label="Фото" type="string"/>-->
<!--                  <vue-excel-column field="science_2" label="Список препоратов для процедуры" type="string"/>-->
<!--                  <vue-excel-column field="science_image_2" label="Фото" type="string"/>-->
<!--                  <vue-excel-column field="home_care" label="Программа домашнего ухода" type="string"/> &ndash;&gt;-->
<!--                </vue-excel-editor>-->
<!--                <v-dialog-->
<!--                  v-model="dialog"-->
<!--                  persistent-->
<!--                  max-width="600px"-->
<!--                >-->
<!--                  <v-card>-->
<!--                    <v-card-title>-->
<!--                      <span class="text-h5">Редактирование ячейки</span>-->
<!--                    </v-card-title>-->
<!--                    <v-card-text>-->
<!--                      <v-container>-->
<!--                        <v-row>-->
<!--                          <v-col-->
<!--                            cols="12"-->
<!--                          >-->
<!--                            <template v-if="dialog">-->
<!--                              <tip-tap v-model="cellEditor.value" />-->
<!--                            </template>-->
<!--                          </v-col>-->
<!--                        </v-row>-->
<!--                      </v-container>-->
<!--                    </v-card-text>-->
<!--                    <v-card-actions>-->
<!--                      <v-spacer></v-spacer>-->
<!--                      <v-btn-->
<!--                        color="blue darken-1"-->
<!--                        text-->
<!--                        @click="saveCellEditorHandler"-->
<!--                      >-->
<!--                        Сохранить-->
<!--                      </v-btn>-->
<!--                      <v-btn-->
<!--                        color="red darken-1"-->
<!--                        text-->
<!--                        @click="dialog = false"-->
<!--                      >-->
<!--                        Отменить-->
<!--                      </v-btn>-->
<!--                    </v-card-actions>-->
<!--                  </v-card>-->
<!--                </v-dialog>-->

<!--                <v-dialog persistent :value="isTableLoading" width="300">-->
<!--                <v-card-->
<!--                  color="primary"-->
<!--                  dark-->
<!--                >-->
<!--                  <v-card-text>-->
<!--                    Загрузка-->
<!--                    <v-progress-linear-->
<!--                      indeterminate-->
<!--                      color="white"-->
<!--                      class="mb-0"-->
<!--                    ></v-progress-linear>-->
<!--                  </v-card-text>-->
<!--                </v-card>-->
<!--              </v-dialog>-->
<!--              </div>-->
<!--            </v-col>-->
<!--          </v-row>-->
<!--        </template>-->
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import catalogTableMixin from './catalogTableMixin'
// import TipTap from '@/components/editor/TipTap.vue';

export default {
  name: "Index",
  mixins: [catalogTableMixin],
  // components: {TipTap},
  data: () => ({
    search: '',
    only_trashed: false,
    options: {
      sortBy: ['id'],
      sortDesc: [true],
      page: 1,
      itemsPerPage: 20,
    },
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'Название', value: 'title' },
      { text: 'Slug', value: 'slug' },
      { text: 'Артикул', value: 'sku' },
      { text: 'Цена', value: 'price' },
      { text: '', value: 'actions', sortable: false, },
    ],
    page: {
      title: "Каталог"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Каталог",
        disabled: true,
        to: '/catalogs'
      },
    ],
  }),
  created() {
    this.fetchListing(this.listingPayload);
    this.fetchCatalog(this.catalogId);
  },
  computed: {
    ...mapGetters({
      "fetchedData": "CatalogItem/getFetchedListing",
      "fetchedCatalog": "Catalog/getFetchedDetail",
      "totalItems": "CatalogItem/getListingTotalItems",
      "isLoading": "CatalogItem/getIsLoading",
    }),
    listingPayload() {
      return {
        options: this.options,
        search: this.search,
        only_trashed: this.only_trashed,
        catalog_id: this.$route.params.catalog_id,
      }
    },
    catalogId() {
      return this.$route.params.catalog_id;
    },
    isCellEditorValueValid() {
      return typeof this.cellEditor.value === 'string';
    }
  },
  methods: {
    ...mapActions({
      "fetchListing": "CatalogItem/fetchListing",
      "fetchCatalog": "Catalog/fetchDetail",
      "destroyItem": "CatalogItem/destroyItem",
      "restore": "CatalogItem/restoreItem",
      "makePatterns": "Patterns/generatePatterns",
      "regenerateListing": "Catalog/regeneratePage",
      "regenerateItem": "CatalogItem/regenerateSingle",
      "transferHandler": "DynamicTables/transferData",
    }),
    deleteItemHandler(records) {
      records.forEach((item) => {
        if (item.id) {
          this.toDelete.push(item.id);
        } else {
          const index = this.toUpdate.findIndex(updatedItem => updatedItem.$id === item.$id);
          if (index + 1) this.toUpdate.splice(index, 1);
        }
      })
    },
    deleteItem({ id }, isForce) {
      this.$confirm('Удалить элемент?', 'Удаление').then(() => {
        this.destroyItem({ id, isForce }).then(() => {
          this.fetchListing(this.listingPayload);
        });
      })
    },
    restoreItem({id}) {
      this.restore(id).then(() => {
        this.fetchListing(this.listingPayload);
      })
    },
    generatePatterns() {
      this.makePatterns({
        entity_type: this.fetchedCatalog.entity_type,
        entity_id: this.fetchedCatalog.id,
      }).then(() => {
        this.fetchCatalog(this.catalogId);
      })
    },
    regenerate() {
      this.regenerateListing(this.$route.params.catalog_id);
    },
    transferItems() {
      this.transferHandler(this.table.id);
    }
  },
  watch: {
    'fetchedCatalog.title': {
      handler(newTitle) {
          this.page.title += ' ' + newTitle;
      }
    },
    options: {
      handler() {
        this.fetchListing(this.listingPayload);
      },
      deep: true,
      immediate: true,
    },
    search: {
      handler(newSearch, oldSearch) {
        newSearch != oldSearch ? this.options.page = 1 : '';
        this.fetchListing(this.listingPayload);
      },
    },
    only_trashed: {
      handler() {
        this.fetchListing(this.listingPayload);
      },
    },
    '$route.params.catalog_id': {
      handler() {
        this.fetchListing(this.listingPayload);
        this.fetchCatalog(this.catalogId);
      }
    }
  }
}
</script>

<style scoped>

</style>
