<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <block-group-form :errors="errors" @onSubmit="submit"/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import BlockGroupForm from '../../../../components/blocks/BlockGroupForm';
import { BlockGroup } from '../../../../models/blockGroup';

export default {
  name: 'Create',
  components: { BlockGroupForm },
  data: () => ({
    parent_id: null,
    errors: {},
    page: {
      title: "Создание группы блоков"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Группы блоков",
        disabled: true,
        to: '/block-groups'
      },
      {
        text: "Редактирование",
        disabled: true,
        to: '/block-groups/edit'
      },
    ]
  }),
  methods: {
    async submit(form) {
      try {
        this.errors = {};
        await BlockGroup.$query().store(form);
        await this.$router.push('/block-groups');
      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors
        }
        throw e;
      }
    },
  },
};
</script>

<style scoped>

</style>
