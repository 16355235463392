import { Model } from "@tailflow/laravel-orion/lib/model";

export class PartnerResource extends Model<{
    id: number,
    title: string,
    description: string,
    url: string,
    created_at: string,
    updated_at: string,
}> {

    $resource(): string {
        return "module/partner-resources";
    }
}
