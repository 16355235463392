import axiosInstance from "../../../axiosInstance";

export default {
  namespaced: true,
  state: {
    metaForm: {},
    isSaveDisabled: false,
  },
  getters: {
    getMetaForm: state => state.metaForm,
    getIsSaveDisabled: state => state.isSaveDisabled,
  },
  mutations: {
    setMetaForm(state, value) {
      state.metaForm = value;
    },
    setIsSaveDisabled(state, value) {
      state.isSaveDisabled = value;
    },
  },
  actions: {
    async storeItem({ commit, dispatch }, {data}) {
      commit('setIsSaveDisabled', true);

     await axiosInstance.post('meta/update/', data).then((response) => {
       commit('setMetaForm', response.data.data);
       dispatch("snackbar/showSuccessfullySaved", null , { root: true })
       commit('setIsSaveDisabled', false);
     }).catch(() => {
        dispatch("snackbar/showUnknownError", null , { root: true })
       commit('setIsSaveDisabled', false);
     }).finally(() => {
       commit('setIsSaveDisabled', false);
     });
    },
    async getMetaData({ commit, dispatch }, {entity_type, entity_id}) {
      let data = {entity_type, entity_id};

      await axiosInstance.post('meta/get', data).then((response) => {
        if (response.data.data)
          commit('setMetaForm', response.data.data);
        else
          commit('setMetaForm', {entity_type, entity_id, 'titlePostFix': response.data.onlyPrefix});
      }).catch(() => {
        dispatch("snackbar/showUnknownError", null , { root: true })
      })
    }
  },
}
