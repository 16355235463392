<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <div>
          <CronForm :cron="detail" @onSubmit="update"/>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CronForm from "../../../components/cron/CronForm";

export default {
  name: "Edit",
  components: {CronForm},
  data: () => ({
    page: {
      title: "Редактирование команды"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Каталог",
        disabled: false,
        to: '/cron'
      },
      {
        text: "Редактирование",
        disabled: true,
        to: '/cron/edit'
      },
    ],
  }),
  created() {
    this.findItem(this.$route.params.id);
  },
  computed: {
    ...mapGetters({
      detail: "Cron/getDetail",
    })
  },
  methods: {
    ...mapActions({
      findItem: "Cron/findItem",
      updateItem: "Cron/updateItem",
    }),
    update(form) {
      this.updateItem(form).then(() => {
        this.$router.push({ name: 'cron-index' });
      })
    }
  },
  watch: {
    'detail.id': {
      handler(newTitle) {
        this.page.title +=  ` "${newTitle}"`;
      }
    },
  }
}
</script>

<style scoped>

</style>
