import Index from '../../views/main/customers/Index'
import Create from '../../views/main/customers/Create'
import Edit from '../../views/main/customers/Edit'

export default [
  {
    component: Index,
    name: 'customers-index',
    path: '/customers',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'customers',
    }
  },
  {
    component: Create,
    name: 'customers-create',
    path: '/customers/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'customers',
    }
  },
  {
    component: Edit,
    name: 'customers-edit',
    path: '/customers/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'customers',
    }
  }
]
