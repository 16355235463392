var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('h1',[_vm._v("Уроки курса \""+_vm._s(_vm.course.title)+"\"")])])],1),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"mr-1",attrs:{"color":"gray","to":"/courses"}},[_vm._v(" Обратно к курсам ")]),_c('v-btn',{attrs:{"color":"primary","to":("/course/" + _vm.courseId + "/lessons/create")}},[_vm._v(" Создать урок ")])],1),_c('v-col',[_c('v-checkbox',{attrs:{"label":"Показать удаленные","name":"only_trashed","value":"1"},model:{value:(_vm.only_trashed),callback:function ($$v) {_vm.only_trashed=$$v},expression:"only_trashed"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.deleted_at)?_c('v-icon',{attrs:{"color":"green"},on:{"click":function($event){return _vm.restoreItem(item)}}},[_vm._v(" mdi-backup-restore ")]):[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.course && item.course.admin_attach_url)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","target":"_blank"},on:{"click":function($event){return _vm.attachItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-eye-plus ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Предпросмотр купленного урока")])]),_c('v-btn',{attrs:{"href":_vm.editItem(item),"icon":""}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-pencil ")])],1),_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]]}},{key:"item.publish",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.status),"text-color":"white"}},[_vm._v(" "+_vm._s(item.human_status)+" ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }