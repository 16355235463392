import axiosInstance from "../../../axiosInstance";
import {Mail} from "../../../models/mails";

export default {
  namespaced: true,
  state: {
    fetchedListing: [],
    listingTotalItems: 0,
    fetchedDetail: {},
    fetchedEntities: [],
  },
  getters: {
    getFetchedDetail: (state) => state.fetchedDetail,
    getFetchedListing: (state) => state.fetchedListing,
    getListingTotalItems: (state) => state.listingTotalItems,
    getFetchedEntities: (state) => state.fetchedEntities,
  },
  mutations: {
    setFetchedDetail(state, val) {
      state.fetchedDetail = val;
    },
    setFetchedListing(state, val) {
      state.fetchedListing = val;
    },
    setListingTotalItems(state, val) {
      state.listingTotalItems = val;
    },
    setFetchedEntities(state, val) {
      state.fetchedEntities = val;
    }
  },
  actions: {
    async fetchListing({commit}, {options, search, only_trashed}) {
      const {sortBy, sortDesc, page, itemsPerPage} = options;
      const onlyTrashed = only_trashed ? `&only_trashed=1` : '';
      let data = {};

      search ? data.search = {value: search} : null;
      sortBy && sortBy[0] ? data.sort = [{field: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc'}] : null;

      await axiosInstance.post(`/module/mails/search?limit=${itemsPerPage}&page=${page}${onlyTrashed}`, data).then((response) => {
        commit('setFetchedListing', response.data.data);
        commit('setListingTotalItems', response.data.meta.total);
      })
    },
    async deleteItem({ dispatch },id) {
      Mail.$query().destroy(id, 0).then(() => {
        dispatch('snackbar/showSuccessfullyDelete', null, {root: true});
      }).catch(() => {
        dispatch('snackbar/showDeleteError', null, {root: true});
      });
    },
    async recoverItem({ dispatch }, id) {
      Mail.$query().restore(id).then(() => {
        dispatch('snackbar/showSuccessfullyRestore', null, {root: true});
      }).catch(() => {
        dispatch('snackbar/showErrorRestore', null, {root: true});
      })
    },
    async fetchDetail({ dispatch, commit }, id) {
      Mail.$query().restore(id).then((response) => {
        commit('setFetchedDetail', response.$attributes);
      }).catch(() => {
        dispatch('snackbar/showUnknownError', null, { root: true });
      })
    },
    async updateItem({ dispatch, commit }, form) {
      Mail.$query().update(form.id, form).then((response) => {
        dispatch('snackbar/showSuccessfullySaved', null, { root: true });
        commit('setFetchedDetail', response.$attributes);
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, { root: true });
      })
    },
    async fetchEntities({ commit }) {
      axiosInstance.get('/module/mails/entities').then((response) => {
        commit('setFetchedEntities', response.data);
      })
    },
    async storeItem({ dispatch }, { form, router }) {
      Mail.$query().store(form).then((response) => {
        dispatch('snackbar/showSuccessfullySaved', null, { root: true });
        router.push({ name: 'mails-edit', params: { id: response.$attributes.id } });
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, { root: true });
      })
    },
    async syncMails({ dispatch }, payload) {
      await axiosInstance.post('/module/mails/sync', payload).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, { root: true });
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, { root: true });
      });
    }
  },
}
