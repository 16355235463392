<template>
  <div>
    <div class="mb-5">
      <v-btn color="primary" href="/temp/region-statistic">Статистика</v-btn>
      <v-btn color="primary" href="/temp/region-monitoring">Мониторинги</v-btn>
      <v-btn color="primary" href="/temp/region-olympic">Олимпиады</v-btn>
      <v-btn color="primary" href="/temp/region-buget">Инициативное бюджетирование</v-btn>
      <v-btn color="primary" href="/temp/region-export" disabled>Данные о регионах</v-btn>
    </div>
    <div v-if="regions">
      <div>
        <v-btn color="primary" @click="onCreate">Добавить</v-btn>
        <v-btn color="error" @click="onDelete">Удалить</v-btn>
        <v-btn color="green" @click="onSave">Сохранить</v-btn>
      </div>
      <vue-excel-editor ref="grid" v-model="tempModel">
        <vue-excel-column field="field_1" label="Субъект (5.field_1)" type="map" :options="regions.title"/>
        <vue-excel-column field="field_2" label="ФО (5.field_2)" type="select" :options="regions.district"/>
        <vue-excel-column field="field_3" label="Межведомственный орган 1 (5.field_3)"/>
        <vue-excel-column field="field_4" label="Межведомственный орган 2 (5.field_4)"/>
        <vue-excel-column field="field_5" label="Рабочая группа (5.field_5)"/>
        <vue-excel-column field="field_6" label="Экспертный орган 1 (5.field_6)"/>
        <vue-excel-column field="field_7" label="Экспертный орган 2 (5.field_7)"/>
        <vue-excel-column field="field_8" label="Регулирующий документ (5.field_8)"/>
        <vue-excel-column field="field_9" label="Регулирующий документ 2 (5.field_9)"/>
        <vue-excel-column field="field_10" label="Регулирующий документ 3 (5.field_10)"/>
        <vue-excel-column field="field_11" label="Регулирующий документ 4 (5.field_11)"/>
        <vue-excel-column field="field_12" label="Регулирующий документ 5 (5.field_12)"/>
        <vue-excel-column field="field_13" label="Региональный центр финансовой грамотности (5.field_13)"/>
             <vue-excel-column field="field_24" label="(ID) Региональный центр финансовой грамотности (5.field_24)"/>
        <vue-excel-column field="field_14" label="Должност (5.field_14)" />
        <vue-excel-column field="field_15" label="Контакт (ФИО) (5.field_15)"/>
        <vue-excel-column field="field_16" label="Контакт (Email) (5.field_16)"/>
        <vue-excel-column field="field_17" label="Контакт (Phone) (5.field_17)"/>
        <vue-excel-column field="field_18" label="Регулирующий док рцфг 1 (5.field_18)"/>
        <vue-excel-column field="field_19" label="Регулирующий док рцфг 2 (5.field_19)"/>
        <vue-excel-column field="field_20" label="Докумнеты (5.field_20)"/>
        <vue-excel-column field="field_21" label="Региональный документ 1 (5.field_21)"/>
        <vue-excel-column field="field_22" label="Региональный документ 2 (5.field_22)"/>
        <vue-excel-column field="field_23" label="Региональный документ 3 (5.field_23)"/>
      </vue-excel-editor>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import axiosInstance from "../../../axiosInstance";

export default {
  name: "RegionExport",
  data: () => ({
    regions: null,
  }),
  created() {
    axiosInstance.get('temp-data/regions').then((resp) => {
      this.regions = resp.data;
      this.fetchTempData(5);
    });
  },
  computed: {
    ...mapGetters({
      tempData: "TempData/getTempData",
    }),
    tempModel: {
      set(val) {
        this.setTempData(val);
      },
      get() {
        return this.tempData;
      }
    }
  },
  methods: {
    ...mapActions({
      fetchTempData: "TempData/fetchTempData",
      saveTempData: "TempData/saveTempData",
    }),
    ...mapMutations({
      setTempData: "TempData/setTempData",
    }),
    onCreate(){
      this.$refs.grid.newRecord();
    },
    onDelete(){
      this.$refs.grid.deleteRecord(0);
    },
    onSave() {
      this.saveTempData(5);
    }
  }
}
</script>

<style scoped>

</style>
