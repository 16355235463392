<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Редактирование организатора: {{ organizer.title }}</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <organizer-form
          :organizer="organizer"
          :errors="errors"
          @onSubmit="submit"
        ></organizer-form>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbarSuccess"
      :timeout="timeout"
      color="success"
    >
      Сохранено

      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          @click="snackbarSuccess = false"
          color="red"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackbarError"
      :timeout="timeout"
      color="error"
    >
      {{ this.errors }}

      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          @click="snackbarError = false"
          color="red"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { Organizer } from '../../../models/organizer';
import OrganizerForm from "../../../components/organizers/OrganizerForm";

export default {
  name: 'Edit',
  components: {
    OrganizerForm,
  },
  data: () => ({
    organizer: {},
    media: {},
    errors: {},
    snackbarSuccess: false,
    snackbarError: false,
    timeout: 2000,
  }),
  created() {
    this.find();
  },
  methods: {
    async submit({ form, mediaIds }) {
      try {
        this.errors = {};
        if (mediaIds) {
          form.mediaIds = mediaIds;
        }
        await Organizer.$query().update(form.id, form);
        await this.find();
        this.snackbarSuccess = true;
      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors;
          this.snackbarError = true;
        }
        throw e;
      }
    },
    async find() {
      const organizer = await Organizer.$query().find(this.$route.params.id);
      this.organizer = organizer.$attributes;
    },
  },
};
</script>
