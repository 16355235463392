<template>
  <div class="text-center">
        <div class="widget-content">
          <table>
          <tbody>
            <tr>
              <th v-for="item in items"
                :key="item.id">
                  <td style="padding:0 0 30px 10px;text-align:center;">
                  <div style="display:inline-block;max-width:350px;padding:30px 0 0 0;vertical-align:top;width:100%;background-color: #dddddd;border-radius: 3px;">
                    <table align="center" border="0" cellspacing="0" cellpadding="0" width="350" style="border-collapse:collapse;border-spacing:0">
                      <tbody>
                        <tr>
                          <td height="220" style="text-align:left;padding:0 0 0 16px">
                            <img :src="item.image_listing" style="border:0;height:auto;outline:none;text-decoration:none;width:200px" width="200" :alt="item.title">
                          </td>
                        </tr>
                        <tr>
                          <td style="padding:16px 0 0 0">
                            <table border="0" cellspacing="0" cellpadding="0" width="100%" style="border-collapse:collapse;border-spacing:0">
                            <tbody>
                              <tr>
                                <td width="110" style="padding:0 0 0 16px;text-align:left" v-if="item.main_taxonomy">
                                <table border="0" cellspacing="0" cellpadding="0" width="110" style="border-collapse:collapse;border-spacing:0;width:110px">
                                  <tbody>
                                    <tr>
                                      <td style="color:#ffffff;  font-size:10px;line-height:16px;padding:4px 6px 4px 6px;text-align:center;background-color:#623bb4;border-radius:2px;text-transform:uppercase">
                                        {{item.main_taxonomy.title}}
                                      </td>
                                    </tr>
                                  </tbody>
                                  </table>
                                </td>

                                <td style="padding:0 0 0 12px;text-align:left">
                                <table border="0" cellspacing="0" cellpadding="0" width="85" style="border-collapse:collapse;border-spacing:0;width:85px">
                                  <tbody>
                                    <tr>
                                      <td style="color:#ffffff;  font-size:12px;line-height:16px;padding:4px 6px 4px 6px;text-align:center;background-color:#f87d87;border-radius:2px;font-weight:bold;text-transform:uppercase">
                                        {{item.ages}}
                                      </td>
                                  </tr>
                                </tbody></table>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td height="70"  style="color:#0d0c22;font-family:'PT Sans',Arial;font-weight:bold;font-size:20px;line-height:24px;padding:16px 16px 0 16px;text-align:left;vertical-align:top">
                            <a :href="getUrl(item)" style="text-decoration:none;color:#262626" >{{item.title}}</a>
                          </td>
                        </tr>
                        <tr>
                          <td  style="color:#0d0c22;font-family:'PT Sans',Arial;font-weight:bold;font-size:14px;line-height:14px;padding:10px 16px 0 16px;text-align:left;vertical-align:top">
                            <strong>Автор:</strong> {{item.widget_person.full_name}}
                          </td>
                        </tr>
                        <tr>
                          <td style="padding:0">
                            <table border="0" cellspacing="0" cellpadding="0" width="100%" style="border-collapse:collapse;border-spacing:0">
                            <tbody>
                              <tr>
                              <td style="text-align:right;padding:20px 20px 0 20px">
                                <div align="left">
                                  <a :href="getUrl(item)"
                                    style="background-color:#26a9e0;border-radius:4px;color:#ffffff;display:inline-block;font-family:Arial;font-size:16px;line-height:40px;text-align:center;text-decoration:none;width:150px">
                                    Купить курс
                                  </a>
                                </div>
                              </td>
                              <td style="font-family:'PT Sans',Arial;font-weight:bold;font-size:13px;line-height:20px;padding:17px 0 0 16px;text-align:left;white-space:nowrap">
                                <div class="">
                                  <div class="flex gap-x-1">
                                    <svg xmlns="http://www.w3.org/2000/svg"  width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                      <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"></path>
                                      <rect x="9" y="3" width="6" height="4" rx="2"></rect>
                                      <path d="M9 17v-5"></path>
                                      <path d="M12 17v-1"></path>
                                      <path d="M15 17v-3"></path>
                                    </svg>
                                    <span>{{item.avg_rating}}</span>
                                  </div>
                                  <div class="flex gap-x-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                      <path d="M3 19a9 9 0 0 1 9 0a9 9 0 0 1 9 0"></path>
                                      <path d="M3 6a9 9 0 0 1 9 0a9 9 0 0 1 9 0"></path>
                                      <line x1="3" y1="6" x2="3" y2="19"></line>
                                      <line x1="12" y1="6" x2="12" y2="19"></line>
                                      <line x1="21" y1="6" x2="21" y2="19"></line>
                                    </svg>
                                    <span>{{item.uniq_visitors}}</span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            </tbody></table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </th>
            </tr>

          </tbody>
        </table>
        </div>
  </div>
</template>

<script>

export default {
  props: {
    items: {
      type: Array,
    },
    partner: {
      type: String,
      default: ""
    },
  },
  computed: {
  },
  data: () => ({
  }),
  async created() {
  },
  methods: {
    getUrl(item) {
      return item.preview_url + '?theme=&teacher=&source=widget&position=0&partner='+ this.partner;
    }
  },
};
</script>

<style scoped>
.widget-content {
  padding: 60px;
}
</style>
