<template>
  <div>
    <v-row>
      <v-col>
        <h1>Редактирование урока {{ lesson.title }}</h1>
      </v-col>
    </v-row>
    <v-tabs
      v-model="tab"
      background-color="transparent"
      grow
      class="mb-5"
    >
      <v-tab>Основная информация</v-tab>
      <v-tab v-if="lesson && lesson.id">Вопросы к уроку</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <lesson-form
          :lesson="lesson"
          @onSubmit="submit"
          @onFileUpload="uploadFile"
        ></lesson-form>
      </v-tab-item>
      <v-tab-item>
        <lesson-control-question v-model="lesson.control_question" :entity_type="lesson.entity_type" :entity_id="lesson.id"/>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import LessonForm from "../../../components/lessons/LessonForm";
import LessonControlQuestion from "../../../components/lessons/LessonControlQuestion";
import {mapActions, mapGetters} from "vuex";
import {Media} from "../../../models/media";

export default {
  name: 'Edit',
  components: {
    LessonForm,
    LessonControlQuestion,
  },
  data: () => ({
    tab: 0,
  }),
  created() {
    this.fetchDetail(this.$route.params.id);
  },
  computed: {
    ...mapGetters({
      lesson: 'Lessons/getFetchedDetail'
    })
  },
  methods: {
    ...mapActions({
      fetchDetail: 'Lessons/fetchDetail',
      updateItem: 'Lessons/updateItem',
      uploadConspectus: 'Lessons/uploadFile',
    }),
    async submit({form, mediaIds, isNeedRedirect, media}) {
        if (mediaIds) {
          form.mediaIds = mediaIds;
        }

        if (media && media.id) {
          await Media.$query().update(media.id, {...media, entity_id: form.id});
        } else if (media) {
          await Media.$query().store({...media, entity_id: form.id});
        }

        this.updateItem(form).then(() => {
          if (isNeedRedirect) {
            this.$router.push({name: 'lessons-index', params: {course_id: this.$route.params.course_id}});
          }
        });
    },
    async uploadFile(data) {
      const payload = {
        form: data,
        lessonID: this.$route.params.id,
      }

      await this.uploadConspectus(payload);
    },
  },
};
</script>
