import { Model } from "@tailflow/laravel-orion/lib/model";

export class QuestionsAnswers extends Model<{
    id: number,
    question: number,
    answer: string,
    created_at: string,
    updated_at: string,
}> {

    $resource(): string {
        return "answers";
    }

}

