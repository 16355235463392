import Index from '../../views/main/partners-statistic/Index'

export default [
  {
    component: Index,
    name: 'partners-statistic-index',
    path: '/partners-statistic',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  }
]
