<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Справочник функциональных иконок</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          color="primary"
          @click="$router.push({ name: 'functional-icons-create' })"
        >
          Создать
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
        >
          <template v-slot:item.action="{item}">
            <v-btn
              :href="editItem(item)"
              icon
            >
              <v-icon color="primary">
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-icon
              color="red"
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {FunctionalIcon} from "../../../models/functionalIcon";

export default {
  name: "Index",
  data: () => ({
    headers: [
      { text: 'id', value: 'id'},
      { text: 'Название', value: 'title' },
      { text: 'Slug', value: 'slug' },
      { text: '', value: 'action' },
    ],
    items: [],
  }),
  created() {
    this.get();
  },
  methods: {
    async get() {
      this.items = (await FunctionalIcon.$query().get()).map(icon => icon.$attributes);
    },
    deleteItem(item) {
      FunctionalIcon.$query().destroy(item.id).then(() => this.get());
    },
    editItem(item) {
      return this.$router.resolve({ name: 'functional-icons-edit', params: { id: item.id } }).href
    },
  }
}
</script>

<style scoped>

</style>
