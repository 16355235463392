<template>
  <div>
    <div class="mb-5">
      <v-btn color="primary" href="/temp/region-statistic">Статистика</v-btn>
      <v-btn color="primary" href="/temp/region-monitoring" disabled>Мониторинги</v-btn>
      <v-btn color="primary" href="/temp/region-olympic">Олимпиады</v-btn>
      <v-btn color="primary" href="/temp/region-buget">Инициативное бюджетирование</v-btn>
      <v-btn color="primary" href="/temp/region-export">Данные о регионах</v-btn>
      <v-btn color="primary" href="/temp/region-export">Данные о регионах</v-btn>
    </div>
    <div v-if="regions">
      <div>
        <v-btn color="primary" @click="onCreate">Добавить</v-btn>
        <v-btn color="error" @click="onDelete">Удалить</v-btn>
        <v-btn color="green" @click="onSave">Сохранить</v-btn>
      </div>
      <vue-excel-editor ref="grid" v-model="tempModel">
        <vue-excel-column field="field_1" label="Субъект (2.field_1)" type="map" :options="regions.title"/>
        <vue-excel-column field="field_2" label="ФО (2.field_2)" type="select" :options="regions.district"/>
        <vue-excel-column field="field_3" label="Общее количество общеобразовательных организаций субъекта Российской Федерации (2.field_3)"/>
        <vue-excel-column field="field_4" label="Количество общеобразовательных организаций субъекта Российской Федерации, которые обеспечили включение элементов финансовой грамотности в образовательные (2.field_4)"/>
        <vue-excel-column field="field_5" label="Доля школ, охваченных преподаванием финансовой грамотности и её элементов в других дисциплинах (2.field_5)"/>
        <vue-excel-column field="field_6" label="(Общее количество обучающихся) Начальное общее образование (2.field_6)"/>
        <vue-excel-column field="field_7" label="(Общее количество обучающихся) Основное общее образование (2.field_7)"/>
        <vue-excel-column field="field_8" label="(Общее количество обучающихся)Среднее общее образование (2.field_8)"/>
        <vue-excel-column field="field_9" label="Итого (2.field_9)"/>
        <vue-excel-column field="field_10" label="(Количество обучающихся) Начальное общее образование (2.field_10)"/>
        <vue-excel-column field="field_11" label="(Количество обучающихся) Основное общее образование (2.field_11)"/>
        <vue-excel-column field="field_12" label="(Количество обучающихся) Среднее общее образование (2.field_12)"/>
        <vue-excel-column field="field_13" label="Итого (2.field_13)"/>
        <vue-excel-column field="field_14" label="(Доля обучающихся) Начальное общее образование (2.field_14)"/>
        <vue-excel-column field="field_15" label="(Доля обучающихся) Основное общее образование (2.field_15)"/>
        <vue-excel-column field="field_16" label="(Доля обучающихся) Среднее общее образование (2.field_16)"/>
        <vue-excel-column field="field_17" label="Итого (2.field_17)"/>
        <vue-excel-column field="field_18" label="Общее количество профессиональных образовательных организаций субъекта Российской Федерации (2.field_18)"/>
        <vue-excel-column field="field_19" label="Количество профессиональных образовательных организаций субъекта Российской Федерации, которые обеспечили включение элементов финансовой грамотности в образовательные программы среднего профессионального образования (2.field_19)"/>
        <vue-excel-column field="field_20" label="Доля профессиональных образовательных организаций, осуществляющих деятельность на территории субъекта Российской Федерации, которые обеспечили включение элементов финансовой грамотности в образовательные программы среднего профессионального образования (2.field_20)"/>
        <vue-excel-column field="field_21" label="Общее количество обучающихся в профессиональных образовательных организациях субъекта Российской Федерации (2.field_21)"/>
        <vue-excel-column field="field_22" label="Количество обучающихся в профессиональных образовательных организациях субъекта Российской Федерации, принявших участие в мероприятиях, направленных на повышение финансовой грамотности (2.field_22)"/>
        <vue-excel-column field="field_23" label="Доля обучающихся в профессиональных образовательных организациях субъекта Российской Федерации, принявших участие в мероприятиях, направленных на повышение финансовой грамотност (2.field_23)"/>
        <vue-excel-column field="field_24" label="Доля обучающихся в профессиональных образовательных организациях субъекта Российской Федерации, принявших участие в мероприятиях, направленных на повышение финансовой грамотност (2.field_24)"/>
        <vue-excel-column field="field_25" label="(Количество проведенных) за 2021 год (2.field_25)"/>
        <vue-excel-column field="field_26" label="(Количество проведенных) ИТОГО на текущий год (2.field_26)"/>
        <vue-excel-column field="field_27" label="(Количество участников) за 2021 год (2.field_27)"/>
        <vue-excel-column field="field_28" label="(Количество участников) ИТОГО на текущий год (2.field_28)"/>
        <vue-excel-column field="field_29" label="(Кол-во публикации) за 2021 год (2.field_29)"/>
        <vue-excel-column field="field_30" label="(Кол-во публикации) ИТОГО на текущий год (2.field_30)"/>
        <vue-excel-column field="field_31" label="(Кол-во передач/видеороликов) за 2021 год (2.field_31)"/>
        <vue-excel-column field="field_32" label="(Кол-во передач/видеороликов) ИТОГО на текущий год (2.field_32)"/>
        <vue-excel-column field="field_33" label="(Кол-во передач/аудиороликов) за 2021 год (2.field_33)"/>
        <vue-excel-column field="field_34" label="(Кол-во передач/аудиороликов) ИТОГО на текущий год (2.field_34)"/>
        <vue-excel-column field="field_35" label="(Тираж буклетов) за 2021 год (2.field_35)"/>
        <vue-excel-column field="field_36" label="(Тираж буклетов) ИТОГО на текущий год (2.field_36)"/>
        <vue-excel-column field="field_37" label="(Кол-во публикации в печатных и интернет СМИ, шт.) за 2021 год (2.field_37)"/>
        <vue-excel-column field="field_38" label="(Кол-во публикации в печатных и интернет СМИ, шт.) ИТОГО на текущий год (2.field_38)"/>
      </vue-excel-editor>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import axiosInstance from "../../../axiosInstance";

export default {
  name: "RegionMonitoring",
  data: () => ({
    regions: null,
  }),
  created() {
    axiosInstance.get('temp-data/regions').then((resp) => {
      this.regions = resp.data;
      this.fetchTempData(2);
    });
  },
  computed: {
    ...mapGetters({
      tempData: "TempData/getTempData",
    }),
    tempModel: {
      set(val) {
        this.setTempData(val);
      },
      get() {
        return this.tempData;
      }
    }
  },
  methods: {
    ...mapActions({
      fetchTempData: "TempData/fetchTempData",
      saveTempData: "TempData/saveTempData",
    }),
    ...mapMutations({
      setTempData: "TempData/setTempData",
    }),
    onCreate(){
      this.$refs.grid.newRecord();
    },
    onDelete(){
      this.$refs.grid.deleteRecord(0);
    },
    onSave() {
      this.saveTempData(2);
    }
  }
}
</script>

<style scoped>

</style>
