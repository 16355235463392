import { Model } from "@tailflow/laravel-orion/lib/model";

export class Promocode extends Model<{
    title: string,
    type: number,
    amount: string,
    created_at: string,
    updated_at: string,
}> {

    $resource(): string {
        return "promocodes";
    }
}
