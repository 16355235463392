import Index from '../../views/main/systemPhrases/Index';

export default [
  {
    component: Index,
    name: 'system-phrases',
    path: '/system-phrases',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
]
