<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <suggest-form
              :suggest="suggest"
              :errors="errors"
              @onSubmit="submit"
            />
          </v-col>
        </v-row>
        <v-snackbar
          v-model="snackbarSuccess"
          :timeout="timeout"
          color="success"
        >
          Сохранено

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarSuccess = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar
          v-model="snackbarError"
          :timeout="timeout"
          color="error"
        >
          {{ this.errors }}

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarError = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import SuggestForm from '../../../components/suggests/SuggestForm';
import {Suggest} from '../../../models/suggest';

export default {
  name: 'Edit',
  components: {SuggestForm},
  data: () => ({
    suggest: {},
    errors: {},
    snackbarSuccess: false,
    snackbarError: false,
    timeout: 2000,
    page: {
      title: "Редактирование саджеста"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Саджесты",
        disabled: true,
        to: '/suggests'
      },
      {
        text: "Редактирование",
        disabled: true,
        to: '/suggests/edit'
      },
    ]
  }),
  created() {
    this.find();
  },
  methods: {
    async submit(form) {
      try {
        this.errors = {};
        await Suggest.$query().update(form.id, form);
        await this.find();
        this.snackbarSuccess = true;
      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors
        }
        this.snackbarError = true;
        throw e;
      }
    },
    async find() {
      const suggest = await Suggest.$query().find(this.$route.params.id);
      this.suggest = suggest.$attributes;
    }
  },
  watch: {
    'suggest.title': {
      handler(newTitle) {
        this.page.title += ` "${newTitle}"`;
      }
    },
  }
};
</script>
