<template>
  <div>
    <div class="mb-5">
      <v-btn color="primary" href="/temp/region-statistic">Статистика</v-btn>
      <v-btn color="primary" href="/temp/region-monitoring">Мониторинги</v-btn>
      <v-btn color="primary" href="/temp/region-olympic" disabled>Олимпиады</v-btn>
      <v-btn color="primary" href="/temp/region-buget">Инициативное бюджетирование</v-btn>
      <v-btn color="primary" href="/temp/region-export">Данные о регионах</v-btn>
    </div>
    <div v-if="regions">
      <div>
        <v-btn color="primary" @click="onCreate">Добавить</v-btn>
        <v-btn color="error" @click="onDelete">Удалить</v-btn>
        <v-btn color="green" @click="onSave">Сохранить</v-btn>
      </div>
      <vue-excel-editor ref="grid" v-model="tempModel">
        <vue-excel-column field="field_1" label="Субъект (3.field_1)" type="map" :options="regions.title"/>
        <vue-excel-column field="field_2" label="ФО (3.field_2)" type="select" :options="regions.district"/>
        <vue-excel-column field="field_3" label="(Число участников) Олимпиада НИУ ВШЭ «Высшая проба» (3.field_3)"/>
        <vue-excel-column field="field_4" label="(Число участников) Олимпиада РАНХиГС (3.field_4)"/>
        <vue-excel-column field="field_5" label="(Число участников) Олимпиада экономического факультета МГУ им. М.В. Ломоносова (3.field_5)"/>
        <vue-excel-column field="field_6" label="(Число участников) Олимпиада Российского экономического Университета имени Г.В. Плеханова (3.field_6)"/>
        <vue-excel-column field="field_7" label="(Победители и призеры олимпиад) Олимпиада НИУ ВШЭ «Высшая проба» (3.field_7)"/>
        <vue-excel-column field="field_8" label="(Победители и призеры олимпиад) Олимпиада РАНХиГС (3.field_8)"/>
        <vue-excel-column field="field_9" label="(Победители и призеры олимпиад) Олимпиада экономического факультета МГУ им. М.В. Ломоносова(3.field_9)"/>
        <vue-excel-column field="field_10" label="(Победители и призеры олимпиад) Олимпиада Российского экономического Университета имени Г.В. Плеханова (3.field_10)"/>
      </vue-excel-editor>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import axiosInstance from "../../../axiosInstance";

export default {
  name: "RegionOlympic",
  data: () => ({
    regions: null,
  }),
  created() {
    axiosInstance.get('temp-data/regions').then((resp) => {
      this.regions = resp.data;
      this.fetchTempData(3);
    });
  },
  computed: {
    ...mapGetters({
      tempData: "TempData/getTempData",
    }),
    tempModel: {
      set(val) {
        this.setTempData(val);
      },
      get() {
        return this.tempData;
      }
    }
  },
  methods: {
    ...mapActions({
      fetchTempData: "TempData/fetchTempData",
      saveTempData: "TempData/saveTempData",
    }),
    ...mapMutations({
      setTempData: "TempData/setTempData",
    }),
    onCreate(){
      this.$refs.grid.newRecord();
    },
    onDelete(){
      this.$refs.grid.deleteRecord(0);
    },
    onSave() {
      this.saveTempData(3);
    }
  }
}
</script>

<style scoped>

</style>
