<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <h1>{{ page.title }}</h1>
          </v-col>
        </v-row>
        <v-tabs
          v-model="tab"
          background-color="transparent"
          grow
          class="mb-5"
        >
          <v-tab>
            Основная информация
          </v-tab>
          <v-tab>
            Предустановленные курсы
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <season-pass-form
              :entity=entity
              @onSubmit="submit"
            />
          </v-tab-item>
          <v-tab-item>
            <set-presets
              :entity=entity
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import SeasonPassForm from "@/components/seasonPass/SeasonPassForm";
import {SeasonPass} from "@/models/seasonPass";
import SetPresets from "@/components/seasonPass/SetPresets";
import {mapMutations} from "vuex";

export default {
  name: 'Edit',
  components: {SetPresets, SeasonPassForm},
  data: () => ({
    entity: {},
    page: {
      title: "Редактирование абонемента"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Абонементы",
        disabled: false,
        to: '/season-pass'
      },
      {
        text: "Редактирование",
        disabled: true,
        to: '/season-pass/edit'
      }
    ],
    tab: 0,
  }),
  created() {
    this.find();
  },
  methods: {
    ...mapMutations({
      setIsSaveButtonDisabled: 'SeasonPass/setIsSaveButtonDisabled',
    }),
    async find() {
      const entity = await SeasonPass.$query().find(this.$route.params.id);

      this.entity = entity.$attributes;
    },
    async submit({form, isNeedRedirect}) {
      this.setIsSaveButtonDisabled(true);
      try {
        this.errors = {};

        /* Проверка данных */
        form.active = (form.active === null ? false : form.active);
        form.test_mode = (form.test_mode === null ? false : form.test_mode);

        await SeasonPass.$query().update(form.id, form);

        this.$store.dispatch('snackbar/showSuccessfullySaved', null, {root: true});

        if (isNeedRedirect) {
          this.$router.push({ name: 'seasonPass-index' });
        }

      } catch (e) {
        this.$store.dispatch('snackbar/showSaveError', null, {root: true});

        if (e.response?.data) {
          this.errors = e.response.data.errors || e.response.data.message;
          this.snackbarError = true;
        } else {
          this.errors = e;
          this.snackbarError = true;
        }
        throw e;
      }
      this.setIsSaveButtonDisabled(false);
    },
  },
}
</script>
