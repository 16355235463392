<template>
  <v-container>
    <v-card v-if="statistic.indicators">
      <v-card-title>
        <v-row align="center">
          <v-col cols="3">
            Статистика по мероприятиям
          </v-col>
          <v-col>
            <v-autocomplete :items="eventsList" v-model="selectedEvents" multiple item-value="slug" item-text="title" chips deletable-chips>
              <template v-slot:prepend-item>
                <v-list-item ripple @mousedown.prevent @click="selectedEventsToggle">
                  <v-list-item-action>
                    <v-icon :color="selectedEvents.length > 0 ? 'primary' : ''">
                      {{ autoCompleteIcon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      Выбрать всё
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"/>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index < 3">
                  <span>{{ item.title }}</span>
                </v-chip>
                <span
                  v-if="index === 3"
                  class="grey--text text-caption"
                >
                  (+{{ selectedEvents.length - 3 }} других)
               </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-dialog
              ref="dialog"
              v-model="dateDialog"
              :return-value.sync="date"
              persistent
              width="290px"
              >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Выберите промежуток"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  />
              </template>
              <v-date-picker
                v-model="date"
                range
                scrollable
                locale="ru-RU"
                >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="dateDialog = false"
                  >
                  Закрыть
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(date)"
                  >
                  Oк
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <div>
          <v-row>
            <v-col sm="3">
              <div class="text h3">Пользователи</div>
              <div class="text h3 font-weight-normal">{{ statistic.indicators.users }}</div>
            </v-col>
            <v-col sm="3">
              <div class="text h3">Просмотр страниц</div>
              <div class="text h3 font-weight-normal">{{ statistic.indicators.pageviews }}</div>
            </v-col>
            <v-col>
              <div class="text h3">Ср.длительность просмотра стр.</div>
              <div class="text h3 font-weight-normal">{{ statistic.indicators.avrDuration }}</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="3">
              <div class="text h3">Подано заявок</div>
              <div class="text h3 font-weight-normal">{{ statistic.indicators.applications_submitted }}</div>
            </v-col>
            <v-col sm="3">
              <div class="text h3">Опубликовано</div>
              <div class="text h3 font-weight-normal">{{ statistic.indicators.published }}</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="3">
              <div class="text h3">Читабельность</div>
              <div class="text h3 font-weight-normal">{{ statistic.indicators.readability }}</div>
            </v-col>
            <v-col sm="3">
              <div class="text h3">Сложность восприятия</div>
              <div class="text h3 font-weight-normal">{{ statistic.indicators.complexity }}</div>
            </v-col>
            <v-col sm="3">
              <div class="text h3">Уровень взаимодействия</div>
              <div class="text h3 font-weight-normal">{{ statistic.indicators.level }}</div>
            </v-col>
            <v-col sm="3">
              <div class="text h3">Индекс качества оформления</div>
              <div class="text h3 font-weight-normal">{{ statistic.indicators.quality_index }}</div>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
    <v-card class="mt-2" v-if="statistic.users">
      <v-card-title>
        <v-row>
          <v-col>
            <h4>Пользователи</h4>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="userHeaders"
          :items="statistic.users"
          :items-per-page="5"
        />
      </v-card-text>
    </v-card>
    <v-card class="mt-2" v-if="statistic.referral && isMounted">
      <v-card-title>
        Распределение по каналам трафика
      </v-card-title>
      <v-card-text>
        <apexchart type="bar" height="400" :series="statistic.referral.series" :options="chartOptions"/>
      </v-card-text>
    </v-card>
    <v-card class="mt-2" v-if="statistic.regions">
      <v-card-title>
        <v-row>
          <v-col>
            <h4>Регионы</h4>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="regionsHeaders"
          :items="statistic.regions"
          :items-per-page="5"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "Events",
  data: () => ({
    dateDialog: false,
    date: [],
    userHeaders: [
      { text: 'Страница', value: 'URL' },
      { text: 'Название страницы', value: 'Title' },
      { text: 'Пользователи', value: 'Users' },
      { text: 'Просмотры страниц', value: 'PageViews' },
      { text: 'Я пойду', value: 'goal_poydu' },
      { text: 'Добавлен в календарь', value: 'goal_calendar' },
    ],
    regionsHeaders: [
      { text: 'Регион', value: 'RegionCity' },
      { text: 'Пользователи', value: 'Users' },
      { text: 'Просмотры страниц', value: 'PageViews' },
    ],
    isMounted: false,
    selectedEvents: [],
  }),
  async created() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() < 9 ? `0${currentDate.getMonth() + 1}` : currentDate.getMonth() + 1;
    const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)

    this.date = [
      `${lastDay.getFullYear()}-${currentMonth}-01`,
      `${lastDay.getFullYear()}-${currentMonth}-${lastDay.getDate()}`
    ];

    await this.fetch(this.payload)
  },
  mounted() {
    this.isMounted = true;
  },
  computed: {
    ...mapGetters({
      'statistic': 'Statistics/getEventStatistic',
    }),
    payload() {
      return {
        interval: {
          from: this.date[0],
          to: this.date[1],
        },
        list: this.selectedAllEvents ? '' : this.selectedEvents,
      }
    },
    chartOptions() {
      return {
        chart: {
          type: 'bar',
          height: 400
        },
        xaxis: {
          categories: this.statistic.referral.xaxis.categories,
        },
        yaxis: [
          {
            title: {
              text: 'Пользователи'
            }
          },
          {
            opposite: true,
            title: {
              text: 'Просмотры страниц'
            }
          }
        ],
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top',
            }
          }
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"],
          }
        },
      }
    },
    eventsList() {
      if (this.statistic.eventsList) {
        return this.statistic.eventsList;
      }

      return [];
    },
    selectedAllEvents() {
      return this.eventsList.length === this.selectedEvents.length;
    },
    likesSomeEvents() {
      return this.selectedEvents.length > 0 && !this.selectedAllEvents;
    },
    autoCompleteIcon() {
      if (this.selectedAllEvents) return 'mdi-close-box'
      if (this.likesSomeEvents) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline';
    }
  },
  methods: {
    ...mapActions({
      'fetch': 'Statistics/fetchEventStatistic',
    }),
    selectedEventsToggle() {
      this.$nextTick(() => {
        if (this.selectedAllEvents) {
          this.selectedEvents = [];
        } else {
          this.selectedEvents = this.eventsList.map((item) => item.slug);
        }
      })
    },
    formatDate(date) {
      const currentMonth = date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
      const currentDay = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
      return `${date.getFullYear()}-${currentMonth}-${currentDay}`
    }
  },
  watch: {
    date: {
      async handler() {
        if (this.date.length === 2) {
          const firstDate = new Date(this.date[0]);
          const secondDate = new Date(this.date[1]);

          if (firstDate > secondDate) {
            this.date[0] = this.formatDate(secondDate);
            this.date[1] = this.formatDate(firstDate);
          }

          await this.fetch(this.payload)
        }
      }
    },
    selectedEvents: {
      async handler() {
        await this.fetch(this.payload);
      }
    }
  }
}
</script>

<style scoped>

</style>
