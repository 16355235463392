<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row justify="space-between">
          <v-col cols="12" lg="4">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Поиск..."
              filled
              background-color="transparent"
              hide-details
              name="title"
            >
              <v-btn v-if="search" class="reset-button"
                     slot="append"
                     icon
                     @click="search = null"
                     color="red"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="4" class="justify-end align-center d-flex">
            <v-dialog v-model="dialog" persistent max-width="700px">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" @click="$router.push({name: 'permissions-create'})">
                  Создать права
                </v-btn>
              </template>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="items"
              :options.sync="options"
              :server-items-length="total"
            >
              <template v-slot:item.id="{ item }">
                <v-chip pill>{{ item.id }}</v-chip>
              </template>
              <template v-slot:item.name="{item}">
                <v-card-title>
                  {{item.name}}
                </v-card-title>
                <v-card-subtitle>
                  {{item.description}}
                </v-card-subtitle>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  :href="editItem(item)"
                  icon
                >
                  <v-icon color="primary" small>
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-icon
                  color="red"
                  @click="deleteItem(item)"
                  small
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {Permission} from "../../../models/permission";
import axiosInstance from "../../../axiosInstance";

export default {
  name: "Index",
  data: () => ({
    search: '',
    options: {
      sortBy: ['id'],
      sortDesc: [true],
    },
    total: 0,
    headers: [
      {text: 'Id', value: 'id'},
      {text: 'Название', value: 'name'},
      {text: 'Действия', value: 'actions'}
    ],
    items: [],
    page: {
      title: "Права"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Права",
        disabled: true,
        to: '/permissions'
      },
    ]
  }),
  created() {
    this.get();
  },
  methods: {
    async get() {
      // eslint-disable-next-line no-unused-vars
      const {sortBy, sortDesc, page, itemsPerPage} = this.options;
      let data = {};
      this.search ? data.search = {value: this.search} : null;
      sortBy && sortBy[0] ? data.sort = [{field: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc'}] : null;

      await axiosInstance.post(`/permissions/search?limit=${itemsPerPage}&page=${page}`, data).then((response) => {
        this.items = response.data.data;
        this.total = response.data.meta.total;
      });
    },
    async deleteItem(item) {
      await Permission.$query().destroy(item.id);
      await this.get();
    },
    editItem(item) {
      return this.$router.resolve({name: 'permissions-edit', params: {id: item.id}}).href
    }
  },
  watch: {
    search: {
      handler() {
        this.get();
      }
    },
    options: {
      handler() {
        this.get()
      },
      deep: true,
    },
  },
}
</script>

<style scoped>

</style>
