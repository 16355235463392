import { Model } from "@tailflow/laravel-orion/lib/model";
import {BelongsToMany} from "@tailflow/laravel-orion/lib/drivers/default/relations/belongsToMany";
import {Role} from "@/models/role";

export class Page extends Model<{
    parent_id: number,
    slug: string,
    description: string,
    language: string,
    blocks: object[],
    layout: string,
    layouts: string[],
    roles: object[],
    created_at: string,
    updated_at: string
}> {

    $resource(): string {
        return "pages";
    }

    public roles(): BelongsToMany<Role> {
        return new BelongsToMany<Role>(Role, this);
    }
}
