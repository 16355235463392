<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="items"
              :options.sync="options"
              :server-items-length="total"
              class="contact-listing-app"
              >
              <template v-slot:top>
                <v-toolbar flat class="mb-8">
                  <v-row>
                    <v-col cols="12" lg="6"/>
                    <v-col/>
                  </v-row>
                  <v-spacer/>
                  <v-btn color="primary" to="/sites/create" dark class="mb-2 text-capitalize">
                    <v-icon class="mr-2">mdi-web-plus</v-icon>
                    Добавить сайт
                  </v-btn>
                </v-toolbar>
              </template>
              <template v-slot:item.id="{ item }">
                <v-chip>{{ item.id }}</v-chip>
              </template>
              <template v-slot:item.is_default="{ item }">
                <v-chip :color="item.is_default == 1 ? 'green' : 'red'">
                  <v-icon v-if="item.is_default == 1" color="white">
                    mdi-check
                  </v-icon>
                  <v-icon v-else color="white">
                    mdi-close
                  </v-icon>
                </v-chip>
              </template>
              <template v-slot:item.action="{ item }">
                <v-btn
                  :href="editItem(item)"
                  icon
                >
                  <v-icon color="primary" small>
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-icon
                  v-if="!item.is_default"
                  color="red"
                  small
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Index",
  data: () => ({
    options: {
      sortBy: ['is_default'],
      sortDesc: [true],
      page: 1,
      itemsPerPage: 15,
    },
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'Название', value: 'title'},
      { text: 'Домен', value: 'domain' },
      { text: 'По умолчанию', value: 'is_default' },
      { text: '', value: 'action' },
    ],
    page: {
      title: "Сайты"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Сайты",
        disabled: true,
        to: '/sites'
      },
    ]
  }),
  created() {
    this.get();
  },
  computed: {
    ...mapGetters({
      items: "Sites/getFetchedListing",
      total: "Sites/getListingTotalItems",
      loading: "Sites/isListingLoad",
    })
  },
  methods: {
    ...mapActions({
      fetchListing: "Sites/fetchListing",
      destroyItem: "Sites/destroyItem",
    }),
    async get() {
      const payload = {
        options: this.options,
        search: this.search,
      }

      this.fetchListing(payload);
    },
    editItem(item) {
      return this.$router.resolve({ name: 'site-edit', params: { id: item.id }}).href
    },
    deleteItem(item) {
      this.destroyItem(item.id).then(() => this.get());
    },
  },
  watch: {
    options: {
      handler() {
        this.get();
      }
    }
  }
}
</script>

<style scoped>

</style>
