<template>
  <v-form>
    <v-text-field
      v-model="form.title"
      :error-messages="errors.title"
      label="Заголовок"
      name="title"
      required
    ></v-text-field>
    <v-btn
      color="primary"
      @click="submit"
    >
      Сохранить
    </v-btn>
  </v-form>
</template>

<script>
export default {
  name: 'BlockGroupForm',
  props: {
    group: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    form: {
      title: null,
    },
  }),
  methods: {
    submit() {
      this.$emit('onSubmit', this.form);
    },
  },
  watch: {
    group: {
      handler() {
        this.form = JSON.parse(JSON.stringify(this.group));
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>

</style>
