<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row justify="space-between">
          <v-col cols="12" lg="4">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Поиск..."
              filled
              background-color="transparent"
              hide-details
              name="title"
            >
              <v-btn v-if="search" class="reset-button"
                     slot="append"
                     icon
                     @click="search = null"
                     color="red"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="4" class="justify-end align-center d-flex">
            <v-dialog v-model="dialog" persistent max-width="700px">
              <template v-slot:activator="{ on }">
              </template>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="items"
              :loading="loading"
              :options.sync="options"
              :server-items-length="total"
              :search="search"
            >
              <template v-slot:item.image="{ item }">
                <v-img
                  class="mb-1 mt-1"
                  contain
                  :width="150"
                  :src="item.image"
                ></v-img>
              </template>
              <template v-slot:item.pages="{ item }">
                <div v-for="(page, key) in item.pages" :key="key">
                  <p v-if="page.deleted === false"><a :href="page.link+'?edit_mode=true'" target="_blank">{{ page.name }}</a></p>
                  <p v-else> <s> {{ page.name }} (удалено)</s> </p>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axiosInstance from "../../../axiosInstance";

export default {
  name: 'Index',
  data: () => ({
    search: '',
    loading: true,
    total: 0,
    maxItems: 0,
    options: {
      sortBy: ['created_at'],
      sortDesc: [true],
    },
    filters: {
      type: null,
      status: null,
    },
    headers: [
      {text: 'ID блока', value: 'block_id'},
      {text: 'Изображение', value: 'image'},
      {text: 'Название блока', value: 'name'},
      {text: 'Тех. название блока', value: 'component_name'},
      {text: 'Страницы', value: 'pages'},
      {text: 'Количество применений', value: 'usage_quantity'},
      {text: 'Дата создания блока', value: 'block_created_at'},
      {text: 'Дата обновления блока', value: 'block_updated_at'},
      {text: 'Дата обновления записи', value: 'updated_at'},
    ],
    items: [],
    page: {
      title: "Статистика используемых блоков"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Блоки",
        disabled: true,
        to: '/blocks'
      },
      {
        text: "Статистика используемых блоков",
        disabled: true,
        to: '/blocks/blocks-statistic'
      },
    ],
  }),
  created() {
    this.get();
  },
  methods: {

    async get() {
      if(this.search)
        this.options.page = 1;

      this.loading = true;
      const {sortBy, sortDesc, page} = this.options;
      let itemsPerPage = this.options.itemsPerPage > 0 ? this.options.itemsPerPage : this.maxItems;
      const {type, status} = this.filters;
      let data = {filters: []};
      this.search ? data.search = {value: this.search} : null;
      sortBy && sortBy[0] ? data.sort = [{field: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc'}] : null;

      type !== null ? data.filters.push({field: 'type', operator: '=', value: type}) : null;
      status !== null ? data.filters.push({field: 'status', operator: '=', value: status}) : null;

      await axiosInstance.post(`/block-statistic/search?limit=${itemsPerPage}&page=${page}`, data).then((response) => {
        this.items = response.data.data;
        this.total = response.data.meta.total;
      })
      this.loading = false;
    },
  },
  watch: {
    options: {
      handler() {
        this.get()
      },
      deep: true,
    },
    search: {
      handler() {
        this.get();
      }
    },
    filters: {
      handler() {
        this.get();
      },
      deep: true,
    },
    total: {
      handler(value) {
        this.maxItems = value > this.maxItems ? value : this.maxItems;
      },
      deep: true,
    }
  }
};
</script>
