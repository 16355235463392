<template>
  <v-container>
    <v-form>
      <div>
        <v-text-field
          v-model="form.title"
          label="Название"
        />
        <v-text-field
          v-model="form.site_alias"
          label="Алиас сайта"
        />
        <v-text-field
          v-model="form.admin_email"
          lablel="Логин админа"
        />
        <v-text-field
          v-model="form.admin_password"
          label="Пароль админа"
          />
        <media-widget label="Логотип" :medias="form.logo_image" :is-multiple="false" @onChange="setImage"/>
      </div>
      <div v-if="Object.keys(customer).length > 0">
        <v-text-field
          v-model="form.front_url"
          label="URL Фронта"
        />
        <v-text-field
          v-model="form.admin_url"
          label="URL Админ панели"
        />
        <v-text-field
          v-model="form.app_url"
          label="URL Бекэнда"
        />
        <v-text-field
          v-model="form.port"
          label="Порт"
          :min="10000"
        />
        <v-textarea
          v-model="form.frontend_env"
          :disabled="editable.frontend_env"
          label="Файл конфигурации фронтенда"
        />
        <v-btn
          color="primary"
          block class="mb-4"
          @click="editable.frontend_env = !editable.frontend_env"
        >
          {{ editable.frontend_env ? 'Начать редактировать' : 'Прекратить редактировать' }} файл конфигурации фронтенда
        </v-btn>
        <v-textarea
          v-model="form.backend_env"
          :disabled="editable.backend_env"
          label="Файл конфигурации бекэнда"
        />
        <v-btn
          color="primary"
          block class="mb-4"
          @click="editable.backend_env = !editable.backend_env"
        >
          {{ editable.backend_env ? 'Начать редактировать' : 'Прекратить редактировать' }} файл конфигурации бекэнда
        </v-btn>
        <v-textarea
          v-model="form.nginx_config"
          :disabled="editable.nginx_config"
          label="Файл конфигурации nginx'a"
        />
        <v-btn
          color="primary"
          block
          class="mb-4"
          @click="editable.nginx_config = !editable.nginx_config"
        >
          {{ editable.nginx_config ? 'Начать редактировать' : 'Прекратить редактировать' }} файл конфигурации nginx'a
        </v-btn>
        <v-text-field
          v-model="form.matomo_id_site"
          label="ID сайта в matomo"
        />
        <v-text-field
          v-model="form.oauth_token"
          label="OAuthToken"
        />
        <v-text-field
          v-model="form.yandex_site_id"
          label="Id сайта в yandex webmaster"
        />
      </div>
    </v-form>
    <v-btn color="primary" @click="submit">
      Сохранить
    </v-btn>
    <media-uploader/>
  </v-container>
</template>

<script>
import MediaWidget from "../MediaWidget";
import MediaUploader from "../MediaUploader";
import {mapActions} from "vuex";
export default {
  name: "CustomerForm",
  components: {MediaUploader, MediaWidget},
  props: {
    customer: {
      type: Object,
      required: false,
      default: () => ({}),
    }
  },
  data: () => ({
    valid: true,
    form: {
      title: null,
      logo_image: null,
      site_alias: null,
      front_url: null,
      admin_url: null,
      app_url: null,
      frontend_env: null,
      backend_env: null,
      nginx_config: null,
      admin_email: 'admin@admin.com',
      admin_password: 'password',
      oauth_token: null,
      yandex_site_id: null,
      matomo_id_site: null,
    },
    editable: {
      frontend_env: true,
      backend_env: true,
      nginx_config: true,
    },
    rules: {
      titleRules: [
        v => !!v || 'Поле \'Название\' обязательно для заполнения',
        v => (v && v.length > 3) || 'Поле \'Название\' должно иметь хотябы 3 символа',
      ]
    }
  }),
  methods: {
    ...mapActions({
      validationFailed: 'snackbar/showValidationError'
    }),
    setImage(image) {
      this.form.logo_image = image;
    },
    submit() {
      this.$emit('onSubmit', this.form);
    }
  },
  watch: {
    customer: {
      handler(val) {
        if (Object.keys(val).length > 0) {
          this.form = JSON.parse(JSON.stringify(val));
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
