import {Model} from "@tailflow/laravel-orion/lib/model";

export class ReviewsType extends Model<{
    title: string,
}> {

    $resource(): string {
        return "module/review-types";
    }
}
