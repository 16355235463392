<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="items"
              :options.sync="options"
              :loading="loading"
              :server-items-length="total"
              :search="search"
            >
              <template v-slot:item.id="{ item }">
                <v-chip pill>{{ item.id }}</v-chip>
              </template>
              <template v-slot:item.product_name="{ item }">
                <div v-for="product in item.products" :key="product.id">
                  <span v-if="product.entity.title">
                    {{ product.entity.title }}
                  </span>
                </div>
              </template>
              <template v-slot:item.status="{ item }">
                <v-chip :color="getStatusColor(item.status)" :content="item.status_title" label>{{ item.status_title }}</v-chip>
              </template>
              <template v-slot:item.payment_system="{ item }">
                <v-chip>{{ paymentSystem(item.payment_system) }}</v-chip>
              </template>
              <template v-slot:item.mirror_site="{ item }">
                <template v-if="item.mirror_site">
                  Евро сайт
                </template>
                <template v-else>
                  Российский сайт
                </template>
              </template>
              <template v-slot:item.type="{ item }">
                <v-chip>{{ types[item.type] }}</v-chip>
              </template>
              <template v-slot:item.promocode="{ item }">
                <a :href="`/promocodes/edit/${item.promocode_id}`" target="_blank">{{ item.promocode }}</a>
              </template>
              <template v-slot:item.actions="{ item }">
                <a :href="`/orders/${item.id}`">
                  <v-icon color="primary">
                    mdi-eye
                  </v-icon>
                </a>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axiosInstance from '../../../axiosInstance';

export default {
  name: 'Index',
  data: () => ({
    loading: true,
    total: 0,
    options: {
      sortBy: ['id'],
      sortDesc: [true],
    },
    search: '',
    only_trashed: 0,
    headers: [
      {text: 'Id', value: 'id'},
      {text: 'Пользователь', value: 'user.full_name', sortable: false},
      {text: 'Email пользователя', value: 'user.email', sortable: false},
      { text: 'Промокод', value: 'promocode' },
      {text: 'Сумма', value: 'amount'},
      {text: 'Статус', value: 'status'},
      {text: 'Тип', value: 'type'},
      {text: 'Наименование продукта', value: 'product_name'},
      {text: 'Дата оплаты', value: 'paid_at'},
      {text: 'Платёжная система', value: 'payment_system' },
      {text: 'Сайт оплаты', value: 'mirror_site' },
      {text: 'Дата создания', value: 'created_at'},
      {text: 'Дата обновления', value: 'updated_at'},
      {text: '', value: 'actions' },
    ],
    items: [],
    types: {
      0 : 'Покупка',
      1 : 'Аренда',
      3 : 'Абонемент',
      4 : 'Билет',
    },
    apiUrl: process.env.VUE_APP_API_URL,
    page: {
      title: "Заказы"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Ecommerce",
        disabled: true,
        to: '/'
      },
      {
        text: "Заказы",
        disabled: true,
        to: '/statisticsV2/media'
      },
    ],
  }),
  created() {
    this.get();
  },
  methods: {
    async get() {
      this.loading = true;
      const {sortBy, sortDesc, page, itemsPerPage} = this.options;

      const data = {};
      this.search ? data.search = {value: this.search} : null;
      sortBy && sortBy[0] ? data.sort = [{field: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc'}] : null;

      const onlyTrashed = this.only_trashed ? `&only_trashed=1` : '';

      await axiosInstance.post(`/orders/search?limit=${itemsPerPage}&page=${page}${onlyTrashed}`, data)
        .then((response) => {
          this.items = response.data.data;
          this.total = response.data.meta.total;
        });

      this.loading = false;
    },
    getStatusColor(status) {
      switch (status) {
        case 0:
          return 'red';
        case 1:
          return 'green';
        default:
          return 'grey';
      }
    },
    paymentSystem(id) {
      switch (id) {
        case 1:
          return 'platron';
        case 2:
          return 'stripe';
        default:
          return 'Данные отсутсвуют';
      }
    }
  },
  watch: {
    search: {
      handler() {
        this.get();
      }
    },
    query: {
      handler() {
        this.get();
      },
      deep: true,
      immediate: true,
    },
    options: {
      handler() {
        this.get()
      },
      deep: true,
    },
  }
};
</script>
