import axiosInstance from "../../axiosInstance";
import {SystemPhrase} from '../../models/systemPhrase';

export default {
  namespaced: true,
  state: {
    isLoading: false,
    collectionItems: [],
    products: [],
    productsOptions: {},
    procedures: [],
    bestPractices: [],
    partners: [],
    partnersOptions: {},
    bestPracticesOptions: {},
    proceduresOptions: {},
    systemPhrases: [],
  },
  getters: {
    isLoading: (state) => state.isLoading,
    collectionItems: (state) => state.collectionItems,
    products: (state) => state.products,
    productsOptions: (state) => state.productsOptions,
    procedures: (state) => state.procedures,
    proceduresOptions: (state) => state.proceduresOptions,
    partners: (state) => state.partners,
    partnersOptions: (state) => state.partnersOptions,
    bestPractices: (state) => state.bestPractices,
    bestPracticesOptions: (state) => state.bestPracticesOptions,
    systemPhrases: (state) => state.systemPhrases,
  },
  mutations: {
    setIsLoading(state, val) {
      state.isLoading = val;
    },
    setCollectionItems(state, val) {
      state.collectionItems = val;
    },
    setProducts(state, val) {
      state.products = val;
    },
    setProductsOptions(state, val) {
      state.productsOptions = val;
    },
    setProcedures(state, val) {
      state.procedures = val;
    },
    setProceduresOptions(state, val) {
      state.proceduresOptions = val;
    },
    setPartners(state, val) {
      state.partners = val;
    },
    setPartnersOptions(state, val) {
      state.partnersOptions = val;
    },
    setBestPractices(state, val) {
      state.bestPractices = val;
    },
    setBestPracticesOptions(state, val) {
      state.bestPracticesOptions = val;
    },
    setSystemPhrases(state, val){
      state.systemPhrases = val;
    },
  },
  actions: {
    async fetchCollectionItems({commit}, id) {
      commit('setIsLoading', true);

      await axiosInstance.get(`follred/collection-items/${id}`).then((response) => {
        commit('setCollectionItems', response.data);
      })

      commit('setIsLoading', false);
    },
    async fetchSystemPhrases({ commit }) {
      commit('setIsLoading', true);

      SystemPhrase.$query().get(1000000).then((resp) => {
        commit('setSystemPhrases', resp.map(item => item.$attributes));
      }).finally(() => {
        commit('setIsLoading', false);
      });
    },
    async fetchProducts({ commit }) {
      commit('setIsLoading', true);

      await axiosInstance.get(`follred/products`).then((response) => {
        commit('setProductsOptions', response.data.column_data);
        commit('setProducts', response.data.data);
      });

      commit('setIsLoading', false);
    },
    async fetchProcedures({ commit }) {
      commit('setIsLoading', true);

      await axiosInstance.get(`follred/procedures`).then((response) => {
        commit('setProceduresOptions', response.data.column_data);
        commit('setProcedures', response.data.data);
      });

      commit('setIsLoading', false);
    },
    async fetchPartners({ commit }) {
      commit('setIsLoading', true);

      await axiosInstance.get(`follred/partners`).then((response) => {
        commit('setPartnersOptions', response.data.column_data);
        commit('setPartners', response.data.data);
      })

      commit('setIsLoading', false)
    },
    async fetchBestPractices({ commit }) {
      commit('setIsLoading', true);

      await axiosInstance.get(`follred/bestPractices`).then((response) => {
        commit('setBestPracticesOptions', response.data.column_data);
        commit('setBestPractices', response.data.data);
      });

      commit('setIsLoading', false);
    },
    async saveCollectionItems({ commit }, { toUpdate, taxonomyId, toDelete }) {
      commit('setIsLoading', true);

      if (toUpdate.length > 0) {
        await axiosInstance.post(`follred/collection-items/update/${taxonomyId}`, {
          items: toUpdate,
        })
      }

      if (toDelete.length > 0) {
        await axiosInstance.post('follred/collection-items', {
          items: toDelete,
        });
      }

      commit('setIsLoading', false);
    },
    async saveProducts({ commit }, { toDelete, toUpdate }) {
      commit('setIsLoading', true);

      if (toUpdate.length) {
        await axiosInstance.post(`follred/products/update`, {
          items: toUpdate,
        });
      }

      if (toDelete.length) {
        await axiosInstance.post(`follred/products`, {
          items: toDelete,
        });
      }

      commit('setIsLoading', false);
    },
    async saveProcedures({ commit }, { toDelete, toUpdate }) {
      commit('setIsLoading', true);

      if (toUpdate.length) {
        await axiosInstance.post(`follred/procedures/update`, {
          items: toUpdate,
        });
      }

      if (toDelete.length) {
        await axiosInstance.post(`follred/procedures`, {
          items: toDelete,
        });
      }

      commit('setIsLoading', false);
    },
    async savePartners({ commit }, { toDelete, toUpdate }) {
      commit('setIsLoading', true)

      if (toUpdate.length) {
        await axiosInstance.post(`follred/partners/update`, {
          items: toUpdate,
        });
      }

      if (toDelete.length) {
        await axiosInstance.post(`follred/partners`, {
          items: toDelete,
        });
      }

      commit('setIsLoading', false)
    },
    async saveBestPractices({ commit }, { toDelete, toUpdate }) {
      commit('setIsLoading', true);

      if (toUpdate.length) {
        await axiosInstance.post('follred/bestPractices/update', {
          items: toUpdate,
        });
      }

      if (toDelete.length) {
        await axiosInstance.post('follred/bestPractices', {
          items: toDelete,
        })
      }

      commit('setIsLoading', false);
    },
    async saveSystemPhrases({ commit }, { toDelete, toUpdate }) {
      commit('setIsLoading', true);

      if (toUpdate.length) {
        await axiosInstance.post('system-phrase/update-or-create', {
          systemPhrases: toUpdate,
        });
      }

      if (toDelete.length) {
        await axiosInstance.delete('system-phrase/batch',{
          data: {
            resources: toDelete,
          }
        });
      }

      commit('setIsLoading', false);
    },
  }
}
