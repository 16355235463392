<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row>
      <v-col>
        <h1>
          Профиль
          <v-btn
            fab
            dark
            small
            color="primary"
            @click="$router.push({name: 'profile-edit'})"
          >
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-list>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="indigo">
                mdi-account-box
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ user.full_name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider inset></v-divider>

          <v-list-item>
            <v-list-item-icon>
              <v-icon color="indigo">
                mdi-email
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ user.email }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Index',
  data: () => ({
    page: {
      title: "Профиль"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Профиль",
        disabled: true,
        to: '/profile'
      },
    ]
  }),
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
    }),
  },
};
</script>
