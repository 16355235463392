export default {
  namespaced: true,
  state: {
    dynamicFields: [],
  },
  getters: {
    getDynamicFields: (state) => state.dynamicFields,
  },
  mutations: {
    setDynamicFields(state, val) {
      state.dynamicFields = val;
    }
  },
  actions: {

  }
}
