<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <suggest-form :errors="errors" @onSubmit="submit"/>
          </v-col>
        </v-row>
        <v-snackbar
          v-model="snackbarSuccess"
          :timeout="timeout"
          color="success"
        >
          Сохранено

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarSuccess = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar
          v-model="snackbarError"
          :timeout="timeout"
          color="error"
        >
          {{ this.errors }}

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarError = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import SuggestForm from '../../../components/suggests/SuggestForm';
import {Suggest} from '../../../models/suggest';

export default {
  name: 'Create',
  components: {SuggestForm},
  data: () => ({
    errors: {},
    snackbarSuccess: false,
    snackbarError: false,
    timeout: 2000,
    page: {
      title: "Создание саджеста"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Саджесты",
        disabled: true,
        to: '/suggests'
      },
      {
        text: "Создание",
        disabled: true,
        to: '/suggests/create'
      },
    ]
  }),
  methods: {
    async submit(form) {
      try {
        this.errors = {};
        await Suggest.$query().store(form);
        this.snackbarSuccess = true;
        await this.$router.push('/suggests');
      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors
        }
        this.snackbarError = true;
        throw e;
      }
    },
  },
};
</script>
