import { Model } from "@tailflow/laravel-orion/lib/model";

export class Cron extends Model<{
    command: string,
    cron: string,
}> {

    $resource(): string {
        return "cron";
    }
}
