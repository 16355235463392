var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"down-top-padding",attrs:{"fluid":""}},[_c('BaseBreadcrumb',{attrs:{"title":_vm.page.title,"icon":_vm.page.icon,"breadcrumbs":_vm.breadcrumbs}}),_c('v-row',[_c('v-col',[_c('h1',[_vm._v("Партнеры")])])],1),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"primary","to":"/WebmasterPartners/create"}},[_vm._v(" Добавить партнера ")])],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"loading":_vm.loading,"server-items-length":_vm.listingTotal,"search":_vm.search},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.fullUrlHref",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_vm._v(_vm._s(item.url))])]}},{key:"item.shortUrlHref",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.shortUrl,"target":"_blank"}},[_vm._v(_vm._s(item.shortUrl))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.widget",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{on:{"click":function($event){return _vm.setPartner(item.code)}}},[_vm._v("Показать")])]}},{key:"item.post_widget",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{on:{"click":function($event){return _vm.setPartnerPost(item.code)}}},[_vm._v("Показать")])]}}])})],1)],1),_c('partner-widget',{attrs:{"partner":_vm.getPartner},model:{value:(_vm.widgetPopup),callback:function ($$v) {_vm.widgetPopup=$$v},expression:"widgetPopup"}}),_c('partner-post-widget',{attrs:{"partner":_vm.getPartner},model:{value:(_vm.widgetPostPopup),callback:function ($$v) {_vm.widgetPostPopup=$$v},expression:"widgetPostPopup"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }