<template>
  <div>
    <h1>Редактирование {{ field.title }}</h1>
    <div>
      <FieldForm :field="field" @onSubmit="onSubmit"/>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import FieldForm from "../../../components/fields/FieldForm";

export default {
  name: "Edit",
  components: {FieldForm},
  computed: {
    ...mapGetters({
      field: "Fields/getFetchedDetail",
    })
  },
  created() {
    this.fetchDetail(this.$route.params.id);
  },
  methods: {
    ...mapActions({
      fetchDetail: "Fields/fetchDetail",
      updateItem: "Fields/updateItem"
    }),
    onSubmit(form) {
      this.updateItem(form);
    }
  }
}
</script>

<style scoped>

</style>
