<template>
  <v-container fluid class="down-top-padding">
    <v-card>
      <vxe-toolbar>
        <template #buttons>
          <vxe-input v-model="search" type="search" placeholder="Поиск" @keyup="onSearchInput"></vxe-input>

          <vxe-button content="Добавить" class="ml-3" status="primary" @click="insertEvent" />
          <vxe-button content="Удалить" status="danger" @click="removeEvent" />
          <vxe-button content="Сохранить" status="success" @click="saveEvent" />
        </template>
      </vxe-toolbar>
      <div style="height:calc(100vh - 260px)">
        <vxe-table
          border
          stripe
          resizable
          highlight-hover-row
          keep-source

          show-footer
          max-height="100%"
          ref="xTable"
          :data="data"
          :edit-config="{trigger: 'click', mode: 'cell', showStatus: true}"
          :column-config="{resizable: true, minWidth: 350}"
          :tooltip-config="tableToolbar"
          :menu-config="menuConfig"
          @header-cell-click="headerCellClickEvent"
          @menu-click="contextMenuClickEvent"
        >
          <vxe-column type="checkbox" width="60"  />
          <vxe-column field="id" title="id" width="60"  />
          <vxe-column field="title" title="Заголовок (f1-1)" :edit-render="{}" sortable  fixed="left">
            <template #edit="{ row }">
              <vxe-input v-model="row.title" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="slug" title="slug (f1-2)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.slug" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="price" width="100" title="Цена (f1-3)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.price" type="number"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="subtitle" title="Подзаголовок (f1-4)"  :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.subtitle" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="person_id" title="Персона (f1-5)" :edit-render="{}" sortable>
            <template #default="{ row }">
              <span>{{ formatPersonsValue(row.person_id) }}</span>
            </template>
            <template #edit="scope">
              <vxe-select v-model="scope.row.person_id" transfer>
                <vxe-option v-for="item in persons" :key="item.key" :value="item.key"
                            :label="item.value"/>
              </vxe-select>
            </template>
          </vxe-column>
          <vxe-column field="video_time" title="Время видео (f1-6)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.video_time" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="teaser_url" title="Ссылка на тизер (f1-7)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.teaser_url" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="description" title="Описание (f1-8)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.description" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="subline_description" title="Подстрочник под описание (f1-9)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.subline_description" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="text_after_purchase" title="Текст после покупки (f1-10)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.text_after_purchase" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="subline_description_course" title="Подстрочник описания курса (f1-11)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.subline_description_course" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="introductory_text" title="Вводный текст (f1-12)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.introductory_text" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="subline_introductory_text" title="Подстрочник вводного текста (f1-13)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.subline_introductory_text" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="age_from" title="Возраст от (f1-14)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.age_from" type="number"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="age_to" title="Возраст до (f1-15)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.age_to" type="number"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="is_free" title="Является бесплатным (f1-16)" :edit-render="{}" sortable>
            <template #edit="{ row }">

              <vxe-input v-model="row.is_free" type="number"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="preview_image" title="Изображение (f1-17)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.preview_image" type="number"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="preview_image_video" title="Изображение видео (f1-18)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.preview_image_video" type="number"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="preview_image_head" title="Изображение в шапке (f1-19)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.preview_image_head" type="number"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="pass_course_1" title="Курс стоит пройти 1 (текст) (f1-20)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.pass_course_1" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="pass_course_image_1" title="Курс стоит пройти 1 (изображение) (f1-21)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.pass_course_image_1" type="number"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="pass_course_2" title="Курс стоит пройти 2 (текст) (f1-22)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.pass_course_2" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="pass_course_image_2" title="Курс стоит пройти 2 (изображение) (f1-23)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.pass_course_image_2" type="number"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="pass_course_3" title="Курс стоит пройти 3 (текст) (f1-24)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.pass_course_3" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="pass_course_image_3" title="Курс стоит пройти 3 (изображение) (f1-25)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.pass_course_image_3" type="number"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="pass_course_4" title="Курс стоит пройти 4 (текст) (f1-26)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.pass_course_4" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="pass_course_image_4" title="Курс стоит пройти 4 (изображение)(f1-27)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.pass_course_image_4" type="number"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="accent_text_1" title="Акценты курса 1 (текст)(f1-28)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.accent_text_1" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="accent_image_1" title="Акценты курса 1 (изображение)(f1-29)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.accent_image_1" type="number"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="accent_text_2" title="Акценты курса 2 (текст)(f1-30)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.accent_text_2" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="accent_image_2" title="Акценты курса 2 (изображение)(f1-31)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.accent_image_2" type="number"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="accent_text_3" title="Акценты курса 3 (текст)(f1-32)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.accent_text_3" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="accent_image_3" title="Акценты курса 3 (изображение)(f1-33)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.accent_image_3" type="number"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="accent_text_4" title="Акценты курса 4 (текст)(f1-34)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.accent_text_4" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="accent_image_4" title="Акценты курса 4 (изображение)(f1-35)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.accent_image_4" type="number"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="excel_collection" title="Коллекции(f1-36)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-select v-model="row.excel_collection" transfer>
                <vxe-option v-for="item in collectionsSelect" :key="item" :value="item" :label="item" />
              </vxe-select>
            </template>
          </vxe-column>
          <vxe-column field="excel_season" title="Сезон(f1-37)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-select v-model="row.excel_season" transfer>
                <vxe-option v-for="item in seasonSelect" :key="item" :value="item" :label="item" />
              </vxe-select>
            </template>
          </vxe-column>
          <vxe-column field="excel_items" title="Предметы(f1-38)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.excel_items" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="excel_class" title="Класс(f1-39)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.excel_class" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="excel_quarter" title="Четверть(f1-40)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.excel_quarter" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="excel_month" title="Месяц(f1-41)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.excel_month" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="excel_science" title="Вид науки(f1-42)" :edit-render="{}" sortable>
            <template #edit="{row}">
              <vxe-select v-model="row.excel_science" transfer>
                <vxe-option v-for="item in selectSphereKnowledge" :key="item" :value="item" :label="item" />
              </vxe-select>
            </template>
          </vxe-column>
          <vxe-column field="excel_knowledge" title="Сфера знаний(f1-43)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.excel_knowledge" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="excel_methodist" title="Лидерство(f1-44)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-select v-model="row.excel_methodist" transfer>
                <vxe-option v-for="(item, key) in selectMethodist" :key="key" :value="item" :label="item" />
              </vxe-select>
            </template>
          </vxe-column>
          <vxe-column field="conspectus_file" title="Файлы конспектов (через запятую)(f1-45)" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.conspectus_file" type="text"></vxe-input>
            </template>
          </vxe-column>
        </vxe-table>
        <vxe-pager
          border
          icon-prev-page="fa fa-angle-left"
          icon-jump-prev="fa fa-angle-double-left"
          icon-jump-next="fa fa-angle-double-right"
          icon-next-page="fa fa-angle-right"
          icon-jump-more="fa fa-ellipsis-h"
          :loading="loading"
          :current-page="paginator.currentPage"
          :page-size="paginator.limit"
          :total="paginator.total"
          :page-sizes="paginator.pageSizes"
          :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']"
          @page-change="changePage">
        </vxe-pager>
      </div>
    </v-card>
    <wysywig-popup
      v-if="isShowWysywig"
      v-model="wysywigPopup"
      :html="data[latestCellContextMenuClick.row][latestCellContextMenuClick.column]"
      @html-input="htmlInput"
    />
  </v-container>
</template>

<script>
import VXETable from "vxe-table";
import axiosInstance from "@/axiosInstance";
import WysywigPopup from "@/fields/table/WysywigPopup";

export default {
  name: "Temp",
  components: { WysywigPopup },
  data: () => ({
    data: [],
    collectionsSelect: [
      "Великие открытия",
      "Великие правители",
      "Тайны и загадки",
      "Искусствоведение",
      "Интеллектуальный серфинг (путешествия)",
      "Живая планета",
      "Школа чтения",
      "Подготовка к ЕГЭ",
      "Сюжеты и мотивы в литературе",
      "Как это работает?",
      "Общество познание",
      "Магия химии"
    ],
    seasonSelect: [
      "Эпизод 1",
      "Эпизод 2",
      "Эпизод 3",
      "Эпизод 4",
      "Эпизод 5",
      "Эпизод 6",
      "Эпизод 7",
      "Эпизод 8",
      "Эпизод 9",
      "Эпизод 10",
      "Эпизод 11",
      "Эпизод 12",
      "Эпизод 13",
      "Эпизод 14",
      "нет",
    ],
    selectSphereKnowledge: [
      "О человеке",
      "Об боществе",
      "Об окружающей среде",
      "О технике",
      "-"
    ],
    selectMethodist: [
      "Методист 1",
      "Методист 2",
      "Методист 3",
    ],
    tableToolbar: {
      buttons: [
        { code: 'myInsert', name: 'Добавить' },
        { code: 'mySave', name: 'Сохранить' },
        { code: 'myDelete', name: 'Удалить' },
      ],
      tools: [
        { code: 'myPrint', name: '自定义打印' }
      ],
      refresh: true,
    },
    paginator: {
      currentPage: 1,
      limit: 150,
      pageSizes: [100, 150, 200],
      total: 0,
      vxePagerLayouts: [
        'PrevPage',
        'NextPage',
        'Sizes',
        'Total',
      ]
    },
    loading: false,
    search: '',
    sort: '',
    menuConfig: {
      body: {
        options: [
          [
            { code: 'wysiwyg', name: 'Wysiwyg', visible: true, disabled: false },
          ]
        ]
      },
    },
    wysywigPopup: false,
    latestCellContextMenuClick: {
      row: null,
      column: null,
    },
    persons: [],
  }),
  computed: {
    isShowWysywig() {
      return (this.latestCellContextMenuClick.row !== null && this.latestCellContextMenuClick.column !== null);
    }
  },
  created() {
    this.fetchPersons();
    this.fetchData();
  },
  methods: {
    insertEvent() {
      this.$refs.xTable.insert();
    },
    async removeEvent() {
      if (this.$refs.xTable.getCheckboxRecords().length > 0) {
        const type = await VXETable.modal.confirm('Удалить выбраные записи?')
        if (type === 'confirm') {
          this.$refs.xTable.removeCheckboxRow();
        }
      }
    },
    async saveEvent() {
      const errMap = await this.$refs.xTable.validate(true).catch(errMap => errMap)
      if (errMap) {
        VXETable.modal.message({ status: 'error', content: 'Заполните все обязательные поля' })
      } else {
        const { insertRecords, removeRecords, updateRecords } = this.$refs.xTable.getRecordset();

        if (insertRecords.length > 0) {
          await axiosInstance.post("module/courses/temp/insert", { items: insertRecords });
        }

        if (removeRecords.length > 0) {
          await axiosInstance.post("module/courses/temp/delete", { items: removeRecords });
        }

        if (updateRecords.length > 0) {
          await axiosInstance.post("module/courses/temp/update", { items: updateRecords });
        }
        await this.fetchData();

        this.refreshColumns();
      }
    },
    refreshColumns() {
      this.$nextTick(() => {
        this.hidenColumns = this.$refs.xTable.getColumns();
      })
    },
    async changePage({ currentPage, pageSize }) {
      this.paginator.currentPage = currentPage;
      this.paginator.limit = pageSize;

      await this.fetchData();

      this.refreshColumns();
    },
    async fetchData() {
      this.loading = true;

      const { data } = await axiosInstance.get(`module/courses/temp/index?page=${this.paginator.currentPage}&per_page=${this.paginator.limit}&search=${this.search}&sort=${this.sort}`);

      this.paginator.currentPage = data.current_page;
      this.paginator.limit = data.per_page;
      this.paginator.total = data.total;
      this.data = data.data;

      this.loading = false;
    },
    onSearchInput() {
      this.fetchData();
    },
    async headerCellClickEvent({ column, triggerResizable, triggerSort, triggerFilter }) {
      if (column.sortable && !(triggerResizable || triggerSort || triggerFilter)) {
        if (column.order === 'desc') {
          await this.$refs.xTable.clearSort()

          this.sort = '';
        } else if (column.order === 'asc') {
          this.sort = `${column.property}|desc`;
          column.order = 'desc';
        } else {
          this.sort = `${column.property}|asc`;
          column.order = 'asc';
        }

        await this.fetchData();
      }
    },
    contextMenuClickEvent({ menu, rowIndex, column }) {
      if (menu.code === "wysiwyg") {
        this.latestCellContextMenuClick.row = rowIndex;
        this.latestCellContextMenuClick.column = column.field;
        this.wysywigPopup = true;
      }
    },
    htmlInput(val) {
      this.data[this.latestCellContextMenuClick.row][this.latestCellContextMenuClick.column] = val;
    },
    visibleMethod({ options, column }) {
      const field = this.dynamicFields.find((item) => item.field_name === column.field);

      options[0][0].disabled = field?.component !== 'textarea-field';

      return true;
    },
    fetchPersons() {
      axiosInstance.get('entity/list', { params: { entity: ['App\\Models\\Person'] } }).then((response) => {
        this.persons = response.data;
      });
    },
    formatPersonsValue(value) {
      if (value) {
        const label = this.persons.find((item) => {
          return item.key === value;
        })

        if (label !== undefined) {
          return label.value;
        }
      }

      return "-";
    }
  }
}
</script>

<style scoped>

</style>
