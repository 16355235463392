import Cookies from 'js-cookie';
import axiosInstance from '../../axiosInstance';

export default {
  namespaced: true,
  state: {
    user: {},
    token: null,
  },
  getters: {
    getToken: state => state.token,
    getUser: state => state.user,
    getPermissions: state => state.user?.permissions,
    getRoles: state => state.user?.roles,
    isAuthenticated: state => !!state.token,
    isAdministrator: state => {
      return state.user?.roles.find(item => item.name == 'Супер админ') !== undefined;
    },
    hasBetaRole: state => {
      return state.user?.roles.find(item => item.id === 32) !== undefined;
    }
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    login(state, token) {
      state.token = token;
    },
    logout(state) {
      state.token = null;
    },
  },
  actions: {
    async logout({ state, commit }) {
      try {
        if (state.token) {
          await axiosInstance.post('/auth/logout');
        }
      }  finally {
        Cookies.remove('token', {
          path: '/',
          domain: process.env.VUE_APP_DOMAIN,
        });
        commit('logout');
      }
    },
    async login({ commit }, form) {
        const { data } = await axiosInstance.post('/login', form)
        Cookies.set('token', `Bearer ${data.token}`, {
          expires: data.expires_in,
          domain: process.env.VUE_APP_DOMAIN
        });
        commit('login', data.token);
        return data;
    },
    async autologin({ commit }, token) {
        const { data } = await axiosInstance.post('/autologin', { token })
        Cookies.set('token', `Bearer ${data.token}`, {
          expires: data.expires_in,
          domain: process.env.VUE_APP_DOMAIN
        });
        commit('login', data);
        return data;
    },
    async getUser({ state, commit }) {
      try {
        if (state.token) {
          const { data } = await axiosInstance.get('/auth/user');
          commit('setUser', data);
          return data;
        }
      } catch (e) {
        // eslint-disable-next-line
        console.error(e);
      }
    },
    setToken({ commit }, token) {
      Cookies.set('token', `Bearer ${token}`);
      commit('login', token)
    },
    checkToken(ctx) {
      const token = Cookies.get('token');

      if (token) {
        ctx.commit('login', token.replace('Bearer ', ''));
      }
    }
  },
};
