var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Название","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Название","error-messages":errors},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Название поля","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Название поля в ресурсе"},model:{value:(_vm.form.field_name),callback:function ($$v) {_vm.$set(_vm.form, "field_name", $$v)},expression:"form.field_name"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Тип","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Тип","error-messages":errors,"items":_vm.types,"item-value":"key","item-text":"value"},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Группа","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Группа","error-messages":errors,"items":_vm.groups,"item-value":"key","item-text":"value"},model:{value:(_vm.form.group_id),callback:function ($$v) {_vm.$set(_vm.form, "group_id", $$v)},expression:"form.group_id"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"v-text-field__details"},[_c('div',{staticClass:"v-messages theme--light"},[_c('div',{staticClass:"v-messages__wrapper"},[_c('div',{staticClass:"v-messages__message"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.hint)}})])])])]),_c('VJsonEditor',{attrs:{"plus":true,"options":{ mode: 'code' }},model:{value:(_vm.form.extra_options),callback:function ($$v) {_vm.$set(_vm.form, "extra_options", $$v)},expression:"form.extra_options"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":_vm.submit}},[_vm._v(" Сохранить ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }