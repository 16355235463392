<template>
  <v-container>
    <template>
      <v-toolbar
        flat
      >
        <v-tabs v-model="tab">
          <v-tab>
            Настройки сайта
          </v-tab>
          <v-tab>
            Настройки сущностией
          </v-tab>
        </v-tabs>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              Создать новую настройку
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">Настройки опции</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Ключ"
                      v-model="editOption.option_key"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-textarea label="Описание" v-model="editOption.description"/>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-if="tab === 0"
                      label="Значение"
                      v-model="editOption.option_value"
                    />
                    <v-checkbox
                      v-else
                      true-value="1"
                      false-value="0"
                      label="Включено"
                      v-model="editOption.option_value"
                      />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
              >
                Закрыть
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="store"
              >
                Сохранить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-data-table
          :headers="headers"
          :options.sync="options"
          :items="optionalItems"
          :server-items-length="totalOptionalItems"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn
              @click="editItem(item)"
              icon
            >
              <v-icon color="primary">
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-icon
              color="red"
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item>
        <v-autocomplete :items="allEntities" v-model="entity" item-value="value" item-text="name"/>
        <v-data-table :items="optionalItems" :options.sync="options" :headers="headers" :server-items-length="totalOptionalItems">
          <template v-slot:item.actions="{ item }">
            <v-btn
              @click="editItem(item)"
              icon
            >
              <v-icon color="primary">
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-icon
              color="red"
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>

  </v-container>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
  name: "OptionForm",
  created() {
    this.fetchAllEntities();
    this.fetchOptions(this.payLoad);
  },
  data: () => ({
    headers: [
      {text: 'Ключ', value: 'option_key'},
      {text: 'Значение', value: 'option_value'},
      {text: 'Описание', value: 'description' },
      {text: 'Дата изменения', value: 'updated_at'},
      {text: '', value: 'actions'},
    ],
    editOption: {
      option_key: '',
      option_value: '',
      description: null,
    },
    defaultOption: {
      option_key: '',
      option_value: '',
      description: null,
    },
    options: {
      sortBy: ['option_key'],
      sortDesc: [true],
      page: 1,
      itemsPerPage: 15,
    },
    dialog: false,
    tab: 0,
    entity: null,
  }),
  computed: {
    ...mapGetters({
      allEntities: "Constants/getAllEntities",
      optionalItems: "Sites/getOptionsListing",
      totalOptionalItems: "Sites/getListingTotalItems"
    }),
    payLoad() {
      return {
        options: this.options,
        entity: this.entity,
        site_id: this.$route.params.id,
      }
    }
  },
  methods: {
    ...mapActions({
      storeOption: 'Sites/storeOption',
      updateOption: 'Sites/updateOption',
      destroyOption: 'Sites/destroyOption',
      fetchSite: 'Sites/fetchDetail',
      fetchAllEntities: 'Constants/fetchAllEntities',
      fetchOptions: 'Sites/fetchOptionListing',
    }),
    editItem(item) {
      this.editOption = { ...item };
      this.dialog = true;
    },
    deleteItem(item) {
      this.destroyOption(item.option_key).then(() => {
        this.fetchOptions(this.payLoad);
      })
    },
    store() {
      this.editOption.entity = this.entity;
      this.editOption.site_id = this.$route.params.id

      if (this.editOption.entity) {
        const splitted = this.editOption.entity.split('\\');

        if (this.editOption.option_key.indexOf(splitted[splitted.length - 1].toLowerCase()) !== 0) {
          this.editOption.option_key = splitted[splitted.length - 1].toLowerCase() + "_" + this.editOption.option_key;
        }
      }

      if (this.optionalItems.find((el) => el.option_key === this.editOption.option_key)) {
        this.updateOption(this.editOption).then(() => {
          this.fetchOptions(this.payLoad).then(() => {
            this.dialog = false;
          })
        })
      } else {
        this.editOption.site_id = this.$route.params.id;
        this.storeOption(this.editOption).then(() => {
          this.fetchOptions(this.payLoad).then(() => {
            this.dialog = false;
          })
        })
      }
    }
  },
  watch: {
    option: {
      handler() {
        if (this.option && Object.keys(this.option).length > 0) {
          this.form = {...this.form, ...JSON.parse(JSON.stringify(this.option))};
          this.isSaveDisabled = false;
        }
      },
      deep: true,
    },
    dialog: {
      handler(val) {
        if (!val) {
          this.editOption = this.defaultOption;

          if (this.tab === 1) {
            this.editOption.option_value = '0';
          }
        }
      }
    },
    tab: {
      handler(val) {
        if (val === 0) {
          this.entity = null;
          this.editOption.option_value = '';
        } else {
          this.entity = "Modules\\Articles\\Entities\\Article";
          this.editOption.option_value = '0';
        }

        this.options = {
          sortBy: ['option_key'],
          sortDesc: [true],
          page: 1,
          itemsPerPage: 15,
        };

        this.fetchOptions(this.payLoad);
      }
    },
    entity: {
      handler() {
        this.fetchOptions(this.payLoad);
      }
    },
    options: {
      handler() {
        this.fetchOptions(this.payLoad)
      }
    }
  }
}
</script>
