<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <CatalogItemForm @onSubmit="submit"/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import CatalogItemForm from "../../../../components/catalogs/CatalogItemForm";
import { mapActions } from 'vuex';

export default {
  name: "Create",
  components: {CatalogItemForm},
  data: () => ({
    page: {
      title: "Создание элемента каталога"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Каталог",
        disabled: false,
        to: '/catalogs'
      },
      {
        text: "Создание",
        disabled: true,
        to: '/catalogs/create'
      },
    ],
  }),
  async created() {
    console.log(await this.checkSku('123'));
  },
  methods: {
    ...mapActions({
      storeItem: "CatalogItem/storeItem",
      checkSku: "CatalogItem/checkSku",
      snackbarError: "snackbar/showSnackbar"
    }),
    async submit({ form, isNeedRedirect }) {
      form.catalog_id = this.$route.params.catalog_id;

      if (await this.checkSku(form.sku)) {
        await this.storeItem({ form: form, isNeedRedirect: isNeedRedirect, router: this.$router })
      } else {
        this.snackbarError({ color: 'red', text: 'Данный артикул уже занят', timeout: 3000 })
      }
    }
  }
}
</script>

<style scoped>

</style>
