import App from './App.vue';
import VueApexCharts from 'vue-apexcharts';
import layouts from './layouts';
import router from './router';
import Vue from 'vue';
import vuetify from './plugins/vuetify';
import axios from './axiosInstance';
import { Orion } from '@tailflow/laravel-orion/lib/orion';
import store from './stores';
import VuejsClipper from 'vuejs-clipper'
import VueRx from 'vue-rx'
import Preview from '@/components/Preview.vue'
// @ts-ignore
import DatetimePicker from 'vuetify-datetime-picker';
// @ts-ignore
import VueSimpleAlert from "vue-simple-alert";
// @ts-ignore
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
// @ts-ignore
import FormLoader from './components/forms/FormLoader.vue'
import DynamicForm from './components/forms/DynamicForm.vue';
import VueExcelEditor from '@/plugins/vue-excel-editor'
import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
// @ts-ignore
import { ValidationObserver, ValidationProvider, localize } from 'vee-validate';
// @ts-ignore
import { messages } from 'vee-validate/dist/locale/ru.json';
import { VueMaskDirective } from 'v-mask';
import VueClipboard from 'vue-clipboard2'
import Vuebar from "vuebar";
import "./plugins/base";
import VueSkycons from "vue-skycons";
import InstantSearch from "vue-instantsearch";
import CountryFlag from 'vue-country-flag';
import 'xe-utils';
import VXETable from 'vxe-table';
import 'vxe-table/lib/style.css';
import VueI18n from "vue-i18n";
// @ts-ignore
import ru from "./lang/ru/lang.json";

Vue.use(VueSkycons, {
    color: "#1e88e5",
});

Vue.use(VueClipboard)
Vue.use(VueExcelEditor)
Vue.use(InstantSearch);
Vue.use(Vuebar);

Vue.component('form-loader', FormLoader);
Vue.component('dynamic-form', DynamicForm);
Vue.component('apexchart', VueApexCharts);
Vue.component('country-flag', CountryFlag);

Vue.directive('mask', VueMaskDirective);
/**
 * Set global plugins
 */
Vue.use(VueRx)
// @ts-ignore
Vue.use(VuejsClipper);
Vue.use(DatetimePicker);
Vue.use(VueSimpleAlert);
Vue.use(TiptapVuetifyPlugin, {
    // the next line is important! You need to provide the Vuetify Object to this place.
    vuetify, // same as "vuetify: vuetify"
    // optional, default to 'md' (default vuetify icons before v2.0.0)
    iconsGroup: 'mdi'
})
Orion.init(process.env.VUE_APP_API_URL);
// @ts-ignore
Orion.makeHttpClientUsing(() => axios)

/**
 * Set layouts
 */

layouts.forEach(({ id, component }) => {
  Vue.component(id, component);
})


/**
 * Set global component
 */
Vue.component('preview', Preview)
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Object.keys(rules).forEach(rule => {
    extend(rule, {
        ...rules[rule], // copies rule configuration
        message: messages[rule] // assign message
    });
});

/**
 * Set global mixins
 */

Vue.mixin({
    methods:{
        can(permission) {
            return store.getters['auth/getPermissions'].indexOf(permission) > -1;
        }
    }
});

const localization = {
    ru: ru,
}

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'ru',
    "messages": localization,
})

VXETable.setup({
    // @ts-ignore
    i18n: (key, args) => i18n.t(key, args),
})

Vue.use(VXETable);

// eslint-disable-next-line no-new
// @ts-ignore
new Vue({
 el: '#app',
 render: h => h(App),
 router,
 vuetify,
 store,
 i18n
})


export const eventBus = new Vue()
