import { Model } from "@tailflow/laravel-orion/lib/model";

export class Lead extends Model<{
    name: string,
    phone: string,
    email: string,
    comment: object[],
    created_at: string,
    updated_at: string
}> {

    $resource(): string {
        return "leads";
    }
}
