<template>
  <v-layout style="flex: unset">
    <v-navigation-drawer
      style="z-index:2"
      clipped
      stateless
      :value="true"
    >
      <v-container ma-0 pa-0>
        <v-toolbar flat>
          <h2>Блоки</h2>
        </v-toolbar>
      </v-container>
      <v-list>
        <v-list-item
          v-for="item in items"
          :key="item.id"
          @click="onClickGroup(item)"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-navigation-drawer
      id="nav2"
      style="z-index:1"
      v-model="drawer"
      :class="['pb-0', !drawer ? 'd-none' : '']"
      stateless
    >
      <v-list>
        <v-list-item
          v-for="block in currentBlocks"
          :key="block.id"
          @click="selectBlock(block)"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ block.structure.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-layout>
</template>

<script>

export default {
  name: 'BlocksMenu',
  props: {
    blocks: {
      type: Array,
      required: true,
    },
    blockGroups: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    drawer: false,
    items: [],
    currentBlocks: [],
  }),
  watch: {
    blocks: {
      handler(blocks) {
        if (blocks.length > 0) {
          this.getItems();
        }
      },
      deep: true,
      immediate: true,
    },
    blockGroups: {
      handler(blockGroups) {
        if (blockGroups.length > 0) {
          this.getItems();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getItems() {
      this.items = [];
      this.blockGroups.forEach((blockGroup) => {
        const blocks = this.blocks.filter(({ block_group_id }) => block_group_id === blockGroup.id);
        this.items.push({
          ...blockGroup,
          blocks,
        })
      })
    },
    onClickGroup(item) {
      this.drawer = !this.drawer;
      this.currentBlocks = item.blocks;
    },
    selectBlock(block) {
      const blocks = this.$store.getters['block/getBlocks'];
      this.$store.dispatch('block/setBlocks', [...blocks, { ...block.structure, values: {} }]);
    },
  },
};
</script>

<style lang="scss">
.v-overlay {
  position: fixed;
  width: 100%;
}
</style>
