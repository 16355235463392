<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <PermissionForm
              :permission="permission"
              @onSubmit="submit"
            />
          </v-col>
        </v-row>
        <v-snackbar
          v-model="snackbarSuccess"
          :timeout="timeout"
          color="success"
        >
          Сохранено

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarSuccess = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar
          v-model="snackbarError"
          :timeout="timeout"
          color="error"
        >
          {{ this.errors }}

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarError = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import PermissionForm from "../../../components/permissions/PermissionForm";
import {Permission} from "../../../models/permission";

export default {
  name: "Edit",
  components: {PermissionForm},
  data: () => {
    return {
      permission: new Permission(),
      errors: {},
      snackbarSuccess: false,
      snackbarError: false,
      timeout: 2000,
      page: {
        title: "Редактирование права"
      },
      breadcrumbs: [
        {
          text: "Админ панель",
          disabled: false,
          to: '/'
        },
        {
          text: "Права",
          disabled: true,
          to: '/permissions'
        },
        {
          text: "Редактирование",
          disabled: true,
          to: '/permissions/edit'
        },
      ]
    }
  },
  created() {
    this.get();
  },
  methods: {
    async get() {
      this.permission = await Permission.$query().find(this.$route.params.id);
    },
    async submit(permission) {
      permission.$save(permission.$attributes).then(() => {
        this.snackbarSuccess = true;
        this.get()
      }).catch(() => {
        this.errors.name = "Name is not unique";
        this.snackbarError = true;
      })
    }
  },
  watch: {
    'permission.$attributes.name': {
      handler(newTitle) {
        this.page.title +=  ` "${newTitle}"`;
      }
    },
  }
}
</script>
