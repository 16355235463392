<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <UserForm
              @onSubmit="submit"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import UserForm from "../../../components/users/UserForm";
import {mapActions} from "vuex";

export default {
  name: "Create",
  components: {
    UserForm,
  },
  data: () => ({
    page: {
      title: "Создание пользователя"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Пользователи",
        disabled: true,
        to: '/users'
      },
      {
        text: "Создание",
        disabled: true,
        to: '/users/create'
      },
    ]
  }),
  methods: {
    ...mapActions({
      store: 'Users/store',
    }),
    async submit(form) {
      await this.store(form).then(() => {
        this.$router.push({name: 'users'});
      });
    }
  }
}
</script>
