<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Уроки курса "{{ course.title }}"</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="gray" to="/courses" class="mr-1">
          Обратно к курсам
        </v-btn>
        <v-btn color="primary" :to="`/course/${courseId}/lessons/create`">
          Создать урок
        </v-btn>
      </v-col>
      <v-col>
        <v-checkbox
          v-model="only_trashed"
          label="Показать удаленные"
          name="only_trashed"
          value="1"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon
              v-if="item.deleted_at"
              color="green"
              @click="restoreItem(item)"
            >
              mdi-backup-restore
            </v-icon>
            <template v-else>
              <!--              <v-btn-->
              <!--                icon-->
              <!--                v-if="item.preview_url"-->
              <!--                :href="item.preview_url"-->
              <!--                target="_blank"-->
              <!--              >-->
              <!--                <v-icon color="primary">-->
              <!--                  mdi-eye-->
              <!--                </v-icon>-->
              <!--              </v-btn>-->
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-if="item.course && item.course.admin_attach_url"
                    v-bind="attrs"
                      target="_blank"
                    v-on="on"
                    @click="attachItem(item)"
                  >
                    <v-icon color="primary">
                      mdi-eye-plus
                    </v-icon>
                  </v-btn>
                </template>
                <span>Предпросмотр купленного урока</span>
              </v-tooltip>
              <v-btn
                :href="editItem(item)"
                icon
              >
                <v-icon color="primary">
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-icon
                color="red"
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
          </template>
          <template v-slot:item.publish="{ item }">
            <v-chip :color="getStatusColor(item.status)" text-color="white">
              {{ item.human_status }}
            </v-chip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axiosInstance from "../../../axiosInstance";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'Index',
  data: () => ({
    courseId: null,
    search: '',
    only_trashed: null,
    headers: [
      {text: 'Id', value: 'id'},
      {text: 'Название', value: 'title'},
      {text: 'Slug', value: 'slug'},
      {text: 'Статус', value: 'publish'},
      {text: 'Позиция в сортировке', value: 'position'},
      {text: 'Дата создания', value: 'created_at'},
      {text: 'Дата обновления', value: 'updated_at'},
      {text: 'Создатель', value: 'creator.full_name'},
      {text: '', value: 'actions'},
    ],
  }),
  created() {
    this.courseId = this.$route.params.course_id;
    this.get();
    this.fetchCourseDetail(this.courseId);
  },
  computed: {
    ...mapGetters({
      items: 'Lessons/getFetchedListing',
      course: 'Courses/getFetchedDetail',
    })
  },
  methods: {
    ...mapActions({
      fetchListing: 'Lessons/fetchListing',
      destroyItem: 'Lessons/destroyItem',
      restoreItem: 'Lessons/restoreItem',
      fetchCourseDetail: 'Courses/fetchDetail',
    }),
    editItem(item) {
      return this.$router.resolve({name: 'lessons-edit', params: {id: item.id, course_id: this.courseId}}).href
    },
    deleteItem(item) {
      this.$confirm('Вы уверены?', 'Удаление').then(() => {
        this.destroyItem(item.id).then(() => {
          this.get();
        })
      });
    },
    async restoreItem(item) {
      await this.restoreItem(item.id).then(() => {
        this.get();
      })
    },
    attachItem(item) {
      axiosInstance.get(`/auth/user/course/${item.course.id}/attach`)
        .then((response) => {
          window.open(response.data + `/${item.slug}?preview_mode=true`);
        });
    },
    async get() {
      const payload = {
        courseID: this.courseId,
        onlyTrashed: this.only_trashed,
      }

      await this.fetchListing(payload);
    },
    getStatusColor(status) {
      switch (status) {
        case 0:
          return 'red';
        case 1:
          return 'green';
        default:
          return 'grey';
      }
    },
  },
  watch: {
    only_trashed: {
      handler() {
        this.get();
      }
    },
  },
};
</script>
