<template>
  <v-form>
    <v-row>
      <v-col>
        <v-card :loading="loading">
          <v-container>
            <v-flex>
              <v-btn
                class="mr-4"
                color="primary"
                @click="submit(true)"
                :disabled="isSaveDisabled || isDisabled"
                :loading="isSaveDisabled"
              >
                Сохранить
              </v-btn>
              <v-btn
                class="mr-4"
                color="primary"
                @click="submit(false)"
                :disabled="isSaveDisabled || isDisabled"
                :loading="isSaveDisabled"
              >
                Сохранить и продолжить
              </v-btn>
            </v-flex>
            <v-text-field
              v-model="form.title"
              :error-messages="errors.title"
              label="Название"
              name="title"
              required
            ></v-text-field>
            <v-text-field
              v-model="form.slug"
              :error-messages="errors.slug"
              label="Slug"
              name="slug"
              required
              :disabled="!(Object.keys(library).length === 0)"
            ></v-text-field>
            <a
              v-if="library.preview_url"
              :href="library.preview_url"
              target="_blank"
              class="mr-3"
            >
              {{ library.preview_url }}
            </a>
            <preview v-if="library.preview_url" :previewUrl="library.preview_url"/>
            <v-textarea
              v-model="form.lead"
              :error-messages="errors.lead"
              label="Лид"
              name="lead"
              required
            />
            <div class="m-2">
              <h4 class="mt-2">Контент</h4>
              <media-form
                :key="reRenderMedia"
                ref="media-form"
                :structure="currentMedia.structure"
                :is-show-save="false"
                style="margin: 0 auto"
              />
            </div>
            <div class="m-2">
              <h4 class="mt-2">Детальный текст</h4>
              <tip-tap
                v-model="form.detail_text"
                class="mb-2"
              />
            </div>
            <div class="m-2">
              <h4 class="mt-2">Автор</h4>
              <tip-tap
                v-model="form.author"
                class="mb-2"
              />
            </div>
            <div class="m-2">
              <h4 class="mt-2">Особенности применения продукта</h4>
              <tip-tap
                v-model="form.special"
                class="mb-2"
              />
            </div>
            <div class="m-2">
              <h4 class="mt-2">Опыт применения продукта</h4>
              <tip-tap
                v-model="form.opyt"
                class="mb-2"
              />
            </div>
            <div class="m-2">
              <h4 class="mt-2">Актуальность материала</h4>
              <tip-tap
                v-model="form.actuality"
                class="mb-2"
              />
            </div>
            <div class="m-2">
              <h4 class="mt-2">Организации для партнера</h4>
              <tip-tap
                v-model="form.organizations"
                class="mb-2"
              />
            </div>
            <media-widget
              class="mb-1"
              label="Анонсное изображение"
              :medias="form.preview_image"
              :is-multiple="false"
              @onChange="setPreviewImage"
            />
            <v-row class="mt-5">
              <v-col cols="12">
                <h4>Ссылка для скачивания</h4>
              </v-col>
              <v-row v-for="(link, index) in form.links" :key="index">
                <v-col>
                  <v-text-field
                    v-model="link.title"
                    label="Название"
                    name="title"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="link.name"
                    label="Имя файла"
                    name="name"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="link.size"
                    label="Размер"
                    name="size"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="auto pl-0">
                  <v-btn
                    icon
                    color="red"
                    @click="delete form.links.splice(index, 1)"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-col cols="12">
                <v-btn
                  @click="addLink"
                >
                  Добавить ссылку
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <v-col class="col-4">
        <v-card>
          <v-container>
            <v-datetime-picker
              v-model="form.published_at"
              scrollable
              label="Дата публикации. По умолчанию текущая дата и время"
              clear-text="Очистить"
            ></v-datetime-picker>
            <v-select
              v-if="statuses"
              v-model="form.status"
              :error-messages="errors.status"
              :items="statuses"
              label="Статус"
              name="status"
              required
            ></v-select>
            <v-select
              v-if="formats"
              v-model="form.format"
              :error-messages="errors.format"
              :items="formats"
              label="Формат"
              name="format"
              required
            ></v-select>
<!--            <v-select-->
<!--              v-if="types"-->
<!--              v-model="form.type"-->
<!--              :error-messages="errors.type"-->
<!--              :items="types"-->
<!--              label="Тип"-->
<!--              name="type"-->
<!--              required-->
<!--            ></v-select>-->
            <v-checkbox
              v-model="form.is_archive"
              :error-messages="errors.is_archive"
              label="Архивная запись"
              name="is_archive"
              required
            ></v-checkbox>
          </v-container>
        </v-card>
        <v-card class="mt-5">
          <v-card-title>
            Техническая информация старого архива
          </v-card-title>
          <v-container>
            <v-row class="mt-5">
              <v-col cols="12" class="pb-0">
                <p>Ссылка на связные продукты</p>
              </v-col>
              <v-col
                cols="12"
                v-for="(value, index) in form.links_add"
                :key="index"
                class="pt-0"
              >
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="form.links_add[index]"
                      label="Название"
                      name="title"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="auto pl-0">
                    <v-btn
                      icon
                      color="red"
                      @click="delete form.links_add.splice(index, 1)"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                class="pt-0"
              >
                <v-btn
                  @click="addLinkAdd"
                >
                  Добавить ссылку
                </v-btn>
              </v-col>
            </v-row>
            <v-text-field
              v-model="form.number"
              label="Номер"
              name="number"
              required
            ></v-text-field>
            <v-text-field
              v-model="form.tirag"
              label="Тиражирование"
              name="tirag"
              required
            ></v-text-field>
            <v-textarea
              v-model="form.comment"
              :error-messages="errors.comment"
              label="Комментарий"
              name="comment"
              required
            ></v-textarea>
            <v-text-field
              v-model="form.file_card"
              label="Файл карточки"
              name="file_card"
              required
            ></v-text-field>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-flex>
          <v-btn
            class="mr-4"
            color="primary"
            @click="submit(true)"
            :disabled="isSaveDisabled || isDisabled"
            :loading="isSaveDisabled"
          >
            Сохранить
          </v-btn>
          <v-btn
            class="mr-4"
            color="primary"
            @click="submit(false)"
            :disabled="isSaveDisabled || isDisabled"
            :loading="isSaveDisabled"
          >
            Сохранить и продолжить
          </v-btn>
        </v-flex>
      </v-col>
    </v-row>
    <media-uploader
      :loaded-medias="library.loaded_medias"
      @completeUploads="onCompleteUploads"
    />
  </v-form>
</template>

<script>
import slugify from 'slugify';
import MediaUploader from '../MediaUploader';
import MediaWidget from '../MediaWidget';
import moment from "moment";
import TipTap from "../editor/TipTap";
import MediaForm from '../medias/MediaForm';
import optionsMixin from "../../mixins/optionsMixin";

export default {
  name: 'LibraryForm',
  mixins: [optionsMixin],
  components: {
    MediaWidget,
    MediaUploader,
    TipTap,
    MediaForm,
  },
  props: {
    library: {
      type: Object,
      default: () => ({})
    },
    media: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Object,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    form: {
      id: null,
      title: null,
      slug: null,
      preview_image: null,
      lead: null,
      is_archive: false,
      status: null,
      detail_text: null,
      author: null,
      special: null,
      opyt: null,
      organizations: null,
      type: null,
      format: null,
      links: [],
      links_add: [],
      published_at: null,
      number: null,
      comment: null,
      tirag: null,
      file_card: null,
      language: null,
      actuality: null,
    },
    statuses: [
      {text: 'Черновик', value: 0},
      {text: 'Заполанировано', value: 1},
      {text: 'Опубликовано', value: 2},
      {text: 'Неактуальный', value: 3},
    ],
    types: [
      {text: 'Новость', value: 4},
      {text: 'Инструкция', value: 5},
      {text: 'Исследование', value: 0},
      // {text: 'Кейс', value: 1},
      {text: 'Интервью', value: 2},
      {text: 'Личная история', value: 3},
      {text: 'Экспертное мнение', value: 6},
      // {text: 'Заметка', value: 7},
      {text: 'Отзыв', value: 8},
      {text: 'Вопрос&ответ', value: 9},
      {text: 'Дайджест', value: 10},
      {text: 'Образовательный модуль', value: 11},
      {text: 'Игра', value: 12},
    ],
    formats: [
      {text: 'аудиозапись', value: 0},
      {text: 'брошюра', value: 1},
      {text: 'буклет', value: 2},
      {text: 'видеоролик', value: 3},
      {text: 'видеоурок', value: 4},
      {text: 'игра / квест', value: 5},
      {text: 'комплект материалов', value: 6},
      {text: 'материалы для бесед', value: 7},
      {text: 'мультфильм', value: 8},
      {text: 'материалы для работы', value: 9},
      {text: 'методические материалы', value: 10},
      {text: 'настольная игра', value: 11},
      {text: 'образовательный модуль', value: 12},
      {text: 'лекция / онлайн-курс', value: 13},
      {text: 'памятка', value: 14},
      {text: 'плакат', value: 15},
      {text: 'презентация', value: 16},
      {text: 'программа курса', value: 17},
      {text: 'рабочая тетрадь', value: 18},
      {text: 'сериал', value: 19},
      {text: 'разъяснения', value: 20},
      {text: 'статья', value: 21},
      {text: 'учебник', value: 22},
      {text: 'учебное пособие', value: 23},
      {text: 'фильм', value: 24},
      {text: 'отчет', value: 25},
      {text: 'исследование', value: 26},
      {text: 'тест', value: 27},
    ],
    languages: [
      {text: 'Русский', value: 'ru'},
      {text: 'Английский', value: 'en'},
    ],
    currentMedia: {
      id: null,
      entity: 'Modules\\MoiFinancy\\Entities\\Library',
      entity_id: null,
      structure: null,
    },
    loading: 0,
    mediaIds: [],
    formErrors: {},
    isSaveDisabled: false,
    reRenderMedia: 1,
  }),
  created() {
    this.loading = true;
    if (this.$route.params.media) {
      this.currentMedia = JSON.parse(this.$route.params.media);
    }
    if (this.$route.params.data) {
      this.form = JSON.parse(this.$route.params.data);
    }
    this.loading = false;
    this.form.language = this.language;
  },
  mounted() {
    this.form.language = this.language;
  },
  methods: {
    async onCompleteUploads(ids) {
      this.mediaIds = ids;
      await this.submit(true);
      this.mediaIds = [];
    },
    async submit(isNeedRedirect) {
      this.isSaveDisabled = true;

      const data = await this.$refs['media-form'].submit();

      if (data) {
        this.currentMedia.structure = data;
      }

      this.form.published_at = this.form.published_at
        ? moment(this.form.published_at).format('YYYY-MM-DD HH:mm')
        : null;

      if (this.form.status === null) {
        this.form.status = 0;
      }

      this.$emit('onSubmit', {
        form: this.form,
        media: this.currentMedia,
        mediaIds: this.mediaIds,
        isNeedRedirect: isNeedRedirect,
      });
    },
    setPreviewImage(image) {
      this.form.preview_image = image;
    },
    addLink() {
      this.form.links.push({
        title: '',
        name: '',
        size: '',
      })
    },
    addLinkAdd() {
      this.form.links_add.push('')
    }
  },
  watch: {
    library: {
      handler(library) {
        if (library && Object.keys(library).length > 0) {
          this.form = library;
          this.form.language = this.language;
          this.isSaveDisabled = false;
        }
      },
      immediate: true,
      deep: true,
    },
    media: {
      handler(media) {
        if (media && Object.keys(media).length > 0) {
          this.currentMedia = JSON.parse(JSON.stringify(media));
          this.reRenderMedia += 1;
        }
      },
      immediate: true,
      deep: true,
    },
    'form.title': {
      handler(value) {
        if (value && Object.keys(this.library).length === 0) {
          this.form.slug = slugify(value.toLowerCase()).match(/[a-z]|[0-9]|[-]/gm).join('');
        }
      }
    }
  },
};
</script>
