<template>
  <div class="excel">
    <v-tabs  v-model="currentTab">
      <v-tab v-for="(tab, index) in tabs" :key="index">
        {{tab.title}}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="currentTab">
      <v-tab-item v-for="(tab, index) in tabs" :key="index">
        <v-btn class="mr-2" v-for="(button, index2) in tab.buttons" :key="index2" :href="getUrl(button.query, index)">
          {{ button.title }}
        </v-btn>
      </v-tab-item>
    </v-tabs-items>
    <h1>{{ currentTitle }}</h1>
    <template class="v-window v-item-group theme--light v-tabs-items" v-if="isCollectionItems">
      <v-text-field label="Поиск" @input="search('searchableCollections', $event, 'collectionItems', 'title')"/>
      <div class="tab-inner-wrap">
        <vue-excel-editor v-model="searchableCollections" ref="grid" width="900" @cell-click="cellClickHandler('searchableCollections', $event)" @update="collectionUpdated" @delete="deleteItemHandler">
          <vue-excel-column field="id" :invisible="true" :readonly="true" key-field/>
          <vue-excel-column field="title" label="Название"/>
        </vue-excel-editor>
      </div>
    </template>
    <template class="v-window v-item-group theme--light v-tabs-items" v-if="entity === 'products' && isOptionsLoaded">
      <v-text-field label="Поиск" v-model="searchText" @input="search('searchableProducts', $event, 'products', 'sku')"/>
      <div class="tab-inner-wrap">
        <vue-excel-editor v-model="searchableProducts" ref="grid" width="900" @cell-click="cellClickHandler('searchableProducts', $event)" @update="productsUpdated" @delete="deleteItemHandler">
          <vue-excel-column field="id" :invisible="true" :readonly="true" key-field/>
          <vue-excel-column field="product_line" label="Линия" type="map" :options="productOptions.product_line"/>
          <vue-excel-column field="sku" label="Артиткул"/>
          <vue-excel-column field="where_display" label="Где отображать" type="map" :options="['В профессиональном разделе', 'В розничном разделе', 'В обоих']"/>
          <vue-excel-column field="product_type" label="Тип продукта" type="map"
                            :options="productOptions.product_type"/>
          <vue-excel-column field="title" label="Название" type="string"/>
          <vue-excel-column field="title_eng" label="Название на англ" type="string"/>
          <vue-excel-column field="description" label="Описание" type="string"/>
          <vue-excel-column field="announce_description" label="Описание для анонса" type="string"/>
          <vue-excel-column field="price" label="Стоимость" type="string"/>
          <vue-excel-column field="product_url" label="Ссылка на товар" type="string"/>
          <vue-excel-column field="url_image" label="Фото для описания" type="string"/>
          <vue-excel-column field="main_action" label="Основное действие" type="string"/>
          <vue-excel-column field="image_main_action" label="Фото для основного действия" type="string"/>
          <vue-excel-column field="science" label="Наука" type="string"/>
          <vue-excel-column field="image_science" label="Фото для науки" type="string"/>
          <vue-excel-column field="applications" label="Примение" type="string"/>
          <vue-excel-column field="image_applications" label="Фото для применения" type="string"/>
          <vue-excel-column field="volume" label="Объем" type="number"/>
          <vue-excel-column field="quantity" label="Кол-во" type="number"/>
          <vue-excel-column field="unit" label="Ед. измерения" type="map" :options="productOptions.unit"/>
          <vue-excel-column field="for_whom" label="Для кого" type="map" :options="productOptions.for_whom"/>
          <vue-excel-column field="age" label="Возраст" type="map" :options="productOptions.age"/>
          <vue-excel-column field="usage" label="Использование" type="map" :options="productOptions.usage"/>
          <vue-excel-column field="for_whom_problem" label="Для кого(тип кожи)" type="map"
                            :options="productOptions.for_whom_problem"/>
          <vue-excel-column field="series" label="Серия" type="map" :options="productOptions.series"/>
          <vue-excel-column field="body_part" label="Часть тела" type="map" :options="productOptions.body_part"/>
        </vue-excel-editor>
      </div>
    </template>
    <template class="v-window v-item-group theme--light v-tabs-items" v-if="entity === 'procedures'">
      <v-text-field label="Поиск" v-model="searchText" @input="search('searchableProcedures', $event, 'procedures', 'title')"/>
      <div class="tab-inner-wrap">
        <vue-excel-editor v-model="searchableProcedures" ref="grid" width="900" @cell-click="cellClickHandler('searchableProcedures', $event)" @update="proceduresUpdated" @delete="deleteItemHandler">
          <vue-excel-column :invisible="true" field="id" :readonly="true" key-field/>
          <vue-excel-column field="title" label="Название" type="string"/>
          <vue-excel-column field="destination" label="Назначение" type="string"/>
          <vue-excel-column field="description" label="Описание" type="string"/>
          <vue-excel-column field="announcement_description" label="Описание в анонсе" type="string"/>
          <vue-excel-column field="image" label="Фото" type="string"/>
          <vue-excel-column field="for_who" label="Кому подходит" type="string"/>
          <vue-excel-column field="programs" label="Программа" type="string"/>
          <vue-excel-column field="indications" label="Показания" type="string"/>
          <vue-excel-column field="results" label="Результат" type="string"/>
          <vue-excel-column field="video" label="Видео" type="string"/>
          <vue-excel-column field="science_1" label="Наука лежащая в основе (1)" type="string"/>
          <vue-excel-column field="science_image_1" label="Фото" type="string"/>
          <vue-excel-column field="science_2" label="Список препоратов для процедуры" type="string"/>
          <vue-excel-column field="science_image_2" label="Фото" type="string"/>
          <vue-excel-column field="home_care" label="Программа домашнего ухода" type="string"/>
        </vue-excel-editor>
      </div>
    </template>
    <template class="v-window v-item-group theme--light v-tabs-items" v-if="entity === 'partners' && isOptionsLoaded">
      <v-text-field label="Поиск" v-model="searchText" @input="search('searchablePartners', $event, 'partners', 'title')"/>
      <div class="tab-inner-wrap">
        <vue-excel-editor v-model="searchablePartners" ref="grid" width="900" @cell-click="cellClickHandler('searchablePartners', $event)" @update="partnersUpdated" @delete="deleteItemHandler">
          <vue-excel-column field="title" label="Название"/>
          <vue-excel-column v-if="!isLegasyFollred" field="partner_type" label="Тип партнера" type="map" :options="partnersOptions.partner_type"/>
          <vue-excel-column field="lead" label="Описание"/>
          <vue-excel-column field="image" label="Логотип"/>
          <vue-excel-column field="additional_image" label="Keyvisual"/>
          <vue-excel-column field="geographic_address" label="Адрес"/>
          <vue-excel-column field="latitude" label="Точка локации(широта)"/>
          <vue-excel-column field="longitude" label="Точка локации(долгота)"/>
          <vue-excel-column field="city" label="Город" type="map" :options="partnersOptions.city"/>
          <vue-excel-column field="metro" label="Метро"/>
          <vue-excel-column field="site_url" label="Сайт"/>
          <vue-excel-column field="additional_site_url" label="Дополнительный сайт"/>
          <vue-excel-column field="contact_person" label="Контактное лицо"/>
          <vue-excel-column field="work_time" label="Режим работы"/>
          <vue-excel-column field="vk_url" label="Ссылка на VK"/>
          <vue-excel-column field="ok_url" label="Ссылка на Odnoklassniki"/>
          <vue-excel-column field="fb_url" label="Ссылка на FaceBook"/>
          <vue-excel-column field="inst_url" label="Ссылка на Instagram"/>
          <vue-excel-column field="tg_url" label="Ссылка на Telegram"/>
          <vue-excel-column field="tt_url" label="Ссылка на TikTok"/>
          <vue-excel-column field="youtube_url" label="Ссылка на YouTube"/>
          <vue-excel-column field="twitter_url" label="Ссылка на Twitter"/>
          <vue-excel-column field="zen_url" label="Ссылка на Дзен"/>
          <vue-excel-column field="email" label="Емейл"/>
          <vue-excel-column field="phone" label="Телефон"/>
          <vue-excel-column field="second_phone" label="Второй телефон"/>
          <vue-excel-column field="whatsapp" label="Whatsapp"/>
          <vue-excel-column field="viber" label="Viber"/>
          <vue-excel-column field="type" label="Таксономия" type="map" :options="partnersOptions.type"/>
        </vue-excel-editor>
      </div>
    </template>
    <template class="v-window v-item-group theme--light v-tabs-items" v-if="entity === 'bestPractices' && isOptionsLoaded">
      <v-text-field label="Поиск" v-model="searchText" @input="search('searchableBestPractices', $event, 'bestPractices', 'title')"/>
      <vue-excel-editor v-model="searchableBestPractices" ref="grid" :autocomplete-count=100 width="900" @cell-click="cellClickHandler('searchableBestPractices', $event)" @update="bestPracticesUpdated" @delete="deleteItemHandler">
        <vue-excel-column field="region" label="Регион" type="map" :options="bestPracticesOptions.regions"/>
        <vue-excel-column field="title" label="Заголовок"/>
        <vue-excel-column field="is_the_best" label="Является лучшей практикой" type="map" :options="{ '0': 'Нет', '1': 'Да' }"/>
        <vue-excel-column field="name" label="Название"/>
        <vue-excel-column field="direction" label="Направление"/>
        <vue-excel-column field="target_audience" label="Целевая аудитория"/>
        <vue-excel-column field="reach_audience" label="Охват аудитории"/>
        <vue-excel-column field="number_of_coverage" label="Численность охвата"/>
        <vue-excel-column field="addition" label="Дополнение"/>
        <vue-excel-column field="developer_name" label="Разработчик (название)"/>
        <vue-excel-column field="developer_url" label="Разработчик ссылка"/>
        <vue-excel-column field="year_of_develop" label="Год разработки"/>
        <vue-excel-column field="short_description" label="Краткое описание"/>
        <vue-excel-column field="results" label="Достигнутые результаты"/>
        <vue-excel-column field="year_of_awarding" label="Год награждения"/>
        <vue-excel-column field="promising_results" label="Ожидаемые перспективные результаты"/>
        <vue-excel-column field="opportunities" label="Возможности и инструменты тиражирования"/>
        <vue-excel-column field="practices_site_url" label="Ссылки на практику сайта"/>
        <vue-excel-column field="social_networks" label="Соцсети"/>
        <vue-excel-column field="region_financial_literacy" label="Региональный центр финансовой грамотности"/>
        <vue-excel-column field="contact_person_of_the_center" label="Контактное лицо центра"/>
        <vue-excel-column field="mark" label="Отметка" type="map" :options="bestPracticesOptions.mark"/>
      </vue-excel-editor>
    </template>
    <template class="v-window v-item-group theme--light v-tabs-items" v-if="entity === 'systemPhrases'">
      <v-text-field label="Поиск" v-model="searchText"/>
      <vue-excel-editor v-model="searchableSystemPhrases" ref="grid" :autocomplete-count=100 width="900" @cell-click="cellClickHandler('systemPhrases', $event)" @update="systemPhrasesUpdate" @delete="deleteItemHandler">
        <vue-excel-column field="original" label="Оригинал"/>
        <vue-excel-column field="russian" label="Русский"/>
        <vue-excel-column field="ru_description" label="Описание на русском"/>
        <vue-excel-column field="english" label="Английский"/>
        <vue-excel-column field="eng_description" label="Описание на английском"/>
      </vue-excel-editor>
    </template>
    <div class="left-sidebar">
      <v-btn @click="addNewItem" color="green">Добавить</v-btn>
      <v-btn @click="undo" color="orange">Отменить</v-btn>
      <v-btn @click="saveItems" color="primary">Сохранить</v-btn>
      <v-btn @click="deleteItems" color="error">Удалить</v-btn>
      <v-btn to="/" color="primary">В админку</v-btn>
      <v-btn color="green" v-if="preview_url" :href="preview_url" target="_blank">
        <div>
          <v-row align="center" justify="center">
            <v-icon>
              mdi-eye
            </v-icon>
          </v-row>
          <v-row>
            Предпросмотр
          </v-row>
        </div>
      </v-btn>
      <hr>
      <template v-if="showEditCellButton">
        <v-btn
          class="btn btn-primary"
          @click="editCellModalHandler"
          :disabled="!isCellEditorValueValid"
        >
          Редактировать<br>
          ячейку
        </v-btn>
      </template>
    </div>
    <v-dialog persistent :value="isLoading" width="300">
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Загрузка
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Редактирование ячейки</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <template v-if="dialog">
                  <tip-tap v-model="cellEditor.value" />
                </template>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="saveCellEditorHandler"
          >
            Сохранить
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="dialog = false"
          >
            Отменить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import TipTap from '../../../components/editor/TipTap.vue';

export default {
  components: { TipTap },
  name: "ExcelV2",
  created() {
    const tab = this.$route.query.tab;
    const collectionItem = this.$route.query.collectionItem
    const entity = this.$route.query.entity

    if (tab) {
      this.currentTab = Number(tab);
      this.currentTitle = this.titles[this.currentTab];
    }

    if (collectionItem) {
      this.isCollectionItems = true;
      this.currentCollectionItem = Number(collectionItem)
      this.fetchCollectionItems(Number(collectionItem));
      this.currentTitle = this.titles[this.currentCollectionItem];
    }

    if (entity === 'products') {
      this.entity = 'products';
      this.currentTitle = this.titles[this.entity];
      this.fetchProducts();
    }

    if (entity === 'procedures') {
      this.entity = 'procedures';
      this.currentTitle = this.titles[this.entity];
      this.fetchProcedures();
    }

    if (entity === 'partners') {
      this.entity = 'partners';
      this.currentTitle = this.titles[this.entity];
      this.fetchPartners();
    }

    if (entity === 'bestPractices') {
      this.entity = 'bestPractices';
      this.currentTitle = this.titles[this.entity];
      this.fetchBestPractices();
    }

    if (entity === 'systemPhrases') {
      this.entity = 'systemPhrases';
      this.currentTitle = this.titles[this.entity];
      this.fetchSystemPhrases();
    }
  },
  data: () => ({
    currentTab: 0,
    dialog: false,
    currentCollectionItem: null,
    entity: '',
    cellEditor: {
      showEditor: false,
      entity: '',
      value: '',
      rowPos: null,
      rowIndex: null,
      colName: '',
    },
    searchText: '',
    isCollectionItems: false,
    titles: {
      products: "Продукты",
      procedures: "Процедуры",
      partners: "Партнеры",
      bestPractices: 'Лучшие практики',
      systemPhrases: 'Системные фразы',
      1: "Тип продукта",
      2: "Для кого",
      3: "Возраст",
      4: "Использование",
      5: "SKIN CONCERN",
      6: "SERIES",
      7: "Часть тела",
      8: "Показания",
      9: "Кому подходит",
      10: "Результат",
      11: "Время проведения процедуры",
      12: "Курс процедур",
      13: "Кратность применения",
      14: "Линии",
      15: "Единицы измерения",
      18: "Программы",
    },
    currentTitle: "",
    toUpdate: [],
    toDelete: [],
    searchableCollections: [],
    searchableProducts: [],
    searchableProcedures: [],
    searchablePartners: [],
    searchableBestPractices: [],
    searchableSystemPhrases: [],
    preview_url: null,
  }),
  computed: {
    ...mapGetters({
      isLoading: 'ExcelV2/isLoading',
      collectionItems: 'ExcelV2/collectionItems',
      productOptions: "ExcelV2/productsOptions",
      getProducts: "ExcelV2/products",
      getProcedures: "ExcelV2/procedures",
      getPartners: "ExcelV2/partners",
      getBestPractices: "ExcelV2/bestPractices",
      proceduresOptions: "ExcelV2/proceduresOptions",
      partnersOptions: "ExcelV2/partnersOptions",
      bestPracticesOptions: "ExcelV2/bestPracticesOptions",
      getSystemPhrases: "ExcelV2/systemPhrases",
    }),
    tabs() {
      let defaultTabs = [
          {
            title: "Партнеры",
            buttons: [
              {
                title: 'Партнеры',
                query: '&entity=partners'
              },
              {
                title: 'Таксономия',
                query: '&collectionItem=16'
              },
              {
                title: 'Вид партнера',
                query: '&collectionItem=112',
              }
            ]
          }
        ]

      if (process.env.VUE_APP_LEGASY_FOLLRED == 'true') {
        defaultTabs.push({
          title: "Процедуры",
          buttons: [
              {
                title: 'Процедуры',
                query: '&entity=procedures'
              },
            ],
          });
        defaultTabs.push({
            title: 'Продукты',
            buttons: [
              {
                title: 'Продукты',
                query: '&entity=products'
              },
              {
                title: 'Тип продукта',
                query: '&collectionItem=1',
              },
              {
                title: 'Для кого',
                query: '&collectionItem=2',
              },
              {
                title: 'Возраст',
                query: '&collectionItem=3',
              },
              {
                title: 'Использование',
                query: '&collectionItem=4',
              },
              {
                title: 'SKIN CONCERN',
                query: '&collectionItem=5',
              },
              {
                title: 'SERIES',
                query: '&collectionItem=6',
              },
              {
                title: 'Часть тела',
                query: '&collectionItem=7',
              },
              {
                title: "Линия",
                query: "&collectionItem=14"
              },
              {
                title: "Единица измерения",
                query: "&collectionItem=15"
              }
            ]
        });
      }

      if (process.env.VUE_APP_LEGASY_MINFIN == 'true') {
        defaultTabs.push(
          {
            title: 'Лучшие практики',
            buttons: [
              {
                title: 'Лучшие практики',
                query: '&entity=bestPractices'
              }
            ]
          }
        )
      }

      defaultTabs.push({
        title: 'Системные фразы',
        buttons: [
          {
            title: 'Системные фразы',
            query: '&entity=systemPhrases',
          }
        ]
      })

      return defaultTabs;
    },
    getCollectionItems: {
      get() {
        return this.collectionItems;
      },
      set(val) {
        this.setCollectionItems(val);
      }
    },
    products: {
      get() {
        return this.getProducts;
      },
      set(val) {
        this.setProducts(val);
      }
    },
    procedures: {
      get() {
        return this.getProcedures;
      },
      set(val) {
        this.setProcedures(val);
      }
    },
    partners: {
      get() {
        return this.getPartners;
      },
      set(val) {
        this.setPartners(val);
      }
    },
    bestPractices: {
      get() {
        return this.getBestPractices;
      },
      set(val) {
        this.setBestPractices(val);
      }
    },
    systemPhrases: {
      get() {
        return this.getSystemPhrases;
      },
      set(val) {
        this.setSystemPhrases(val);
      }
    },
    isOptionsLoaded() {
      return Object.keys(this.productOptions).length > 0 ||
        Object.keys(this.partnersOptions).length > 0 || Object.keys(this.bestPractices).length > 0;
    },
    showEditCellButton() {
      return this.cellEditor.rowIndex !== null && this.cellEditor.colName;
    },
    isCellEditorValueValid() {
      return typeof this.cellEditor.value === 'string';
    },
    isLegasyFollred() {
      return process.env.VUE_APP_LEGASY_FOLLRED == 'true';
    }
  },
  methods: {
    ...mapMutations({
      setCollectionItems: "ExcelV2/setCollectionItems",
      setProducts: "ExcelV2/setProducts",
      setProcedures: "ExcelV2/setProcedures",
      setPartners: "ExcelV2/setPartners",
      setBestPractices: "ExcelV2/setBestPractices",
      setSystemPhrases: "ExcelV2/setSystemPhrases",
    }),
    ...mapActions({
      fetchCollectionItems: "ExcelV2/fetchCollectionItems",
      saveTaxonomy: "ExcelV2/saveCollectionItems",
      saveProducts: "ExcelV2/saveProducts",
      fetchProducts: "ExcelV2/fetchProducts",
      fetchProcedures: "ExcelV2/fetchProcedures",
      saveProcedures: "ExcelV2/saveProcedures",
      fetchPartners: "ExcelV2/fetchPartners",
      savePartners: "ExcelV2/savePartners",
      fetchBestPractices: "ExcelV2/fetchBestPractices",
      saveBestPractices: "ExcelV2/saveBestPractices",
      fetchSystemPhrases: "ExcelV2/fetchSystemPhrases",
      saveSystemPhrases: "ExcelV2/saveSystemPhrases",
    }),
    saveCellEditorHandler() {
      if (this.cellEditor.value !== this[this.cellEditor.entity][this.cellEditor.rowIndex][this.cellEditor.colName]) {
        this[this.cellEditor.entity][this.cellEditor.rowIndex][this.cellEditor.colName] = this.cellEditor.value;
        const item = this[this.cellEditor.entity][this.cellEditor.rowIndex];
        const itemToAdd = this[this.cellEditor.entity].find(({ $id }) => $id === item.$id);
        const index = this.toUpdate.findIndex(({ $id }) => $id === item.$id);

        if (index + 1) {
          this.toUpdate[index] = itemToAdd;
        } else {
          this.toUpdate.push(itemToAdd);
        }
      }

      this.dialog = false;
    },
    editCellModalHandler() {
      if (this.isCellEditorValueValid) {
        this.dialog = true;
      }
    },
    cellClickHandler(entity, {rowPos, rowIndex, colName, record}) {
      this.cellEditor.entity = entity;
      this.cellEditor.rowPos = rowPos;
      this.cellEditor.rowIndex = rowIndex;
      this.cellEditor.colName = colName;
      this.cellEditor.record = record;
      this.cellEditor.value = this[entity][rowIndex][colName];

      if (this.isEditorsValueContainsHTML()) {
        this.cellEditor.showEditor = true;
      } else {
        this.cellEditor.showEditor = false;
      }
    },
    isEditorsValueContainsHTML() {
      if (this.isCellEditorValueValid) {
        const regExp = /<(.*?)>(.*?)<\/(.*?)>/;
        const containsHTML = this.cellEditor.value.match(regExp);

        return !!containsHTML;
      }

      return false;
    },
    getUrl(query, tab) {
      return `/partners/excelV2?tab=${tab}${query}`;
    },
    addNewItem() {
      this.$refs.grid.newRecord();
    },
    undo() {
      this.$refs.grid.undoTransaction();
    },
    saveItems() {
      if (this.isCollectionItems) {
        this.saveTaxonomy({
          toDelete: this.toDelete,
          toUpdate: this.toUpdate,
          taxonomyId: this.currentCollectionItem,
        })
      }
      if (this.entity === 'products') {
        this.saveProducts({
          toUpdate: this.toUpdate,
          toDelete: this.toDelete,
        });

        setTimeout(() => {
          this.fetchProducts();
        },1000);
      }
      if (this.entity === 'procedures') {
        this.saveProcedures({
          toUpdate: this.toUpdate,
          toDelete: this.toDelete,
        });

        setTimeout(() => {
          this.fetchProcedures();
        },1000);
      }
      if (this.entity === 'partners') {
        this.savePartners({
          toUpdate: this.toUpdate,
          toDelete: this.toDelete,
        });

        setTimeout(() => {
          this.fetchPartners();
        },1000);
      }
      if (this.entity === 'bestPractices') {
        this.saveBestPractices({
          toUpdate: this.toUpdate,
          toDelete: this.toDelete,
        });
        setTimeout(() => {
          this.fetchBestPractices();
          },1000);
      }

      if (this.entity === 'systemPhrases') {
        this.saveSystemPhrases({
          toUpdate: this.toUpdate,
          toDelete: this.toDelete,
        }).then(() => {
          this.fetchSystemPhrases()
        });
      }

      this.toUpdate = [];
      this.toDelete = [];
    },
    deleteItemHandler(records) {
      records.forEach((item) => {
        if (item.id) {
          this.toDelete.push(item.id);
        } else {
          const index = this.toUpdate.findIndex(updatedItem => updatedItem.$id === item.$id);
          if (index + 1) this.toUpdate.splice(index, 1);
        }
      })
    },
    deleteItems() {
      this.$refs.grid.deleteSelectedRecords();
    },
    collectionUpdated(items) {
      items.forEach((item) => {
        const itemToAdd = this.collectionItems.find((collection) => collection.$id === item.$id);
        const index = this.toUpdate.findIndex(({$id}) => $id === item.$id);

        if (index +1) {
          this.toUpdate[index] = itemToAdd;
        } else {
          this.toUpdate.push(itemToAdd);
        }
      })
    },
    productsUpdated(items) {
      items.forEach((item) => {
        const itemToAdd = this.products.find((product) => product.$id === item.$id);
        const index = this.toUpdate.findIndex(({$id}) => $id === item.$id);

        if (index + 1) {
          this.toUpdate[index] = itemToAdd;
        } else {
          this.toUpdate.push(itemToAdd);
        }
      })
    },
    proceduresUpdated(items) {
      items.forEach((item) => {
        const itemToAdd = this.procedures.find((procedure) => procedure.$id === item.$id);
        const index = this.toUpdate.findIndex(({$id}) => $id === item.$id);

        if (index + 1) {
          this.toUpdate[index] = itemToAdd;
        } else {
          this.toUpdate.push(itemToAdd);
        }
      })
    },
    partnersUpdated(items) {
      items.forEach((item) => {
        const itemToAdd = this.partners.find((partner) => partner.$id === item.$id);
        const index = this.toUpdate.findIndex(({$id}) => $id === item.$id);

        if (index + 1) {
          this.toUpdate[index] = itemToAdd;
        } else {
          this.toUpdate.push(itemToAdd);
        }
      })
    },
    bestPracticesUpdated(items) {
      items.forEach((item) => {
        const itemToAdd = this.bestPractices.find((bestPractices) => bestPractices.$id === item.$id);
        const index = this.toUpdate.findIndex(({$id}) => $id === item.$id);

        if (index + 1) {
          this.toUpdate[index] = itemToAdd;
        } else {
          this.toUpdate.push(itemToAdd);
        }
      })
    },
    systemPhrasesUpdate(items) {
      items.forEach((item) => {
        const itemToAdd = this.systemPhrases.find((procedure) => procedure.$id === item.$id);
        const index = this.toUpdate.findIndex(({$id}) => $id === item.$id);

        if (index + 1) {
          this.toUpdate[index] = itemToAdd;
        } else {
          this.toUpdate.push(itemToAdd);
        }
      })
    },
    phoneToText(val) {
      if (val) {
        return val.replace(/(.)(...)(...)(..)(..)$/, '+$1-$2-$3-$4-$5');
      }

      return val;
    },
    search(searchable, string, original) {
      if (string) {
        this[searchable] = this[original].filter((item) => {
          let condition = false;

          for (var key in item) {
            condition = condition || (String(item[key]).toLowerCase().indexOf(string.toLowerCase()) !== -1);
          }

          return condition;
        })
      } else {
        this[searchable] = this[original];
      }
    }
  },
  watch: {
    getCollectionItems: {
      handler(val) {
        this.searchableCollections = val;
        this.search('searchableCollections', this.searchText, 'collectionItems', 'title')
      }
    },
    products: {
      handler(val) {
        this.searchableProducts = val;
        this.search('searchableProducts', this.searchText, 'products', 'sku')
      }
    },
    procedures: {
      handler(val) {
        this.searchableProcedures = val;
        this.search('searchableProcedures', this.searchText, 'procedures', 'title')
      }
    },
    partners: {
      handler(val) {
        this.searchablePartners = val;
        this.search('searchablePartners', this.searchText, 'partners', 'title')
      }
    },
    bestPractices: {
      handler(val) {
        this.searchableBestPractices = val;
        this.search('searchableBestPractices', this.searchText, 'bestPractices', 'title')
      }
    },
    systemPhrases: {
      handler(val) {
        this.systemPhrases = val;
        this.search('searchableSystemPhrases', this.searchText, 'systemPhrases', 'russian');
      }
    },
    cellEditor: {
      handler(val) {
        this.preview_url = val.record?.preview_url;
      },
      deep: true,
      immediate: true,
    },
    //currentTab: {
      //handler(val) {
        //this.$router.push({ name: 'partners-excel', query: { tab: val, entity: this.tabs[val]['buttons'][0]['query'].split('=').pop() } }).then(() => {
          //window.location.reload();
        //})
      //}
    //}
  }
}
</script>

<style lang="scss">
.excel {
  padding-left: 160px;

  .tab-inner-wrap {
    display: flex;
    padding: 10px;

    .remove-panel {
      display: flex;
      flex-direction: column;
      padding-top: 29px;

      button {
        width: 24px;
        height: 24px;
        background: red;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;

        &::after {
          transform: rotate(45deg);
        }

        &::before {
          transform: rotate(-45deg);
        }

        &::after,
        &::before {
          content: "";
          background: #fff;
          display: block;
          position: absolute;
          width: 2px;
          height: 14px;
        }
      }
    }
  }


  .vue-excel-editor {
    display: block !important;
    max-width: 100% !important;

    .component-content {
      max-width: 100% !important;
    }

    #systable {
      width: 100% !important;
    }
  }

  .left-sidebar {
    display: flex;
    padding: 50px 20px;
    flex-direction: column;
    position: fixed;
    border-right: 1px solid #d3d3d3;
    max-width: 160px;
    bottom: 0;
    top: 0;
    left: 0;

    .v-btn {
      margin-bottom: 10px;
    }
  }
}
</style>
