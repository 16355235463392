<template>
  <div class="text-center">
    <v-dialog
      v-model="popup"
      width="90%"
      persistent
      >
      <v-toolbar color="primary" dark>
          <v-spacer/>
          <v-btn icon small @click="popup = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
      </v-toolbar>
      <v-card>
        <div class="widget-content">
          <pre>
            Подключение нашего скрипта, ставим после тега &lt;body&gt;:
          </pre>
          <pre class="code">
            &lt;script type="text/javascript" charset="utf-8"&gt;
                        (function() {
                        window.sc = document.createElement('script'); sc.type = 'text/javascript'; sc.async = false;

                        var timestamp = new Date().getTime();
                        sc.src = '//school.pryamaya.ru//widgets/widget-courses/dist/main.js?' + timestamp; sc.charset = 'utf-8';
                        var s = document.body; s.appendChild(sc);

                        }());
            &lt;/script&gt;

              {{text_p1}}

          </pre>

          <pre>
            &lt;div class="widget-courses"&gt;&lt;/div&gt;
                    &lt;script type="text/javascript" charset="utf-8"&gt;
                      var data1 = '';
                      var data2 = '';
                      var partner = '{{partner}}';
          </pre>
          <pre>
                  sc.addEventListener('load', function() {
                    window.widgetCourses = new WidgetCourses({
                      parentNodeSelector: '.widget-courses',
                    });

                    widgetCourses.render(data1, data2, partner);
                  });
            &lt;/script&gt;
          </pre>
          <p>
              После применения фильтров виджет должен отобразить новые курсы. Для этого нужно вызвать функцию  renderCardList и передать туда значения фильтров по предмету и преподавателю через запятую.
          </p>
          <p>
                // widgetCourses.renderCardList({data1, data2}); - перерисовывает карточки на основании переданных данных
          </p>
          <p><a href=" https://chimera.ink/test_widget.html">пример</a></p>


        </div>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  //props: ["value"],
  props: {
    value: {
      type: Boolean ,
      default: false,
    },
    partner: {
      type: String,
      default: ""
    },
  },
  computed: {
    popup: {
      get (){
        return this.value;
      },
      set (value){
        this.$emit('input',value);
      },
    },
  },
  data: () => ({
    text_p1: null,
    text_p2: null,
  }),
  async created() {
    this.setText();
  },
  methods: {
    setText() {
       this.text_p1 =
        "Подключение нашего скрипта, ставим после тега <body>: ";
    }
  },
};
</script>

<style scoped>
.widget-content {
  padding: 60px;
}
</style>
