<template>
  <v-data-table
    :headers="headers"
    :items="userActivities"
    :loading="loading"
    :options.sync="options"
    :server-items-length="total"
  >
    <template v-slot:item.id="{ item }">
      <v-chip pill>{{ item.id }}</v-chip>
    </template>
    <template v-slot:item.action="{ item }">
      {{ activityTitle[item.action] }}
    </template>
    <template v-slot:item.entity_id="{ item }">
      {{ item.entity.title }}
    </template>
  </v-data-table>
</template>

<script>
import axiosInstance from "@/axiosInstance";

export default {
  name: "UserActivityForm",
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      searchPermission: "",
      searchPerson: '',
      userActivities: [],
      loading: false,
      headers: [
        {text: 'Id', value: 'id'},
        {text: 'Действие', value: 'action'},
        {text: 'Объект', value: 'entity_type'},
        {text: 'Заголовок', value: 'entity_id'},
        {text: 'Дата обновления', value: 'updated_at'},
      ],
      options: {
        sortBy: ['created_at'],
        sortDesc: [true],
      },
      total: 0,
      activityTitle: [
        'Просмотр',
        'Сдан тест',
        'Экзамен начат',
        'Экзамен перезапущен',
        'Экзамен провален',
        'Экзамен сдан',
        'Скачан конспект',
      ],
    }
  },
  watch: {
    options: {
      handler() {
        this.getUserActivities();
      }
    }
  },
  created() {
    this.getUserActivities();
  },
  methods: {
    async getUserActivities() {
      this.loading = true;
      const sortDesc = this.options.sortDesc.length ? (this.options.sortDesc[0] ? 'desc' : 'asc') : 'desc';
      const sortBy = this.options.sortBy.length ? this.options.sortBy : 'id';
      const sort = `${sortBy}|${sortDesc}`;

      const userActivities = (await axiosInstance.get(`/admin/users/${this.$route.params.id}/get-activities/?sort=${sort}&page=${this.options.page}`)).data;
      this.userActivities = userActivities.data;
      this.loading = false;
      this.total = userActivities.meta.total;
    },
  },
}
</script>
