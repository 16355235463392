<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <h1>Редактирование лендинга "{{ landing.title }}"</h1>
          </v-col>
        </v-row>
        <v-tabs
          v-model="tab"
          background-color="transparent"
          grow
          class="mb-5"
        >
          <v-tab>
            Основная информация
          </v-tab>
          <v-tab>
            Дочерние элементы
          </v-tab>
          <v-tab>
            SEO
          </v-tab>
          <v-tab>
            SMM
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <landing-form
              :landing="landing"
              :errors="errors"
              @onSubmit="submit"
              :is-edit="true"
            />
          </v-tab-item>
          <v-tab-item>
            <landing-children
              :landing="landing"
              :errors="errors"
              @onSubmit="submit"
            />
          </v-tab-item>
          <v-tab-item>
            <seo-form-separate
              :entity="landing"
              :errors="errors"
              @onSubmit="submit"
            />
          </v-tab-item>
          <v-tab-item>
            <smm-form-separate
              :entity="landing"
              :errors="errors"
              @onSubmit="submit"
            />
          </v-tab-item>
        </v-tabs-items>
        <v-snackbar
          v-model="snackbarSuccess"
          :timeout="timeout"
          color="success"
        >
          Сохранено

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarSuccess = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar
          v-model="snackbarError"
          :timeout="timeout"
          color="error"
        >
          {{ this.errors }}

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarError = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import LandingForm from '../../../components/landings/LandingForm';
import LandingChildren from "../../../components/landings/LandingChildren";
import {Landing} from '../../../models/landing';
import {BlockEntity} from '../../../models/blockEntity';
import SeoFormSeparate from "../../../components/SEOFormSeparate";
import SmmFormSeparate from "../../../components/SMMFormSeparate";

export default {
  name: 'Edit',
  components: {
    LandingForm,
    LandingChildren,
    SeoFormSeparate,
    SmmFormSeparate,
  },
  data: () => ({
    landing: {},
    errors: {},
    tab: 0,
    snackbarSuccess: false,
    snackbarError: false,
    timeout: 2000,
    page: {
      title: "Редактирование лендинга"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Лендинги",
        disabled: false,
        to: '/landings'
      },
      {
        text: "Редактирование",
        disabled: true,
        to: '/edit'
      }
    ],
  }),
  created() {
    this.find();
  },
  methods: {
    async submit({form, mediaIds, isNeedRedirect}) {
      try {
        this.errors = {};

        if (mediaIds) {
          form.mediaIds = mediaIds;
        }

        const currentForm = JSON.parse(JSON.stringify(form));
        // const blocks = currentForm.blocks;

        delete currentForm.blocks;

        await Landing.$query().update(currentForm.id, currentForm);

        // for (let block of blocks) {
        //   await this.saveBlock(block, currentForm);
        // }
        //
        // if (form.deleteIds && form.deleteIds.length > 0) {
        //   await this.deleteBlocks(form.deleteIds);
        // }

        if (!mediaIds || mediaIds.length === 0) {
          await this.find();
        }

        this.snackbarSuccess = true;

        if (isNeedRedirect) {
          this.$router.push({ name: 'landings-index' });
        }
      } catch (e) {
        this.snackbarError = true;
        if (e.response?.data) {
          this.errors = e.response.data.errors
        }

        throw e;
      }
    },
    async saveBlock(block, landing) {
      const blockEntity = {...block};
      blockEntity.id = typeof blockEntity.id === 'number' ? blockEntity.id : null;
      blockEntity.structure = block;
      blockEntity.entity = 'Modules\\Landings\\Entities\\Landing';
      blockEntity.entity_id = landing.id;
      if (blockEntity.id) {
        await BlockEntity.$query().update(blockEntity.id, blockEntity);
      } else {
        await BlockEntity.$query().store(blockEntity);
      }
      delete block.id
    },
    async deleteBlocks(ids) {
      for (const id of ids) {
        await BlockEntity.$query().destroy(id);
      }
    },
    async find() {
      const landing = await Landing.$query().find(this.$route.params.id);
      this.landing = landing.$attributes;
    }
  },
};
</script>
