import {Catalog} from "../../../../models/catalog";
import {SortDirection} from "@tailflow/laravel-orion/lib/drivers/default/enums/sortDirection";
import axiosInstance from "../../../../axiosInstance";

export default {
  namespaced: true,
  state: {
    fetchedListing: [],
    listingTotalItems: 0,
    isLoading: false,
    fetchedDetail: {},
    dynamicFields: []
  },
  getters: {
    getFetchedListing: (state) => state.fetchedListing,
    getListingTotalItems: (state) => state.listingTotalItems,
    getIsLoading: (state) => state.isLoading,
    getFetchedDetail: (state) => state.fetchedDetail,
    getDynamicFields: (state) => state.dynamicFields,
  },
  mutations: {
    setFetchedListing(state, val) {
      state.fetchedListing = val;
    },
    setListingTotalItems(state, val) {
      state.listingTotalItems = val;
    },
    setIsLoading(state, val) {
      state.isLoading = val;
    },
    setFetchedDetail(state, val) {
      state.fetchedDetail = val;
    },
    setDynamicFields(state, val) {
      state.dynamicFields = val;
    }
  },
  actions: {
    fetchListing({ commit, dispatch }, { options, search, only_trashed }) {
      commit('setIsLoading', true);

      const { sortBy, sortDesc, page, itemsPerPage } = options;

      let queryBuilder = Catalog
        .$query()
        .lookFor(search);

      if (only_trashed) {
        queryBuilder.onlyTrashed();
      }

      if (sortBy[0]) {
        queryBuilder.sortBy(sortBy[0], sortDesc[0] ? SortDirection.Desc : SortDirection.Asc)
      }

      queryBuilder.search(itemsPerPage, page).then((response) => {
        if (response.length > 0) {
          commit('setListingTotalItems', response[0].$response.data.meta.total);
        } else {
          commit('setListingTotalItems', 0);
        }
        commit('setFetchedListing', response.map((item) => item.$attributes));
      }).catch(() => {
        dispatch("snackbar/showUnknownError", null, { root: true })
      }).finally(() => {
        commit("setIsLoading", false);
      })
    },
    async fetchDetail({ commit, dispatch }, id) {
      commit('setIsLoading', true);

      Catalog.$query().find(id).then((response) => {
        commit('setFetchedDetail', response.$attributes);
      }).catch(() => {
        dispatch('snackbar/showUnknownError', null, { root: true });
      }).finally(() => {
        commit('setIsLoading', false);
      })
    },
    async destroyItem({ commit, dispatch }, { id, isForce } ) {
      commit('setIsLoading', true);

      await Catalog.$query().destroy(id, isForce).then(() => {
        dispatch('snackbar/showSuccessfullyDelete', null, { root: true });
      }).catch(() => {
        dispatch('snackbar/showDeleteError', null, { root: true })
      }).finally(() => {
        commit('setIsLoading', false);
      });
    },
    async restoreItem({ commit, dispatch }, id ) {
      commit('setIsLoading', true);

      await Catalog.$query().restore(id).then(() => {
        dispatch('snackbar/showSuccessfullyRestore', null, { root: true })
      }).catch(() => {
        dispatch('snackbar/showErrorRestore', null, { root: true })
      }).finally(() => {
        commit('setIsLoading', false)
      });
    },
    async storeItem({ commit, dispatch }, { form, isNeedRedirect, router }) {
      commit('setIsLoading', true)
      let catalog = null;

      Catalog.$query().store(form).then((response) => {
        catalog = response.$attributes.id;
        dispatch('snackbar/showSuccessfullySaved', null, { root: true });
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, { root: true });
      }).finally(() => {
        commit('setIsLoading', false)
        if (catalog) {
          if (isNeedRedirect) {
            router.push({ name: 'catalog-index' });
          } else {
            router.push({ name: 'catalog-edit', params: { id: catalog } });
          }
        }
      })
    },
    async updateItem({ commit, dispatch }, { form, isNeedRedirect, router }) {
      commit('setIsLoading', true);
      let hasErrors = false;

      Catalog.$query().update(form.id, form).then((response) => {
        commit('setFetchedDetail', response.$attributes);
        dispatch("snackbar/showSuccessfullySaved", null, { root: true })
      }).catch(() => {
        hasErrors = true;
        dispatch("snackbar/showUnknownError", null, { root: true });
      }).finally(() => {
        commit('setIsLoading', false);

        if (!hasErrors) {
          if (isNeedRedirect) {
            router.push({ name: 'catalog-index' });
          }
        }
      })
    },
    async regeneratePage({ dispatch }, id ) {
      await axiosInstance.get(`module/catalog/${id}/regenerate-listing-page`).then(() => {
        dispatch('snackbar/showSnackbar', { color: 'green', text: 'Страница успешно перегенерирована' }, { root: true })
      }).catch(() => {
        dispatch('snackbar/showUnknownError', null, { root: true });
      })
    },
    async fetchDynamicFields({ dispatch, commit }, id) {
      commit('setIsLoading', true);

      await axiosInstance.get(`module/admin/catalog/${id}/dynamic-fields`).then((resp) => {
        commit('setDynamicFields', resp.data);
      }).catch(() => {
        dispatch('snackbar/showUnknownError', null, { root: true });
      }).finally(() => {
        commit('setIsLoading', false);
      })
    },
    async storeDynamicFields({ dispatch, commit }, { id, toUpdate, toDelete, toStore }) {
      commit('setIsLoading', true);

      await axiosInstance.post(`module/admin/catalog/${id}/dynamic-fields`, {
        update: toUpdate,
        "delete": toDelete,
        store: toStore
      }).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, { root: true });
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, { root: true })
      }).finally(() => {
        commit('setIsLoading', true);
      })
    }
  }
}
