<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="items"
              :options.sync="options"
              :server-items-length="total"
              class="contact-listing-app"
              >
              <template v-slot:top>
                <v-toolbar flat class="mb-8">
                  <v-row>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Поиск..."
                        filled
                        background-color="transparent"
                        hide-details
                        name="title"
                        >
                        <v-btn v-if="search"
                               class="reset-button"
                               slot="append"
                               icon
                               @click="search = null"
                               color="red"
                               >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-spacer/>
                  <v-dialog max-width="700">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="primary"
                        to="/block-groups/create"
                        dark
                        class="mb-2 text-capitalize"
                        >
                        <v-icon class="mr-2">mdi-group</v-icon>
                        Создать группу
                      </v-btn>
                    </template>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:item.id="{item}">
                <v-chip pill>{{ item.id }}</v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  :href="editItem(item)"
                  icon
                >
                  <v-icon small color="primary">
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-icon
                  small
                  color="red"
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { BlockGroup } from '../../../../models/blockGroup';
import axiosInstance from "../../../../axiosInstance";

export default {
  name: 'Index',
  data: () => ({
    search: '',
    options: {
      sortBy: ['id'],
      sortDesc: [true],
    },
    total: 0,
    headers: [
      {
        text: 'Id',
        value: 'id',
      },
      { text: 'Заголовок', value: 'title' },
      { text: 'Дата создания', value: 'created_at' },
      { text: 'Дата обновления', value: 'updated_at' },
      { text: '', value: 'actions' },
    ],
    items: [],
    page: {
      title: "Группы блоков"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Группы блоков",
        disabled: true,
        to: '/block-groups'
      },
    ]
  }),
  created() {
    this.get();
  },
  methods: {
    editItem(item) {
      return this.$router.resolve({ name: 'block-groups-edit', params: { id: item.id }}).href
    },
    async deleteItem(item) {
      await BlockGroup.$query().destroy(item.id);
      await this.get();
    },
    async get() {
      // eslint-disable-next-line no-unused-vars
      const {sortBy, sortDesc, page, itemsPerPage} = this.options;
      let data = {};
      this.search ? data.search = {value: this.search} : null;
      sortBy && sortBy[0] ? data.sort = [{field: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc'}] : null;

      await axiosInstance.post(`/module/block-groups/search?limit=${itemsPerPage}&page=${page}`, data).then((response) => {
        this.items = response.data.data;
        this.total = response.data.meta.total;
      });
    },
  },
  watch: {
    search: {
      handler() {
        this.get();
      }
    },
    options: {
      handler() {
        this.get()
      },
      deep: true,
    },
  },
};
</script>

<style scoped>

</style>
