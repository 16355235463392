export default {
  namespaced: true,
  state: {
    options: []
  },
  getters: {
    getOption: (state) => (name, def) => {
      const option = state.options.find(el => {
        return el.option_key === name
      });

      if (option) {
        return option.option_value;
      }

      return def;
    }
  },
  mutations: {
    setOptions(state, options) {
      state.options = options;
    }
  },
  actions: {
    setOptionAction({ commit }, value) {
      commit('setOptions', value);
    },
  }
}
