import {FAQ} from "../../../models/FAQ";
import axiosInstance from "../../../axiosInstance";

export default {
  namespaced: true,
  state: {
    listingWithoutFilters: [],
    isSaveButtonDisabled: false,
    fetchedList: [],
    fetchedDetail: {},
  },
  getters: {
    getListingWithoutFilters: (state) => state.listingWithoutFilters,
    isSaveButtonDisabled: (state) => state.isSaveButtonDisabled,
    getFetchedList: (state) => state.fetchedList,
    getFetchedDetail: (state) => state.fetchedDetail,
  },
  mutations: {
    setListingWithoutFilters(state, val) {
      state.listingWithoutFilters = val;
    },
    setIsSaveButtonDisabled(state, val) {
      state.isSaveButtonDisabled = val;
    },
    setFetchedList(state, val) {
      state.fetchedList = val;
    },
    setFetchedDetail(state, value) {
      state.fetchedDetail = value;
    },
  },
  actions: {
    fetchListingWithoutFilters({ commit }) {
      FAQ.$query().get(100).then((response) => {
        commit('setListingWithoutFilters', response.map((item) => item.$attributes));
      })
    },
    async submitSort({commit, state, dispatch}) {
      commit('setIsSaveButtonDisabled', true);

      let formattedData = {};
      const items = state.listingWithoutFilters;

      items.forEach(function (element, index) {
        formattedData[element.id] = {position: index}
      });

      await axiosInstance.patch('faqs/batch', { resources: formattedData }).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, { root: true });
        dispatch('fetchListingWithoutFilters');
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, { root: true });
      }).finally(() => {
        commit('setIsSaveButtonDisabled', false);
      })
    },
    async fetchList({ commit }) {
      await axiosInstance.get('faqs/select-list').then((response) => {
        commit('setFetchedList', response.data.data);
      })
    },
    async submitCollections({dispatch, state}, {items}) {
      await axiosInstance.post(`/faqs/${state.fetchedDetail.id}/collection-items/sync`, {items: items}).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true})
        dispatch('fetchDetail', state.fetchedDetail.id)
      }).catch(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true});
      })
    },
    async submitMainCollections({dispatch, state}, {items}) {
      await axiosInstance.post(`/faqs/${state.fetchedDetail.id}/collection-items/make-main`, {items: items}).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true})
        dispatch('fetchDetail', state.fetchedDetail.id)
      }).catch(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true});
      })
    },
    async fetchDetail({commit}, id) {
      await FAQ.$query().find(id).then((response) => {
        commit('setFetchedDetail', response.$attributes);
      });
    },

  }
}

