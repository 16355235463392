<template>
  <v-form>
    <v-btn
      v-if="region.parent"
      class="mb-2"
      color="primary"
      :disabled="isSaveDisabled"
      :loading="isSaveDisabled"
      :href="editParent()"
      block
    >
      Редактирование родительской страницы
    </v-btn>
    <v-text-field
      v-model="form.title"
      label="Название"
      name="title"
      required
    ></v-text-field>
    <v-text-field
      v-model="form.slug"
      label="Slug"
      name="slug"
      :disabled="!(Object.keys(region).length === 0)"
    ></v-text-field>
    <v-text-field
      v-model="form.region_number"
      label="Номер региона"
      name="region_number"
      required
    />
    <a
      v-if="region.preview_url"
      :href="region.preview_url"
      target="_blank"
      class="mr-3"
    >
      {{ region.preview_url }}
    </a>
    <preview v-if="region.preview_url" :previewUrl="region.preview_url"/>
    <v-text-field
      v-model="form.index"
      label="Индекс финансовой грамотности"
      name="index"
      required
    ></v-text-field>
    <v-text-field
      v-model="form.district"
      label="Федеральный округ"
      name="district"
      required
    ></v-text-field>
    <v-text-field
      v-model="form.admin_center"
      label="Административный центр"
      name="admin_center"
      required
    ></v-text-field>
    <v-autocomplete label="Выберите партнера"
                    :items="partners"
                    item-text="title"
                    item-value="id"
                    chips
                    v-model="form.partner_id"
    >
      <template v-slot:selection="data">
        <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          close
          @click="data.select"
          @click:close="form.partner_id = null"
        >
          <v-avatar left>
            <v-img :src="data.item.image"/>
          </v-avatar>
          {{ data.item.title }}
        </v-chip>
      </template>
      <template v-slot:item="data">
        <v-list-item-avatar>
          <img :src="data.item.image">
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.title }}
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-checkbox
      v-model="form.published"
      label="Опубликовано"
      name="published"
      required
    ></v-checkbox>
    <media-widget
      class="mb-5"
      label="Анонсное изображение"
      :medias="form.preview_image"
      :is-multiple="false"
      @onChange="setPreviewImage"
    />
    <media-widget
      class="mb-1 mt-5"
      label="Герб"
      :medias="form.arms_image"
      :is-multiple="false"
      @onChange="setArmsImage"
    />
    <v-flex class="mb-2">
      <v-btn
        v-if="isEdit && isModuleBlockEnabled"
        :href="`${clientUrl}/regions/${form.slug}?edit_mode=true&admin_url=${this.$route.fullPath}`"
        color="primary"
        class="mr-2"
        @click="toBlocks"
      >
        Редактирование блоков
      </v-btn>
    </v-flex>
    <v-flex>
      <v-btn
        class="mr-4"
        color="primary"
        :disabled="isLoading"
        :loading="isLoading"
        @click="submit(true)"
      >
        Сохранить
      </v-btn>
      <v-btn
        class="mr-4"
        color="primary"
        :disabled="isLoading"
        :loading="isLoading"
        @click="submit(false)"
      >
        Сохранить и продолжить
      </v-btn>
    </v-flex>
    <media-uploader
      :loaded-medias="region.loaded_medias"
      @completeUploads="onCompleteUploads"
    />
  </v-form>
</template>

<script>
import slugify from "slugify";
import MediaUploader from "../MediaUploader";
import MediaWidget from "../MediaWidget";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'RegionForm',
  props: {
    region: {
      type: Object,
      default: () => ({})
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MediaUploader,
    MediaWidget,
  },
  data: () => ({
    form: {
      id: null,
      title: null,
      slug: null,
      region_number: null,
      preview_image: null,
      arms_image: null,
      district: null,
      admin_center: null,
      index: null,
      published: 0,
      partner_id: null,
    },
    mediaIds: [],
    clientUrl: process.env.VUE_APP_CLIENT_URL,
    isSaveDisabled: false,
  }),
  created() {
    this.fetchPartners();
    if (this.$route.params.data) {
      this.form = JSON.parse(this.$route.params.data);
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'Regions/isSaveButtonDisabled',
      partners: 'Partners/getFetchedList',
    }),
    isModuleBlockEnabled() {
      return process.env.VUE_APP_M_BLOCKS === 'true';
    }
  },
  methods: {
    ...mapActions({
      fetchPartners: 'Partners/fetchList',
    }),
    async onCompleteUploads(ids) {
      this.mediaIds = ids;
      await this.submit();
      this.mediaIds = [];
    },
    async submit(isNeedRedirect) {
      this.$emit('onSubmit', {
        form: this.form,
        mediaIds: this.mediaIds,
        isNeedRedirect: isNeedRedirect
      });
    },
    setPreviewImage(image) {
      this.form.preview_image = image;
    },
    setArmsImage(image) {
      this.form.arms_image = image;
    },
    toBlocks() {
      this.$store.dispatch('block/updatePageForm', this.form);
    },
    editParent() {
      return this.$router.resolve({ name: 'regions-edit', params: { id: this.region.parent.id } }).href;
    }
  },
  watch: {
    region: {
      handler(region) {
        if (region && Object.keys(region).length > 0) {
          this.form = region;
        }
      },
      immediate: true,
      deep: true,
    },
    'form.title': {
      handler(value) {
        if (value && Object.keys(this.region).length === 0) {
          this.form.slug = slugify(value.toLowerCase()).match(/[a-z]|[0-9]|[-]/gm).join('');
        }
      }
    },
  },
};
</script>
