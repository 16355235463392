import Index from '../../views/main/sources/Index'
import Create from '../../views/main/sources/Create'
import Edit from '../../views/main/sources/Edit';

export default [
  {
    component: Index,
    name: 'sources-index',
    path: '/sources',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'sources',
    }
  },
  {
    component: Create,
    name: 'sources-create',
    path: '/sources/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'sources',
    }
  },
  {
    component: Edit,
    name: 'sources-edit',
    path: '/sources/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'sources',
    }
  },
]
