<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <review-type-form
              :review-type="reviewType"
              :errors="errors"
              @onSubmit="submit"
            ></review-type-form>
          </v-col>
        </v-row>
        <v-snackbar
          v-model="snackbarSuccess"
          :timeout="timeout"
          color="success"
        >
          Сохранено

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarSuccess = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar
          v-model="snackbarError"
          :timeout="timeout"
          color="error"
        >
          {{ this.errors }}

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarError = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {ReviewsType} from "../../../../models/reviewsType";
import ReviewTypeForm from "../../../../components/reviews/ReviewTypeForm";

export default {
  name: 'Edit',
  components: {
    ReviewTypeForm,
  },
  data: () => ({
    reviewType: {},
    errors: {},
    snackbarSuccess: false,
    snackbarError: false,
    page: {
      title: "Редактирование типа отзыва"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Типы отзывов",
        disabled: true,
        to: '/reviews/types'
      },
      {
        text: "Редактировать",
        disabled: true,
        to: '/reviews/types/edit'
      },
    ]
  }),
  created() {
    this.find();
  },
  methods: {
    async submit({ form }) {
      try {
        this.errors = {};
        await ReviewsType.$query().update(form.id, form);
        await this.find();
        this.snackbarSuccess = true;
      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors;
          this.snackbarError = true;
        }
        throw e;
      }
    },
    async find() {
      const reviewType = await ReviewsType.$query().find(this.$route.params.id);
      this.reviewType = reviewType.$attributes;
    },
  },
};
</script>
