import NotFound from '../views/NotFound.vue'

export default {
  component: NotFound,
  name: 'not-found',
  path: '*',
  meta: {
    layout: 'main-layout'
  }
}
