import Index from '../../views/main/histories/Index'
import Show from '../../views/main/histories/Show'

export default [
  {
    component: Index,
    name: 'histories-index',
    path: '/histories',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'history'
    }
  },
  {
    component: Show,
    name: 'histories-show',
    path: '/histories/show/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'history'
    }
  },
]
