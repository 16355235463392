import {Widget} from "../../models/widgets";

export default {
  namespaced: true,
  state: {
    fetchedDetail: {},
  },
  getters: {
    getFetchedDetail: (state) => state.fetchedDetail,
  },
  mutations: {
    setFetchedDetail(state, val) {
      state.fetchedDetail = val;
    }
  },
  actions: {
    fetchDetail({ commit }, ID) {
      Widget.$query().find(ID).then((response) => {
        commit('setFetchedDetail', response.$attributes);
      })
    }
  }
}
