import Index from "../../views/main/cron/Index";
import Create from "../../views/main/cron/Create";
import Edit from "../../views/main/cron/Edit";

export default [
  {
    component: Index,
    name: 'cron-index',
    path: '/cron',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
  {
    component: Create,
    name: 'cron-create',
    path: '/cron/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
  {
    component: Edit,
    name: 'cron-edit',
    path: '/cron/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
]
