import {Model} from "@tailflow/laravel-orion/lib/model";
import {BelongsToMany} from "@tailflow/laravel-orion/lib/drivers/default/relations/belongsToMany";
import {CollectionItem} from "@/models/collectionItem";
import {Partner} from "@/models/partner";
import {Organizer} from "@/models/organizer";

export class Event extends Model<{
    title: string,
    slug: string,
    meta_title: string,
    meta_description: string,
    meta_keywords: string,
    created_at: string,
    updated_at: string,
}> {

    $resource(): string {
        return "module/events";
    }

    public collectionItems(): BelongsToMany<CollectionItem> {
        return new BelongsToMany<CollectionItem>(CollectionItem, this);
    }

    public partners(): BelongsToMany<Partner> {
        return new BelongsToMany<Partner>(Partner, this);
    }

    public organizers(): BelongsToMany<Organizer> {
        return new BelongsToMany<Organizer>(Organizer, this);
    }
}
