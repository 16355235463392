import { Model } from "@tailflow/laravel-orion/lib/model";

export class Organizer extends Model<{
    title: string,
    slug: string,
    published_at: string,
    lead: string,
    image: object,
    site_url: string,
    vk_url: string,
    fb_url: string,
    ok_url: string,
    tg_url: string,
    tt_url: string,
}> {

    $resource(): string {
        return "organizers";
    }
}
