import Index from '../../views/main/statistics/Index'

export default [
  {
    component: Index,
    name: 'statistic-index',
    path: '/statistics',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  }
]
