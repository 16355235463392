import { Model } from "@tailflow/laravel-orion/lib/model";
import {Person} from "@/models/person";
import {BelongsToMany} from "@tailflow/laravel-orion/lib/drivers/default/relations/belongsToMany";

export class Partner extends Model<{
    title: string,
    slug: string,
    published_at: string,
    lead: string,
    image: object,
    site_url: string,
    vk_url: string,
    fb_url: string,
    ok_url: string,
    tg_url: string,
    tt_url: string,
}> {

    public persons(): BelongsToMany<Person> {
        return new BelongsToMany<Person>(Person, this);
    }

    $resource(): string {
        return "partners";
    }
}
