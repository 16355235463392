import { Model } from "@tailflow/laravel-orion/lib/model";
import {BelongsTo} from "@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo";
import {Collection} from "./collection";

export class CollectionItem extends Model<{
    user_id: number,
    collection_id: number,
    title: string,
    slug: string,
    language: string,
    publish: number,
    created_at: string,
    updated_at: string,
}> {

    $resource(): string {
        return "collection-items";
    }

    public collection(): BelongsTo<Collection> {
        return new BelongsTo<Collection>(Collection, this);
    }

}
