import { Model } from "@tailflow/laravel-orion/lib/model";
import {BelongsToMany} from "@tailflow/laravel-orion/lib/drivers/default/relations/belongsToMany";
import {CollectionItem} from "@/models/collectionItem";

export class Course extends Model<{
    user_id: number,
    title: string,
    description: string,
    slug: string,
    language: string,
    created_at: string,
    updated_at: string,
}> {

    $resource(): string {
        return "module/courses";
    }

    public collectionItems(): BelongsToMany<CollectionItem> {
        return new BelongsToMany<CollectionItem>(CollectionItem, this);
    }

}
