<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <partner-resource-form
              :partner-resource="partnerResource"
              :errors="errors"
              @onSubmit="submit"
            />
          </v-col>
        </v-row>
        <v-snackbar
          v-model="snackbarSuccess"
          :timeout="timeout"
          color="success"
        >
          Сохранено

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarSuccess = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar
          v-model="snackbarError"
          :timeout="timeout"
          color="error"
        >
          {{ this.errors }}

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarError = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import PartnerResourceForm from '../../../components/partnerResources/PartnerResourceForm';
import { PartnerResource } from '../../../models/partnerResource';

export default {
  name: 'Edit',
  components: { PartnerResourceForm },
  data: () => ({
    partnerResource: {},
    errors: {},
    snackbarSuccess: false,
    snackbarError: false,
    timeout: 2000,
    page: {
      title: "Редактировать партнерские ресурсы"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Партнерские ресурсы",
        disabled: true,
        to: '/partner-resources'
      },
      {
        text: "Редактирование",
        disabled: true,
        to: '/partner-resources/edit'
      },
    ]
  }),
  created() {
    this.find();
  },
  methods: {
    async submit(form) {
      try {
        this.errors = {};
        await PartnerResource.$query().update(form.id, form);
        this.snackbarSuccess = true;
        await this.find();
      } catch (e) {
        this.snackbarError = true;
        if (e.response?.data) {
          this.errors = e.response.data.errors;
        }
        throw e;
      }
    },
    async find() {
      this.partnerResource = (await PartnerResource.$query().find(this.$route.params.id)).$attributes;
    }
  },
  watch: {
    'partnerResource.title': {
      handler(newTitle) {
        this.page.title +=  ` "${newTitle}"`;
      }
    },
  }
};
</script>
