<template>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        :stroke="iconColor"
        :width="width"
        :height="height"
      >
        <template v-if="iconName === 'world-download'">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M21 12a9 9 0 1 0 -9 9" />
          <path d="M3.6 9h16.8" />
          <path d="M3.6 15h8.4" />
          <path d="M11.578 3a17 17 0 0 0 0 18" />
          <path d="M12.5 3c1.719 2.755 2.5 5.876 2.5 9" />
          <path d="M18 14v7m-3 -3l3 3l3 -3" />
        </template>

        <template v-if="iconName === 'mist'">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M5 5h3m4 0h9" />
          <path d="M3 10h11m4 0h1" />
          <path d="M5 15h5m4 0h7" />
          <path d="M3 20h9m4 0h3" />
        </template>

        <template v-if="iconName === 'align-left'">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <line x1="4" y1="6" x2="20" y2="6" />
          <line x1="4" y1="12" x2="14" y2="12" />
          <line x1="4" y1="18" x2="18" y2="18" />
        </template>

        <template v-if="iconName === 'align-center'">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <line x1="4" y1="6" x2="20" y2="6" />
          <line x1="8" y1="12" x2="16" y2="12" />
          <line x1="6" y1="18" x2="18" y2="18" />
        </template>

        <template v-if="iconName === 'align-right'">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <line x1="4" y1="6" x2="20" y2="6" />
          <line x1="10" y1="12" x2="20" y2="12" />
          <line x1="6" y1="18" x2="20" y2="18" />
        </template>

        <template v-if="iconName === 'multiplier-2x'">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M14 16l4 -4" />
          <path d="M18 16l-4 -4" />
          <path d="M6 10a2 2 0 1 1 4 0c0 .591 -.417 1.318 -.816 1.858l-3.184 4.143l4 0" />
        </template>

        <template v-if="iconName === 'viewport-wide'">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M10 12h-7l3 -3m0 6l-3 -3" />
          <path d="M14 12h7l-3 -3m0 6l3 -3" />
          <path d="M3 6v-3h18v3" />
          <path d="M3 18v3h18v-3" />
        </template>

        <template v-if="iconName === 'circle-0'">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M12 8a2 2 0 0 1 2 2v4a2 2 0 1 1 -4 0v-4a2 2 0 0 1 2 -2z" />
          <circle cx="12" cy="12" r="9" />
        </template>

        <template v-if="iconName === 'circle-1'">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M12 16v-8l-2 2" />
          <circle cx="12" cy="12" r="9" />
        </template>

        <template v-if="iconName === 'circle-2'">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M10 10a2 2 0 1 1 4 0c0 .591 -.417 1.318 -.816 1.858l-3.184 4.143l4 0" />
          <circle cx="12" cy="12" r="9" />
        </template>

        <template v-if="iconName === 'circle-3'">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M12 12a2 2 0 1 0 -2 -2" />
          <path d="M10 14a2 2 0 1 0 2 -2" />
          <circle cx="12" cy="12" r="9" />
        </template>

        <template v-if="iconName === 'circle-4'">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M13 16v-8l-4 6h5" />
          <circle cx="12" cy="12" r="9" />
        </template>

        <template v-if="iconName === 'circle-5'">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M10 16h2a2 2 0 1 0 0 -4h-2v-4h4" />
          <circle cx="12" cy="12" r="9" />
        </template>

        <template v-if="iconName === 'circle-6'">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <circle cx="12" cy="14" r="2" />
          <path d="M14 10a2 2 0 1 0 -4 0v4" />
          <circle cx="12" cy="12" r="9" />
        </template>

        <template v-if="iconName === 'circle-7'">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M10 8h4l-2 8" />
          <circle cx="12" cy="12" r="9" />
        </template>

        <template v-if="iconName === 'circle-check'">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <circle cx="12" cy="12" r="9" />
          <path d="M9 12l2 2l4 -4" />
        </template>

        <template v-if="iconName === 'circle-off'">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M20.042 16.045a9 9 0 0 0 -12.087 -12.087m-2.318 1.677a9 9 0 1 0 12.725 12.73" />
          <path d="M3 3l18 18" />
        </template>

        <template v-if="iconName === 'circle'">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <circle cx="12" cy="12" r="9" />
        </template>

        <template v-if="iconName === 'polaroid'">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <rect x="4" y="4" width="16" height="16" rx="2" />
          <line x1="4" y1="16" x2="20" y2="16" />
          <path d="M4 12l3 -3c.928 -.893 2.072 -.893 3 0l4 4" />
          <path d="M13 12l2 -2c.928 -.893 2.072 -.893 3 0l2 2" />
          <line x1="14" y1="7" x2="14.01" y2="7" />
        </template>

        <template v-if="iconName === 'photo'">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <line x1="15" y1="8" x2="15.01" y2="8" />
          <rect x="4" y="4" width="16" height="16" rx="3" />
          <path d="M4 15l4 -4a3 5 0 0 1 3 0l5 5" />
          <path d="M14 14l1 -1a3 5 0 0 1 3 0l2 2" />
        </template>

        <template v-if="iconName === 'square-off'">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M8 4h10a2 2 0 0 1 2 2v10m-.584 3.412a1.994 1.994 0 0 1 -1.416 .588h-12a2 2 0 0 1 -2 -2v-12c0 -.552 .224 -1.052 .586 -1.414" />
          <path d="M3 3l18 18" />
        </template>

        <template v-if="iconName === 'blockquote'">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M6 15h15" />
          <path d="M21 19h-15" />
          <path d="M15 11h6" />
          <path d="M21 7h-6" />
          <path d="M9 9h1a1 1 0 1 1 -1 1v-2.5a2 2 0 0 1 2 -2" />
          <path d="M3 9h1a1 1 0 1 1 -1 1v-2.5a2 2 0 0 1 2 -2" />
        </template>

        <template v-if="iconName === 'bottombar'">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <rect x="4" y="4" width="16" height="16" rx="2" />
          <line x1="4" y1="15" x2="20" y2="15" />
        </template>

        <template v-if="iconName === 'layout-navbar'">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <rect x="4" y="4" width="16" height="16" rx="2" />
          <line x1="4" y1="9" x2="20" y2="9" />
        </template>

        <template v-if="iconName === 'layout-rows'">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <rect x="4" y="4" width="16" height="16" rx="2" />
          <line x1="4" y1="12" x2="20" y2="12" />
        </template>

        <template v-if="iconName === 'mood-smile'">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <circle cx="12" cy="12" r="9" />
          <line x1="9" y1="10" x2="9.01" y2="10" />
          <line x1="15" y1="10" x2="15.01" y2="10" />
          <path d="M9.5 15a3.5 3.5 0 0 0 5 0" />
        </template>

        <template v-if="iconName === 'palette'">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M12 21a9 9 0 1 1 0 -18a9 8 0 0 1 9 8a4.5 4 0 0 1 -4.5 4h-2.5a2 2 0 0 0 -1 3.75a1.3 1.3 0 0 1 -1 2.25" />
          <circle cx="7.5" cy="10.5" r=".5" fill="currentColor" />
          <circle cx="12" cy="7.5" r=".5" fill="currentColor" />
          <circle cx="16.5" cy="10.5" r=".5" fill="currentColor" />
        </template>

        <template v-if="iconName === 'letter-case'">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <circle cx="17.5" cy="15.5" r="3.5" />
          <path d="M3 19v-10.5a3.5 3.5 0 0 1 7 0v10.5" />
          <path d="M3 13h7" />
          <path d="M21 12v7" />
        </template>

        <template v-if="iconName === 'corner-up-right'">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M6 18v-6a3 3 0 0 1 3 -3h10l-4 -4m0 8l4 -4" />
        </template>

        <template v-if="iconName === 'float-right'">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <rect width="6" height="6" x="14" y="5" rx="1" />
          <line x1="4" y1="7" x2="10" y2="7" />
          <line x1="4" y1="11" x2="10" y2="11" />
          <line x1="4" y1="15" x2="20" y2="15" />
          <line x1="4" y1="19" x2="20" y2="19" />
        </template>

        <template v-if="iconName === 'aspect-ratio'">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <rect x="3" y="5" width="18" height="14" rx="2" />
          <path d="M7 12v-3h3" />
          <path d="M17 12v3h-3" />
        </template>

      </svg>
</template>

<script>
export default {
  props: {
    iconName: String,
    iconColor: {
      type: String,
      default: 'currentColor'
    },
    width: {
      type: String,
      default: '20'
    },
    height: {
      type: String,
      default: '20'
    },
  },
}
</script>

<style lang="scss"  scoped>
  svg {
    display: flex;
  }
</style>
