import Index from '../../views/main/partnerResources/Index'
import Create from '../../views/main/partnerResources/Create'
import Edit from '../../views/main/partnerResources/Edit';

export default [
  {
    component: Index,
    name: 'partner-resources-index',
    path: '/partner-resources',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'partner_resources',
    }
  },
  {
    component: Create,
    name: 'partner-resources-create',
    path: '/partner-resources/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'partner_resources',
    }
  },
  {
    component: Edit,
    name: 'partner-resources-edit',
    path: '/partner-resources/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'partner_resources',
    }
  },
]
