<template>
  <v-data-table
    :items="items"
    :headers="headers"
    hide-default-footer
    disable-sort
  >
    <template v-slot:item.operation="{ item }">
      <v-chip :color="getColor(item)" text-color="white">
        {{ item.operation }}
      </v-chip>
    </template>
  </v-data-table>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Preview",
  created() {
    this.fetchItems();
  },
  data: () => ({
    headers: [
      { text: "ID", value: "id" },
      { text: "Пользователь", value: "causer" },
      { text: "Операция", value: "operation" },
      { text: "Тип сущности", value: "subject_type" },
      { text: "Название сущности", value: "subject" },
      { text: "ID сущности", value: "subject_id" },
      { text: "Дата и время изменения", value: "created_at" },
    ]
  }),
  computed: {
    ...mapGetters({
      items: "ActivityLogs/getFetchedListing"
    })
  },
  methods: {
    ...mapActions({
      fetchItems: "ActivityLogs/fetchListing",
    }),
    getColor({ operation }) {
      switch (operation) {
        case "Обновление":
          return "orange";
        case "Создание":
          return "green";
        case "Удаление":
          return "red";
        default:
          return "gray";
      }
    }
  }
}
</script>

<style scoped>

</style>
