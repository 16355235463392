<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
   <v-row>
     <v-col>
       <h1>Редактирование клиента №{{ fetchedDetail.id }}</h1>
     </v-col>
   </v-row>
   <v-row>
     <v-col>
       <customer-form :customer="fetchedDetail" @onSubmit="submit"/>
     </v-col>
   </v-row>
 </v-container>
</template>

<script>
import CustomerForm from "../../../components/customers/CustomerForm";
import {mapActions, mapGetters} from "vuex";
export default {
  name: "Edit",
  components: {CustomerForm},
  data: () => ({
    page: {
      title: "Редактирование клиента"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Клиенты",
        disabled: false,
        to: '/customers'
      },
      {
        text: "Создание",
        disabled: true,
        to: '/customers/create'
      },
    ],
  }),
  created() {
    this.fetchDetail(this.$route.params.id);
  },
  computed: {
    ...mapGetters({
      fetchedDetail: 'Customers/getFetchedDetail',
    }),
  },
  methods: {
    ...mapActions({
      fetchDetail: 'Customers/fetchDetail',
      updateItem: 'Customers/updateItem',
    }),
    submit(form) {
      this.updateItem(form);
    }
  }
}
</script>

<style scoped>

</style>
