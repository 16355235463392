<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
          <v-tabs v-model="tab">
            <v-tab>
              Основная информация
            </v-tab>
            <v-tab>
              Таксономия
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <partner-form
                :partner="partner"
                :errors="errors"
                @onSubmit="submit"
              ></partner-form>
            </v-tab-item>
            <v-tab-item>
              <CollectionRelationForm
                module-name="Partners"
                :errors="errors"
                :selected-collection-items="partner.selected_collection_items"
                :main-items="partner.main_taxonomy_ids"
                @onSubmit="submitCollectionItems"
                @onSubmitMain="submitMainCollectionItems"/>
            </v-tab-item>
          </v-tabs-items>
        <v-snackbar
          v-model="snackbarSuccess"
          :timeout="timeout"
          color="success"
        >
          Сохранено

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarSuccess = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar
          v-model="snackbarError"
          :timeout="timeout"
          color="error"
        >
          {{ this.errors }}

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarError = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { Partner } from '../../../models/partner';
import PartnerForm from "../../../components/partners/PartnerForm";
import CollectionRelationForm from "../../../components/collections/CollectionRelationForm";
import axiosInstance from "../../../axiosInstance";
import {mapActions} from "vuex";

export default {
  name: 'Edit',
  components: {
    CollectionRelationForm,
    PartnerForm,
  },
  data: () => ({
    partner: {},
    media: {},
    errors: {},
    snackbarSuccess: false,
    snackbarError: false,
    timeout: 2000,
    tab: 0,
    page: {
      title: "Редактирование партнера"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Партнеры",
        disabled: true,
        to: '/partners'
      },
      {
        text: "Редактрование",
        disabled: true,
        to: '/partners/create'
      },
    ]
  }),
  created() {
    this.find();
  },
  methods: {
    ...mapActions({
      showSuccess: "snackbar/showSuccessfullySaved",
      showError: "snackbar/showUnknownError",
    }),
    async submit({ form, mediaIds,isNeedRedirect }) {
      try {
        this.errors = {};
        if (mediaIds) {
          form.mediaIds = mediaIds;
        }
        const partner = await Partner.$query().update(form.id, form);
        await partner.persons().sync(form.selected_persons || []);
        await this.find();
        this.snackbarSuccess = true;

        if (isNeedRedirect) {
          await this.$router.push({ name: 'partners-index' });
        }
      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors;
          this.snackbarError = true;
        }
        throw e;
      }
    },
    async find() {
      const partner = await Partner.$query().find(this.$route.params.id);
      this.partner = partner.$attributes;
    },
    async submitCollectionItems({ items, isNeedRedirect }) {
      await axiosInstance.post(`partners/${this.partner.id}/collection-items/sync`, { items: items }).then(() => {
        this.showSuccess();
        if (isNeedRedirect) {
          this.$router.push({ name: 'partners-index' });
        }
        this.find();
      }).catch(() => {
        this.showError()
      });
    },
    async submitMainCollectionItems(items) {
      await axiosInstance.post(`partners/${this.partner.id}/collection-items/make-main`, {items: items}).then(() => {
        this.showSuccess();
        this.find();
      }).catch(() => {
        this.showError();
      });
    }
  },
  watch: {
    'partner.title': {
      handler(newTitle) {
        this.page.title += ` "${newTitle}"`;
      }
    },
  }
};
</script>
