<template>
  <div>
    <v-btn color="success" class="ml-2 mt-2 w-50 text text-center" @click="submitQuestions">Сохранить</v-btn>
    <v-divider/>
    <v-form ref="form">
      <v-expansion-panels>
        <v-expansion-panel v-for="(question, QuestionIndex) in questions" :key="QuestionIndex">
          <v-expansion-panel-header>
            Вопрос №{{ QuestionIndex + 1 }}
            <v-btn
              icon
              style="flex: none"
              color="red"
              @click="delete questions.splice(QuestionIndex, 1)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col md="8">
                <v-text-field
                  v-model="question.title"
                  :rules="rules.questionTitle"
                  label="Контрольный вопрос"
                  name="control_question"
                  type="text"
                  required
                ></v-text-field>
              </v-col>
              <v-col md="4">
                <media-widget
                  label="Изображение для вопроса"
                  :medias="question.image"
                  :is-multiple="false"
                  @onChange="setQuestionImage($event, question)"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h4>Ответы</h4>
              </v-col>
              <v-col cols="12">
                <v-row v-for="(item, index) in question.answers" :key="index">
                  <v-col md="8">
                    <v-text-field
                      v-model="item.points"
                      type="number"
                      label="Кол-во баллов"
                      />
                    <v-textarea
                      v-model="item.title"
                      :rules="rules.answerTitle"
                      label="Текст ответа"
                      name="text"
                      required
                    ></v-textarea>
                    <v-checkbox
                      v-model="item.is_correct"
                      label="Правильный ответ"
                      name="points"
                      required
                    />
                  </v-col>
                  <v-col md="3">
                    <media-widget
                      class="mb-1"
                      label="Изображение"
                      :medias="item.image"
                      :is-multiple="false"
                      @onChange="setAnswerImage($event, item)"
                    />
                  </v-col>
                  <v-col md="1">
                    <v-col cols="auto pl-0">
                      <v-btn
                        icon
                        color="red"
                        @click="delete question.answers.splice(index, 1)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-col>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-btn
                  color="success"
                  @click="addAnswer(QuestionIndex)"
                >
                  Добавить ответ
                </v-btn>


              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-form>

    <v-btn color="primary" class="m-2 w-25" @click="addQuestion">Добавить вопрос</v-btn>
    <v-btn color="success" class="ml-2 w-25" @click="submitQuestions">Сохранить</v-btn>
    <media-uploader/>
  </div>
</template>

<script>
import MediaWidget from "../MediaWidget";
import {mapActions} from "vuex";
import MediaUploader from "../MediaUploader";

export default {
  name: "LessonControlQuestion",
  components: {
    MediaWidget,
    MediaUploader
  },
  props: {
    value: {
      type: [Array],
      default: () => ([]),
    },
    entity_type: {
      type: String,
      required: true,
    },
    entity_id: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      questions: [],
      rules: {
        questionTitle: [
          v => !!v || 'Контрольный вопрос обязателен для заполнения',
          v => (v && v.length >= 1) || 'Контрольный вопрос должен иметь больше 1 символа',
        ],
        answerTitle: [
          v => !!v || 'Текст вопроса обязателен для заполнения',
          v => (v && v.length >= 1) || 'Текст вопроса должен иметь больше 1 символа',
        ]
      }
    }
  },
  computed: {
    isValid() {
      const formIsValid = this.$refs.form.validate();
      let manualCheck = true;

      this.questions.forEach((question) => {

        if (manualCheck) {
          if (!question.title) {
            manualCheck = false;
          }

          question.answers.forEach((answer) => {
            console.log(answer);
            if (!answer.title) {
              manualCheck = false;
            }
          });
        }
      })

      return formIsValid && manualCheck && this.questions.length > 0;
    }
  },
  watch: {
    value: {
      handler(value) {
        if (value) {
          this.questions = value;
        } else {
          this.questions = []
        }
      },
      deep: true,
      immediate: true,
    },
    question: {
      handler(value) {
        this.$emit('input', value);
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      syncQuestions: 'Lessons/syncQuestions',
      snackValidationError: 'snackbar/showValidationError',
    }),
    setAnswerImage(image, answer) {
      answer.image = image;
    },
    setQuestionImage(image, question) {
      question.image = image;
    },
    addAnswer(index) {
      this.questions[index].answers.push({
        is_correct: false,
        points: 0,
        image: {},
      });
    },
    addQuestion() {
      this.questions.push({
        title: null,
        image: {},
        answers: [],
      })
    },
    submitQuestions() {
      const payload = {
        lessonId: this.entity_id,
        form: this.questions,
      }

      if (this.isValid) {
        this.syncQuestions(payload);
      } else {
        this.snackValidationError();
      }
    }
  }
}
</script>
