import {Model} from "@tailflow/laravel-orion/lib/model";

import {Role} from "@/models/role";
import {BelongsToMany} from "@tailflow/laravel-orion/lib/drivers/default/relations/belongsToMany";

export class User extends Model<{
    name: string,
    email: string,
}> {

    $resource(): string {
        return "users";
    }

    public roles(): BelongsToMany<Role> {
        return new BelongsToMany<Role>(Role, this);
    }
}
