<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="fetchedData"
              :options.sync="options"
              :server-items-length="totalItems"
              class="contact-listing-app"
              >
              <template v-slot:top>
                <v-toolbar flat class="mb-8">
                  <v-row>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        v-model="options.search"
                        append-icon="mdi-magnify"
                        label="Поиск..."
                        filled
                        background-color="transparent"
                        hide-details
                        name="title"
                      >
                        <v-btn v-if="search" class="reset-button"
                               slot="append"
                               icon
                               @click="search = null"
                               color="red"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-spacer/>
                  <v-dialog max-width="700">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="primary"
                        to="/block-settings/create"
                        dark
                        class="mb-2 text-capitalize"
                        >
                        <v-icon class="mr-2">mdi-cog-outline</v-icon>
                        Создать настройку
                      </v-btn>
                    </template>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:item.id="{ item }">
                <v-chip pill>{{ item.id }}</v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  style="text-decoration:none"
                  icon
                  :href="$router.resolve({name: 'block-settings-edit', params: {id: item.id}}).href"
                >
                  <v-icon small color="primary">
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-btn
                  icon
                  @click="destroy(item)"
                >
                  <v-icon small color="red">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Index",
  data: () => ({
    options: {
      sort: {
        orderBy: ['id'],
        sortDesc: true,
      },
      search: null,
    },
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'Наименование', value: 'title' },
      { text: '', value: 'actions' },
    ],
    page: {
      title: "Настройки блока"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Настройки блока",
        disabled: true,
        to: '/block-settings'
      },
    ]
  }),
  created() {
    this.fetchData(this.options);
  },
  computed: {
    ...mapGetters({
      fetchedData: 'BlockSettings/getFetchedListing',
      isLoading: 'BlockSettings/isListingLoad',
      totalItems: 'BlockSettings/getListingTotalItems',
    }),
  },
  methods: {
    ...mapActions({
      fetchData: 'BlockSettings/fetchListing',
      destroyItem: 'BlockSettings/destroyItem',
    }),
    destroy(item) {
      this.destroyItem(item.id).then(() => {
        this.fetchData(this.options);
      });
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchData(this.options);
      }
    }
  }
}
</script>

<style scoped>

</style>
