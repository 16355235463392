<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <h1>Редактирование тизера {{ teaser.title }}</h1>
          </v-col>
        </v-row>
        <v-tabs
            v-model="tab"
            background-color="transparent"
            grow
            class="mb-5"
          >
          <v-tab>
            Основная информация
          </v-tab>
          <v-tab>
            Таксономия
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-row>
              <v-col>
                <teaser-form
                  :teaser="teaser"
                  :errors="errors"
                  @onSubmit="submit"
                />
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <collection-relation-form
              module-name="Teasers"
              :errors="errors"
              :selected-collection-items="teaser.selected_collection_items"
              :main-items="teaser.main_taxonomy_ids"
              @onSubmit="submitCollections"
              @onSubmitMain="submitMainCollection"
            />
          </v-tab-item>
        </v-tabs-items>
        <v-snackbar
          v-model="snackbarSuccess"
          :timeout="timeout"
          color="success"
        >
          Сохранено

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarSuccess = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar
          v-model="snackbarError"
          :timeout="timeout"
          color="error"
        >
          {{ this.errors }}

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarError = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import TeaserForm from "../../../components/teasers/TeaserForm";
import {Teaser} from "../../../models/teaser";
import CollectionRelationForm from "../../../components/collections/CollectionRelationForm";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'Edit',
  components: {
    TeaserForm,
    CollectionRelationForm,
  },
  data: () => ({
    errors: {},
    snackbarSuccess: false,
    snackbarError: false,
    timeout: 2000,
    tab: 0,
    page: {
      title: "Редактирование тизера"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Тизеры",
        disabled: true,
        to: '/teasers'
      },
      {
        text: "Редактирование",
        disabled: true,
        to: '/teasers/create'
      },
    ],
  }),
  created() {
    this.find();
  },
  computed: {
    ...mapGetters({
      teaser: 'Teasers/getFetchedDetail',
    }),
  },
  methods: {
    ...mapActions({
      submitCollections: 'Teasers/submitCollections',
      submitMainCollections: 'Teasers/submitMainCollections',
      fetchDetail: 'Teasers/fetchDetail',
    }),
    async submit({form, isNeedRedirect}) {
      try {
        this.errors = {};

        await Teaser.$query().update(form.id, form);
        this.snackbarSuccess = true;
        await this.find();

        if (isNeedRedirect) {
          await this.$router.push({name: 'teasers-index'});
        }
      } catch (e) {
        this.snackbarError = true;
        if (e.response?.data) {
          this.errors = e.response.data.errors;
        }
        throw e;
      }
    },
    async find() {
      this.fetchDetail(this.$route.params.id);
    },
    async submitCollection({ items }) {
      await this.submitCollections({ items: items});
    },
    async submitMainCollection(items) {
      await this.submitMainCollections({items: items});
    }
  },
};
</script>
