var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"down-top-padding",attrs:{"fluid":""}},[_c('v-card',[_c('vxe-toolbar',{scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('vxe-input',{attrs:{"type":"search","placeholder":"Поиск"},on:{"keyup":_vm.onSearchInput},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('vxe-button',{staticClass:"ml-3",attrs:{"content":"Добавить","status":"primary"},on:{"click":_vm.insertEvent}}),_c('vxe-button',{attrs:{"content":"Удалить","status":"danger"},on:{"click":_vm.removeEvent}}),_c('vxe-button',{attrs:{"content":"Сохранить","status":"success"},on:{"click":_vm.saveEvent}})]},proxy:true}])}),_c('div',{staticStyle:{"height":"calc(100vh - 260px)"}},[_c('vxe-table',{ref:"xTable",attrs:{"border":"","stripe":"","resizable":"","highlight-hover-row":"","keep-source":"","show-footer":"","max-height":"100%","data":_vm.data,"edit-config":{trigger: 'click', mode: 'cell', showStatus: true},"column-config":{resizable: true, minWidth: 350},"tooltip-config":_vm.tableToolbar,"menu-config":_vm.menuConfig},on:{"header-cell-click":_vm.headerCellClickEvent,"menu-click":_vm.contextMenuClickEvent}},[_c('vxe-column',{attrs:{"type":"checkbox","width":"60"}}),_c('vxe-column',{attrs:{"field":"id","title":"id","width":"60"}}),_c('vxe-column',{attrs:{"field":"title","title":"Заголовок (f1-1)","edit-render":{},"sortable":"","fixed":"left"},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.title),callback:function ($$v) {_vm.$set(row, "title", $$v)},expression:"row.title"}})]}}])}),_c('vxe-column',{attrs:{"field":"slug","title":"slug (f1-2)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.slug),callback:function ($$v) {_vm.$set(row, "slug", $$v)},expression:"row.slug"}})]}}])}),_c('vxe-column',{attrs:{"field":"price","width":"100","title":"Цена (f1-3)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"number"},model:{value:(row.price),callback:function ($$v) {_vm.$set(row, "price", $$v)},expression:"row.price"}})]}}])}),_c('vxe-column',{attrs:{"field":"subtitle","title":"Подзаголовок (f1-4)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.subtitle),callback:function ($$v) {_vm.$set(row, "subtitle", $$v)},expression:"row.subtitle"}})]}}])}),_c('vxe-column',{attrs:{"field":"person_id","title":"Персона (f1-5)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.formatPersonsValue(row.person_id)))])]}},{key:"edit",fn:function(scope){return [_c('vxe-select',{attrs:{"transfer":""},model:{value:(scope.row.person_id),callback:function ($$v) {_vm.$set(scope.row, "person_id", $$v)},expression:"scope.row.person_id"}},_vm._l((_vm.persons),function(item){return _c('vxe-option',{key:item.key,attrs:{"value":item.key,"label":item.value}})}),1)]}}])}),_c('vxe-column',{attrs:{"field":"video_time","title":"Время видео (f1-6)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.video_time),callback:function ($$v) {_vm.$set(row, "video_time", $$v)},expression:"row.video_time"}})]}}])}),_c('vxe-column',{attrs:{"field":"teaser_url","title":"Ссылка на тизер (f1-7)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.teaser_url),callback:function ($$v) {_vm.$set(row, "teaser_url", $$v)},expression:"row.teaser_url"}})]}}])}),_c('vxe-column',{attrs:{"field":"description","title":"Описание (f1-8)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.description),callback:function ($$v) {_vm.$set(row, "description", $$v)},expression:"row.description"}})]}}])}),_c('vxe-column',{attrs:{"field":"subline_description","title":"Подстрочник под описание (f1-9)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.subline_description),callback:function ($$v) {_vm.$set(row, "subline_description", $$v)},expression:"row.subline_description"}})]}}])}),_c('vxe-column',{attrs:{"field":"text_after_purchase","title":"Текст после покупки (f1-10)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.text_after_purchase),callback:function ($$v) {_vm.$set(row, "text_after_purchase", $$v)},expression:"row.text_after_purchase"}})]}}])}),_c('vxe-column',{attrs:{"field":"subline_description_course","title":"Подстрочник описания курса (f1-11)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.subline_description_course),callback:function ($$v) {_vm.$set(row, "subline_description_course", $$v)},expression:"row.subline_description_course"}})]}}])}),_c('vxe-column',{attrs:{"field":"introductory_text","title":"Вводный текст (f1-12)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.introductory_text),callback:function ($$v) {_vm.$set(row, "introductory_text", $$v)},expression:"row.introductory_text"}})]}}])}),_c('vxe-column',{attrs:{"field":"subline_introductory_text","title":"Подстрочник вводного текста (f1-13)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.subline_introductory_text),callback:function ($$v) {_vm.$set(row, "subline_introductory_text", $$v)},expression:"row.subline_introductory_text"}})]}}])}),_c('vxe-column',{attrs:{"field":"age_from","title":"Возраст от (f1-14)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"number"},model:{value:(row.age_from),callback:function ($$v) {_vm.$set(row, "age_from", $$v)},expression:"row.age_from"}})]}}])}),_c('vxe-column',{attrs:{"field":"age_to","title":"Возраст до (f1-15)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"number"},model:{value:(row.age_to),callback:function ($$v) {_vm.$set(row, "age_to", $$v)},expression:"row.age_to"}})]}}])}),_c('vxe-column',{attrs:{"field":"is_free","title":"Является бесплатным (f1-16)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"number"},model:{value:(row.is_free),callback:function ($$v) {_vm.$set(row, "is_free", $$v)},expression:"row.is_free"}})]}}])}),_c('vxe-column',{attrs:{"field":"preview_image","title":"Изображение (f1-17)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"number"},model:{value:(row.preview_image),callback:function ($$v) {_vm.$set(row, "preview_image", $$v)},expression:"row.preview_image"}})]}}])}),_c('vxe-column',{attrs:{"field":"preview_image_video","title":"Изображение видео (f1-18)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"number"},model:{value:(row.preview_image_video),callback:function ($$v) {_vm.$set(row, "preview_image_video", $$v)},expression:"row.preview_image_video"}})]}}])}),_c('vxe-column',{attrs:{"field":"preview_image_head","title":"Изображение в шапке (f1-19)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"number"},model:{value:(row.preview_image_head),callback:function ($$v) {_vm.$set(row, "preview_image_head", $$v)},expression:"row.preview_image_head"}})]}}])}),_c('vxe-column',{attrs:{"field":"pass_course_1","title":"Курс стоит пройти 1 (текст) (f1-20)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.pass_course_1),callback:function ($$v) {_vm.$set(row, "pass_course_1", $$v)},expression:"row.pass_course_1"}})]}}])}),_c('vxe-column',{attrs:{"field":"pass_course_image_1","title":"Курс стоит пройти 1 (изображение) (f1-21)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"number"},model:{value:(row.pass_course_image_1),callback:function ($$v) {_vm.$set(row, "pass_course_image_1", $$v)},expression:"row.pass_course_image_1"}})]}}])}),_c('vxe-column',{attrs:{"field":"pass_course_2","title":"Курс стоит пройти 2 (текст) (f1-22)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.pass_course_2),callback:function ($$v) {_vm.$set(row, "pass_course_2", $$v)},expression:"row.pass_course_2"}})]}}])}),_c('vxe-column',{attrs:{"field":"pass_course_image_2","title":"Курс стоит пройти 2 (изображение) (f1-23)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"number"},model:{value:(row.pass_course_image_2),callback:function ($$v) {_vm.$set(row, "pass_course_image_2", $$v)},expression:"row.pass_course_image_2"}})]}}])}),_c('vxe-column',{attrs:{"field":"pass_course_3","title":"Курс стоит пройти 3 (текст) (f1-24)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.pass_course_3),callback:function ($$v) {_vm.$set(row, "pass_course_3", $$v)},expression:"row.pass_course_3"}})]}}])}),_c('vxe-column',{attrs:{"field":"pass_course_image_3","title":"Курс стоит пройти 3 (изображение) (f1-25)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"number"},model:{value:(row.pass_course_image_3),callback:function ($$v) {_vm.$set(row, "pass_course_image_3", $$v)},expression:"row.pass_course_image_3"}})]}}])}),_c('vxe-column',{attrs:{"field":"pass_course_4","title":"Курс стоит пройти 4 (текст) (f1-26)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.pass_course_4),callback:function ($$v) {_vm.$set(row, "pass_course_4", $$v)},expression:"row.pass_course_4"}})]}}])}),_c('vxe-column',{attrs:{"field":"pass_course_image_4","title":"Курс стоит пройти 4 (изображение)(f1-27)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"number"},model:{value:(row.pass_course_image_4),callback:function ($$v) {_vm.$set(row, "pass_course_image_4", $$v)},expression:"row.pass_course_image_4"}})]}}])}),_c('vxe-column',{attrs:{"field":"accent_text_1","title":"Акценты курса 1 (текст)(f1-28)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.accent_text_1),callback:function ($$v) {_vm.$set(row, "accent_text_1", $$v)},expression:"row.accent_text_1"}})]}}])}),_c('vxe-column',{attrs:{"field":"accent_image_1","title":"Акценты курса 1 (изображение)(f1-29)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"number"},model:{value:(row.accent_image_1),callback:function ($$v) {_vm.$set(row, "accent_image_1", $$v)},expression:"row.accent_image_1"}})]}}])}),_c('vxe-column',{attrs:{"field":"accent_text_2","title":"Акценты курса 2 (текст)(f1-30)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.accent_text_2),callback:function ($$v) {_vm.$set(row, "accent_text_2", $$v)},expression:"row.accent_text_2"}})]}}])}),_c('vxe-column',{attrs:{"field":"accent_image_2","title":"Акценты курса 2 (изображение)(f1-31)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"number"},model:{value:(row.accent_image_2),callback:function ($$v) {_vm.$set(row, "accent_image_2", $$v)},expression:"row.accent_image_2"}})]}}])}),_c('vxe-column',{attrs:{"field":"accent_text_3","title":"Акценты курса 3 (текст)(f1-32)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.accent_text_3),callback:function ($$v) {_vm.$set(row, "accent_text_3", $$v)},expression:"row.accent_text_3"}})]}}])}),_c('vxe-column',{attrs:{"field":"accent_image_3","title":"Акценты курса 3 (изображение)(f1-33)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"number"},model:{value:(row.accent_image_3),callback:function ($$v) {_vm.$set(row, "accent_image_3", $$v)},expression:"row.accent_image_3"}})]}}])}),_c('vxe-column',{attrs:{"field":"accent_text_4","title":"Акценты курса 4 (текст)(f1-34)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.accent_text_4),callback:function ($$v) {_vm.$set(row, "accent_text_4", $$v)},expression:"row.accent_text_4"}})]}}])}),_c('vxe-column',{attrs:{"field":"accent_image_4","title":"Акценты курса 4 (изображение)(f1-35)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"number"},model:{value:(row.accent_image_4),callback:function ($$v) {_vm.$set(row, "accent_image_4", $$v)},expression:"row.accent_image_4"}})]}}])}),_c('vxe-column',{attrs:{"field":"excel_collection","title":"Коллекции(f1-36)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-select',{attrs:{"transfer":""},model:{value:(row.excel_collection),callback:function ($$v) {_vm.$set(row, "excel_collection", $$v)},expression:"row.excel_collection"}},_vm._l((_vm.collectionsSelect),function(item){return _c('vxe-option',{key:item,attrs:{"value":item,"label":item}})}),1)]}}])}),_c('vxe-column',{attrs:{"field":"excel_season","title":"Сезон(f1-37)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-select',{attrs:{"transfer":""},model:{value:(row.excel_season),callback:function ($$v) {_vm.$set(row, "excel_season", $$v)},expression:"row.excel_season"}},_vm._l((_vm.seasonSelect),function(item){return _c('vxe-option',{key:item,attrs:{"value":item,"label":item}})}),1)]}}])}),_c('vxe-column',{attrs:{"field":"excel_items","title":"Предметы(f1-38)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.excel_items),callback:function ($$v) {_vm.$set(row, "excel_items", $$v)},expression:"row.excel_items"}})]}}])}),_c('vxe-column',{attrs:{"field":"excel_class","title":"Класс(f1-39)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.excel_class),callback:function ($$v) {_vm.$set(row, "excel_class", $$v)},expression:"row.excel_class"}})]}}])}),_c('vxe-column',{attrs:{"field":"excel_quarter","title":"Четверть(f1-40)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.excel_quarter),callback:function ($$v) {_vm.$set(row, "excel_quarter", $$v)},expression:"row.excel_quarter"}})]}}])}),_c('vxe-column',{attrs:{"field":"excel_month","title":"Месяц(f1-41)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.excel_month),callback:function ($$v) {_vm.$set(row, "excel_month", $$v)},expression:"row.excel_month"}})]}}])}),_c('vxe-column',{attrs:{"field":"excel_science","title":"Вид науки(f1-42)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-select',{attrs:{"transfer":""},model:{value:(row.excel_science),callback:function ($$v) {_vm.$set(row, "excel_science", $$v)},expression:"row.excel_science"}},_vm._l((_vm.selectSphereKnowledge),function(item){return _c('vxe-option',{key:item,attrs:{"value":item,"label":item}})}),1)]}}])}),_c('vxe-column',{attrs:{"field":"excel_knowledge","title":"Сфера знаний(f1-43)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.excel_knowledge),callback:function ($$v) {_vm.$set(row, "excel_knowledge", $$v)},expression:"row.excel_knowledge"}})]}}])}),_c('vxe-column',{attrs:{"field":"excel_methodist","title":"Лидерство(f1-44)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-select',{attrs:{"transfer":""},model:{value:(row.excel_methodist),callback:function ($$v) {_vm.$set(row, "excel_methodist", $$v)},expression:"row.excel_methodist"}},_vm._l((_vm.selectMethodist),function(item,key){return _c('vxe-option',{key:key,attrs:{"value":item,"label":item}})}),1)]}}])}),_c('vxe-column',{attrs:{"field":"conspectus_file","title":"Файлы конспектов (через запятую)(f1-45)","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.conspectus_file),callback:function ($$v) {_vm.$set(row, "conspectus_file", $$v)},expression:"row.conspectus_file"}})]}}])})],1),_c('vxe-pager',{attrs:{"border":"","icon-prev-page":"fa fa-angle-left","icon-jump-prev":"fa fa-angle-double-left","icon-jump-next":"fa fa-angle-double-right","icon-next-page":"fa fa-angle-right","icon-jump-more":"fa fa-ellipsis-h","loading":_vm.loading,"current-page":_vm.paginator.currentPage,"page-size":_vm.paginator.limit,"total":_vm.paginator.total,"page-sizes":_vm.paginator.pageSizes,"layouts":['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']},on:{"page-change":_vm.changePage}})],1)],1),(_vm.isShowWysywig)?_c('wysywig-popup',{attrs:{"html":_vm.data[_vm.latestCellContextMenuClick.row][_vm.latestCellContextMenuClick.column]},on:{"html-input":_vm.htmlInput},model:{value:(_vm.wysywigPopup),callback:function ($$v) {_vm.wysywigPopup=$$v},expression:"wysywigPopup"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }