<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-form>
        <v-tabs
          v-model="tab"
          background-color="transparent"
          grow
          class="mb-5"
        >
          <v-tab>
            Основная информация
          </v-tab>
          <v-tab>
            Таксономия
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-row>
              <v-col>
                <faq-form
                  :faq="faq"
                  :errors="errors"
                  @onSubmit="submit"
                ></faq-form>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <collection-relation-form
              module-name="Faqs"
              :errors="errors"
              :selected-collection-items="fetchedDetail.selected_collection_items"
              :main-items="fetchedDetail.main_taxonomy_ids"
              @onSubmit="submitCollection"
              @onSubmitMain="submitMainCollection"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-form>
        <v-snackbar
          v-model="snackbarSuccess"
          :timeout="timeout"
          color="success"
        >
          Сохранено

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarSuccess = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar
          v-model="snackbarError"
          :timeout="timeout"
          color="error"
        >
          {{ this.errors }}

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarError = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import FaqForm from '../../../components/faqs/FaqForm';
import { FAQ } from '../../../models/FAQ';
import CollectionRelationForm from "../../../components/collections/CollectionRelationForm";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'Edit',
  components: {
    FaqForm,
    CollectionRelationForm,
  },
  data: () => ({
    faq: {},
    media: {},
    errors: {},
    snackbarSuccess: false,
    snackbarError: false,
    timeout: 2000,
    page: {
      title: "Редактирование вопроса"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Вопросы и ответы",
        disabled: true,
        to: '/faqs'
      },
      {
        text: "Редактирование",
        disabled: true,
        to: '/faqs/edit'
      },
    ],
    tab: 0,
  }),
  created() {
    this.find();
    this.fetchDetail(this.$route.params.id);
  },
  computed: {
    ...mapGetters({
      fetchedDetail: 'FAQs/getFetchedDetail',
    })
  },
  methods: {
    ...mapActions({
      submitCollections: "FAQs/submitCollections",
      submitMainCollections: "FAQs/submitMainCollections",
      fetchDetail: 'FAQs/fetchDetail',
    }),
    async submit({ form, mediaIds, isNeedRedirect }) {
      try {
        this.errors = {};
        if (mediaIds) {
          form.mediaIds = mediaIds;
        }
        await FAQ.$query().update(form.id, form);
        await this.find();
        this.snackbarSuccess = true;

        if (isNeedRedirect) {
          await this.$router.push({ name: 'faqs-index' });
        } else {
          await this.$router.push({ name: 'faqs-edit', params: { id: form.id } });
        }

      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors;
          this.snackbarError = true;
        }
        throw e;
      }
    },
    async find() {
      const faq = await FAQ.$query().find(this.$route.params.id);
      this.faq = faq.$attributes;
    },
    async submitCollection({ items }) {
      await this.submitCollections({ items: items});
    },
    async submitMainCollection(items) {
      await this.submitMainCollections({items: items});
    }

  },
  watch: {
    'faq.full_name': {
      handler(newTitle) {
        this.page.title += ` "${newTitle}"`;
      }
    },
  }
};
</script>
