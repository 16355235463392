import store from './stores'
import axios from 'axios'
import Cookies from 'js-cookie';

const instance = axios.create({ baseURL: `${process.env.VUE_APP_API_URL}/api` })

instance.interceptors.request.use((res) => {
  const token = Cookies.get('token');
  const siteId = Cookies.get('site_id');
  if (token) {
    res.headers.Authorization = `${token}`;
  }
  if (siteId) {
    res.headers["X-SITE-ID"] = siteId;
  }

  return res;
}, error => error)

instance.interceptors.response.use(res => res, error => {
        if (error.response?.status === 401) {
            store.dispatch('auth/logout').then(() => {
              document.location.href = '/'
            })
        }

        return Promise.reject(error)
    }
)

export default instance;
