<template>
  <v-app id="inspire">
    <v-layout fill-height>
      <blocks-menu :blocks="blocks" :block-groups="blockGroups" />
      <v-main>
        <div class="pa-2">
          <router-view/>
        </div>
      </v-main>
    </v-layout>
  </v-app>
</template>

<script>


import { Block } from '../models/block';
import BlocksMenu from './block/BlocksMenu';
import { BlockGroup } from '../models/blockGroup';

export default {
  name: 'block-layout',
  components: { BlocksMenu },
  data: () => ({
    blocks: [],
    blockGroups: [],
  }),
  created() {
    this.getBlocks();
    this.getBlockGroups();
  },
  methods: {
    async getBlocks() {
      const blocks = await Block.$query().get();
      this.blocks = blocks.map(block => block.$attributes);
    },
    async getBlockGroups() {
      const blockGroups = await BlockGroup.$query().get();
      this.blockGroups = blockGroups.map(blockGroup => blockGroup.$attributes);
    },
  },
};

</script>
