<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row justify="space-between">
          <v-col cols="12" lg="4">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Поиск..."
              filled
              background-color="transparent"
              hide-details
              name="title"
            >
              <v-btn v-if="search" class="reset-button"
                     slot="append"
                     icon
                     @click="search = null"
                     color="red"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-text-field>
          </v-col>
          <v-col>
            <v-switch
              v-model="only_trashed"
              label="Показать удаленные"
              name="only_trashed"
              value="1"
            ></v-switch>
          </v-col>
          <v-col cols="12" lg="4" class="justify-end align-center d-flex">
            <v-dialog v-model="dialog" persistent max-width="700px">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" to="/teasers/create">
                  Создать тизер
                </v-btn>
                <v-btn color="primary" class="ml-2" @click="showModal=true">
                  Сортировка тизеров
                </v-btn>
              </template>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="items"
              :options.sync="options"
              :server-items-length="total"
              :footer-props="{'items-per-page-options':[5, 10, 15, 20, -1]}"
            >
              <template v-slot:item.id="{ item }">
                <v-chip pill>{{ item.id }}</v-chip>
              </template>
              <template v-slot:item.status="{ item }">
                <v-chip :color="getStatusColor(item.status)" :content="item.human_status" label>{{ item.human_status }}</v-chip>
              </template>
              <template v-slot:item.position="{ item }">
                <p>
                  {{ item.position === null ? '-' : item.position + 1 }}
                </p>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  :href="editItem(item)"
                  icon
                >
                  <v-icon color="primary" small>
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-icon
                  v-if="item.deleted_at"
                  color="green"
                  @click="restoreItem(item)"
                  small
                >
                  mdi-backup-restore
                </v-icon>
                <v-icon
                  v-else
                  color="red"
                  @click="deleteItem(item)"
                  small
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <sortable-teaser v-model="showModal"/>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {Teaser} from "../../../models/teaser";
import axiosInstance from "../../../axiosInstance";
import SortableTeaser from "../../../components/teasers/SortableTeaser";

export default {
  name: 'Index',
  components: {SortableTeaser},
  data: () => ({
    search: '',
    only_trashed: null,
    options: {
      sortBy: ['position'],
      sortDesc: [false],
      page: 1,
      itemsPerPage: 20,
    },
    total: 0,
    headers: [
      {text: 'Id', value: 'id'},
      {text: 'Название', value: 'title'},
      {text: 'Автор', value: 'user.full_name'},
      {text: 'Статус', value: 'status'},
      {text: 'Позиция в сортировке', value: 'position'},
      {text: 'Дата создания', value: 'created_at'},
      {text: 'Дата обновления', value: 'updated_at'},
      {text: '', value: 'actions'},
    ],
    showModal: false,
    items: [],
    page: {
      title: "Тизеры"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Тизеры",
        disabled: true,
        to: '/teasers'
      },
    ],
  }),
  methods: {
    editItem(item) {
      return this.$router.resolve({name: 'teasers-edit', params: {id: item.id}}).href
    },
    deleteItem(item) {
      this.$confirm('Вы уверены?', 'Удаление').then(() => {
        Teaser.$query().destroy(item.id, 0);
        this.get();
      });
    },
    async restoreItem(item) {
      await Teaser.$query().restore(item.id);
      await this.get();
    },
    async get() {
      // eslint-disable-next-line no-unused-vars
      const {page, itemsPerPage} = this.options;

      const search = this.search ?? null;
      const sortDesc = this.options.sortDesc.length ? (this.options.sortDesc[0] ? 'desc' : 'asc') : 'desc';
      const sortBy = this.options.sortBy.length ? this.options.sortBy : 'id';
      const sort = `${sortBy}|${sortDesc}`;

      await axiosInstance.get(`/module/teasers-cis/?per_page=${itemsPerPage}&page=${page}&sort=${sort}&search=${search}`).then((response) => {
        this.items = response.data.data;
        this.total = response.data.meta.total;
      })
    },
    getStatusColor(status) {
      switch (status) {
        case 0:
          return 'red';
        case 1:
          return 'yellow';
        case 2:
          return 'green';
        default:
          return 'grey';
      }
    },
  },
  watch: {
    only_trashed: {
      handler() {
        this.get();
      }
    },
    search: {
      handler(newSearch, oldSearch) {
        newSearch != oldSearch ? this.options.page = 1 : '';
        this.get();
      }
    },
    options: {
      handler() {
        this.get()
      },
      deep: true,
    },
    showModal: {
      handler() {
        if (!this.showModal) {
          this.get();
        }
      }
    }
  },
};
</script>
