<template>
  <v-app>
    <router-view/>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'auth-layout',
  async created() {
    await this.fetchPhrasesAndImages();
  },
  methods: {
    ...mapActions({
      fetchPhrasesAndImages: 'Sites/fetchPhrasesAndImages',
    })
  }
};

</script>
