<template>
  <div>
    <v-row>
      <v-col class="col-md-12 mt-6">
        <v-btn
          class="ml-2"
          color="primary"
          @click="coursesDialog = true"
        >
          Добавить
        </v-btn>
      </v-col>
      <v-col class="col-md-12 mt-6">
        <h3>Привязанные курсы</h3>
        <v-data-table
          :headers="seasonPassPresetsHeaders"
          :items="seasonPassPresets"
        >
          <template v-slot:item.id="{ item }">
            <v-chip pill>{{ item.id }}</v-chip>
          </template>
          <template v-slot:item.actions="{item}">
            <v-btn @click="deleteItem(item)" icon>
              <v-icon color="red">
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      v-model="coursesDialog"
      max-width="600px"
    >
      <v-card>
        <v-container>
          <v-row>
            <v-col>
              <h3>Добавление курса</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-if="courses"
                v-model="selectCourseId"
                :items="courses"
                item-text="title"
                item-value="id"
                label="Выберите курс"
                name="course"
                required
                @update:search-input="searchCourses"
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-btn
                color="primary"
                :disabled="isSaveDisabled"
                :loading="isSaveDisabled"
                @click="submitAddCourse"
              >
                Ок
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import {Course} from "@/models/course";
import axiosInstance from "@/axiosInstance";

export default {
  name: 'SetPresets',
  props: {
    entity: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      coursesDialog: false,
      courses: [],
      selectCourseId: null,
      seasonPassPresetsHeaders: [
        {text: 'id', value: 'id'},
        {text: 'id абонемента', value: 'season_pass_id'},
        {text: 'id курса', value: 'course_id'},
        {text: 'Заголовок курса', value: 'course_title'},
        {text: 'Дата создания', value: 'created_at'},
        {text: 'Дата обновления', value: 'updated_at'},
        {text: '', value: 'actions'},
      ],
      seasonPassPresets: [],
      isSaveDisabled: false,
    }
  },
  mounted() {
    this.getCourses();
    this.getSeasonPassPresets();
  },
  methods: {
    async getCourses(search = '') {
      let courses = [];

      this.isSaveDisabled = true;

      if (search) {
        courses = await Course.$query().lookFor(search).search(15);
      } else {
        courses = await Course.$query().get(50);
      }

      this.isSaveDisabled = false;
      this.courses = courses.map((c) => c.$attributes);
    },
    searchCourses(value) {
      // if(value){
      //   if(value.length > 3)
          this.getCourses(value);
      // }
    },
    async submitAddCourse() {
      if (this.entity.id) {
        const data = {
          season_pass_id: this.entity.id,
          course_id: this.selectCourseId,
        };

        await axiosInstance.post(`/season-pass/admin/set-sp-preset/`, data).then(() => {
          this.getSeasonPassPresets();
        });

        this.selectCourseId = null;
      } else {
        this.$store.dispatch("snackbar/showSnackbar", { color: "red", text: `Ошибка: Абонемент должен быть создан для привязки курсов` }, { root: true });
      }
    },
    async getSeasonPassPresets() {
      if (this.entity.id) {
        const data = {
          season_pass_id: this.entity.id,
        };

        await axiosInstance.post(`/season-pass/admin/get-sp-preset/`, data).then((response) => {
          this.seasonPassPresets = response.data.data;
        });
      }
    },
    async deleteItem(item) {
      const data = {
        season_pass_id: this.entity.id,
        course_id: item.course_id,
      };

      await axiosInstance.post(`/season-pass/admin/delete-sp-preset/`, data).then(() => {
        this.getSeasonPassPresets();
      });
    },
  },
}
</script>
