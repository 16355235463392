import Index from "../../views/main/logging/Index";

export default [
  {
    component: Index,
    name: 'logs-index',
    path: '/logs',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  }
]
