import {Promocode} from "../../../models/promocode";
import axiosInstance from "../../../axiosInstance";

export default {
  namespaced: true,
  state: {
    isSaveButtonDisabled: false,
    fetchedList: [],
    fetchedDetail: {},
  },
  getters: {
    isSaveButtonDisabled: (state) => state.isSaveButtonDisabled,
    getFetchedList: (state) => state.fetchedList,
    getFetchedDetail: (state) => state.fetchedDetail,
  },
  mutations: {
    setIsSaveButtonDisabled(state, val) {
      state.isSaveButtonDisabled = val;
    },
    setFetchedList(state, val) {
      state.fetchedList = val;
    },
    setFetchedDetail(state, detail) {
      state.fetchedDetail = detail;
    },
  },
  actions: {
    fetchListingWithoutFilters({ commit }) {
      Promocode.$query().get(100).then((response) => {
        commit('setFetchedList', response.map((item) => item.$attributes));
      })
    },
    async submitCollections({dispatch, state}, {items}) {
      await axiosInstance.post(`/promocodes/promocode/${state.fetchedDetail.id}/collection-items/sync`, {items: items}).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true})
        dispatch('fetchDetail', state.fetchedDetail.id)
      }).catch(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true});
      })
    },
    async submitMainCollections({dispatch, state}, {items}) {
      await axiosInstance.post(`/promocodes/promocode/${state.fetchedDetail.id}/collection-items/make-main`, {items: items}).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true})
        dispatch('fetchDetail', state.fetchedDetail.id)
      }).catch(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true});
      })
    },
    async fetchDetail({commit}, id) {
      await Promocode.$query().find(id).then((response) => {
        console.log(response);
        commit('setFetchedDetail', response.$attributes);
      });
    },
    async submitCourses({dispatch, state}, {items}) {
      await axiosInstance.post(`/promocodes/promocode/${state.fetchedDetail.id}/course-items/sync`, {items: items}).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true})
        dispatch('fetchDetail', state.fetchedDetail.id)
      }).catch(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true});
      })
    },
    async submitPersons({dispatch, state}, {items}) {
      await axiosInstance.post(`/promocodes/promocode/${state.fetchedDetail.id}/persons/sync`, {items: items}).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true})
        dispatch('fetchDetail', state.fetchedDetail.id)
      }).catch(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true});
      })
    },
  }
}

