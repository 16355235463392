<template>
  <v-container>
    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <v-row>
        <v-col>
          <ValidationProvider name="Название" rules="required|min:5" v-slot="{ errors }">
            <v-text-field :error-messages="errors" v-model="form.title" label="Название"/>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <ValidationProvider name="Сущность" rules="required" v-slot="{ errors }">
            <v-autocomplete search-input="value" v-model="form.entities" :items="fetchedEntities" item-value="key"
                            item-text="value" label="Сущности" multiple chips :error-messages="errors"/>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="entityList.length > 0">
          <v-autocomplete search-input="value" v-model="form.entities_ids" :items="entityList"
                          item-text="value" label="Выберите сущность" multiple chips return-object
                          hint="Оставьте поле пустым если хотите чтобы группа прикрепилась ко всем сущностям"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn block color="primary" @click="submit">Сохранить</v-btn>
        </v-col>
      </v-row>
    </ValidationObserver>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "FieldGroupForm",
  data: () => ({
    form: {
      title: null,
      entities: [],
      entities_ids: [],
    }
  }),
  props: {
    group: {
      type: Object,
      default: () => ({}),
    }
  },
  created() {
    this.fetchEntities();
  },
  computed: {
    ...mapGetters({
      fetchedEntities: "FieldGroups/getEntities",
      entityList: "FieldGroups/getEntitiesList",
    })
  },
  methods: {
    ...mapActions({
      fetchEntities: "FieldGroups/fetchEntities",
      fetchEntitiesList: "FieldGroups/fetchEntitiesList",
    }),
    submit() {
      this.$refs.observer.validate().then((isValid) => {
        if (isValid) {
          this.$emit('onSubmit', this.form);
        }
      })
    }
  },
  watch: {
    group: {
      handler(val) {
        if (val && Object.keys(val).length > 0) {
          this.form = JSON.parse(JSON.stringify(val));
        }
      }
    },
    'form.entities': {
      handler(val) {
        this.form.entities_ids = this.form.entities_ids.filter((item) => {
          return (this.form.entities.indexOf(item.entity) + 1);
        })

        if (this.form.entities.length > 0) {
          this.fetchEntitiesList(val)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
