<template>
  <v-container>
    <h1>Создание шаблона сообщения</h1>
    <div>
      <mail-form @onSubmit="submit"/>
    </div>
  </v-container>
</template>

<script>
import MailForm from "../../../components/mails/MailForm";
import {mapActions} from "vuex";
export default {
  name: "Create",
  components: {MailForm},
  methods: {
    ...mapActions({
      storeItem: 'Mails/storeItem',
    }),
    submit(form) {
      const payload = {
        form: form,
        router: this.$router,
      }

      this.storeItem(payload);
    }
  }
}
</script>

<style scoped>

</style>
