<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <person-form
              :person="person"
              :errors="errors"
              @onSubmit="submit"
            ></person-form>
          </v-col>
        </v-row>
        <v-snackbar
          v-model="snackbarSuccess"
          :timeout="timeout"
          color="success"
        >
          Сохранено

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarSuccess = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar
          v-model="snackbarError"
          :timeout="timeout"
          color="error"
        >
          {{ this.errors }}

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarError = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import PersonForm from '../../../components/persons/PersonForm';
import { Person } from '../../../models/person';

export default {
  name: 'Edit',
  components: {
    PersonForm,
  },
  data: () => ({
    person: {},
    media: {},
    errors: {},
    snackbarSuccess: false,
    snackbarError: false,
    timeout: 2000,
    page: {
      title: "Редактирование персоны"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Персоны",
        disabled: true,
        to: '/persons'
      },
      {
        text: "Редактирование",
        disabled: true,
        to: '/persons/edit'
      },
    ]
  }),
  created() {
    this.find();
  },
  methods: {
    async submit({ form, mediaIds, isNeedRedirect }) {
      try {
        this.errors = {};
        if (mediaIds) {
          form.mediaIds = mediaIds;
        }
        const person = await Person.$query().update(form.id, form);
        await person.partners().sync(form.selected_partners || []);
        await this.find();
        this.snackbarSuccess = true;

        if (isNeedRedirect) {
          await this.$router.push({ name: 'persons-index' });
        } else {
          await this.$router.push({ name: 'persons-edit', params: { id: form.id } });
        }

      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors;
          this.snackbarError = true;
        }
        throw e;
      }
    },
    async find() {
      const person = await Person.$query().find(this.$route.params.id);
      this.person = person.$attributes;
    },
  },
  watch: {
    'person.full_name': {
      handler(newTitle) {
        this.page.title += ` "${newTitle}"`;
      }
    },
  }
};
</script>
