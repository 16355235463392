import { Model } from "@tailflow/laravel-orion/lib/model";
import {HasOne} from "@tailflow/laravel-orion/lib/drivers/default/relations/hasOne";
import {BelongsTo} from "@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo";
import {BlockGroup} from "./blockGroup";

export class Mail extends Model<{
    title: string,
    subject: string,
    html_template: string,
    entity_type: string,
    hint: string,
    is_default: boolean,
}> {

    $resource(): string {
        return "module/mails";
    }
}
