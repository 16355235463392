import Index from '../../views/main/partners/Index'
import Create from '../../views/main/partners/Create'
import Edit from '../../views/main/partners/Edit';
import Excel from '../../views/main/partners/Excel';
import ExcelV2 from '../../views/main/partners/ExcelV2';

export default [
  {
    component: Index,
    name: 'partners-index',
    path: '/partners',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'partners',
    }
  },
  {
    component: Create,
    name: 'partners-create',
    path: '/partners/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'partners',
    }
  },
  {
    component: Edit,
    name: 'partners-edit',
    path: '/partners/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'partners',
    }
  },
  {
    component: Excel,
    name: 'partners-edit',
    path: '/partners/excel/',
    meta: {
      layout: 'clear-layout',
      requiresAuth: true,
      module: 'partners',
    }
  },
  {
    component: ExcelV2,
    name: 'partners-excel',
    path: '/partners/excelV2/',
    meta: {
      layout: 'clear-layout',
      module: 'partners',
    }
  }
]
