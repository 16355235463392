import Index from '../../views/main/briefs/Index'

export default [
  {
    component: Index,
    name: 'briefs',
    path: '/briefs',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  }
]
