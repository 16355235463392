<template>
  <v-container>
    <v-row>
      <v-col md="1">
        <v-btn fab small color="primary" href="/mails/create">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col md="8">
        <v-text-field
          v-model.lazy="search"
          label="Поиск..."
        />
      </v-col>
      <v-col>
        <v-checkbox
          v-model="only_trashed"
          label="Только удаленные"
         />
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
    >
      <template v-slot:item.actions="{ item }">
        <v-btn
          v-if="!item.deleted_at"
          icon
          @click="destroy(item)"
        >
          <v-icon color="red">
            mdi-delete
          </v-icon>
        </v-btn>
        <v-btn
          v-else
          icon
          @click="recovery(item)"
        >
          <v-icon color="green">
            mdi-restore
          </v-icon>
        </v-btn>
        <v-btn
          icon
          :href="`/mails/edit/${item.id}`"
          >
            <v-icon color="primary">
              mdi-pen
            </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Index",
  data: () => ({
    search: '',
    options: {
      sortBy: ['id'],
      sortDesc: [true],
    },
    only_trashed: false,
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'Название', value: 'title' },
      { text: 'Сущность', value: 'entity_type' },
      { text: '', value: 'actions' },
    ]
  }),
  computed: {
    ...mapGetters({
      items: 'Mails/getFetchedListing',
      totalItems: 'Mails/getListingTotalItems',
    })
  },
  created() {
    this.get()
  },
  methods: {
    ...mapActions({
      fetchListing: 'Mails/fetchListing',
      deleteItem: 'Mails/deleteItem',
      recoveryItem: 'Mails/recoverItem',
    }),
    get() {
      const payload = {
        options: this.options,
        search: this.search,
        only_trashed: this.only_trashed,
      }

      this.fetchListing(payload);
    },
    destroy(item) {
      this.deleteItem(item.id).then(() => {
        this.get();
      });
    },
    recovery(item) {
      this.recoveryItem(item.id).then(() => {
        this.get();
      });
    },
  },
  watch: {
    search: {
      handler() {
        this.get()
      }
    },
    options: {
      handler() {
        this.get();
      }
    },
    only_trashed: {
      handler() {
        this.get();
      }
    }
  }
}
</script>

<style scoped>

</style>
