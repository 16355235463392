<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row>
      <v-col>
        <h1>Партнеры</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="primary" to="/WebmasterPartners/create">
          Добавить партнера
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :loading="loading"
          :server-items-length="listingTotal"
          :search="search"
        >
          <template v-slot:item.fullUrlHref="{item}">
            <a :href="item.url" target="_blank">{{ item.url }}</a>
          </template>
          <template v-slot:item.shortUrlHref="{item}">
            <a :href="item.shortUrl" target="_blank">{{ item.shortUrl }}</a>
          </template>
          <template v-slot:item.action="{item}">
            <v-icon
              color="red"
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:item.widget="{item}">
            <v-btn @click="setPartner(item.code)" >Показать</v-btn>
          </template>
          <template v-slot:item.post_widget="{item}">
            <v-btn @click="setPartnerPost(item.code)" >Показать</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <partner-widget v-model="widgetPopup" :partner="getPartner"/>
    <partner-post-widget v-model="widgetPostPopup" :partner="getPartner"/>

  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PartnerWidget from "../../../components/partners/PartnerWidget.vue";
import PartnerPostWidget from "../../../components/partners/PartnerPostWidget.vue";

export default {
  name: 'Index',
  components: {PartnerWidget, PartnerPostWidget, /*BlockPopup*/},
  data: () => ({
    search: '',
    only_trashed: null,
    options: {
      sortBy: ['id'],
      sortDesc: [true],
      page: 1,
      itemsPerPage: 10,
    },
    headers: [
      {text: 'Id', value: 'id'},
      {text: 'Название', value: 'name'},
      {text: 'Код', value: 'code'},
      {text: 'Дата создания', value: 'created_at'},
      {text: 'Дата обновления', value: 'updated_at'},
      {text: 'Виджет', value: 'widget'},
      {text: 'Почтовый виджет', value: 'post_widget'},
      {text: '', value: 'action'},
    ],
    page: {
      title: "Партнеры"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Партнеры",
        disabled: true,
        to: '/webmasterPartners'
      },
    ],
    widgetPopup:false,
    widgetPostPopup:false,
    currentPartner: null,
  }),
  created() {
    this.fetchedListing(this.listingPayload);
  },
  computed: {
    ...mapGetters({
        items: 'WebmasterPartners/getFetchedListing',
        listingTotal: 'WebmasterPartners/getListingTotalItems',
        loading: 'WebmasterPartners/getIsListingLoad',
    }),
    listingPayload() {
      return {
        options: this.options,
        search: this.search,
        only_trashed: this.only_trashed,
      }
    },
    getPartner() {
      return this.currentPartner;
    }
  },
  methods: {
    ...mapActions({
      fetchedListing: 'WebmasterPartners/fetchListing',
      destroyItem: 'WebmasterPartners/destroyItem',
    }),
    deleteItem(item) {
      this.destroyItem(item.id).then(this.fetchedListing(this.listingPayload));
    },
    setPartner(value) {
      this.widgetPopup = true;
      this.currentPartner = value;
    },
    setPartnerPost(value) {
      this.widgetPostPopup = true;
      this.currentPartner = value;
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchedListing(this.listingPayload);
      },
      deep: true,
      immediate: true,
    },
  }
}
</script>
