import { Model } from "@tailflow/laravel-orion/lib/model";
import {HasMany} from "@tailflow/laravel-orion/lib/drivers/default/relations/hasMany";
import {Permission} from "./permission";
import {BelongsToMany} from "@tailflow/laravel-orion/lib/drivers/default/relations/belongsToMany";

export class Role extends Model<{
    name: string,
    guard_name: string,
    created_at: string,
    updated_at: string
}> {

    $resource(): string {
        return "roles";
    }

    public permissions(): BelongsToMany<Permission> {
        return new BelongsToMany<Permission>(Permission, this);
    }
}
