import {Model} from "@tailflow/laravel-orion/lib/model";
import {CollectionItem} from "./collectionItem";
import {BelongsToMany} from "@tailflow/laravel-orion/lib/drivers/default/relations/belongsToMany";

export class Library extends Model<{
    title: string,
    slug: string,
    created_at: string,
    updated_at: string,
    lead: string,
    tags: object,
    detail_text: string,
    author: string,
    target: object,
    area: object,
    special: string,
    opyt: string,
    organizations: string,
    thematic: object,
    age: object,
    category: object,
    notes: object,
    type: object,
    actuality: object,
    keywords: object,
    preview_image?: object,
    preview_url?: string,
}> {

    $resource(): string {
        return "module/moi-financy/library";
    }

    public collectionItems(): BelongsToMany<CollectionItem> {
        return new BelongsToMany<CollectionItem>(CollectionItem, this);
    }
}
