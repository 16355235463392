import axios from '@/axiosInstance';

export default {
  data() {
    return {
      cellEditor: {
        showEditor: false,
        value: '',
        rowPos: null,
        rowIndex: null,
        colName: '',
      },
      searchableProcedures: [],
      toUpdate: [],
      toDelete: [],
      dialog: false,
      table: {
        columns: [],
        rows: [],
      },
      searchText: '',
      isTableLoading: false,
      tableFetchError: false
    }
  },
  created() {
    this.fetchTable();
  },
  computed: {
    showEditCellButton() {
      return this.cellEditor.rowIndex !== null && this.cellEditor.colName;
    },
    catalogId() {
      return this.$route.params.catalog_id;
    }
  },
  methods: {
    async fetchTable() {
      try {
        this.isTableLoading = true;

        const { data: table } = await axios.get(`module/admin/catalog/${this.catalogId}/table`)

        this.table = table;
        this.isTableLoading = false;
      } catch (error) {
        this.isTableLoading = false;
        this.tableFetchError = true;
      }
    },
    updateTable() {
      this.saveTable();
      this.deleteRow();
    },
    async saveTable() {
      if (this.toUpdate.length) {
        this.isTableLoading = true;

        await axios.post(`/module/admin/catalog/${this.catalogId}/table/save-items`, {
          rows: this.toUpdate
        });

        this.isTableLoading = false;
      }
    },
    async deleteRow() {
      if (this.toDelete.length) {
        this.isTableLoading = true;

        await axios.delete(`/module/admin/catalog/${this.catalogId}/table/delete-items`, {
          data: {
            rows: this.toDelete
          }
        });
        this.isTableLoading = false;
      }
    },
    cellClickHandler({rowPos, rowIndex, colName, record}) {
      this.cellEditor.rowPos = rowPos;
      this.cellEditor.rowIndex = rowIndex;
      this.cellEditor.colName = colName;
      this.cellEditor.record = record;
      this.cellEditor.value = this.table.rows[rowIndex][colName];

      if (this.isEditorsValueContainsHTML()) {
        this.cellEditor.showEditor = true;
      } else {
        this.cellEditor.showEditor = false;
      }
    },
    isEditorsValueContainsHTML() {
      if (this.isCellEditorValueValid) {
        const regExp = /<(.*?)>(.*?)<\/(.*?)>/;
        const containsHTML = this.cellEditor.value.match(regExp);

        return !!containsHTML;
      }

      return false;
    },
    addNewItem() {
      this.$refs.grid.newRecord();
    },
    undo() {
      this.$refs.grid.undoTransaction();
    },
    deleteItems() {
      this.$refs.grid.deleteSelectedRecords();
    },
    updatedTableData(items) {
      items.forEach((item) => {
        const itemToAdd = this.table.rows.find((row) => row.$id === item.$id);
        const index = this.toUpdate.findIndex(({$id}) => $id === item.$id);

        if (index + 1) {
          this.toUpdate[index] = itemToAdd;
        } else {
          this.toUpdate.push(itemToAdd);
        }
      })
    },
    editCellModalHandler() {
      if (this.isCellEditorValueValid) {
        this.dialog = true;
      }
    },
    saveCellEditorHandler() {
      if (this.cellEditor.value !== this.table.rows[this.cellEditor.rowIndex][this.cellEditor.colName]) {
        this.table.rows[this.cellEditor.rowIndex][this.cellEditor.colName] = this.cellEditor.value;
        const item = this.table.rows[this.cellEditor.rowIndex];
        const itemToAdd = this.table.rows.find(({ $id }) => $id === item.$id);
        const index = this.toUpdate.findIndex(({ $id }) => $id === item.$id);

        if (index + 1) {
          this.toUpdate[index] = itemToAdd;
        } else {
          this.toUpdate.push(itemToAdd);
        }
      }

      this.dialog = false;
    },
  },
  watch: {
    procedures: {
      handler(val) {
        this.searchableProcedures = val;
        this.search('searchableProcedures', this.searchText, 'procedures', 'title')
      }
    },
  }
}
