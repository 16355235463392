<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row justify="space-between">
          <v-col cols="12" lg="4">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Поиск..."
              filled
              background-color="transparent"
              hide-details
              name="title"
            >
              <v-btn v-if="search" class="reset-button"
                     slot="append"
                     icon
                     @click="search = null"
                     color="red"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="4" class="justify-end align-center d-flex">
            <v-dialog v-model="dialog" persistent max-width="700px">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" to="/landings/create">
                  Создать лендинг
                </v-btn>
              </template>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-switch
              v-model="only_trashed"
              label="Показать удаленные"
              name="only_trashed"
              value="1"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :options.sync="options"
              :server-items-length="total"
              :footer-props="{'items-per-page-options':[5, 10, 15, 20, -1]}"
            >
              <template v-slot:item.id="{ item }">
                <v-chip pill>{{ item.id }}</v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  icon
                  v-if="item.preview_url"
                  :href="item.preview_url"
                  target="_blank"
                >
                  <v-icon
                    color="primary"
                    small
                  >
                    mdi-eye
                  </v-icon>
                </v-btn>
                <v-btn
                  v-if="!item.parent_id"
                  :href="addTranslate(item)"

                  icon
                >
                  <v-icon color="primary" small>
                    mdi-google-translate
                  </v-icon>
                </v-btn>
                <v-btn
                  :href="editItem(item)"

                  icon
                >
                  <v-icon color="primary" small>
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-icon
                  class="mr-2"
                  color="primary"
                  @click="copyItem(item)"
                  small
                >
                  mdi-content-copy
                </v-icon>
                <v-icon
                  v-if="item.deleted_at"
                  color="green"
                  @click="restoreItem(item)"
                  small
                >
                  mdi-backup-restore
                </v-icon>
                <v-icon
                  v-else
                  color="red"
                  @click="deleteItem(item)"
                  small
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {Landing} from '../../../models/landing';
import axiosInstance from "../../../axiosInstance";

export default {
  name: 'Index',
  data: () => ({
    search: '',
    only_trashed: null,
    options: {
      sortBy: ['id'],
      sortDesc: [true],
      page: 1,
      itemsPerPage: 20,
    },
    total: 0,
    headers: [
      {text: 'Id', value: 'id'},
      {text: 'Название', value: 'title'},
      {text: 'Slug', value: 'slug'},
      {text: 'Описание', value: 'description'},
      {text: 'Язык страницы', value: 'language'},
      {text: 'Дата создания', value: 'created_at'},
      {text: 'Дата обновления', value: 'updated_at'},
      {text: '', value: 'actions'},
    ],
    items: [],
    page: {
      title: "Лендинги"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Лендинги",
        disabled: true
      }
    ],
  }),
  created() {
    this.get();
  },
  methods: {
    addTranslate(item) {
      return this.$router.resolve({name: 'landings-create', params: {parent_id: item.id}}).href
    },
    editItem(item) {
      return this.$router.resolve({name: 'landings-edit', params: {id: item.id}}).href
    },
    async deleteItem(item) {
      this.$confirm('Вы уверены?', 'Удаление').then(() => {
        Landing.$query().destroy(item.id, 0);
        this.get();
      });
    },
    async restoreItem(item) {
      await Landing.$query().restore(item.id);
      await this.get();
    },
    async copyItem(item) {
      await axiosInstance.get(`/module/admin/landings/${item.id}/make-copy`);
      await this.get();
    },
    async get() {
      // eslint-disable-next-line no-unused-vars
      const {sortBy, sortDesc, page, itemsPerPage} = this.options;
      const onlyTrashed = this.only_trashed ? `&only_trashed=1` : '';
      let data = {};
      this.search ? data.search = {value: this.search} : null;
      sortBy && sortBy[0] ? data.sort = [{field: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc'}] : null;

      await axiosInstance.post(`/module/admin/landings/search?limit=${itemsPerPage}&page=${page}${onlyTrashed}`, data).then((response) => {
        this.items = response.data.data;
        this.total = response.data.meta.total;
      })
    },
  },
  watch: {
    only_trashed: {
      handler() {
        this.get();
      }
    },
    search: {
      handler(newSearch, oldSearch) {
        newSearch != oldSearch ? this.options.page = 1 : '';
        this.get();
      }
    },
    options: {
      handler() {
        this.get()
      },
      deep: true,
    },
  },
};
</script>
