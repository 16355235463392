import axiosInstance from "../../../axiosInstance";
import {Customer} from "../../../models/customer";

export default {
  namespaced: true,
  state: {
    fetchedListing: [],
    fetchedDetail: {},
    listingTotalItems: 0,
    isListingLoading: false,
    isActionButtonDisabled: false,
  },
  getters: {
    getFetchedListing: (state) => state.fetchedListing,
    getFetchedDetail: (state) => state.fetchedDetail,
    getListingTotalItems: (state) => state.listingTotalItems,
    getIsListingLoading: (state) => state.isListingLoading,
    getIsActionButtonDisabled: (state) => state.isActionButtonDisabled,
  },
  mutations: {
    setFetchedListing(state, value) {
      state.fetchedListing = value;
    },
    setFetchedDetail(state, value) {
      state.fetchedDetail = value;
    },
    setIsActionButtonDisabled(state, value) {
      state.isActionButtonDisabled = value;
    },
    setIsListingLoading(state, value) {
      state.isListingLoading = value;
    },
    setListingTotalItems(state, value) {
      state.listingTotalItems = value;
    }
  },
  actions: {
    async fetchListing({commit, dispatch}, {options, search, only_trashed}) {
      commit('setIsListingLoading', true);

      const {sortBy, sortDesc, page, itemsPerPage} = options;
      const onlyTrashed = only_trashed ? `&only_trashed=1` : '';
      let data = {};

      search ? data.search = {value: search} : null;
      sortBy && sortBy[0] ? data.sort = [{field: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc'}] : null;

      await axiosInstance.post(`/module/customers/search?limit=${itemsPerPage}&page=${page}${onlyTrashed}`, data).then((response) => {
        commit('setFetchedListing', response.data.data);
        commit('setListingTotalItems', response.data.meta.total);
      }).catch(() => {
        dispatch('snackbar/showUnknownError', null, { root: true });
      })

      commit('setIsListingLoading', false);
    },
    async destroyItem({ commit, dispatch }, id) {
      commit('setIsActionButtonDisabled', true);

      Customer.$query().destroy(id, 0).then(() => {
        dispatch('snackbar/showSuccessfullyDelete', null, { root: true });
      }).catch(() => {
        dispatch('snackbar/showDeleteError', null, { root: true });
      })

      commit('setIsActionButtonDisabled', false);
    },
    async restoreItem({ commit, dispatch }, id) {
      commit('setIsActionButtonDisabled', true);

      Customer.$query().restore(id).then(() => {
        dispatch('snackbar/showSuccessfullyRestore', null, { root: true });
      }).catch(() => {
        dispatch('snackbar/showErrorRestore', null, { root: true });
      })

      commit('setIsActionButtonDisabled', false);
    },
    async storeItem({ commit, dispatch }, { form, router }) {
      commit('setIsActionButtonDisabled', true);

      Customer.$query().store(form).then((response) => {
        dispatch('snackbar/showSuccessfullySaved', null, { root: true });
        commit('setIsActionButtonDisabled', false);
        router.push({ name: 'customers-edit', params: { id: response.$attributes.id } });
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, { root: true });
      })

      commit('setIsActionButtonDisabled');
    },
    async updateItem({ commit, dispatch }, form ) {
      commit('setIsActionButtonDisabled', true);

      Customer.$query().update(form.id, form).then((response) => {
        dispatch('snackbar/showSuccessfullySaved', null, { root: true });
        commit('setFetchedDetail', response.$attributes);
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, { root: true });
      })

      commit('setIsActionButtonDisabled');
    },
    async fetchDetail({ commit, dispatch }, id) {
      commit('setIsActionButtonDisabled', true);

      Customer.$query().find(id).then((response) => {
        commit('setFetchedDetail', response.$attributes);
      }).catch(() => {
        dispatch('snackbar/showUnknownError', null, { root: true });
      })

      commit('setIsActionButtonDisabled', false);
    },
    async changeField({ commit, dispatch }, { id, payload }) {
      commit('setIsActionButtonDisabled', true);

      return axiosInstance.post(`module/customers/change-status/${id}`, payload).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, { root: true });
      }).catch(() => {
        dispatch('snackbar/showUnknownError', null, { root: true });
      }).finally(() => {
        commit('setIsActionButtonDisabled', false);
      })
    }
  }
}
