<template>
  <div class="text-center">
    <h3>Выполняется вход</h3>
    <v-progress-circular
      :size="70"
      :width="7"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "Autologin",
  methods: {
    ...mapActions({
      autologin: 'auth/autologin',
      getUser: 'auth/getUser',
    }),
  },
  async created() {
    const user = await this.getUser();

    if (user) {
      await this.$store.dispatch('auth/logout');
    }

    if (this.$route.params && this.$route.params.token) {
      await this.autologin(this.$route.params.token);
      await this.getUser();
      await this.$router.push('/')
    } else {
      await this.$router.push('/auth/login')
    }
  }
}
</script>
