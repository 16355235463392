import EmptyLayout from './layouts/Empty'
import MainLayout from './layouts/Main'
import AuthLayout from './layouts/Auth'
import BlockLayout from './layouts/Block'
import ClearLayout from './layouts/Clear'

export default [
  { id: 'empty-layout', component: EmptyLayout },
  { id: 'main-layout', component: MainLayout },
  { id: 'auth-layout', component: AuthLayout },
  { id: 'block-layout', component: BlockLayout },
  { id: 'clear-layout', component: ClearLayout },
];
