import axiosInstance from "../../../axiosInstance";
import {FieldGroup} from "../../../models/fieldGroup";

export default {
  namespaced: true,
  state: {
    fetchedListing: [],
    fetchedDetail: {},
    isLoading: false,
    isButtonDisabled: false,
    entities: [],
    totalItems: 0,
    entitiesList: [],
  },
  getters: {
    getFetchedListing: (state) => state.fetchedListing,
    getFetchedDetail: (state) => state.fetchedDetail,
    getTotalItems: (state) => state.totalItems,
    getEntities: (state) => state.entities,
    getEntitiesList:(state) => state.entitiesList,
    isLoading: (state) => state.isLoading,
  },
  mutations: {
    setFetchedListing(state, val) {
      state.fetchedListing = val;
    },
    setIsLoading(state, val) {
      state.isLoading = val;
    },
    setTotalItems(state, val) {
      state.totalItems = val;
    },
    setFetchedDetail(state, val) {
      state.fetchedDetail = val;
    },
    setEntities(state, val) {
      state.entities = val;
    },
    setEntitiesList(state, val) {
      state.entitiesList = val;
    }
  },
  actions: {
    async fetchListing({ commit, dispatch }, { options, search }) {
      commit("setIsLoading", true)

      let filters = {
        search: {},
      };

      const { itemsPerPage, page } = options;

      filters.search = { value: search };

      await axiosInstance.post(`module/fields/admin/field-groups/search?limit=${itemsPerPage}&page=${page}`, filters).then((response) => {
        commit('setFetchedListing', response.data.data);
        commit('setTotalItems', response.data.meta.total);
      }).catch(() => {
        dispatch("snackbar/showUnknownError", null, { root: true })
      }).finally(() => {
        commit("setIsLoading", false)
      })
    },
    async destroyItem({ dispatch }, id) {
      await FieldGroup.$query().destroy(id).then(() => {
        dispatch("snackbar/showSuccessfullyDelete", null, { root: true })
      }).catch(() => {
        dispatch("snackbar/showDeleteError", null, { root: true });
      })
    },
    async storeItem({dispatch}, { payload, router }) {
      FieldGroup.$query().store(payload).then((response) => {
        dispatch("snackbar/showSuccessfullySaved", null, { root: true })
        router.push({ name: 'field-groups-edit', params: { id: response.$attributes.id } });
      }).catch(() => {
        dispatch("snackbar/showSaveError", null, { root: true });
      })
    },
    async fetchDetail({ dispatch, commit }, id ) {
      FieldGroup.$query().find(id).then((response) => {
        commit("setFetchedDetail", response.$attributes);
      }).catch(() => {
        dispatch("snackbar/showUnknownError", null, { root: true });
      })
    },
    async updateItem({ dispatch, commit }, form) {
      await FieldGroup.$query().update(form.id, form).then((response) => {
        commit("setFetchedDetail", response.$attributes);
        dispatch("snackbar/showSuccessfullySaved", null, { root: true });
      }).catch(() => {
        dispatch("snackbar/showUnknownError", null, { root: true })
      });
    },
    async fetchEntities({commit}) {
      await axiosInstance.get('module/fields/admin/field-groups/entities').then((response) => {
        commit('setEntities', response.data);
      })
    },
    async fetchEntitiesList({ commit }, entity) {
      await axiosInstance.get('entity/list', { params: { entity: entity } }).then((response) => {
        commit('setEntitiesList', response.data);
      })
    }
  },
}
