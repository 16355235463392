<template>
  <div class="mb-5">
    <v-row class="pb-0">
      <v-col cols="12" class="pb-3">
        <h4>Курс стоит пройти</h4>
      </v-col>
      <v-col cols="12" class="pb-0">
        <v-row
          v-for="(item, index) in reasons"
          :key="index"
          class="pb-0"
        >
          <v-col class="pb-0">
            <v-textarea
              v-model="item.text"
               label="Текст"
              name="text"
               persistent-hint
      outlined
       counter="100"
              required
            ></v-textarea>
          </v-col>

          <v-col class="pb-0">
            <media-widget
              class="mb-1"
              label="Иллюстрация к тексту"
              :medias="item.image"
              :is-multiple="false"
              @onChange="setImage($event, item)"
            />
          </v-col>
          <v-col class="pb-0">

              <v-btn
                icon
                color="red"
                @click="reasons.splice(index, 1)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>

        </v-row>
      </v-col>
      <v-col cols="12">
        <v-btn @click="addItem">
          Добавить элемент
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MediaWidget from "../MediaWidget";

export default {
  name: "CourseReasons",
  props: {
    value: {
      type: Array,
      default: () => ([]),
    }
  },
  components: {
    MediaWidget,
  },
  data() {
    return {
      reasons: [],
    }
  },
  watch: {
    value: {
      handler(value) {
        if (value) {
          this.reasons = value;
        }
      },
      deep: true,
      immediate: true,
    },
    reasons: {
      handler(value) {
        this.$emit('input', value);
      },
      deep: true,
    },
  },
  methods: {
    setImage(image, item) {
      item.image = image;
    },
    addItem() {
      this.reasons.push({
        image: {},
        text: '',
        url: '',
      })
    },
  }
}
</script>
