<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-card v-if="statistic.indicators">
          <v-card-title>
            <v-row align="center">
              <v-col>
                <v-autocomplete :items="mediaList" v-model="selectedMedias" multiple item-value="slug" item-text="title" chips deletable-chips>
                  <template v-slot:prepend-item>
                    <v-list-item ripple @mousedown.prevent @click="selectedMediasToggle">
                      <v-list-item-action>
                        <v-icon :color="selectedMedias.length > 0 ? 'primary' : ''">
                          {{ autoCompleteIcon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          Выбрать всё
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"/>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 3">
                      <span>{{ item.title }}</span>
                    </v-chip>
                    <span
                      v-if="index === 3"
                      class="grey--text text-caption"
                    >
                      (+{{ selectedMedias.length - 3 }} других)
                   </span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col>
                <v-dialog
                  ref="dialog"
                  v-model="dateDialog"
                  :return-value.sync="date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Выберите промежуток"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="date"
                    range
                    scrollable
                    locale="ru-RU"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="dateDialog = false"
                    >
                      Закрыть
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(date)"
                    >
                      Oк
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <div>
              <v-row>
                <v-col sm="3">
                  <div class="text h3">Пользователи</div>
                  <div class="text h3 font-weight-normal">{{ statistic.indicators.users }}</div>
                </v-col>
                <v-col sm="3">
                  <div class="text h3">Просмотр страниц</div>
                  <div class="text h3 font-weight-normal">{{ statistic.indicators.pageviews }}</div>
                </v-col>
                <v-col>
                  <div class="text h3">Ср.длительность просмотра стр.</div>
                  <div class="text h3 font-weight-normal">{{ statistic.indicators.avrDuration }}</div>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="3">
                  <div class="text h3">Подано заявок</div>
                  <div class="text h3 font-weight-normal"> - </div>
                </v-col>
                <v-col sm="3">
                  <div class="text h3">Опубликовано</div>
                  <div class="text h3 font-weight-normal"> {{ statistic.indicators.published }} </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="3">
                  <div class="text h3">Читабельность</div>
                  <div class="text h3 font-weight-normal"> {{ statistic.indicators.readability }} </div>
                </v-col>
                <v-col sm="3">
                  <div class="text h3">Сложность восприятия</div>
                  <div class="text h3 font-weight-normal"> {{ statistic.indicators.complexity }} </div>
                </v-col>
                <v-col sm="3">
                  <div class="text h3">Уровень взаимодействия</div>
                  <div class="text h3 font-weight-normal">{{ statistic.indicators.level }}</div>
                </v-col>
                <v-col sm="3">
                  <div class="text h3">Индекс качества оформления</div>
                  <div class="text h3 font-weight-normal"> - </div>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
        <v-card class="mt-5" v-if="statistic.users">
          <v-card-title>
            <v-row>
              <v-col>
                <h4>Пользователи</h4>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="usersHeaders"
              :items="statistic.users"
              :items-per-page="5"
            />
          </v-card-text>
        </v-card>
        <v-card class="mt-5" v-if="statistic.trafic">
          <v-card-title>
            <v-row>
              <v-col>
                <h4>Распределение по каналам трафика</h4>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <apexchart type="bar" height="350" :options="chartOptions" :series="statistic.trafic.series"/>
          </v-card-text>
        </v-card>
        <v-card class="mt-5">
          <v-card-title>
            Регионы пользователей
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="regionsHeaders"
              :items="statistic.regions"
              :items-per-page="10"
            />
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Media",
  data: () => ({
    dateDialog: false,
    date: [],
    isMounted: false,
    usersHeaders: [
      { text: 'URL', value: 'URL' },
      { text: 'Просмотры страниц', value: 'PageView' },
      { text: 'Пользователи', value: 'Scrolls' },
      { text: 'Уровень взаимодействия', value: 'ingagementLevel' },
    ],
    regionsHeaders: [
      { text: 'Регион', value: 'RegionCity' },
      { text: 'Пользователи', value: 'Users' },
      { text: 'Просмотры страниц', value: 'PageViews' },
    ],
    selectedMedias: [],
    page: {
      title: "Статистика по медиа"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Статистика",
        disabled: true,
        to: '/'
      },
      {
        text: "Статистика по медиа",
        disabled: true,
        to: '/statisticsV2/media'
      },
    ],
  }),
  async created() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() < 9 ? `0${currentDate.getMonth() + 1}` : currentDate.getMonth() + 1;
    const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)

    this.date = [
      `${lastDay.getFullYear()}-${currentMonth}-01`,
      `${lastDay.getFullYear()}-${currentMonth}-${lastDay.getDate()}`
    ];

    await this.fetch(this.payload)
  },
  mounted() {
    this.isMounted = true;
  },
  computed: {
    ...mapGetters({
      'statistic': 'Statistics/getMediaStatistic',
    }),
    payload() {
      return {
        interval: {
          from: this.date[0],
          to: this.date[1],
        },
        list: this.selectedAllMedias ? '' : this.selectedMedias,
      }
    },
    chartOptions() {
      return {
        chart: {
          type: 'bar',
          height: 400
        },
        yaxis: [
          {
            title: {
              text: 'Пользователи'
            }
          },
          {
            opposite: true,
            title: {
              text: 'Просмотры страниц'
            }
          }
        ],
        xaxis: {
          categories: this.statistic.trafic.xaxis.categories,
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top',
            }
          }
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ['#304758'],
          }
        }
      }
    },
    mediaList() {
      if (this.statistic.articlesList) {
        return this.statistic.articlesList;
      }

      return [];
    },
    selectedAllMedias() {
      return this.selectedMedias.length === this.mediaList.length;
    },
    likesSomeMedias() {
      return this.selectedMedias.length > 0 && !this.selectedAllMedias;
    },
    autoCompleteIcon() {
      if (this.selectedAllMedias) return 'mdi-close-box'
      if (this.likesSomeMedias) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline';
    }
  },
  methods: {
    ...mapActions({
      'fetch': 'Statistics/fetchMediaStatistic',
    }),
    selectedMediasToggle() {
      this.$nextTick(() => {
        if (this.selectedAllMedias) {
          this.selectedMedias = [];
        } else {
          this.selectedMedias = this.mediaList.map((item) => item.slug);
        }
      })
    },
    formatDate(date) {
      const currentMonth = date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
      const currentDay = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
      return `${date.getFullYear()}-${currentMonth}-${currentDay}`
    }
  },
  watch: {
    date: {
      async handler() {
        if (this.date.length === 2) {
          const firstDate = new Date(this.date[0]);
          const secondDate = new Date(this.date[1]);

          if (firstDate > secondDate) {
            this.date[0] = this.formatDate(secondDate);
            this.date[1] = this.formatDate(firstDate);
          }

          await this.fetch(this.payload)
        }
      }
    },
    selectedMedias: {
      async handler() {
        await this.fetch(this.payload);
      }
    }
  }
}
</script>

<style scoped>

</style>
