<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <role-form
              :role="role"
              :permissions="permissions"
              :role_permissions="role_permissions"
              :errors="errors"
              @onSubmit="submit"
            />
          </v-col>
        </v-row>
        <v-snackbar
          v-model="snackbarSuccess"
          :timeout="timeout"
          color="success"
        >
          Сохранено

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarSuccess = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar
          v-model="snackbarError"
          :timeout="timeout"
          color="error"
        >
          {{ this.errors }}

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarError = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import RoleForm from '../../../components/roles/RoleForm';
import { Role } from '../../../models/role';
import { Permission } from '../../../models/permission';

export default {
  name: 'Edit',
  components: { RoleForm },
  data: () => ({
    role: new Role(),
    role_permissions: [],
    permissions: [],
    errors: {},
    snackbarSuccess: false,
    snackbarError: false,
    timeout: 2000,
    page: {
      title: "Редактирование роли"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Роли",
        disabled: true,
        to: '/roles'
      },
      {
        text: "Редактирование",
        disabled: true,
        to: '/roles/edit'
      },
    ]
  }),
  created() {
    this.find();
  },
  methods: {
    async submit(permissions, role) {
      try {
        this.errors = {};
        role.$save()
          .then(response => {
            response.permissions().sync(permissions.map(permission => permission.$attributes.id));
          });
        this.snackbarSuccess = true;
        await this.find();
      } catch (e) {
        this.snackbarError = true;
        if (e.response?.data) {
          this.errors = e.response.data.errors
        }
        throw e;
      }
    },
    async find() {
      this.role = await Role.$query().find(this.$route.params.id);
      this.role_permissions = await this.role.permissions().get(100);
      this.permissions = await Permission.$query().get();
    },
  },
  watch: {
    'role.$attributes.name': {
      handler(newTitle) {
        this.page.title +=  ` "${newTitle}"`;
      }
    },
  }
};
</script>

<style scoped>

</style>
