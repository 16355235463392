<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="6">
        <h1>Редактирование медиа контента {{ media.id }}</h1>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="6">
        <media-form
          v-if="isReadyStructure"
          :structure="media.structure"
          @onSubmit="submit"
          style="margin: 0 auto"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MediaForm from '../../../components/medias/MediaForm';
import { Media } from '../../../models/media';
export default {
  name: 'Edit',
  components: { MediaForm },
  data: () => ({
    media: {
      id: null,
      entity: null,
      entity_id: null,
      structure: null,
    },
  }),
  async created() {
    if (!this.$route.params.back_route) {
      await this.$router.push('/');
    }
    if (this.$route.params.id) {
      await this.find();
    }
  },
  computed: {
    isReadyStructure() {
        return this.media.structure && Object.keys(this.media.structure).length > 0
    },
  },
  methods: {
    submit(data) {
      const backRoute = JSON.parse(this.$route.params.back_route);
      this.media.structure = data;
      const params = {
        ...this.$route.params,
        media: JSON.stringify(this.media),
      };
      if (this.$route.params.entity_id) {
        params.id = this.$route.params.entity_id;
      }
      this.$router.push({
        name: backRoute.name,
        params,
      })
    },
    async find() {
      const media = await Media.$query().find(this.$route.params.id);
      this.media = media.$attributes;
    },
  },
};
</script>

<style scoped>

</style>
