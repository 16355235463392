<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
      <v-card>
        <v-card-text class="pa-5">
          <v-row>
            <v-col>
              <FormForm
                @submit="store"
              />
            </v-col>
          </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import FormForm from "../../../components/forms/FormForm";
import {mapActions} from "vuex";
export default {
  name: "Create",
  components: {FormForm},
  data: () => ({
    page: {
      title: "Создание формы"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Forms",
        disabled: true,
        to: '/forms'
      },
      {
        text: "Создать",
        disabled: true,
        to: '/forms/create'
      },
    ]
  }),
  methods: {
    ...mapActions({
      storeItem: 'storeItem',
    }),
    store(form) {
      this.storeItem(form).then(() => this.$router.push({ name: 'forms-index' }));
    }
  }
}
</script>

<style scoped>

</style>
