f<template>
  <v-form>
    <v-text-field
      v-model="form.title"
      :error-messages="errors.title"
      label="Название"
      name="title"
      required
    ></v-text-field>
    <v-text-field
      v-model="form.slug"
      :error-messages="errors.slug"
      label="Slug"
      name="slug"
      required
      :disabled="!(Object.keys(landing).length === 0)"
    ></v-text-field>
    <a
      v-if="landing.preview_url"
      :href="landing.preview_url"
      target="_blank"
    >
      {{ landing.preview_url }}
    </a><br/>
    <v-textarea
      v-model="form.subtitle"
      :error-messages="errors.subtitle"
      label="Подзаголовок"
      name="subtitle"
      required
    ></v-textarea>
    <label>Описание</label>
    <tip-tap v-model="form.description" />
    <media-widget
      class="mb-1"
      label="Изображение"
      :medias="form.image"
      :is-multiple="false"
      @onChange="setImage"
    />
    <v-flex class="mb-2">
      <v-btn
        v-if="isEdit && isModuleBlockEnabled"
        :href="`${clientUrl}/landing/${form.slug}?edit_mode=true&admin_url=${this.$route.fullPath}`"
        color="primary"
        class="mr-2"
      >
        Редактирование блоков
      </v-btn>
    </v-flex>
    <v-flex>
      <v-btn
        class="mr-4"
        color="primary"
        :disabled="isSaveDisabled"
        :loading="isSaveDisabled"
        @click="submit(true)"
      >
        Сохранить
      </v-btn>
      <v-btn
        class="mr-4"
        color="primary"
        :disabled="isSaveDisabled"
        :loading="isSaveDisabled"
        @click="submit(false)"
      >
        Сохранить и продолжить
      </v-btn>
    </v-flex>
    <media-uploader
      @completeUploads="onCompleteUploads"
    />
  </v-form>
</template>

<script>
import slugify from 'slugify';
import MediaWidget from "../MediaWidget";
import MediaUploader from "../MediaUploader";
import TipTap from "../editor/TipTap";
import optionsMixin from "../../mixins/optionsMixin";

export default {
  name: 'LandingForm',
  mixins: [optionsMixin],
  components: {
    MediaUploader,
    MediaWidget,
    TipTap,
  },
  props: {
    landing: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.getForm();
  },
  computed: {
    isModuleBlockEnabled() {
      return process.env.VUE_APP_M_BLOCKS === 'true';
    }
  },
  mounted() {
    this.form.language = this.language;
  },
  data: () => ({
    form: {
      title: null,
      slug: null,
      description: null,
      language: null,
      image: null,
      subtitle: null,
    },
    languages: [
      {text: 'Русский', value: 'ru'},
      {text: 'English', value: 'en'},
    ],
    layouts: [
      'type1',
      'type2'
    ],
    clientUrl: process.env.VUE_APP_CLIENT_URL,
    isSaveDisabled: false,
    mediaIds: [],
  }),
  methods: {
    submit(isNeedRedirect) {
      this.isSaveDisabled = true;
      this.$emit('onSubmit', {
        form: this.form,
        mediaIds: this.mediaIds,
        isNeedRedirect: isNeedRedirect
      });
    },
    async getForm() {
      this.form = JSON.parse(JSON.stringify({...this.landing}));
      this.form.language = this.language;
    },
    setImage(image) {
      this.form.image = image;
    },
    async onCompleteUploads(ids) {
      this.mediaIds = ids;
      await this.submit();
      this.mediaIds = [];
    },
  },
  watch: {
    landing: {
      handler(landing) {
        if (landing && Object.keys(landing).length > 0) {
          this.getForm();
          this.isSaveDisabled = false;
          this.form.language = this.language;
        }
      },
      immediate: true,
    },
    'form.title': {
      handler(value) {
        if (value && Object.keys(this.landing).length === 0) {
          this.form.slug = slugify(value.toLowerCase()).match(/[a-z]|[0-9]|[-]/gm).join('');
        }
      }
    },
  },
};
</script>
