import Index from '../../views/main/sites/Index'
import Edit from '../../views/main/sites/Edit';
import Create from "../../views/main/sites/Create";
import SpeedTest from "../../views/main/sites/SpeedTest";

export default [
  {
    component: Index,
    name: 'site-index',
    path: '/sites',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'sites',
    }
  },
  {
    component: Edit,
    name: 'site-edit',
    path: '/sites/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'sites',
    }
  },
  {
    component: Create,
    name: 'site-create',
    path: '/sites/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'sites',
    }
  },
  {
    component: SpeedTest,
    name: 'site-speed',
    path: '/site-speed',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'sites',
    }
  }
]
