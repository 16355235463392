import Index from '../../views/main/promocodes/Index'
import Create from '../../views/main/promocodes/Create'
import Edit from '../../views/main/promocodes/Edit';

export default [
  {
    component: Index,
    name: 'promocodes-index',
    path: '/promocodes',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'promocode',
    }
  },
  {
    component: Create,
    name: 'promocodes-create',
    path: '/promocodes/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'promocode',
    }
  },
  {
    component: Edit,
    name: 'promocodes-edit',
    path: '/promocodes/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'promocode',
    }
  },
]
