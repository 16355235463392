var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"down-top-padding",attrs:{"fluid":""}},[_c('BaseBreadcrumb',{attrs:{"title":_vm.page.title,"icon":_vm.page.icon,"breadcrumbs":_vm.breadcrumbs}}),_c('v-card',[_c('v-card-text',{staticClass:"pa-5"},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Поиск...","filled":"","background-color":"transparent","hide-details":"","name":"title"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[(_vm.search)?_c('v-btn',{staticClass:"reset-button",attrs:{"slot":"append","icon":"","color":"red"},on:{"click":function($event){_vm.search = null}},slot:"append"},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1)],1),_c('v-col',[_c('v-switch',{attrs:{"label":"Показать удаленные","name":"only_trashed","value":"1"},model:{value:(_vm.only_trashed),callback:function ($$v) {_vm.only_trashed=$$v},expression:"only_trashed"}})],1),_c('v-col',{staticClass:"justify-end align-center d-flex",attrs:{"cols":"12","lg":"4"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"color":"primary","to":"/events/create"}},[_vm._v(" Создать событие ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","href":_vm.getExportUrl}},[_vm._v(" Выгрузить ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-0"},[(_vm.statuses)?_c('v-select',{attrs:{"items":_vm.statuses,"label":"Статус","name":"status","prepend-icon":"mdi-pulse"},model:{value:(_vm.query.status),callback:function ($$v) {_vm.$set(_vm.query, "status", $$v)},expression:"query.status"}}):_vm._e()],1),_c('v-col',{staticClass:"pl-0",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){_vm.query.region_id = null}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"loading":_vm.loading,"server-items-length":_vm.total,"search":_vm.search,"footer-props":{'items-per-page-options':[5, 10, 15, 20, -1]}},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"pill":""}},[_vm._v(_vm._s(item.id))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.status),"content":item.human_status,"label":""}},[_vm._v(_vm._s(item.human_status))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"href":_vm.editItem(item),"icon":"","small":""}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{attrs:{"href":item.preview_url,"target":"_blank","icon":""}},[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v(" mdi-eye ")])],1),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.copyItem(item)}}},[_vm._v(" mdi-content-copy ")])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [(item.deleted_at)?_c('v-icon',{attrs:{"color":"green","small":""},on:{"click":function($event){return _vm.restoreItem(item)}}},[_vm._v(" mdi-backup-restore ")]):_c('v-icon',{attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }