<template>
  <div class="text-center">
    <v-dialog
      v-model="valueModel"
      width="500"
      persistent
      >
      <v-toolbar color="primary" dark>
          <v-spacer/>
          <v-btn icon small @click="valueModel = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
      </v-toolbar>
      <v-card>
        <tip-tap v-model="htmlModel"/>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TipTap from "../../components/editor/TipTap";
export default {
  name: "WysywigPopup",
  components: { TipTap },
  props: ["value", "html"],
  computed: {
    valueModel: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    htmlModel: {
      get() {
        return this.html;
      },
      set(val) {
        this.$emit('html-input', val);
      }
    }
  }
}
</script>

<style scoped>

</style>
