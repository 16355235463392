import axiosInstance from "../../../axiosInstance";

export default {
  namespaced: true,
  actions: {
    async transferData({ dispatch }, tableID) {
      await axiosInstance.post(`module/dynamic-tables/${tableID}/transfer`).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, { root: true })
      }).catch(() => {
        dispatch('snackbar/showUnknownError', null, { root: true })
      })
    }
  }
}
