<template>
  <form v-if="userData">
    <v-text-field
      label="Имя"
      v-model="userData.first_name"
    />
    <v-text-field
      label="Фамилия"
      v-model="userData.last_name"
    />
    <v-text-field
      label="Email"
      v-model="userData.email"
    />
    <v-text-field
      label="Пароль"
      v-model="userData.password"
      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
      :type="show ? 'text' : 'password'"
      @click:append="show = !show"
    />
    <v-file-input
      label="Аватар"
      v-model="userData.avatar"
    ></v-file-input>
    <v-btn
      color="primary"
      :disabled="isSaveDisabled"
      :loading="isSaveDisabled"
      @click="submit"
    >
      Сохранить
    </v-btn>
  </form>
</template>

<script>

export default {
  name: "ProfileForm",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    show: false,
    userData: {},
    isSaveDisabled: false,
  }),
  watch: {
    user: {
      handler(val) {
        this.userData = val.$attributes;
        this.isSaveDisabled = false;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    submit() {
      this.isSaveDisabled = true;

      const formData = new FormData();
      formData.set('first_name', this.userData.first_name);
      formData.set('last_name', this.userData.last_name);
      formData.set('email', this.userData.email);
      formData.set('password', this.userData.password);
      formData.set('avatar', this.userData.avatar);

      this.$emit('onSubmit', formData);
    }
  }
}
</script>
