<template>
  <div>
    <template v-for="(item, index) in items">
      <div class="divider" v-if="item.type === 'divider'" :key="index"/>
      <menu-item
        v-else
        :key="index"
        :title="item.title"
        :action="item.action"
        :icon="item.icon"
        :is-active="item.isActive"
      />
    </template>

    <v-dialog
      v-model="linkDialog"
      width="500"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Ссылка</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field
              v-model="activeLink.href"
              label="URL"
              required
            ></v-text-field>
            <v-checkbox
              v-model="activeLink.target"
              label="Открывать в новой вкладке"
              value="_blank"
              false-value="''"
            ></v-checkbox>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="setLinkAction"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MenuItem from './MenuItem.vue'

export default {
  components: {
    MenuItem,
  },
  props: {
    editor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeLink: {href: null, target: null},
      linkDialog: false,
      items: [
        {
          icon: 'bold',
          title: 'Bold',
          action: () => this.editor.chain().focus().toggleBold().run(),
          isActive: () => this.editor.isActive('bold'),
        },
        {
          icon: 'italic',
          title: 'Italic',
          action: () => this.editor.chain().focus().toggleItalic().run(),
          isActive: () => this.editor.isActive('italic'),
        },
        {
          icon: 'strikethrough',
          title: 'Strike',
          action: () => this.editor.chain().focus().toggleStrike().run(),
          isActive: () => this.editor.isActive('strike'),
        },
        /*
        {
          icon: 'code-view',
          title: 'Code',
          action: () => this.editor.chain().focus().toggleCode().run(),
          isActive: () => this.editor.isActive('code'),
        },

        {
          icon: 'h-1',
          title: 'Heading 1',
          action: () => this.editor.chain().focus().toggleHeading({ level: 1 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 1 }),
        },
        */
        {
          icon: 'h-2',
          title: 'Heading 2',
          action: () => this.editor.chain().focus().toggleHeading({level: 2}).run(),
          isActive: () => this.editor.isActive('heading', {level: 2}),
        },
        {
          icon: 'h-3',
          title: 'Heading 3',
          action: () => this.editor.chain().focus().toggleHeading({level: 3}).run(),
          isActive: () => this.editor.isActive('heading', {level: 3}),
        },
        {
          icon: 'h-4',
          title: 'Heading 4',
          action: () => this.editor.chain().focus().toggleHeading({level: 4}).run(),
          isActive: () => this.editor.isActive('heading', {level: 4}),
        },
        {
          icon: 'h-5',
          title: 'Heading 5',
          action: () => this.editor.chain().focus().toggleHeading({level: 5}).run(),
          isActive: () => this.editor.isActive('heading', {level: 5}),
        },
        {
          icon: 'paragraph',
          title: 'Paragraph',
          action: () => this.editor.chain().focus().setParagraph().run(),
          isActive: () => this.editor.isActive('paragraph'),
        },
        {
          icon: 'link',
          title: 'Link',
          action: () => {
            this.activeLink = this.editor.getAttributes('link');
            this.linkDialog = true;
          },
          isActive: () => this.editor.isActive('link'),
        },
        {
          icon: 'link-unlink',
          title: 'Unlink',
          action: () => {
            this.editor
              .chain()
              .focus()
              .unsetLink()
              .run();
          },
          isActive: () => this.editor.isActive('link'),
        },
        {
          icon: 'list-unordered',
          title: 'Bullet List',
          action: () => this.editor.chain().focus().toggleBulletList().run(),
          isActive: () => this.editor.isActive('bulletList'),
        },
        {
          icon: 'list-ordered',
          title: 'Ordered List',
          action: () => this.editor.chain().focus().toggleOrderedList().run(),
          isActive: () => this.editor.isActive('orderedList'),
        },
        /*
        {
          icon: 'list-check-2',
          title: 'Task List',
          action: () => this.editor.chain().focus().toggleTaskList().run(),
          isActive: () => this.editor.isActive('taskList'),
        },

        {
          icon: 'code-box-line',
          title: 'Code Block',
          action: () => this.editor.chain().focus().toggleCodeBlock().run(),
          isActive: () => this.editor.isActive('codeBlock'),
        }, */
        {
          icon: 'double-quotes-l',
          title: 'Blockquote',
          action: () => this.editor.chain().focus().toggleBlockquote().run(),
          isActive: () => this.editor.isActive('blockquote'),
        },
        {
          icon: 'separator',
          title: 'Horizontal Rule',
          action: () => this.editor.chain().focus().setHorizontalRule().run(),
        },
        /*
        {
          icon: 'text-wrap',
          title: 'Hard Break',
          action: () => this.editor.chain().focus().setHardBreak().run(),
        }, */
        {
          icon: 'format-clear',
          title: 'Clear Format',
          action: () => this.editor.chain()
            .focus()
            .clearNodes()
            .unsetAllMarks()
            .run(),
        },
        {
          icon: 'align-left',
          title: 'Align left',
          action: () => this.editor.chain().focus().setTextAlign('left').run(),

        },
        {
          icon: 'align-center',
          title: 'Align center',
          action: () => this.editor.chain().focus().setTextAlign('center').run(),

        },
        {
          icon: 'align-right',
          title: 'Align right',
          action: () => this.editor.chain().focus().setTextAlign('right').run(),

        },
        {
          icon: 'arrow-go-back-line',
          title: 'Undo',
          action: () => this.editor.chain().focus().undo().run(),
        },
        {
          icon: 'arrow-go-forward-line',
          title: 'Redo',
          action: () => this.editor.chain().focus().redo().run(),
        },
      ],
    }
  },
  methods: {
    setLinkAction() {
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink(this.activeLink)
        .run();

      this.activeLink = {href: null, target: null};
      this.linkDialog = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.divider {
  width: 2px;
  height: 1.25rem;
  background-color: black;
  margin-left: 0.5rem;
  margin-right: 0.75rem;
}
</style>
