<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-data-table
      :headers="headers"
      :options.sync="options"
      :items="fetchedListing"
      :server-items-length="listingTotalItems"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Языки</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog max-width="800px" v-model="dialog">
            <template v-slot:activator="{ on, attrs }">
              <v-btn fab small color="primary" v-bind="attrs" v-on="on">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Добавление языка</span>
              </v-card-title>
              <v-card-text>
                <v-text-field label="Язык" v-model="form.language"/>
                <v-text-field label="ISO" v-model="form.iso_format"/>
                <v-text-field label="Флаг" v-model="form.flag_icon"/>
                <v-switch label="Являеться языком по умолчанию" v-model="form.is_default"/>
              </v-card-text>
              <v-card-actions>
                <v-row>
                  <v-col>
                    <v-btn block color="primary" @click="save">
                      Сохранить
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn block color="primary" @click="dialog = false">
                      Закрыть
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.is_default="{ item }">
        <v-icon :color="item.is_default ? 'green' : 'red'">mdi-circle</v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn icon small color="primary">
          <v-icon @click="edit(item)">mdi-pen</v-icon>
        </v-btn>
        <v-btn icon small color="error">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "Index",
  data: () => ({
    page: {
      title: "Языки"
    },
    empty_form: {
      id: null,
      language: null,
      iso_format: null,
      flag_icon: null,
      is_default: false,
    },
    form: {
      id: null,
      language: null,
      iso_format: null,
      flag_icon: null,
      is_default: false,
    },
    only_trashed: null,
    options: {
      sortBy: ['id'],
      sortDesc: [true],
      page: 1,
      itemsPerPage: 20,
    },
    headers: [
      { text: "ID", value: "id" },
      { text: "Язык", value: "language" },
      { text: "ISO", value: "iso_format" },
      { text: "Флаг", value: "flag_icon" },
      { text: 'Язык по умолчанию', value: 'is_default' },
      { text: '', value: 'actions' },
    ],
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Языки",
        disabled: true,
        to: '/lang'
      },
    ],
    dialog: false,
  }),
  created() {
    this.fetchListing(this.payload);
  },
  computed: {
    ...mapGetters({
       fetchedListing: "Languages/getFetchedListing",
       listingTotalItems: "Languages/getListingTotalItems",
    }),
    payload() {
      return {
        options: this.options,
        search: this.search,
        only_trashed: this.only_trashed,
      };
    }
  },
  methods: {
    ...mapActions({
      fetchListing: "Languages/fetchListing",
      createItem: "Languages/createItem",
      updateItem: "Languages/updateItem",
    }),
    save() {
      if (this.form.id === null) {
        this.createItem(this.form).then(() => {
          this.dialog = false;
          this.fetchListing(this.payload);
        })
      } else {
        this.updateItem(this.form).then(() => {
          this.dialog = false;
          this.fetchListing(this.payload);
        })
      }
    },
    edit(item) {
      this.form = JSON.parse(JSON.stringify(item));
      this.dialog = true;
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchListing(this.payload);
      },
      deep: true,
      immediate: true,
    },
    dialog: {
      handler(val) {
        if (val === false) {
          this.form = this.empty_form;
        }
      }
    }
  },
}
</script>

<style scoped>

</style>
