import {Model} from "@tailflow/laravel-orion/lib/model";

export class Program extends Model<{
    title: string,
    url: string,
}> {

    $resource(): string {
        return "module/lms/admin/programs";
    }
}
