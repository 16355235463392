<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <h1>Редактирование курса {{ course.id }}</h1>
          </v-col>
        </v-row>
        <v-tabs
          v-model="tab"
          background-color="transparent"
          grow
          class="mb-5"
        >
          <v-tab>
            Основная информация
          </v-tab>
          <v-tab>
            Уроки
          </v-tab>
          <v-tab>
            Таксономия
          </v-tab>
          <v-tab>
            Письма
          </v-tab>
          <v-tab>
            SEO
          </v-tab>
          <v-tab>
            SMM
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <course-form
              :course="course"
              @onSubmit="submit"
            />
          </v-tab-item>
          <v-tab-item>
            <course-lessons-form
              :course="course"
              @onSubmit="submitLessons"
              @reloadEntity="find"
            />
          </v-tab-item>
          <v-tab-item>
            <collection-relation-form
              module-name="Courses"
              :errors="errors"
              :selected-collection-items="course.selected_collection_items"
              :main-items="course.main_taxonomy_ids"
              @onSubmit="submitCollections"
              @onSubmitMain="submitCollectionsMainItems"
            />
          </v-tab-item>
          <v-tab-item>
            <entity-mails
              :entity_type="course.entity_type"
              :entity_id="course.id"
              :mails="course.mails"
            />
          </v-tab-item>
          <v-tab-item>
            <seo-form-separate
              :entity="course"
              :errors="errors"
              :is-disabled="isOccupied"
              @onSubmit="submit"
            />
          </v-tab-item>
          <v-tab-item>
            <smm-form-separate
              :entity="course"
              :errors="errors"
              :is-disabled="isOccupied"
              @onSubmit="submit"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import CourseForm from '../../../components/courses/CourseForm';
import CollectionRelationForm from "../../../components/collections/CollectionRelationForm";
import CourseLessonsForm from "../../../components/courses/CourseLessonsForm";
import {mapActions, mapGetters} from "vuex";
import EntityMails from "../../../components/EntityMails";
import SeoFormSeparate from "../../../components/SEOFormSeparate";
import SmmFormSeparate from "../../../components/SMMFormSeparate";

export default {
  name: 'Edit',
  components: {
    EntityMails,
    CourseForm,
    CourseLessonsForm,
    CollectionRelationForm,
    SeoFormSeparate,
    SmmFormSeparate,
  },
  data: () => ({
    tab: 0,
    errors: {},
    isOccupied: false,
    page: {
      title: "Создание курса"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Курсы",
        disabled: false,
        to: '/courses/'
      },
      {
        text: "Создание",
        disabled: true,
        to: '/courses/create'
      },
    ],
  }),
  created() {
    this.find();
  },
  computed: {
    ...mapGetters({
      course: 'Courses/getFetchedDetail',
    })
  },
  methods: {
    ...mapActions({
      fetchDetail: 'Courses/fetchDetail',
      updateItem: 'Courses/updateItem',
      storeCollections: 'Courses/storeCollections',
      storeCollectionMainItems: 'Courses/storeCollectionsMainItems',
      storeLessons: 'Courses/storeLessons'
    }),
    find() {
      this.fetchDetail(this.$route.params.id);
    },
    async submit({form, mediaIds, isNeedRedirect}) {
      const payload = {
        form: form,
        media: mediaIds,
        id: form.id,
      }

      this.updateItem(payload).then(() => {
        if (isNeedRedirect) {
          this.$router.push({name: 'courses-index'});
        }
      })
    },
    async submitCollections({ items, isNeedRedirect }) {
      if (items.length > 0) {
        const payload = {
          id: this.$route.params.id,
          form: items,
        }

        this.storeCollections(payload).then(() => {
          if (isNeedRedirect) {
            this.$router.push({name: 'courses-index'});
          }
        });
      }
    },
    async submitCollectionsMainItems(items) {
      const payload = {
        id: this.$route.params.id,
        items: items,
      }

      this.storeCollectionMainItems(payload);
    },
    async submitLessons(ids) {
      const payload = {
        id: this.$route.params.id,
        lessons: ids,
      }

      this.storeLessons(payload);
    },
  },
};
</script>
