import Create from '../../views/main/mails/Create'
import Edit from '../../views/main/mails/Edit';

export default [
  {
    component: Create,
    name: 'mails-create',
    path: '/mails/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
  {
    component: Edit,
    name: 'mails-edit',
    path: '/mails/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
]
