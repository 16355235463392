export default {
  computed: {
    getPreviewUrl() {
      if (!this.entity.preview_url) return '';

      let url = new URL(this.entity.preview_url);

      if (url.pathname.toString().slice(-1) != '/')
        url.pathname = url.pathname + '/';

      return url.pathname === '/index/' ? '' : url.pathname;
    },
    getHostname() {
      if (!this.entity.preview_url) return '';

      let url = this.entity.preview_url.replace(/http(.*?)\/\//, '');
      let hostname = url.match(/(.*?)\//)[1];

      return hostname;
    },
    getFullUrl() {
      return 'https://' + this.getHostname + this.getPreviewUrl;
    },
  }
}
