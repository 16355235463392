import Index from '../../views/main/forms/Index'
import Create from '../../views/main/forms/Create'
import Edit from '../../views/main/forms/Edit';

export default [
  {
    component: Index,
    name: 'forms-index',
    path: '/forms',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
  {
    component: Create,
    name: 'forms-create',
    path: '/forms/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
  {
    component: Edit,
    name: 'forms-edit',
    path: '/forms/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
]
