import { Model } from "@tailflow/laravel-orion/lib/model";

export class WebmasterPartners extends Model<{
    id: number,
    name: string,
    code: string,
    created_at: string,
    updated_at: string,
}> {

    $resource(): string {
        return "webmasterPartners";
    }
}
