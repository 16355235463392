<template>
  <div>
    <v-alert :type="this.alert.type">
      <v-row align="center">
        <v-col class="grow">
          {{ this.alert.message }}
        </v-col>
        <v-col
          v-if="isOccupied"
          class="shrink"
        >
          <v-btn @click="freePage">Освободить страницу</v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
import axiosInstance from "../../axiosInstance";

export default {
  name: "Alert",
  props: {
    itemId: {
      required: true,
      type: Number,
    },
    prefix: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      isOccupied: false,
      occupiedUser: null,
      intervalId: null,
      alert: {
        type: null,
        message: null,
      }
    };
  },
  mounted() {
    if (this.itemId && this.prefix) {
      this.startTimerCheckOccupied();
      this.checkOccupied().then(() => {
        if (this.isOccupied) {
          this.alert.type = 'error';
          this.alert.message = `В данный момент страница редактируется другим админом: ${this.occpiedUser}`;
        }
      });

      window.onbeforeunload = () => this.cancelOccupied();
      this.$router.beforeEach((to, from, next) => {
        this.cancelOccupied();
        next();
      });
    }
  },
  methods: {
    freePage() {
      this.checkOccupied().then(() => {
        if (this.isOccupied) {
          const params = {
            prefix: this.prefix,
            itemId: this.itemId,
          };

          axiosInstance.get(`${process.env.VUE_APP_API_URL}/api/auth/occupied_page/free`, {params});

          this.checkOccupied();
        }
      });
    },
    checkOccupied() {
      return new Promise((resolve, reject) => {
        const params = {
          prefix: this.prefix,
          itemId: this.itemId,
        };

        axiosInstance.get(`${process.env.VUE_APP_API_URL}/api/auth/occupied_page/check`, {params}).then(
          (response) => {
            if (response.data.status !== 200) {
              this.isOccupied = true;
              this.occpiedUser = response.data.user;

              this.alert.type = 'error';
              this.alert.message = `В данный момент страница редактируется другим админом: ${this.occpiedUser}`;
            } else {
              this.isOccupied = false;
              this.occpiedUser = null;

              this.alert.type = 'success';
              this.alert.message = 'Страница свободна';
            }
            resolve();
          },
        ).catch(() => {
          reject();
        });
      });
    },
    startTimerCheckOccupied() {
      this.intervalId = setInterval(() => {
        this.checkOccupied().then(() => {
          if (this.isOccupied) {
            this.alert.type = 'error';
            this.alert.message = `В данный момент страница редактируется другим админом: ${this.occpiedUser}`;
          }
        });
      }, 60000);
    },
    async cancelOccupied() {
      const params = {
        prefix: this.prefix,
        itemId: this.itemId,
      };
      await axiosInstance.get(`${process.env.VUE_APP_API_URL}/api/auth/occupied_page/delete`, {params});

      clearInterval(this.intervalId);
      window.onbeforeunload = () => {};
      this.$router.beforeEach((to, from, next) => {
        next();
      });

      return 'Закрываем окошко';
    },
  },
  watch: {
    isOccupied: {
      handler(value) {
        this.$emit('change', value);
      },
    }
  }
}
</script>
