import axiosInstance from "../../../axiosInstance";

export default {
  namespaced: true,
  state: {
    tempData: [],
  },
  getters: {
    getTempData: (state) => state.tempData,
  },
  mutations: {
    setTempData(state, val) {
      state.tempData = val;
    }
  },
  actions: {
    fetchTempData({ commit, dispatch }, id) {
      axiosInstance.get(`temp-data/${id}`).then((resp) => {
        commit('setTempData', resp.data);
      }).catch(() => {
        dispatch("snackbar/showUnknownError", null, { root: true })
      });
    },
    saveTempData({ dispatch, state }, id) {
      axiosInstance.post(`temp-data/${id}`, { tempData: state.tempData }).then(() => {
        dispatch("fetchTempData", id);
      }).then(() => {
        dispatch("snackbar/showSuccessfullySaved", null, { root: true })
      }).catch(() => {
        dispatch("snackbar/showSaveError", null, { root: true })
      })
    }
  },
}
