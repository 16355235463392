<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <faq-form
              :errors="errors"
              @onSubmit="submit"
            />
          </v-col>
        </v-row>
        <v-snackbar
          v-model="snackbarSuccess"
          :timeout="timeout"
          color="success"
        >
          Сохранено

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarSuccess = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar
          v-model="snackbarError"
          :timeout="timeout"
          color="error"
        >
          {{ this.errors }}

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarError = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import FaqForm from '../../../components/faqs/FaqForm';
import { FAQ } from '../../../models/FAQ';

export default {
  name: 'Create',
  components: { FaqForm },
  data: () => ({
    person_id: null,
    errors: {},
    snackbarSuccess: false,
    snackbarError: false,
    timeout: 2000,
    page: {
      title: "Создание элемента"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Вопросы и ответы",
        disabled: true,
        to: '/faqs'
      },
      {
        text: "Создание",
        disabled: true,
        to: '/faqs/create'
      },
    ]
  }),
  mounted() {
    if (this.$route.params.person_id) {
      this.person_id = this.$route.params.person_id
    }
  },
  methods: {
    async submit({ form, mediaIds, isNeedRedirect }) {
      try {
        this.errors = {};
        if (mediaIds) {
          form.mediaIds = mediaIds;
        }
        const faq = await FAQ.$query().store(form);
        this.snackbarSuccess = true;

        if (isNeedRedirect) {
          await this.$router.push({ name: 'faqs-index' });
        } else {
          await this.$router.push({ name: 'faqs-edit', params: { id: faq.$attributes.id } });
        }


      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors
          this.snackbarError = true;
        }
        throw e;
      }
    },
  },
};
</script>
