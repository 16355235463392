<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <h1>Редактирование элемента каталога {{ fetchedDetail.title }} </h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-tabs v-model="tab">
              <v-tab>
                Основная информация
              </v-tab>
              <v-tab>
                Таксономия
              </v-tab>
              <v-tab>
                SEO
              </v-tab>
              <v-tab>
                SMM
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <CatalogItemForm :catalog-item="fetchedDetail" @onSubmit="submit"/>
              </v-tab-item>
              <v-tab-item>
                <CollectionRelationForm
                  :errors="{}"
                  module-name="Catalogs"
                  :selected-collection-items="fetchedDetail.selected_collection_items"
                  :main-items="fetchedDetail.main_taxonomy_ids"
                  @onSubmit="submitCollection"
                  @onSubmitMain="submitMainCollection"
                />
              </v-tab-item>
              <v-tab-item>
                <seo-form-separate
                  :entity="fetchedDetail"
                  :errors="{}"
                  @onSubmit="submit"
                />
              </v-tab-item>
              <v-tab-item>
                <smm-form-separate
                  :entity="fetchedDetail"
                  :errors="{}"
                  @onSubmit="submit"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import CatalogItemForm from "../../../../components/catalogs/CatalogItemForm";
import {mapActions, mapGetters} from "vuex";
import CollectionRelationForm from "../../../../components/collections/CollectionRelationForm";
import SeoFormSeparate from "../../../../components/SEOFormSeparate";
import SmmFormSeparate from "../../../../components/SMMFormSeparate";

export default {
  name: "Edit",
  components: {
    CollectionRelationForm,
    CatalogItemForm,
    SeoFormSeparate,
    SmmFormSeparate,
  },
  data: () => ({
    tab: 0,
    page: {
      title: "Редактирование каталог"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Каталог",
        disabled: true,
        to: '/catalogs'
      },
      {
        text: "Редактирование",
        disabled: true,
        to: '/catalogs/edit'
      },
    ],
  }),
  async created() {
    await this.fetchDetail(this.$route.params.id);
  },
  computed: {
    ...mapGetters({
      "fetchedDetail": "CatalogItem/getFetchedDetail"
    })
  },
  methods: {
    ...mapActions({
      "fetchDetail": "CatalogItem/fetchDetail",
      "updateItem": "CatalogItem/updateItem",
      "submitCollections": "CatalogItem/submitCollections",
      "submitMainCollections": "CatalogItem/makeMainCollectionItem",
    }),
    async submitCollection({ items, isNeedRedirect }) {
      await this.submitCollections({ items: items}).then(() => {
        if (isNeedRedirect) {
          this.$router.push({ name: 'catalog-items-index', params: { catalog_id: this.$route.params.catalog_id } });
        }
      });
    },
    async submitMainCollection(items) {
      await this.submitMainCollections({items: items});
    },
    submit({ form, isNeedRedirect }) {
      this.updateItem({ form: form, isNeedRedirect: isNeedRedirect, router: this.$router, catalog_id: this.$route.params.catalog_id });
    }
  }
}
</script>

<style scoped>

</style>
