import Index from '../../views/main/articles/Index'
import Create from '../../views/main/articles/Create'
import Edit from '../../views/main/articles/Edit';

export default [
  {
    component: Index,
    name: 'articles-index',
    path: '/articles',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      hasAccess: 'article.view',
      module: 'articles',
    }
  },
  {
    component: Create,
    name: 'articles-create',
    path: '/articles/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      hasAccess: 'article.create',
      module: 'articles',
    }
  },
  {
    component: Edit,
    name: 'articles-edit',
    path: '/articles/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      hasAccess: 'article.edit',
      module: 'articles',
    }
  },
]
