<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <source-form
              :source="source"
              :errors="errors"
              @onSubmit="submit"
            />
          </v-col>
        </v-row>
        <v-snackbar
          v-model="snackbarSuccess"
          :timeout="timeout"
          color="success"
        >
          Сохранено

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarSuccess = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar
          v-model="snackbarError"
          :timeout="timeout"
          color="error"
        >
          {{ this.errors }}

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarError = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import SourceForm from '../../../components/sources/SourceForm';
import {Source} from '../../../models/source';

export default {
  name: 'Edit',
  components: {SourceForm},
  data: () => ({
    source: {},
    errors: {},
    snackbarSuccess: false,
    snackbarError: false,
    timeout: 2000,
    page: {
      title: "Редактирование источника"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Источники",
        disabled: true,
        to: '/sources'
      },
      {
        text: "Редактирование",
        disabled: true,
        to: '/sources/edit'
      },
    ]
  }),
  created() {
    this.find();
  },
  methods: {
    async submit(form, isNeedRedirect) {
      try {
        this.errors = {};
        await Source.$query().update(form.id, form);
        await this.find();
        this.snackbarSuccess = true;

        if (isNeedRedirect) {
          this.$router.push({ name: 'sources-index' });
        }
      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors
        }
        this.snackbarError = true;
        throw e;
      }
    },
    async find() {
      const source = await Source.$query().find(this.$route.params.id);
      this.source = source.$attributes;
    }
  },
  watch: {
    'source.title': {
      handler(newTitle) {
        this.page.title += ` "${newTitle}"`;
      }
    },
  }
};
</script>
