<template>
  <div class="facebook-preview">
    <div class="card-seo-facebook">
      <div v-if="$data.facebookImg" class="card-seo-facebook__image js-preview-image" :style="$data.facebookImg"></div>
      <div class="card-seo-facebook__text">
        <span class="card-seo-facebook__link js-preview-domain">{{ getHostname }}</span>
        <div class="card-seo-facebook__content">
          <div style="margin-top:5px">
            <div class="card-seo-facebook__title js-preview-title">{{ title }}</div>
          </div>
          <span class="card-seo-facebook__description js-preview-description">{{ description }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import smmMixin from "@/mixins/smmMixin";
import seoAndSmmWidgetMixin from "@/mixins/seoAndSmmWidgetMixin";

export default {
  mixins: [
    smmMixin,
    seoAndSmmWidgetMixin
  ],
  props: {
    form: {
      type: Object,
    },
    entity: {
      type: Object,
    },
  },
  data() {
    return {
      title: '',
      facebookImg: '',
      description: '',
      descriptionLength: 215,
    }
  },
  methods: {
    ...mapActions({
      dynamicResize: "Medias/dynamicResize"
    }),
    cutString(string, count) {
      if (string.length > count) {
        return string.substring(0, count) + '...';
      }

      return string;
    }
  },
  watch: {
    'form.smm_image': {
      async handler(val) {
        if (val != null) {
          let value = val.url || this.entity.smm_image.url;

          if (typeof value != 'string') return;

          const url = (await this.dynamicResize({ media: val.id, width: 1200, height: 630 })).data;

          this.$data.facebookImg = `background-image: url(${url})`;
        } else {
          this.$data.facebookImg = '';
        }
      },
      immediate: true,
    },
    'form.smm_description': {
      handler(val) {
        let value = val || this.entity.lead || this.entity.description;

        if (typeof value != 'string') return;

        this.description = this.cutString(value, this.descriptionLength) || this.entity.lead;
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
  .facebook-preview {
    margin-bottom: 15px;
  }

  .card-seo-facebook {
    width: 500px;
    cursor: pointer;
    position: relative;
    display: block;
    background-color: #ffffff;
    margin: 0;
    overflow: hidden;
    font-size: 14px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
    -webkit-font-smoothing: subpixel-antialiased;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out;

    .card-seo-facebook__image {
      border: 1px solid #dadde1;
      border-bottom: none;
      width: 500px;
      height: 261px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    .card-seo-facebook__text {
      border: 1px solid #dadde1;
      padding: 10px 12px;
      color: #1d2129;
      border-bottom: 1px solid #dadde1;
      background: #f2f3f5;

      .card-seo-facebook__link {
        border-collapse: separate;
        color: #606770;
        direction: ltr;
        display: block;
        font-family: Helvetica, Arial, sans-serif;
        font-size: 12px;
        height: 11px;
        line-height: 11px;
        overflow-x: hidden;
        overflow-y: hidden;
        text-align: left;
        text-overflow: ellipsis;
        text-transform: uppercase;
        user-select: none;
        white-space: nowrap;
        width: 501px;
        word-wrap: break-word;
      }

      .card-seo-facebook__content {
        border-collapse: separate;
        color: #4b4f56;
        direction: ltr;
        display: block;
        font-family: Helvetica, Arial, sans-serif;
        font-size: 12px;
        height: 46px;
        line-height: 14.4px;
        max-height: 46px;
        overflow-x: hidden;
        overflow-y: hidden;
        text-align: left;
        user-select: none;
        word-wrap: break-word;

        .card-seo-facebook__title {
          margin-top: 5px;
          border-collapse: separate;
          color: #1d2129;
          cursor: pointer;
          direction: ltr;
          display: inline;
          font-family: Helvetica, Arial, sans-serif;
          font-size: 14px;
          font-weight: 600;
          height: auto;
          letter-spacing: normal;
          line-height: 18px;
          text-align: left;
          text-decoration-color: #1d2129;
          text-decoration-line: none;
          text-decoration-style: solid;
          transition-delay: 0s;
          transition-duration: 0.1s;
          transition-property: color;
          transition-timing-function: ease-in-out;
          user-select: none;
          white-space: normal;
          width: auto;
          word-wrap: break-word;
        }

        .card-seo-facebook__description {
          border-collapse: separate;
          color: #606770;
          direction: ltr;
          display: -webkit-box;
          font-family: Helvetica, Arial, sans-serif;
          font-size: 14px;
          height: 18px;
          line-height: 20px;
          margin-top: 3px;
          max-height: 80px;
          overflow-x: hidden;
          overflow-y: hidden;
          text-align: left;
          text-overflow: ellipsis;
          user-select: none;
          white-space: normal;
          word-break: break-word;
          word-wrap: break-word;
          -webkit-border-horizontal-spacing: 0px;
          -webkit-border-vertical-spacing: 0px;
          -webkit-box-orient: vertical;
          -webkit-font-smoothing: antialiased;
          -webkit-line-clamp: 1;
        }
      }
    }
  }
</style>
