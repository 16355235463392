var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"mb-5"},[_c('v-card-title',[_vm._v("Дочерние элементы")]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.child},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"mb-5",attrs:{"color":"primary","disabled":_vm.child.length === 0},on:{"click":function($event){_vm.showSortableModal = true}}},[_vm._v(" Сортировка позиций ")])],1),_c('v-col',[_c('v-spacer'),_c('v-btn',{staticClass:"mb-5",attrs:{"color":"primary","href":_vm.createChild()}},[_vm._v(" Создать дочерний элемент ")])],1)],1)]},proxy:true},{key:"item.edit_mode",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":_vm.getEditUrl(item),"target":"_blank","title":"Открыть страницу в режиме редактирования блоков"}},[_c('svg-icons',{attrs:{"icon-name":"aspect-ratio"}})],1)]}},{key:"item.title_mode",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":_vm.getEditUrl(item),"target":"_blank","title":"Открыть страницу в режиме редактирования блоков"}},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('br'),_c('small',[_vm._v(_vm._s(item.slug))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.preview_url,"target":"_blank"}},[_c('v-icon',{staticClass:"mr-2 info--text",attrs:{"small":""}},[_vm._v("mdi-eye")])],1),_c('v-btn',{attrs:{"href":_vm.editItem(item),"icon":""}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-pencil ")])],1),_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.position",fn:function(ref){
var item = ref.item;
return [_c('tr',[_vm._v(" "+_vm._s(item.position + 1)+" ")])]}}])})],1)],1),(false)?_c('v-card',[_c('v-card-title',[_vm._v("Форма для добавление существующего элемента")]),_c('v-card-subtitle',[_vm._v("Выводится только те страницы у которых нет родительской страницы")]),_c('v-card-text',[_c('v-select',{attrs:{"multiple":"","item-text":"title","items":_vm.pagesWithoutParent,"item-value":"id"},model:{value:(_vm.selectedPages),callback:function ($$v) {_vm.selectedPages=$$v},expression:"selectedPages"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","block":"","disabled":_vm.isSaveButtonDisable,"loading":_vm.isSaveButtonDisable},on:{"click":_vm.attach}},[_vm._v(" Сохранить ")])],1)],1):_vm._e(),_c('SortablePages',{model:{value:(_vm.showSortableModal),callback:function ($$v) {_vm.showSortableModal=$$v},expression:"showSortableModal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }