import { Model } from "@tailflow/laravel-orion/lib/model";
import {HasOne} from "@tailflow/laravel-orion/lib/drivers/default/relations/hasOne";
import {BelongsTo} from "@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo";
import {BlockGroup} from "./blockGroup";

export class Site extends Model<{
    id: number,
    title: string,
    domain: string,
}> {

    $resource(): string {
        return "module/sites";
    }
}
