<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row justify="space-between">
          <v-col cols="12" lg="4">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Поиск..."
              filled
              background-color="transparent"
              hide-details
              name="title"
            >
              <v-btn v-if="search" class="reset-button"
                     slot="append"
                     icon
                     @click="search = null"
                     color="red"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-text-field>
          </v-col>
          <v-col>
            <v-switch
              v-model="only_trashed"
              label="Показать удаленные"
              name="only_trashed"
              value="1"
            ></v-switch>
          </v-col>
          <v-col cols="12" lg="4" class="justify-end align-center d-flex">
            <v-dialog v-model="dialog" persistent max-width="700px">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" to="/regions/create">
                  Создать регион
                </v-btn>
                <v-btn color="primary" class="ml-2" :href="getExportUrl">
                  Выгрузить
                </v-btn>
              </template>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="auto">
            <v-row align="center">
              <v-col class="pr-0">
                <v-select
                  v-model="query.published"
                  :items="statuses"
                  label="Выберите статус"
                  name="published"
                  prepend-icon="mdi-publish"
                ></v-select>
              </v-col>
              <v-col cols="auto" class="pl-0">
                <v-btn
                  icon
                  @click="query.published = null"
                  color="red"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
              <v-col>
                <v-select
                  label="В работе"
                  v-model="scope"
                  :items="taskItems"
                  item-value="value"
                  item-text="title"/>
              </v-col>
              <v-col>
                <v-menu
                  v-model="datePicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="query.created_at"
                      label="Дата создания"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      append-icon="mdi-close"
                      @click:append="query.created_at = null"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="query.created_at"
                    locale="ru-RU"
                    @input="datePicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn @click="detachAll" color="primary" class="mb-4" block>
             Приступить к задаче(очистить звездочки)
            </v-btn>
            <v-data-table
              :headers="headers"
              :items="items"
              :options.sync="options"
              :footer-props="{'items-per-page-options':[15, 30, 50, 100, -1]}"
              :loading="loading"
              :server-items-length="total"
              :search="search"
            >
              <template v-slot:item.id="{ item }">
                <v-chip pill>{{ item.id }}</v-chip>
              </template>
              <template v-slot:item.title="{ item }">
                {{ item.title }}<br/>
                <v-chip v-if="item.published" color="green" content="Опубликовано" label>Опубликовано</v-chip>
                <v-chip color="red" content="Не опубликовано" label>Не опубликовано</v-chip>
              </template>
              <template v-slot:item.bookmarks="{ item }">
                <v-btn icon v-if="item.is_bookmarked" @click="detach(item)">
                  <v-icon color="orange" small>
                    mdi-star
                  </v-icon>
                </v-btn>
                <v-btn icon v-else @click="attach(item)">
                  <v-icon  small>
                    mdi-star
                  </v-icon>
                </v-btn>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  v-if="item.deleted_at"
                  color="green"
                  @click="restoreItem(item)"
                  small
                >
                  mdi-backup-restore
                </v-icon>
                <template v-else>
                  <v-btn
                    :href="editItem(item)"
                    icon
                  >
                    <v-icon color="primary" small>
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                  <v-icon
                    color="red"
                    @click="deleteItem(item)"
                    small
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <v-btn
                  :href="item.preview_url"
                    target="_blank"
                  icon>
                  <v-icon color="primary" small>
                    mdi-eye
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {Region} from '../../../models/region';
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'Index',
  data: () => ({
    options: {
      sortBy: ['id'],
      sortDesc: [true],
      page: 1,
      itemsPerPage:30,
    },
    taskItems: [
      { value: null, title: 'Все' },
      { value: 'onlyMarked', title: 'Только отмеченные' },
      { value: 'withoutMarked', title: 'Только не отмеченные' },
    ],
    search: '',
    only_trashed: 0,
    query: {
      published: null,
      created_at: null,
    },
    scope: null,
    datePicker: false,
    headers: [
      {text: 'Id', value: 'id'},
      {text: 'Заголовок', value: 'title'},
      {text: 'Обработано', value: 'bookmarks'},
      {text: 'SLug', value: 'slug'},
      {text: 'Номер региона', value: 'region_number'},
      {text: 'Индекс фин. грамотности', value: 'index'},
      {text: 'Дата создания', value: 'created_at'},
      {text: 'Дата обновления', value: 'updated_at'},
      {text: '', value: 'actions'},
    ],
    statuses: [
      {text: 'Все', value: null},
      {text: 'Опубликованные', value: '1'},
      {text: 'Не опубликованные', value: '0'},
    ],
    page: {
      title: "Регионы"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Регионы",
        disabled: false,
        to: '/regions/'
      },
    ],
  }),
  created() {
    this.get();
  },
  computed: {
    ...mapGetters({
      loading: 'Regions/isListingLoad',
      items: 'Regions/getFetchedListing',
      total: 'Regions/getListingTotalItems',
    }),
    filters() {
      const filters = [];

      Object.keys(this.query).forEach((key) => {
        if ((this.query[key] || this.query[key] === 0) && key != 'created_at') {
          filters.push({field: key, operator: '=', value: this.query[key]});
        }
      });

      if (this.query.created_at) {
        filters.push({ field: 'created_at',  operator: '>=', value: this.query.created_at + " 00:00:00"});
        filters.push({ field: 'created_at',  operator: '<=', value: this.query.created_at + " 23:59:59"});
      }

      return filters;
    },
    getExportUrl() {
      const payload = {
        title: this.search,
        published: this.query.published,
      };

      return process.env.VUE_APP_API_URL + '/api/module/regions/export?' + (new URLSearchParams(payload).toString());
    },
  },
  methods: {
    ...mapActions({
      fetchListing: 'Regions/fetchListing',
      destroyItem: 'Regions/destroy',
      attachBookmark: 'Regions/attachBookmark',
      detachBookmark: 'Regions/detachBookmark',
      detachAllBookmark: 'Regions/detachAll',
    }),
    editItem(item) {
      return this.$router.resolve({name: 'regions-edit', params: {id: item.id}}).href
    },
    async deleteItem(item) {
      this.$confirm('Вы уверены?', 'Удаление').then(() => {
        this.destroyItem(item.id).then(() => {
          this.get();
        });
      });
    },
    async restoreItem(item) {
      await Region.$query().restore(item.id);
      await this.get();
    },
    async get() {
      const payload = {
        options: this.options,
        filters: this.filters,
        search: this.search,
        scope: this.scope
      };

      this.fetchListing(payload);
    },
    attach(item) {
      this.attachBookmark(item).then(() => {
        this.get();
      });
    },
    detach(item) {
      this.detachBookmark(item).then(() => {
        this.get();
      });
    },
    detachAll() {
      this.detachAllBookmark().then(() => {
        this.get();
      })
    }
  },
  watch: {
    search: {
      handler(newSearch, oldSearch) {
        newSearch != oldSearch ? this.options.page = 1 : '';
        this.get();
      }
    },
    only_trashed: {
      handler() {
        this.get();
      }
    },
    options: {
      handler() {
        this.get()
      },
      deep: true,
    },
    query: {
      handler() {
        this.options.page = 1;

        this.get()
      },
      deep: true,
    },
    scope: {
      handler() {
        this.get();
      }
    }
  },
};
</script>
