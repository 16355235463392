<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="fetchedData"
              :options.sync="totalItems"
              class="contact-listing-app"
              >
              <template v-slot:top>
                <v-toolbar flat class="mb-8">
                  <v-row>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Поиск..."
                        filled
                        background-color="transparent"
                        hide-details
                        name="title"
                        >
                        <v-btn v-if="search" class="reset-button"
                               slot="append"
                               icon
                               @click="search = null"
                               color="red"
                               >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-switch v-model="only_trashed" label="Показать удаленные" value="1" style="margin-top: 16px"/>
                    </v-col>
                  </v-row>
                  <v-spacer/>
                  <v-btn color="primary" to="/catalogs/create" dark class="mb-2 text-capitalize">
                    <v-icon class="mr-2">mdi-store-plus</v-icon>
                    Добавить каталог
                  </v-btn>
                </v-toolbar>
              </template>
              <template v-slot:item.id="{ item }">
                <v-chip pill>{{ item.id }}</v-chip>
              </template>
              <template v-slot:item.status="{ item }">
                <v-chip :color="getStatusColor(item.status)" text-color="white">{{ item.human_status }}</v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn icon small :to="`/catalogs/${item.id}/items`">
                  <v-icon color="primary" small>
                    mdi-clipboard-multiple-outline
                  </v-icon>
                </v-btn>
                <v-btn icon small :to="`/catalogs/edit/${item.id}`">
                  <v-icon color="primary" small>
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-btn v-if="item.preview_url" small _target="blank" :href="item.preview_url" icon>
                  <v-icon color="primary" small>
                    mdi-eye
                  </v-icon>
                </v-btn>
                <v-icon
                  small
                  v-if="!item.deleted_at"
                  color="red"
                  @click="deleteItem(item, 0)"
                >
                  mdi-delete
                </v-icon>
                <v-icon
                  small
                  v-else
                  color="green"
                  @click="restoreItem(item)"
                >
                  mdi-backup-restore
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

import { mapGetters, mapActions } from "vuex";

export default {
  name: "Index",
  data: () => ({
    options: {
      sortBy: ['id'],
      sortDesc: [true],
      page: 1,
      itemsPerPage: 20,
    },
    search: '',
    only_trashed: false,
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'Название', value: 'title' },
      { text: 'Slug', value: 'slug' },
      { text: 'Статус', value: 'status' },
      { text: '', value: 'actions', sortable: false },
    ],
    page: {
      title: "Каталог"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Каталог",
        disabled: true,
        to: '/catalogs'
      },
    ],
  }),
  created() {
    this.fetchListing(this.listingPayload);
  },
  computed: {
    ...mapGetters({
      "fetchedData": "Catalog/getFetchedListing",
      "totalItems": "Catalog/getListingTotalItems",
      "isLoading": "Catalog/getIsLoading",
    }),
    listingPayload() {
      return {
        options: this.options,
        search: this.search,
        only_trashed: this.only_trashed,
      }
    }
  },
  methods: {
    ...mapActions({
      "fetchListing": "Catalog/fetchListing",
      "destroyItem": "Catalog/destroyItem",
      "restore": "Catalog/restoreItem",
    }),
    getStatusColor(status) {
      switch (status) {
        case 0:
          return 'red';
        case 1:
          return 'orange';
        case 2:
          return 'green';
      }
    },
    deleteItem({ id }, isForce) {
      this.$confirm('Удалить элемент?', 'Удаление').then(() => {
        this.destroyItem({ id, isForce }).then(() => {
          this.fetchListing(this.listingPayload);
        });
      })
    },
    restoreItem({id}) {
      this.restore(id).then(() => {
        this.fetchListing(this.listingPayload);
      })
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchListing(this.listingPayload);
      },
      deep: true,
      immediate: true,
    },
    search: {
      handler(newSearch, oldSearch) {
        newSearch != oldSearch ? this.options.page = 1 : '';
        this.fetchListing(this.listingPayload);
      },
    },
    only_trashed: {
      handler() {
        this.fetchListing(this.listingPayload);
      },
    }
  }
}
</script>

<style scoped>

</style>
