import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      getOption: 'options/getOption',
    }),
    language() {
      return this.getOption('language', 'ru');
    }
  }
}
