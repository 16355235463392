import {Model} from "@tailflow/laravel-orion/lib/model";

export class BlockSetting extends Model<{
    title: string,
    settings: object,
    created_at: string,
    updated_at: string,
}> {

    $resource(): string {
        return "module/block-settings";
    }
}
