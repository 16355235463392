import {Model} from "@tailflow/laravel-orion/lib/model";

export class FunctionalIcon extends Model<{
    title: string,
    slug: string,
    icon: string,
}> {
    $resource(): string {
        return "functional-icon";
    }
}
