import Index from '../../views/main/collections/Index'
import Create from '../../views/main/collections/Create'
import Edit from '../../views/main/collections/Edit';
import ItemIndex from '../../views/main/collections/item/Index'
import ItemCreate from '../../views/main/collections/item/Create'
import ItemEdit from '../../views/main/collections/item/Edit';

export default [
  {
    component: Index,
    name: 'collections-index',
    path: '/collections',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'collections',
    }
  },
  {
    component: Create,
    name: 'collections-create',
    path: '/collections/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'collections',
    }
  },
  {
    component: Edit,
    name: 'collections-edit',
    path: '/collections/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'collections',
    }
  },
  {
    component: ItemIndex,
    name: 'collection-items-index',
    path: '/collection-items',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'collections',
    }
  },
  {
    component: ItemCreate,
    name: 'collection-items-create',
    path: '/collection-items/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'collections',
    }
  },
  {
    component: ItemEdit,
    name: 'collection-items-edit',
    path: '/collection-items/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'collections',
    }
  }
]
