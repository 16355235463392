import {SortDirection} from "@tailflow/laravel-orion/lib/drivers/default/enums/sortDirection";
import {FilterOperator} from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator";
import {CatalogItem} from "../../../../models/catalogItem";
import axiosInstance from "../../../../axiosInstance";

export default {
  namespaced: true,
  state: {
    fetchedListing: [],
    listingTotalItems: 0,
    isLoading: false,
    fetchedDetail: {},
  },
  getters: {
    getFetchedListing: (state) => state.fetchedListing,
    getListingTotalItems: (state) => state.listingTotalItems,
    getIsLoading: (state) => state.isLoading,
    getFetchedDetail: (state) => state.fetchedDetail,
  },
  mutations: {
    setFetchedListing(state, val) {
      state.fetchedListing = val;
    },
    setListingTotalItems(state, val) {
      state.listingTotalItems = val;
    },
    setIsLoading(state, val) {
      state.isLoading = val;
    },
    setFetchedDetail(state, val) {
      state.fetchedDetail = val;
    }
  },
  actions: {
    fetchListing({commit, dispatch}, {options, search, only_trashed, catalog_id}) {
      commit('setIsLoading', true);

      const {sortBy, sortDesc, page, itemsPerPage} = options;

      let queryBuilder = CatalogItem
        .$query()
        .lookFor(search)
        .filter('catalog_id', FilterOperator.Equal, catalog_id);

      if (only_trashed) {
        queryBuilder.onlyTrashed();
      }

      if (sortBy[0]) {
        queryBuilder.sortBy(sortBy[0], sortDesc[0] ? SortDirection.Desc : SortDirection.Asc)
      }

      queryBuilder.search(itemsPerPage, page).then((response) => {
        if (response.length > 0) {
          commit('setListingTotalItems', response[0].$response.data.meta.total);
        } else {
          commit('setListingTotalItems', 0);
        }
        commit('setFetchedListing', response.map((item) => item.$attributes));
      }).catch(() => {
        dispatch("snackbar/showUnknownError", null, {root: true})
      }).finally(() => {
        commit("setIsLoading", false);
      })
    },
    // eslint-disable-next-line no-unused-vars
    async checkSku({ dispatch }, sku) {
      return (await axiosInstance.get(`module/catalog-item/check-sku?sku=${sku}`)).data;
    },
    async submitCollections({dispatch, state}, {items}) {
      await axiosInstance.post(`module/catalog-item/${state.fetchedDetail.id}/collection-items/sync`, {items: items}).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true})
        dispatch('fetchDetail', state.fetchedDetail.id)
      }).catch(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true});
      })
    },
    async makeMainCollectionItem({dispatch, state}, {items}) {
      await axiosInstance.post(`module/catalog-item/${state.fetchedDetail.id}/collection-items/make-main`, {items: items}).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true})
        dispatch('fetchDetail', state.fetchedDetail.id)
      }).catch(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true});
      })
    },
    async destroyItem({commit, dispatch}, {id, isForce}) {
      commit('setIsLoading', true);

      await CatalogItem.$query().destroy(id, isForce).then(() => {
        dispatch('snackbar/showSuccessfullyDelete', null, {root: true});
      }).catch(() => {
        dispatch('snackbar/showDeleteError', null, {root: true})
      }).finally(() => {
        commit('setIsLoading', false);
      });
    },
    async restoreItem({commit, dispatch}, id) {
      commit('setIsLoading', true);

      await CatalogItem.$query().restore(id).then(() => {
        dispatch('snackbar/showSuccessfullyRestore', null, {root: true})
      }).catch(() => {
        dispatch('snackbar/showErrorRestore', null, {root: true})
      }).finally(() => {
        commit('setIsLoading', false)
      });
    },
    async storeItem({ commit, dispatch }, { form, isNeedRedirect, router }) {
      commit('setIsLoading', true)
      let catalog = null;

      CatalogItem.$query().store(form).then((response) => {
        catalog = response.$attributes.id;
        dispatch('snackbar/showSuccessfullySaved', null, { root: true });
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, { root: true });
      }).finally(() => {
        commit('setIsLoading', false)
        if (catalog) {
          if (isNeedRedirect) {
            router.push({ name: 'catalog-items-index' });
          } else {
            router.push({ name: 'catalog-items-edit', params: { id: catalog, catalog_id: form.catalog_id } });
          }
        }
      })
    },
    async fetchDetail({ commit, dispatch }, id) {
      commit('setIsLoading', true);

      CatalogItem.$query().find(id).then((response) => {
        commit('setFetchedDetail', response.$attributes);
      }).catch(() => {
        dispatch('snackbar/showUnknownError', null, { root: true });
      }).finally(() => {
        commit('setIsLoading', false);
      })
    },
    async updateItem({ commit, dispatch }, { form, isNeedRedirect, router, catalog_id }) {
      commit('setIsLoading', true);
      let hasErrors = false;

      CatalogItem.$query().update(form.id, form).then((response) => {
        commit('setFetchedDetail', response.$attributes);
        dispatch("snackbar/showSuccessfullySaved", null, { root: true })
      }).catch(() => {
        hasErrors = true;
        dispatch("snackbar/showUnknownError", null, { root: true });
      }).finally(() => {
        commit('setIsLoading', false);

        if (!hasErrors) {
          if (isNeedRedirect) {
            router.push({ name: 'catalog-items-index', params: { catalog_id: catalog_id } });
          }
        }
      })
    },
    async regenerateSingle({dispatch}, itemID) {
      axiosInstance.get(`module/catalog-item/${itemID}/regenerate-page`, { headers: { "X-BLOCK-MODE": "tailwind" } }).then(() => {
        dispatch('snackbar/showSnackbar', { color: 'green', text: 'Страница сгенерирована', timeout: 3000 }, { root: true })
      }).catch(() => {
        dispatch('snackbar/showUnknownError', null, { root: true })
      })
    }
  }
}
