import Index from '../../views/main/pages/Index'
import Create from '../../views/main/pages/Create'
import Edit from '../../views/main/pages/Edit';

export default [
  {
    component: Index,
    name: 'pages-index',
    path: '/pages',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'pages',
    }
  },
  {
    component: Create,
    name: 'pages-create',
    path: '/pages/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'pages',
    }
  },
  {
    component: Edit,
    name: 'pages-edit',
    path: '/pages/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'pages',
    }
  }
]
