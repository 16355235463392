import { Model } from "@tailflow/laravel-orion/lib/model";

export class Customer extends Model<{
    title: string,
    front_url: string,
    admin_url: string
}> {

    $resource(): string {
        return "module/customers";
    }
}
