<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row>
      <v-col>
        <h1>Статистика</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <apexchart height="325" type="line" :options="chartOptions" :series="series"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="statsForTable"
          :options.sync="options"
          :loading="loading"
          :server-items-length="listingTotal"
          :search="search"
        >
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

  export default {
    name: 'Index',
    data: () => ({
      search: '',
      only_trashed: null,
      options: {
        sortBy: ['id'],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 10,
      },
      headers: [
        {text: 'Id', value: 'id'},
        {text: 'Дата перехода', value: 'created_at'},
        {text: 'ip адрес', value: 'ip'},
        {text: 'Устройство', value: 'device'},
        {text: 'Платформа', value: 'platform'},
        {text: 'Браузер', value: 'browser'},
        {text: 'Робот', value: 'robot'},
        {text: 'Страница перехода', value: 'http_referer'},
      ],
      page: {
        title: "Редактирование короткие ссылки"
      },
      breadcrumbs: [
        {
          text: "Админ панель",
          disabled: false,
          to: '/'
        },
        {
          text: "Короткие ссылки",
          disabled: true,
          to: '/shortuls'
        },
        {
          text: "Статистика",
          disabled: true,
          to: '/shortuls/stats'
        },
      ],
    }),
    created() {
      this.getStatistics(this.$route.params.id);
      this.fetchStatsForTable(this.statsForTablePayload);
    },
    computed: {
      ...mapGetters({
          stats: 'ShortUrls/getStats',
          loading: 'ShortUrlsStats/getIsLoading',
          statsForTable: 'ShortUrlsStats/getStatsForTable',
          listingTotal: 'ShortUrlsStats/getListingTotal',
        }),
      chartOptions() {
        return {
          chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: false
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'straight'
          },
          title: {
            text: 'Просмотров',
            align: 'center'
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          xaxis: {
            categories: this.stats.categories,
          }
        }
      },
      series() {
        return [
          {
            data: this.stats.data,
          }
        ]
      },
      statsForTablePayload() {
        return {
          options: this.options,
          search: this.search,
          only_trashed: this.only_trashed,
          short_urls_id: this.$route.params.id
        }
      }
    },
    methods: {
      ...mapActions({
        getStatistics: 'ShortUrls/getStatistics',
        fetchStatsForTable: 'ShortUrlsStats/fetchStatsForTable',
      }),
    },
    watch: {
      options: {
        handler() {
          this.fetchStatsForTable(this.statsForTablePayload);
        },
        deep: true,
        immediate: true,
      },
    }
  }
</script>
