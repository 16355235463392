import Index from '../../views/main/lessons/Index'
import Create from '../../views/main/lessons/Create'
import Edit from '../../views/main/lessons/Edit';

export default [
  {
    component: Index,
    name: 'lessons-index',
    path: '/course/:course_id/lessons',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'courses'
    }
  },
  {
    component: Create,
    name: 'lessons-create',
    path: '/course/:course_id/lessons/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'courses'
    }
  },
  {
    component: Edit,
    name: 'lessons-edit',
    path: '/course/:course_id/lessons/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'courses'
    }
  },
]
