<template>
  <div>
    <v-row>
      <v-col>
        <h1>
          Группы динамическиз полей
          <v-btn fab small color="primary" to="field-groups/create">
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field v-model="search" placeholder="Поиск..."/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          disable-sort
          :loading="isLoading"
          loading-text="Загрузка..."
          :items="fetchedListing"
          :headers="headers"
          :options.sync="options"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn icon color="red" @click="deleteItem(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn icon color="primary" @click="editItem(item)">
              <v-icon>mdi-pen</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Index",
  data: () => ({
    search: "",
    headers: [
      { text: "ID", value: "id" },
      { text: "Название", value: "title" },
      { text: "", value: "actions" },
    ],
    options: {
      page: 1,
      itemsPerPage: 15,
    },
  }),
  created() {
    this.fetchListing(this.payload);
  },
  computed: {
    ...mapGetters({
      isLoading: "FieldGroups/isLoading",
      fetchedListing: "FieldGroups/getFetchedListing",
    }),
    payload() {
      return {
        options: this.options,
        search: this.search
      }
    }
  },
  methods: {
    ...mapActions({
      fetchListing: "FieldGroups/fetchListing",
      destroyItem: "FieldGroups/destroyItem"
    }),
    deleteItem({ id }) {
      this.destroyItem(id).then(() => {
        this.fetchListing(this.payload);
      })
    },
    editItem({ id }) {
      this.$router.push({ name: 'field-groups-edit', params: { id: id } });
    }
  },
  watch: {
    query: {
      handler() {
        this.fetchListing(this.payload)
      }
    },
    options: {
      handler() {
        this.fetchListing(this.payload);
      }
    },
    search: {
      handler() {
        this.fetchListing(this.payload);
      }
    }
  }
}
</script>

<style scoped>

</style>
