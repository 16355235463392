<template>
  <div>
    <v-row>
      <v-col>
        <v-text-field label="Команда" placeholder="Команда" v-model="form.command"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field label="Крон" placeholder="Крон" v-model="form.cron"/>
      </v-col>
    </v-row>
    <v-btn
      color="primary"
      @click="submit"
      :disabled="isLoading"
      :loading="isLoading"
    >
      Сохранить
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "CronForm",
  props: {
    cron: {
      type: Object,
      default: () => ({})
    },
  },
  data: () => ({
    form: {
      command: null,
      cron: null,
    }
  }),
  computed: {
    ...mapGetters({
      isLoading: "Cron/isLoading",
    })
  },
  methods: {
    submit() {
      this.$emit('onSubmit', this.form);
    }
  },
  watch: {
    cron: {
      handler() {
        if (Object.keys(this.cron).length > 0) {
          this.form = JSON.parse(JSON.stringify(this.cron));
        }
      },
      immediate: true,
    },
  },
}
</script>

<style scoped>

</style>
