<template>
  <div class="mt-5">
    <v-select
      v-model="selected"
      :items="sites"
      item-text="title"
      return-object
    />
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "SiteSwitcher",
  computed: {
    ...mapGetters({
      sites: 'SiteSwitcher/getSites',
      selectedSite: 'SiteSwitcher/getSelectedSite',
    }),
    selected: {
      set(value) {
        this.setSelectedSite(value)
        window.location.reload();
      },
      get() {
        return this.selectedSite;
      }
    }
  },
  methods: {
    ...mapMutations({
      setSelectedSite: 'SiteSwitcher/setSelectedSite',
    }),
  }
}
</script>

<style scoped>

</style>
