import { Model } from "@tailflow/laravel-orion/lib/model";

export class KeyWord extends Model<{
    key_word: string,
}> {

    $resource(): string {
        return "module/key-words";
    }
}
