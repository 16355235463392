import { Model } from "@tailflow/laravel-orion/lib/model";

export class SeasonPass extends Model<{
    title: string,
    slug: string,
    description: string,
    amount: number,
    period: number,
    available_quantity: number,
    created_at: string,
    updated_at: string,
}> {

    $resource(): string {
        return "season-pass";
    }
}
