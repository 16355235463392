import Index from '../../views/main/orders/Index'
import Detail from '../../views/main/orders/Detail'

export default [
  {
    component: Index,
    name: 'orders-index',
    path: '/orders',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'orders',
    }
  },
  {
    component: Detail,
    name: 'order-detail',
    path: '/orders/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'orders'
    }
  }
]
