var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"down-top-padding",attrs:{"fluid":""}},[_c('BaseBreadcrumb',{attrs:{"title":_vm.page.title,"icon":_vm.page.icon,"breadcrumbs":_vm.breadcrumbs}}),_c('v-card',[_c('v-card-text',{staticClass:"pa-5"},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Поиск...","filled":"","background-color":"transparent","hide-details":"","name":"title"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[(_vm.search)?_c('v-btn',{staticClass:"reset-button",attrs:{"slot":"append","icon":"","color":"red"},on:{"click":function($event){_vm.search = null}},slot:"append"},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1)],1),_c('v-col',[_c('v-switch',{attrs:{"label":"Показать удаленные","name":"only_trashed","value":"1"},model:{value:(_vm.only_trashed),callback:function ($$v) {_vm.only_trashed=$$v},expression:"only_trashed"}})],1),_c('v-col',{staticClass:"justify-end align-center d-flex",attrs:{"cols":"12","lg":"4"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"color":"primary","to":"/courses/create"}},[_vm._v(" Создать курс ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","href":_vm.getExportUrl}},[_vm._v(" Выгрузить ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-0"},[(_vm.persons)?_c('v-autocomplete',{staticClass:"mr-0",attrs:{"items":_vm.persons,"item-text":"full_name","item-value":"id","label":"Выберите персону","name":"person_id","required":"","prepend-icon":"mdi-account-box"},model:{value:(_vm.query.person_id),callback:function ($$v) {_vm.$set(_vm.query, "person_id", $$v)},expression:"query.person_id"}}):_vm._e()],1),_c('v-col',{staticClass:"pl-0",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){_vm.query.person_id = null}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"options":_vm.options,"server-items-length":_vm.total,"footer-props":{'items-per-page-options':[5, 10, 15, 20, -1]}},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"pill":""}},[_vm._v(_vm._s(item.id))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.getUrlWithLastSlash(item.preview_url))?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","href":_vm.getUrlWithLastSlash(item.preview_url) + '?preview_mode=true',"target":"_blank"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v(" mdi-eye ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Предпросмотр курса")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.admin_attach_url)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.attachItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v(" mdi-eye-plus ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Предпросмотр купленного курса")])]),_c('v-btn',{attrs:{"href":_vm.editItem(item),"icon":""}},[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.copyItem(item)}}},[_vm._v(" mdi-content-copy ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.lessons(item)}}},[_vm._v(" mdi-clipboard-list ")]),(item.deleted_at)?_c('v-icon',{attrs:{"color":"green","small":""},on:{"click":function($event){return _vm.restoreItem(item)}}},[_vm._v(" mdi-backup-restore ")]):_c('v-icon',{attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""},on:{"click":function($event){return _vm.pickupItem(item)}}},[_vm._v(" mdi-arrow-up ")])]}},{key:"item.publish",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.status),"content":item.human_status,"label":""}},[_vm._v(_vm._s(item.human_status))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }