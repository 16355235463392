<template>
  <div class="vk_preview">
    <div class="vk-widget-block">
      <div class="image-block" v-if="$data.vkImg" :style="$data.vkImg"></div>
      <div class="description-block">
        <span class="vk-title">{{ title }}</span>
        <a :href="getHostname" class="site-url">{{ getHostname }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import smmMixin from "@/mixins/smmMixin";
import seoAndSmmWidgetMixin from "@/mixins/seoAndSmmWidgetMixin";

export default {
  mixins: [
    smmMixin,
    seoAndSmmWidgetMixin
  ],
  props: {
    form: {
      type: Object,
    },
    entity: {
      type: Object,
    },
  },
  data() {
    return {
      title: '',
      vkImg: '',
    }
  },
  methods: {
    ...mapActions({
      dynamicResize: "Medias/dynamicResize"
    }),
    cutString(string, count) {
      if (string.length > count) {
        return string.substring(0, count) + '...';
      }

      return string;
    }
  },
  watch: {
    'form.smm_image': {
      async handler(val) {
       if (val != null) {
          let value = val.url || this.entity.smm_image.url;
          if (typeof value != 'string') return;

         const url = (await this.dynamicResize({ media: val.id, width: 1200, height: 630 })).data;

         this.$data.vkImg = `background-image: url(${url})`;
        } else {
         this.$data.vkImg = '';
       }
      },
      immediate: true,
    }
  },
}
</script>

<style lang="scss" scoped>
  .vk_preview {
    margin-bottom: 15px;

    .vk-widget-block {
      margin-top: 8px;
      margin-bottom: 10px;
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: 2px;
      width: 510px;

      .image-block {
        height: 228px;
        background-repeat: no-repeat;
        background-size: 100% auto;

        img {
          border-bottom: 1px solid rgba(0, 0, 0, 0.12);
          width: 100%;
        }
      }

      .description-block {
        padding-top: 7px;
        padding-bottom: 11px;
        padding-left: 9px;
        padding-right: 9px;

        span.vk-title {
          display: block;
          max-height: 2.8em;
          text-overflow: ellipsis;
          word-break: break-word;
          margin-bottom: 8px;
        }

        a.site-url {
          display: block;
          line-height: 12px;
          font-size: 12.5px;
          color: #626d7a;
        }

        a.site-url:hover {
          text-decoration: unset;
        }
      }
    }
  }
</style>
