import axiosInstance from "../../axiosInstance";

export default {
  namespaced: true,
  state: {
    eventStatistic: {},
    generalStatistic: {},
    socialStatistic: {},
    regionalStatistic: {},
    mediaStatistic: {},
    popularStatistic: {},
  },
  getters: {
    getEventStatistic: (state) => state.eventStatistic,
    getGeneralStatistic: (state) => state.generalStatistic,
    getSocialStatistic: (state) => state.socialStatistic,
    getRegionalStatistic: (state) => state.regionalStatistic,
    getMediaStatistic: (state) => state.mediaStatistic,
    getPopularStatistic: (state) => state.popularStatistic,
  },
  mutations: {
    setEventStatistic(state, val) {
      state.eventStatistic = val;
    },
    setGeneralStatistic(state, val) {
      state.generalStatistic = val;
    },
    setSocialStatistic(state, val) {
      state.socialStatistic = val;
    },
    setRegionalStatistic(state, val) {
      state.regionalStatistic = val;
    },
    setMediaStatistic(state, val) {
      state.mediaStatistic = val;
    },
    setPopularStatistic(state, val) {
      state.popularStatistic = val;
    }
  },
  actions: {
    fetchEventStatistic({ commit, dispatch }, { interval, list }) {
      let params = new URLSearchParams(interval);
      if (list.length > 0) {
        params.append('list', list);
      }
      axiosInstance.get(`module/statistics/dashboard/events?${params}`, { data: interval }).then((response) => {
        commit('setEventStatistic', response.data);
      }).catch(() => {
        dispatch('snackbar/showUnknownError', null, { root: true });
      });
    },
    fetchGeneralStatistic({ commit, dispatch }, { interval, list }) {
      let params = new URLSearchParams(interval);
      if (list.length > 0) {
        params.append('list', list);
      }
      axiosInstance.get(`module/statistics/dashboard/general?${params}`).then((response) => {
        commit('setGeneralStatistic', response.data);
      }).catch(() => {
        dispatch('snackbar/showUnknownError', null, { root: true });
      })
    },
    fetchSocialStatistic({ commit, dispatch }, { interval, list }) {
      let params = new URLSearchParams(interval);
      if (list.length > 0) {
        params.append('list', list);
      }
      axiosInstance.get(`module/statistics/dashboard/social?${params}`).then((response) => {
        commit('setSocialStatistic', response.data);
      }).catch(() => {
        dispatch('snackbar/showUnknownError', null, { root: true });
      })
    },
    fetchRegionalStatistic({ commit, dispatch }, {interval, list}) {
      let params = new URLSearchParams(interval);
      if (list.length > 0) {
        params.append('list', list);
      }
      axiosInstance.get(`module/statistics/dashboard/regional?${params.toString()}`).then((response) => {
        commit('setRegionalStatistic', response.data);
      }).catch(() => {
        dispatch('snackbar/showUnknownError', null, { root: true });
      })
    },
    fetchMediaStatistic({ commit, dispatch }, {interval, list}) {
      let params = new URLSearchParams(interval);
      if (list.length > 0) {
        params.append('list', list);
      }
      axiosInstance.get(`module/statistics/dashboard/media?${params.toString()}`).then((response) => {
        commit('setMediaStatistic', response.data);
      }).catch(() => {
        dispatch('snackbar/showUnknownError', null, { root: true });
      })
    },
    fetchPopularStatistic({ commit, dispatch }, {interval}) {
      axiosInstance.get(`module/statistics/dashboard/popular?${(new URLSearchParams(interval)).toString()}`).then((response) => {
        commit('setPopularStatistic', response.data);
      }).catch(() => {
        dispatch('snackbar/showUnknownError', null, { root: true });
      })
    }
  }
}
