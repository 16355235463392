<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <teaser-form
              :errors="errors"
              @onSubmit="submit"
            />
          </v-col>
        </v-row>
        <v-snackbar
          v-model="snackbarSuccess"
          :timeout="timeout"
          color="success"
        >
          Сохранено

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarSuccess = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar
          v-model="snackbarError"
          :timeout="timeout"
          color="error"
        >
          {{ this.errors }}

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarError = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import TeaserForm from "../../../components/teasers/TeaserForm";
import {Teaser} from "../../../models/teaser";

export default {
  name: 'Create',
  components: {TeaserForm},
  data: () => ({
    errors: {},
    snackbarSuccess: false,
    snackbarError: false,
    timeout: 2000,
    page: {
      title: "Создание тизеры"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Тизеры",
        disabled: true,
        to: '/teasers'
      },
      {
        text: "Создание",
        disabled: true,
        to: '/teasers/create'
      },
    ],
  }),
  methods: {
    async submit({form, isNeedRedirect}) {
      try {
        this.errors = {};

        const teaser = await Teaser.$query().store(form);
        this.snackbarSuccess = true;

        if (isNeedRedirect) {
          await this.$router.push({name: 'teasers-index'});
        } else {
          await this.$router.push({ name: 'teasers-edit', params: { id: teaser.$attributes.id } });
        }
      } catch (e) {
        this.snackbarError = true;
        if (e.response?.data) {
          this.errors = e.response.data.errors
        }
        throw e;
      }
    },
  },
};
</script>
