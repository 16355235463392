<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row>
      <v-col>
        <h1>Короткие ссылки</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="primary" to="/ShortUrls/create">
          Создать короткую ссылку
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="search"
          label="Поиск..."
          name="search"
        />
      </v-col>
      <v-col>
          <v-menu
                v-model="datePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFilter"
                    label="Диапазон дат"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    append-icon="mdi-close"
                    @click:append="dateFilter = null"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateFilter"
                  locale="ru-RU"
                  range
                >
                  <v-spacer/>
                  <v-btn text color="primary" @click="datePickerAction">ОК</v-btn>
                </v-date-picker>
              </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :loading="loading"
          :server-items-length="listingTotal"
          :search="search"
        >
          <template v-slot:item.fullUrlHref="{item}">
            <a :href="item.url" target="_blank">{{ item.url }}</a>
          </template>
          <template v-slot:item.shortUrlHref="{item}">
            <a :href="item.shortUrl" target="_blank">{{ item.shortUrl }}</a>
          </template>
          <template v-slot:item.action="{item}">
            <v-btn
              :href="itemStat(item)"
              icon
            >
              <v-icon color="primary">
                mdi-chart-timeline-variant
              </v-icon>
            </v-btn>
            <v-icon
              color="red"
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from 'moment';

export default {
  name: 'Index',
  data: () => ({
    search: '',
    only_trashed: null,
    options: {
      sortBy: ['id'],
      sortDesc: [true],
      page: 1,
      itemsPerPage: 10,
    },
    headers: [
      {text: 'Id', value: 'id'},
      {text: 'Комментарий', value: 'comment'},
      {text: 'Полный url', value: 'fullUrlHref'},
      {text: 'Короткая ссылка', value: 'shortUrlHref'},
      {text: 'Количество переходов', value: 'show_count'},
      {text: 'Дата создания', value: 'created_at'},
      {text: 'Дата обновления', value: 'updated_at'},
      {text: '', value: 'action'},
    ],
    page: {
      title: "Короткие ссылки"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Короткие ссылки",
        disabled: true,
        to: '/shortuls'
      },
    ],
    datePicker: false,
    dateFilter: null,
    filter: [],
    dates: {},
  }),
  created() {
    this.fetchedListing(this.listingPayload);
  },
  computed: {
    ...mapGetters({
        items: 'ShortUrls/getFetchedListing',
        listingTotal: 'ShortUrls/getListingTotalItems',
        loading: 'ShortUrls/getIsListingLoad',
    }),
    listingPayload() {
      return {
        options: this.options,
        search: this.search,
        only_trashed: this.only_trashed,
        filter: this.filter,
      }
    }
  },
  methods: {
    ...mapActions({
      fetchedListing: 'ShortUrls/fetchListing',
      destroyItem: 'ShortUrls/destroyItem',
    }),
    deleteItem(item) {
      this.destroyItem(item.id).then(this.fetchedListing(this.listingPayload));
    },
    itemStat(item) {
      return this.$router.resolve({name: 'shortUrls-stats', params: {id: item.id}}).href
    },
    filterformatDate(dateString){
      if(dateString){
        return moment(dateString).format('YYYY-MM-DD');
      }
    },
    dateFilterMethod(){
      if(this.dateFilter){
        let dateFrom = this.filterformatDate(this.dateFilter[0]);
        let dateTo = this.dateFilter[1] ? this.filterformatDate(moment(this.dateFilter[1], "YYYY-MM-DD").add(1, 'days')) : moment(dateFrom, "YYYY-MM-DD").add(1, 'days');
        let dates = {};
        dates.date_from = dateFrom;
        dates.date_to = dateTo;
        this.setFilter(dates);
      }
      this.fetchedListing(this.listingPayload);
    },
    datePickerAction(){
      if(this.dateFilter)
        if(this.dateFilter.length > 1){
          if(this.dateFilter[1] < this.dateFilter[0]){
            let date = this.dateFilter[0];
            this.dateFilter[0] = this.dateFilter[1];
            this.dateFilter[1] = date;
          }
        }
      this.dateFilterMethod();
      this.datePicker = false;
    },
    setFilter(dates){
      if(dates.date_from && dates.date_to)
        this.filter = [
            {
              "field" : "created_at",
              "operator" : ">=",
              "value" : dates.date_from,
            },
            {
              "field" : "created_at",
              "operator" : "<=",
              "value" : dates.date_to,
            }
          ];
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchedListing(this.listingPayload);
      },
      deep: true,
      immediate: true,
    },
    search: {
      handler() {
          this.fetchedListing(this.listingPayload);
      },
      immediate: true,
    },
    dateFilter: {
      handler(val) {
        if(!val){
          this.filter = [];
          this.fetchedListing(this.listingPayload);
        }
      },
      immediate: true,
    },
  }
}
</script>
