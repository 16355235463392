import axiosInstance from "../../axiosInstance";

export default {
  namespaced: true,
  state: {
    statuses: [],
    allEntities: {},
  },
  getters: {
    getStatuses: (state) => state.statuses,
    getAllEntities: (state) => state.allEntities,
  },
  mutations: {
    setStatuses(state, val) {
      state.statuses = val;
    },
    setAllEntities(state, val) {
      state.allEntities = val;
    }
  },
  actions: {
    fetchStatuses({ commit, dispatch }) {
      axiosInstance.get('constants/statuses-list').then((response) => {
        commit('setStatuses', response.data)
      }).catch(() => {
        dispatch("snackbar/showUnknownError", null, { root: true })
      })
    },
    fetchAllEntities({ commit, dispatch }) {
      axiosInstance.get('constants/all-entities').then((response) => {
        commit('setAllEntities', response.data);
      }).catch(() => {
        dispatch("snackbar/showUnknownError", null, { root: true });
      })
    },
    // eslint-disable-next-line no-unused-vars
    async fetchEntityList({ commit, dispatch }, payload) {
      const { data } = await axiosInstance.post('constants/entity-list', payload)

      return data;
    }
  }
}
