<template>
  <v-container>
    <v-btn block class="mb-2" color="primary" @click="submit">Сохранить</v-btn>
    <v-expansion-panels>
      <v-expansion-panel v-for="(mail) in entityMails" :key="mail.id">
        <v-expansion-panel-header>
          {{ mail.title }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-text-field
            v-model="mail.subject"
            label="Заголовок письма"
          />
          <label>Шаблон письма</label>
          <tip-tap v-model="mail.html_template"/>
          <div class="v-text-field__details">
            <div class="v-messages theme--light">
              <div class="v-messages__wrapper">
                <div class="v-messages__message">
                  Подсказка: <br>
                  {{ mail.hint }}
                </div>
              </div>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import {mapActions} from "vuex";
import TipTap from "./editor/TipTap";

export default {
  name: "EntityMails",
  components: {TipTap},
  props: {
    mails: {
      type: Array,
      required: true,
    },
    entity_type: {
      type: String,
      required: true,
    },
    entity_id: {
      type: Number,
      required: true,
    }
  },
  mounted() {
    this.entityMails = this.mails.slice(0);
  },
  data: () => ({
    entityMails: [],
  }),
  methods: {
    ...mapActions({
      syncMails: 'Mails/syncMails',
    }),
    submit() {
      let mails = {};
      this.entityMails.forEach(function(val) {
        mails[val.id] = {
          html_template: val.html_template,
          subject: val.subject,
        }
      })

      const payload = {
        mails: mails,
        entity_type: this.entity_type,
        entity_id: this.entity_id,
      }
      console.log(payload);
      this.syncMails(payload);
    }
  },
}
</script>
