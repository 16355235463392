<template>
  <div class="text-center">
    <v-dialog
      v-model="popup"
      width="90%"
      persistent
      >
      <v-toolbar color="primary" dark>
          <v-spacer/>
          <v-btn icon small @click="popup = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
      </v-toolbar>
      <v-card>
          <v-data-table
              :headers="headers"
              :items="fetchedPopularBlocks"
              :items-per-page="15"
              :footer-props="{ 'items-per-page-options': [5, 10, 15, 20, -1] }"
              >
            </v-data-table>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default { 
  props: ["value"],
  computed: {
    popup: {
      get (){
        return this.value;
      },
      set (value){
        this.$emit('input',value);
      },
    },
    ...mapGetters({
      fetchedPopularBlocks: 'Blocks/getfetchedPopularBlocks',
    })
  },
  data: () => ({
    headers: [
    { text: 'Id', value: 'component' },
    { text: 'Позиция 1', value: 'position_0' },
    { text: 'Позиция 2', value: 'position_1' },
    { text: 'Позиция 3', value: 'position_2' },
    { text: 'Позиция 4', value: 'position_3' },
    { text: 'Позиция 5', value: 'position_4' },
    { text: 'Позиция 6', value: 'position_5' },
    { text: 'Позиция 7', value: 'position_6' },
    { text: 'Позиция 8', value: 'position_7' },
    { text: 'Позиция 9', value: 'position_8' },
    { text: 'Позиция 10', value: 'position_9' },
    ],
  }),
  async created() {
    this.fetchPopularBlocks(3);
  },
  methods: {
    ...mapActions({
      fetchPopularBlocks: 'Blocks/fetchPopularBlocks',
    }),
  },
};
</script>

<style scoped>

</style>
