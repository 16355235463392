var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"down-top-padding",attrs:{"fluid":""}},[_c('v-card',[_c('vxe-toolbar',{scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('vxe-input',{attrs:{"type":"search","placeholder":"Поиск"},on:{"keyup":_vm.onSearchInput},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('vxe-button',{staticClass:"ml-3",attrs:{"content":"Добавить","status":"primary"},on:{"click":_vm.insertEvent}}),_c('vxe-button',{attrs:{"content":"Удалить","status":"danger"},on:{"click":_vm.removeEvent}}),_c('vxe-button',{attrs:{"content":"Сохранить","status":"success"},on:{"click":_vm.saveEvent}})]},proxy:true}])}),_c('div',{staticStyle:{"height":"calc(100vh - 260px)"}},[_c('vxe-table',{ref:"xTable",attrs:{"border":"","stripe":"","resizable":"","highlight-hover-row":"","keep-source":"","show-footer":"","max-height":"100%","data":_vm.data,"edit-config":{trigger: 'click', mode: 'cell', showStatus: true},"column-config":{resizable: true, minWidth: 300},"tooltip-config":_vm.tableToolbar,"menu-config":_vm.menuConfig},on:{"header-cell-click":_vm.headerCellClickEvent,"menu-click":_vm.contextMenuClickEvent}},[_c('vxe-column',{attrs:{"type":"checkbox","width":"60"}}),_c('vxe-column',{attrs:{"field":"id","title":"id","width":"60"}}),_c('vxe-column',{attrs:{"field":"title","title":"Заголовок","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.title),callback:function ($$v) {_vm.$set(row, "title", $$v)},expression:"row.title"}})]}}])}),_c('vxe-column',{attrs:{"field":"person_id","title":"Персона","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.formatPersonsValue(row.person_id)))])]}},{key:"edit",fn:function(scope){return [_c('vxe-select',{attrs:{"transfer":""},model:{value:(scope.row.person_id),callback:function ($$v) {_vm.$set(scope.row, "person_id", $$v)},expression:"scope.row.person_id"}},_vm._l((_vm.persons),function(item){return _c('vxe-option',{key:item.key,attrs:{"value":item.key,"label":item.value}})}),1)]}}])}),_c('vxe-column',{attrs:{"field":"course","title":"Курс","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.course),callback:function ($$v) {_vm.$set(row, "course", $$v)},expression:"row.course"}})]}}])}),_c('vxe-column',{attrs:{"field":"age","title":"Возраст","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.age),callback:function ($$v) {_vm.$set(row, "age", $$v)},expression:"row.age"}})]}}])}),_c('vxe-column',{attrs:{"field":"lesson","title":"Урок","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.lesson),callback:function ($$v) {_vm.$set(row, "lesson", $$v)},expression:"row.lesson"}})]}}])}),_c('vxe-column',{attrs:{"field":"question_content","title":"Название вопроса","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.question_content),callback:function ($$v) {_vm.$set(row, "question_content", $$v)},expression:"row.question_content"}})]}}])}),_c('vxe-column',{attrs:{"field":"sphere","title":"Cфера","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.sphere),callback:function ($$v) {_vm.$set(row, "sphere", $$v)},expression:"row.sphere"}})]}}])}),_c('vxe-column',{attrs:{"field":"illustration_question","title":"Иллюстрация для вопроса","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"number"},model:{value:(row.illustration_question),callback:function ($$v) {_vm.$set(row, "illustration_question", $$v)},expression:"row.illustration_question"}})]}}])}),_c('vxe-column',{attrs:{"field":"answer_1","title":"Ответ 1","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.answer_1),callback:function ($$v) {_vm.$set(row, "answer_1", $$v)},expression:"row.answer_1"}})]}}])}),_c('vxe-column',{attrs:{"field":"illustration_answer_1","title":"Иллюстрация для ответа 1","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"number"},model:{value:(row.illustration_answer_1),callback:function ($$v) {_vm.$set(row, "illustration_answer_1", $$v)},expression:"row.illustration_answer_1"}})]}}])}),_c('vxe-column',{attrs:{"field":"answer_2","title":"Ответ 2","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.answer_2),callback:function ($$v) {_vm.$set(row, "answer_2", $$v)},expression:"row.answer_2"}})]}}])}),_c('vxe-column',{attrs:{"field":"illustration_answer_2","title":"Иллюстрация для ответа 2","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"number"},model:{value:(row.illustration_answer_2),callback:function ($$v) {_vm.$set(row, "illustration_answer_2", $$v)},expression:"row.illustration_answer_2"}})]}}])}),_c('vxe-column',{attrs:{"field":"answer_3","title":"Ответ 3","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.answer_3),callback:function ($$v) {_vm.$set(row, "answer_3", $$v)},expression:"row.answer_3"}})]}}])}),_c('vxe-column',{attrs:{"field":"illustration_answer_3","title":"Иллюстрация для ответа 3","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"number"},model:{value:(row.illustration_answer_3),callback:function ($$v) {_vm.$set(row, "illustration_answer_3", $$v)},expression:"row.illustration_answer_3"}})]}}])}),_c('vxe-column',{attrs:{"field":"answer_4","title":"Ответ 4","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.answer_4),callback:function ($$v) {_vm.$set(row, "answer_4", $$v)},expression:"row.answer_4"}})]}}])}),_c('vxe-column',{attrs:{"field":"illustration_answer_4","title":"Иллюстрация для ответа 4","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"number"},model:{value:(row.illustration_answer_4),callback:function ($$v) {_vm.$set(row, "illustration_answer_4", $$v)},expression:"row.illustration_answer_4"}})]}}])}),_c('vxe-column',{attrs:{"field":"answer_5","title":"Ответ 5","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.answer_5),callback:function ($$v) {_vm.$set(row, "answer_5", $$v)},expression:"row.answer_5"}})]}}])}),_c('vxe-column',{attrs:{"field":"illustration_answer_5","title":"Иллюстрация для ответа 5","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"number"},model:{value:(row.illustration_answer_5),callback:function ($$v) {_vm.$set(row, "illustration_answer_5", $$v)},expression:"row.illustration_answer_5"}})]}}])}),_c('vxe-column',{attrs:{"field":"qz_check","title":"Ответ подходит для панели qz-04","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-checkbox',{model:{value:(row.qz_check),callback:function ($$v) {_vm.$set(row, "qz_check", $$v)},expression:"row.qz_check"}})]}}])}),_c('vxe-column',{attrs:{"field":"correct_answer","title":"Правильный ответ","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.correct_answer),callback:function ($$v) {_vm.$set(row, "correct_answer", $$v)},expression:"row.correct_answer"}})]}}])}),_c('vxe-column',{attrs:{"field":"illustration_for_correct_answer","title":"Иллюстрация для правильного ответа","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"number"},model:{value:(row.illustration_for_correct_answer),callback:function ($$v) {_vm.$set(row, "illustration_for_correct_answer", $$v)},expression:"row.illustration_for_correct_answer"}})]}}])}),_c('vxe-column',{attrs:{"field":"cost_correct_answer","title":"Стоимость правильного ответа","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"number"},model:{value:(row.cost_correct_answer),callback:function ($$v) {_vm.$set(row, "cost_correct_answer", $$v)},expression:"row.cost_correct_answer"}})]}}])}),_c('vxe-column',{attrs:{"field":"cost_wrong_answer","title":"Стоимость неправильного ответа","edit-render":{},"sortable":""},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"number"},model:{value:(row.cost_wrong_answer),callback:function ($$v) {_vm.$set(row, "cost_wrong_answer", $$v)},expression:"row.cost_wrong_answer"}})]}}])})],1),_c('vxe-pager',{attrs:{"border":"","icon-prev-page":"fa fa-angle-left","icon-jump-prev":"fa fa-angle-double-left","icon-jump-next":"fa fa-angle-double-right","icon-next-page":"fa fa-angle-right","icon-jump-more":"fa fa-ellipsis-h","loading":_vm.loading,"current-page":_vm.paginator.currentPage,"page-size":_vm.paginator.limit,"total":_vm.paginator.total,"page-sizes":_vm.paginator.pageSizes,"layouts":['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']},on:{"page-change":_vm.changePage}})],1)],1),(_vm.isShowWysywig)?_c('wysywig-popup',{attrs:{"html":_vm.data[_vm.latestCellContextMenuClick.row][_vm.latestCellContextMenuClick.column]},on:{"html-input":_vm.htmlInput},model:{value:(_vm.wysywigPopup),callback:function ($$v) {_vm.wysywigPopup=$$v},expression:"wysywigPopup"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }