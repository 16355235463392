<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <FormForm
              :form-prop="fetchedDetail"
              @submit="save"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import FormForm from "../../../components/forms/FormForm";
import {mapActions, mapGetters} from "vuex";
export default {
  name: "Edit",
  components: {FormForm},
  data: () => ({
    page: {
      title: "Редактирование формы"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Forms",
        disabled: true,
        to: '/forms'
      },
      {
        text: "Редактировать",
        disabled: true,
        to: '/forms/edit'
      },
    ]
  }),
  computed: {
    ...mapGetters({
      fetchedDetail: 'getFetchedDetail',
    })
  },
  created() {
    this.get();
  },
  methods: {
    ...mapActions({
      fetchDetail: 'fetchDetail',
      updateItem: 'updateItem'
    }),
    async get() {
      await this.fetchDetail(this.$route.params.id);
    },
    save(form){
      this.updateItem(form).then(() => this.$router.push({ name: 'forms-index' }));
    }
  },
  watch: {
    'fetchedDetail.title': {
      handler(newTitle) {
        this.page.title +=  ` "${newTitle}"`;
      }
    },
  }
}
</script>

<style scoped>

</style>
