<template>
  <v-form>
    <v-text-field
      v-model="form.title"
      :error-messages="errors.title"
      label="Название"
      name="title"
      required
    ></v-text-field>
    <v-text-field
      v-model="form.slug"
      :error-messages="errors.slug"
      label="Slug"
      name="slug"
      required
      :disabled="!(Object.keys(organizer).length === 0)"
    ></v-text-field>
    <v-datetime-picker
      v-model="form.published_at"
      scrollable
      label="Дата публикации. По умолчанию текущая дата и время"
      clear-text="Очистить"
    ></v-datetime-picker>
    <v-textarea
      name="lead"
      label="Лид"
      v-model="form.lead"
      :error-messages="errors.lead"
      required
    ></v-textarea>
    <v-text-field
      v-model="form.site_url"
      :error-messages="errors.site_url"
      label="Ссылка на сайт"
      name="site_url"
    ></v-text-field>
    <v-text-field
      v-model="form.vk_url"
      :error-messages="errors.vk_url"
      label="Ссылка на VK"
      name="vk_url"
    ></v-text-field>
    <v-text-field
      v-model="form.ok_url"
      :error-messages="errors.ok_url"
      label="Ссылка на Odnoklassniki"
      name="ok_url"
    ></v-text-field>
    <v-text-field
      v-model="form.fb_url"
      :error-messages="errors.fb_url"
      label="Ссылка на FB"
      name="fb_url"
    ></v-text-field>
    <v-text-field
      v-model="form.inst_url"
      :error-messages="errors.inst_url"
      label="Ссылка на Instagram"
      name="inst_url"
    ></v-text-field>
    <v-text-field
      v-model="form.tg_url"
      :error-messages="errors.tg_url"
      label="Ссылка на Telegram"
      name="tg_url"
    ></v-text-field>
    <v-text-field
      v-model="form.tt_url"
      :error-messages="errors.tt_url"
      label="Ссылка на TikTok"
      name="tt_url"
    ></v-text-field>
    <v-text-field
      v-model="form.youtube_url"
      :error-messages="errors.youtube_url"
      label="Ссылка на YouTube"
      name="youtube_url"
    ></v-text-field>
    <v-text-field
      v-model="form.twitter_url"
      :error-messages="errors.twitter_url"
      label="Ссылка на Twitter"
      name="twitter_url"
    ></v-text-field>
    <v-text-field
      v-model="form.zen_url"
      :error-messages="errors.zen_url"
      label="Ссылка на Дзен"
      name="zen_url"
    ></v-text-field>
    <v-text-field
      v-model="form.email"
      :error-messages="errors.email"
      label="Почта"
      name="email"
    ></v-text-field>
    <v-text-field
      v-model="form.phone"
      :error-messages="errors.phone"
      label="Телефон"
      name="phone"
    ></v-text-field>
    <v-text-field
      v-model="form.geographic_address"
      :error-messages="errors.geographic_address"
      label="Географический адрес"
      name="geographic_address"
    ></v-text-field>
    <v-textarea
      v-model="form.comment"
      :error-messages="errors.comment"
      label="Комментарий"
      name="comment"
    ></v-textarea>
    <media-widget
      class="mb-1"
      label="Изображение"
      :medias="form.image"
      :is-multiple="false"
      @onChange="setImage"
    />
    <v-btn
      color="primary"
      :disabled="isSaveDisabled"
      :loading="isSaveDisabled"
      @click="submit"
    >
      Сохранить
    </v-btn>
    <media-uploader
      :loaded-medias="organizer.loaded_medias"
      @completeUploads="onCompleteUploads"
    />
  </v-form>
</template>

<script>
import MediaUploader from '../MediaUploader';
import MediaWidget from '../MediaWidget';
import moment from "moment";
import slugify from 'slugify';

export default {
  name: "OrganizerForm",
  components: {
    MediaUploader,
    MediaWidget,
  },
  props: {
    organizer: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    form: {
      id: null,
      title: null,
      slug: null,
      published_at: null,
      lead: null,
      image: null,
      site_url: null,
      vk_url: null,
      fb_url: null,
      ok_url: null,
      tg_url: null,
      tt_url: null,
    },
    mediaIds: [],
    isSaveDisabled: false,
  }),
  created() {
    if (this.$route.params.data) {
      this.form = JSON.parse(this.$route.params.data);
    }
  },
  methods: {
    submit() {
      this.isSaveDisabled = true;

      this.form.published_at = this.form.published_at
        ? moment(this.form.published_at).format('YYYY-MM-DD HH:mm')
        : null;

      this.$emit('onSubmit', {form: this.form, mediaIds: this.mediaIds});
    },
    async onCompleteUploads(ids) {
      this.mediaIds = ids;
      await this.submit(true);
      this.mediaIds = [];
    },
    setImage(image) {
      this.form.image = image;
    },
  },
  watch: {
    organizer: {
      handler(organizer) {
        if (organizer && Object.keys(organizer).length > 0) {
          this.form = organizer;
          this.isSaveDisabled = false;
        }
      },
      immediate: true,
      deep: true,
    },
    'form.title': {
      handler(value) {
        if (value && Object.keys(this.organizer).length === 0) {
          this.form.slug = slugify(value.toLowerCase()).match(/[a-z]|[0-9]|[-]/gm).join('');
        }
      }
    },
  },
}
</script>
