<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Редактирование настроек</h1>
      </v-col>
    </v-row>
    <optionForm
      :option="option.$attributes"
      @onSubmit="save"
    />
    <v-snackbar
      v-model="hasErros"
    >
      {{errors}}
    </v-snackbar>
  </v-container>
</template>

<script>
import OptionForm from "../../../components/portalOptions/OptionForm";
import {Option} from "../../../models/option";

export default {
  components: {
    OptionForm,
  },
  name: "Edit",
  data() {
    return {
      option: {},
      errors: {},
      hasErros: false,
    }
  },
  created() {
    this.get()
  },
  methods: {
    async get() {
      this.option = await Option.$query().find(this.$route.params.id);
    },
    async save(form) {
      this.errors = {};
      await this.option.$save(form)
        .then(() => this.$router.push({name: 'portal-options-index'}))
        .catch(() => {this.errors.message = "Такой ключ уже существует"; this.errors = true});
    }
  }
}
</script>

<style scoped>

</style>
