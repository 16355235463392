<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Создание организатора</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <organizer-form
          :errors="errors"
          @onSubmit="submit"
        />
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbarSuccess"
      :timeout="timeout"
      color="success"
    >
      Сохранено

      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          @click="snackbarSuccess = false"
          color="red"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackbarError"
      :timeout="timeout"
      color="error"
    >
      {{ this.errors }}

      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          @click="snackbarError = false"
          color="red"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import {Organizer} from '../../../models/organizer';
import OrganizerForm from "../../../components/organizers/OrganizerForm";

export default {
  name: 'Create',
  components: {OrganizerForm},
  data: () => ({
    organizer_id: null,
    errors: {},
    snackbarSuccess: false,
    snackbarError: false,
    timeout: 2000,
  }),
  mounted() {
    if (this.$route.params.organizer_id) {
      this.organizer_id = this.$route.params.organizer_id
    }
  },
  methods: {
    async submit({form, mediaIds}) {
      try {
        this.errors = {};
        if (mediaIds) {
          form.mediaIds = mediaIds;
        }
        const organizer = await Organizer.$query().store(form);
        this.snackbarSuccess = true;
        await this.$router.push({name: 'organizers-edit', params: {id: organizer.$attributes.id}});
      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors
          this.snackbarError = true;
        }
        throw e;
      }
    },
  },
};
</script>
