import { Model } from "@tailflow/laravel-orion/lib/model";

export class Suggest extends Model<{
    title: string,
    created_at: string,
    updated_at: string,
}> {

    $resource(): string {
        return "suggests";
    }
}
