<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Настройки портала</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="primary" to="/portal-options/create">
          Создать настройку
        </v-btn>
        <v-btn color="warning" class="ml-2" @click="reloadCache">
          Сбросить кэш
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model.lazy="search"
          label="Поиск..."
          name="title"
        >
          <v-icon slot="prepend">
            mdi-magnify
          </v-icon>
          <v-btn
            slot="append"
            icon
            @click="search = null"
            color="red"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="this.headers"
          :items="this.items"
          :options.sync="options"
          :server-items-length="total"
        >
          <template v-slot:item.action="{ item }">
            <v-btn
              :href="editItem(item)"
              icon
            >
              <v-icon color="primary">
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-icon
              color="red"
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {Option} from "../../../models/option";
import axiosInstance from "../../../axiosInstance";

export default {
  name: "Index",
  data() {
    return {
      search: '',
      options: {
        sortBy: ['option_key'],
        sortDesc: [true],
      },
      total: 0,
      headers: [
        {text: "Ключ", value: "option_key"},
        {text: "Значение", value: "option_value"},
        {text: "Дата изменения", value: "updated_at"},
        {text: "", value: "action"},
      ],
      items: [],
    }
  },
  created() {
    this.get();
  },
  methods:{
    async get() {
      // eslint-disable-next-line no-unused-vars
      const {sortBy, sortDesc, page, itemsPerPage} = this.options;
      let data = {};
      this.search ? data.search = {value: this.search} : null;
      sortBy && sortBy[0] ? data.sort = [{field: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc'}] : null;

      await axiosInstance.post(`/options/search?limit=${itemsPerPage}&page=${page}`, data).then((response) => {
        this.items = response.data.data;
        this.total = response.data.meta.total;
      });
    },
    async deleteItem(item) {
      await Option.$query().destroy(item.option_key).then(() => this.get());
    },
    async reloadCache() {
      await Option.reloadCache().then(() => this.get());
    },
    editItem(item) {
      return this.$router.resolve({ name: 'portal-options-edit', params: { id: item.option_key } }).href
    }
  },
  watch: {
    search: {
      handler() {
        this.get();
      }
    },
    options: {
      handler() {
        this.get()
      },
      deep: true,
    },
  },
}
</script>
