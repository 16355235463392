<template>
  <div>
    <v-text-field
      v-model="form.title"
      label="Наименование"
    />
    <v-text-field
      v-model="form.subject"
      label="Заголовок письма"
    />
    <v-textarea
      v-model="form.hint"
      label="Подсказки"
    />
    <v-select
      :items="entities"
      v-model="form.entity_type"
      item-value="key"
      item-text="value"
    />
    <tip-tap v-model="form.html_template"/>
    <v-btn color="primary" @click="submit">Сохранить</v-btn>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import TipTap from "../editor/TipTap";

export default {
  name: "MailForm",
  components: {TipTap},
  props: {
    mail: {
      type: Object,
      required: false,
      default: () => ({}),
    }
  },
  data: () => ({
    form: {
      title: null,
      subject: null,
      html_template: null,
      entity_type: null,
      hint: null,
    },
  }),
  computed: {
    ...mapGetters({
      entities: 'Mails/getFetchedEntities',
    })
  },
  created() {
    this.fetchEntities();
  },
  methods: {
    ...mapActions({
      fetchEntities: 'Mails/fetchEntities',
    }),
    submit() {
      this.$emit('onSubmit', this.form);
    }
  },
  watch: {
    mail: {
      handler() {
        if (Object.keys(this.mail).length > 0) {
          this.form = JSON.parse(JSON.stringify(this.mail));
        }
      },
      immediate: true,
    },
  }
}
</script>

<style scoped>

</style>
