<template>
  <component
    v-if="formComponent"
    :is="formComponent"
    :form="formData"
    @mounted="handleFormMounted"
    ref="form$"
  />
</template>

<script>
  import axios from 'axios';
  export default {
    props: {
      form: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        endpoint: `${process.env.VUE_APP_API_URL}/api/module/form/{form}/detail`,
        formComponent: null,
        formData: {},
        form$: {},
      }
    },
    created() {
      axios.get(this.endpoint.replace('{form}', this.form)).then(({data}) => {
        this.formComponent = data.component
        this.formData = data.data
      })
    },
    methods: {
      handleFormMounted() {
        this.form$ = this.$refs.form$

        this.$emit('load', this.form$)
      }
    }
  }
</script>
