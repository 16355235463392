<template>
  <!---User -->
  <v-menu
    bottom
    left
    offset-y
    origin="top right"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn dark icon v-on="on" class="mr-1">
        <v-avatar size="40">
          <img
            v-if="user.avatar_url"
            :src="user.avatar_url"
            :alt="user.first_name"
          />
          <span
            v-else
            class="white--text headline"
          >
            {{ splitUserName }}
          </span>
        </v-avatar>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        v-for="(item, index) in menu"
        :key="index"
        :to="item.to ? item.to : null"
        @click="() => {
          if (item.action && typeof item.action === 'function') {
            item.action();
          }
        }"
      >
        <v-list-item-title>
          {{ item.title }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
  <!---User -->
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'UserMenu',


  data: () => ({
    menu: [
      {title: 'Профиль', to: '/profile'},
      {title: 'Выйти', action: 'onLogout',},
    ],
  }),
  created() {
    this.menu.forEach((item, index) => {
      if (item.action) {
        this.menu[index].action = this[item.action];
      }
    })
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
    }),
    splitUserName() {
      if (this.user && this.user.full_name) {
        const splitName = this.user.full_name.split(' ');
        if (splitName.length > 1) {
          return (splitName[0][0] + splitName[1][0]).toUpperCase();
        }
        return splitName[0][0].toUpperCase();
      }
      return '';
    }
  },
  methods: {
    async onLogout() {
      await this.$store.dispatch('auth/logout');
      await this.$router.push({name: 'auth-login'});
    }
  },
}
</script>
