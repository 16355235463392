<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <profile-form
      :user="userData"
      @onSubmit="submit"
    />
  </v-container>
</template>

<script>
import ProfileForm from "../../../components/profile/ProfileForm";
import {mapGetters} from "vuex";
import {User} from "../../../models/user";
import axiosInstance from "../../../axiosInstance";

export default {
  components: {ProfileForm},
  name: "Edit",
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
    })
  },
  data: () => ({
    userData: {},
    page: {
      title: "Редактирование профиля"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Профиль",
        disabled: true,
        to: '/profile'
      },
      {
        text: "Редактирование",
        disabled: true,
        to: '/profile/edit'
      },
    ]
  }),
  created() {
    this.get();
  },
  methods: {
    async submit(userData) {
      await axiosInstance.post('/auth/user/update-self', userData)
        .then(() => {
          this.$router.push({name: 'profile-index'});
        })
    },
    async get() {
      this.userData = await User.$query().find(this.user.id);
    }
  }
}
</script>
