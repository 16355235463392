import Index from '../../views/main/faqs/Index'
import Create from '../../views/main/faqs/Create'
import Edit from '../../views/main/faqs/Edit';

export default [
  {
    component: Index,
    name: 'faqs-index',
    path: '/faqs',
    meta: {
      layout: 'main-layout',
    }
  },
  {
    component: Create,
    name: 'faqs-create',
    path: '/faqs/create',
    meta: {
      layout: 'main-layout',
    }
  },
  {
    component: Edit,
    name: 'faqs-edit',
    path: '/faqs/edit/:id',
    meta: {
      layout: 'main-layout',
    }
  },
]
