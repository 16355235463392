<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <div v-if="isLoading">
          <div class="d-flex justify-center">
            <v-progress-circular indeterminate :size="50" color="primary"/>
          </div>
          <div class="d-flex justify-center mt-4">
            Идет проверка сайта....
          </div>
        </div>
        <div v-else>
          <v-row>
            <v-col>
              <v-card>
                <v-card-title class="d-flex justify-center">
                  Мобильные устройства
                </v-card-title>
                <v-card-text>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <strong>FCP</strong>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        Время первой отрисовки контента: {{ data.desktop.FCP }} сек.
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <strong>LCP</strong>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        Время отрисовки самой большой и видимой части содержимого: {{ data.desktop.LCP }} сек.
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col>
              <v-card>
                <v-card-title class="d-flex justify-center">
                  Мобильные устройства
                </v-card-title>
                <v-card-text>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <strong>FCP</strong>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        Время первой отрисовки контента: {{ data.mobile.FCP }} сек.
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <strong>LCP</strong>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        Время отрисовки самой большой и видимой части содержимого: {{ data.mobile.LCP }} сек.
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axiosInstance from "../../../axiosInstance";

export default {
  name: "SpeedTest",
  data: () => ({
    page: {
      title: 'Текст скорости сайта (Google PageSpeed Insights)',
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Тест скорости сайта",
        disabled: true,
        to: '/site-speed',
      },
    ],
    isLoading: true,
    data: null,
  }),
  mounted() {
    axiosInstance.get('module/site/site-speed').then((response) => {
      this.data = response.data;
      this.isLoading = false;
    });
  }
}
</script>

<style scoped>

</style>
