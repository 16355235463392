<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <h1>{{ page.title }}</h1>
          </v-col>
        </v-row>
        <v-tabs
          v-model="tab"
          background-color="transparent"
          grow
          class="mb-5"
        >
          <v-tab>
            Основная информация
          </v-tab>
          <v-tab>
            Предустановленные курсы
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <season-pass-form @onSubmit="submit" />
          </v-tab-item>
          <v-tab-item>
            Добавление предустановленных курсов доступно после создания абонемента
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import SeasonPassForm from "@/components/seasonPass/SeasonPassForm";
import {mapActions} from "vuex";

export default {
  name: 'Create',
  components: {SeasonPassForm},
  data: () => ({
    page: {
      title: "Создание абонемента"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Абонементы",
        disabled: true,
        to: '/season-pass'
      },
      {
        text: "Создание",
        disabled: true,
        to: '/season-pass/create'
      },
    ],
    tab: 0,
  }),
  methods: {
    ...mapActions({
      storeItem: 'SeasonPass/storeItem',
    }),
    submit(payload) {
      this.storeItem(payload).then(() => {});
    }
  }
}
</script>
