<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Редактирование элемента фасетной таксономии {{ collectionItem.id }}</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <collection-item-form :collection-item="collectionItem" :errors="errors" @onSubmit="submit"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { CollectionItem } from '../../../../models/collectionItem';
import CollectionItemForm from '../../../../components/collections/CollectionItemForm';

export default {
  name: 'Edit',
  components: { CollectionItemForm },
  data: () => ({
    collectionItem: {},
    errors: {},
  }),
  created() {
    this.find();
  },
  methods: {
    async submit(form) {
      try {
        this.errors = {};
        await CollectionItem.$query().update(form.id, form);
        await this.$router.push('/collection-items');
      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors
        }
        throw e;
      }
    },
    async find() {
      const collectionItem = await CollectionItem.$query().find(this.$route.params.id);
      this.collectionItem = collectionItem.$attributes;
    }
  },
};
</script>
