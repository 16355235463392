<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Создание функциональной иконки</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <FunctionalIconsForm @onSubmit="submit"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FunctionalIconsForm from "../../../components/functionalIcons/FunctionalIconsForm";
import {FunctionalIcon} from "../../../models/functionalIcon";

export default {
  name: "Create",
  components: {
    FunctionalIconsForm
  },
  methods: {
    async submit({ form }) {
      await FunctionalIcon.$query().store(form).then((response) => {
        this.$router.push({ name: 'functional-icons-edit', params: { id: response.$attributes.id } });
      });
    }
  }
}
</script>

<style scoped>

</style>
