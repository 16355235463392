import { Model } from "@tailflow/laravel-orion/lib/model";
import { CollectionItem } from "./collectionItem";
import {BelongsToMany} from "@tailflow/laravel-orion/lib/drivers/default/relations/belongsToMany";
import {Person} from "@/models/person";

export class Article extends Model<{
    created_by: number,
    parent_id: number,
    title: string,
    slug: string,
    language: string,
    position: number,
    created_at: string,
    updated_at: string,
    published_at: string,
    status: number,
    lead: string,
    format: number,
    type: number,
    meta_title: string,
    meta_description: string,
    meta_keywords: string,
    content: string,
    preview_image?: object,
    preview_url?: string,
}> {

    $resource(): string {
        return "module/articles";
    }

    public collectionItems(): BelongsToMany<CollectionItem> {
        return new BelongsToMany<CollectionItem>(CollectionItem, this);
    }

    public persons(): BelongsToMany<Person> {
        return new BelongsToMany<Person>(Person, this);
    }
}
