import {BlockGroup} from "../../../models/blockGroup";

export default {
  namespaced: true,
  state: {
    fetchingList: [],
  },
  getters: {
    getFetchedList: (state) => state.fetchingList,
  },
  mutations: {
    setFetchedList(state, value) {
      state.fetchingList = value;
    }
  },
  actions: {
    async fetchList({ commit, dispatch }) {
      await BlockGroup.$query().get(100000).then((response) => {
        console.log()
        commit('setFetchedList', response.map((item) => item.$attributes));
      }).catch(() => {
        dispatch('snackbar/showUnknownError', null, { root: true });
      });
    }
  }
}
