export const fields = {
  components: {
    //Standard fields
    'input-field': () => import('./input/Field'),
    'textarea-field': () => import('./textarea/Field'),
    'select-field': () => import('./select/Field'),
    'radio-field': () => import('./radio/Field'),
    'single-relation': () => import('./single-relation/Field'),
    'multiple-relation': () => import('./multiple-relation/Field'),
    'taxonomy-relation': () => import('./taxonomy-select/Field'),

    //Table fields
    'input-field-table': () => import('./table/input/Field'),
    'textarea-field-table': () => import('./table/textarea/Field'),
    'select-field-table': () => import('./table/select/Field'),
    'single-relation-table': () => import('./table/single-relation/Field'),
    'multiple-relation-table': () => import('./table/multiple-relation/Field'),
    'taxonomy-relation-table': () => import('./table/taxonomy-select/Field'),
  }
}
