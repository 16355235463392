import Index from '../../views/main/portal-options/Index';
import Edit from "../../views/main/portal-options/Edit";
import Create from "../../views/main/portal-options/Create";

export default [
  {
    component: Index,
    name: 'portal-options-index',
    path: '/portal-options',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'options',
    }
  },
  {
    component: Create,
    name: 'portal-options-create',
    path: '/portal-options/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'options',
    }
  },
  {
    component: Edit,
    name: 'portal-options-edit',
    path: '/portal-options/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'options',
    }
  }
]
