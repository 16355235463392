<template>
  <v-row>
    <v-col cols="12" lg="7" xl="6" class="info d-none d-md-flex align-center justify-center">
      <v-container>
        <div class="pa-10">
          <v-row justify="center">
            <v-col cols="8" xl="5">
              <div>
                <!--                <h2-->
                <!--                  class="display-1 white&#45;&#45;text font-weight-medium"-->
                <!--                >Elegant Design with unlimited features, built with love</h2>-->
                <!--                <h6-->
                <!--                  class="subtitle-1 mt-4 white&#45;&#45;text op-5 font-weight-regular"-->
                <!--                >Wrappixel helps developers to build organized and well-coded admin dashboards full of beautiful and feature rich modules.</h6>-->
                <!--                <v-btn class="mt-4 text-capitalize" x-large outlined color="white">Learn More</v-btn>-->
              </div>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
    <v-col cols="12" lg="5" xl="6" class="d-flex align-center">
      <v-container>
        <div class="pa-7 pa-sm-12">
          <v-row>
            <v-col cols="12" lg="9" xl="6">
              <img
                v-if="getOption('site_logo', '')"
                :src="getOption('site_logo', '')"
                :alt="getOption('siteName', '')"
              />
              <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">Восстановление пароля</h2>
              <!--              <h6 class="subtitle-1">-->
              <!--                Нет аккаунта?-->
              <!--                <a href="#/pages/fullregister" class>Создать</a>-->
              <!--              </h6>-->

              <v-form ref="form" @submit.prevent="submit">
                <v-alert
                  v-if="status"
                  type="success"
                  dismissible
                >
                  {{ status }}
                </v-alert>
                <v-text-field
                  label="Введите email"
                  v-model="form.email"
                  :error-messages="errors.email"
                  name="email"
                  required
                  outlined
                />
                <div class="d-block d-sm-flex align-center mb-4 mb-sm-0">
                  <div class="ml-auto">
                    <router-link :to="`/auth/login`" class="link">Войти</router-link>
                  </div>
                </div>
                <v-btn
                  type="submit"
                  color="info"
                  class="mr-4"
                  block
                >
                  Отправить ссылку на восстановление
                </v-btn>
              </v-form>
              <!--              <div class="text-center mt-6">-->
              <!--                <v-chip pill class="mr-2">-->
              <!--                  <v-avatar left>-->
              <!--                    <v-btn color="blue lighten-1" class="white&#45;&#45;text">-->
              <!--                      <v-icon>mdi-twitter</v-icon>-->
              <!--                    </v-btn>-->
              <!--                  </v-avatar>Twitter-->
              <!--                </v-chip>-->
              <!--                <v-chip pill>-->
              <!--                  <v-avatar left>-->
              <!--                    <v-btn color="teal lighten-2" class="white&#45;&#45;text">-->
              <!--                      <v-icon>mdi-github</v-icon>-->
              <!--                    </v-btn>-->
              <!--                  </v-avatar>Github-->
              <!--                </v-chip>-->
              <!--              </div>-->
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>


<script>
import {mapActions, mapGetters} from "vuex";
import axiosInstance from '../../axiosInstance';

export default {
  name: "Email",
  data() {
    return {
      form: {
        email: null,
      },
      status: null,
      errors: {},
    }
  },
  mounted() {
    this.form.email = this.$route.query.email;
  },
  computed: {
    ...mapGetters({
      getOption: 'options/getOption'
    })
  },
  methods: {
    ...mapActions({
      login: 'auth/login',
      getUser: 'auth/getUser'
    }),
    async submit() {
      try {
        this.errors = {};
        await axiosInstance.post('/password/email', { ...this.form }).then(() => {
          this.status = 'На почту отправлена ссылка восстановления'
        })
      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors
        }
        throw e;
      }
    }
  },
}
</script>
