<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <h1>Редактирование региона {{ region.title }}</h1>
          </v-col>
        </v-row>
        <v-tabs
          v-model="tab"
          background-color="transparent"
          grow
          class="mb-5"
        >
          <v-tab>
            Основная информация
          </v-tab>
          <v-tab>
            Таксономия
          </v-tab>
          <v-tab>
            SEO
          </v-tab>
          <v-tab>
            SMM
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <region-form
              :region="region"
              :is-edit="true"
              @onSubmit="submit"
            />
          </v-tab-item>
          <v-tab-item>
            <CollectionRelationForm
              module-name="Regions"
              :errors="errors"
              :selected-collection-items="region.selected_collection_items"
              :main-items="region.main_taxonomy_ids"
              @onSubmit="submitCollection"
              @onSubmitMain="submitMainCollection"
            />
          </v-tab-item>
          <v-tab-item>
            <seo-form
              :entity="region"
              :errors="errors"
              @onSubmit="submit"
            />
          </v-tab-item>
          <v-tab-item>
            <smm-form
              :entity="region"
              :errors="errors"
              @onSubmit="submit"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import RegionForm from '../../../components/regions/RegionForm';
import SeoForm from "../../../components/SEOForm";
import SmmForm from "../../../components/SMMForm";
import {mapActions, mapGetters} from "vuex";
import CollectionRelationForm from "../../../components/collections/CollectionRelationForm";

export default {
  name: 'Edit',
  components: {
    CollectionRelationForm,
    RegionForm,
    SeoForm,
    SmmForm,
  },
  data: () => ({
    errors: {},
    tab: 0,
    page: {
      title: "Редактирование региона"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Регионы",
        disabled: false,
        to: '/regions/'
      },
      {
        text: "Редактирование",
        disabled: true,
        to: '/regions/edit'
      },
    ],
  }),
  created() {
    this.fetchDetail(this.$route.params.id);
  },
  computed: {
    ...mapGetters({
      region: 'Regions/getFetchedDetail',
    })
  },
  methods: {
    ...mapActions({
      fetchDetail: 'Regions/fetchDetail',
      submitCollections: 'Regions/submitCollections',
      submitMainCollections: 'Regions/submitMainCollections',
      update: 'Regions/update',
    }),
    async submit(form) {
      this.update({
        form: form.form,
        mediaIds: form.mediaIds,
        isNeedRedirect: form.isNeedRedirect,
        router: this.$router,
      });
    },
    async submitCollection({ items, isNeedRedirect }) {
      await this.submitCollections({ items: items}).then(() => {
        if (isNeedRedirect) {
          this.$router.push({ name: 'regions-index' });
        }
      });
    },
    async submitMainCollection(items) {
      await this.submitMainCollections({items: items});
    }
  },
};
</script>
