<template>
  <div class="mb-5">
    <v-row class="pb-0">
      <v-col cols="12" class="pb-3">
        <v-row
          v-for="(item, index) in themes"
          :key="index"
          class="pb-0"
        >
          <v-col class="pb-0">
            <v-textarea
              v-model="item.text"
              label="Текст"
              hint="Сюда вписывается акцент курса. Каждый акцент можно вытащить из урока — просмотрев сам урок или прочитав конспект."
              name="text"
               persistent-hint
      outlined
       counter="150"
              required
            ></v-textarea>
          </v-col>

          <v-col class="pb-0">
            <media-widget
              class="mb-1"
              label="Иллюстрация к тексту"
              :medias="item.image"
              :is-multiple="false"
              @onChange="setImage($event, item)"
            />
            <div class="v-text-field__details">
              <div class="v-messages theme--light">
                <div class="v-messages__wrapper">
                  <div class="v-messages__message">
                    Желательно изображение, которое будет иллюстрировать ту мысль, которую вы внесли в поле "Текст". Изображение лучше предподготовить в квадрате.
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col class="pb-0">

              <v-btn
                icon
                color="red"
                @click="themes.splice(index, 1)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>

          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-btn @click="addItem">
          Добавить элемент
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MediaWidget from "../MediaWidget";

export default {
  name: "CourseThemes",
  props: {
    value: {
      type: Array,
      default: () => ([]),
    }
  },
  components: {
    MediaWidget,
  },
  data() {
    return {
      themes: [],
    }
  },
  watch: {
    value: {
      handler(value) {
        if (value) {
          this.themes = value;
        }
      },
      deep: true,
      immediate: true,
    },
    themes: {
      handler(value) {
        this.$emit('input', value);
      },
      deep: true,
    },
  },
  methods: {
    setImage(image, item) {
      item.image = image;
    },
    addItem() {
      this.themes.push({
        image: {},
        text: '',
        url: '',
      })
    },
  }
}
</script>
