<template>
  <ValidationObserver ref="observer">
    <ValidationProvider v-slot="{ errors }" name="Название" rules="required">
      <v-text-field v-model="form.title" :error-messages="errors" label="Название"/>
    </ValidationProvider>
    <ValidationProvider v-slot="{ errors }" name="Название">
      <v-text-field v-model="form.name" label="Название"/>
    </ValidationProvider>
    <ValidationProvider v-slot="{ errors }" name="Slug" rules="">
      <v-text-field v-model="form.slug" :disabled="slugDisabled" label="Slug"/>
    </ValidationProvider>
    <ValidationProvider v-slot="{ errors }" name="Регион" rules="required">
      <v-autocomplete chips :items="regionList" :error-messages="errors" v-model="form.region_id" item-value="id" item-text="title" label="Регион"/>
    </ValidationProvider>
    <ValidationProvider v-slot="{ errors }" name="Разработчики">
      <v-autocomplete multiple chips :items="partnerList" v-model="form.developers" item-value="id" item-text="title" label="Разработчики"/>
    </ValidationProvider>
    <ValidationProvider v-slot="{ errors }" name="Охват аудитории">
      <v-text-field v-model="form.audience_reach" label="Охват аудитории"/>
    </ValidationProvider>
    <ValidationProvider v-slot="{ errors }" name="Год награждения">
      <v-text-field v-model="form.year_of_awarding" label="Год награждения"/>
    </ValidationProvider>
    <ValidationProvider v-slot="{ errors }" name="Численность охвата">
      <v-text-field v-model="form.number_of_coverage" label="Численность охвата"/>
    </ValidationProvider>
    <ValidationProvider v-slot="{ errors }" name="Дополнение">
      <v-text-field v-model="form.addition" label="Дополнение"/>
    </ValidationProvider>
    <ValidationProvider v-slot="{ errors }" name="Год разработки">
      <v-text-field v-model="form.year_of_dev" label="Год разработки"/>
    </ValidationProvider>
    <ValidationProvider v-slot="{ errors }" name="Краткое описание">
      <v-textarea v-model="form.short_description" label="Краткое описание"/>
    </ValidationProvider>
    <ValidationProvider v-slot="{ errors }" name="Достигнутые результаты">
      <v-textarea v-model="form.reached_results" label="Достигнутые результаты"/>
    </ValidationProvider>
    <ValidationProvider v-slot="{ errors }" name="Ожидаемые результаты">
      <v-textarea v-model="form.expected_results" label="Ожидаемые результаты"/>
    </ValidationProvider>
    <ValidationProvider v-slot="{ errors }" name="Возможности и инструменты тиражирования">
      <v-textarea v-model="form.replication_tools" label="Возможности и инструменты тиражирования"/>
    </ValidationProvider>
    <ValidationProvider v-slot="{ errors }" name="Ссылка практики">
      <v-text-field v-model="form.url_practices" label="Ссылка практики"/>
    </ValidationProvider>
    <ValidationProvider v-slot="{ errors }" name="Социальные сети">
      <v-text-field v-model="form.social_url" label="Социальные сети"/>
    </ValidationProvider>
    <ValidationProvider v-slot="{ errors }" name="Региональный центр">
      <v-autocomplete :items="partnerList" chips item-value="id" item-text="title" v-model="form.regional_center_id" label="Региональный центр"/>
    </ValidationProvider>
    <ValidationProvider v-slot="{ errors }" name="Контактное лицо">
      <v-autocomplete :items="personList" chips item-value="id" item-text="title" v-model="form.contact_person" label="Контактное лицо"/>
    </ValidationProvider>
    <v-checkbox label="Является лучшей практикой" v-model="form.is_the_best"/>
    <v-btn :loading="isLoading" :disabled="isLoading" color="primary" @click="submit(true)">Сохранить</v-btn>
    <v-btn :loading="isLoading" :disabled="isLoading" color="primary" @click="submit">Сохранить и продолжить</v-btn>
  </ValidationObserver>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import slugify from "slugify";

export default {
  name: "RegionalPracticesForm",
  props: {
    regionalPractices: {
      required: false,
      default: () => ({}),
      type: Object,
    }
  },
  data: () => ({
    form: {
      title: null,
      slug: null,
      developers: [],
      region_id: null,
      audience_reach: null,
      year_of_dev: null,
      reached_results: null,
      expected_results: null,
      replication_tools: null,
      url_practices: null,
      social_url: null,
      regional_center_id: null,
      contact_person: null,
      year_of_awarding: null,
      number_of_coverage: null,
      addition: null,
      name: null,
      is_the_best: false,
    }
  }),
  async created() {
    await this.fetchRegionList();
    await this.fetchPartnerList();
    await this.fetchPersonList();
  },
  methods: {
    ...mapActions({
      fetchRegionList: "Regions/fetchList",
      fetchPartnerList: "Partners/fetchList",
      fetchPersonList: "Persons/fetchList",
    }),
    submit(isNeedRedirect) {
      this.$refs.observer.validate().then((isValid) => {
        if (isValid) {
          this.$emit('onSubmit', { form: this.form, isNeedRedirect: isNeedRedirect });
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      regionList: "Regions/setFetchedList",
      partnerList: "Partners/getFetchedList",
      personList: "Persons/getFetchedList",
      isLoading: "RegionalPractices/getIsLoading",
    }),
    slugDisabled() {
      return this.form.id !== undefined;
    }
  },
  watch: {
    regionalPractices: {
      handler(val) {
        if (val && Object.keys(val).length) {
          this.form = JSON.parse(JSON.stringify(val));
        }
      }
    },
    'form.title': {
      handler(val) {
        if (val && !this.form.id) {
          this.form.slug = slugify(val.toLowerCase()).match(/[a-z]|[0-9]|[-]/gm).join('');
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
