import axiosInstance from "../../../axiosInstance";
import {BlockSetting} from "../../../models/blockSetting";

export default {
  namespaced: true,
  state: {
    fetchedListing: [],
    fetchedList: [],
    listingTotalItems: 0,
    fetchedDetail: {},
    isSaveButtonDisabled: false,
    isListingLoad: false,
  },
  getters: {
    getFetchedListing: state => state.fetchedListing,
    getFetchedList: state => state.fetchedList,
    getFetchedDetail: state => state.fetchedDetail,
    getListingTotalItems: state => state.listingTotalItems,
    isSaveButtonDisabled: state => state.isSaveButtonDisabled,
    isListingLoad: state => state.isListingLoad,
  },
  mutations: {
    setFetchedListing(state, value) {
      state.fetchedListing = value;
    },
    setFetchedDetail(state, value) {
      state.fetchedDetail = value;
    },
    setListingTotalItems(state, value) {
      state.listingTotalItems = value;
    },
    setIsSaveButtonDisabled(state, value) {
      state.isSaveButtonDisabled = value;
    },
    setIsListingLoad(state, value) {
      state.isListingLoad = value;
    },
    setFetchedList(state, value) {
      state.fetchedList = value;
    },
  },
  actions: {
    fetchListing({ commit }, { sort, search }) {
      commit('setIsListingLoad', true);

      const { page, itemPerPage } = sort;
      let data = {};
      search ? data.search = {value: search} : null;

      axiosInstance.post(`/module/block-settings/search?limit=${itemPerPage}&page=${page}`, data).then((response) => {
        commit('setFetchedListing', response.data.data);
        commit('setListingTotalItems', response.data.meta.total);
      }).finally(() => {
        commit ('setIsListingLoad', false);
      })
    },
    async storeItem({ commit, dispatch }, form) {
      commit('setIsSaveButtonDisabled', true);

      await BlockSetting.$query().store(form).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, { root: true });
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, { root: true });
      }).finally(() => {
        commit('setIsSaveButtonDisabled', false);
      })
    },
    async destroyItem({ dispatch }, id) {
      await BlockSetting.$query().destroy(id).then(() => {
        dispatch('snackbar/showSuccessfullyDelete', null, { root: true });
      }).catch(() => {
        dispatch('snackbar/showDeleteError', null, { root: true });
      })
    },
    async updateItem({ dispatch, commit }, { id, ...values }) {
      commit('setIsSaveButtonDisabled', true);

      await BlockSetting.$query().update(id, values).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, { root: true });
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, { root: true });
      }).finally(() => {
        commit('setIsSaveButtonDisabled', false);
      })
    },
    async fetchDetail({ commit }, id) {
      await BlockSetting.$query().find(id).then((response) => {
        commit('setFetchedDetail', response.$attributes);
      })
    },
    async fetchList({ commit }) {
      await BlockSetting.$query().get(100000).then((response) => {
        commit('setFetchedList', response.map(el => el.$attributes));
      })
    }
  }
}
