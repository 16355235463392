import Index from '../../views/main/subscribers/Index'

export default [
  {
    component: Index,
    name: 'subscribers-index',
    path: '/subscribers',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'subscribers',
    }
  },
]
