<template>
  <v-row class="mt-5">
    <v-col cols="6">
      <h4><b>Области применения:</b></h4>
      <v-virtual-scroll
        :items="library.area"
        height="300"
        item-height="64"
      >
        <template v-slot:default="{ item }">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title v-text="item"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-virtual-scroll>
    </v-col>
    <v-col cols="6">
      <h4><b>Теги элемента:</b></h4>
      <v-virtual-scroll
        :items="library.tags"
        height="300"
        item-height="64"
      >
        <template v-slot:default="{ item }">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title v-text="item"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-virtual-scroll>
    </v-col>
    <v-col cols="6">
      <h4><b>Целевая аудитория:</b></h4>
      <v-virtual-scroll
        :items="library.target"
        height="300"
        item-height="64"
      >
        <template v-slot:default="{ item }">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title v-text="item"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-virtual-scroll>
    </v-col>
    <v-col cols="6">
      <h4><b>Тематика:</b></h4>
      <v-virtual-scroll
        :items="library.thematic"
        height="300"
        item-height="64"
      >
        <template v-slot:default="{ item }">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title v-text="item"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-virtual-scroll>
    </v-col>
    <v-col cols="6">
      <h4><b>Возраст:</b></h4>
      <v-virtual-scroll
        :items="library.age"
        height="300"
        item-height="64"
      >
        <template v-slot:default="{ item }">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title v-text="item"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-virtual-scroll>
    </v-col>
    <v-col cols="6">
      <h4><b>Категории:</b></h4>
      <v-virtual-scroll
        :items="library.category"
        height="300"
        item-height="64"
      >
        <template v-slot:default="{ item }">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title v-text="item"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-virtual-scroll>
    </v-col>
    <v-col cols="6">
      <h4><b>Содержательный тип:</b></h4>
      <v-virtual-scroll
        :items="library.notes"
        height="300"
        item-height="64"
      >
        <template v-slot:default="{ item }">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title v-text="item"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-virtual-scroll>
    </v-col>
    <v-col cols="6">
      <h4><b>Тип:</b></h4>
      <v-virtual-scroll
        :items="library.old_type"
        height="300"
        item-height="64"
      >
        <template v-slot:default="{ item }">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title v-text="item"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-virtual-scroll>
    </v-col>
    <v-col cols="6">
      <h4><b>Ключевые слова:</b></h4>
      <v-virtual-scroll
        :items="library.keywords"
        height="300"
        item-height="64"
      >
        <template v-slot:default="{ item }">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title v-text="item"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-virtual-scroll>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "OldTaxonomy",
  props: {
    library: {
      type: Object,
      required: true,
    }
  }
}
</script>
