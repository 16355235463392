<template>
  <v-container>
    <v-col>
      <v-row>
        <v-col>
          <h1>Ключевые слова
            <v-btn fab small color="primary" @click="createDialog = true">
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="10">
          <v-text-field placeholder="Поиск..." v-model="search"/>
        </v-col>
        <v-col>
          <v-checkbox label="Только удаленные" v-model="only_trashed"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="fetchedListing"
            :options.sync="options"
            :server-items-length="listingTotalItems"
            :loading="isLoading"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon
                v-if="!item.deleted_at"
                color="red"
                @click="deleteItem(item, 0)"
              >
                mdi-delete
              </v-icon>
              <v-icon
                v-else
                color="green"
                @click="restore(item)"
              >
                mdi-backup-restore
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row v-if="fetchGraphic">
        <v-col>
          <apexchart type="line" :series="fetchedGraphic.series" :options="fetchedGraphic.options" height="500"></apexchart>
        </v-col>
      </v-row>
    </v-col>

    <v-dialog transition="dialog-to-transition" max-width="600" v-model="createDialog">
      <v-card>
        <v-toolbar color="primary" dark>
          Создание ключевого слова
        </v-toolbar>
        <v-card-text>
          <v-container>
            <validation-observer ref="observer">
              <validation-provider rules="required" name="Ключевое поле" v-slot="{ errors }">
                <v-text-field
                  v-model="form.key_word"
                  :error-messages="errors"
                  hint="Введите ключевое слово"
                  counter="255"
                  label="Ключевое слово"
                />
              </validation-provider>
            </validation-observer>
            <v-btn
              block
              color="primary"
              class="mt-2"
              :loading="isLoading"
              :disabled="isLoading"
              @click="storeKeyWord"
            >
              Добавить ключевое слово
            </v-btn>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import Swal from 'sweetalert2';

export default {
  name: "SeoPositionComponent",
  props: {
    entity_type: {
      type: String,
      require: true,
    },
    entity_id: {
      type: Number,
      require: true,
    }
  },
  data: () => ({
    search: '',
    only_trashed: false,
    options: {
      sortBy: ['id'],
      sortDesc: [true],
    },
    form: {
      key_word: null,
    },
    chartOptions: {
      chart: {
        type: 'line',
      },
      title: {
        text: 'Page Statistics',
        align: 'left'
      },
      xaxis: {
        categories: [],
      },
    },
    createDialog: false,
    headers: [
      {text: 'ID', value: 'id'},
      {text: 'Ключевое слово', value: 'key_word'},
      {text: '', value: 'actions', sortable: false,},
    ]
  }),
  created() {
    this.fetchListing(this.listingPayload);
    this.form.entity_type = this.entity_type;
    this.form.entity_id = this.entity_id;
    this.fetchGraphic(this.form).then(() => {
      this.chartOptions.xaxis = this.fetchedGraphic.xaxis;
    });
  },
  computed: {
    ...mapGetters({
      "fetchedListing": "KeyWords/getFetchedListing",
      "listingTotalItems": "KeyWords/getListingTotalItems",
      "isLoading": "KeyWords/getIsLoading",
      "fetchedGraphic": "KeyWords/getFetchedGraphic",
    }),
    listingPayload() {
      return {
        options: this.options,
        search: this.search,
        only_trashed: this.only_trashed,
        entity_id: this.entity_id,
        entity_type: this.entity_type,
      }
    },
  },
  methods: {
    ...mapActions({
      "fetchListing": "KeyWords/fetchListing",
      "store": "KeyWords/storeItem",
      "destroyItem": "KeyWords/destroyItem",
      "restoreItem": "KeyWords/restoreItem",
      "fetchGraphic": "KeyWords/fetchGraphic",
    }),
    storeKeyWord() {
      this.$refs.observer.validate().then((isValid) => {
        if (isValid) {
          this.store(this.form).then(() => {
            this.fetchListing(this.listingPayload);
            this.createDialog = false;
            this.form.key_word = null;
          })
        }
      })
    },
    deleteItem({id}, isForce) {
      Swal.fire({
        title: "Вы уверены?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Да",
        cancelButtonText: "Нет",
      }).then(({value}) => {
        if (value) {
          this.destroyItem({id: id, isForce: isForce}).then(() => {
            this.fetchListing(this.listingPayload);
          })
        }
      })
    },
    restore({id}) {
      this.restoreItem(id).then(() => {
        this.fetchListing(this.listingPayload);
      })
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchListing(this.listingPayload);
      },
      deep: true,
      immediate: true,
    },
    search: {
      handler() {
        this.fetchListing(this.listingPayload);
      },
    },
    only_trashed: {
      handler() {
        this.fetchListing(this.listingPayload);
      },
    },
  }
}
</script>

<style scoped>

</style>
