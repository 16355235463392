import {Model} from "@tailflow/laravel-orion/lib/model";
import {HasMany} from "@tailflow/laravel-orion/lib/drivers/default/relations/hasMany";
import {Block} from "./block";

export class BlockGroup extends Model<{
    title: string,
    created_at: string,
    updated_at: string,
}> {

    $resource(): string {
        return "module/block-groups";
    }

    public blocks(): HasMany<Block> {
        return new HasMany<Block>(Block, this);
    }

}
