import Index from "../../views/main/fields/Index";
import Create from "../../views/main/fields/Create";
import Edit from "../../views/main/fields/Edit";

export default [
  {
    component: Index,
    name: 'fields-index',
    path: '/fields',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'fields',
    }
  },
  {
    component: Create,
    name: 'fields-create',
    path: '/fields/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'fields',
    }
  },
  {
    component: Edit,
    name: 'fields-edit',
    path: '/fields/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'fields',
    }
  },
]
