import Index from '../../views/main/seasonPass/Index'
import Create from '../../views/main/seasonPass/Create'
import Edit from '../../views/main/seasonPass/Edit'


export default [
  {
    component: Index,
    name: 'seasonPass-index',
    path: '/season-pass',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      // hasAccess: 'seasonPass.view',
      //module: 'ShortUrl',
    }
  },
  {
    component: Create,
    name: 'seasonPass-create',
    path: '/season-pass/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      // hasAccess: 'seasonPass.create',
      //module: 'ShortUrl',
    }
  },
  {
    component: Edit,
    name: 'seasonPass-edit',
    path: '/season-pass/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      //module: 'ShortUrl',
    }
  },
]
