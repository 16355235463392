<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row>
      <v-col>
        <h1>Создание нового клиента</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <customer-form @onSubmit="submit"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CustomerForm from "../../../components/customers/CustomerForm";
import {mapActions} from "vuex";
export default {
  name: "Create",
  components: {CustomerForm},
  data: () => ({
    page: {
      title: "Создание клиента"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Клиенты",
        disabled: false,
        to: '/customers'
      },
      {
        text: "Создание",
        disabled: true,
        to: '/customers/create'
      },
    ],
  }),
  methods: {
    ...mapActions({
      storeItem: 'Customers/storeItem',
    }),
    submit(form) {
      const payload = {
        form: form,
        router: this.$router,
      }
       this.storeItem(payload);
    }
  }
}
</script>

<style scoped>

</style>
