<template>
  <v-form ref="form" lazy-validation>
      <v-tabs
        v-model="tab"
        background-color="transparent"
        grow
        class="mb-5"
      >
        <v-tab>
          Свойства
        </v-tab>
        <v-tab>
          Пользователи промокода
        </v-tab>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-text-field
              v-model="form.title"
              :error-messages="errors.title"
              label="Описание"
              name="title"
              required
              :rules="[rules.required]"
            ></v-text-field>
            <v-text-field
              v-model="form.code"
              :error-messages="errors.code"
              label="Промокод"
              :rules="[promocodeCheck(), rules.required]"
              counter="12"
              name="code"
              required
            ></v-text-field>
            <v-select
              v-model="form.type"
              :error-messages="errors.type"
              :items="types"
              label="Сценарий"
              name="type"
              required
              :rules="[rules.required_num]"
            ></v-select>
            <v-text-field
              v-model="form.amount"
              :error-messages="errors.amount"
              label="Величина"
              name="amount"
              type="number"
              :disabled="form.type === null"
              required
              :rules="[rules.required]"
            ></v-text-field>
            <v-text-field
              v-model="form.valid_from"
              :error-messages="errors.valid_from"
              label="Активен с"
              name="valid_from"
              type="date"
              required
              :rules="[rules.required]"
            ></v-text-field>
            <v-text-field
              v-model="form.valid_to"
              :error-messages="errors.valid_to"
              label="Активен по"
              name="valid_to"
              type="date"
              required
              :rules="[rules.required]"
            ></v-text-field>
            <v-text-field
              v-model="form.activation_limit"
              :error-messages="errors.activation_limit"
              label="Лимит активаций"
              name="activation_limit"
              type="number"
              required
              :rules="[rules.required]"
            ></v-text-field>
            <v-text-field
              v-model="form.card_title"
              :error-messages="errors.card_title"
              label="Заголовок (для карточки)"
              name="card_title"
              type="text"
            ></v-text-field>
            <v-text-field
              v-model="form.description"
              :error-messages="errors.description"
              label="Описание (для карточки)"
              name="description"
              type="text"
            ></v-text-field>
            <v-checkbox
              v-model="form.is_public"
              :error-messages="errors.is_public"
              name="is_public"
              label="Публичный промокод"
              :true-value="1"
              :false-value="0"
            ></v-checkbox>
            <v-checkbox
              v-model="form.status"
              :error-messages="errors.status"
              name="status"
              label="Активен"
              :true-value="1"
              :false-value="0"
            ></v-checkbox>
          </v-tab-item>
           <v-tab-item>
            <h2 class="mt-4">Зарегистрированные пользователи ({{ this.promocodeUsers.length }})</h2>
            <v-col cols="12" lg="12">
              <v-select label="Добавить пользователя" chips item-text="name" item-value="id" v-model="promocodeUsersInitial" :items="copyUsersList" multiple>
                <template v-slot:selection="[]">
                </template>
                <template v-slot:prepend-item>
                  <v-text-field class="ml-2 mr-2" v-model="searchUser" @input="search"></v-text-field>
                  <v-divider class="mt-2"/>
                </template>
                <template v-slot:item="data" >
                  <v-list-item-content @click="addUser(data.item)">
                    <v-list-item-title>
                      {{ data.item.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ data.item.email }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>
            <v-container class="grey lighten-5">
              <v-row class="align-center">
                <v-col cols="4" lg="4">
                  <v-text-field
                    v-model="searchPromocodeUsers"
                    prepend-inner-icon="mdi-magnify"
                    label="Поиск..."
                    filled
                    background-color="transparent"
                    hide-details
                    name="title"
                  >

                  <v-btn v-if="searchPromocodeUsers" class="reset-button"
                          slot="append"
                          icon
                          @click="searchPromocodeUsers = null"
                          color="red"
                    >
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
                  </v-text-field>
                </v-col>
                <v-col cols="4" lg="4">
                  <v-btn
                    block
                    color="primary"
                    @click="deleteItems"
                    :loading="deleteBtnLoading"
                    >
                      Удалить отмеченные
                  </v-btn>
                </v-col>
                <v-col cols="3">
                <v-menu
                  v-model="datePicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="createdAtFilter"
                      label="Дата добавления"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      append-icon="mdi-close"
                      @click:append="createdAtFilter = null"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="createdAtFilter"
                    locale="ru-RU"
                    range
                  >
                    <v-spacer/>
                    <v-btn text color="primary" @click="datePickerAction">ОК</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
             </v-row>
           </v-container>
              <v-data-table
                    :headers="usersHeaders"
                    :items="usersSearch"
                    :loading="loadIndicator"
                    show-select
                     v-model="selected"
                  >
                    <template v-slot:item.pivot.created_at="{ item }">
                        {{ formatDate(item.pivot.created_at) }}
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-btn
                        :href="editItem(item)"
                        icon
                      >
                        <v-icon color="primary" small>
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                      <v-icon
                        color="red"
                        @click="removeUser(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-data-table>
              <div class="mt-4">
                <h2>Незарегистрированные пользователи ({{ this.unregisteredUsers.length }})</h2>
                  <v-flex class="container fill-height row justify-start">
                    <div class="col-3">
                      <v-text-field
                        v-model="unregisteredUserEmail"
                        label="Добавить email"
                        type="text"
                      ></v-text-field>
                    </div>
                    <div class="col-2">
                      <v-btn
                        block
                        color="primary"
                        @click="addUnregisteredUser(unregisteredUserEmail)"
                        >
                          Добавить
                      </v-btn>
                    </div>
                    <div class="col-3">
                      <v-btn
                        block
                        color="primary"
                        @click="checkUnregisteredUsers()"
                        >
                          Удалить прикреплённых
                      </v-btn>
                    </div>
                    <div class="col-3">
                      <v-btn
                        block
                        color="primary"
                        @click="removeUnregisteredUsersBtn()"
                        >
                          Удалить отмеченные
                      </v-btn>
                    </div>
                  </v-flex>
                <v-data-table
                    :headers="usersHeaders"
                    :items="unregisteredUsers"
                    :loading="loadIndicator"
                    show-select
                     v-model="unregisteredSelected"
                  >
                    <template v-slot:item.actions="{ item }">
                      <v-icon
                        color="red"
                        @click="removeUnregisteredUser(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                </v-data-table>
              </div>
              <div>
                <div class="d-flex flex-column mt-2 col-6 block pl-0">
                  <v-subheader class="pl-0 mb-6">Разрешенные форматы для загрузки .csv .xlsx.
Для идентификации пользователя используется емейл. В таблице размещается столбец с переченем емейлов - каждый адрес в отдельной ячейке.</v-subheader>
                  <div class="mb-2">
                    <input
                      v-if="form.id"
                      ref="file"
                      type="file"
                      accept=".csv, .xls,.xlsx"
                      class="input-file"
                      v-on:change="checkFile"
                      :disabled="uploadFileDisabled"
                    >
                  </div>
                  <v-row class="col-md-4">
                    <v-btn
                      v-if="usersFile"
                      color="green"
                      class="input-style"
                      @click="uploadFile"
                      :disabled="uploadFileDisabled"
                      >
                        Загрузить пользователей
                    </v-btn>
                  </v-row>
                  <v-row class="col-md-4">
                    <v-btn
                    v-if="lastUnregisteredUlpoaded.length || uploadedUsers.length"
                      color="primary"
                      @click="deleteLastAdded"
                    >
                      Отменить последний ввод
                    </v-btn>
                  </v-row>
                </div>
              </div>
           </v-tab-item>
        </v-tabs-items>
      </v-tabs>



    <v-flex>
      <v-btn
        color="primary"
        :disabled="isSaveDisabled"
        :loading="isSaveDisabled"
        @click="submit"
      >
        Сохранить
      </v-btn>
    </v-flex>

  </v-form>
</template>

<script>
import moment from 'moment';
import {mapActions, mapGetters} from "vuex";
import axiosInstance from "../../axiosInstance";

export default {
  name: "PromocodeForm",
  props: {
    promocode: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    form: {
      title: null,
      code: null,
      type: null,
      amount: null,
      valid_from: null,
      valid_to: null,
      activation_limit: null,
      status: 0,
      emails: null,
    },
    options: {
        sortBy: ['id'],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 20,
      },
    types: [
      {text: 'Скидка на сумму', value: 0},
      {text: 'Скидка на %', value: 1},
      {text: 'Доступ', value: 2},
    ],
    selected: [],
    searchPromocodeUsers: null,
    searchPromocodeUsersValue: null,
    codePattern: new RegExp('[^A-Za-z0-9]+'),
    isSaveDisabled: false,
    uploadFileDisabled: false,
    searchUser: "",
    copyUsersList: [],
    createdAtFilter: null,
    searchText: '',
    only_trashed: null,
    promocodeUsers: [],
    promocodeUsersInitial: [],
    promocodeUsersSearch: [],
    usersSearch: [],
    tab: 0,
    uploadedPromocodeUsers: [],
    uploadedUsers: [],
    lastUnregisteredUlpoaded: [],
    unregisteredUsers: [],
    unregisteredUserEmail: null,
    unregisteredSelected: [],
    loadIndicator: false,
    usersFile: false,
    datePicker: false,
    deleteBtnLoading: false,
    usersHeaders: [
      {text: 'Id', value: 'id'},
      {text: 'Имя', value: 'first_name'},
      {text: 'Фамилия', value: 'last_name'},
      {text: 'Email', value: 'email'},
      {text: 'Дата', value: 'pivot.created_at'},
      {text: '', value: 'actions'},
    ],
    rules: {
      required: value => !!value || 'Обязательное поле',
      required_num: value => !!Number.isInteger(value) || 'Обязательное поле',
    },
  }),
    computed: {
    ...mapGetters({
      usersList: 'Users/getFetchedListing',
      usersPromocodeList: 'Users/getFetchedListing',
    }),
  },
  created() {
    if (this.$route.params.data) {
      this.form = JSON.parse(this.$route.params.data);
    }
    this.get();
  },
  mounted() {
    this.getPromocodeUsers();
  },
  methods: {
    submit() {
      if(this.$refs.form.validate()){
        if (this.promocodeCheck()) {
          this.isSaveDisabled = true;
          this.$emit('onSubmit', {
            form: this.form,
          });
        }
      }
    },
    submitAddPromocodeUsers() {
      if(this.promocodeUsers.length && this.form.id){
        let initial = this.promocodeUsersInitial;
        let users = this.promocodeUsers.filter(function(element){
          return initial.includes(element.id) == false;
        });
        this.uploadedUsers = users;
        if(users.length)
        {
          axiosInstance.post(`/promocodes/promocode/${this.form.id}/attach`,  {'users': users.map(element => element.id)},
            ).then(() => {
              this.getPromocodeUsers();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    async removeUser(user) {
      if(user.id)
      {
        await axiosInstance.get(`/auth/user/${user.id}/promocodes/${this.form.id}/detach`);
        this.getPromocodeUsers();
      }
    },
    editItem(item) {
      return this.$router.resolve({name: 'users-edit', params: {id: item.id}}).href
    },
    async addUser(user) {
      if(this.promocodeUsersInitial.includes(user.id) == false)
      {
        await axiosInstance.get(`/auth/user/${user.id}/promocodes/${this.form.id}/attach`);
        this.getPromocodeUsers();
      }
    },
    promocodeCheck() {
      if (this.codePattern.test(this.form.code) || (this.form.code && this.form.code.length > 12)) {
        this.errors['code'] = 'Латиница и цифры, не больше 12 символов';
        return false;
      }

      this.errors['code'] = null;
      return true;
    },
    ...mapActions({
      fetchRoles: 'Users/fetchListing',
    }),
    async get() {
      await this.fetchRoles({options:['id', true, 1, 20], search: this.searchText, only_trashed: this.only_trashed});
    },
    search() {
      if (!this.searchUser) {
        this.copyUsersList = this.usersList;
      } else {
        this.searchText = this.searchUser.toLowerCase();
        if(this.searchText.length >= 3)
          this.get();
        this.copyUsersList = this.usersList.filter((user) => {
          let last_name = false;
          let name = false;
          if (user.last_name) {
            last_name = user.last_name.toLowerCase().indexOf(this.searchUser.toLowerCase()) > -1;
          }
          if (user.name) {
            name =  user.name.toLowerCase().indexOf(this.searchUser.toLowerCase()) > -1;
          }

          return user.email.toLowerCase().indexOf(this.searchUser.toLowerCase()) > -1 || last_name || name;
        })
      }
      this.copyUsersList = this.copyUsersList.concat(this.promocodeUsersSearch);
    },
    async getPromocodeUsers() {
      if(this.$route.params.id)
      {
        this.usersSearch = this.promocodeUsers = (await axiosInstance.get(`/promocodes/${this.$route.params.id}/get-users`)).data.data;
        this.promocodeUsersInitial = [].concat(this.promocodeUsers.map(element => element.id));
      }
      if(this.promocodeUsers.length){
        let filter =
        {
          "filters": [
            {
              "field" : "id",
              "operator" : "in",
              "value" : this.promocodeUsers.map(element => element.id),
            },
          ],
        };

        if(this.searchPromocodeUsersValue)
          filter.search = {"value": this.searchPromocodeUsersValue};

          (await axiosInstance.post(`/users/search?limit=100`,  filter,
            ).then((res) => {
              this.copyUsersList = this.copyUsersList.concat(res.data.data);
              this.loadIndicator = false;
              this.uploadFileDisabled = false;
            })
            .catch((err) => {
              console.log(err);
              this.loadIndicator = false;
              this.uploadFileDisabled = false;
            }));
      }else{
        this.loadIndicator = false;
        this.uploadFileDisabled = false;
      }
      this.deleteBtnLoading = false;
    },
    formatDate(dateString){
      if(dateString){
        return moment(dateString).format('DD.MM.YYYY');
      }
    },
    filterformatDate(dateString){
      if(dateString){
        return moment(dateString).format('YYYY-MM-DD');
      }
    },
    dateFilter(){
      if(this.createdAtFilter){
        let dateFrom = this.filterformatDate(this.createdAtFilter[0]);
        let dateTo = this.createdAtFilter[1] ? this.filterformatDate(this.createdAtFilter[1]) : dateFrom;
        let self = this;
        return this.promocodeUsers.filter(function(element) {
          if(element.pivot.created_at){
            let date = self.filterformatDate(element.pivot.created_at);
            if(date >= dateFrom && date <= dateTo)
              return true;
          }else
            return false;
        })
      }
    },
    datePickerAction(){
      if(this.createdAtFilter.length > 1){
        if(this.createdAtFilter[1] < this.createdAtFilter[0]){
          let date = this.createdAtFilter[0];
          this.createdAtFilter[0] = this.createdAtFilter[1];
          this.createdAtFilter[1] = date;
        }
      }
      this.usersSearch = this.dateFilter();
    },
    async getUsersByEmails(emails) {

      if(emails.length){
        let filter =
        {
          "filters": [
            {
              "field" : "email",
              "operator" : "in",
              "value" : emails
            },
          ],
        };

        (await axiosInstance.post(`/users/search?limit=${emails.length}`,  filter,
          ).then((res) => {
            this.promocodeUsers = this.promocodeUsers.concat(res.data.data);
            alert(`Найдено ${res.data.data.length} из ${emails.length} пользователей`);
            if(res.data.data.length){
              let findedEmails = res.data.data.map(element => element.email);
              emails = emails.filter(x => !findedEmails.includes(Array.isArray(x) ? x[0] : x));
            }

            this.addUnregisteredUsers(emails);
            this.submitAddPromocodeUsers();
            this.getPromocodeUsers();
          })
          .catch((err) => {
            console.log(err);
          }));
      }else{
        this.uploadFileDisabled = false;
        this.loadIndicator = false;
        alert(`Не найдено емейлов в файле`);
      }
    },
    filterData(data){
      return data.filter(email => email.includes('@'));
    },
    uploadFile() {
      if (this.usersFile) {
        this.loadIndicator = true;
        this.uploadFileDisabled = true;
        const config = {headers:{'content-type':'multipart/form-data'}};
        let data = new FormData();
        data.append('file', this.usersFile);

        axiosInstance.post(
          `/promocodes/upload-file/${this.form.id}`,
          data,
          config
        ).then((res) => {
          this.uploadedPromocodeUsers = this.filterData(res.data);
          this.getUsersByEmails(this.uploadedPromocodeUsers);
          this.usersFile = null;
        })
        .catch((err) => {
          console.log(err);
          this.usersFile = null;
        });
      }
    },
    async checkFile(e) {
      const supported_mimes = [
        'text/csv',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ];
      const maxSize = 5242880;
      if(e.target.files.length == 0)
        return false;

      if(e.target.files[0]?.size > maxSize){
        alert('Файл должен быть размером не более 5МБ');
        this.usersFile = null;
        return false;
      }

      if (supported_mimes.includes(e.target.files[0]?.type)) {
        this.usersFile = e.target.files[0];
      } else {
        this.usersFile = null;
        alert('Файл должен иметь расширение csv или xls');
        return false;
      }
    },
    async deleteItems() {
      const itemsID = this.selected.map(element => element.id);
      let self = this;
      if(itemsID){
        this.deleteBtnLoading = true;
        await axiosInstance.post(`/promocodes/promocode/${this.form.id}/detach`,  {'users': itemsID})
          .then(() => {
            self.selected = [];
            self.getPromocodeUsers();
          })
          .catch((err) => {console.log(err)});
      }
    },
    async deleteLastAdded(){
      this.loadIndicator = true;
      this.removeUnregisteredUsers(this.lastUnregisteredUlpoaded);
      const itemsID = this.uploadedUsers.map(element => element.id);
      this.uploadedUsers = [];
      this.lastUnregisteredUlpoaded = [];
      let self = this;
      if(itemsID){
        this.deleteBtnLoading = true;
        await axiosInstance.post(`/promocodes/promocode/${this.form.id}/detach`,  {'users': itemsID})
          .then(() => {
            self.selected = [];
            self.getPromocodeUsers();
          })
          .catch((err) => {console.log(err)});
      }else{
        this.loadIndicator = false;
      }
    },
    promocodeUsersFilter(element){
      if(element.first_name){
        if(element.first_name.includes(this.searchPromocodeUsersValue))
          return true;
      }

      if(element.email){
        if(element.email.includes(this.searchPromocodeUsersValue))
          return true;
      }

      if(element.last_name){
        if(element.last_name.includes(this.searchPromocodeUsersValue))
          return true;
      }

      return false;
    },
    addUnregisteredUsers(emails){
      emails.forEach(element => {
        let email = Array.isArray(element) ? element[0]: element;
        if(email)
        {
          this.lastUnregisteredUlpoaded.push({'email': email});
          this.addUnregisteredUser(email);
        }
      });
    },
    addUnregisteredUser(email){
      if(this.form.emails){
        if(this.form.emails.indexOf(email) < 0)
          this.form.emails += email + ',';
      }else{
         this.form.emails = email + ',';
      }
    },
    removeUnregisteredUsersBtn(){
      this.removeUnregisteredUsers(this.unregisteredSelected);
    },
    removeUnregisteredUsers(users){
      users.forEach(element => {
        this.removeUnregisteredUser(element);
      });
    },
    removeUnregisteredUser(item){
      this.form.emails = this.form.emails.replaceAll(item.email+',','');
    },
    checkUnregisteredUsers(){
      let forDelete = [];
      let registeredUsers = this.promocodeUsers.map(element => element.email);
      this.unregisteredUsers.forEach(element => {
        if(registeredUsers.includes(element.email))
          forDelete.push(element);
      });
      this.removeUnregisteredUsers(forDelete);
    },
  },
  watch: {
    promocode: {
      handler(promocode) {
        if (promocode && Object.keys(promocode).length > 0) {
          this.form = promocode;
          this.oldCode = this.form.code;
          this.isSaveDisabled = false;
        }
      },
      immediate: true,
      deep: true,
    },
    usersList: {
      handler(val) {
        this.copyUsersList = val;
      }
    },
    searchPromocodeUsers: {
      handler(value) {
        this.searchPromocodeUsersValue = value;
        this.usersSearch = this.promocodeUsers.filter(this.promocodeUsersFilter);
      }
    },
    'form.emails': {
      handler(value) {
        if(value)
        {
          let values = [];
          let num = 0;
          value.split(',').forEach(element => {
            if(element){
              values.push({'id': num, 'email': element});
              num++;
            }
          });
          this.unregisteredUsers = values;
        }else{
          this.unregisteredUsers = [];
        }
      }
    },

  },
}
</script>
