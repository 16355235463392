var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',[_c('validation-provider',{attrs:{"name":"Название","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Название"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Slug","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.form.id ? true : false,"error-messages":errors,"label":"slug"},model:{value:(_vm.form.slug),callback:function ($$v) {_vm.$set(_vm.form, "slug", $$v)},expression:"form.slug"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Артикул"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Артикул"},model:{value:(_vm.form.sku),callback:function ($$v) {_vm.$set(_vm.form, "sku", $$v)},expression:"form.sku"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Цена"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Цена","error-messages":errors,"lable":"Цена"},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}})]}}],null,true)}),_c('v-textarea',{attrs:{"error-messages":_vm.errors,"label":"Краткое описание"},model:{value:(_vm.form.short_description),callback:function ($$v) {_vm.$set(_vm.form, "short_description", $$v)},expression:"form.short_description"}}),_c('v-text-field',{attrs:{"error-messages":_vm.errors,"label":"Ссылка на анонсное видео"},model:{value:(_vm.form.video_link),callback:function ($$v) {_vm.$set(_vm.form, "video_link", $$v)},expression:"form.video_link"}}),_c('v-text-field',{staticClass:"pb-4",attrs:{"error-messages":_vm.errors,"label":"Связанные мероприятия"},model:{value:(_vm.form.events_id),callback:function ($$v) {_vm.$set(_vm.form, "events_id", $$v)},expression:"form.events_id"}}),_c('v-row',[(_vm.form.dynamic_fields)?_c('v-col',[_c('FieldComponent',{attrs:{"title":"Дополнительные поля"},model:{value:(_vm.form.dynamic_fields),callback:function ($$v) {_vm.$set(_vm.form, "dynamic_fields", $$v)},expression:"form.dynamic_fields"}})],1):_vm._e()],1),(_vm.form.id)?_c('media-widget',{attrs:{"label":"Фото товара","medias":_vm.form.image,"is-multiple":false},on:{"onChange":_vm.setImage}}):_vm._e()],1),_c('media-uploader'),_c('v-btn',{attrs:{"loading":_vm.isLoading,"disabled":_vm.isLoading,"color":"primary"},on:{"click":function($event){return _vm.submit(true)}}},[_vm._v("Сохранить")]),_c('v-btn',{staticClass:"ml-2",attrs:{"loading":_vm.isLoading,"disabled":_vm.isLoading,"color":"primary"},on:{"click":function($event){return _vm.submit(false)}}},[_vm._v("Сохранить и продолжить")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }