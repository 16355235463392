import { Model } from "@tailflow/laravel-orion/lib/model";

export class Form extends Model<{
    component: string,
    title: string,
    structure: object,
}> {

    $resource(): string {
        return "module/form/admin/form";
    }
}
