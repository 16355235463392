<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <SiteComponent
              :site="site"
              @onSubmit="submit"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import SiteComponent from "../../../components/sites/SiteComponent";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Edit",
  components: {SiteComponent},
  data: () => ({
    page: {
      title: "Редактирование сайта"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Сайты",
        disabled: true,
        to: '/sites'
      },
      {
        text: "Редактирование",
        disabled: true,
        to: '/sites/edit'
      },
    ]
  }),
  computed: {
    ...mapGetters({
      "site": "Sites/getFetchedDetail",
    })
  },
  created() {
    this.fetch(this.$route.params.id);
  },
  methods: {
    ...mapActions({
      "fetch": "Sites/fetchDetail",
      "update": "Sites/updateItem",
    }),
    async submit(form) {
      this.update(form).then(() => {
        this.$router.push({name: 'site-index'});
      })
    },
  },
  watch: {
    'site.title': {
      handler(newTitle) {
        this.page.title +=  ` "${newTitle}"`;
      }
    },
  }
}
</script>

<style scoped>

</style>
