import {Model} from "@tailflow/laravel-orion/lib/model";
import axiosInstance from "../axiosInstance";
// @ts-ignore
import Cookies from "js-cookie";

export class Option extends Model <{
    option_key: string,
    option_value: string,
    updated_at: string,
}> {
    $resource(): string {
        return "options";
    }

    protected $keyName: string = 'option_key';

    static async reloadCache(){
        await axiosInstance.get('/options/reload').then(() => Cookies.remove('options'));
        window.location.reload();
    }
}
