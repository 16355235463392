<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <div v-if="isLoading">
          <div class="d-flex justify-center">
            <v-progress-circular indeterminate :size="50" color="primary"/>
          </div>
          <div class="d-flex justify-center mt-4">
            Загрузка....
          </div>
        </div>
        <div v-else>
          <div>ФИО Покупателя: {{ detailInformation.user_full_name }}</div>
          <div>Email Покупателя: {{ detailInformation.user_email }}</div>
          <div>Использованный промокод: <a v-if="detailInformation.promocode" :href="`/promocodes/edit/${detailInformation.promocode_id}`">{{ detailInformation.promocode }}</a><template v-else>-</template></div>
          <div>Стоимость заказа: {{ detailInformation.amount }}</div>
          <div>Продукты:</div>
          <ul>
            <li v-for="(product, index) in detailInformation.products" :key="index">
              Название: {{ product.name }}<br>
              Стоимость (на момент создания заказа): {{ product.amount }}
            </li>
          </ul>
          <div class="mt-2">Дополнительные данные:</div>
          <ul>
            <li v-if="detailInformation.webmaster_partners_data.partner_id">
              partner_id:  {{ detailInformation.webmaster_partners_data.partner_id }}<br>
            </li>
             <li v-if="detailInformation.webmaster_partners_data.from">
              from:  {{ detailInformation.webmaster_partners_data.from }}<br>
            </li>
            <li v-if="detailInformation.webmaster_partners_data.utm_source">
              utm_source:  {{ detailInformation.webmaster_partners_data.utm_source }}<br>
            </li>
            <li v-if="detailInformation.webmaster_partners_data.utm_content">
              utm_content:  {{ detailInformation.webmaster_partners_data.utm_content }}<br>
            </li>
            <li v-if="detailInformation.webmaster_partners_data.utm_medium">
              utm_medium:  {{ detailInformation.webmaster_partners_data.utm_medium }}<br>
            </li>
            <li v-if="detailInformation.webmaster_partners_data.utm_source">
              utm_source:  {{ detailInformation.webmaster_partners_data.utm_source }}<br>
            </li>
            <li v-if="detailInformation.webmaster_partners_data.utm_term">
              utm_term:  {{ detailInformation.webmaster_partners_data.utm_term }}<br>
            </li>
          </ul>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axiosInstance from "@/axiosInstance";

export default {
  name: "Detail",
  data: () => ({
    page: {
      title: "Заказы"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Ecommerce",
        disabled: true,
        to: '/'
      },
      {
        text: "Заказы",
        disabled: true,
        to: '/statisticsV2/media'
      },
    ],
    detailInformation: null,
    isLoading: true,
  }),
  created() {
    axiosInstance.get(`orders/${this.$route.params.id}/detail`).then((response) => {
      this.detailInformation = response.data;
    }).finally(() => {
      this.isLoading = false;
    })
  }
}
</script>

<style scoped>

</style>
