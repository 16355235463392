var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer"},[_c('ValidationProvider',{attrs:{"name":"Название","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Название"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Название"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Название"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Slug","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.slugDisabled,"label":"Slug"},model:{value:(_vm.form.slug),callback:function ($$v) {_vm.$set(_vm.form, "slug", $$v)},expression:"form.slug"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Регион","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"chips":"","items":_vm.regionList,"error-messages":errors,"item-value":"id","item-text":"title","label":"Регион"},model:{value:(_vm.form.region_id),callback:function ($$v) {_vm.$set(_vm.form, "region_id", $$v)},expression:"form.region_id"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Разработчики"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"multiple":"","chips":"","items":_vm.partnerList,"item-value":"id","item-text":"title","label":"Разработчики"},model:{value:(_vm.form.developers),callback:function ($$v) {_vm.$set(_vm.form, "developers", $$v)},expression:"form.developers"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Охват аудитории"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Охват аудитории"},model:{value:(_vm.form.audience_reach),callback:function ($$v) {_vm.$set(_vm.form, "audience_reach", $$v)},expression:"form.audience_reach"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Год награждения"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Год награждения"},model:{value:(_vm.form.year_of_awarding),callback:function ($$v) {_vm.$set(_vm.form, "year_of_awarding", $$v)},expression:"form.year_of_awarding"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Численность охвата"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Численность охвата"},model:{value:(_vm.form.number_of_coverage),callback:function ($$v) {_vm.$set(_vm.form, "number_of_coverage", $$v)},expression:"form.number_of_coverage"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Дополнение"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Дополнение"},model:{value:(_vm.form.addition),callback:function ($$v) {_vm.$set(_vm.form, "addition", $$v)},expression:"form.addition"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Год разработки"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Год разработки"},model:{value:(_vm.form.year_of_dev),callback:function ($$v) {_vm.$set(_vm.form, "year_of_dev", $$v)},expression:"form.year_of_dev"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Краткое описание"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Краткое описание"},model:{value:(_vm.form.short_description),callback:function ($$v) {_vm.$set(_vm.form, "short_description", $$v)},expression:"form.short_description"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Достигнутые результаты"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Достигнутые результаты"},model:{value:(_vm.form.reached_results),callback:function ($$v) {_vm.$set(_vm.form, "reached_results", $$v)},expression:"form.reached_results"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Ожидаемые результаты"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Ожидаемые результаты"},model:{value:(_vm.form.expected_results),callback:function ($$v) {_vm.$set(_vm.form, "expected_results", $$v)},expression:"form.expected_results"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Возможности и инструменты тиражирования"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Возможности и инструменты тиражирования"},model:{value:(_vm.form.replication_tools),callback:function ($$v) {_vm.$set(_vm.form, "replication_tools", $$v)},expression:"form.replication_tools"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Ссылка практики"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Ссылка практики"},model:{value:(_vm.form.url_practices),callback:function ($$v) {_vm.$set(_vm.form, "url_practices", $$v)},expression:"form.url_practices"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Социальные сети"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Социальные сети"},model:{value:(_vm.form.social_url),callback:function ($$v) {_vm.$set(_vm.form, "social_url", $$v)},expression:"form.social_url"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Региональный центр"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.partnerList,"chips":"","item-value":"id","item-text":"title","label":"Региональный центр"},model:{value:(_vm.form.regional_center_id),callback:function ($$v) {_vm.$set(_vm.form, "regional_center_id", $$v)},expression:"form.regional_center_id"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Контактное лицо"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.personList,"chips":"","item-value":"id","item-text":"title","label":"Контактное лицо"},model:{value:(_vm.form.contact_person),callback:function ($$v) {_vm.$set(_vm.form, "contact_person", $$v)},expression:"form.contact_person"}})]}}])}),_c('v-checkbox',{attrs:{"label":"Является лучшей практикой"},model:{value:(_vm.form.is_the_best),callback:function ($$v) {_vm.$set(_vm.form, "is_the_best", $$v)},expression:"form.is_the_best"}}),_c('v-btn',{attrs:{"loading":_vm.isLoading,"disabled":_vm.isLoading,"color":"primary"},on:{"click":function($event){return _vm.submit(true)}}},[_vm._v("Сохранить")]),_c('v-btn',{attrs:{"loading":_vm.isLoading,"disabled":_vm.isLoading,"color":"primary"},on:{"click":_vm.submit}},[_vm._v("Сохранить и продолжить")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }