<template>
  <v-form>
    <v-row>
      <v-col>
        <v-card>
          <v-container>
            <v-text-field
              v-model="form.smm_title"
              :error-messages="errors.smm_title"
              label="SMM title"
              name="smm_title"
              required
            ></v-text-field>
            <v-text-field
              v-model="form.smm_description"
              :error-messages="errors.smm_description"
              label="SMM description"
              name="smm_description"
              required
            ></v-text-field>
            <media-widget
              class="mb-1"
              label="SMM изображение"
              :medias="form.smm_image"
              :is-multiple="false"
              @onChange="setSmmImage"
            />
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-container>
            <short-link-widget :entity="entity"/>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!hideButton">
      <v-col>
        <div class="smm-preview">
          <h3>Предпросмотр</h3>
          <h4 class="search-system-title"><span>ВКонтакте</span></h4>
          <vk-smm-widget :entity="entity" :form="form" />
          <h4 class="search-system-title"><span>Facebook</span></h4>
          <facebook-smm-widget :entity="entity" :form="form" />
        </div>
        <v-flex>
          <v-btn
            class="mr-4"
            color="primary"
            @click="submit"
            :disabled="isSaveDisabled || isDisabled"
            :loading="isSaveDisabled"
          >
            Сохранить
          </v-btn>
        </v-flex>
      </v-col>
    </v-row>
    <media-uploader
      :loaded-medias="entity.loaded_medias"
      @completeUploads="onCompleteUploads"
    />
  </v-form>
</template>

<script>
import MediaUploader from './MediaUploader';
import MediaWidget from './MediaWidget';
import VkSmmWidget from './VkSmmWidget.vue';
import FacebookSmmWidget from './FacebookSmmWidget.vue';
import ShortLinkWidget from './ShortLinksWidget.vue';

export default {
  name: 'SMMForm',
  components: {
    MediaWidget,
    MediaUploader,
    VkSmmWidget,
    FacebookSmmWidget,
    ShortLinkWidget
  },
  props: {
    entity: {
      type: Object,
      default: () => ({})
    },
    media: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Object,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    hideButton: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    form: {
      id: null,
      smm_title: null,
      smm_description: null,
      smm_image: null,
    },
    formErrors: {},
    isSaveDisabled: false,
  }),
  created() {
    if (this.$route.params.data) {
      this.form = JSON.parse(this.$route.params.data);
    }
  },
  methods: {
    async submit() {
      this.isSaveDisabled = true;
      this.$emit('onSubmit', {
        form: this.form,
        mediaIds: this.mediaIds,
      });
    },
    setSmmImage(image) {
      this.form.smm_image = image;
    },
    async onCompleteUploads(ids) {
      this.mediaIds = ids;
      await this.submit();
      this.mediaIds = [];
    },
  },
  watch: {
    entity: {
      handler(entity) {
        if (entity && Object.keys(entity).length > 0) {
          this.form = entity;
          this.isSaveDisabled = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  .smm-preview {
    margin-left: 15px;
    margin-right: 15px;
  }

  h4.search-system-title {
    font-size: 14px;
    letter-spacing: 0.015em;
    color: #A3B3CA;
    font-family: "adelle-sans", sans-serif;
    position: relative;

    span {
      position: relative;
      background: #fff;
      padding-right: 12px;
    }
  }

  h4.search-system-title:before {
    content: '';
    position: absolute;
    top: 10px;
    right: 0;
    width: 100%;
    height: 1px;
    background: #E0E7F1;
  }
</style>
