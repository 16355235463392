<template>
  <v-form>
    <v-row>
      <v-col>
        <v-btn
          color="primary"
          class="mt-2"
          @click="submit(false)"
          :disabled="isSaveDisabled || isDisabled"
          :loading="isSaveDisabled"
        >
          Сохранить и продолжить
        </v-btn>
        <v-btn
          color="primary"
          class="ml-2 mt-2"
          @click="submit(true)"
          :disabled="isSaveDisabled || isDisabled"
          :loading="isSaveDisabled"
        >
          Сохранить
        </v-btn>
      </v-col>
    </v-row>
    <v-stepper
      ref="stepper"
      v-model="step"
      class="mt-2 mb-2"
    >
      <v-stepper-header>
        <template v-for="(collection, index) in collections">
          <v-stepper-step
            :key="`${index+1}-step`"
            :step="index+1"
            editable
          >
            {{ collection.title }}
          </v-stepper-step>

          <v-divider
            v-if="index+1 !== collections.length"
            :key="index"
          ></v-divider>
        </template>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content
          v-for="(collection, index) in searchable_collection"
          :key="`${index+1}-content`"
          :step="index+1"
        >
          <div
            v-if="!collection.entity_type"
            >
            <v-card
            elevation="3"
            outlined
          >
            <v-card-title>
              Основные
            </v-card-title>
            <v-row>
              <v-col>
                <v-container>
                  <h4>Родительский элемент таксономии "{{ collection.title }}"</h4>
                  <v-text-field label="поиск" @input="(event) => mainParentSearch(event, collection.id)"/>
                  <v-radio-group
                    v-if="rerenderMain"
                    :value="form.mainParent[collection.id]"
                  >
                    <v-radio
                      v-for="(item, index) in collection.items"
                      :key="index"
                      :label="item.title"
                      :value="item.id"
                      @click="updateParentItem(collection.id, item.id)"
                    ></v-radio>
                  </v-radio-group>
                </v-container>
              </v-col>
              <v-col>
                <v-container>
                  <h4>Дочерний элемент таксономии "{{ collection.title }}"</h4>
                  <v-text-field label="Поиск" @input="(event) => childrenItemsSearch(event, collection.id)"/>
                  <v-radio-group
                    v-if="rerenderMainChild"
                    :value="form.mainChildren[collection.id]"
                  >
                    <v-radio
                      v-for="(item, index) in searchable_childrenItems[collection.id]"
                      :key="index"
                      :label="item.title"
                      :value="item.id"
                      :class="{ 'v-item--active' : item.id === form.mainChildren[collection.id] }"
                      @click="submitMainChildren(collection.id, item.id)"
                    ></v-radio>
                  </v-radio-group>
                </v-container>
              </v-col>
            </v-row>
          </v-card>
          <v-card
            elevation="3"
            outlined
            class="mt-2"
          >
            <v-card-title>
              Дополнительные
            </v-card-title>
            <tabs-with-checkboxes
              v-model="form.selected[collection.id]"
              :options="collection.items"
              value-field="id"
              text-field="title"
              :main-items="mainItemsIds"
            />
          </v-card>
      </div>
      <div
            v-if="collection.entity_type == 'Courses'"
            >
          <v-card
            elevation="3"
            outlined
            class="mt-2"
          >
            <v-card-title>
              Курсы
            </v-card-title>
            <tabs-with-checkboxes
              v-model="form.selected_courses"
              :options="courses"
              value-field="id"
              text-field="title"
              :main-items="[]"
              @searchAction="searchCourses"
            />
          </v-card>
      </div>
      <div
            v-if="collection.entity_type == 'Persons'"
            >
          <v-card
            elevation="3"
            outlined
            class="mt-2"
          >
            <v-card-title>
              Персоны
            </v-card-title>
            <tabs-with-checkboxes
              v-model="form.selected_persons"
              :options="persons"
              value-field="id"
              text-field="full_name"
              :main-items="[]"
              @searchAction="searchPersons"
            />
          </v-card>
      </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-row>
      <v-col>
        <v-btn
          color="primary"
          class="mb-2"
          @click="submit(false)"
          :disabled="isSaveDisabled || isDisabled"
          :loading="isSaveDisabled"
        >
          Сохранить и продолжить
        </v-btn>
        <v-btn
          color="primary"
          class="ml-2 mb-2"
          @click="submit(true)"
          :disabled="isSaveDisabled || isDisabled"
          :loading="isSaveDisabled"
          >
          Сохранить
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import {Collection} from "../../models/collection";
import {Course} from "../../models/course";
import {Person} from "../../models/person";
import TabsWithCheckboxes from '../ui/TabsWithCheckboxes.vue';
import {findIndex} from 'lodash';
import Vue from "vue";

export default {
  name: "CollectionRelationForm",
  components: {TabsWithCheckboxes},
  props: {
    moduleName: {
      type: String,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
      default: () => ({})
    },
    selectedCollectionItems: {
      type: Array,
      default: () => ([]),
    },
    selectedCourses: {
      type: Array,
      default: () => ([]),
    },
    selectedPersons: {
      type: Array,
      default: () => ([]),
    },
    itemAction: {
      type: String,
      default: null,
    },
    mainItems: {
      type: Array,
      default: () => ([]),
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    form: {
      selected: {},
      mainParent: {},
      mainChildren: {},
    },
    search: {
      mainParent: ''
    },
    rerenderMainChild: true,
    rerenderMain: true,
    step: 1,
    collections: [],
    searchable_collection: [],
    searchable_childrenItems: {},
    mainItemsIds: [],
    childrenItems: {},
    isSaveDisabled: false,
    courses: [],
    searchable_courses: [],
    persons: [],
    searchable_persons: [],
  }),
  mounted() {
    this.getCollections().then(async () => {
      await this.updateChildrenItems();
      await this.$refs.stepper.updateView();
    });
    this.getCourses();
    this.getPersons();
  },
  methods: {
    submit(isNeedRedirect) {
      this.isSaveDisabled = true;
      const items = Object.values(this.form.selected).reduce((acc, arr) => acc.concat(arr), []);
      this.$emit('onSubmitCourses', { items: this.form.selected_courses, isNeedRedirect: false });
      this.$emit('onSubmitPersons', { items: this.form.selected_persons, isNeedRedirect: false });
      this.$emit('onSubmit', { items: items, isNeedRedirect: isNeedRedirect });
    },
    submitMainChildren(index, val) {
      Vue.set(this.form.mainChildren, index, val);
      this.submitMain();
    },
    mainParentSearch(event, index) {
      const currCollection = this.collections.find((item) => item.id === index);
      const indexColl = this.collections.indexOf(currCollection);

      if (currCollection && (indexColl + 1) && event !== '') {
        const items = currCollection.items.filter((item) => {
          return (item.title.toLowerCase().indexOf(event.toLowerCase()) + 1);
        })

        Vue.set(this.searchable_collection[indexColl], 'items', items);
      } else {
        Vue.set(this.searchable_collection[indexColl], 'items', currCollection.items);
      }

      this.rerenderMain = false;
      this.$nextTick(() => {
        this.rerenderMain = true;
      })
    },
    childrenItemsSearch(event, index) {
      if (event !== '') {
        let items = JSON.parse(JSON.stringify(this.childrenItems[index]));

        items = items.filter((item) => {
          return (item.title.toLowerCase().indexOf(event.toLowerCase()) + 1);
        });

        Vue.set(this.searchable_childrenItems, index, items);
      } else {
        Vue.set(this.searchable_childrenItems, index, this.childrenItems[index]);
      }

      this.rerenderMainChild = false;
      this.$nextTick(() => {
        this.rerenderMainChild = true;
      })
    },
    submitMain() {
      let mainItems = {};

      Object.keys(this.form.mainParent).forEach((key) => {
        let res = [this.form.mainParent[key]];

        if (this.form.mainChildren[key]) {
          res.push(this.form.mainChildren[key]);
        }

        mainItems[key] = res;
      })

      this.$emit('onSubmitMain', mainItems);
    },
    async getCollections() {
      try {
        const collections = await Collection.$query()
          .sortBy('is_main', 'desc')
          .scope('whereModule', [this.moduleName])
          .search();
        this.collections = collections.map(collection => collection.$attributes);
        this.searchable_collection = JSON.parse(JSON.stringify(this.collections));
      } catch (e) {
        throw e;
      }
    },
    async getCourses(searchText = '') {
      try {
        const courses = await Course.$query().lookFor(searchText).search();
        this.courses = courses.map(courses => courses.$attributes);
        this.searchable_courses = JSON.parse(JSON.stringify(this.courses));
      } catch (e) {
        throw e;
      }
    },
    async getPersons(searchText = '') {
      try {
        const persons = await Person.$query().lookFor(searchText).search();
        this.persons = persons.map(courses => courses.$attributes);
        this.searchable_persons = JSON.parse(JSON.stringify(this.persons));
        console.log(this.persons);
      } catch (e) {
        throw e;
      }
    },
    nextStep(step) {
      if (step === this.collections.length) {
        this.step = 1
      } else {
        this.step = step + 1
      }

      this.submit();
    },
    updateParentItem(collectionId, val) {

      let collection = this.collections.filter((element) => {return element.id == collectionId});
      if(collection[0].title == "Предметы"){
        alert('Изменён предмет курса, рекомендуется пересчитать стоимость');
      }

      Vue.set(this.form.mainParent, collectionId, val);
      this.form.mainChildren[collectionId] = null;
      this.submitMain();
    },
    updateChildrenItems() {
      this.collections.forEach((item) => {
        if (this.form.mainParent[item.id]) {
          this.childrenItems[item.id] = item.items[findIndex(item.items, {id: this.form.mainParent[item.id]})].children;
          this.searchable_childrenItems = JSON.parse(JSON.stringify(this.childrenItems));
        } else {
          this.childrenItems[item.id] = [];
          this.searchable_childrenItems = JSON.parse(JSON.stringify(this.childrenItems));
        }
      })
    },
    searchCourses(value){
      this.getCourses(value);
    },
    searchPersons(value){
      this.getPersons(value);
    },
  },
  watch: {
    mainItems: {
      handler() {
        this.form = {
          selected: {},
          selected_courses: {},
          selected_persons: {},
          mainParent: {},
          mainChildren: {},
        };
        this.mainItemsIds = [];

        Object.keys(this.mainItems).forEach((collectionId) => {
          this.form.mainParent[collectionId] = this.mainItems[collectionId]['parent'];
          this.form.mainChildren[collectionId] = this.mainItems[collectionId]['child'];
          this.mainItemsIds = [...this.mainItemsIds, ...Object.values(this.mainItems[collectionId])];
        })
        this.updateChildrenItems();
        this.isSaveDisabled = false;
      },
      immediate: true,
      deep: true,
    },
    selectedCollectionItems: {
      handler() {
        this.form.selected = this.selectedCollectionItems;
        this.isSaveDisabled = false;
      },
      immediate: true,
    },
    selectedCourses: {
      handler() {
        this.form.selected_courses = this.selectedCourses;
        this.isSaveDisabled = false;
      },
      immediate: true,
    },
    selectedPersons: {
      handler() {
        this.form.selected_persons = this.selectedPersons;
        this.isSaveDisabled = false;
      },
      immediate: true,
    },
    form: {
      handler() {
        this.updateChildrenItems();
        this.isSaveDisabled = false;
      },
      deep: true,
    },
  }
}
</script>
