import { Model } from "@tailflow/laravel-orion/lib/model";

export class Subscriber extends Model<{
    email: string,
}> {

    $resource(): string {
        return "subscribers";
    }
}
