var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"down-top-padding",attrs:{"fluid":""}},[_c('BaseBreadcrumb',{attrs:{"title":_vm.page.title,"icon":_vm.page.icon,"breadcrumbs":_vm.breadcrumbs}}),_c('v-card',[_c('v-card-text',{staticClass:"pa-5"},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Поиск...","filled":"","background-color":"transparent","hide-details":"","name":"title"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[(_vm.search)?_c('v-btn',{staticClass:"reset-button",attrs:{"slot":"append","icon":"","color":"red"},on:{"click":function($event){_vm.search = null}},slot:"append"},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1)],1),_c('v-col',[_c('v-switch',{attrs:{"label":"Показать удаленные","name":"only_trashed","value":"1"},model:{value:(_vm.only_trashed),callback:function ($$v) {_vm.only_trashed=$$v},expression:"only_trashed"}})],1),_c('v-col',{staticClass:"justify-end align-center d-flex",attrs:{"cols":"12","lg":"4"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{staticClass:"mb-4",attrs:{"color":"primary","to":"items/create"}},[_vm._v(" Добавить элемент каталога ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}),_c('v-btn',{staticClass:"mb-4 ml-2",attrs:{"color":"primary","to":"dynamic-fields"}},[_vm._v(" Настройка динамических полей ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-4 ml-2",attrs:{"color":"primary","icon":"","to":"items/table"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-table")])],1)]}}])},[_c('span',[_vm._v("Редактирование в режиме таблицы")])])],1)],1),(_vm.fetchedCatalog.pattern_listing_url && _vm.fetchedCatalog.pattern_detail_url)?_c('v-row',[_c('v-col',[_c('div',{staticClass:"text text-center"},[_c('h3',[_vm._v(" Шаблон списочной странцы "),_c('a',{attrs:{"target":"_blank","href":_vm.fetchedCatalog.pattern_listing_url}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-eye ")])],1),_c('a',{attrs:{"target":"_blank","href":((_vm.fetchedCatalog.pattern_listing_url) + "?edit_mode=true")}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-pen ")])],1),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":_vm.regenerate}},[_c('v-icon',[_vm._v(" mdi-backup-restore ")])],1)],1)])]),_c('v-col',[_c('div',{staticClass:"text text-center"},[_c('h3',[_vm._v(" Шаблон детальной странцы "),_c('a',{attrs:{"target":"_blank","href":_vm.fetchedCatalog.pattern_detail_url}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-eye ")])],1),_c('a',{attrs:{"target":"_blank","href":((_vm.fetchedCatalog.pattern_detail_url) + "?edit_mode=true")}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-pen ")])],1)])])])],1):_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":_vm.generatePatterns}},[_vm._v("Сгенерировать шаблоны страниц")])],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"options":_vm.options,"loading":_vm.isLoading,"loading-text":"Загрузка...","items":_vm.fetchedData,"server-items-length":_vm.totalItems,"footer-props":{'items-per-page-options':[5, 10, 15, 20, -1]}},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"pill":""}},[_vm._v(_vm._s(item.id))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","to":("/catalogs/" + _vm.catalogId + "/items/" + (item.id) + "/edit")}},[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v(" mdi-pencil ")])],1),_c('a',{attrs:{"target":"_blank","href":item.preview_url}},[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v(" mdi-eye ")])],1),(!item.deleted_at)?_c('v-icon',{attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.deleteItem(item, 0)}}},[_vm._v(" mdi-delete ")]):_c('v-icon',{attrs:{"color":"green","small":""},on:{"click":function($event){return _vm.restoreItem(item)}}},[_vm._v(" mdi-backup-restore ")]),_c('v-icon',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.regenerateItem(item.id)}}},[_vm._v(" mdi-backup-restore ")])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }