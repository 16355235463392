<template>
  <v-row v-if="Object.keys(getPhrasesAndImages).length > 0">
    <v-col
      cols="12"
      lg="7"
      xl="6"
      class="d-none d-md-flex align-center justify-center"
      :style="{ 'background-image': 'url(' + getPhrasesAndImages.background_images.large + ')' }"
    >
      <v-container>
        <div class="pa-10">
          <v-row justify="center">
            <v-col cols="8" xl="5">
              <div>
                <h2
                  v-if="getPhrasesAndImages.phrases"
                  class="display-1 white--text font-weight-medium"
                >{{ getPhrasesAndImages.phrases.title }}</h2>
                <h6
                  v-if="getPhrasesAndImages.phrases"
                  class="subtitle-1 mt-4 white--text op-5 font-weight-regular"
                >{{ getPhrasesAndImages.phrases.subtitle }}</h6>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
    <v-col cols="12" lg="5" xl="6" class="d-flex align-center">
      <v-container>
        <div class="pa-7 pa-sm-12">
          <v-row>
            <v-col cols="12" lg="9" xl="6">
              <img
                v-if="getOption('site_logo', '')"
                :src="getOption('site_logo', '')"
                :alt="getOption('siteName', '')"
                class="logo"
              />
              <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">Войти</h2>
<!--              <h6 class="subtitle-1">-->
<!--                Нет аккаунта?-->
<!--                <a href="#/pages/fullregister" class>Создать</a>-->
<!--              </h6>-->

              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="form.email"
                  label="Введите email"
                  :error-messages="errors.email"
                  name="email"
                  class="mt-4"
                  required
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="form.password"
                  :counter="20"
                  min="8"
                  name="email"
                  :error-messages="errors.password"
                  label="Введите пароль"
                  required
                  outlined
                  :append-icon="viewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => viewPassword = !viewPassword"
                  :type="viewPassword ? 'text' : 'password'"
                ></v-text-field>

                <div class="d-block d-sm-flex align-center mb-4 mb-sm-0">
<!--                  <v-checkbox-->
<!--                    v-model="checkbox"-->
<!--                    :rules="[v => !!v || 'You must agree to continue!']"-->
<!--                    label="Remember me?"-->
<!--                    required-->
<!--                  ></v-checkbox>-->
                  <div class="ml-auto">
                    <router-link :to="`/auth/email?email=${form.email}`" class="link">Забыли пароль?</router-link>
                  </div>
                </div>
                <v-btn
                  :disabled="!valid"
                  color="info"
                  block
                  class="mr-4"
                  submit
                  @click="submit"
                >Войти</v-btn>
              </v-form>
<!--              <div class="text-center mt-6">-->
<!--                <v-chip pill class="mr-2">-->
<!--                  <v-avatar left>-->
<!--                    <v-btn color="blue lighten-1" class="white&#45;&#45;text">-->
<!--                      <v-icon>mdi-twitter</v-icon>-->
<!--                    </v-btn>-->
<!--                  </v-avatar>Twitter-->
<!--                </v-chip>-->
<!--                <v-chip pill>-->
<!--                  <v-avatar left>-->
<!--                    <v-btn color="teal lighten-2" class="white&#45;&#45;text">-->
<!--                      <v-icon>mdi-github</v-icon>-->
<!--                    </v-btn>-->
<!--                  </v-avatar>Github-->
<!--                </v-chip>-->
<!--              </div>-->
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'Login',
  data() {
    return {
      form: {
        email: null,
        password: null,
      },
      errors: {},
      viewPassword: false,
      show1: false,
    }
  },
  async created() {
    await this.checkToken();
  },
  computed: {
    ...mapGetters({
      getToken: 'auth/getToken',
      getOption: 'options/getOption',
      getPhrasesAndImages: 'Sites/getPhrasesAndImages',
    })
  },
  methods: {
    ...mapActions({
      login: 'auth/login',
      getUser: 'auth/getUser',
      setToken: 'auth/setToken',
    }),
    async submit() {
      try {
        this.errors = {};
        await this.login(this.form);
        await this.getUser();
        await this.$router.push('/')
      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors
        }
        throw e;
      }
    },
    makeProviderUrl(provider) {
      const redirectUri = process.env.VUE_APP_URL + this.$route.path;

      return `${process.env.VUE_APP_API_URL}/oauth/${provider}?redirect_uri=${encodeURIComponent(redirectUri)}`;
    },
    async checkToken() {
      if (this.$route.query && this.$route.query.token) {
        try {
          await this.setToken(this.$route.query.token);
          await this.getUser();
          await this.$router.push('/');
        } catch (e) {
          if (e.response?.data) {
            this.errors = e.response.data.errors
          }
          throw e;
        }
      }
    },
  },
}
</script>

<style scoper>
  img.logo {
    max-width: 100%;
  }
</style>
