<template>
  <div>
    <div class="mb-5">
      <v-btn color="primary" href="/temp/region-statistic">Статистика</v-btn>
      <v-btn color="primary" href="/temp/region-monitoring">Мониторинги</v-btn>
      <v-btn color="primary" href="/temp/region-olympic">Олимпиады</v-btn>
      <v-btn color="primary" href="/temp/region-buget" disabled>Инициативное бюджетирование</v-btn>
      <v-btn color="primary" href="/temp/region-export" disabled>Данные о регионах</v-btn>
    </div>
    <div v-if="regions">
      <div>
        <v-btn color="primary" @click="onCreate">Добавить</v-btn>
        <v-btn color="error" @click="onDelete">Удалить</v-btn>
        <v-btn color="green" @click="onSave">Сохранить</v-btn>
      </div>
      <vue-excel-editor ref="grid" v-model="tempModel">
        <vue-excel-column field="field_1" label="Субъект (2.field_1)" type="map" :options="regions.title"/>
        <vue-excel-column field="field_2" label="ФО (2.field_2)" type="select" :options="regions.district"/>
        <vue-excel-column field="field_3" label="Кол-во реализованных проектов инициативного бюджетирования, шт.(2017) (4.field_3)"/>
        <vue-excel-column field="field_4" label="Кол-во реализованных проектов инициативного бюджетирования, шт.(2018) (4.field_4)"/>
        <vue-excel-column field="field_5" label="Кол-во реализованных проектов инициативного бюджетирования, шт.(2019) (4.field_5)"/>
        <vue-excel-column field="field_6" label="Кол-во реализованных проектов инициативного бюджетирования, шт.(2020) (4.field_6)"/>
        <vue-excel-column field="field_7" label="Кол-во реализованных проектов инициативного бюджетирования, шт.(2021) (4.field_7)"/>
        <vue-excel-column field="field_8" label="Общая стоимость реализованных проектов инициативного бюджетирования, млн руб.(2017) (4.field_8)"/>
        <vue-excel-column field="field_9" label="Общая стоимость реализованных проектов инициативного бюджетирования, млн руб.(2018) (4.field_9)"/>
        <vue-excel-column field="field_10" label="Общая стоимость реализованных проектов инициативного бюджетирования, млн руб.(2019) (4.field_10)"/>
        <vue-excel-column field="field_11" label="Общая стоимость реализованных проектов инициативного бюджетирования, млн руб.(2020) (4.field_11)"/>
        <vue-excel-column field="field_12" label="Общая стоимость реализованных проектов инициативного бюджетирования, млн руб.(2021) (4.field_12)"/>

        <vue-excel-column field="field_13" label="Победители конкурса ИБ (ФОТО) (4.field_13)"/>
        <vue-excel-column field="field_14" label="Победители конкурса ИБ (год) (4.field_14)"/>
               <vue-excel-column field="field_15" label="Победители конкурса ИБ (номинация)  (4.field_15)"/>
        <vue-excel-column field="field_16" label="Победители конкурса ИБ (название)  (4.field_16)"/>
  <vue-excel-column field="field_17" label="Победители конкурса ИБ (ссылка)  (4.field_17)"/> 
        

      </vue-excel-editor>
    </div>
  </div>
</template>

<script>
import axiosInstance from "../../../axiosInstance";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "RegionBuget",
  data: () => ({
    regions: null,
  }),
  created() {
    axiosInstance.get('temp-data/regions').then((resp) => {
      this.regions = resp.data;
      this.fetchTempData(4);
    });
  },
  computed: {
    ...mapGetters({
      tempData: "TempData/getTempData",
    }),
    tempModel: {
      set(val) {
        this.setTempData(val);
      },
      get() {
        return this.tempData;
      }
    }
  },
  methods: {
    ...mapActions({
      fetchTempData: "TempData/fetchTempData",
      saveTempData: "TempData/saveTempData",
    }),
    ...mapMutations({
      setTempData: "TempData/setTempData",
    }),
    onCreate(){
      this.$refs.grid.newRecord();
    },
    onDelete(){
      this.$refs.grid.deleteRecord(0);
    },
    onSave() {
      this.saveTempData(4);
    }
  }
}
</script>

<style scoped>

</style>
