<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row>
      <v-col>
        <h1>Создание медиа</h1>
      </v-col>
    </v-row>
    <v-row>
      <vue2-dropzone
        id="media-upload-dropzone"
        ref="dropzone"
        :options="dropzoneOptions"
        @vdropzone-error="onUploadError"
        @vdropzone-success="onUploadSuccess"
        @vdropzone-files-added="onFilesAdded"
      />
    </v-row>
    <v-row>
      <div v-if="isError">
        <span class="text-danger">
          При загрузке произошла ошибка, попробуйте загрузить другое изображение
        </span>
      </div>
      <div v-if="isLoading">
        <v-progress-circular
          :width="3"
          color="grey"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import {mapActions} from "vuex";

export default {
  name: 'Create',
  components: {vue2Dropzone},
  data: () => ({
    isShow: false,
    isLoading: false,
    isError: false,
    medias: [],
    dropzoneOptions: {
      url: `${process.env.VUE_APP_API_URL}/api/media`,
      createImageThumbnails: false,
      uploadMultiple: false,
      paramName: 'files',
      maxFilesize: 100,
      acceptedFiles: '.jpg,.jpeg,.png,.gif,.doc,.docx,.txt,,.pdf,.csv,.xlsx,.xlsm,.xlsb,.xltx,.xltm,.xls,.xlt,.xls,.ppt,.pptx,' +
                     '.mpg,.avi,.mov,.afs,.wmv,.mp4,.3GP,.flv,.ico,.svg,.zip,.rar,.gzip',
      dictDefaultMessage: 'Перетащите файл для загрузки. Максимальный размер 100mb',
    },
    loadedFiles: 0,
    confirmDialog: false,
    page: {
      title: "Медиа библиотека"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Медиа библиотека",
        disabled: true,
        to: '/medias'
      },
      {
        text: "Создание",
        disabled: true,
        to: '/medias/create'
      },
    ],
  }),
  created() {
    this.dropzoneOptions.headers = {
      Authorization: `Bearer ${this.$store.getters['auth/getToken']}`
    }
  },
  methods: {
    ...mapActions({
      showSnackbar: "snackbar/showSnackbar"
    }),
    onFilesAdded(files) {
      if (!this.isError) {
        this.isLoading = true;
      }
      this.loadedFiles = this.medias.length + files.length;
    },
    onUploadSuccess(file, response) {
      if (response.id) {
        this.$router.push({name: 'medias-edit', params: {id: response.id}});
      } else {
        response.forEach((r) => {
          this.isError = false;
          const index = this.medias.findIndex(({id}) => id === r.id);
          if (index >= 0) {
            this.medias[index] = r;
          } else {
            this.isLoading = false;
            this.medias.push(r);
          }
        })
      }
    },
    onUploadError(file) {
      this.isError = !file.accepted;
      this.showSnackbar({ color: 'red', text: "Файл слишком велик"});
    },
  },
};
</script>

<style lang="scss" scoped>
.v-progress-circular {
  margin: 5rem;
}

.media-uploader {
  height: 100vh;
  width: 300px;
  position: fixed;
  top: 0;
  right: 0;
  background: white;
  z-index: 999;
  transition: right .25s ease-in-out;

  &__body {
    height: 100%;
    padding: 30px;
    overflow: auto;
  }

  &__label {
    display: block;
    position: absolute;
    left: -24px;
    top: 20%;
    background: #b6b6f6;
    writing-mode: vertical-rl;
    padding: 10px 0;
    color: white;
    cursor: pointer;
    user-select: none;
  }

  &__images {
    margin-top: 20px;
  }

  &__image {
    position: relative;
    max-width: 100%;
    margin-bottom: 10px;

    img {
      width: 100%;
    }
  }

  &__actions {
    position: absolute;
    width: calc(100% - 10px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    bottom: 15px;

    button, i {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      text-shadow: 0 0 5px black;
      color: #ff5252;
      transition: color ease-in-out .3s;

      &:hover {
        color: white;
        transition: color ease-in-out .3s;
        cursor: pointer;
      }
    }
  }
}
</style>

<style>
.dropzone .dz-preview {
  display: none;
}

.dropzone.dz-started .dz-message {
  display: block !important;
}
</style>
