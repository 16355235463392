import Index from '../../views/main/organizers/Index'
import Create from '../../views/main/organizers/Create'
import Edit from '../../views/main/organizers/Edit';

export default [
  {
    component: Index,
    name: 'organizers-index',
    path: '/organizers',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module:'organizers',
    }
  },
  {
    component: Create,
    name: 'organizers-create',
    path: '/organizers/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module:'organizers',
    }
  },
  {
    component: Edit,
    name: 'organizers-edit',
    path: '/organizers/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module:'organizers',
    }
  },
]
