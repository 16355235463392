<template>
  <div
    class="media-uploader"
    :style="isShow ? 'right: 0' : 'right: -500px'"
  >
    <div class="media-uploader__body">
      <div
        class="media-uploader__label"
        @click="isShow = !isShow"
      >
        Медиа библиотека
      </div>
      <v-btn
        color="primary"
        :href="'/medias/create'"
        target="_blank"
      >
        Загрузить изображение
      </v-btn>
      <v-text-field
        v-model="search"
        label="Поиск..."
        name="title"
      />
      <v-select
        v-if="groups && type === 0"
        v-model="group"
        :items="groups"
        item-value="value"
        item-text="label"
        label="Выберите группу"
        name="groups"
      ></v-select>
      <v-select
        v-model="type"
        :items="types"
        label="Выберите тип"
        name="type"
        />
      <!-- <v-btn
        elevation="2"
        icon
        @click="get"
      > -->
      <v-btn
        elevation="2"
        icon
        @click="get"
      >
        <v-icon>mdi mdi-refresh</v-icon>
      </v-btn>
      <div class="media-uploader__images">
        <v-row>
          <v-col
            v-for="media in medias"
            :key="media.id"
            class="media-uploader__image"
            :cols="6"
          >
            <v-card
              class="mx-auto"
              max-width="250"
            >
              <img
                v-if="media.file_type === 0"
                @dragstart="onDragStart(media, $event)"
                draggable="true"
                :src="media.admin_url"
                alt=""
              />
              <img
                v-else
                :src="media.file_image_preview"
                draggable="true"
                @dragstart="onDragStart(media, $event)"
                />
              <v-card-subtitle>
                <strong> {{ media.title }}</strong>
                <!--{{media.description}}-->
              </v-card-subtitle>
            </v-card>
            <!--          <div class="media-uploader__actions">-->
            <!--            <v-icon @click.stop="confirmDialog = true">-->
            <!--              mdi-delete-->
            <!--            </v-icon>-->
            <!--          </div>-->
            <v-dialog
              v-model="confirmDialog"
              max-width="400px"
            >
              <v-card>
                <v-container>
                  <v-row>
                    <v-col>
                      <p>Вы уверены, что хотите удалить изображение? ВНИМАНИЕ! Убедитесь, что данное изображение нигде
                        не используется.</p>
                      <v-btn
                        class="mr-4"
                        color="primary"
                        @click="onDelete(media)"
                      >
                        Удалить
                      </v-btn>
                      <v-btn
                        class="ml-4"
                        color="secondary"
                        @click="onCancelDelete()"
                      >
                        Отменить
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
        <v-pagination
          v-model="page"
          :length="totalPages"
          />
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../axiosInstance';
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { mapActions, mapGetters } from 'vuex';
import {eventBus} from '../main';

export default {
  name: 'MediaUploader',
  props: {
    loadedMedias: {
      type: Array,
      default: () => ([]),
    },
  },
  data: () => ({
    isShow: false,
    isLoading: false,
    isError: false,
    medias: [],
    dropzoneOptions: {
      url: `${process.env.VUE_APP_API_URL}/api/media`,
      createImageThumbnails: false,
      uploadMultiple: true,
      paramName: 'files',
      maxFilesize: 30,
      acceptedFiles: '.jpg,.jpeg,.png',
      dictDefaultMessage: 'Drop a image here, or click to select a image to upload. Maximum size 7mb',
    },
    loadedFiles: 0,
    confirmDialog: false,
    search: '',
    type: 0,
    page: 1,
    totalPages: 1,
    types: [
      {text: 'Графические файлы', value: 0},
      {text: 'Текстовые файлы', value: 1},
    ],
    group: -1,
  }),
  created() {
    this.dropzoneOptions.headers = {
      Authorization: `${this.$store.getters['auth/getToken']}`
    }
    this.fetchMediaGroups().then(() => {
      this.get();
    })
  },
  computed: {
    ...mapGetters({
      groups: "Medias/getMediaGroups",
    })
  },
  methods: {
    ...mapActions({
      fetchMediaGroups: "Medias/fetchMediaGroups",
    }),
    onFilesAdded(files) {
      if (!this.isError) {
        this.isLoading = true;
      }
      this.loadedFiles = this.medias.length + files.length;
    },
    onUploadSuccess(file, response) {
      response.forEach((r) => {
        this.isError = false;
        const index = this.medias.findIndex(({id}) => id === r.id);
        if (index >= 0) {
          this.medias[index] = r;
        } else {
          this.isLoading = false;
          this.medias.push(r);
        }
      })

      if (this.medias.length === this.loadedFiles) {
        this.$emit('completeUploads', this.medias.map(({id}) => id));
        this.loadedFiles = 0;
      }
    },
    onUploadError(file) {
      this.isError = !file.accepted;
    },

    async onDelete(media) {
      const index = this.medias.findIndex(({id}) => id === media.id);
      this.medias.splice(index, 1);
      await axiosInstance.delete(`/media/${media.id}`);
      eventBus.$emit('deleteMedia', media);
      this.confirmDialog = false;
    },
    async onCancelDelete() {
      this.confirmDialog = false;
    },
    onDragStart(media, $event) {
      $event.dataTransfer.setData('text', JSON.stringify(media));
      const mediaData = document.createAttribute("data-media");
      mediaData.value = JSON.stringify(media)
      $event.srcElement.attributes.setNamedItem(mediaData)
      this.isShow = !this.isShow;
    },
    toMedias() {
      this.$router.push({name: 'medias-index'});
    },
    async get() {
      await axiosInstance.get(`/media/latest?group=${this.group}&search=${this.search}&per_page=${40}&file_type=${this.type}&page=${this.page}`)
        .then((response) => {
          this.medias = response.data.data;
          this.totalPages = response.data.paginator.total_pages;
        });
    }
  },
  watch: {
    search: {
      handler() {
        this.get()
      },
    },
    group: {
      handler() {
        this.get()
      },
    },
    type: {
      handler() {
        this.get();
      }
    },
    page: {
      handler() {
        this.get();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-progress-circular {
  margin: 5rem;
}

.media-uploader {
  height: 100vh;
  width: 500px;
  position: fixed;
  top: 0;
  right: 0;
  background: white;
  z-index: 999;
  transition: right .25s ease-in-out;

  &__body {
    height: 100%;
    padding: 30px;
    overflow: auto;
  }

  &__label {
    display: block;
    position: absolute;
    left: -24px;
    top: 20%;
    background: #b6b6f6;
    writing-mode: vertical-rl;
    padding: 10px 0;
    color: white;
    cursor: pointer;
    user-select: none;
  }

  &__images {
    margin-top: 20px;
  }

  &__image {
    position: relative;
    max-width: 100%;
    margin-bottom: 10px;

    img {
      width: 100%;
    }
  }

  &__actions {
    position: absolute;
    width: calc(100% - 10px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    bottom: 15px;

    button, i {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      text-shadow: 0 0 5px black;
      color: #ff5252;
      transition: color ease-in-out .3s;

      &:hover {
        color: white;
        transition: color ease-in-out .3s;
        cursor: pointer;
      }
    }
  }
}
</style>
