import Index from '../../views/main/roles/Index'
import Edit from '../../views/main/roles/Edit';
import Create from "../../views/main/roles/Create";

export default [
  {
    component: Index,
    name: 'roles-index',
    path: '/roles',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'roles',
    }
  },
  {
    component: Edit,
    name: 'roles-edit',
    path: '/roles/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'roles',
    }
  },
  {
    component: Create,
    name: 'roles-create',
    path: '/roles/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'roles',
    }
  }
]
