<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <v-tabs v-model="tab">
              <v-tab>
                Основная информация
              </v-tab>
              <v-tab>
                SEO
              </v-tab>
              <v-tab>
                SMM
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <catalog-form :catalog="fetchedDetail" @onSubmit="submit"/>
              </v-tab-item>
              <v-tab-item>
                <seo-form-separate
                  :entity="fetchedDetail"
                  :errors="{}"
                  @onSubmit="submit"
                />
              </v-tab-item>
              <v-tab-item>
                <smm-form-separate
                  :entity="fetchedDetail"
                  :errors="{}"
                  @onSubmit="submit"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CatalogForm from "../../../components/catalogs/CatalogForm";
import SeoFormSeparate from "../../../components/SEOFormSeparate";
import SmmFormSeparate from "../../../components/SMMFormSeparate";

export default {
  name: "Edit",
  components: {
    CatalogForm,
    SeoFormSeparate,
    SmmFormSeparate,
  },
  async created() {
    await this.fetchDetail(this.$route.params.id);
  },
  data: () => ({
    tab: 0,
    page: {
      title: "Редактирование каталог"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Каталог",
        disabled: true,
        to: '/catalogs'
      },
      {
        text: "Редактирование",
        disabled: true,
        to: '/catalogs/edit'
      },
    ],
  }),
  computed: {
    ...mapGetters({
      fetchedDetail: "Catalog/getFetchedDetail"
    })
  },
  methods: {
    ...mapActions({
      'fetchDetail': "Catalog/fetchDetail",
      'updateItem': "Catalog/updateItem",
    }),
    submit({ form, isNeedRedirect }) {
      this.updateItem({ form: form, isNeedRedirect: isNeedRedirect, router: this.$router });
    }
  },
  watch: {
    'fetchedDetail.title': {
      handler(newTitle) {
        this.page.title +=  ` "${newTitle}"`;
      }
    },
  }
}
</script>

<style scoped>

</style>
