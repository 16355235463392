import axiosInstance from "../../../axiosInstance";
import {Region} from "../../../models/region";

export default {
  namespaced: true,
  state: {
    fetchedListing: [],
    listingTotalItems: 0,
    fetchedDetail: {},
    isSaveButtonDisabled: false,
    isListingLoad: false,
    regionsWithOutParent: [],
    fetchedList: [],
  },
  getters: {
    getFetchedListing: state => state.fetchedListing,
    getFetchedDetail: state => state.fetchedDetail,
    getListingTotalItems: state => state.listingTotalItems,
    getChild: (state) => {
      const child = state.fetchedDetail.children

      if (child) {
        return child.sort((a, b) => {
          return (a.position === null) - (b.position === null) || +(a.position > b.position) || -(a.position < b.position);
        })
      } else {
        return [];
      }
    },
    getRegionsWithOutParent: state => state.regionsWithOutParent,
    isSaveButtonDisabled: state => state.isSaveButtonDisabled,
    isListingLoad: state => state.isListingLoad,
    setFetchedList: state => state.fetchedList,
  },
  mutations: {
    setFetchedListing(state, listing) {
      state.fetchedListing = listing;
    },
    setFetchedDetail(state, detail) {
      state.fetchedDetail = detail;
    },
    setListingTotalItems(state, totalItems) {
      state.listingTotalItems = totalItems;
    },
    setIsSaveButtonDisabled(state, value) {
      state.isSaveButtonDisabled = value;
    },
    setIsListingLoad(state, value) {
      state.isListingLoad = value;
    },
    setRegionsWithOutParent(state, value) {
      state.regionsWithOutParent = value;
    },
    setFetchedList(state, value) {
      state.fetchedList = value;
    }
  },
  actions: {
    fetchListing({commit}, {options, filters, search, scope}) {
      commit('setIsListingLoad', true);

      const {sortBy, sortDesc, page, itemsPerPage} = options;

      const data = {};
      filters ? data.filters = filters : null;
      search ? data.search = {value: search} : null;
      scope ? data.scopes = [{ name: scope }] : null;
      sortBy && sortBy[0] ? data.sort = [{field: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc'}] : null;

      const onlyTrashed = this.only_trashed ? `&only_trashed=1` : '';

      axiosInstance.post(`module/admin/regions/search?limit=${itemsPerPage}&page=${page}${onlyTrashed}`, data)
        .then((response) => {
          commit('setFetchedListing', response.data.data);
          commit('setListingTotalItems', response.data.meta.total);
        });

      commit('setIsListingLoad', false);
    },
    async destroy({dispatch}, id) {
      await Region.$query().destroy(id, 0).then(() => {
        dispatch('snackbar/showSuccessfullyDelete', null, {root: true});
      }).catch(() => {
        dispatch('snackbar/showDeleteError', null, {root: true});
      });
    },
    async fetchDetail({commit}, id) {
      await Region.$query().find(id).then((response) => {
        commit('setFetchedDetail', response.$attributes);
      })
    },
    update({commit, dispatch}, payload) {
      const {form, mediaIds, isNeedRedirect, router} = payload;

      commit('setIsSaveButtonDisabled', true);

      if (mediaIds) {
        form.mediaIds = mediaIds;
      }

      Region.$query().update(form.id, form).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true});
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, {root: true});
      });

      commit('setIsSaveButtonDisabled', false);

      if (isNeedRedirect) {
        router.push({ name: 'regions-index' });
      }
    },
    async store({commit, dispatch}, {form, mediaIds}) {
      commit('setIsSaveButtonDisabled', true);

      if (mediaIds) {
        form.mediaIds = mediaIds;
      }

      let region = {};

      await Region.$query().store(form).then((response) => {
        region = response.$attributes;
        dispatch('snackbar/showSuccessfullySaved', null, {root: true});
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, {root: true});
      }).finally(() => {
        commit('setIsSaveButtonDisabled', false);
      });

      return region;
    },
    async storeSort({commit, dispatch}, payload) {
      commit('setIsSaveButtonDisabled', true);

      let formattedData = {};

      payload.forEach((elem, index) => {
        formattedData[elem.id] = {position: index}
      });

      await axiosInstance.patch('/module/admin/regions/batch', {resources: formattedData}).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true});
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, {root: true});
      });

      commit('setIsSaveButtonDisabled', false);
    },
    fetchRegionsWithoutParent({commit, state}) {
      axiosInstance.get(`/module/admin/regions/withoutRelation?id=${state.fetchedDetail.id}`).then((response) => {
        commit('setRegionsWithOutParent', response.data.data);
      })
    },
    async attachRegions({commit, dispatch, state}, payload) {
      commit('setIsSaveButtonDisabled', true);

      let formattedData = {};
      payload.forEach(elem => {
        formattedData[elem] = {parent_id: state.fetchedDetail.id}
      });

      await axiosInstance.patch('/module/admin/regions/batch', {resources: formattedData}).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true});
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, {root: true});
      });

      commit('setIsSaveButtonDisabled', false);
    },
    async removeChildren({commit, dispatch}, id) {
      commit('setIsSaveButtonDisabled', true);

      await Region.$query().update(id, {parent_id: null, position: null}).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true});
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, {root: true});
      });

      commit('setIsSaveButtonDisabled', false);
    },
    async attachBookmark({dispatch}, item) {
      await axiosInstance.post('/auth/user/bookmark/attach', {
        entity_class: item.entity_class,
        entity_id: item.id,
      }).then(() => {
        dispatch('snackbar/showSuccessBookmarked', null, {root: true});
      });
    },
    async detachBookmark({dispatch}, item) {
      await axiosInstance.post('/auth/user/bookmark/detach', {
        entity_class: item.entity_class,
        entity_id: item.id,
      }).then(() => {
        dispatch('snackbar/showSuccessDeleteBookmarked', null, {root: true})
      })
    },
    async detachAll({dispatch}) {
      await axiosInstance.post('/auth/user/bookmark/detach-all', {
        entity_class: 'Modules\\Regions\\Entities\\Region',
      }).then(() => {
        dispatch('snackbar/showSuccessDeleteBookmarked', null, {root: true})
      })
    },
    async submitCollections({dispatch, state}, {items}) {
      await axiosInstance.post(`module/regions/${state.fetchedDetail.id}/collection-items/sync`, {items: items}).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true})
        dispatch('fetchDetail', state.fetchedDetail.id)
      }).catch(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true});
      })
    },
    async submitMainCollections({dispatch, state}, {items}) {
      await axiosInstance.post(`module/regions/${state.fetchedDetail.id}/collection-items/make-main`, {items: items}).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true})
        dispatch('fetchDetail', state.fetchedDetail.id)
      }).catch(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true});
      })
    },
    async fetchList({ commit }) {
      await axiosInstance.get('module/admin/regions/select-list').then((response) => {
        commit('setFetchedList', response.data.data);
      })
    }
  }
}
