import CatalogIndex from '../../views/main/catalogs/Index'
import CatalogItemIndex from '../../views/main/catalogs/items/Index'
import CatalogCreate from '../../views/main/catalogs/Create'
import CatalogItemCreate from '../../views/main/catalogs/items/Create'
import CatalogEdit from '../../views/main/catalogs/Edit';
import CatalogItemEdit from '../../views/main/catalogs/items/Edit';
import DynamicFields from '../../views/main/catalogs/DynamicFields';
import CatalogItemTable from '../../views/main/catalogs/items/Table';

export default [
  {
    component: CatalogIndex,
    name: 'catalog-index',
    path: '/catalogs',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
  {
    component: CatalogCreate,
    name: 'catalog-create',
    path: '/catalogs/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
  {
    component: CatalogEdit,
    name: 'catalog-edit',
    path: '/catalogs/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
  {
    component: DynamicFields,
    name: 'catalog-dynamic-fields',
    path: '/catalogs/:catalog_id/dynamic-fields',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
  {
    component: CatalogItemIndex,
    name: 'catalog-items-index',
    path: '/catalogs/:catalog_id/items',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
  {
    component: CatalogItemTable,
    name: 'catalog-items-index-table',
    path: '/catalogs/:catalog_id/items/table',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
  {
    component: CatalogItemCreate,
    name: 'catalog-items-create',
    path: '/catalogs/:catalog_id/items/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
  {
    component: CatalogItemEdit,
    name: 'catalog-items-edit',
    path: '/catalogs/:catalog_id/items/:id/edit',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  }
]
