import RegionStatistic from "../../views/main/temp/RegionStatistic"
import RegionMonitoring from "../../views/main/temp/RegionMonitoring"
import RegionOlympic from "../../views/main/temp/RegionOlympic"
import RegionBuget from "../../views/main/temp/RegionBuget"
import RegionExport from "../../views/main/temp/RegionExport"

export default [
  {
    component: RegionStatistic,
    name: 'region-statistic',
    path: '/temp/region-statistic',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
  {
    component: RegionMonitoring,
    name: 'region-statistic',
    path: '/temp/region-monitoring',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
  {
    component: RegionOlympic,
    name: 'region-statistic',
    path: '/temp/region-olympic',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
  {
    component: RegionBuget,
    name: 'region-buget',
    path: '/temp/region-buget',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
  {
    component: RegionExport,
    name: 'region-export',
    path: '/temp/region-export',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
]
