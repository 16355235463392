<template>
  <div>
    <h1>Создание поля</h1>
    <v-row>
      <v-col>
        <field-form @onSubmit="store"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FieldForm from "../../../components/fields/FieldForm";
import {mapActions} from "vuex";
export default {
  name: "Create",
  components: {FieldForm},
  methods: {
    ...mapActions({
      storeItem: "Fields/storeItem",
    }),
    store(payload) {
      this.storeItem({ payload: payload, router: this.$router });
    }
  }
}
</script>

<style scoped>

</style>
