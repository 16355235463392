<template>
  <v-form>
    <v-text-field
      v-model="form.title"
      :error-messages="errors.title"
      label="Название"
      name="title"
      required
    ></v-text-field>
    <v-textarea
      v-model="form.description"
      :error-messages="errors.description"
      name="description"
      label="Описание"
      required
    ></v-textarea>
    <v-text-field
      v-model="form.url"
      :error-messages="errors.url"
      label="Ссылка на сайт"
      name="url"
    ></v-text-field>
    <v-btn
      color="primary"
      :disabled="isSaveDisabled"
      :loading="isSaveDisabled"
      @click="submit"
    >
      Сохранить
    </v-btn>
  </v-form>
</template>

<script>
export default {
  name: "PartnerResourceForm",
  props: {
    partnerResource: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    form: {
      id: null,
      title: null,
      description: null,
      url: null,
    },
    isSaveDisabled: false,
  }),
  created() {
    if (this.$route.params.data) {
      this.form = JSON.parse(this.$route.params.data);
    }
  },
  methods: {
    submit() {
      this.isSaveDisabled = true;
      this.$emit('onSubmit', this.form);
    },
  },
  watch: {
    partnerResource: {
      handler(partnerResource) {
        if (partnerResource && Object.keys(partnerResource).length > 0) {
          this.form = partnerResource;
          this.isSaveDisabled = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
}
</script>
