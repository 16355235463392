<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <collection-form :errors="errors" @onSubmit="submit"/>
          </v-col>
        </v-row>
        <v-snackbar
          v-model="snackbarSuccess"
          :timeout="timeout"
          color="success"
        >
          Сохранено

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarSuccess = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar
          v-model="snackbarError"
          :timeout="timeout"
          color="error"
        >
          {{ this.errors }}

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarError = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import CollectionForm from '../../../components/collections/CollectionForm';
import {Collection} from '../../../models/collection';

export default {
  name: 'Create',
  components: {CollectionForm},
  data: () => ({
    errors: {},
    snackbarSuccess: false,
    snackbarError: false,
    timeout: 2000,
    page: {
      title: "Создание фасетной таксономии"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Фасетные таксономии",
        disabled: true,
        to: '/collections'
      },
      {
        text: "Создать",
        disabled: true,
        to: '/collections/create'
      },
    ]
  }),
  methods: {
    async submit(form) {
      try {
        this.errors = {};
        await Collection.$query().store(form);
        this.snackbarSuccess = true;
        await this.$router.push('/collections');
      } catch (e) {
        this.snackbarError = true;
        if (e.response?.data) {
          this.errors = e.response.data.errors
        }
        throw e;
      }
    },
  },
};
</script>
