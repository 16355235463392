import Index from '../../views/main/persons/Index'
import Create from '../../views/main/persons/Create'
import Edit from '../../views/main/persons/Edit';
import Sort from '../../views/main/persons/Sort';

export default [
  {
    component: Index,
    name: 'persons-index',
    path: '/persons',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'persons',
    }
  },
  {
    component: Create,
    name: 'persons-create',
    path: '/persons/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'persons',
    }
  },
  {
    component: Edit,
    name: 'persons-edit',
    path: '/persons/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'persons',
    }
  },
  {
    component: Sort,
    name: 'persons-sort',
    path: '/persons/sort',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'persons',
    }
  }
]
