<template>
  <v-form>
    <v-select
        v-model="form.collection_id"
        :error-messages="errors.collection_id"
        :items="collections"
        item-text="title"
        item-value="id"
        label="Выберите коллекцию"
        name="collection_id"
        required
    ></v-select>
    <v-text-field
        v-model="form.title"
        :error-messages="errors.title"
        label="Заголовок"
        name="title"
        required
    ></v-text-field>
    <v-text-field
        v-model="form.slug"
        :error-messages="errors.slug"
        label="Slug"
        name="slug"
        required
        :disabled="!(Object.keys(collectionItem).length === 0)"
    ></v-text-field>
    <v-btn
        color="primary"
        @click="submit"
        :disabled="isSaveDisabled"
        :loading="isSaveDisabled"
    >
      Сохранить
    </v-btn>
  </v-form>
</template>

<script>
import { Collection } from '../../models/collection';
import slugify from "slugify";
import optionsMixin from "../../mixins/optionsMixin";
export default {
  name: 'BlockGroupForm',
  mixins: [optionsMixin],
  props: {
    collectionItem: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    form: {
      id: null,
      title: null,
      slug: null,
      language: null,
      collection_id: null,
    },
    collections: [],
    languages: [
      { text: 'Русский', value: 'ru' },
      { text: 'English', value: 'en' },
    ],
    isSaveDisabled: false,
  }),
  created() {
    this.getCollections();
  },
  methods: {
    submit() {
      this.isSaveDisabled = true;
      this.$emit('onSubmit', this.form);
    },
    async getCollections() {
      try {
        const collections = await Collection.$query().get();
        this.collections = collections.map(collection => collection.$attributes);
      } catch (e) {
        throw e;
      }
    },
  },
  mounted() {
    this.form.language = this.language;
  },
  watch: {
    collectionItem: {
      handler() {
        if (Object.keys(this.collectionItem).length > 0) {
          this.form = JSON.parse(JSON.stringify(this.collectionItem));
          this.form.language = this.language;
          this.isSaveDisabled = false;
        }
      },
      immediate: true,
    },
    'form.title': {
      handler(value) {
        if (value && Object.keys(this.collectionItem).length === 0) {
          this.form.slug = slugify(value.toLowerCase()).match(/[a-z]|[0-9]|[-]/gm).join('');
        }
      }
    }
  },
};
</script>
