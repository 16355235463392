<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Организаторы</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="primary" to="/organizers/create">
          Создать организатора
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="search"
          label="Поиск..."
          name="title"
        >
          <v-icon slot="prepend">
            mdi-magnify
          </v-icon>
          <v-btn
            slot="append"
            icon
            @click="search = null"
            color="red"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :loading="loading"
          :server-items-length="total"
          :search="search"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn
              :href="editItem(item)"

              icon
            >
              <v-icon color="primary">
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-icon
              color="red"
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {Organizer} from '../../../models/organizer';
import axiosInstance from "../../../axiosInstance";

export default {
  name: 'Index',
  data: () => ({
    loading: true,
    total: 0,
    options: {
      sortBy: ['id'],
      sortDesc: [true],
    },
    search: '',
    headers: [
      {text: 'Id', value: 'id'},
      {text: 'Название', value: 'title'},
      {text: 'Slug', value: 'slug'},
      {text: 'Дата публикации', value: 'published_at'},
      {text: 'Дата создания', value: 'created_at'},
      {text: 'Дата обновления', value: 'updated_at'},
      {text: '', value: 'actions'},
    ],
    items: [],
  }),
  created() {
    this.get();
  },
  methods: {
    editItem(item) {
      return this.$router.resolve({name: 'organizers-edit', params: {id: item.id}}).href
    },
    async deleteItem(item) {
      await Organizer.$query().destroy(item.id);
      await this.get();
    },
    async get() {
      this.loading = true;
      const {sortBy, sortDesc, page, itemsPerPage} = this.options;

      const data = {};
      this.search ? data.search = {value: this.search} : null;
      sortBy && sortBy[0] ? data.sort = [{field: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc'}] : null;

      await axiosInstance.post(`/organizers/search?limit=${itemsPerPage}&page=${page}`, data)
        .then((response) => {
          this.items = response.data.data;
          this.total = response.data.meta.total;
        });

      this.loading = false;
    },
  },
  watch: {
    search: {
      handler() {
        this.get();
      }
    },
    options: {
      handler() {
        this.get()
      },
      deep: true,
    },
  },
};
</script>
