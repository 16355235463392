<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row>
      <v-col>
        <h1>Добавление партнера</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <webmaster-partners-form redirect=true @onSubmit="submit"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import WebmasterPartnersForm from '../../../components/webmasterPartners/WebmasterPartnersForm';

import {mapActions} from "vuex";

export default {
  name: 'Create',
  data: () => ({
    page: {
      title: "Добавление партнера"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Партнеры Вебмастер",
        disabled: true,
        to: '/webmasterPartners'
      },
      {
        text: "Создание",
        disabled: true,
        to: '/webmasterPartners/edit'
      },
    ],
  }),
  components: {WebmasterPartnersForm},
  methods: {
    ...mapActions({
      storeItem: 'WebmasterPartners/storeItem',
    }),
    submit(payload) {
      this.storeItem(payload).then(() => {});
    }
  }
}
</script>
