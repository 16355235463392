<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
       <v-row>
         <v-col>
           <BlockSettingForm
             @submit="submit"
           />
         </v-col>
       </v-row>
      </v-card-text>
    </v-card>
 </v-container>
</template>

<script>
import BlockSettingForm from "../../../../components/blocks/BlockSettingForm";
import {mapActions} from "vuex";
export default {
  name: "Create",
  components: {BlockSettingForm},
  data: () => ({
    page: {
      title: "Создание настройки блока"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Настройки блока",
        disabled: true,
        to: '/block-settings'
      },
      {
        text: "Создание",
        disabled: true,
        to: '/block-settings/create'
      },
    ]
  }),
  methods: {
    ...mapActions({
      store: 'BlockSettings/storeItem'
    }),
    submit(form) {
      this.store(form).then(() => {
        this.$router.push({ name: 'block-settings-index' });
      })
    }
  }
}
</script>

<style scoped>

</style>
