<template>
  <v-app id="inspire">
    <v-layout fill-height>
      <v-main>
        <router-view/>
      </v-main>
    </v-layout>
    <snackbar/>
  </v-app>
</template>

<script>
import Snackbar from "@/components/snackbar";
export default {
  name: 'clear-layout',
  components: {Snackbar}
};
</script>
