<template>
  <div>
    <v-row>
      <v-col>
        <h1>
          Динамические поля
          <v-btn fab small color="primary" to="fields/create">
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field v-model="search" placeholder="Поиск..."/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select label="Тип" @click:append="query.type=null" item-value="key" item-text="value" :items="types" v-model="query.type" append-icon="mdi-close"/>
      </v-col>
      <v-col>
        <v-select label="Группа" @click:append="query.group=null" item-value="key" item-text="value" :items="groups" v-model="query.group" append-icon="mdi-close"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          disable-sort
          :loading="isLoading"
          loading-text="Загрузка..."
          :items="fetchedListing"
          :headers="headers"
          :options.sync="options"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn icon color="red" @click="deleteItem(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn icon color="primary" @click="editItem(item)">
              <v-icon>mdi-pen</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Index",
  data: () => ({
    search: "",
    headers: [
      { text: "ID", value: "id" },
      { text: "Название", value: "title" },
      { text: "Тип", value: "type" },
      { text: "Группа", value: "group" },
      { text: "", value: "actions" },
    ],
    options: {
      page: 1,
      itemsPerPage: 15,
    },
    query: {
      group: null,
      type: null,
    },
  }),
  created() {
    this.fetchListing(this.payload);
    this.fetchTypes();
    this.fetchGroups();
  },
  computed: {
    ...mapGetters({
      isLoading: "Fields/isLoading",
      fetchedListing: "Fields/getFetchedListing",
      groups: "Fields/getGroups",
      types: "Fields/getTypes",
    }),
    payload() {
      return {
        query: this.query,
        options: this.options,
        search: this.search
      }
    }
  },
  methods: {
    ...mapActions({
      fetchListing: "Fields/fetchListing",
      destroyItem: "Fields/destroyItem",
      fetchTypes: "Fields/fetchTypes",
      fetchGroups: "Fields/fetchGroups",
    }),
    deleteItem({ id }) {
      this.destroyItem(id).then(() => {
        this.fetchListing(this.payload);
      })
    },
    editItem({ id }) {
      this.$router.push({ name: 'fields-edit', params: { id: id } });
    }
  },
  watch: {
    query: {
      handler() {
        this.fetchListing(this.payload)
      },
      deep: true,
    },
    options: {
      handler() {
        this.fetchListing(this.payload);
      },
      deep: true,
    },
    search: {
      handler() {
        this.fetchListing(this.payload);
      }
    }
  }
}
</script>

<style scoped>

</style>
