<template>
  <v-form>
    <v-text-field
        v-model="form.title"
        :error-messages="errors.title"
        label="Заголовок (для поиска)"
        name="title"
        type="text"
        required
      ></v-text-field>
    <h3 class="mt-3 mb-3">Вопрос</h3>
    <tip-tap v-model="form.question" required/>
    <h3 class="mt-3 mb-3">Ответ</h3>
    <tip-tap v-model="form.answer" required/>
    <h3 class="mt-3 mb-3">Описание</h3>
    <tip-tap v-model="form.description" required/>
    <media-widget
      class="mb-3"
      label="Изображение"
      :medias="form.image"
      :is-multiple="false"
      @onChange="setImage"
    />
    <media-uploader
      :loaded-medias="faq.loaded_medias"
      @completeUploads="onCompleteUploads"
    />
    <v-card-actions class="pa-5 text-left">
      <v-btn
        color="primary"
        :disabled="isSaveDisabled"
        :loading="isSaveDisabled"
        @click="submit(true)"
      >
        Сохранить
      </v-btn>
      <v-btn
        color="primary"
        :disabled="isSaveDisabled"
        :loading="isSaveDisabled"
        @click="submit(false)"
      >
        Сохранить и продолжить
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import MediaUploader from '../MediaUploader';
import MediaWidget from '../MediaWidget';
import TipTap from "../editor/TipTap";

export default {
  name: "FaqForm",
  components: {
    MediaUploader,
    MediaWidget,
    TipTap,
  },
  props: {
    faq: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    form: {
      id: null,
      title: null,
      question: null,
      answer: null,
      description: null,
      image: null,
    },
    mediaIds: [],
    isSaveDisabled: false,
    rules: {
      required: value => !!value || 'Required.',
    },
  }),
  created() {
    if (this.$route.params.data) {
      this.form = JSON.parse(this.$route.params.data);
    }
  },
  methods: {
    submit(isNeedRedirect) {
      this.isSaveDisabled = true;

      if(this.$route.params.id)
        this.form.id = this.$route.params.id;

      this.$emit('onSubmit', { form: this.form, mediaIds: this.mediaIds, isNeedRedirect: isNeedRedirect, });
    },
    setImage(image) {
      this.form.image = image;
    },
    async onCompleteUploads(ids) {
      this.mediaIds = ids;
      await this.submit(true);
      this.mediaIds = [];
    },
  },
  watch: {
    faq: {
      handler(faq) {
        if (faq && Object.keys(faq).length > 0) {
          this.form = faq;
          this.isSaveDisabled = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
}
</script>
