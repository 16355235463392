var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"down-top-padding",attrs:{"fluid":""}},[_c('BaseBreadcrumb',{attrs:{"title":_vm.page.title,"icon":_vm.page.icon,"breadcrumbs":_vm.breadcrumbs}}),_c('v-card',[_c('v-card-text',{staticClass:"pa-5"},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Поиск...","filled":"","background-color":"transparent","hide-details":"","name":"title"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[(_vm.search)?_c('v-btn',{staticClass:"reset-button",attrs:{"slot":"append","icon":"","color":"red"},on:{"click":function($event){_vm.search = null}},slot:"append"},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1)],1),_c('v-col',[_c('v-switch',{attrs:{"label":"Показать удаленные","name":"only_trashed","value":"1"},model:{value:(_vm.only_trashed),callback:function ($$v) {_vm.only_trashed=$$v},expression:"only_trashed"}})],1),_c('v-col',{staticClass:"justify-end align-center d-flex",attrs:{"cols":"12","lg":"4"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"color":"primary","to":"/regions/create"}},[_vm._v(" Создать регион ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","href":_vm.getExportUrl}},[_vm._v(" Выгрузить ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-0"},[_c('v-select',{attrs:{"items":_vm.statuses,"label":"Выберите статус","name":"published","prepend-icon":"mdi-publish"},model:{value:(_vm.query.published),callback:function ($$v) {_vm.$set(_vm.query, "published", $$v)},expression:"query.published"}})],1),_c('v-col',{staticClass:"pl-0",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){_vm.query.published = null}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-col',[_c('v-select',{attrs:{"label":"В работе","items":_vm.taskItems,"item-value":"value","item-text":"title"},model:{value:(_vm.scope),callback:function ($$v) {_vm.scope=$$v},expression:"scope"}})],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Дата создания","prepend-icon":"mdi-calendar","readonly":"","append-icon":"mdi-close"},on:{"click:append":function($event){_vm.query.created_at = null}},model:{value:(_vm.query.created_at),callback:function ($$v) {_vm.$set(_vm.query, "created_at", $$v)},expression:"query.created_at"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datePicker),callback:function ($$v) {_vm.datePicker=$$v},expression:"datePicker"}},[_c('v-date-picker',{attrs:{"locale":"ru-RU"},on:{"input":function($event){_vm.datePicker = false}},model:{value:(_vm.query.created_at),callback:function ($$v) {_vm.$set(_vm.query, "created_at", $$v)},expression:"query.created_at"}})],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"mb-4",attrs:{"color":"primary","block":""},on:{"click":_vm.detachAll}},[_vm._v(" Приступить к задаче(очистить звездочки) ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"footer-props":{'items-per-page-options':[15, 30, 50, 100, -1]},"loading":_vm.loading,"server-items-length":_vm.total,"search":_vm.search},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"pill":""}},[_vm._v(_vm._s(item.id))])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)),_c('br'),(item.published)?_c('v-chip',{attrs:{"color":"green","content":"Опубликовано","label":""}},[_vm._v("Опубликовано")]):_vm._e(),_c('v-chip',{attrs:{"color":"red","content":"Не опубликовано","label":""}},[_vm._v("Не опубликовано")])]}},{key:"item.bookmarks",fn:function(ref){
var item = ref.item;
return [(item.is_bookmarked)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.detach(item)}}},[_c('v-icon',{attrs:{"color":"orange","small":""}},[_vm._v(" mdi-star ")])],1):_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.attach(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-star ")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.deleted_at)?_c('v-icon',{attrs:{"color":"green","small":""},on:{"click":function($event){return _vm.restoreItem(item)}}},[_vm._v(" mdi-backup-restore ")]):[_c('v-btn',{attrs:{"href":_vm.editItem(item),"icon":""}},[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-icon',{attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])],_c('v-btn',{attrs:{"href":item.preview_url,"target":"_blank","icon":""}},[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v(" mdi-eye ")])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }