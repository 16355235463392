<template>
  <v-snackbar
    :color="color"
    :timeout="timeout"
    v-model="isShow"
  >
    {{text}}
  </v-snackbar>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "snackbar",
  computed: {
    ...mapGetters({
      text: 'snackbar/getText',
      color: 'snackbar/getColor',
      timeout: 'snackbar/getTimeout',
      isVisible: 'snackbar/isVisible',
    }),
    isShow: {
      get() {
        return this.isVisible;
      },
      set(value) {
        this.setIsVisible(value);
      }
    },
  },
  methods: {
    ...mapMutations({
      setIsVisible: 'snackbar/setIsVisible',
    })
  }
}
</script>

<style scoped>

</style>
