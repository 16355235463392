<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Редактирование функциональной иконки "{{icon.title}}"</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <FunctionalIconsForm
          :icon="icon"
          @onSubmit="submit"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FunctionalIconsForm from "../../../components/functionalIcons/FunctionalIconsForm";
import {FunctionalIcon} from "../../../models/functionalIcon";

export default {
  components: {
    FunctionalIconsForm,
  },
  name: "Edit",
  data: () => ({
    icon: {},
  }),
  created() {
    this.get();
  },
  methods: {
    async get() {
      this.icon = (await FunctionalIcon.$query().find(this.$route.params.id)).$attributes;
    },
    async submit({ form, mediaIds }) {

      if (mediaIds) {
        form.mediaIds = mediaIds;
      }

      await FunctionalIcon.$query().update(form.id, form);
      await this.get();
    }
  }
}
</script>

<style scoped>

</style>
