import Index from '../../views/main/suggests/Index'
import Create from '../../views/main/suggests/Create'
import Edit from '../../views/main/suggests/Edit';

export default [
  {
    component: Index,
    name: 'suggests-index',
    path: '/suggests',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'suggests',
    }
  },
  {
    component: Create,
    name: 'suggests-create',
    path: '/suggests/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'suggests',
    }
  },
  {
    component: Edit,
    name: 'suggests-edit',
    path: '/suggests/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'suggests',
    }
  },
]
