<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="items"
              :options.sync="options"
              :server-items-length="totalItems"
              class="contact-listing-app"
            >
              <template v-slot:top>
                <v-toolbar flat class="mb-8">
                  <v-row/>
                  <v-spacer/>
                  <v-btn color="primary" to="/cron/create" dark class="mb-2 text-capitalize">
                    <v-icon class="mr-2">mdi-clock-plus</v-icon>
                    Добавить задачу
                  </v-btn>
                </v-toolbar>
              </template>
              <template v-slot:item.id="{ item }">
                <v-chip pill>{{ item.id }}</v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  :href="editItem(item)"
                  icon
                >
                  <v-icon color="primary" small>
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-icon
                  v-if="!item.deleted_at"
                  color="red"
                  small
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: "Index",
  data: () => ({
    options: {
      sortBy: ['id'],
      sortDesc: [true],
      page: 1,
      itemsPerPage: 20,
    },
    headers: [
      {text: 'ID', value: 'id'},
      {text: 'Команда', value: 'command'},
      {text: 'Переодичность', value: 'cron'},
      {text: '', value: 'actions'},
    ],
    page: {
      title: 'Планировщик задач',
      icon: 'mdi-clock-plus'
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Планировщик задач",
        disabled: true,
        to: '/cron'
      },
    ],
  }),
  methods: {
    ...mapActions({
      fetchListing: "Cron/fetchListings",
      destroyItem: "Cron/destroyItem",
    }),
    deleteItem(item) {
      this.destroyItem(item).then(() => {
        this.fetchListing(this.options);
      });
    },
    editItem(item) {
      return this.$router.resolve({name: 'cron-edit', params: {id: item.id}}).href
    },
  },
  computed: {
    ...mapGetters({
      isLoading: "Cron/isLoading",
      items: "Cron/getListings",
      totalItems: "Cron/totalItems",
    })
  },
  watch: {
    options: {
      handler() {
        this.fetchListing(this.options)
      },
      deep: true,
      immediate: true,
    }
  }
}
</script>

<style scoped>

</style>
