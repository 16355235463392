import Events from '../../views/main/statisticsV2/Events'
import General from '../../views/main/statisticsV2/General'
import Social from '../../views/main/statisticsV2/Social'
import Regional from '../../views/main/statisticsV2/Regional'
import Media from '../../views/main/statisticsV2/Media'
import Popular from '../../views/main/statisticsV2/Popular'

export default [
  {
    component: Events,
    name: 'statistic-v2-events',
    path: '/statisticsV2/events',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
  {
    component: General,
    name: 'statistic-v2-general',
    path: '/statisticsV2/general',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
  {
    component: Social,
    name: 'statistic-v2-social',
    path: '/statisticsV2/social',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
  {
    component: Regional,
    name: 'statistic-v2-regional',
    path: '/statisticsV2/regional',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
  {
    component: Media,
    name: 'statistic-v2-media',
    path: '/statisticsV2/media',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
  {
    component: Popular,
    name: 'statistic-v2-popular',
    path: '/statisticsV2/popular',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  }
]
