import axiosInstance from "../../../axiosInstance";
import {Form} from "../../../models/form";

export default {
  state: {
    fetchedListing: [],
    listingTotalItems: 0,
    fetchedDetail: {},
    isSaveButtonDisabled: false,
    isListingLoad: false,
    listEntityClasses: [],
    listEntityTitles: [],
  },
  getters: {
    getFetchedListing: state => state.fetchedListing,
    getFetchedDetail: state => state.fetchedDetail,
    getListingTotalItems: state => state.listingTotalItems,
    isSaveButtonDisabled: state => state.isSaveButtonDisabled,
    isListingLoad: state => state.isListingLoad,
    getListEntityClasses: state => state.listEntityClasses,
    getListEntityTitles: state => state.listEntityTitles,
  },
  mutations: {
    setFetchedListing(state, listing) {
      state.fetchedListing = listing;
    },
    setFetchedDetail(state, detail) {
      state.fetchedDetail = detail;
    },
    setListingTotalItems(state, totalItems) {
      state.listingTotalItems = totalItems;
    },
    setIsSaveButtonDisabled(state, value) {
      state.isSaveButtonDisabled = value;
    },
    setIsListingLoad(state, value) {
      state.isListingLoad = value;
    },
    setListEntitiesClasses(state, value) {
      state.listEntityClasses = value;
    },
    setListEntitiesTitles(state, value) {
      state.listEntityTitles = value;
    }
  },
  actions: {
    fetchListing({commit}, {options, onlyTrashed, search}) {
      commit('setIsListingLoad', true)

      const {page, itemsPerPage} = options;
      const onlyTrashedQuery = onlyTrashed ? `&only_trashed=1` : '';
      let data = {};
      search ? data.search = {value: search} : null;

      axiosInstance
        .post(`/module/form/admin/form/search?limit=${itemsPerPage}&page=${page}${onlyTrashedQuery}`, data)
        .then((response) => {
          commit('setFetchedListing', response.data.data);
          commit('setListingTotalItems', response.data.meta.total);
        })

      commit('setIsListingLoad', false)
    },
    destroyItem({dispatch}, id) {
      Form.$query().destroy(id).then(() => {
        dispatch('snackbar/showSuccessfullyDelete', null, {root: true});
      }).catch(() => {
        dispatch('snackbar/showDeleteError', null, {root: true});
      });
    },
    fetchDetail({commit, dispatch}, id) {
      Form.$query().find(id).then((response) => {
        commit('setFetchedDetail', response.$attributes);
        dispatch('fetchEntityTitles', response.$attributes.entity);
      })
    },
    updateItem({commit, dispatch}, {id, ...form}) {
      commit('setIsSaveButtonDisabled', true);

      Form.$query().update(id, form).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true});
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, {root: true});
      }).finally(() => {
        commit('setIsSaveButtonDisabled', false);
      });
    },
    storeItem({commit, dispatch}, form) {
      commit('setIsSaveButtonDisabled', true);

      Form.$query().store(form).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true});
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, {root: true});
      }).finally(() => {
        commit('setIsSaveButtonDisabled', false);
      });
    },
    async fetchEntityClasses({commit}) {
      let {data: entitiesData} = await axiosInstance.get('/constants/form-entities')
      const entities = Object.keys(entitiesData).map(ent => (
        {
          value: ent,
          title: entitiesData[ent]
        }
      ));
      commit('setListEntitiesClasses', entities);
    },
    async fetchEntityTitles({commit}, entity) {
      let {data: entitiesData} = await axiosInstance.get(`/constants/get-entities?entity=${entity}`)
      const entitiesTitle = Object.keys(entitiesData).map(ent => (
        {
          value: ent,
          title: `${ent} | ${entitiesData[ent]}`
        }
      ))
      commit('setListEntitiesTitles', entitiesTitle);
    }
  }
}
