var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"app":"","temporary":"","right":!_vm.$vuetify.rtl},model:{value:(_vm.Customizer_drawer),callback:function ($$v) {_vm.Customizer_drawer=$$v},expression:"Customizer_drawer"}},[_c('div',{staticClass:"px-4 py-4"},[_c('span',[_vm._v("Horizontal")]),_c('v-switch',{staticClass:"mt-2",attrs:{"hide-details":"","label":"Horizontal"},model:{value:(_vm.setHorizontalLayout),callback:function ($$v) {_vm.setHorizontalLayout=$$v},expression:"setHorizontalLayout"}})],1),_c('v-divider'),_c('div',{staticClass:"px-4 py-4"},[_c('span',[_vm._v("RTL")]),_c('v-switch',{staticClass:"mt-2",attrs:{"hide-details":"","primary":"","label":"Right to Left"},model:{value:(_vm.$vuetify.rtl),callback:function ($$v) {_vm.$set(_vm.$vuetify, "rtl", $$v)},expression:"$vuetify.rtl"}})],1),_c('v-divider'),(!_vm.setHorizontalLayout)?_c('div',{staticClass:"px-4 py-4"},[_c('span',[_vm._v("Mini Sidebar")]),_c('v-switch',{staticClass:"mt-2",attrs:{"hide-details":"","label":"Mini variant"},model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}})],1):_vm._e(),_c('v-divider'),_c('div',{staticClass:"px-4 py-4"},[_c('span',[_vm._v("Theme Color")]),_c('v-item-group',{model:{value:(_vm.themecolor),callback:function ($$v) {_vm.themecolor=$$v},expression:"themecolor"}},_vm._l((_vm.themecolors),function(tcolor){return _c('v-item',{key:tcolor,staticClass:"mt-2",attrs:{"value":tcolor},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-avatar',{staticClass:"v-settings__item mr-2",class:active && 'v-sidebar_active',attrs:{"color":tcolor,"size":"25"},on:{"click":toggle}})]}}],null,true)})}),1)],1),_c('v-divider'),_c('div',{staticClass:"px-4 py-4"},[_c('span',[_vm._v("Topbar Colors")]),_c('v-item-group',{model:{value:(_vm.setNavbarColor),callback:function ($$v) {_vm.setNavbarColor=$$v},expression:"setNavbarColor"}},_vm._l((_vm.navbarColors),function(color){return _c('v-item',{key:color,staticClass:"mt-2",attrs:{"value":color},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-avatar',{staticClass:"v-settings__item mr-2",class:active && 'v-sidebar_active',attrs:{"color":color,"size":"25"},on:{"click":toggle}})]}}],null,true)})}),1)],1),_c('v-divider'),_c('div',{staticClass:"px-4 py-4"},[_c('span',[_vm._v("Sidebar Colors")]),_c('v-item-group',{model:{value:(_vm.setSidebarColor),callback:function ($$v) {_vm.setSidebarColor=$$v},expression:"setSidebarColor"}},_vm._l((_vm.SidebarColors),function(color){return _c('v-item',{key:color,staticClass:"mt-2",attrs:{"value":color},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-avatar',{staticClass:"v-settings__item mr-2",class:active && 'v-sidebar_active',attrs:{"color":color,"size":"25"},on:{"click":toggle}})]}}],null,true)})}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }