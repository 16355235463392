import axiosInstance from "../../../axiosInstance";

export default {
  namespaced: true,
  state: {
    fetchedListing: [],
    listingTotalItems: 0,
    isListingLoading: false,
    actionButtonDisabled: false,
    fetchedDetail: {},
    mediaCreators: [],
    mediaGroups: [],
  },
  getters: {
    getFetchedListing: (state) => state.fetchedListing,
    getListingTotalItems: (state) => state.listingTotalItems,
    isListingLoading: (state) => state.isListingLoading,
    getActionButtonDisabled: (state) => state.actionButtonDisabled,
    getFetchedDetail: (state) => state.fetchedDetail,
    getMediaCreators: (state) => state.mediaCreators,
    getMediaGroups: (state) => state.mediaGroups,
  },
  mutations: {
    setFetchedListing(state, value) {
      state.fetchedListing = value;
    },
    setListingTotalItems(state, value) {
      state.listingTotalItems = value;
    },
    setListingLoading(state, value) {
      state.isListingLoading = value;
    },
    setActionButtonDisabled(state, value) {
      state.actionButtonDisabled = value;
    },
    setFetchedDetail(state, value) {
      state.fetchedDetail = value;
    },
    setMediaCreators(state, value) {
      state.mediaCreators = value;
    },
    setMediaGroups(state, value) {
      state.mediaGroups = value;
    }
  },
  actions: {
    async fetchListing({commit}, {options, filters}) {
      commit('setListingLoading', true);

      const { sortBy, sortDesc ,page, itemsPerPage} = options;
      const {group, file_type, creator, created_at, search, taxonomy} = filters;

      await axiosInstance.get(`/media/latest`, {
        params: {
          group: group,
          file_type: file_type,
          created_by: creator,
          page: page,
          itemsPerPage: itemsPerPage,
          sortBy: sortBy[0],
          sortDesc: sortDesc[0],
          created_at: created_at,
          search: search,
          taxonomy: taxonomy,
        }
      }).then((response) => {
        commit('setFetchedListing', response.data.data);
        commit('setListingTotalItems', response.data.paginator.total);
      });

      commit('setListingLoading', false);
    },
    async fetchMediaCreators({ commit }) {
      await axiosInstance.get('media/media-creators').then((response) => {
        commit('setMediaCreators', response.data);
      })
    },
    async destroyItem({ dispatch }, { id }) {
      return await axiosInstance.delete(`/media/${id}`).then(async () => {
        dispatch("snackbar/showSuccessfullyDelete", null, { root: true });
      });
    },
    async makeMainCollectionItem({ dispatch }, { media, items }) {
      await axiosInstance.post(`media/${media.id}/collection-items/make-main`, {items: items}).then(() => {
        dispatch("snackbar/showSuccessfullySaved", null, { root: true });
      }).catch(() => {
        dispatch("snackbar/showSaveError", null, { root: true })
      });
    },
    async syncCollectionItems({ dispatch }, { media, items }) {
      await axiosInstance.post(`media/${media.id}/collection-items/sync`, {items: items}).then(() => {
        dispatch("snackbar/showSuccessfullySaved", null, { root: true });
      }).catch(() => {
        dispatch("snackbar/showSaveError", null, { root: true });
      });
    },
    // eslint-disable-next-line no-unused-vars
    async dynamicResize({ dispatch }, { media, width, height }) {
      return (await axiosInstance.get(`media/resize/${media}?width=${width}&height=${height}`));
    },
    async fetchMediaGroups({ dispatch, commit }) {
      await axiosInstance.get('constants/media-groups').then((response) => {
        commit('setMediaGroups', response.data);
      }).catch(() => {
        dispatch('snackbar/showUnknownError', null, { root: true });
      })
    }
  }
}
