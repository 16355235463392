import { Model } from "@tailflow/laravel-orion/lib/model";
import {HasOne} from "@tailflow/laravel-orion/lib/drivers/default/relations/hasOne";
import {BelongsTo} from "@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo";
import {BlockGroup} from "./blockGroup";

export class Block extends Model<{
    block_group_id: number,
    title: string,
    image: string,
    path: string,
    component: string,
    class: string,
    structure: object,
    created_at: string,
    updated_at: string,
}> {

    $resource(): string {
        return "module/blocks";
    }

    public group(): BelongsTo<BlockGroup> {
        return new BelongsTo<BlockGroup>(BlockGroup, this);
    }

}
