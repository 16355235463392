<template>
  <v-container>
    <h2>Редактирование сообщения: "{{ mail.title }}"</h2>
    <div>
      <mail-form :mail="mail" @onSubmit="submit"/>
    </div>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import MailForm from "../../../components/mails/MailForm";

export default {
  name: "Edit",
  components: {MailForm},
  computed: {
    ...mapGetters({
      mail: 'Mails/getFetchedDetail',
    })
  },
  created() {
    this.fetchDetail(this.$route.params.id);
  },
  methods: {
    ...mapActions({
      fetchDetail: 'Mails/fetchDetail',
      updateItem: 'Mails/updateItem',
    }),
    submit(form) {
      this.updateItem(form);
    }
  }
}
</script>

<style scoped>

</style>
