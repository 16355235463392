import Index from '../../views/main/medias/Index'
import Create from '../../views/main/medias/Create'
import Edit from '../../views/main/medias/Edit'

export default [
  {
    component: Index,
    name: 'medias-index',
    path: '/medias',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
  {
    component: Create,
    name: 'medias-create',
    path: '/medias/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
  {
    component: Edit,
    name: 'medias-edit',
    path: '/medias/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  },
]
