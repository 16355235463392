<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-tabs
          v-model="tab"
          background-color="transparent"
          grow
        >
          <v-tab>
            Основная информация
          </v-tab>
          <v-tab>
            Элементы
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <collection-form
              :collection="collection"
              :errors="errors"
              @onSubmit="submit"
            />
          </v-tab-item>
          <v-tab-item>
            <collection-item-tab-form
              :collection-id="collection.id"
              :errors="errors"
            />
          </v-tab-item>
        </v-tabs-items>
        <v-snackbar
          v-model="snackbarSuccess"
          :timeout="timeout"
          color="success"
        >
          Сохранено

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarSuccess = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar
          v-model="snackbarError"
          :timeout="timeout"
          color="error"
        >
          {{ this.errors }}

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarError = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import CollectionForm from '../../../components/collections/CollectionForm';
import { Collection } from '../../../models/collection';
import CollectionItemTabForm from "../../../components/collections/CollectionItemTabForm";

export default {
  name: 'Edit',
  components: {CollectionForm, CollectionItemTabForm},
  data: () => ({
    collection: {},
    errors: {},
    snackbarSuccess: false,
    snackbarError: false,
    timeout: 2000,
    tab: 0,
    page: {
      title: "Редактировать фасетные таксономии"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Фасетные таксономии",
        disabled: true,
        to: '/collections'
      },
      {
        text: "Редактировать",
        disabled: true,
        to: '/collections/edit'
      },
    ]
  }),
  created() {
    this.find();
  },
  methods: {
    async submit(form) {
      try {
        this.errors = {};
        await Collection.$query().update(form.id, form);
        await this.find();
        this.snackbarSuccess = true;
      } catch (e) {
        this.snackbarError = true;
        if (e.response?.data) {
          this.errors = e.response.data.errors
        }
        throw e;
      }
    },
    async find() {
      const collection = await Collection.$query().find(this.$route.params.id);
      this.collection = collection.$attributes;
    }
  },
  watch: {
    'collection.id': {
      handler(newId) {
        this.page.title += ' ' + newId;
      }
    },
  }
};
</script>
