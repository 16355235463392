import {SortDirection} from "@tailflow/laravel-orion/lib/drivers/default/enums/sortDirection";
import {KeyWord} from "../../../models/keyWord";
import {FilterOperator} from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator";
import axiosInstance from "../../../axiosInstance";

export default {
  namespaced: true,
  state: {
    fetchedListing: [],
    fetchedGraphic: null,
    listingTotalItems: 0,
    isLoading: false,
  },
  getters: {
    getFetchedListing: (state) => state.fetchedListing,
    getFetchedGraphic: (state) => state.fetchedGraphic,
    getListingTotalItems: (state) => state.listingTotalItems,
    getIsLoading: (state) => state.isLoading,
  },
  mutations: {
    setFetchedListing(state, val) {
      state.fetchedListing = val;
    },
    setListingTotalItems(state, val) {
      state.listingTotalItems = val;
    },
    setIsLoading(state, val) {
      state.isLoading = val;
    },
    setFetchedGraphic(state, val) {
      state.fetchedGraphic = val;
    }
  },
  actions: {
    fetchListing({ commit, dispatch }, { options, search, only_trashed, entity_id, entity_type }) {
      commit('setIsLoading', true);

      const { sortBy, sortDesc, page, itemsPerPage } = options;

      let queryBuilder = KeyWord
        .$query()
        .filter('entity_id', FilterOperator.Equal, entity_id)
        .filter('entity_type', FilterOperator.Equal, entity_type)
        .lookFor(search);

      if (only_trashed) {
        queryBuilder.onlyTrashed();
      }

      if (sortBy[0]) {
        queryBuilder.sortBy(sortBy[0], sortDesc[0] ? SortDirection.Desc : SortDirection.Asc)
      }

      queryBuilder.search(itemsPerPage, page).then((response) => {
        if (response.length > 0) {
          commit('setListingTotalItems', response[0].$response.data.meta.total);
        } else {
          commit('setListingTotalItems', 0);
        }
        commit('setFetchedListing', response.map((item) => item.$attributes));
      }).catch(() => {
        dispatch("snackbar/showUnknownError", null, { root: true })
      }).finally(() => {
        commit("setIsLoading", false);
      })
    },
    storeItem({ commit, dispatch }, form) {
      commit('setIsLoading', true);

      return KeyWord.$query().store(form).then(() => {
        dispatch("snackbar/showSuccessfullySaved", null, { root: true });
      }).catch(() => {
        dispatch("snackbar/showSaveError", null, { root: true });
      })
    },
    async destroyItem({commit, dispatch}, {id, isForce}) {
      commit('setIsLoading', true);

      await KeyWord.$query().destroy(id, isForce).then(() => {
        dispatch('snackbar/showSuccessfullyDelete', null, {root: true});
      }).catch(() => {
        dispatch('snackbar/showDeleteError', null, {root: true})
      }).finally(() => {
        commit('setIsLoading', false);
      });
    },
    async restoreItem({commit, dispatch}, id) {
      commit('setIsLoading', true);

      await KeyWord.$query().restore(id).then(() => {
        dispatch('snackbar/showSuccessfullyRestore', null, {root: true})
      }).catch(() => {
        dispatch('snackbar/showErrorRestore', null, {root: true})
      }).finally(() => {
        commit('setIsLoading', false)
      });
    },
    async fetchGraphic({ commit, dispatch }, form) {
      axiosInstance.post('module/graph-data', form).then((response) => {
        commit('setFetchedGraphic', response.data);
      }).catch((e) => {
        console.log(e);
        dispatch("snackbar/showUnknownError", null, { root: true });
      })
    }
  }
}
