import axiosInstance from "../../../axiosInstance";

export default {
  namespaced: true,
  actions: {
    // eslint-disable-next-line no-unused-vars
    generatePatterns({ commit }, form) {
      return axiosInstance.post('pages/generate-patterns', form)
    }
  }
}
