import {Option} from "../../models/option";
import {FilterOperator} from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator";

export default {
  namespaced: true,
  state: {
    options: [],
  },
  getters: {
    fetchedOptions: (state) => state.options,
    checkOption: (state) => (option, entity) => {
      const splitted = entity.split('\\');
      const key = splitted[splitted.length - 1].toLowerCase() + "_" + option;

      const optionModel = state.options.find((item) => {
        return item.option_key === key;
      })

      return optionModel ? optionModel.option_value == 1 : false;
    },
  },
  mutations: {
    setOptions(state, val) {
      state.options = val;
    },
  },
  actions: {
    async fetchOptions({ commit, dispatch, rootGetters }, entity) {
      Option.$query()
        .filter('entity', FilterOperator.Like, entity)
        .filter('site_id', FilterOperator.Equal, rootGetters['SiteSwitcher/getSelectedSite'].id)
        .search(100000).then((response) => {
          commit('setOptions', response.map((el) => el.$attributes));
        }).catch(() => {
          dispatch('snackbar/showUnknownError', null, { root: true })
        })
    }
  },
}
