import axiosInstance from "../../../axiosInstance";
import {Site} from "../../../models/site";
import {Option} from "../../../models/option";

export default {
  namespaced: true,
  state: {
    fetchedListing: [],
    fetchedOptionsListing: [],
    listingTotalItems: 0,
    optionsListingTotalItems: 0,
    fetchedDetail: {},
    isSaveButtonDisabled: false,
    isListingLoad: false,
    fontTotalItems: 0,
    fonts: [],
    phrasesAndImages: {},
  },
  getters: {
    getFetchedListing: state => state.fetchedListing,
    getListingTotalItems: state => state.listingTotalItems,
    getFetchedDetail: state => state.fetchedDetail,
    getFonts: state => state.fonts,
    getFontTotalItems: state => state.fontTotalItems,
    getOptions: state => state.fetchedDetail.options,
    getOptionsListing: state => state.fetchedOptionsListing,
    getOptionsListingTotalItems: state => state.optionsListingTotalItems,
    isSaveButtonDisabled: state => state.isSaveButtonDisabled,
    isListingLoad: state => state.isListingLoad,
    getPhrasesAndImages: state => state.phrasesAndImages,
  },
  mutations: {
    setFetchedListing(state, listing) {
      state.fetchedListing = listing;
    },
    setListingTotalItems(state, totalItems) {
      state.listingTotalItems = totalItems;
    },
    setFetchedDetail(state, detail) {
      state.fetchedDetail = detail;
    },
    setIsSaveButtonDisabled(state, value) {
      state.isSaveButtonDisabled = value;
    },
    setIsListingLoad(state, value) {
      state.isListingLoad = value;
    },
    setFonts(state, value) {
      state.fonts = value;
    },
    setFontsTotalItems(state, value) {
      state.fontTotalItems = value;
    },
    setOptionsListing(state, value) {
      state.fetchedOptionsListing = value;
    },
    setOptionListingTotalItems(state, value) {
      state.listingTotalItems = value;
    },
    setPhrasesAndImages(state, value) {
      state.phrasesAndImages = value;
    }
  },
  actions: {
    fetchListing({commit}, {options, search}) {
      commit('setIsListingLoad', true)

      const {sortBy, sortDesc, page, itemsPerPage} = options;
      let data = {};
      search ? data.search = {value: search} : null;
      sortBy && sortBy[0] ? data.sort = [{field: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc'}] : null;

      axiosInstance
        .post(`/module/sites/search?limit=${itemsPerPage}&page=${page}`, data)
        .then((response) => {
          commit('setFetchedListing', response.data.data);
          commit('setListingTotalItems', response.data.meta.total);
        })

      commit('setIsListingLoad', false);
    },
    fetchOptionListing({commit, dispatch}, {options, entity, site_id}) {
      commit('setIsListingLoad', true);

      let data = {};

      const {sortBy, sortDesc, page, itemsPerPage} = options;

      sortBy && sortBy[0] ? data.sort = [{field: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc'}] : null;
      data.filters = [
        {
          "field": 'entity', operator: entity ? 'like' : '=', value: entity ? entity.split('\\').join('\\\\') : entity,
        },
        {
          "field": 'site_id', operator: '=', value: site_id,
        }
      ]

      axiosInstance.post(`/options/search?limit=${itemsPerPage}&page=${page}`, data)
        .then((response) => {
          commit('setOptionsListing', response.data.data);
          commit('setOptionListingTotalItems', response.data.meta.total);
        }).catch(() => {
          dispatch('snackbar/showUnknownError', null, { root: true })
        })

      commit('setIsListingLoad', false);
    },
    destroyItem({dispatch}, id) {
      Site.$query().destroy(id).then(() => {
        dispatch('snackbar/showSuccessfullyDelete', null, {root: true});
      }).catch(() => {
        dispatch('snackbar/showDeleteError', null, {root: true});
      });
    },
    storeItem({dispatch, commit}, form) {
      commit('setIsSaveButtonDisabled', true)

      Site.$query().store(form).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true});
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, {root: true});
      }).finally(() => {
        commit('setIsSaveButtonDisabled', false);
      });
    },
    fetchDetail({commit}, id) {
      Site.$query().find(id).then((response) => {
        commit('setFetchedDetail', response.$attributes);
      })
    },
    async updateItem({commit, dispatch}, {id, ...form}) {
      commit('setIsSaveButtonDisabled', true);

      await Site.$query().update(id, form).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true});
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, {root: true});
      }).finally(() => {
        commit('setIsSaveButtonDisabled', false);
      });
    },
    fetchFonts({commit}, {options, search}) {

      const {page, itemsPerPage} = options;
      let data = {};
      data.search = {value: search};

      axiosInstance
        .post(`/module/fonts/search?limit=${itemsPerPage}&page=${page}`, data)
        .then((response) => {
          commit('setFonts', response.data.data);
          commit('setFontsTotalItems', response.data.meta.total);
        })
    },
    storeOption({dispatch, commit}, form) {
      commit('setIsSaveButtonDisabled', true)

      Option.$query().store(form).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true});
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, {root: true});
      }).finally(() => {
        commit('setIsSaveButtonDisabled', false);
      });
    },
    async updateOption({commit, dispatch}, {option_key, ...form}) {
      commit('setIsSaveButtonDisabled', true);

      await Option.$query().update(option_key, form).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true});
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, {root: true});
      }).finally(() => {
        commit('setIsSaveButtonDisabled', false);
      });
    },
    async destroyOption({dispatch}, id) {
      await Option.$query().destroy(id).then(() => {
        dispatch('snackbar/showSuccessfullyDelete', null, {root: true});
      }).catch(() => {
        dispatch('snackbar/showDeleteError', null, {root: true});
      });
    },
    async fetchPhrasesAndImages({ commit }) {
      await axiosInstance.get('module/site/phrases-and-images').then((response) => {
        commit('setPhrasesAndImages', response.data);
      })
    }
  }
}
