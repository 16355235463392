<template>
  <div>
    <vxe-toolbar>
      <template #buttons>
        <vxe-button @click="addItem">Добавить</vxe-button>
        <vxe-button @click="removeItems">Удалить</vxe-button>
        <vxe-button @click="saveItems">Сохранить</vxe-button>
      </template>
    </vxe-toolbar>
    <vxe-grid
      border
      resizable
      keep-source
      empty-text="Данные отсутствуют"
      ref="table"
      id="toolbar_demo_1"
      height="500"
      :columns="tableColumn"
      :data="tableData"
      :loading="isLoading"
      @edit-actived="activateEdit"
      :edit-config="{ enabled: true, mode: 'cell', showStatus: true, trigger: 'click'  }"
      :column-config="{ isCurrent:true, isHover: true, selected: true }">
      <template #original_edit="{ row }">
        <vxe-input v-model="row.original">
          <template #suffix>
            <v-btn small icon color="primary" @click="tipTapModal = true">
              <v-icon>mdi-pen</v-icon>
            </v-btn>
          </template>
        </vxe-input>
      </template>
      <template #russian_edit="{ row }">
        <vxe-input v-model="row.russian">
          <template #suffix>
            <v-btn small icon color="primary" @click="tipTapModal = true">
              <v-icon>mdi-pen</v-icon>
            </v-btn>
          </template>
        </vxe-input>
      </template>
      <template #english_edit="{ row }">
        <vxe-input v-model="row.english">
          <template #suffix>
            <v-btn small icon color="primary" @click="tipTapModal = true">
              <v-icon>mdi-pen</v-icon>
            </v-btn>
          </template>
        </vxe-input>
      </template>
      <template #eng_description_edit="{ row }">
        <vxe-input v-model="row.eng_description">
          <template #suffix>
            <v-btn small icon color="primary" @click="tipTapModal = true">
              <v-icon>mdi-pen</v-icon>
            </v-btn>
          </template>
        </vxe-input>
      </template>
      <template #ru_description_edit="{ row }">
        <vxe-input v-model="row.ru_description">
          <template #suffix>
            <v-btn small icon color="primary" @click="tipTapModal = true">
              <v-icon>mdi-pen</v-icon>
            </v-btn>
          </template>
        </vxe-input>
      </template>
    </vxe-grid>

    <vxe-modal v-model="tipTapModal" title="Редактирование ячейки в tipTap" width="600" resize remember>
      <template #default>
        <tip-tap v-model="currentCol.value"/>
        <vxe-form title-align="right" title-width="60">
          <vxe-form-item align="center" span="24">
            <template #default>
              <vxe-button type="submit" status="primary" @click="submitTipTap">Сохранить</vxe-button>
              <vxe-button @click="closeEdit">Закрыть</vxe-button>
            </template>
          </vxe-form-item>
        </vxe-form>
      </template>
    </vxe-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Swal from "sweetalert2";
import TipTap from "../../../components/editor/TipTap";

export default {
  components: {TipTap},
  component: 'Index',
  data() {
    return {
      tableData: [],
      currentCol: {
        col: null,
        row: null,
        value: null,
        field: null,
      },
      tipTapModal: false,
      emptyObject: {
        id: null,
        original: null,
        russian: null,
        english: null,
      },
      tableColumn: [
        {type: 'checkbox', width: 50},
        {type: 'seq', width: 60},
        {field: 'original', title: 'Оригинал', editRender: {}, slots: {edit: 'original_edit'}},
        {field: 'russian', title: 'Русский', showOverflow: true, editRender: {}, slots: {edit: 'russian_edit'}},
        {
          field: 'ru_description',
          title: 'Описание на русском',
          showOverflow: true,
          editRender: {},
          slots: {edit: 'eng_description_edit'}
        },
        {field: 'english', title: 'Английский', showOverflow: true, editRender: {}, slots: {edit: 'english_edit'}},
        {
          field: 'eng_description',
          title: 'Описание на английском',
          showOverflow: false,
          editRender: {},
          slots: {edit: 'eng_description_edit'}
        },
      ]
    }
  },
  created() {
    this.fetchListing().then(() => {
      this.loadData();
    })
  },
  computed: {
    ...mapGetters({
      fetchedListing: "SystemPhrases/getFetchedListing",
      isLoading: "SystemPhrases/isLoading",
    }),
    disabledEditButton() {
      return !(this.currentCol.row && this.currentCol.col && this.currentCol.field);
    }
  },
  methods: {
    ...mapActions({
      fetchListing: "SystemPhrases/fetchListing",
      saveData: "SystemPhrases/saveData",
    }),
    loadData() {
      this.tableData = this.fetchedListing;
    },
    async addItem() {
      const {row: newRow} = await this.$refs.table.insertAt(JSON.parse(JSON.stringify(this.emptyObject)), -1);
      await this.$refs.table.setActiveCell(newRow, 'original');
    },
    async removeItems() {
      if (this.$refs.table.getCheckboxRecords().length > 0) {
        Swal.fire({
          title: 'Вы уверены?',
          text: "Вы не сможете отменить это!",
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Да, удалить это!',
          cancelButtonText: 'Отменить'
        }).then((result) => {
          if (result.value) {
            this.$refs.table.removeCheckboxRow();
          }
        });
      }
    },
    async saveItems() {
      this.saveData(this.$refs.table.getRecordset()).then(() => {
        this.fetchListing().then(() => {
          this.loadData();
        })
      });
    },
    activateEdit(record) {
      this.currentCol.col = record.$columnIndex;
      this.currentCol.row = record.$rowIndex;
      this.currentCol.field = record.column.field;
      this.currentCol.value = record.row[record.column.field];
    },
    closeEdit() {
      this.currentCol.col = null;
      this.currentCol.row = null;
      this.currentCol.field = null;
      this.currentCol.value = null;

      this.tipTapModal = false;
    },
    submitTipTap() {
      const { fullData } = this.$refs.table.getTableData();
      fullData[this.currentCol.row][this.currentCol.field] = JSON.parse(JSON.stringify(this.currentCol.value));

      this.closeEdit();
    },
  }
}
</script>

<style scoped>

</style>
