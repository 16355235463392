var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('h1',[_vm._v("Изменения")])])],1),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"label":"Операция","items":_vm.operations},model:{value:(_vm.query.operation),callback:function ($$v) {_vm.$set(_vm.query, "operation", $$v)},expression:"query.operation"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.entitiesList,"label":"Тип сущности"},model:{value:(_vm.query.entity_type),callback:function ($$v) {_vm.$set(_vm.query, "entity_type", $$v)},expression:"query.entity_type"}})],1),(false)?_c('v-col',[_c('v-text-field',{attrs:{"label":"Поиск по названию сущности"},model:{value:(_vm.query.entity_search),callback:function ($$v) {_vm.$set(_vm.query, "entity_search", $$v)},expression:"query.entity_search"}})],1):_vm._e(),_c('v-col',[_c('v-select',{attrs:{"items":_vm.usersList,"item-value":"id","item-text":"name","label":"Пользователь"},model:{value:(_vm.query.causer_id),callback:function ($$v) {_vm.$set(_vm.query, "causer_id", $$v)},expression:"query.causer_id"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"server-items-length":_vm.totalItems,"page":_vm.query.page,"items-per-page":_vm.query.per_page,"disable-sort":""},on:{"update:page":function($event){return _vm.$set(_vm.query, "page", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.query, "per_page", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.query, "per_page", $event)}},scopedSlots:_vm._u([{key:"item.operation",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item),"text-color":"white"}},[_vm._v(" "+_vm._s(item.operation)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.operation === 'Обновление')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.showModal = true; _vm.selectedItem = item}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Просмотр изменений")])]):_vm._e()]}}])})],1)],1),_c('v-dialog',{attrs:{"width":"900"},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v(" Измененные поля ")]),_c('v-card-text',[_c('v-container',[_c('v-expansion-panels',_vm._l((_vm.selectedItem.changes),function(attribute,key){return _c('v-expansion-panel',{key:key},[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(key)+" ")]),_c('v-expansion-panel-content',[_c('v-row',[_c('v-col',{staticStyle:{"background-color":"rgb(255, 0, 0, 0.1)"}},[_vm._v(" "+_vm._s(attribute.old)+" ")]),_c('v-col',{staticStyle:{"background-color":"rgb(0, 255, 0, 0.1)"}},[_vm._v(" "+_vm._s(attribute.new)+" ")])],1)],1)],1)}),1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }