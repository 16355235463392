<template>
  <div class="wysiwyg-editor">
    <tiptap-vuetify
      v-model="content"
      :extensions="extensions"
      :placeholder="label"
    />
  </div>
</template>

<script>
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'


export default {
  name: 'WysiwygEditor',
  components: {
    TiptapVuetify,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: ''
    },
  },
  data: () => ({
    content: null,
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [Heading, {
        options: {
          levels: [1, 2, 3]
        }
      }],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak
    ]
  }),
  watch: {
    value(value) {
      if (value !== this.content) {
        this.content = value;
      }
    },
    content(content) {
      if (content !== this.value) {
        this.onInput();
      }
    },
  },
  methods: {
    onInput() {
      this.$emit('input', this.content);
    },
  },
}
</script>

<style lang="scss">
.wysiwyg-editor {
  .ProseMirror {
    padding: 20px;
  }
}
</style>
