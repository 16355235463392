import Login from '../../views/auth/Login'
import Autologin from '../../views/auth/Autologin';
// import Register from '../../views/auth/Register';
import Email from '../../views/auth/Email';

const meta = {
  layout: 'auth-layout'
}

export default [
  {
    component: Login,
    name: 'auth-login',
    path: '/auth/login',
    meta,
  },
  {
    component: Autologin,
    name: 'auth-autologin',
    path: '/auth/autologin/:token',
    meta,
  },
  // {
  //   component: Register,
  //   name: 'auth-register',
  //   path: '/auth/register',
  //   meta,
  // },
  {
    component: Email,
    name: 'auth-email',
    path: '/auth/email',
    meta,
  },
]
