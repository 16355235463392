<template>
  <div :id="holder" />
</template>

<script>
import EditorJS from '@editorjs/editorjs'

export default {
  name: "VueEditor",
  props: {
    holder: {
      type: String,
      default: () => 'vue-editor-js',
    },
    config: {
      type: Object,
      require: true,
    },
    initialized: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    editor: null,
  }),
  mounted() {
    this.initEditor();
  },
  methods: {
    initEditor() {
      this.destroyEditor();
      this.editor = new EditorJS({
        holder: document.querySelector(`#${this.holder}`)
          || document.querySelector('#vue-editor-js'),
        ...this.config,
      })
      this.initialized(this.editor);
    },
    destroyEditor() {
      if (this.editor) {
        this.editor.destroy();
        this.editor = null;
      }
    },
  },
}
</script>

<style>
/* div:not(.ce-settings) .ce-popover__items .ce-popover__item-icon path {
  stroke: transparent;
} */
</style>
