var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"down-top-padding",attrs:{"fluid":""}},[_c('BaseBreadcrumb',{attrs:{"title":_vm.page.title,"icon":_vm.page.icon,"breadcrumbs":_vm.breadcrumbs}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"options":_vm.options,"items":_vm.fetchedListing,"server-items-length":_vm.listingTotalItems},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Языки")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Добавление языка")])]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Язык"},model:{value:(_vm.form.language),callback:function ($$v) {_vm.$set(_vm.form, "language", $$v)},expression:"form.language"}}),_c('v-text-field',{attrs:{"label":"ISO"},model:{value:(_vm.form.iso_format),callback:function ($$v) {_vm.$set(_vm.form, "iso_format", $$v)},expression:"form.iso_format"}}),_c('v-text-field',{attrs:{"label":"Флаг"},model:{value:(_vm.form.flag_icon),callback:function ($$v) {_vm.$set(_vm.form, "flag_icon", $$v)},expression:"form.flag_icon"}}),_c('v-switch',{attrs:{"label":"Являеться языком по умолчанию"},model:{value:(_vm.form.is_default),callback:function ($$v) {_vm.$set(_vm.form, "is_default", $$v)},expression:"form.is_default"}})],1),_c('v-card-actions',[_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"block":"","color":"primary"},on:{"click":_vm.save}},[_vm._v(" Сохранить ")])],1),_c('v-col',[_c('v-btn',{attrs:{"block":"","color":"primary"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Закрыть ")])],1)],1)],1)],1)],1)],1)]},proxy:true},{key:"item.is_default",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.is_default ? 'green' : 'red'}},[_vm._v("mdi-circle")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":"","color":"primary"}},[_c('v-icon',{on:{"click":function($event){return _vm.edit(item)}}},[_vm._v("mdi-pen")])],1),_c('v-btn',{attrs:{"icon":"","small":"","color":"error"}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }