<template>
  <div class="google_preview">
    <div class="card-seo-google">
      <a class="card-seo-google__title">{{ title }}</a>
      <div class="card-seo-google__url">
        <span class="card-seo-google__url-title js-preview-domain">{{ getHostname }}</span>&nbsp;<span class="card-seo-google__url-arrow"></span>
      </div>
      <span class="card-seo-google__description js-preview-description">{{ description }}</span>
    </div>
  </div>
</template>

<script>
import seoMixin from "@/mixins/seoMixin";

export default {
  mixins: [
    seoMixin
  ],
  props: {
    form: {
      type: Object,
    },
    entity: {
      type: Object,
    },
  },
  data() {
    return {
      title: '',
      description: '',
      descriptionLength: 215,
    }
  },
  computed: {
    displayShowMoreButton() {
      return this.form.meta_description?.length > this.descriptionLength;
    },
    getPreviewUrl() {
      if (!this.entity.preview_url) return '';

      let url = new URL(this.entity.preview_url);

      return this.cutString(url.pathname, 50 - this.getHostname.length );
    },
    getHostname() {
      if (!this.entity.preview_url) return '';

      let url = this.entity.preview_url.replace(/http(.*?)\/\//, '');
      let hostname = url.match(/(.*?)\//)[1];

      return hostname;
    },
  },
  methods: {
    cutString(string, count) {
      if (string.length > count) {
        return string.substring(0, count) + '...';
      }

      return string;
    },
  },
  watch: {
    'form.meta_description': {
      handler(val) {
        let value = val || this.entity.lead || this.entity.description;

        if (typeof value != 'string') return;

        this.description = this.cutString(value, this.descriptionLength) || this.entity.lead;
      },
      immediate: true,
    },
  }
}
</script>

<style lang="scss" scoped>
  .google_preview {
    margin-bottom: 15px;
  }

  .card-seo-google {
    width: 600px;
    margin: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0;

    .card-seo-google__title {
      font-size: 18px;
      line-height: 1.2;
      display: block;
      letter-spacing: normal;
      color: #1a0dab;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .card-seo-google__title:hover {
      text-decoration: underline;
    }

    .card-seo-google__url-title {
      position: relative;
      top: -2px;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: normal;
      color: #006621;
    }

    .card-seo-google__url-arrow {
      vertical-align: middle;
      margin-top: -4px;
      margin-left: 3px;
      border-width: 5px 4px 0 4px;
      border-style: solid;
      border-color: #006621 transparent;
      display: inline-block;
    }

    .card-seo-google__description {
      display: block;
      color: #545454;
      font-size: 13px;
      line-height: 1.4;
      word-wrap: break-word;
    }
  }
</style>
