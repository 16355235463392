<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <block-form @onSubmit="submit"/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import BlockForm from '../../../components/blocks/BlockForm';
import {mapActions} from "vuex";

export default {
  name: 'Create',
  components: {BlockForm},
  data: () => {
    return {
      page: {
        title: "Создание блока"
      },
      breadcrumbs: [
        {
          text: "Админ панель",
          disabled: false,
          to: '/'
        },
        {
          text: "Блоки",
          disabled: true,
          to: '/blocks'
        },
        {
          text: "Создание",
          disabled: true,
          to: '/blocks/create'
        },
      ]
    }
  },
  methods: {
    ...mapActions({
      storeItem: 'Blocks/storeItem',
    }),
    async submit({form, mediaIds, isNeedRedirect}) {
      if (mediaIds) {
        form.mediaIds = mediaIds;
      }

      const payload = {
        form: form,
        router: this.$router,
        isNeedRedirect: isNeedRedirect
      }

      await this.storeItem(payload);
    },
  },
};
</script>
