<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <block-form :block="fetchedDetail" @onSubmit="submit"/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import BlockForm from '../../../components/blocks/BlockForm';
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'Edit',
  components: {BlockForm},
  data: () => {
    return {
      page: {
        title: "Редактирование блока"
      },
      breadcrumbs: [
        {
          text: "Админ панель",
          disabled: false,
          to: '/'
        },
        {
          text: "Блоки",
          disabled: true,
          to: '/blocks'
        },
        {
          text: "Редактирование",
          disabled: true,
          to: '/blocks/edit'
        },
      ]
    }
  },
  async created() {
    await this.fetchDetail(this.$route.params.id);
  },
  computed: {
    ...mapGetters({
      fetchedDetail: 'Blocks/getFetchedDetail',
    })
  },
  methods: {
    ...mapActions({
      fetchDetail: 'Blocks/fetchDetail',
      updateItem: 'Blocks/updateItem',
    }),
    async submit({form, mediaIds, isNeedRedirect}) {
      if (mediaIds) {
        form.mediaIds = mediaIds;
      }

      await this.updateItem(form).then(() => {
        if (isNeedRedirect) {
          this.$router.push({ name: 'blocks-index' });
        }
      });
    },
  },
  watch: {
    'fetchedDetail.id': {
      handler(newTitle) {
        this.page.title +=  ` "${newTitle}"`;
      }
    },
  }
};
</script>

<style scoped>

</style>
