<template>
  <v-app id="inspire" :class="{ horizontalstyle: setHorizontalLayout }">
    <!-- ---------------------------------- -->
    <!--- Vertical Header part -->
    <!-- ---------------------------------- -->
    <VerticalHeader
      v-if="!setHorizontalLayout"
      v-model="expandOnHover"
    ></VerticalHeader>
    <!-- ---------------------------------- -->
    <!--- Horizontal Header part -->
    <!-- ---------------------------------- -->
    <HorizontalHeader v-else></HorizontalHeader>

    <v-main>
      <v-container fluid class="page-wrapper">
        <router-view />
        <!--- Custom bar -->
<!--        <v-btn-->
<!--          bottom-->
<!--          color="success"-->
<!--          dark-->
<!--          fab-->
<!--          fixed-->
<!--          right-->
<!--          @click.stop="setCustomizerDrawer(!Customizer_drawer)"-->
<!--        >-->
<!--          <v-icon>mdi-cog</v-icon>-->
<!--        </v-btn>-->
      </v-container>
    </v-main>
    <!-- ---------------------------------- -->
    <!--- Vertical Sidebar  part -->
    <!-- ---------------------------------- -->
    <VerticalSidebar
      v-if="!setHorizontalLayout"
      :expand-on-hover.sync="expandOnHover"
    ></VerticalSidebar>
    <!-- ---------------------------------- -->
    <!--- Horizontal Sidebar part -->
    <!-- ---------------------------------- -->
    <HorizontalSidebar v-else></HorizontalSidebar>
    <!-- ---------------------------------- -->
    <!--- Customizer part -->
    <!-- ---------------------------------- -->
    <Customizer v-model="expandOnHover"></Customizer>
    <!-- ---------------------------------- -->
    <!--- Vertical Footer part -->
    <!-- ---------------------------------- -->
    <Footer v-if="!setHorizontalLayout"></Footer>
    <!-- ---------------------------------- -->
    <!--- Horizontal Footer part -->
    <!-- ---------------------------------- -->
    <HorizontalFooter v-else></HorizontalFooter>


<!--    <app-header />-->
<!--    <v-navigation-drawer permanent app>-->
<!--      <sidebar-menu />-->
<!--    </v-navigation-drawer>-->

<!--    <v-main>-->
<!--      <div class="pa-4 bg-cnt" >-->
<!--        <router-view />-->
<!--      </div>-->
<!--    </v-main>-->
    <snackbar/>
  </v-app>
</template>

<script>
// import SidebarMenu from "./main/SidebarMenu";
// import AppHeader from "@/layouts/main/AppHeader";
import Snackbar from "@/components/snackbar";

import HorizontalHeader from "./full-layout/horizontal-header/HorizontalHeader";
import VerticalHeader from "./full-layout/vertical-header/VerticalHeader";
import HorizontalSidebar from "./full-layout/horizontal-sidebar/HorizontalSidebar";
import VerticalSidebar from "./full-layout/vertical-sidebar/VerticalSidebar";
import Footer from "./full-layout/footer/Footer";
import HorizontalFooter from "./full-layout/horizontal-footer/HorizontalFooter";
import Customizer from "./full-layout/customizer/Customizer";
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "main-layout",

  components: {
    Snackbar,
    // SidebarMenu,
    // AppHeader,
    HorizontalHeader,
    VerticalHeader,
    HorizontalSidebar,
    VerticalSidebar,
    Footer,
    HorizontalFooter,
    Customizer,
  },

  async created() {
    await this.fetchPhrasesAndImages();
  },

  props: {
    source: String,
  },
  data: () => ({
    expandOnHover: false,
  }),
  computed: {
    ...mapState(["Customizer_drawer", "setHorizontalLayout"]),
  },

  methods: {
    ...mapMutations({
      setCustomizerDrawer: "SET_CUSTOMIZER_DRAWER",
    }),
    ...mapActions({
      fetchPhrasesAndImages: "Sites/fetchPhrasesAndImages",
    })
  },
};
</script>

<style scoped></style>
