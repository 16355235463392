<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Создание элемента фасетной таксономии</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <collection-item-form :errors="errors" @onSubmit="submit"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CollectionItemForm from '../../../../components/collections/CollectionItemForm';
import { CollectionItem } from '../../../../models/collectionItem';

export default {
  name: 'Create',
  components: { CollectionItemForm },
  data: () => ({
    collection_id: null,
    errors: {},
  }),
  methods: {
    async submit(form) {
      try {
        this.errors = {};
        await CollectionItem.$query().store(form);
        await this.$router.push('/collection-items');
      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors
        }
        throw e;
      }
    },
  },
};
</script>
