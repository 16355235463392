import axiosInstance from "../../../axiosInstance";
import {Page} from "../../../models/page";
import {Media} from "../../../models/media";

export default {
  namespaced: true,
  state: {
    fetchedListing: [],
    fetchedTreeListing: {},
    listingTotalItems: 0,
    fetchedDetail: {},
    fetchedDetailMedia: {},
    isSaveButtonDisabled: false,
    isListingLoad: false,
    pagesWithOutParent: [],
    fetchedUserNotes: [],    
  },
  getters: {
    getFetchedListing: state => state.fetchedListing,
    getFetchedTreeListing: state => state.fetchedTreeListing,
    getFetchedDetail: state => state.fetchedDetail,
    getListingTotalItems: state => state.listingTotalItems,
    getChild: (state) => {
      const child = state.fetchedDetail.children

      if (child) {
        return child.sort((a, b) => {
          return (a.position === null) - (b.position === null) || +(a.position > b.position) || -(a.position < b.position);
        })
      } else {
        return [];
      }
    },
    getPagesWithOutParent: state => state.pagesWithOutParent,
    getFetchedDetailMedia: state => state.fetchedDetailMedia,
    isSaveButtonDisabled: state => state.isSaveButtonDisabled,
    isListingLoad: state => state.isListingLoad,
    getFetchedUserNotes: state => state.fetchedUserNotes,
  },
  mutations: {
    setFetchedListing(state, listing) {
      state.fetchedListing = listing;
    },
    setFetchedTreeListing(state, listing) {
      state.fetchedTreeListing = listing;
    },
    setFetchedDetail(state, detail) {
      state.fetchedDetail = detail;
    },
    setListingTotalItems(state, totalItems) {
      state.listingTotalItems = totalItems;
    },
    setIsSaveButtonDisabled(state, value) {
      state.isSaveButtonDisabled = value;
    },
    setIsListingLoad(state, value) {
      state.isListingLoad = value;
    },
    setPagesWithOutParent(state, value) {
      state.pagesWithOutParent = value;
    },
    setFetchedDetailMedia(state, value) {
      state.fetchedDetailMedia = value;
    },
    setFetchedUserNotes(state, listing) {
      state.fetchedUserNotes = listing;
    },
  },
  actions: {
    fetchListing({commit}, {options, search, only_trashed}) {
      commit('setIsListingLoad', true);

      const {page, itemsPerPage, sortBy, sortDesc} = options;
      const onlyTrashed = only_trashed ? `&only_trashed=1` : '';
      let data = {};

      data.filters = [
        {
          field: 'parent_id',
          operator: '=',
          value: null,
        }
      ]

      sortBy && sortBy[0] ? data.sort = [{field: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc'}] : null;

      search ? data.search = {value: search} : null;

      axiosInstance.post(`pages/search?limit=${itemsPerPage}&page=${page}${onlyTrashed}`, data)
        .then((response) => {
          commit('setFetchedListing', response.data.data);
          commit('setListingTotalItems', response.data.meta.total);
        });

      commit('setIsListingLoad', false);
    },
    fetchTreeListing({commit}) {

      axiosInstance.get(`categories/flat-tree`)
        .then((response) => {
          commit('setFetchedTreeListing', response.data);
        });

    },
    async destroy({dispatch}, id) {
      await axiosInstance.delete(`pages/${id}`).then(() => {
        dispatch('snackbar/showSuccessfullyDelete', null, {root: true});
      }).catch(() => {
        dispatch('snackbar/showDeleteError', null, {root: true});
      });
    },
    async fetchDetail({commit, state}, id) {
      await Page.$query().find(id).then((response) => {
        commit('setFetchedDetail', response.$attributes);
      });

      const media = await Media.$query()
        .filter('entity', '=', 'App\\Models\\Page')
        .filter('entity_id', '=', state.fetchedDetail.id)
        .search(1);
      commit('setFetchedDetailMedia', media && media.length > 0 ? media[0].$attributes : null);
    },
    async update({commit, dispatch}, {form, mediaIds, media}) {
      commit('setIsSaveButtonDisabled', true);

      if (mediaIds) {
        form.mediaIds = mediaIds;
      }

      if (media && media.id) {
        await Media.$query().update(media.id, {...media, entity_id: form.id});
      } else if (media) {
        await Media.$query().store({...media, entity_id: form.id});
      }

      await Page.$query().update(form.id, form).then((response) => {
        response.roles().sync(form.roles_data).then(() => {
          dispatch('snackbar/showSuccessfullySaved', null, {root: true});
        })
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, {root: true});
      });

      commit('setIsSaveButtonDisabled', false);
    },
    async store({commit, dispatch}, {form, mediaIds}) {
      commit('setIsSaveButtonDisabled', true);

      if (mediaIds) {
        form.mediaIds = mediaIds;
      }

      let page = {};

      await Page.$query().store(form).then((response) => {
        page = response.$attributes;
        dispatch('snackbar/showSuccessfullySaved', null, {root: true});
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, {root: true});
      }).finally(() => {
        commit('setIsSaveButtonDisabled', false);
      });

      return page;
    },
    async storeSort({commit, dispatch}, payload) {
      commit('setIsSaveButtonDisabled', true);

      let formattedData = {};

      payload.forEach((elem, index) => {
        formattedData[elem.id] = {position: index}
      });

      await axiosInstance.patch('/pages/batch', {resources: formattedData}).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true});
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, {root: true});
      });

      commit('setIsSaveButtonDisabled', false);
    },
    fetchPagesWithoutParent({state, commit}) {
      axiosInstance.get(`pages/withoutRelation?id=${state.fetchedDetail.id}`).then((response) => {
        commit('setPagesWithOutParent', response.data.data);
      })
    },
    async attachPages({commit, dispatch, state}, payload) {
      commit('setIsSaveButtonDisabled', true);

      let formattedData = {};
      payload.forEach(elem => {
        formattedData[elem] = {parent_id: state.fetchedDetail.id}
      });

      await axiosInstance.patch('/pages/batch', {resources: formattedData}).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true});
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, {root: true});
      });

      commit('setIsSaveButtonDisabled', false);
    },
    async removeChildren({commit, dispatch}, id) {
      commit('setIsSaveButtonDisabled', true);

      await Page.$query().update(id, {parent_id: null, position: null}).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true});
      }).catch(() => {
        dispatch('snackbar/showSaveError', null, {root: true});
      });

      commit('setIsSaveButtonDisabled', false);
    },
    async submitCollections({dispatch, state}, {items}) {
      await axiosInstance.post(`pages/${state.fetchedDetail.id}/collection-items/sync`, {items: items}).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true})
        dispatch('fetchDetail', state.fetchedDetail.id)
      }).catch(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true});
      })
    },
    async submitMainCollections({dispatch, state}, {items}) {
      await axiosInstance.post(`pages/${state.fetchedDetail.id}/collection-items/make-main`, {items: items}).then(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true})
        dispatch('fetchDetail', state.fetchedDetail.id)
      }).catch(() => {
        dispatch('snackbar/showSuccessfullySaved', null, {root: true});
      })
    },
    // eslint-disable-next-line no-unused-vars
    async restoreItem({commit}, id) {
      await Page.$query().restore(id);
    },
    async makeCopy({dispatch}, id) {
      await axiosInstance.get(`/pages/${id}/make-copy`).then(() => {
        dispatch('snackbar/showSuccessCopy', null, { root: true });
      }).catch(() => {
        dispatch('snackbar/showErrorCopy', null, { root: true });
      });
    },
    fetchUserNotes({commit}) {
      axiosInstance.get(`/pages/get-user-notes`)
        .then((response) => {
          commit('setFetchedUserNotes', response.data);
        });

    },
  }
}
