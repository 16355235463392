import { Model } from "@tailflow/laravel-orion/lib/model";
import {BelongsToMany} from "@tailflow/laravel-orion/lib/drivers/default/relations/belongsToMany";
import { Partner } from "./partner"

export class Person extends Model<{
    first_name: string,
    last_name: string,
    patronymic: string,
    post: string,
    created_at: string,
    updated_at: string,
    preview_image?: object,
}> {

    $resource(): string {
        return "persons";
    }

    public partners(): BelongsToMany<Partner> {
        return new BelongsToMany<Partner>(Partner, this);
    }
}
