<template>
  <div>
    <v-row>
      <v-col cols="8">
        <v-row>
          <v-col>
            <h3>Дочерние</h3>
          </v-col>
          <v-col>
            <v-checkbox
              v-model="only_trashed"
              label="Показать удаленные"
              name="only_trashed"
              value="1"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="items"
            >
              <template v-slot:item.actions="{ item }">
                <v-btn
                  icon
                  v-if="item.preview_url"
                  :href="item.preview_url"
                  target="_blank"
                >
                  <v-icon
                    color="primary"
                  >
                    mdi-eye
                  </v-icon>
                </v-btn>
                <v-icon
                  class="mr-2"
                  color="primary"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  class="mr-2"
                  color="primary"
                  @click="copyItem(item)"
                >
                  mdi-content-copy
                </v-icon>
                <v-icon
                  v-if="item.deleted_at"
                  color="green"
                  @click="restoreItem(item)"
                >
                  mdi-backup-restore
                </v-icon>
                <v-icon
                  v-else
                  color="red"
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4">
        <h3>Форма</h3>
        <v-form>
          <v-tabs
            v-model="tab"
            background-color="transparent"
            grow
            class="mb-5"
          >
            <v-tab>
              Основное
            </v-tab>
            <template v-if="form.id">
              <v-tab>
                SEO
              </v-tab>
              <v-tab>
                SMM
              </v-tab>
            </template>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-text-field
                v-model="form.title"
                :error-messages="errors.title"
                label="Название"
                name="title"
                required
              ></v-text-field>
              <v-text-field
                v-model="form.slug"
                :error-messages="errors.slug"
                label="Slug"
                name="slug"
                required
                :disabled="form.id"
              ></v-text-field>
              <v-textarea
                v-model="form.description"
                :error-messages="errors.description"
                label="Описание"
                name="description"
                required
              ></v-textarea>
            </v-tab-item>
            <template v-if="form.id">
              <v-tab-item>
                <seo-form
                  :entity="form"
                  :errors="errors"
                  :hide-button="true"
                />
              </v-tab-item>
              <v-tab-item>
                <smm-form
                  :entity="form"
                  :errors="errors"
                  :media="form.loaded_medias"
                  :hide-button="true"
                />
              </v-tab-item>
            </template>
          </v-tabs-items>
          <v-btn
            v-if="form.id && isModuleBlockEnabled"
            :href="`${clientUrl}/landing/${form.parent.slug}/${form.slug}?edit_mode=true&admin_url=${this.$route.fullPath}`"
            color="primary"
            class="mr-2 mt-1 mb-1"
            @click="toBlocks"
          >
            Редактирование блоков
          </v-btn>
          <v-btn
            color="primary"
            :disabled="isSaveDisabled"
            :loading="isSaveDisabled"
            @click="submit({mediaIds: null})"
          >
            Сохранить
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {Landing} from "../../models/landing";
import SeoForm from "../SEOForm";
import SmmForm from "../SMMForm";
import axiosInstance from "../../axiosInstance";
import slugify from "slugify";
import optionsMixin from "../../mixins/optionsMixin";

export default {
  name: "LandingChildren",
  mixins: [optionsMixin],
  components: {
    SeoForm,
    SmmForm,
  },
  props: {
    landing: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    form: {
      title: null,
      slug: null,
      description: null,
      language: null,
      parent_id: null,
      blocks: [],
      blockValues: [],
      mediaIds: [],
    },
    languages: [
      {text: 'Русский', value: 'ru'},
      {text: 'English', value: 'en'},
    ],
    layouts: [
      'type1',
      'type2'
    ],
    clientUrl: process.env.VUE_APP_CLIENT_URL,
    only_trashed: null,
    headers: [
      {text: 'Id', value: 'id'},
      {text: 'Название', value: 'title'},
      {text: 'Slug', value: 'slug'},
      {text: 'Описание', value: 'description'},
      {text: 'Язык страницы', value: 'language'},
      {text: 'Дата создания', value: 'created_at'},
      {text: 'Дата обновления', value: 'updated_at'},
      {text: '', value: 'actions'},
    ],
    items: [],
    isSaveDisabled: false,
    tab: 1,
  }),
  created() {
    this.form.parent_id = this.landing.id;
    this.get();
  },
  computed: {
    isModuleBlockEnabled() {
      return process.env.VUE_APP_M_BLOCKS === 'true';
    }
  },
  mounted(){
    this.form.language = this.language;
  },
  methods: {
    async submit({mediaIds}) {
      this.isSaveDisabled = true;

      try {
        this.errors = {};

        if (mediaIds) {
          this.form.mediaIds = mediaIds;
        }

        const currentForm = JSON.parse(JSON.stringify(this.form));

        if (currentForm.id) {
          await Landing.$query().update(currentForm.id, currentForm);
        } else {
          await Landing.$query().store(currentForm);
        }

        this.form = {
          slug: null,
          description: null,
          language: this.language,
          parent_id: this.landing.id,
          blocks: [],
          blockValues: [],
          mediaIds: [],
        }

        await this.get();
      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors
        }

        throw e;
      }

      this.isSaveDisabled = false;
    },
    addTranslate(item) {
      this.$router.push({name: 'landings-create', params: {parent_id: item.id}})
    },
    editItem(item) {
      this.find(item.id);
    },
    async deleteItem(item) {
      this.$confirm('Вы уверены?', 'Удаление').then(() => {
        Landing.$query().destroy(item.id, 0);
        this.get();
      });
    },
    async restoreItem(item) {
      await Landing.$query().restore(item.id);
      await this.get();
    },
    async copyItem(item) {
      await axiosInstance.get(`/module/landings/${item.id}/make-copy`);
      await this.get();
    },
    toBlocks() {
      this.$store.dispatch('block/updateForm', this.form);
    },
    async get() {
      let items = [];

      if (this.only_trashed) {
        items = await Landing.$query()
          .filter('parent_id', '=', this.landing.id)
          .onlyTrashed()
          .search(10000);
      } else {
        items = await Landing.$query()
          .filter('parent_id', '=', this.landing.id)
          .search(10000);
      }

      this.items = items.map(item => item.$attributes);
    },
    async find(id) {
      const landing = await Landing.$query().find(id);
      this.form = landing.$attributes;
      this.form.language = this.language;
    },
  },
  watch: {
    only_trashed: {
      handler() {
        this.get();
      }
    },
    'form.title': {
      handler(value) {
        if (value && !this.form.id) {
          this.form.slug = slugify(value.toLowerCase()).match(/[a-z]|[0-9]|[-]/gm).join('');
        }
      }
    },
  },
}
</script>
