import { Model } from "@tailflow/laravel-orion/lib/model";

export class FAQ extends Model<{
    id: string,
    question: string,
    answer: string,
    description: string,
    created_at: string,
    updated_at: string,
    image?: object,
}> {

    $resource(): string {
        return "faqs";
    }
}
