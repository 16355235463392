<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <article-form
              :errors="errors"
              @onSubmit="submit"
            />
          </v-col>
        </v-row>
        <v-snackbar
          v-model="snackbarSuccess"
          :timeout="timeout"
          color="success"
        >
          Сохранено

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarSuccess = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar
          v-model="snackbarError"
          :timeout="timeout"
          color="error"
        >
          {{ this.errors }}

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarError = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ArticleForm from '../../../components/articles/ArticleForm';
import { Article } from '../../../models/article';
import { Media } from '../../../models/media';

export default {
  name: 'Create',
  components: { ArticleForm },
  data: () => ({
    article_id: null,
    errors: {},
    snackbarSuccess: false,
    snackbarError: false,
    timeout: 2000,
    page: {
      title: "Создание статьи"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Медиа",
        disabled: false,
        to: '/articles'
      },
      {
        text: "Создание",
        disabled: true,
        to: '/create'
      }
    ],
  }),
  mounted() {
    if (this.$route.params.article_id) {
      this.article_id = this.$route.params.article_id
    }
  },
  methods: {
    async submit({ form, media, mediaIds }) {
      try {
        this.errors = {};
        const storeData = { ...form, article_id: this.article_id };
        if (mediaIds) {
          storeData.mediaIds = mediaIds;
        }
        const article = await Article.$query().store(storeData);
        await article.persons().sync(form.selected_authors || []);
        await Media.$query().store({...media, entity_id: article.$attributes.id});
        await this.$router.push({ name: 'articles-edit', params: { id: article.$attributes.id } });
        this.snackbarSuccess = true;
      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors
          this.snackbarError = true;
        }
        throw e;
      }
    },
  },
};
</script>
