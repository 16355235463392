import { Model } from "@tailflow/laravel-orion/lib/model";

export class Lesson extends Model<{
    user_id: number,
    title: string,
    description: string,
    slug: string,
    language: string,
    created_at: string,
    updated_at: string,
    deleted_at: string,
}> {

    $resource(): string {
        return "module/lessons";
    }

}
