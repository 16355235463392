import {ActivityLog} from "../../../models/activityLog";
import axiosInstance from "../../../axiosInstance";
import {SortDirection} from "@tailflow/laravel-orion/lib/drivers/default/enums/sortDirection";

export default {
  namespaced: true,
  state: {
    fetchedListing: [],
    usersList: [],
    entitiesList: [],
    listingTotalItems: 0,
  },
  getters: {
    getFetchedListing: (state) => state.fetchedListing,
    getListingTotalItems: (state) => state.listingTotalItems,
    getUsersList: (state) => state.usersList,
    getEntitiesList: (state) => state.entitiesList,
  },
  mutations: {
    setFetchedListing(state, value) {
      state.fetchedListing = value;
    },
    setListingTotalItems(state, value) {
      state.listingTotalItems = value;
    },
    setUsersList(state, value) {
      state.usersList = value;
    },
    setEntitiesList(state, value) {
      state.entitiesList = value;
    }
  },
  actions: {
    async fetchListing({ dispatch, commit }) {
      await ActivityLog.$query().sortBy('created_at', SortDirection.Desc).search(10).then((response) => {
        commit('setFetchedListing', response.map((item) => item.$attributes));
      }).catch(() => {
        dispatch("snackbar/showUnknownError", null, { root: true });
      })
    },
    async fetchUserList({ dispatch, commit }) {
      await axiosInstance.get('activity-logs/causers-list').then((response) => {
        commit('setUsersList', response.data);
      }).catch(() => {
        dispatch("snackbar/showUnknownError", null , { root: true })
      })
    },
    async fetchEntitiesList({ dispatch, commit }) {
      await axiosInstance.get('activity-logs/entities-list').then((response) => {
        commit('setEntitiesList', response.data);
      }).catch(() => {
        dispatch("snackbar/showUnknownError", null , { root: true })
      })
    },
    async fetchFilteredListing({ dispatch, commit }, filters) {
      await axiosInstance.post('activity-logs/manual-filter', filters).then((response) => {
        commit("setFetchedListing", response.data.data);
        commit("setListingTotalItems", response.data.meta.total);
      }).catch(() => {
        dispatch("snackbar/showUnknownError", null, { root: true });
      })
    }
  }
}
