<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="6">
        <h1>Создание медиа контента</h1>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="6">
        <media-form
          @onSubmit="submit"
          style="margin: 0 auto"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MediaForm from '../../../components/medias/MediaForm';
export default {
  name: 'Create',
  components: { MediaForm },
  data: () => ({
    media: {
      id: null,
      entity: 'article',
      entity_id: null,
      structure: null,
    },
  }),
  created() {
    if (!this.$route.params.back_route) {
      this.$router.push('/')
    }
  },
  methods: {
    submit(data) {
      const backRoute = JSON.parse(this.$route.params.back_route);
      this.media.structure = data;
      const params = {
        ...this.$route.params,
        media: JSON.stringify(this.media),
      };
      if (this.$route.params.entity_id) {
        params.id = this.$route.params.entity_id;
      }
      this.$router.push({
        name: backRoute.name,
        params,
      })
    },
  },
};
</script>

<style scoped>

</style>
