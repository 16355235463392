<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row>
      <v-col>
        <h1>{{ page.title }}</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="primary" to="/season-pass/create">
          Создать абонемент
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :loading="loading"
          :server-items-length="listingTotal"
          :search="search"
        >
          <template v-slot:item.recurrent_payments="{ item }">
            <template v-if="item.recurrent_payments">
              <v-chip color="green" content="да" label>да</v-chip>
            </template>
            <template v-else>
              <v-chip color="blue" content="нет" label>нет</v-chip>
            </template>
          </template>
          <template v-slot:item.active="{ item }">
            <template v-if="item.active">
              <v-chip color="green" content="да" label>да</v-chip>
            </template>
            <template v-else>
              <v-chip color="red" content="нет" label>нет</v-chip>
            </template>
          </template>
          <template v-slot:item.test_mode="{ item }">
            <template v-if="item.test_mode">
              <v-chip color="blue" content="да" label>да</v-chip>
            </template>
            <template v-else>
              <v-chip color="green" content="нет" label>нет</v-chip>
            </template>
          </template>
          <template v-slot:item.type="{ item }">
            <v-chip :color="getTypeColor(item.type)" :content="getHumanType(item.type)" label>{{ getHumanType(item.type) }}</v-chip>
          </template>
          <template v-slot:item.action="{item}">
            <v-btn :href="editItem(item)" icon>
              <v-icon small color="primary">
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-btn @click="deleteItem(item)" icon>
              <v-icon color="red">
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
name: 'Index',
  data: () => ({
    search: '',
    only_trashed: null,
    options: {
      sortBy: ['id'],
      sortDesc: [true],
      page: 1,
      itemsPerPage: 10,
    },
    headers: [
      {text: 'Id', value: 'id'},
      {text: 'Название', value: 'title'},
      {text: 'Стоимость', value: 'amount'},
      {text: 'Тип', value: 'type'},
      {text: 'Количество дней', value: 'period'},
      {text: 'Количество курсов', value: 'available_quantity'},
      {text: 'Лимит', value: 'limit'},
      {text: 'Рекурентный', value: 'recurrent_payments'},
      {text: 'Стоимость первой покупки', value: 'first_purchase_cost'},
      {text: 'Активность', value: 'active'},
      {text: 'Тестовый режим', value: 'test_mode'},
      {text: 'Дата создания', value: 'created_at'},
      {text: 'Дата обновления', value: 'updated_at'},
      {text: '', value: 'action'},
    ],
    page: {
      title: "Абонементы"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Абонементы",
        disabled: true,
        to: '/season-pass'
      },
    ],
  }),
  created() {
    this.fetchedListing(this.listingPayload);
  },
  computed: {
    ...mapGetters({
      items: 'SeasonPass/getFetchedListing',
      listingTotal: 'SeasonPass/getListingTotalItems',
      loading: 'SeasonPass/getIsListingLoad',
    }),
    listingPayload() {
      return {
        options: this.options,
        search: this.search,
        only_trashed: this.only_trashed,
      }
    },
  },
  methods: {
    ...mapActions({
      fetchedListing: 'SeasonPass/fetchListing',
      destroyItem: 'SeasonPass/destroyItem',
    }),
    deleteItem(item) {
      this.destroyItem(item.id).then(this.fetchedListing(this.listingPayload));
    },
    editItem(item) {
      return this.$router.resolve({name: 'seasonPass-edit', params: {id: item.id}}).href;
    },
    getTypeColor(type) {
      switch (type) {
        case 0:
          return 'grey';
        case 1:
          return 'green';
        default:
          return 'grey';
      }
    },
    getHumanType(type) {
      switch (type) {
        case 0:
          return 'Стандартный';
        case 1:
          return 'Акционный';
        default:
          return 'Стандартный';
      }
    },
  }
}
</script>
