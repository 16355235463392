<template>
  <v-form>
    <v-row>
      <v-col>
        <v-card>
          <v-container>
            <v-text-field
              v-model="metaForm.smm_title"
              :error-messages="errors.smm_title"
              label="SMM title"
              name="smm_title"
              required
            ></v-text-field>
            <v-text-field
              v-model="metaForm.smm_description"
              :error-messages="errors.smm_description"
              label="SMM description"
              name="smm_description"
              required
            ></v-text-field>

            <media-widget
              class="mb-1"
              label="SMM изображение"
              :medias="metaForm.smm_image"
              :is-multiple="false"
              @onChange="setSmmImage"
            />
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-container>
            <short-link-widget :entity="entity" @updateParent="setShareUrl"/>
            <v-text-field
              v-model="metaForm.share_text"
              :error-messages="errors.share_text"
              label="Подводка к посту в социальные сети"
              name="share_text"
              required
            ></v-text-field>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!hideButton">
      <v-col>
        <div class="smm-preview">
          <h3>Предпросмотр</h3>
          <div class="share-text-block" v-if="metaForm.share_text">
            <h4 class="search-system-title"><span>Код для ширинга в социальную сеть</span></h4>
            <div class="row">
              <div class="col" id="share-text">
                {{ metaForm.share_text }}<br>
                {{ shareUrl }}
              </div>
              <v-btn
                color="primary"
                class="col-2 mr-2"
                @click="doCopy(metaForm.share_text + ' ' + shareUrl)"
              >Копировать</v-btn>
            </div>
          </div>
          <h4 class="search-system-title"><span>ВКонтакте</span></h4>
          <vk-smm-widget :entity="entity" :form="metaForm" />
          <h4 class="search-system-title"><span>Facebook</span></h4>
          <facebook-smm-widget :entity="entity" :form="metaForm" />
          <h4 class="search-system-title"><span>Telegram</span></h4>
          <telegram-smm-widget :entity="entity" :form="metaForm" />
          <h4 class="search-system-title"><span>Одноклассники</span></h4>
          <ok-smm-widget :entity="entity" :form="metaForm" />
        </div>
        <v-flex>
          <v-btn
            class="mr-4"
            color="primary"
            @click="submit"
            :disabled="isSaveDisabled || isDisabled"
            :loading="isSaveDisabled"
          >
            Сохранить
          </v-btn>
        </v-flex>
      </v-col>
    </v-row>
    <media-uploader
      :loaded-medias="entity.loaded_medias"
      @completeUploads="onCompleteUploads"
    />
  </v-form>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import MediaUploader from './MediaUploader';
import MediaWidget from './MediaWidget';
import VkSmmWidget from './VkSmmWidget.vue';
import FacebookSmmWidget from './FacebookSmmWidget.vue';
import TelegramSmmWidget from './TelegramSmmWidget.vue';
import OkSmmWidget from './OkSmmWidget.vue';
import ShortLinkWidget from './ShortLinksWidget.vue';

export default {
  name: 'SMMForm',
  components: {
    MediaWidget,
    MediaUploader,
    VkSmmWidget,
    FacebookSmmWidget,
    TelegramSmmWidget,
    OkSmmWidget,
    ShortLinkWidget,
  },
  props: {
    entity: {
      type: Object,
      default: () => ({})
    },
    media: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    hideButton: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    formErrors: {},
    shareUrl: '',
  }),
  created() {
    if (this.$route.params.data) {
      this.metaForm = JSON.parse(this.$route.params.data);
    }

    if (this.entity) {
      let tempForm = {};

      tempForm.entity_type = this.entity.entity_type || this.entity.entity;
      tempForm.entity_id = this.entity.id;

      this.mutationMetaForm(tempForm);
      this.getMetaData(this.metaForm);
    }
  },
  computed: {
    ...mapGetters({
      metaForm: 'Meta/getMetaForm',
      isSaveDisabled: 'Meta/getIsSaveDisabled',
    }),
  },
  methods: {
    ...mapActions({
      getMetaData: 'Meta/getMetaData',
      storeItem: 'Meta/storeItem',
    }),
    ...mapMutations({
      mutationMetaForm: 'Meta/setMetaForm',
      mutationIsSaveDisabled: 'Meta/setIsSaveDisabled',
    }),
    async submit() {
      this.storeItem({data: this.metaForm});
    },
    setSmmImage(image) {
      this.metaForm.smm_image = image;
    },
    async onCompleteUploads(ids) {
      this.mediaIds = ids;
      await this.submit();
      this.mediaIds = [];
    },
    setShareUrl(payload) {
      this.shareUrl = payload.shareUrl;
    },
    doCopy: function (message) {
      this.$copyText(message).then(() => {
        this.$store.dispatch('snackbar/showSuccessCopyShareText', null, { root: true });
      }, () => {
        this.$store.dispatch('snackbar/showErrorCopy', null, { root: true });
      })
    }
  },
  watch: {
    entity: {
      handler(entity) {
        if (entity && Object.keys(entity).length > 0) {
          let tempForm = {};

          tempForm = entity;
          tempForm.entity_type = entity.entity_type || entity.entity;

          this.mutationMetaForm(tempForm);
          this.mutationIsSaveDisabled(false);
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  .smm-preview {
    margin-left: 15px;
    margin-right: 15px;
  }

  h4.search-system-title {
    font-size: 14px;
    letter-spacing: 0.015em;
    color: #A3B3CA;
    font-family: "adelle-sans", sans-serif;
    position: relative;

    span {
      position: relative;
      background: #fff;
      padding-right: 12px;
    }
  }

  h4.search-system-title:before {
    content: '';
    position: absolute;
    top: 10px;
    right: 0;
    width: 100%;
    height: 1px;
    background: #E0E7F1;
  }

  .share-text-block {
    margin-bottom: 5px;

    .row {
      background: #f2f3f5;
      margin: 0px;

      button {
        margin-top: 15px;
      }
    }
  }
</style>
