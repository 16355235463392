<template>
  <v-dialog v-model="show" max-width="800">
    <v-card>
      <v-card>
        <v-card-title class="justify-center">
          <div class="text-center">
            Сортировка дочерних элементов
          </div>
        </v-card-title>
        <v-card-text>
          <div>
            <v-list>
              <v-list-item-group color="indigo">
                <draggable
                  :list="sortableItems"
                >
                  <v-card
                    class="d-flex"
                    v-for="(element, index) in sortableItems"
                    :key="element.id">

                    <v-card-text class="mr-auto text-h5 black--text">
                      {{ element.title }}
                    </v-card-text>
                    <v-card-text class="text-right black--text">
                      <v-row>
                        Старая позиция: {{ element.position === null ? '-' : element.position + 1 }}
                      </v-row>
                      <v-row>
                        Новая позиция:{{ index + 1 }}
                      </v-row>
                    </v-card-text>
                  </v-card>
                </draggable>
              </v-list-item-group>
            </v-list>
          </div>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            @click="show=false"
            text
            color="green darken-1"
          >Закрыть
          </v-btn>
          <v-btn
            @click="submitSort"
            text
            color="green darken-1"
            :loading="isSaveButtonDisabled"
            :disabled="isSaveButtonDisabled"
          >Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import draggable from "vuedraggable";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "SortablePages",
  components: {
    draggable,
  },
  props: {
    value: Boolean,
  },
  data: () => ({
    sortableItems: [],
  }),
  created() {
    this.sortableItems = this.items.slice(0);
  },
  computed: {
    ...mapGetters({
      items: 'Pages/getChild',
      isSaveButtonDisabled: 'Pages/isSaveButtonDisabled'
    }),
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value);
      }
    },
  },
  methods: {
    ...mapActions({
      storeSort: 'Pages/storeSort',
      fetchDetail: 'Pages/fetchDetail',
    }),
    async submitSort() {
      this.storeSort(this.sortableItems).then(() => {
        this.fetchDetail(this.$route.params.id).then(() => {
          this.show = false;
        });
      });
    },
  },
  watch: {
    items: {
      handler() {
        this.sortableItems = this.items.slice(0);
      }
    }
  }
}
</script>

<style scoped>

</style>
