<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <catalog-form @onSubmit="store"/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import CatalogForm from "../../../components/catalogs/CatalogForm";
import { mapActions } from "vuex";

export default {
  name: "Create",
  components: {CatalogForm},
  data: () => ({
    page: {
      title: "Создание каталога"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Каталог",
        disabled: false,
        to: '/catalogs'
      },
      {
        text: "Создание",
        disabled: true,
        to: '/catalogs/create'
      },
    ],
  }),
  methods: {
    ...mapActions({
      "storeItem": "Catalog/storeItem"
    }),
    store({ form, isNeedRedirect }) {
      this.storeItem({ form: form, isNeedRedirect: isNeedRedirect, router: this.$router });
    }
  }
}
</script>

<style scoped>

</style>
