<template>
  <div>
    <p>
      <b>Вопрос:</b>
      {{ form.question }}
    </p>
    <p>
      <b>Создан:</b>
      {{ form.created_at }}
    </p>
    <p>
      <b>Обновлен:</b>
      {{ form.updated_at }}
    </p>    
    <v-form>
      <wysiwyg-editor
              class="mb-6 ws-border"
              v-model="form.answer"
              :error-messages="errors.answer"
              label="Ответ"
              title="Ответ"
              name="answer"
      />      
      <v-flex>
        <v-btn
          color="primary"
          :disabled="isSaveDisabled"
          :loading="isSaveDisabled"
          @click="submit"
        >
          Сохранить
        </v-btn>
      </v-flex>
    </v-form>
  </div>
</template>

<script>
import WysiwygEditor from "@/components/ui/WysiwygEditor.vue";

export default {
  name: "QuestionsAnswersForm",
  components: {
    WysiwygEditor 
  },
  props: {
    lead: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    form: {
      id: null,
      question: null,
      answer: null,
    },
    statuses: [
      {text: 'Новый', value: 0},
      {text: 'Прочитано', value: 1},
      {text: 'Отвечено', value: 2},
    ],
    isSaveDisabled: false,
  }),
  created() {
    if (this.$route.params) {
      this.form = this.$route.params;
    }
  },
  methods: {
    submit() {
      this.isSaveDisabled = true;

      this.$emit('onSubmit', {
        form: this.form,
      });
    },
    editUser(item) {
      return this.$router.resolve({name: 'users-edit', params: {id: item.id}}).href
    },
  },
  watch: {
    lead: {
      handler(lead) {
        if (lead && Object.keys(lead).length > 0) {
          this.form = lead;
          this.isSaveDisabled = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
}
</script>
<style scoped>
  .ws-border
  {
    border: 2px solid whitesmoke;
    border-radius: 6px;
  }
</style>