<template>
  <v-dialog v-model="dialogModal" width="650">
    <v-card>
      <v-card-title>
        Создание перевода страницы
      </v-card-title>
      <v-card-text>
        <v-select label="Выберите язык" :items="languages" v-model="form.language_id" item-text="label" item-value="value"/>
        <v-text-field label="Введите заголовок" v-model="form.title"/>
        <v-text-field label="Введите slug" v-model="form.slug"/>
      </v-card-text>
      <v-card-actions>
        <v-btn block color="primary" :disabled="form.language_id === null" @click="submit">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import slugify from "slugify";

export default {
  name: "TranslationCreatorComponent",
  data: () => ({
    form: {
      title: null,
      slug: null,
      language_id: null,
      translated_origin_id: null,
    }
  }),
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    existingLanguages: {
      type: Object,
      required: true,
    },
    original_page: {
      type: Number,
      required: true,
    }
  },
  created() {
    this.fetchList();
  },
  computed: {
    ...mapGetters({
      'fetchedData': 'Languages/getFetchedList',
    }),
    languages() {
      return this.fetchedData.filter((item) => this.existingLanguages.indexOf(item.value) === -1);
    },
    dialogModal: {
      get() {
        return this.value;
      },
      set(val) {
        if (val === false) {
          this.form = {
            title: null,
            slug: null,
            language_id: null,
            translated_origin_id: null,
          }
        }

        this.$emit('input', val);
      }
    }
  },
  methods: {
    ...mapActions({
      'fetchList': "Languages/fetchList",
    }),
    submit() {
      this.form.translated_origin_id = this.original_page;
      this.$emit('onSubmit', this.form);
    }
  },
  watch: {
    'form.title': {
      handler(value) {
        this.form.slug = slugify(value.toLowerCase()).match(/[a-z]|[0-9]|[-]/gm).join('');
      }
    }
  }
}
</script>

<style scoped>

</style>
