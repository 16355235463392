<template>
  <v-container>
    <h3 v-if="title">{{ title }}</h3>
    <div v-for="dynamicGroup in groups" :key="dynamicGroup.id">
      <div v-for="dynamicField in dynamicGroup.fields" :key="dynamicField.id">
        <component :is="dynamicField.component" v-model="dynamicField.value" :options="dynamicField.extra_options"/>
      </div>
    </div>
  </v-container>
</template>

<script>
import { fields } from './fields';

export default {
  name: "FieldComponent",
  mixins: [fields],
  props: ['value', 'title'],
  computed: {
    groups: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  }
}
</script>

<style scoped>

</style>
