import axiosInstance from "../../../axiosInstance";
import {Partner} from "../../../models/partner";

export default {
  namespaced: true,
  state: {
    fetchedList: [],
    excelListing: [],
    isExcelLoading: true,
  },
  getters: {
    getFetchedList: (state) => state.fetchedList,
    getExcelListing: (state) => state.excelListing,
    isExcelLoading: (state) => state.isExcelLoading,
  },
  mutations: {
    setFetchedList(state, val) {
      state.fetchedList = val;
    },
    setExcelListing(state, val) {
      state.excelListing = val;
    },
    setIsExcelLoading(state, val) {
      state.isExcelLoading = val;
    }
  },
  actions: {
    async fetchList({ commit, dispatch }) {
      await axiosInstance.get('partners/select-list').then((response) => {
        commit('setFetchedList', response.data.data);
      }).catch(() => {
        dispatch('snackbar/showUnknownError', null, { root: true });
      });
    },
    async fetchExcelListing({ commit, dispatch }) {
      commit("setIsExcelLoading", true);
      Partner.$query().get(100).then((response) => {
        commit('setExcelListing', response.map(item => item.$attributes));
      }).catch(() => {
        dispatch("snackbar/showUnknownError", null, { root: true })
      }).finally(() => {
        commit("setIsExcelLoading", false);
      })
    },
    async saveExcel({ dispatch, commit }, { toDelete, toSave, toUpdate }) {
      commit("setIsExcelLoading", true);
      try{
        if (toDelete.length > 0) {
          await axiosInstance.delete('partners/batch', { params: { resources: toDelete } });
        }
        const toStore = toSave.filter((item) => typeof item.id !== "number");
        if (toStore.length > 0) {
          await axiosInstance.post('partners/batch', { resources: toStore });
        }
        let attributesToUpdate = {};
        toUpdate.forEach((item) => {
          attributesToUpdate[item.id] = {
            title: item.title,
            phone: item.phone,
            geographic_address: item.geographic_address,
            site_url: item.site_url,
          }
        });

        if (Object.keys(attributesToUpdate).length > 0) {
          await axiosInstance.patch('partners/batch', {resources: attributesToUpdate});
        }

        dispatch("snackbar/showSuccessfullySaved", null, { root: true });
        dispatch("fetchExcelListing");
      } catch(e) {
        dispatch("snackbar/showUnknownError", null, { root: true });
      }

      commit("setIsExcelLoading", false);
    }
  }
}
