import { Model } from "@tailflow/laravel-orion/lib/model";
import {HasMany} from "@tailflow/laravel-orion/lib/drivers/default/relations/hasMany";
import {Role} from "./role";
import {BelongsTo} from "@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo";
import {BelongsToMany} from "@tailflow/laravel-orion/lib/drivers/default/relations/belongsToMany";

export class Permission extends Model<{
    name: string,
    guard_name: string,
    description: string,
    created_at: string,
    updated_at: string
}> {

    $resource(): string {
        return "permissions";
    }

    public roles(): BelongsToMany<Role> {
        return new BelongsToMany<Role>(Role, this);
    }
}
