import {Model} from "@tailflow/laravel-orion/lib/model";

export class SystemPhrase extends Model<{
    russian: string,
    english: string,
}> {

    $resource(): string {
        return "system-phrase";
    }

}
