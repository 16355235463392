import Index from '../../views/main/permissions/Index'
import Create from '../../views/main/permissions/Create'
import Edit from '../../views/main/permissions/Edit'

export default [
  {
    component: Index,
    name: 'permissions',
    path: '/permissions',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'permissions',
    }
  },
  {
    component: Create,
    name: 'permissions-create',
    path: '/permissions/create',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'permissions',
    }
  },
  {
    component: Edit,
    name: 'permissions-edit',
    path: '/permissions/edit/:id',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
      module: 'permissions',
    }
  },
]
