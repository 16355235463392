<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row justify="space-between">
          <v-col cols="12" lg="4">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Поиск..."
              filled
              background-color="transparent"
              hide-details
              name="title"
            >
              <v-btn v-if="search" class="reset-button"
                     slot="append"
                     icon
                     @click="search = null"
                     color="red"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-text-field>
          </v-col>
          <v-col>
            <v-switch
              v-model="only_trashed"
              label="Показать удаленные"
              name="only_trashed"
              value="1"
            ></v-switch>
          </v-col>
          <v-col cols="12" lg="4" class="justify-end align-center d-flex">
            <v-dialog v-model="dialog" persistent max-width="700px">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" class="ml-2" to="regional-practices/create">
                  Добавить региональную практику
                </v-btn>
              </template>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :options.sync="options"
              :loading="isLoading"
              loading-text="Загрузка..."
              :items="fetchedData"
              :server-items-length="totalItems"
            >
              <template v-slot:item.id="{ item }">
                <v-chip pill>{{ item.id }}</v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn icon :to="`/regional-practices/edit/${item.id}`">
                  <v-icon color="primary" small>
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-icon
                  v-if="!item.deleted_at"
                  color="red"
                  @click="deleteItem(item, 0)"
                  small
                >
                  mdi-delete
                </v-icon>
                <v-icon
                  v-else
                  color="green"
                  @click="restoreItem(item)"
                  small
                >
                  mdi-backup-restore
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Index",
  data: () => ({
    options: {
      sortBy: ['id'],
      sortDesc: [true],
    },
    search: '',
    only_trashed: false,
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'Название', value: 'title' },
      { text: 'Slug', value: 'slug' },
      { text: 'Регион', value: 'region_title' },
      { text: '', value: 'actions', sortable: false },
    ],
    page: {
      title: "Региональные практики"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Региональные практики",
        disabled: true,
        to: '/regional-practices/'
      },
    ],
  }),
  computed: {
    ...mapGetters({
      "fetchedData": "RegionalPractices/getFetchedListing",
      "totalItems": "RegionalPractices/getListingTotalItems",
      "isLoading": "RegionalPractices/getIsLoading",
    }),
    listingPayload() {
      return {
        options: this.options,
        search: this.search,
        only_trashed: this.only_trashed,
      }
    }
  },
  methods: {
    ...mapActions({
      "fetchListing": "RegionalPractices/fetchListing",
      "destroyItem": "RegionalPractices/destroyItem",
      "restore": "RegionalPractices/restoreItem",
    }),
    deleteItem({ id }, isForce) {
      this.$confirm('Удалить элемент?', 'Удаление').then(() => {
        this.destroyItem({ id, isForce }).then(() => {
          this.fetchListing(this.listingPayload);
        });
      })
    },
    restoreItem({id}) {
      this.restore(id).then(() => {
        this.fetchListing(this.listingPayload);
      })
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchListing(this.listingPayload);
      },
      deep: true,
      immediate: true,
    },
    search: {
      handler() {
        this.fetchListing(this.listingPayload);
      },
    },
    only_trashed: {
      handler() {
        this.fetchListing(this.listingPayload);
      },
    }
  }
}
</script>

<style scoped>

</style>
