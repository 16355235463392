<template>
  <v-container fluid class="down-top-padding">
    <v-card>
      <vxe-toolbar>
        <template #buttons>
          <vxe-input v-model="search" type="search" placeholder="Поиск" @keyup="onSearchInput"></vxe-input>

          <vxe-button content="Добавить" class="ml-3" status="primary" @click="insertEvent" />
          <vxe-button content="Удалить" status="danger" @click="removeEvent" />
          <vxe-button content="Сохранить" status="success" @click="saveEvent" />
        </template>
      </vxe-toolbar>
      <div style="height:calc(100vh - 260px)">
        <vxe-table
          border
          stripe
          resizable
          highlight-hover-row
          keep-source

          show-footer
          max-height="100%"
          ref="xTable"
          :data="data"
          :edit-config="{trigger: 'click', mode: 'cell', showStatus: true}"
          :column-config="{resizable: true, minWidth: 300}"
          :tooltip-config="tableToolbar"
          :menu-config="menuConfig"
          @header-cell-click="headerCellClickEvent"
          @menu-click="contextMenuClickEvent"
        >
          <vxe-column type="checkbox" width="60" />
          <vxe-column field="id" title="id" width="60" />
          <vxe-column field="title" title="Заголовок" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.title" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="person_id" title="Персона" :edit-render="{}" sortable>
            <template #default="{ row }">
              <span>{{ formatPersonsValue(row.person_id) }}</span>
            </template>
            <template #edit="scope">
              <vxe-select v-model="scope.row.person_id" transfer>
                <vxe-option v-for="item in persons" :key="item.key" :value="item.key"
                            :label="item.value"/>
              </vxe-select>
            </template>
          </vxe-column>
          <vxe-column field="course" title="Курс" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.course" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="age" title="Возраст" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.age" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="lesson" title="Урок" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.lesson" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="question_content" title="Название вопроса" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.question_content" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="sphere" title="Cфера" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.sphere" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="illustration_question" title="Иллюстрация для вопроса" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.illustration_question" type="number"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="answer_1" title="Ответ 1" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.answer_1" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="illustration_answer_1" title="Иллюстрация для ответа 1" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.illustration_answer_1" type="number"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="answer_2" title="Ответ 2" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.answer_2" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="illustration_answer_2" title="Иллюстрация для ответа 2" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.illustration_answer_2" type="number"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="answer_3" title="Ответ 3" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.answer_3" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="illustration_answer_3" title="Иллюстрация для ответа 3" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.illustration_answer_3" type="number"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="answer_4" title="Ответ 4" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.answer_4" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="illustration_answer_4" title="Иллюстрация для ответа 4" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.illustration_answer_4" type="number"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="answer_5" title="Ответ 5" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.answer_5" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="illustration_answer_5" title="Иллюстрация для ответа 5" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.illustration_answer_5" type="number"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="qz_check" title="Ответ подходит для панели qz-04" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-checkbox v-model="row.qz_check"></vxe-checkbox>
            </template>
          </vxe-column>
          <vxe-column field="correct_answer" title="Правильный ответ" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.correct_answer" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="illustration_for_correct_answer" title="Иллюстрация для правильного ответа" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.illustration_for_correct_answer" type="number"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="cost_correct_answer" title="Стоимость правильного ответа" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.cost_correct_answer" type="number"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="cost_wrong_answer" title="Стоимость неправильного ответа" :edit-render="{}" sortable>
            <template #edit="{ row }">
              <vxe-input v-model="row.cost_wrong_answer" type="number"></vxe-input>
            </template>
          </vxe-column>
        </vxe-table>
        <vxe-pager
          border
          icon-prev-page="fa fa-angle-left"
          icon-jump-prev="fa fa-angle-double-left"
          icon-jump-next="fa fa-angle-double-right"
          icon-next-page="fa fa-angle-right"
          icon-jump-more="fa fa-ellipsis-h"
          :loading="loading"
          :current-page="paginator.currentPage"
          :page-size="paginator.limit"
          :total="paginator.total"
          :page-sizes="paginator.pageSizes"
          :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']"
          @page-change="changePage">
        </vxe-pager>
      </div>
    </v-card>
    <wysywig-popup
      v-if="isShowWysywig"
      v-model="wysywigPopup"
      :html="data[latestCellContextMenuClick.row][latestCellContextMenuClick.column]"
      @html-input="htmlInput"
    />
  </v-container>
</template>

<script>
import WysywigPopup from "@/fields/table/WysywigPopup";
import VXETable from "vxe-table";
import axiosInstance from "@/axiosInstance";

export default {
  name: "BlockQuizFunctional",
  components: { WysywigPopup },
  data: () => ({
    data: [],
    tableToolbar: {
      buttons: [
        { code: 'myInsert', name: 'Добавить' },
        { code: 'mySave', name: 'Сохранить' },
        { code: 'myDelete', name: 'Удалить' },
      ],
      tools: [
        { code: 'myPrint', name: '自定义打印' }
      ],
      refresh: true,
    },
    paginator: {
      currentPage: 1,
      limit: 1500,
      pageSizes: [100, 150, 200],
      total: 0,
      vxePagerLayouts: [
        'PrevPage',
        'NextPage',
        'Sizes',
        'Total',
      ]
    },
    loading: false,
    search: '',
    sort: '',
    menuConfig: {
      body: {
        options: [
          [
            { code: 'wysiwyg', name: 'Wysiwyg', visible: true, disabled: false },
          ]
        ]
      },
    },
    wysywigPopup: false,
    latestCellContextMenuClick: {
      row: null,
      column: null,
    },
    persons: [],
    lessons: [],
  }),
  computed: {
    isShowWysywig() {
      return (this.latestCellContextMenuClick.row !== null && this.latestCellContextMenuClick.column !== null);
    }
  },
  created() {
    this.fetchPersons();
    this.fetchData();
  },
  methods: {
    insertEvent() {
      this.$refs.xTable.insert();
    },
    async removeEvent() {
      if (this.$refs.xTable.getCheckboxRecords().length > 0) {
        const type = await VXETable.modal.confirm('Удалить выбраные записи?')
        if (type === 'confirm') {
          this.$refs.xTable.removeCheckboxRow();
        }
      }
    },
    async saveEvent() {
      const errMap = await this.$refs.xTable.validate(true).catch(errMap => errMap)
      if (errMap) {
        VXETable.modal.message({ status: 'error', content: 'Заполните все обязательные поля' })
      } else {
        const { insertRecords, removeRecords, updateRecords } = this.$refs.xTable.getRecordset();

        if (insertRecords.length > 0) {
          await axiosInstance.post("module/blocks/quiz-functional/insert", { items: insertRecords });
        }

        if (removeRecords.length > 0) {
          await axiosInstance.post("module/blocks/quiz-functional/delete", { items: removeRecords });
        }

        if (updateRecords.length > 0) {
          await axiosInstance.post("module/blocks/quiz-functional/update", { items: updateRecords });
        }
        await this.fetchData();

        this.refreshColumns();
      }
    },
    refreshColumns() {
      this.$nextTick(() => {
        this.hidenColumns = this.$refs.xTable.getColumns();
      })
    },
    async changePage({ currentPage, pageSize }) {
      this.paginator.currentPage = currentPage;
      this.paginator.limit = pageSize;

      await this.fetchData();

      this.refreshColumns();
    },
    async fetchData() {
      this.loading = true;

      const { data } = await axiosInstance.get(`module/blocks/quiz-functional/index?page=${this.paginator.currentPage}&per_page=${this.paginator.limit}&search=${this.search}&sort=${this.sort}`);

      this.paginator.currentPage = data.current_page;
      this.paginator.limit = data.per_page;
      this.paginator.total = data.total;
      this.data = data.data;

      this.loading = false;
    },
    onSearchInput() {
      this.fetchData();
    },
    async headerCellClickEvent({ column, triggerResizable, triggerSort, triggerFilter }) {
      if (column.sortable && !(triggerResizable || triggerSort || triggerFilter)) {
        if (column.order === 'desc') {
          await this.$refs.xTable.clearSort()

          this.sort = '';
        } else if (column.order === 'asc') {
          this.sort = `${column.property}|desc`;
          column.order = 'desc';
        } else {
          this.sort = `${column.property}|asc`;
          column.order = 'asc';
        }

        await this.fetchData();
      }
    },
    contextMenuClickEvent({ menu, rowIndex, column }) {
      if (menu.code === "wysiwyg") {
        this.latestCellContextMenuClick.row = rowIndex;
        this.latestCellContextMenuClick.column = column.field;
        this.wysywigPopup = true;
      }
    },
    htmlInput(val) {
      this.data[this.latestCellContextMenuClick.row][this.latestCellContextMenuClick.column] = val;
    },
    visibleMethod({ options, column }) {
      const field = this.dynamicFields.find((item) => item.field_name === column.field);

      options[0][0].disabled = field?.component !== 'textarea-field';

      return true;
    },
    fetchPersons() {
      axiosInstance.get('entity/list', { params: { entity: ['App\\Models\\Person'] } }).then((response) => {
        this.persons = response.data;
      });
    },
    formatPersonsValue(value) {
      if (value) {
        const label = this.persons.find((item) => {
          return item.key === value;
        })

        if (label !== undefined) {
          return label.value;
        }
      }

      return "-";
    },
  }
}
</script>

<style scoped>

</style>
