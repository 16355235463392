<template>
    <v-dialog v-model="isShowPreview">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-for="deviceType in deviceTypes"
                :key="deviceType.type"
                v-bind="attrs"
                v-on="on"
                icon
                @click="currentDeviceType = deviceType.type"
            >
                <v-icon>
                    {{ deviceType.icon }}
                </v-icon>
            </v-btn> 
        </template>
        <div class="preview">
            <div
                :class="['preview-item', `preview-${currentDeviceData.type}`]"
            >
                <div :class="['preview-img-wr', `preview-img-wr-${currentDeviceData.type}`]">
                    <img :src="require(`@/assets/images/${currentDeviceData.image}`)" alt="">
                    <div :class="`preview-iframe-${currentDeviceData.type}`">
                        <div v-if="loading" :class="['preview-overlay', `preview-overlay-${currentDeviceData.type}`]">
                            <v-progress-circular
                                indeterminate
                                color="primary"
                            />
                        </div>
                        <iframe
                            v-show="!loading"
                            @load="loadHandler"
                            :src="previewUrl"
                            :width="currentDeviceData.iframeConfig.width"
                            :height="currentDeviceData.iframeConfig.height"
                            frameborder="0" 
                        />
                    </div>
                    <v-btn icon class="preview-close" @click="isShowPreview = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
            </div>
        </div>
    </v-dialog>
</template>

<script>

export default {
    name: 'preview-mobile',
    props: {
        previewUrl: {
            type: String,
            default: 'https://xn--80apaohbc3aw9e.xn--p1ai'
        }
    },
    data() {
        return {
            loading: true,
            timeout: null,
            isShowPreview: false,
            deviceTypes: [
                {
                    type: 'tablet-horizontal',
                    icon: 'mdi-tablet',
                    
                    image: 'ipad-horizontal.png',
                    iframeConfig: {
                        width:'1200',
                        height: '900'
                    }
                },
                {
                    type: 'tablet',
                    icon: 'mdi-tablet-android',
                    image: 'ipad.png',
                    iframeConfig: {
                        width:'780',
                        height: '1030'
                    }
                },
                {
                    type: 'mobile',
                    icon: 'mdi-cellphone',
                    image: 'iphone-7.png',
                    iframeConfig: {
                        width:'375',
                        height: '640'
                    }
                },
            ],
            currentDeviceType: 'mobile'
        }
    },
    computed: {
        currentDeviceData() {
            return this.deviceTypes.find(device => device.type === this.currentDeviceType)
        }
    },
    methods: {
        loadHandler() {
            this.loading = false
        },
        checkLoading() {
            this.loading = true
            clearTimeout(this.timeout)

            this.timeout = setTimeout(() => {
                if (this.loading) {
                    this.loading = false
                }
            }, 4000)
        }
    },
    watch: {
        deviceType() {
            this.checkLoading()
        }
    }
}
</script>

<style lang="scss" scoped>
    .v-dialog .preview {
        width: auto;
    }
    .preview {
        position: absolute;
        left: 50%;
        top: 20px;
        transform: translateX(-50%);
        z-index: -1;

        select {
            position: absolute;
            left: 10px;
            top: 100px;
        }
        .mdi-close {
            font-size: 60px;
        }
    }
    .preview-overlay {
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        &-mobile {
            width: 375px;
            height: 640px;
        }

        &-tablet {
            width: 780px;
            height: 1030px;
        }

        &-tablet-horizontal {
            width: 1200px;
            height: 900px;
        }
    }
    .preview-item {
        position: relative;
    }
    .preview-img-wr {
        max-width: 100%;
        position: relative;
        img {
            width: 100%;
        }

        &-mobile {
            width: 410px;
            transform: scale(0.9);
        }
        &-tablet {
            width: 870px;
            transform: translateY(-255px) scale(0.55)
        }
        &-tablet-horizontal {
            width: 1450px;
            transform: translateY(-188px) scale(0.55);
        }
    }
    .preview-iframe-mobile {
        position: absolute;
        top: 91px;
        left: 24px;
    }
    .preview-iframe-tablet {
        position: absolute;
        top: 133px;
        left: 50px;
    }
    .preview-iframe-tablet-horizontal {
        position: absolute;
        top: 62px;
        left: 125px;
    }
    .preview-close {
        position: absolute;
        right: -60px;
        top: 10px;
        background-color: transparent;
        color: #000;
        border: none;
        font-size: 35px;
    }
    .fade-enter-active, .fade-leave-active {
		transition: opacity .3s;
	}
	.fade-enter, .fade-leave-to {
		opacity: 0;
	}
</style>