import {Model} from "@tailflow/laravel-orion/lib/model";

export class Review extends Model<{
    user_id: number,
    reviewable_id: number,
    reviewable_type: string,
    type_id: number,
    text: string,
    created_at: string,
    updated_at: string,
}> {

    $resource(): string {
        return "module/reviews";
    }
}
