import Index from '../../views/main/access/Index';

export default [
  {
    component: Index,
    name: 'access',
    path: '/access',
    meta: {
      layout: 'main-layout',
      requiresAuth: false,
    }
  },
]
