<template>
  <v-form>
    <v-text-field
      v-model="form.title"
      :error-messages="errors.title"
      label="Название"
      name="title"
      required
    ></v-text-field>
    <v-text-field
      v-model="form.url"
      :error-messages="errors.url"
      label="URL"
      name="url"
      required
    ></v-text-field>
    <v-flex>
      <v-btn
        class="mr-4"
        color="primary"
        :disabled="isSaveDisabled"
        :loading="isSaveDisabled"
        @click="submit(true)"
      >
        Сохранить
      </v-btn>
      <v-btn
        color="primary"
        :disabled="isSaveDisabled"
        :loading="isSaveDisabled"
        class="ml-2"
        @click="submit(false)"
      >
        Сохранить и продолжить
      </v-btn>
    </v-flex>
  </v-form>
</template>

<script>
export default {
  name: 'ResearchForm',
  props: {
    source: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    form: {
      id: null,
      title: null,
      url: null,
    },
    isSaveDisabled: false,
  }),
  created() {
    if (this.$route.params.data) {
      this.form = JSON.parse(this.$route.params.data);
    }
  },
  methods: {
    async submit(isNeedRedirect) {
      this.isSaveDisabled = true;
      this.$emit('onSubmit', this.form, isNeedRedirect);
    },
  },
  watch: {
    source: {
      handler(source) {
        if (source && Object.keys(source).length > 0) {
          this.form = source;
          this.isSaveDisabled = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
