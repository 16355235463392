var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',[_c('validation-provider',{attrs:{"name":"Название","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Название"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Slug","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.form.id ? true : false,"error-messages":errors,"label":"slug"},model:{value:(_vm.form.slug),callback:function ($$v) {_vm.$set(_vm.form, "slug", $$v)},expression:"form.slug"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Статус","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Статус","items":_vm.getStatuses,"item-value":"value","item-text":"label","error-messages":errors},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Иконка(mdi)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Иконка(mdi)","error-messages":errors},model:{value:(_vm.form.icon),callback:function ($$v) {_vm.$set(_vm.form, "icon", $$v)},expression:"form.icon"}})]}}],null,true)}),_c('v-btn',{attrs:{"loading":_vm.isLoading,"disabled":_vm.isLoading,"color":"primary"},on:{"click":function($event){return _vm.submit(true)}}},[_vm._v("Сохранить")]),_c('v-btn',{staticClass:"ml-2",attrs:{"loading":_vm.isLoading,"disabled":_vm.isLoading,"color":"primary"},on:{"click":function($event){return _vm.submit(false)}}},[_vm._v("Сохранить и продолжить")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }