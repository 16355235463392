export default {
  methods: {
    getUrlWithLastSlash(url) {
      if (url.toString().slice(-1) !== '/')
        url += '/';

      return url;
    }
  }
}
