import axiosInstance from "../../../axiosInstance";
import {Language} from "../../../models/lang";
import {SortDirection} from "@tailflow/laravel-orion/lib/drivers/default/enums/sortDirection";

export default {
  namespaced: true,
  state: {
    fetchedList: [],
    fetchedListing: [],
    listingTotalItems: 0,
    isLoading: false,
  },
  getters: {
    getFetchedList: (state) => state.fetchedList,
    getFetchedListing: (state) => state.fetchedListing,
    getIsLoading: (state) => state.isLoading,
    getListingTotalItems: (state) => state.listingTotalItems,
  },
  mutations: {
    setFetchedList(state, val) {
      state.fetchedList = val;
    },
    setFetchedListing(state, val) {
      state.fetchedListing = val;
    },
    setIsLoading(state, val) {
      state.isLoading = val;
    },
    setListingTotalItems(state, val) {
      state.listingTotalItems = val;
    }
  },
  actions: {
    async fetchList({ commit }) {
      await axiosInstance.get('language/list').then((reponse) => {
        commit('setFetchedList', reponse.data);
      });
    },
    async fetchListing({ commit, dispatch }, { options }) {
      commit('setIsLoading', true);

      const { sortBy, sortDesc, page, itemsPerPage } = options;

      let queryBuilder = Language
        .$query();

      if (sortBy[0]) {
        queryBuilder.sortBy(sortBy[0], sortDesc[0] ? SortDirection.Desc : SortDirection.Asc)
      }

      queryBuilder.search(itemsPerPage, page).then((response) => {
        if (response.length > 0) {
          commit('setListingTotalItems', response[0].$response.data.meta.total);
        } else {
          commit('setListingTotalItems', 0);
        }
        commit('setFetchedListing', response.map((item) => item.$attributes));
      }).catch(() => {
        dispatch("snackbar/showUnknownError", null, { root: true })
      }).finally(() => {
        commit("setIsLoading", false);
      })
    },
    async createItem({ commit, dispatch }, form) {
      commit('setIsLoading', true);

      return Language.$query().store(form).then(() => {
        dispatch("snackbar/showSuccessfullySaved", null, { root: true })
      }).catch(() => {
        dispatch("snackbar/showUnknownError", null, { root: true });
      }).finally(() => {
        commit('setIsLoading', false);
      })
    },
    async updateItem({ commit, dispatch }, form) {
      commit('setIsLoading', true);

      return Language.$query().update(form.id, form).then(() => {
        dispatch("snackbar/showSuccessfullySaved", null, { root: true });
      }).catch(() => {
        dispatch("snackbar/showUnknownError", null, { root: true });
      }).finally(() => {
        commit('setIsLoading', false);
      })
    }
  }
}
